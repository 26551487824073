import React, { useState } from 'react'
import { FormTextArea, FormWrapper } from '../../CustomForm/CustomFormElements'
import { CustomButton, CustomButton1 } from '../../ButtonElement'
import { Theme } from '../../WebsiteThemeElements'
import { useModal } from '../../Modal/ModalContext'
import { useAuth } from '../../../contexts/AuthContext'
import { TRANSACTION_OPTIONS } from '../../../utils/Enum'
import QRCodeForm from '../../QRCodeForm'
import { hexToRgba } from '../../HexToRGBA'

const ReplyForm = ({type, id, commentId, setOpen, refresh, commenter}) => {
    const [message, setMessage] = useState("")
    const {modalContent, toggleModal, openModal} = useModal()
    const {currentUser} = useAuth()

    const handleInput = (e) => {
        setMessage(e.target.value)
    }

  return (
    <>
    <div style={{display:'flex', padding: '2%', flexDirection:'column', width: '100%'}}>
        <FormTextArea placeholder='Reply to comment...' value={message} onChange={handleInput}/>

    <div style={{display:'flex', padding: '2%', justifyContent: 'space-around', alignSelf: 'flex-end', width: '50%'}}>
        <CustomButton1 background={hexToRgba(Theme({}).accentColor, 0.05)} onClick={async() => 
        {
            if(message?.trim()?.length > 0){ 
                toggleModal()
              modalContent(<>
              <QRCodeForm 
              body={{
              memo: message,
              sender: currentUser?.uid,
              contentId: id,
              type: type,
              parentId: commentId
            }}
            type={TRANSACTION_OPTIONS.COMMENT}
            onFail={() => {
              toggleModal()
              modalContent()
            }}
            onSuccess={() => {
              toggleModal()
              refresh(type, id)
              setMessage("")
              modalContent()
            }}
            cancelQr={() => {
              toggleModal()
              modalContent()
            }}
            heading={`User is signing the following transaction to reply to the comment by ${commenter}`}/>
              </>)
            setOpen(false)
        }
        }
        }>Reply</CustomButton1>
    <CustomButton  onClick={() => setOpen(false)}>Cancel</CustomButton></div>
    </div>
    </>
  )
}

export default ReplyForm