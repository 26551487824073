import React, { useState } from 'react'
import { SmallText } from '../../NFTCollectionCreator/NFTCollectionCreatorElements';
import { FormInput, FormLabel, FormWrapper } from '../../CustomForm/CustomFormElements';
import { DetailsText, TableData } from '../../ContentList/ContentListElements';
import { ListContainer } from '../MusicUploadsFormElements';
import ContentList from '../../ContentList';
import { CONTENT_TYPES } from '../../../utils/Enum';
import useMusic from '../../../hooks/useMusic';
import { ChipList } from '../../Chip/ChipElements';
import Chip from '../../Chip';
import { useModal } from '../../Modal/ModalContext';
import { PodcasterGenreSearchModal } from '../../SearchModal';

const GenreSelector = ({selectedGenres, addGenre, removeGenre, genreError}) => {
    const { searchPodcastGenres} = useMusic();
    const [filteredGenres, setFilteredGenres] = useState([]);
    const [search, setSearch] = useState("");
    const [isLoaded, setLoaded] = useState(false);
    const {modalContent, openModal} = useModal()

  
    const handleInput = (e) => {
      setSearch(e.target.value);
      if (e.target.value?.trim()?.length > 0) {
            searchPodcastGenres(e.target.value)
              .then((genres) => {
                if (genres?.length > 0) {
                    let filter = genres.filter(genre => selectedGenres.some(sGenres => sGenres?.id !== genre?.id))
                    setFilteredGenres(genres);
                } else {
                    //console.log('none')
                    setFilteredGenres([])
                  return;
                }
              })
              .catch((err) => {
                setFilteredGenres([])
                console.error(err);
                return;
              });
           
        return
      }
      setFilteredGenres([])
    };
  
    const selectGenre = (genre) => {
          setFilteredGenres([])
          addGenre(genre)
          setSearch('')
    }
  
    const displaySearch = () => {
      return (
          <>
          {filteredGenres && search?.length > 0 &&
                  <FormWrapper>
                    <ListContainer>
                      <ContentList
                        type={CONTENT_TYPES.GENRE}
                        content={filteredGenres}
                        onButtonClick={selectGenre}
                      />
                    </ListContainer>
                  </FormWrapper>
                }
            </>
  )
    };
  
    return (
      
       
        <FormWrapper>
          <FormLabel>Genres/Topics</FormLabel>
          {selectedGenres?.length > 0 && <ChipList>{ selectedGenres.map((genre, index) => {
              return(
                  <Chip key={genre?.genreId} background={'transparent'} label={genre?.name} closeButton onDelete={() => removeGenre(index)}/>
              )
          })}</ChipList>}
            {(
              <FormInput
                onChange={handleInput}
                value={search}
                readOnly={true}
                onClick={() => {
                  openModal()
                  modalContent(<PodcasterGenreSearchModal existingGenres={selectedGenres} onClick={selectGenre}/>)
                }}
                placeholder={`Search for genres in episode`}
              />
            )}
                  <SmallText>
          Select the genres/topics that is included within the podcast episode.
        </SmallText>
                  {genreError && <SmallText error>
          {genreError}
        </SmallText>}
         
            { filteredGenres?.length > 0 && displaySearch()}
          
        </FormWrapper>
    );
}

export default GenreSelector