import styled from "styled-components";
import React from 'react'
import { hexToRgba } from "./HexToRGBA";

const ProgressBarWrapper = styled.div`
box-shadow: 0 0 15px ${(props) => hexToRgba(props.theme.secondaryColor, 0.5)};
border-radius: 15px;
background: ${(props) => hexToRgba(props.theme.mainBg, 0.6)};
overflow: hidden;
margin-bottom: 2%;
color: ${(props) => props.theme.secondaryBg};
animation-fill-mode: forwards;
`;

export const ProgressBarMessageContainer = styled.div`
  display: flex;
  padding: 3%;
  justify-content: center;
`

export const ProgressBarMessage = styled.span`
  display: flex;
`;

export const ExpirationBar = styled.div`
    height: 12px;
    bottom: 0;
    border-radius: 0px 15px 15px 0px;
    width: ${props => props.width}%;
    background-color: ${props => props.type};
  `;




const ProgressBar = ({width, message, color,}) => {
  return (
    <ProgressBarWrapper color={color}>
    <ProgressBarMessageContainer>
    <ProgressBarMessage>{message}</ProgressBarMessage>
    </ProgressBarMessageContainer>
<ExpirationBar type={color} width={width}/>
</ProgressBarWrapper>
  )
}

export default ProgressBar