import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Footer from '../../../components/Footer'
import Navbar from '../../../components/Navbar'
import {Home, Welcome } from '../../../components/Navbar/Data'
import Sidebar from '../../../components/Sidebar'
import {RecordLabelProfile as Profile} from '../../../components/RecordLabelProfile'


const RecordLabelProfile = () => {
    const [recordLabelProfile, setRecordLabel] = useState()
    const {recordLabel} = useParams()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}
    const navigate  = useNavigate()
    
    useEffect(() => {
        let rProfile = recordLabel
        if(!recordLabel)
        {
            navigate(-1)
        }
        setRecordLabel(recordLabel)
        return () => {}
    }, [recordLabel])



    return( 
        <>
             <Sidebar
          isOpen={isOpen}
          toggle={toggle}
          navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
        />
          <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
            <Profile recordLabel={recordLabel}/>
            
        </>
        )
    
}

export default RecordLabelProfile