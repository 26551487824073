export const Theme = ({mainColor}) => {
   return  {
    mainBg: "#050917",
    secondaryBg: "#E2DDD5",
    mainColor: mainColor ? mainColor : "#5F0994",
    secondaryColor: "#9490b6",
    accentColor: "#696dba",
    errorColor: "#ff0000",
    warningColor:"#ff9800",
    infoColor: "#2196F3",
    successColor:"#04AA6D",
    background: 'black'
}
};

export const DarkTheme = ({mainColor}) => {
   return  {
    mainBg: "#050917",
    secondaryBg: "#E2DDD5",
    mainColor: mainColor ? mainColor : "#5F0994",
    secondaryColor: "#9490b6",
    accentColor: "#696dba",
    errorColor: "#ff0000",
    warningColor:"#ff9800",
    infoColor: "#2196F3",
    successColor:"#04AA6D",
    background: 'black',
}
};
export const LightTheme = ({mainColor}) => {
   return  {
    mainBg: "#E2DDD5",
    secondaryBg: "#050917",
    mainColor: mainColor ? mainColor : "#5F0994",
    secondaryColor: "#89023E",
    accentColor: "#FF6978",
    errorColor: "#ff0000",
    warningColor:"#ff9800",
    infoColor: "#2196F3",
    successColor:"#04AA6D",
    background: 'white'
}
};

