import { collection } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
  BsArrowLeftShort,
  BsArrowRepeat,
  BsArrowRightShort,
  BsFillSkipEndFill,
  BsFillSkipStartFill,
  BsShuffle,
} from "react-icons/bs";
import { FaPause, FaPlay } from "react-icons/fa";
import { useSonarMusePlayer } from "../../../hooks/AudioHook";
import useMusic from "../../../hooks/useMusic";
import { getSongTitle, stringFormatter } from "../../../utils";
import { CONTENT_TYPES, MUSIC_PLAYING_FROM } from "../../../utils/Enum";
import ImageUtils from "../../../utils/ImageUtils";
import { Audio } from "../../AudioPlayer/AudioPlayerElements";
import { PlaylistWrapper } from "../../AudioPlayer/AudioPlayerPlaylist/AudioPlayerPlaylistElements";
import { useAudio } from "../../AudioPlayer/context/AudioContext";
import { Button1 } from "../../ButtonElement";
import CollectionAudioPlayer from "../../CollectionAudioPlayer";
import {
  CollectionSongList,
  MusicStoreSongList,
} from "../../ContentList/SongList";
import LoadingScreen, { ModalLoadingScreen } from "../../LoadingScreen";
import { MusicPlayPauseButton } from "../../MusicPage/MusicPageElements";
import { Nav, NavContainer, NavLink } from "../../NavLink/NavLinkElements";
import OptionModal from "../../OptionModal";
import RecordPlayer from "../../RecordPlayer";
import MusicStoreAudioPlayer from "./MusicStoreAudioPlayer";
import {
  AudioControlButton,
  AudioPlayPauseButton,
  ControlButtonsContainer,
  PlaylistContainer,
  PlaylistContainerWrapper,
} from "./MusicStorePlayerElements";
import VideoPlayer from "../../VideoPlayer";
import LyricSynchronizer from "../../LyricSynchronizer";
import { Form, FormH1, FormText } from "../../CustomForm/CustomFormElements";
import { hexToRgba } from "../../HexToRGBA";
import { useWebsiteTheme } from "../../WebsiteTheme/WebsiteThemeContext";

const MusicStorePlayer = ({ music, type, name }) => {
  const { defaultImg } = ImageUtils();
  const {themeColors} = useWebsiteTheme()
  const { getSongs, getAlbum } = useMusic();
  const [currentTab, setTab] = useState("default");
  const [isLoaded, setLoaded] = useState(false);
  const {
    timeJump,
    currentSong,
    isPlaying,
    duration,
    currentTime,
    progressBar,
    audioPlayer,
    changeRange,
    setTimeJump,
    volume,
    isRepeat,
    calcTime,
    backThirty,
    forwardThirty,
    isPodcast,
    isShuffle,
    nextSong,
    handleToggle,
    prevSong,
    playlist,
    setPlaylist,
    setCurrentSong,
    timeTravel,
    togglePlayPause,
    toggleMute,
    toggleVolume,
    handleEnd,
    isOpen,
    closeViewer,
    stop,
    isMute,
    openViewer,
  } = useSonarMusePlayer();

  useEffect(() => {
    loadMusic();
    return () => {}
  }, [!isLoaded]);

  const loadMusic = async() => {
    switch (type) {
      case CONTENT_TYPES.SONG:
        setPlaylist(music.map(s => {
          return {...s,
            playingFrom: {
              playingFrom: MUSIC_PLAYING_FROM.MUSIC_STORE,
              id: type,
            }}
        }));
        setCurrentSong(0);
        setLoaded(true);
        break;
      case CONTENT_TYPES.ALBUM:
        if(music?.some((song) => song?.songId)){
          setPlaylist(music?.filter((s) =>
            (!s?.isPreRelease || (s?.isPreRelease && s?.isPreview)))?.map(s => {
            return {...s,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.MUSIC_STORE,
                id: type,
              }}
          }));
          setCurrentSong(0);
          setLoaded(true);
          return
        }
        return getSongs(music)
        .then((songs) => {
          setPlaylist(songs?.filter((s) =>
            (!s?.isPreRelease || (s?.isPreRelease && s?.isPreview)))?.map(s => {
            return {...s,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.MUSIC_STORE,
                id: type,
              }}
          }));
          setCurrentSong(0);
          setLoaded(true);
        })
        .catch((err) => {});
      case CONTENT_TYPES.COLLECTION:
        let collectionSongs = []
        if(music?.songs?.length > 0){
        await getSongs(music?.songs)
          .then((songs) => {

           collectionSongs.push(...songs.map(s => {
            return {...s,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.MUSIC_STORE,
                id: type,
              }}
          }))
            
          })
          .catch((err) => {});
        }if(music?.albums?.length > 0){

          for(let i = 0; i < music?.albums?.length; i++){
            let album = await getAlbum(music?.albums[i]).then((data) => {
              return data
            })
            if(album?.songs?.length > 0){
              collectionSongs.push(...album.songs.map(s => {
                return {...s,
                  playingFrom: {
                    playingFrom: MUSIC_PLAYING_FROM.NFT,
                    id: type,
                  }}
              }))
            }
          }
        }
        if(collectionSongs?.length > 0){
        setPlaylist(collectionSongs);
        setCurrentSong(0);
        setLoaded(true);
        }else{
            isLoaded(true)
            }
        break;
    
      case CONTENT_TYPES.NFTOKEN:
        let tokenSongs = []
        if(music?.songs?.length > 0){

           tokenSongs.push(...music?.songs.map(s => {
            return {...s,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.NFT,
                id: type,
              }}
          }))

        }if(music?.albums?.length > 0){
          for(let i = 0; i < music?.albums?.length; i++){
            let album = music?.albums[i]
            if(album?.songs?.length > 0){
              tokenSongs.push(...album.songs.map(s => {
                return {...s,
                  playingFrom: {
                    playingFrom: MUSIC_PLAYING_FROM.NFT,
                    id: type,
                  }}
              }))
            }
          }
        }
        if(tokenSongs?.length > 0){
        setPlaylist(tokenSongs);
        setCurrentSong(0);
        setLoaded(true);
        }else{
            isLoaded(true)
            }
        break;
    
      default:
        break;
    }

    return;
  };

  const value = {
    timeJump,
    currentSong,
    isPlaying,
    duration,
    currentTime,
    progressBar,
    audioPlayer,
    changeRange,
    setTimeJump,
    handleEnd,
    volume,
    isRepeat,
    calcTime,
    backThirty,
    forwardThirty,
    isPodcast,
    isShuffle,
    nextSong,
    handleToggle,
    prevSong,
    playlist,
    setPlaylist,
    togglePlayPause,
    toggleMute,
    toggleVolume,
    isOpen,
    closeViewer,
    isMute,
    openViewer,
    currentTab,
    setTab
  };

  const togglePlay = () => {
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.NFT,
      id: type,
    };
    localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
    if (type === CONTENT_TYPES.SONG) {
      setCurrentSong(0);
    }
    if (isPlaying) {
      handleToggle("play-pause", false);
    } else {
      handleToggle("play");
    }
  };

  return (
    <Form style={{background:  hexToRgba(themeColors.mainBg, 0.3)}}>
      <PlaylistContainer
            src={playlist[currentSong]?.visualizer?.gifUrl ? playlist[currentSong]?.visualizer?.gifUrl : playlist[currentSong]?.albumArt
              ? playlist[currentSong]?.albumArt
              : playlist[currentSong]?.thumbnail
              ? playlist[currentSong]?.thumbnail
              : false}
          >
            <PlaylistContainerWrapper visualizer={playlist[currentSong]?.visualizer?.gifUrl && true}>
            <FormH1>{`Playing ${name}`} </FormH1>
      <div style={{justifySelf: 'center', textAlign: 'center', whiteSpace: 'wrap', marginBottom:'2%'}}>
        {isLoaded && (type ===CONTENT_TYPES.COLLECTION || type ===CONTENT_TYPES.ALBUM || type === CONTENT_TYPES.NFTOKEN) && `${getSongTitle(playlist[currentSong])}`}
        </div>
      {playlist?.length > 0 && isLoaded && (
        <>
        
          <div style={{ flexDirection: "column", marginBottom: "10%", marginTop: "2%", justifyContent: "center", alignItems: "center" }}>
            {currentTab === "default" && (
              <RecordPlayer
                onClick={() => togglePlay()}
                stop={!isPlaying}
                albumArt={
                  playlist[currentSong]?.albumArt
                    ? playlist[currentSong]?.albumArt
                    : defaultImg
                }
                alt={playlist[currentSong]?.songName}
              />
            )}
            {currentTab === "queue" && (
              <MusicStoreSongList
                type={CONTENT_TYPES.SONG}
                currentSong={currentSong}
                isPlaying={isPlaying}
                playlist={playlist}
                content={playlist}
              />
            )}
            {currentTab === "lyrics" && (
              <>{playlist[currentSong]?.syncedLyrics ? <LyricSynchronizer currentTime={audioPlayer?.current?.currentTime} song={playlist[currentSong]} timeTravel={timeTravel} togglePlayPause={togglePlayPause} isPlaying={isPlaying}/> :
              
              <PlaylistWrapper>{stringFormatter(playlist[currentSong]?.lyrics)}</PlaylistWrapper>}</>
            )}
         <MusicStoreAudioPlayer {...value} />
          </div>
         
       
          {/* <Button1 style={{ marginTop: "2%", marginBottom: "2%" }} onClick={() => togglePlay()}>
            {isPlaying ? (
              <>
                <FaPause style={{ position: "relative", left: "1%", marginRight: "0.5%" }} /> Pause
              </>
            ) : (
              <>
                <FaPlay style={{ position: "relative", left: "1%", marginRight: "0.5%" }} /> Play
              </>
            )}
          </Button1> */}
         {/*  <ControlButtonsContainer>
          {((music.length > 1) || (music?.albums?.length > 0) || (music?.songs?.length > 1)) && <AudioControlButton onClick={() => handleToggle("prev", true)}>
              <BsFillSkipStartFill />
            </AudioControlButton>}
            <AudioControlButton 
              onClick={() => togglePlay()}
            >
              {isPlaying ? (
                <FaPause />
              ) : (
                <FaPlay style={{ position: "relative", left: "1%" }} />
              )}
            </AudioControlButton >
            {((music.length > 1) || (music?.albums?.length > 0) || (music?.songs?.length > 1)) && <AudioControlButton onClick={() => handleToggle("next", true)}>
              <BsFillSkipEndFill />
            </AudioControlButton>}
          </ControlButtonsContainer> */}
          
        </>
      )}
      {!isLoaded && <ModalLoadingScreen text={"Loading Music. Please Wait..."} transparent />}
      </PlaylistContainerWrapper>
       </PlaylistContainer>
    </Form>
  );
};

export default MusicStorePlayer;
