import React from "react";
import { stringFormatter } from "../../../utils";
import {
  ErrorWrapper,
  FormInput,
  FormLabel,
  FormTextArea,
  FormWrapper,
  SelectButton,
  SongDetailsSection,
  SongDetailsSectionTitle,
  SongDetailsText,
} from "../MusicUploadsFormElements";

const Page3 = ({
  isStreamable,
  isDownloadable,
  isPreOrder,
  isMintable,
  isrcType,
  isrcRef,
  handleInput,
  isrc,
  errors,
  aboutRef,
  lyricsRef,
  releaseDateRef,
  about,
  lyrics,
  releaseDate,
  index
}) => {
  return (
    <SongDetailsSection>
      <SongDetailsSectionTitle>Additional Information</SongDetailsSectionTitle>
      {(isStreamable || isDownloadable || isMintable) && (
        <FormWrapper>
          <FormLabel>
            ISRC Code
            <SelectButton
              name={"isrcType"}
              value={isrcType}
              onChange={handleInput}
            >
              <option value={"auto"}>Auto generate</option>
              <option value={"custom"}>Custom</option>
            </SelectButton>{" "}
          </FormLabel>
          <ErrorWrapper>
            <FormInput
              ref={isrcRef}
              readOnly={isrcType === "auto"}
              type={"text"}
              name={"isrc "+ index}
              placeholder={"Enter your unique ISRC code for the song"}
              value={isrc}
              onContextMenu={(e) =>
                isrcType === "auto" ? e.preventDefault() : {}
              }
              onChange={(e) => {
                handleInput(e);
              }}
            />
            {errors?.isrc && (
              <SongDetailsText error>{errors?.isrc}</SongDetailsText>
            )}
          </ErrorWrapper>
        </FormWrapper>
      )}
      <FormWrapper>
        <FormLabel>Release Date</FormLabel>
        <ErrorWrapper>
          <FormInput
            ref={releaseDateRef}
            type="date"
            min={isPreOrder && new Date(new Date().setDate( new Date().getDate() + 1)).toISOString().split("T")[0]}
            name={"releaseDateSong " + index}
            value={releaseDate}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          {errors?.releaseDate && (
            <SongDetailsText error>{errors?.releaseDate}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
      <FormWrapper>
        <FormLabel>About Song</FormLabel>
        <ErrorWrapper>
          <FormTextArea
            ref={aboutRef}
            name={"about " + index}
            value={stringFormatter(about)}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          {errors?.about && (
            <SongDetailsText error>{errors?.about}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
      <FormWrapper>
        <FormLabel>Song Lyrics</FormLabel>
        <ErrorWrapper>
          <FormTextArea
            ref={lyricsRef}
            name={"lyrics " + index}
            value={stringFormatter(lyrics)}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          {errors?.lyrics && (
            <SongDetailsText error>{errors?.lyrics}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
    </SongDetailsSection>
  );
};

export default Page3;
