import React, { useState } from 'react'
import ContentWrapper from '../ContentWrapper'
import { FormText, FormWrapper } from '../CustomForm/CustomFormElements'
import ToggleButton from '../ToggleButton'
import { FaChartBar, FaChartLine } from 'react-icons/fa'
import LineGraph from './Line'
import BarGraph from './Bar'
import { useWebsiteTheme } from '../WebsiteTheme/WebsiteThemeContext'

const ChartToggle = ({type, data }) => {
    const {themeColors} = useWebsiteTheme()
    const [toggle, setToggle] = useState(type ? type : 'line')
  return (
    
      
    <FormWrapper>
          <div style={{display:'flex', alignSelf: 'flex-end', padding: '2%'}}>
        <ToggleButton toggle={toggle === 'line'} toggleClick={() => toggle === 'line' ? setToggle('bar') : setToggle('line') } onText={''} customOffIcon={<FaChartBar size={20} />} customOnIcon={<FaChartLine size={20} />} offText={''}/>
    </div>
                <FormText>{data.heading}</FormText>
    {toggle === 'line' && <LineGraph data={data}/>}
    {toggle === 'bar' && <BarGraph data={data}/>}
    
    
    </FormWrapper>

  )
}

export default ChartToggle