import React, { useCallback, useEffect, useRef, useState } from 'react'
import ContentWrapper from '../ContentWrapper'
import { Slider } from '../Slider'
import { SLIDER_TYPES } from '../../utils/Enum'
import { ModalLoadingScreen } from '../LoadingScreen'
import Modal from '../Modal'
import { useArtistAppearencesSearch } from '../../hooks/useSearch'
import useArtist from '../../hooks/useArtist'

const ArtistAppearences = ({artistId}) => {
    const [pageNumber, setPageNumber] = useState("all")
    const {getArtistName, getArtistProfile} = useArtist({})
    const [artistName, setArtistName] = useState()
    const {loading, error, appearences, pages, setPages} = useArtistAppearencesSearch({pageNumber: pageNumber, artistId: artistId})
  
    const observer = useRef()
    const lastAppearenceElementRef = /* useCallback( node => {
      if(loading){
        return
      }
      if(observer.current){ 
        observer.current.disconnect()}
        observer.current = new IntersectionObserver(entries=> {
          if(entries[0].isIntersecting && (pageNumber < parseInt(pages/10))){
                setPageNumber(prev => prev + 1)
  
          }
      }) 
      
      if(node) observer.current.observe(node)
     
    }, [loading, pages, pageNumber]) */
      
    useEffect(() => {
        
    }, [artistId]);
      
    return (
      <>
      <ContentWrapper heading={"Artist Appearences"}>
      {
        !loading && appearences?.length > 0 && (
          
            <Slider
              type={SLIDER_TYPES.SONG}
              heightScroll
              slides={appearences}
              cardRef={lastAppearenceElementRef}
              id={"artist appearences"}
            />
         
        )
        /*         <PageIndexer/>
         */
      }
      {
        loading && appearences?.length > 0 && (
          <>
            <Slider
              type={SLIDER_TYPES.SONG}
              heightScroll
              slides={appearences}
              id={"artist appearences"}
            />
        <Modal isOpen={loading}><ModalLoadingScreen text={'Loading. Please Wait'}/></Modal>
  </>
        )
        /*         <PageIndexer/>
         */
      }
      {loading && appearences?.length === 0 && (
        <>
             <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        </>
      )}
      {!loading && appearences?.length === 0 && <ContentWrapper heading={"No Appearences Found"}>
          
          </ContentWrapper>}
          </ContentWrapper>
    </>
    )
}

export default ArtistAppearences