import React, { useState } from 'react'
import { SkeletonMusicCard } from '../../Skeleton/SkeletonElements';
import { CONTENT_TYPES } from '../../../utils/Enum';
import ScrollToTop from '../../ScrollToTop';
import { CardTitle } from '../CardElements';
import { getRandomColor } from '../../HexToRGBA';
import { CardContent, CardInfoContent, CardInfoTitle, H6, TextLink } from './GenreCardElements';

const GenreCard = ({content, cardRef}) => {
    const {goTo, scrollToTop} = ScrollToTop()
    const [color, setColor] = useState(content?.color ? content?.color : getRandomColor() )

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
        <SkeletonMusicCard
        ref={cardRef}
          type={CONTENT_TYPES.GENRE}
          id={content?.id}
          color={color}
          onClick={() => content?.genreId ? goTo(`/subgenre/${content?.id}`) : content?.id ? goTo(`/genre/${content?.id}`): {}}
        >
            <CardContent>
            <CardTitle type={CONTENT_TYPES.GENRE}>{content?.name}</CardTitle>
</CardContent>
        </SkeletonMusicCard>
        {content?.genreId && <CardInfoContent>
            <H6>Sub genre</H6>
            <CardInfoTitle>
                <TextLink>View Main Genre</TextLink>
            </CardInfoTitle>
        </CardInfoContent>}
    </div>
      );
}

export default GenreCard