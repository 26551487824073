import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from '../../components/Footer'
import MusicDownloader from '../../components/MusicDownloader'
import Navbar from '../../components/Navbar'
import { Home, Welcome } from '../../components/Navbar/Data'
import Sidebar from '../../components/Sidebar'

const MusicDownloads = () => {
    const {id} = useParams()
    const {type} = useParams()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}
    const navigate  = useNavigate()
  return (
    <>
            <Sidebar
          isOpen={isOpen}
          toggle={toggle}
          navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
        />
          <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
          <MusicDownloader id={id} type={type}/>
          
      </>
  )
}

export default MusicDownloads