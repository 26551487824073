import styled from "styled-components";

export const ArtistProfileDiv = styled.div`
    min-height: 100vh;
    background: ${props => props?.theme?.background ? props?.theme?.background : 'black'};
    padding: 0% 2%;
    width: 100%;
`

export const ArtistProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    filter: drop-shadow(1px 2px 2px rgba(15,15,15,0.76));

`