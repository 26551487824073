//import mic from '../../images/mic.png'
import music from '../../images/music.svg'
import pay from '../../images/crypto_pay.png'

export const homeObj1 = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'A New Music Platform',
    headLine: 'Streaming and NFT Music Purchases all in One Place ',
    description: 'Stream and buy music on the XRPL, through our streaming platform and digital music store.',
    imgStart: false,
    img: music,
    alt: 'A New Music Platform',
    dark: true,
    primary: true,
    darkText: false,
    isCircular: false
}
export const homeObj2 = {
    id: 'services',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'The New Take On Music and The Digital Ownership',
    headLine: 'Creating a Community for Artists and Fans ',
    description: 'Providing a Platform for artists to showcase their work and get compensated directly from their fans ',
    imgStart: true,
    img: pay,
    alt: '',
    buttonLabel: 'Learn More',
    to: '#about',
    dark: false,
    primary: false,
    darkText: true,
    isCircular: false
}
export const homeObj3 = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Join Our Platform',
    headLine: 'Creating an account is fast and easy',
    description: 'Get everything set up and ready by signing in with your Xumm Wallet ',
    buttonLabel: 'Sign Up Now',
    to:'/',
    imgStart: false,
    img: 'https://pbs.twimg.com/profile_images/1280832346611167236/1sQA2Dn9_400x400.jpg',
    alt: 'mic',
    dark: false,
    primary: false,
    darkText: true,
    isCircular: false
}

