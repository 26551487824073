import React from 'react'
import AlbumArt from '../AlbumArt'
import { useModal } from '../Modal/ModalContext';

const NFTCoverArtSelector = ({music, setCoverArt, coverArt}) => {
    const { toggleModal, modalContent, isOpen } = useModal();

  return (
    <>
        {music.map(m => {
            return (
                <>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{cursor: "pointer"}} onClick={()=> {
                    setCoverArt({url: m?.albumArt, file: {}, progress: 100})
                    toggleModal()
                    modalContent()
                    //console.log(coverArt)
                    }}><AlbumArt albumArt={m?.albumArt} />
                    </div>
                </div>
                </>
            )
        })}
    </>
  )
}

export default NFTCoverArtSelector