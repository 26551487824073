import React from "react";
import { useState } from "react";
import {
  BsArrowLeftShort,
  BsArrowRepeat,
  BsArrowRightShort,
  BsFillSkipEndFill,
  BsFillSkipStartFill,
  BsShuffle,
} from "react-icons/bs";
import {
  FaPause,
  FaPlay,
  FaVolumeDown,
  FaVolumeMute,
  FaVolumeUp,
} from "react-icons/fa";
import { MdExplicit } from "react-icons/md";
import { RiPlayList2Fill } from "react-icons/ri";
import Marquee from "react-text-marquee";
import { convertDuration, getArtistsNames, getSongTitle } from "../../utils";
import { ARTIST_SEARCH_TYPES } from "../../utils/Enum";
import ImageUtils from "../../utils/ImageUtils";
import ScrollToTop from "../ScrollToTop";
import ToggleButton from "../ToggleButton";
import {
  ArtistDetailsContainer,
  ArtistDetailsText,
  ArtistImgContainer,
  ArtistInfoContainer,
  Audio,
  AudioPlayerContainer,
  AudioPlayerControls,
  AudioPlayerControlsWrapper,
  AudioPlayerDetailsWrapper,
  AudioPlayerDurationTimerWrapper,
  AudioPlayerSlider,
  AudioPlayerSliderWrapper,
  AudioPlayerTimerText,
  AudioPlayerVolumeSlider,
  AudioPlayerWrapper,
  AudioText,
  Img,
  ProgressBarWrapper,
  VolumeControlsWrapper,
  WidgetsContainer,
} from "./CollectionAudioPlayerElements";

const CollectionAudioPlayer = ({
  timeJump,
  currentSong,
  isPlaying,
  duration,
  currentTime,
  progressBar,
  audioPlayer,
  changeRange,
  setTimeJump,
  stopMainPlayer,
  volume,
  isRepeat,
  calcTime,
  backThirty,
  forwardThirty,
  isPodcast,
  isShuffle,
  nextSong,
  handleToggle,
  prevSong,
  playlist,
  setPlaylist,
  togglePlayPause,
  toggleMute,
  toggleVolume,
  isOpen,
  closeViewer,
  isMute,
  openViewer,
  handleEnd,
}) => {
  const { defaultAlbumArt } = ImageUtils();
  const { goTo } = ScrollToTop();
  const [isCountdown, setCoundown] = useState(false);

  return (
    <AudioPlayerContainer>
      <ArtistInfoContainer>
        <ArtistDetailsContainer>
          <ArtistDetailsText
            onClick={
              playlist[currentSong]?.songId
                ? () => {
                    goTo(`/song/${playlist[currentSong]?.songId}`);
                  }
                : {}
            }
          >
            {playlist[currentSong] ? (
              getSongTitle(playlist[currentSong])?.length > 30 ? (
                <Marquee text={getSongTitle(playlist[currentSong])} />
              ) : (
                getSongTitle(playlist[currentSong])
              )
            ) : (
              ""
            )}
            {playlist[currentSong]?.explicit && <MdExplicit />}
          </ArtistDetailsText>
          <ArtistDetailsText
            onClick={() =>
              playlist[currentSong] &&
              playlist[currentSong]?.artists?.length > 0
                ? () => {
                    goTo(
                      `/artist/${playlist[currentSong]?.artists[0].artistId}`
                    );
                  }
                : {}
            }
          >
            <small>
              {playlist[currentSong] &&
                getArtistsNames(
                  playlist[currentSong]?.artists,
                  ARTIST_SEARCH_TYPES.NAME
                )}
            </small>
          </ArtistDetailsText>
        </ArtistDetailsContainer>
      </ArtistInfoContainer>
      <AudioPlayerControlsWrapper>
        <AudioPlayerControls
          onClick={() => {
            handleToggle("prev", true);
          }}
        >
          {isPodcast ? (
            <>
              <BsArrowLeftShort /> 30
            </>
          ) : (
            <>
              <BsFillSkipStartFill />
            </>
          )}
        </AudioPlayerControls>
        <AudioPlayerControls
          onClick={() => {
            stopMainPlayer();
            handleToggle("play-pause", !isPlaying);
          }}
        >
          {isPlaying ? <FaPause /> : <FaPlay />}
        </AudioPlayerControls>
        <AudioPlayerControls
          onClick={() => {
            handleToggle("next", true);
          }}
        >
          {isPodcast ? (
            <>
              30 <BsArrowRightShort />
            </>
          ) : (
            <>
              <BsFillSkipEndFill />
            </>
          )}
        </AudioPlayerControls>
        <VolumeControlsWrapper>
          <AudioPlayerControls onClick={toggleMute}>
            {isMute || volume === 0 ? (
              <FaVolumeMute />
            ) : volume * 100 < 50 ? (
              <FaVolumeDown />
            ) : (
              <FaVolumeUp />
            )}
          </AudioPlayerControls>
          <AudioPlayerVolumeSlider
            type="range"
            volume={isMute ? 0 : `${volume * 58.5}px`}
            value={isMute ? 0 : volume * 100}
            onChange={toggleVolume}
          />
        </VolumeControlsWrapper>
      </AudioPlayerControlsWrapper>

      <Audio
        ref={audioPlayer}
        src={
          playlist[currentSong]?.mp3Url
            ? playlist[currentSong]?.mp3Url
            : playlist[currentSong]?.url
        }
        loop={isRepeat}
        preload={"auto"}
        volume={volume}
        muted={isMute || volume === 0}
        onEnded={handleEnd}
      />
      {playlist.length > 0 && (
        <WidgetsContainer>
          {/*current time*/}
          <AudioText style={{ marginLeft: "2%" }}>
            {currentTime && !isNaN(currentTime) && calcTime(currentTime)}
          </AudioText>
          {/*progress bar*/}
          <ProgressBarWrapper>
            <AudioPlayerSlider
             progressWidth={`${(currentTime / duration) * 100}%`}
              value={currentTime}
                        max={duration}
              type="range"
              defaultValue={"0"}
              ref={progressBar}
              onChange={changeRange}
            />
          </ProgressBarWrapper>

          {/*Duration*/}
          <AudioText>
            {playlist[currentSong]?.duration
              ? convertDuration(playlist[currentSong].duration)
              : duration && !isNaN(duration)
              ? convertDuration(duration)
              : '0:00'}
          </AudioText>
        </WidgetsContainer>
      )}
    </AudioPlayerContainer>
  );
};

export default CollectionAudioPlayer;
