import React,{useState} from "react";
import { stringFormatter } from "../../../utils";
import { ArtistDetailsContainer } from "../../AudioPlayer/AudioPlayerElements";
import ImageSlider from "../../ImageSlider";
import SocialLinks from "../../SocialLinks";
import { ArtistProfileContainer } from "../ArtistProfileElements";
import { ArtistContentHeading, ArtistContentHeadingWrapper, ArtistContentWrapper } from "./ArtistContentElements";


export default function ArtistAbout({artist, images, bio, tourDates, socialLinks }){
    return(
        <>
        <ArtistContentWrapper>
        {socialLinks &&<ArtistContentHeadingWrapper style={{flexDirection: 'row', justifyContent:'flex-start'}}> <SocialLinks accounts={socialLinks}/></ArtistContentHeadingWrapper>}
        {(bio || artist?.artistSettings?.bio) && <div style={{color:'white', whiteSpace: "pre-wrap"}}>{artist?.bio ? artist?.artistSettings?.bioType === "custom" ? stringFormatter(artist.bio): bio : bio }</div>}
        {(images?.length > 0 && artist?.artistSettings?.showGallery) && <ImageSlider images={images}/>}
        
        {(tourDates && artist?.artistSettings?.showTrips) && <>{tourDates}</>}</ArtistContentWrapper>
        
        </>
    )
}