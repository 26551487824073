  import React, { useEffect, useState } from 'react'
  import Navbar from '../Navbar'
  import { Container, VideoBg, ContentWrapper } from '../SignUp/SignUpElements'
  import { Access } from '../Navbar/Data'
  import Footer from '../Footer'
  import { VideoUtils } from '../../utils/ImageUtils'
  import { CURRENCIES } from '../../utils/Enum'
  import { HoverOption } from '../MenuOptions/MenuOptionsElements'
  import OptionModal from '../OptionModal'
  import { CustomButton } from '../ButtonElement'
  import TrustlineCreate from '../TrustlineCreate'
  import { useLocation, useNavigate } from 'react-router-dom'

  const SupportedTokens = () => {
      const {video1} = VideoUtils()
      const [token, setToken] = useState()
      const location = useLocation()
      const navigate  = useNavigate()
    

      useEffect(() => {
        if(window?.location?.search.includes(`token=`)){
          const url = new URL(window.location.href);
          const params = new URLSearchParams(url.search)
          setToken(params.get("token"))
        }else{
          selectToken()
        }
        return () => {}
      }, []);

    const selectToken = (t) => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search)
      if(t?.length > 0){
        params.set("token", t)
        setToken(t)
      }else{
        params.delete("token")
        setToken()
      }
      navigate(`${location.pathname}${params.toString().length > 0 ? `?${params.toString()}` : ''}`,{
        replace:true},
      );
      }

    return (
      <>
      <Navbar navInfo={Access} />

      <Container>
      <VideoBg autoPlay loop muted src={video1}
            type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}>
        </VideoBg>
        <ContentWrapper>
          {!token && <OptionModal onCancel={() => {navigate('/', {replace:true})}} heading={"Supported Trustlines/Tokens"} additionalText={"The following are tokens that Sonar Muse supports on the platform. Select token to add trustline."}>
          <br/>
          {Object.keys(CURRENCIES).map(c => {
              if(c === CURRENCIES.WYM || c === CURRENCIES.XRP || c === CURRENCIES.RLUSD ){
                if(c === CURRENCIES.WYM){
                return (<HoverOption onClick={() => {selectToken(c)}}>{c} (Sonar Muse)</HoverOption>)}
                if(c === CURRENCIES.RLUSD){
                  return (<HoverOption onClick={() => {selectToken(c)}}>{c} (Ripple Labs)</HoverOption>)}
            
              
                else{
                  return (<CustomButton onClick={() => selectToken()}>{c}</CustomButton>)
                }
              
            }
            if(c === CURRENCIES.AUD || c === CURRENCIES.JPY || c === CURRENCIES.CHF){
              return (<HoverOption onClick={() => {
                selectToken(c)
              }}> {c} (Bitstamp)</HoverOption>)
            }
            if(c === CURRENCIES.ZAR){
              return (<HoverOption onClick={() => {
                selectToken(c)
              }}> {c} (Xago)</HoverOption>)
            }else{
              return (<HoverOption onClick={() => {
                selectToken(c)
              }}> {c} (Gatehub)</HoverOption>) 
            }
          })}</OptionModal>}
          {token && <TrustlineCreate token={token} onCancel={() => {
            selectToken()
          }}/>}
      </ContentWrapper>
      </Container>
      
      </>
    )
  }

  export default SupportedTokens