import React, { useEffect, useState } from "react";
import axios from "../Authorization/client";
import WebSocket from "react-websocket";
import LoadingScreen from "../LoadingScreen/index";
import Navbar from "../Navbar";
import { Access } from "../Navbar/Data";
import { Theme } from "../WebsiteThemeElements";
import {
  Container,
  ContentWrapper,
  Form,
  FormButton,
  FormContent,
  FormH1,
  FormWrap,
  QrCode,
  QrWrapper,
  Text,
  TextLink,
  VideoBg,
} from "../SignUp/SignUpElements";
import {
  FormButton1,
  FormContainer,
  FormContent1,
  FormInput,
  FormInput1,
  FormLabel,
  FormText,
} from "../CustomForm/CustomFormElements";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { emailRegex } from "../../utils/Regex";
import { VideoUtils } from "../../utils/ImageUtils";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import { useAlert } from "../Alert/AlertContext";
import { ALERT_TYPES } from "../../utils/Enum";
import { getSignTxStatus } from "../../utils/dbUtils/signTxUtils";
import { getObjectLength } from "../../utils";

const EmailAccountSet = () => {
  const {video1} = VideoUtils()
  const {currentUser} = useAuth()
  const navigate  = useNavigate()
  const {goTo} = ScrollToTop()
  const {addAlert} = useAlert()
  const [errMsg, setError] = useState();
  const [msg, setMessage] = useState("")
  const [emailErr, setEmailErr] = useState("");
  const [invoice, setInvoice] = useState();
  const [status, setStatus] = useState("idle");
  const [response, setResponse] = useState({})  
  const [isLoaded, setLoaded] = useState(false);
  const [isOpened, setOpened] = useState(false);
  const [payload, setPayload] = useState();
  const [isSigned, setSigned] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [next, setNext] = useState(false);

  useEffect(() => {
    if(invoice){
          getSignTxStatus(invoice, status, setStatus, setResponse)

    }
    return () => {
    }
  }, [invoice])
  
  useEffect(() => {
    if(invoice){
      if(status === "success" && response !== false){
        setLoaded(false);
        setSigned(true);
        setPayload();
        addAlert({
          title: "Email Account Set",
          type: ALERT_TYPES.SUCCESS,
          message: `The email hash has been set successfully.`,
        });
        setError("The email hash has been set successfully.");
        setOpened(false);
    }if(status === "error"){
      setLoaded(false);
      setPayload();
      addAlert({
        title: "Email Account Set",
        type: ALERT_TYPES.DANGER,
        message: `The transaction failed. Reload the page and try again.`,
      });
      setError(
        "The transaction failed. Reload the page and try again"
      );
      setOpened(false);
    }}
    return () => {

    }
  }, [status, invoice])

  useEffect(() => {
    if(currentUser?.uid){
      navigate( '/home')
    }
    return () => {}
  }, [])
  

const setAccount = async () => {
    axios
      .post("/api/setEmailHash", {
        email: emailAddress,
        userToken: localStorage.getItem("userToken"),
      })
      .then((res) => {
        setPayload(res.data.payload);
        setLoaded(true);
      })
      .catch((err) => {
        //console.log(err);
        setError("Oops! Something wrong happened. Reload the page");
        setLoaded();
      });
  };

  const updateSignInStatus = async (messageData) => {
    //console.log("Message Data: ", messageData);
    const data = JSON.parse(messageData);
    if (data.opened === true) {
      setOpened(true);
      setMessage("Wating for you to sign the transaction");
    }
    if (isOpened === true && data.signed === true) {
      setLoaded(false);
      setSigned(true);
      setPayload();
      addAlert({
        title: "Email Account Set",
        type: ALERT_TYPES.SUCCESS,
        message: `The email hash has been set successfully.`,
      });
      setError("The email hash has been set successfully.");
      setOpened(false); //console.log("Signed")
    } else if (isOpened === true && data.signed === false) {
      setLoaded(false);
      setPayload();
      addAlert({
        title: "Email Account Set",
        type: ALERT_TYPES.DANGER,
        message: `The transaction was rejected. Reload the page and try again.`,
      });
      setError(
        "The transaction was rejected. Reload the page and try again"
      );
      setOpened(false);
    }
    if(data?.signed === false) {
      setLoaded(false);
      setPayload();
      addAlert({
        title: "Email Account Set",
        type: ALERT_TYPES.DANGER,
        message: `The transaction was rejected. Reload the page and try again.`,
      });
      setError(
        "The transaction was rejected. Reload the page and try again"
      );
      setOpened(false) 
    }
  };

  const emailForm = () => {
    return (
      <>
        <FormWrap>
          <FormContent>
            <Form>
              <FormH1> Account Set (EmailHash)</FormH1>
              <FormLabel>Email Address</FormLabel>
              <FormInput
                placeholder={"Enter Email Address"}
                value={emailAddress}
                onChange={(e) => {
                  setEmailErr("");
                  setEmailAddress(e.target.value);
                }}
              />
              <FormText>{emailErr.length > 0 && emailErr}</FormText>
              <FormButton
                style={{ marginTop: "2%" }}
                transparent={false}
                to={"#"}
                onClick={() => {
                  if (emailAddress?.trim()?.length > 0) {
                    if (emailRegex.test(emailAddress)) {
                      setNext(true);
                      setAccount(emailAddress);
                      return;
                    } 
                      setEmailErr("Invalid email address");
                      return
                  }
                  setEmailErr("Email Address cannot be empty");
                  return
                }}
              >
                Confirm
              </FormButton>
              <FormButton
               style={{
                marginTop: "2%",
                color: Theme({}).errorColor,
                border: "1px solid " + Theme({}).errorColor,
                background: "transparent",
              }}
                transparent={false}
                to={"#"}
                onClick={() => {
                  setNext(true);
                  setAccount("");
                }}
              >
                Delete EmailHash
              </FormButton>
              <FormButton transparent to="/">
                Cancel
              </FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </>
    );
  };

  const validateTx = async() => {
    setLoaded(false)
    return await axios
          .post("/api/verifyTxPayload", { uuid: payload?.uuid }).then((verification) => {
            //console.log(verification)
            if(verification?.data && !verification?.data?.error)
            {
              setLoaded(false);
              //dispatch(verification?.data);
              getSignTxStatus(invoice, status, setStatus, setResponse)
              setPayload();
              setError("The transaction went through successfully.");
              setOpened(false); //console.log("Signed")
              return
            }else{
              setLoaded(true)
              setError("The transaction was not signed.");
              return
            }
          }).catch(err => {
            console.error(err)
            setLoaded(true)
            setError("Something went wrong. Try again");
          }) 
       
  }

  const refreshPage = () => {
    setPayload();
    setInvoice()
  setStatus("idle")
  setResponse()
    setError("");
    setOpened(false);
    setLoaded(false);
  };

  //console.log("Websocket: ",payload.refs.websocket_status)
  return (
    <>
      <Navbar navInfo={Access} />

      <Container>
      <VideoBg autoPlay loop muted src={video1}
                    type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}>
                </VideoBg>
                <ContentWrapper>
        <>
          {!next && emailForm()}
          {next && isLoaded === true && getObjectLength(payload) > 0 && (
            <>
              <WebSocket
                url={payload.refs.websocket_status}
                onMessage={(data) => updateSignInStatus(data)}
              />
              <FormWrap>
                <FormContent>
                  <Form action={(e) => e.preventDefault}>
                    <FormH1> Account Set (EmailHash) </FormH1>
                    <QrWrapper>
                      <QrCode src={payload.refs.qr_png} />
                    </QrWrapper>
                    {isOpened === false && (
                      <Text>
                        Scan QR code or click{" "}
                        <TextLink
                          href={payload.next.always}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here
                        </TextLink>{" "}
                        to go to your Xaman wallet
                      </Text>
                    )}
                    {isOpened === true && (
                      <Text>
                        <Text>{msg}</Text>
                      </Text>
                    )}
                     <FormButton1 onClick={() => validateTx()} transparent>
                      I Already Signed Transaction
                    </FormButton1>
                    <FormButton
                      transparent={false}
                      to="/setEmailHash"
                      onClick={refreshPage}
                    >
                      Reload
                    </FormButton>
                    <FormButton transparent to="/">
                      Cancel
                    </FormButton>
                  </Form>
                </FormContent>
              </FormWrap>
            </>
          )}
          {next && errMsg && (
            <FormWrap>
              <FormContent>
                <Form>
                  <Text>{errMsg}</Text>
                  {!isSigned && (
                    <FormButton
                      transparent={true}
                      to="/setEmailHash"
                      onClick={refreshPage}
                    >
                      Reload
                    </FormButton>
                  )}
                  {isSigned && (
                    <><FormButton transparent={false} to="/access/signup">
                      Sign Up
                    </FormButton>
                    <FormButton transparent={false} to="/">
                      Go Home
                    </FormButton>
                    </>
                  )}
                </Form>
              </FormContent>
            </FormWrap>
          )}
          {next && !errMsg && !isLoaded && (
            <LoadingScreen isLoaded={isLoaded} transparent={true} />
          )}
        </>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default EmailAccountSet;
