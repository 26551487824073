import React, { useState } from 'react'
import Access from '../../components/Access';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import { Home, Welcome} from '../../components/Navbar/Data';
import Notifications from '../../components/Notifications';
import Searchbar from '../../components/Searchbar'
import Sidebar from '../../components/Sidebar';
import { useAuth } from '../../contexts/AuthContext';

function Search() {
    const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
  const [isNOpen, setIsNOpen] = useState(false);
	const toggleN = () => {//console.log('toggle')
  setIsOpen(!isNOpen)};
	const {currentUser} = useAuth()

  return (      
    <>
        <Sidebar
            isOpen={isOpen}
            toggle={toggle}
            navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
        <Navbar toggleN={toggleN} toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome} />

      <Searchbar/>
      
    </>
  )
}

export default Search