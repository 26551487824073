import React, { useCallback, useRef, useState } from 'react'
import ContentWrapper from '../ContentWrapper'
import { Slider } from '../Slider'
import { SLIDER_TYPES } from '../../utils/Enum'
import { ModalLoadingScreen } from '../LoadingScreen'
import Modal from '../Modal'
import { useSimilarAlbumsSearch } from '../../hooks/useSearch'

const SimilarAlbums = ({albumId}) => {
    const [pageNumber, setPageNumber] = useState(0)

    const {loading, error, albums, pages, setPages} = useSimilarAlbumsSearch({pageNumber: pageNumber, albumId})
  
    const observer = useRef()
    const lastAlbumElementRef = useCallback( node => {
      if(loading){
        return
      }
      if(observer.current){ 
        observer.current.disconnect()}
        observer.current = new IntersectionObserver(entries=> {
          if(entries[0].isIntersecting && (pageNumber < parseInt(pages/10))){
                setPageNumber(prev => prev + 1)
  
          }
      }) 
      
      if(node) observer.current.observe(node)
     
    }, [loading, pages, pageNumber])
  
    /*useEffect(() => {
      
       getLatestAlbums("all")
        .then((data) => {
          if (data) {
            setAlbums(data);
            setLoaded(true);
          }
        })
        .catch((err) => {setLoaded(true)});
        return () => {} 
    }, [loading, pageNumber]);*/
  
    return (
      <>
        {
          !loading && albums?.length > 0 && (
            <ContentWrapper heading={"Similar Albums"}>
              <Slider
                type={SLIDER_TYPES.ALBUM}
                heightScroll
                slides={albums}
                cardRef={lastAlbumElementRef}
                id={"similar albums"}
              />
            </ContentWrapper>
          )
          /*         <PageIndexer/>
           */
        }
        {
          loading && albums?.length > 0 && (
           <ContentWrapper heading={"Similar Albums"}>
              <Slider
                type={SLIDER_TYPES.ALBUM}
                heightScroll
                slides={albums}
                id={"similar albums"}
              />
                      <Modal isOpen={loading}><ModalLoadingScreen text={'Loading. Please Wait'}/></Modal>
  
            </ContentWrapper>
          )
          /*         <PageIndexer/>
           */
        }
        {loading && albums?.length === 0 && (
          <ContentWrapper heading={"Similar Albums"}>
               <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          </ContentWrapper>
        )}
        {!loading && albums?.length === 0 && <ContentWrapper heading={"No Albums Found"}>
            
            </ContentWrapper>}
      </>
    );
}

export default SimilarAlbums