import React, { useCallback, useEffect, useRef, useState } from 'react'
import ContentWrapper from '../ContentWrapper'
import { Slider } from '../Slider'
import { ARTIST_SEARCH_TYPES, SLIDER_TYPES } from '../../utils/Enum'
import { ModalLoadingScreen } from '../LoadingScreen'
import Modal from '../Modal'
import { getAddressName } from '../../utils/nfTokensUploads'
import { getObjectLength } from '../../utils'
import useArtist from '../../hooks/useArtist'
import { useArtistRoyaltiesSearch } from '../../hooks/useSearch'

const ArtistRoyalties = ({userId}) => {
    const [pageNumber, setPageNumber] = useState(0)
    const {getArtistName, searchArtists} = useArtist({})
    const [artistName, setArtistName] = useState()
    const {loading, error, royalties, pages, setPages} = useArtistRoyaltiesSearch({pageNumber: pageNumber, userId: userId})
    const observer = useRef()

    const lastRoyaltyElementRef = useCallback( node => {
      if(loading){
        return
      }
      if(observer.current){ 
        observer.current.disconnect()}
        observer.current = new IntersectionObserver(entries=> {
          if(entries[0].isIntersecting && (pageNumber < parseInt(pages/10))){
                setPageNumber(prev => prev + 1)
  
          }
      }) 
      
      if(node) observer.current.observe(node)
     
    }, [loading, pages, pageNumber])

    const displayArtistName = async () => {
        return searchArtists(ARTIST_SEARCH_TYPES.ADDRESS, userId)
          .then(async (artist) => {
            if (artist) {
              setArtistName(artist?.artistName);
              /* setProfileImg(artist?.artistSettings?.profilePictureType === "custom" && artist?.profilePicture ?
            artist?.profilePicture :
            artist?.photoUrl
              ? artist.photoUrl + "?s=600&d=mp"
              : DEFAULT_IMAGE(600)) */
              
              return;
            } else {
              let issuerName = await getAddressName(userId);
              if (getObjectLength(issuerName) > 0) {
                setArtistName(issuerName?.name);
                //setProfileImg(issuerName?.profileUrl + "?s=600&d=mp")
                return;
              }
              setArtistName(issuerName);
              return;
            }
          })
          .catch((err) => {
            setArtistName(userId);
            //console.error(err)
          });
      };

    useEffect(() => {
       return displayArtistName()
       
      }, [userId]);
    
  return (
    <>
    <ContentWrapper heading={"Artist Regium Share Tokens"}>
    {
      !loading && royalties?.length > 0 && (
        
          <Slider
            type={SLIDER_TYPES.MUSIC_ROYALTY_SHARE}
            heightScroll
            slides={royalties}
            cardRef={lastRoyaltyElementRef}
            id={"artist regium share tokens"}
          />
       
      )
      /*         <PageIndexer/>
       */
    }
    {
      loading && royalties?.length > 0 && (
        <>
          <Slider
            type={SLIDER_TYPES.MUSIC_ROYALTY_SHARE}
            heightScroll
            slides={royalties}
            cardRef={lastRoyaltyElementRef}
            id={"artist regium share tokens"}
          />
                   <Modal isOpen={loading}><ModalLoadingScreen text={'Loading. Please Wait'}/></Modal>

</>
      )
      /*         <PageIndexer/>
       */
    }
    {loading && royalties?.length === 0 && (
      <ContentWrapper heading={"Artist Regium Share Tokens"}>
           <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      </ContentWrapper>
    )}
    {!loading && royalties?.length === 0 && <ContentWrapper heading={"No Regium Share Tokens Found"}>
        
        </ContentWrapper>}
        </ContentWrapper>
  </>
  )
}


export default ArtistRoyalties