import React from 'react'
import useNetwork from '../../hooks/useNetwork'
import Modal from '../Modal'
import OptionModal from '../OptionModal'
import { RiSignalWifiOffLine } from 'react-icons/ri'
import { FormText } from '../CustomForm/CustomFormElements'

const NetworkModal = ({children}) => {
    const {state} = useNetwork()

    
  return (
    <>{
        !state?.online && 
        <Modal isOpen={true}>
            <OptionModal heading={'No Connection'} additionalText={<RiSignalWifiOffLine/>}>
                <FormText>Offline</FormText>
            </OptionModal>

        </Modal>
        
    }
    {children}</>
  )
}

export default NetworkModal