import React, { useEffect, useRef, useState } from "react";
import { FaInfo, FaInfoCircle, FaTrash } from "react-icons/fa";
import { useAuth } from "../../contexts/AuthContext";
import { useUser } from "../../hooks/useUser";
import {
  ALERT_TYPES,
  CONTENT_TYPES,
  TRANSACTION_OPTIONS,
} from "../../utils/Enum";
import { useAlert } from "../Alert/AlertContext";
import Chip from "../Chip";
import { ChipList } from "../Chip/ChipElements";
import ContentWrapper from "../ContentWrapper";
import {
  FormButton,
  FormButton1,
  FormImg,
  FormImgWrapper,
  FormInput,
  FormInput1,
  FormLabel,
  FormText,
  FormWrapper,
} from "../CustomForm/CustomFormElements";
import { Header } from "../Header";
import LoadingScreen, { ModalLoadingScreen } from "../LoadingScreen";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { useModal } from "../Modal/ModalContext";
import { Nav, NavContainer, NavLink } from "../NavLink/NavLinkElements";
import { MintButtonConainer } from "../NFTCollectionCreator/NFTCollectionCreatorElements";
import OptionModal from "../OptionModal";
import QRCodeForm from "../QRCodeForm";
import ToolTip from "../ToolTip";
import useMusic from "../../hooks/useMusic";
import { getObjectLength, getUniqueListBy, validateAddress } from "../../utils";
import ScrollToTop from "../ScrollToTop";
import ContentList from "../ContentList";
import { RegularUserList } from "../ContentList/UserList";
import { Button1, CustomButton, CustomButton1 } from "../ButtonElement";
import {FormLabel as FormLabel1} from "../MusicUploadsForm/MusicUploadsFormElements"
import ToggleButton from "../ToggleButton";
import { updateWebPageIcon } from "../../utils/MusicUtils";

const NFTokenWhitelister = () => {
  const { openModal, modalContent, closeModal, noCloseModal } = useModal();
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const { currentUser } = useAuth();
  const {
    getCollectionsByUser,
    getCollectionWhitelistAddresses,
    addWhitelistedAddresses,
  } = useUser({});
  const [fileSelector, setFileSelector] = useState(false);
  const { getNFTokenCollection } = useMusic();
  const [csvFile, setFile] = useState();
  const [isUniqueAddresses, setUniqueAddresses] = useState(false);
  const [isScheduled, setScheduled] = useState(false);
  const [startDate, setStartDate] = useState()
  const [startTime, setStartTime] = useState()
  const [endDate, setEndDate] = useState()
  const [endTime, setEndTime] = useState()
  const [isSubmitted, setSubmitted] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [whiteListAddresses, setWhiteListAddresses] = useState([]);
  const [removeAddresses, setRemoveAddresses] = useState([]);
  const [filteredWhitelist, setFilteredWhitelist] = useState([]); // filtered existing whitelist Addresses when searching
  const [filteredRemove, setFilteredRemove] = useState([]); // filtered removed whitelist Addresses when searching
  const [searchRemoveAddress, setSearchRemoveAddress] = useState(""); //search for removed addresses
  const [searchAddress, setSearchAddress] = useState(""); // search for existing whitelisters
  const [isLoaded, setLoaded] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showBlacklister, setShowBlacklister] = useState(false);
  const [showWhitelister, setShowWhitelister] = useState(false);
  const [bListAddress, setBListAddress] = useState("");
  const [wListAddress, setWListAddress] = useState("");
  const [blacklistAddresses, setBlacklist] = useState([]);
  const [collections, setCollections] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterAddresses, setFilteredAddresses] = useState([]);
  const [currentWhiteList, setWhiteList] = useState([]);
  const [existingWhitelist, setExistingWhitelist] = useState([]);
  const [isExitingLoaded, setExistingLoaded] = useState(false)
  const [showExisting, setShowExisting] = useState(false);
  const [showRemoval, setShowRemoval] = useState(false);
  const [selectedCollection, setCollection] = useState({});
  const fileRef = useRef();

  /*
  - User selects NFT collection
  - Load whitelisted Addresses
  - Options to add addresses 
   # Option 1 - Manual Type
   # Option 2 - Text File
  - Provide user option to remove addresses from new list
  - Provide user option to remove addresses from whitelist
  */

  const handleInput = (e) => {
    switch (e.target.name) {
      case "blacklist":
        setBListAddress(e.target.value);
        break;
      case "whitelist":
        setWListAddress(e.target.value);
        break;
      case "search":
        if (e.target.value?.length > 0) {
          setShowAll(true);
        }
        setSearchValue(e.target.value);
        setFilteredAddresses(
          addresses?.filter((a) => a?.includes(e.target.value))
        );
        break;
      case "filterWhitelist":
        setSearchAddress(e.target.value);
        setFilteredWhitelist(
          existingWhitelist?.filter((a) => a?.includes(e.target.value))
        );
        break;
      case "filterRemoved":
        setSearchRemoveAddress(e.target.value);
        setFilteredRemove(
          removeAddresses?.filter((a) => a?.includes(e.target.value))
        );
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    updateWebPageIcon('https://sonarmuse.org/favicon.ico')
    getArtistCollections();
    return () => {};
  }, [!isLoaded]);

  useEffect(() => {}, []);

  const handleToggle = (type) => {
    switch (type) {
      case "unique":
        setUniqueAddresses(!isUniqueAddresses);
        break;
      case "schedule":
        setScheduled(!isScheduled);
        break;

      default:
        break;
    }
  };

  const getArtistCollections = async () => {
    openModal();
    modalContent(
      <OptionModal>
        <ModalLoadingScreen
          text={"Retrieving Collections. Please Wait..."}
          transparent={true}
        />
      </OptionModal>
    );
    return await getCollectionsByUser(currentUser?.uid, "all").then((cols) => {
      if (cols.length > 0) {
        setCollections(cols.collections);
        collectionSelector();
        setLoaded(true);
        return;
      }
      setLoaded(true);
      return false;
    });
  };

  const collectionSelector = () => {
    openModal();

    if (collections && collections.length > 0) {
      return noCloseModal(
        <>
          <OptionModal
            heading={"Select Collection"}
            additionalText={
              "Select collection to allow addresses to mint a free NFToken from"
            }
            onCancel={() => {
              noCloseModal();
              closeModal();
              goTo("/");
            }}
          >
            {collections.map((collection) => {
              return (
                <HoverOption
                  key={collection?.collectionId}
                  onClick={async () => {
                    noCloseModal();
                    closeModal();
                    setCollection(collection);
                    if(collection?.whitelistStartDate){
                      setStartDate(new Date(collection?.whitelistStartDate).toISOString().split("T")[0])
                      setStartTime(new Date(collection?.whitelistStartDate).toISOString().split("T")[1].split(".")[0])
                    }
                   
                    if(collection?.whitelistStartDate){
                      setEndDate(new Date(collection?.whitelistEndDate).toISOString().split("T")[0])
                      setEndTime(new Date(collection?.whitelistEndDate).toISOString().split("T")[1].split(".")[0])
                      setScheduled(true)
                    }
                    return await getCollectionWhitelistAddresses(
                      collection?.collectionId
                    ).then((res) => {
                      if (res && res?.length > 0) {
                        if(!collection?.whitelistStartDate){
                          setStartDate(new Date().toDateString().split("T")[0])
                          setStartTime(new Date(collection?.whitelistStartDate).toISOString().split("T")[1].split(".")[0])
                        }
                        setExistingWhitelist(res);
                        setExistingLoaded(true)
                        noCloseModal();
                        closeModal();
                        return;
                      }
                      setExistingLoaded(true)
                      noCloseModal();
                      closeModal();
                      return;
                    }).catch(err => {
                      setExistingLoaded(true)
                      noCloseModal();
                      closeModal();
                    });
                  }}
                >
                  {`${collection.name} #${collection.mintInfo.taxon}`}
                </HoverOption>
              );
            })}
          </OptionModal>
        </>
      );
    }

    if (!collections) {
      return noCloseModal(
        <OptionModal heading={"No Available Collections"}>
          <HoverOption
            onClick={() => {
              noCloseModal();
              closeModal();
              goTo("/");
            }}
          >
            OK
          </HoverOption>
        </OptionModal>
      );
    }
  };

  const getCollectionInfo = async (collectionId) => {
    return getNFTokenCollection(collectionId).then((collection) => {
      if (collection) {
        //getNFTokenCollectionWhitelist(collectionId).then()
        setLoaded(true);
        setCollection(collection);
      } else {
        setLoaded(true);
      }
    });
  };

  const removeFromList = (i) => {
    let list = existingWhitelist;
    let rem = removeAddresses;
    rem.push(existingWhitelist[i]);
    list = list?.filter((a, index) => index !== i);
    setExistingWhitelist(list);
    setRemoveAddresses(rem);
  };

  const removeAllFromList = () => {
    let list = existingWhitelist;
    setRemoveAddresses(list);
    setExistingWhitelist([]);
  };

  const addToList = (i) => {
    let list = removeAddresses;
    let newList = existingWhitelist;
    newList.push(removeAddresses[i]);
    list = list?.filter((a, index) => index !== i);
    setExistingWhitelist(newList);
    setRemoveAddresses(list);
  };

  const readCSVFile = (e) => {
    var files = e.target.files;
    setFile(e.target.files);
    //console.log(files);
    if (files.length > 0) {
      // Selected file
      var file = files[0];

      // FileReader Object
      var reader = new FileReader();

      // Read file as string
      reader.readAsText(file);

      // Load event
      reader.onload = function (event) {
        // Read file data
        var csvdata = event.target.result;

        // Split by line break to gets rows Array
        var rowData = csvdata.split("\n");
        let arrAddress = addresses;
        let l = [];

        for (
          let i = rowData[0].includes("Owner") ? 1 : 0;
          i < rowData.length;
          i++
        ) {
          const pieces = rowData[i].split(",");
          let last;
          if (rowData[0].includes("Owner")) {
            let ownerPos = rowData[0].split(",");
            last = pieces[ownerPos.indexOf(`"Owner"`)];
          } else {
            last = pieces[0];
          }

          //console.log(last)
          last = last.split('"').join("");
          last = last.split("\r")[0];
          if (validateAddress(last)) {
            arrAddress.push(last);
            l.push(last);
          }
        }
        //console.log(getUniqueListBy(l,''))
        setAddresses(arrAddress);
        fileRef.current = null;
        setFileSelector(false);
        //console.log(arrAddress);
      };
    }
    return;
  };

  const onAirdropSuccess = () => {
    setSubmitted(true);
    goTo("/");
    openModal();
    modalContent(
      <OptionModal
        isOpen={true}
        heading={`Whitelist Request for ${selectedCollection?.name} submitted successfully`}
      >
        <FormImgWrapper>
          <FormImg
            src={selectedCollection?.cover?.url}
            alt={selectedCollection.name}
          />
        </FormImgWrapper>
        <FormText>
          {" "}
          {`Addresses will be whitelisted to claim a free NFToken from collection`}
        </FormText>

        <HoverOption
          onClick={() => {
            closeModal();
            modalContent();
          }}
        >
          OK
        </HoverOption>
      </OptionModal>
    );
  };

  const onAirdropFail = () => {
    modalContent(
      <OptionModal
        heading={`Whitelist Request for ${selectedCollection?.name} Failed`}
      >
        <HoverOption
          onClick={() => {
            closeModal();
            modalContent();
          }}
        >
          Try Again
        </HoverOption>
        <HoverOption
          onClick={() => {
            closeModal();
            modalContent();
          }}
        >
          Cancel
        </HoverOption>
      </OptionModal>
    );
  };

  const confirmWhitelist = () => {
    //console.log(addresses)
    openModal();
    modalContent(
      <QRCodeForm
        heading={`Whitelisting Addresses for NFToken Collection ${selectedCollection.name}`}
        type={TRANSACTION_OPTIONS.COLLECTION_WHITELIST}
        body={{
          newList: isUniqueAddresses ? getUniqueListBy(addresses.filter((a) => !existingWhitelist.some(a1 => a === a1))) : addresses,
          removeList: removeAddresses,
          sender: currentUser?.uid,
          collectionId: selectedCollection?.collectionId,
          /* startDate: (isScheduled || addresses?.length > 0 || existingWhitelist?.length !== removeAddresses?.length) ? selectedCollection?.whitelistStartDate ? selectedCollection?.whitelistStartDate : new Date(`${startDate}T${startTime}`).toISOString() : false,
          endDate: isScheduled ? new Date(`${endDate}T${endTime}`).toISOString() : false, */
          memo: `${currentUser?.uid} is signing transaction to whitelist (add/update) addresses as part their collection  ${selectedCollection?.name} (id: ${selectedCollection?.collectionId})`,
          instruction: `${currentUser?.uid} is signing transaction to whitelist (add/update) addresses as part their collection  ${selectedCollection?.name} (id: ${selectedCollection?.collectionId})`,
        }}
        onSuccess={onAirdropSuccess}
        onFail={onAirdropFail}
        cancelQr={() => {
          addAlert({
            title: "Collection Whitelist Cancelled",
            type: ALERT_TYPES.WARNING,
            secs: 10,
          });
          closeModal()
          modalContent()
        }}
      />
    );
  };

  const validateCollection = () => {
    if (!selectedCollection?.collectionId) {
      return addAlert({
        title: "Collection Whitelist Error",
        type: ALERT_TYPES.DANGER,
        message: "Select collection",
      });
    }
    if (
      selectedCollection?.tokenSuppy &&
      selectedCollection?.tokenSuppy <
        selectedCollection?.tokenSuppy?.amountSold + addresses?.length
    ) {
      return addAlert({
        title: "Collection Whitelist Error",
        type: ALERT_TYPES.DANGER,
        message:
          "Collection supply too low for whitelist, Either increase supply or remove addresses",
      });
    }

    if ((addresses.length === 0 && removeAddresses?.length === 0) /* && (!selectedCollection?.whitelistEndDate && !isScheduled || selectedCollection?.whitelistEndDate && isScheduled) */ ) {
      return addAlert({
        title: "Collection Whitelist Error",
        type: ALERT_TYPES.DANGER,
        message:
          "No changes found. enable scheduling, add addresses to whitelist or remove existing whitelisted addresses",
      });
    }
    confirmWhitelist();
  };

 /*  const blacklistAddress = () => {
    openModal();
    modalContent(
      <OptionModal
        heading={"Blacklist Address"}
        additionalText={"Enter address to blacklist"}
      ></OptionModal>
    );
  }; */

  return (
    <>
      <ContentWrapper>
        <Header title={"NFToken Whitelister"} />
        {getObjectLength(selectedCollection) > 0 && (
          <>{isExitingLoaded && <>
            <FormLabel
              style={{
                textAlign: "center",
                justifySelf: "center",
                alignSelf: "center",
                justifyContent: "center",
              }}
            >
              Add addresses that are able to claim a free NFT from the{" "}
              {selectedCollection?.name} collection. This will affect the number
              of NFTs you will have available to sell on the platform
            </FormLabel>
            
              <FormWrapper >
                <div style={{display: "flex", flexDirection: "row", alignItems: "flex-start", width: "100%"}}>
                <FormLabel
                  onClick={() => {
                    handleToggle("unique");
                  }}
                >
                  Single Mint Per Unique Addresses <ToolTip
                    style={{ marginLeft: "2%" }}
                    text={
                      "If an address is added multiple times, it will only be allowed to mint one (1) NFT from this collection"
                    }
                  >
                    <FaInfo
                      color="white"
                      style={{
                        marginLeft: "1%",
                        border: "1px solid",
                        borderRadius: "50%",
                      }}
                    />
                  </ToolTip>
                </FormLabel>
                <ToggleButton
                  onText={"On"}
                  offText={"Off"}
                  toggle={isUniqueAddresses}
                  toggleClick={() => handleToggle("unique")}
                />
                {isUniqueAddresses &&
                <div style={{display: "flex", alignItems: "center", justifyContent:"center", width: "100%"}}> 
                <FormText>Each address is only allowed to mint one NFT from this collection</FormText> </div>}
                </div>
               {/*  <div style={{display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent:"center", width: "100%"}}>
                <FormLabel
                  onClick={() => {
                    handleToggle("schedule")}}
                >
                  Schedule Whitelist
                </FormLabel>
                <ToggleButton
                  onText={"On"}
                  offText={"Off"}
                  toggle={isScheduled}
                  toggleClick={() => handleToggle("schedule")}
                />
                </div>
                {isScheduled && <FormWrapper>
                  <FormWrapper>
                  <FormLabel>Start Date</FormLabel>
                  <FormInput type="date" readOnly={(new Date(selectedCollection?.whitelistStartDate).getTime() <= new Date().getTime() || (!selectedCollection?.whitelistStartDate && existingWhitelist?.length > 0)) && true } value={startDate} onChange={e => setStartDate(e.target.value)} min={new Date().toISOString().split("T")[0]}/>
                  <FormInput type="time" readOnly={(new Date(selectedCollection?.whitelistStartDate).getTime() <= new Date().getTime() || (!selectedCollection?.whitelistStartDate && existingWhitelist?.length > 0)) && true } value={startTime} onChange={e => setStartTime(e.target.value)}/>
                  </FormWrapper>
                  <FormWrapper>
                  <FormLabel>End Date</FormLabel>
                  
                  <FormInput type="date" value={endDate} onChange={e => setEndDate(e.target.value)} min={new Date( new Date().setDate(new Date().getDate() + 1)).toISOString().split("T")[0]}/>
                  <FormInput type="time"  value={endTime} onChange={e => setEndTime(e.target.value)}/>
                  </FormWrapper>
                  {selectedCollection?.whitelistEndDate && <FormText>To allow for unlimited time to whitelist. Disable (Toggle off) the scheduled whitelist option </FormText>}
                  </FormWrapper>} */}
              </FormWrapper>
            
            <MintButtonConainer style={{ background: "transparent" }}>
              {!fileSelector && (
                <FormButton1 onClick={() => setFileSelector(true)}>
                  Add Addresses From File
                </FormButton1>
              )}
              {addresses?.length > 0 && !showBlacklister && (
                <>
                  <FormButton1 onClick={() => setShowBlacklister(true)}>
                    Blacklist Address
                  </FormButton1>
                </>
              )}
              {!showWhitelister && (
                <FormButton1 onClick={() => setShowWhitelister(true)}>
                  Whitelist Address
                </FormButton1>
              )}
            </MintButtonConainer>

            {fileSelector && (
              <ContentWrapper heading={"Address File Selector"}>
                <FormWrapper>
                  <FormLabel>
                    Select CSV File
                    <ToolTip
                      text={
                        "Upload CSV File exported from Bithomp containing NFToken holders or CSV file containing wallet addresses only"
                      }
                    >
                      <FaInfoCircle />
                    </ToolTip>
                  </FormLabel>
                  <FormInput
                    ref={fileRef}
                    type={"file"}
                    accept={"text/csv, .txt"}
                    onChange={readCSVFile}
                  />
                  <FormButton1
                    onClick={() => {
                      setFileSelector(false);
                    }}
                  >
                    Cancel
                  </FormButton1>
                </FormWrapper>
              </ContentWrapper>
            )}
            {(whiteListAddresses?.length > 0 ||
              blacklistAddresses?.length > 0) && (
              <ContentWrapper>
                {showBlacklister && (
                  <FormWrapper>
                    <FormLabel>Remove Wallet Address</FormLabel>
                    <FormInput1
                      style={{ padding: "2% 0%" }}
                      value={bListAddress}
                      name={"blacklist"}
                      placeholder={
                        "Enter Wallet Address to Remove from new list"
                      }
                      onChange={(e) => handleInput(e)}
                    />
                    <MintButtonConainer style={{ background: "transparent" }}>
                      <FormButton1
                        onClick={() => {
                          if (validateAddress(bListAddress)) {
                            setBlacklist((prev) => {
                              return [...prev, bListAddress];
                            });
                            setAddresses((prev) => {
                              //console.log(prev.filter((a) => a !== bListAddress))
                              return prev.filter((a) => a !== bListAddress);
                            });
                            setShowBlacklister(false);
                            setBListAddress("");
                          } else {
                            addAlert({
                              title: "Collection Whitelist Error",
                              type: ALERT_TYPES.DANGER,
                              message: "Invalid Wallet Address",
                            });
                          }
                        }}
                      >
                        Blacklist Address
                      </FormButton1>
                      <FormButton1
                        onClick={() => {
                          setBListAddress("");
                          setShowBlacklister(false);
                        }}
                      >
                        Cancel
                      </FormButton1>
                    </MintButtonConainer>
                  </FormWrapper>
                )}
              </ContentWrapper>
            )}
            {showWhitelister && (
              <FormWrapper>
                <FormLabel>Whitelist Address</FormLabel>
                <FormInput1
                  style={{ padding: "2% 0%" }}
                  value={wListAddress}
                  name={"whitelist"}
                  placeholder={"Enter Wallet Address"}
                  onChange={(e) => handleInput(e)}
                />
                <MintButtonConainer style={{ background: "transparent" }}>
                  <FormButton1
                    onClick={() => {
                      if (
                        validateAddress(wListAddress) &&
                        !whiteListAddresses.some((a) => a === wListAddress)
                      ) {
                        setAddresses((prev) => {
                          return [...prev, wListAddress];
                        });
                        setBlacklist((prev) => {
                          //console.log(prev.filter((a) => a !== bListAddress))
                          return prev.filter((a) => a !== wListAddress);
                        });
                        setShowWhitelister(false);
                        setWListAddress("");
                      } else {
                        if (
                          whiteListAddresses.some((a) => a === wListAddress)
                        ) {
                          addAlert({
                            title: "Collection Whitelist Error",
                            type: ALERT_TYPES.DANGER,
                            message: "Wallet Address Already Exists",
                          });
                        } else {
                          addAlert({
                            title: "Collection Whitelist Error",
                            type: ALERT_TYPES.DANGER,
                            message: "Invalid Wallet Address",
                          });
                        }
                      }
                    }}
                  >
                    Whitelist Address
                  </FormButton1>
                  <FormButton1
                    onClick={() => {
                      setWListAddress("");
                      setShowWhitelister(false);
                    }}
                  >
                    Cancel
                  </FormButton1>
                </MintButtonConainer>
              </FormWrapper>
            )}
            {isLoaded &&
              getObjectLength(selectedCollection) > 0 &&
              selectedCollection?.collectionId && (
                <>
                  {existingWhitelist?.length > 0 && (
                    <ContentWrapper
                      heading={`Existing Whitelisted Addresses (${existingWhitelist?.length})`}
                      subheading={showExisting ? `Hide` : `ShowAll`}
                      onClick={() => setShowExisting(!showExisting)}
                    >
                      {showExisting && (
                        <FormWrapper>
                          <FormLabel>
                            {" "}
                            Existing Whitelisted Addresses{" "}
                            {existingWhitelist?.length}
                          </FormLabel>
                          <CustomButton1 onClick={removeAllFromList}>
                            Remove All
                          </CustomButton1>
                          <FormInput1
                            name={"filterWhitelist"}
                            placeholder={"Search Existing Wallet Addresses"}
                            value={searchAddress}
                            onChange={handleInput}
                          />

                          {searchAddress?.trim()?.length === 0 && (
                            <RegularUserList
                              key={"existingList"}
                              type={CONTENT_TYPES.USER}
                              content={existingWhitelist.map((add) => {
                                return { uid: add };
                              })}
                              onButtonClick={removeFromList}
                              btnText={"Remove from List"}
                            />
                          )}
                          {searchAddress?.trim()?.length > 0 && (
                            <RegularUserList
                              key={"filteredList"}
                              type={CONTENT_TYPES.USER}
                              content={filteredWhitelist.map((add) => {
                                return { uid: add };
                              })}
                              onButtonClick={removeFromList}
                              btnText={"Remove from List"}
                            />
                          )}
                        </FormWrapper>
                      )}
                    </ContentWrapper>
                  )}
                  {removeAddresses?.length > 0 && (
                    <ContentWrapper
                      heading={`Addresses to Remove ${removeAddresses?.length}`}
                      subheading={showRemoval ? `Hide` : `ShowAll`}
                      onClick={() => setShowRemoval(!showRemoval)}
                    >
                      {showRemoval && (
                        <FormWrapper>
                          <FormLabel>
                            Addresses to Remove ({removeAddresses?.length})
                          </FormLabel>
                          <FormInput1
                            name={"filterRemoved"}
                            placeholder={"Search Removed Wallet Addresses"}
                            value={searchRemoveAddress}
                            onChange={handleInput}
                          />

                          {searchRemoveAddress?.trim()?.length === 0 && (
                            <RegularUserList
                              key={"removalList"}
                              type={CONTENT_TYPES.USER}
                              content={removeAddresses.map((add) => {
                                return { uid: add };
                              })}
                              onButtonClick={addToList}
                              btnText={"Add Back to List"}
                            />
                          )}
                          {searchRemoveAddress?.trim()?.length > 0 && (
                            <RegularUserList
                              key={"filteredRenovalList"}
                              type={CONTENT_TYPES.USER}
                              content={filteredRemove.map((add) => {
                                return { uid: add };
                              })}
                              onButtonClick={addToList}
                              btnText={"Add Back to List"}
                            />
                          )}
                        </FormWrapper>
                      )}
                    </ContentWrapper>
                  )}
                  {addresses?.length > 0 && (
                    <ContentWrapper
                      subheading={showAll ? "Hide" : "Show All"}
                      onClick={() => setShowAll(!showAll)}
                    >
                      <FormWrapper>
                        <FormLabel>
                          New Addresses ({addresses.length})
                        </FormLabel>
                        <FormInput1
                          name={"search"}
                          placeholder={"Search Wallet Addresses"}
                          value={searchValue}
                          onChange={handleInput}
                        />
                        {showAll && (
                          <ChipList alignment={"center"} maxHeight={"60%"}>
                            {addresses?.length > 0 &&
                              searchValue?.trim() === "" &&
                              addresses.map((address, index) => {
                                return (
                                  <Chip
                                    index={index}
                                    closeButton
                                    label={address}
                                    onDelete={() => {
                                      setAddresses((prev) => {
                                        return prev.filter(
                                          (a, i) => i !== index
                                        );
                                      });
                                    }}
                                  />
                                );
                              })}
                            {filterAddresses?.length > 0 &&
                              searchValue?.trim() !== "" &&
                              filterAddresses.map((address, index) => {
                                return (
                                  <Chip
                                    index={index}
                                    closeButton
                                    label={address}
                                    onDelete={() => {
                                      setAddresses((prev) => {
                                        return prev.filter(
                                          (a, i) => i !== index
                                        );
                                      });
                                    }}
                                  />
                                );
                              })}
                          </ChipList>
                        )}
                      </FormWrapper>
                    </ContentWrapper>
                  )}{" "}
                </>
              )}
          </>}
          {!isExitingLoaded && <ModalLoadingScreen text={"Loading Existing Whitlisted Addresses"}/>}</>
        )}
        {isLoaded && getObjectLength(selectedCollection) === 0 && (
          <Button1
            onClick={() => {
              getArtistCollections();
            }}
          >
            Select Collection
          </Button1>
        )}
        {!isLoaded && <LoadingScreen transparent />}
        <MintButtonConainer>
          <CustomButton onClick={validateCollection}>
            Confirm Whitelist
          </CustomButton>
          <CustomButton
            onClick={() => {
              goTo("/");
            }}
          >
            Cancel
          </CustomButton>
        </MintButtonConainer>
      </ContentWrapper>
    </>
  );
};

export default NFTokenWhitelister;
