import { Link } from "react-router-dom";
import styled from "styled-components";
import { hexToRgba } from "../../HexToRGBA";

export const CardContainer = styled.div`
  display:flex;
  flex-direction: column;
  margin: 2%;
  padding: 2%;
  max-width: 350px;

  border-radius: 50px 5px 50px 5px;
  align-content: center;
align-items: left;
justify-content: left;
  background: ${props => hexToRgba(props.theme.accentColor, 0.01)};
  &:hover{
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
    background: ${props => hexToRgba(props.theme.accentColor, 0.05)};
border: solid ${props => hexToRgba(props.theme?.secondaryBg, 0.7)} 1px;
}

`

export const CardImg = styled.div`
  display: flex;
  background: ${props => props.color} url(${props => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 50px 5px 50px 5px;
  width: 15rem;
  height: 15rem;
  margin-bottom: 2%;
  transition: transform 500ms ease;
  cursor:pointer;
  &:hover{
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
}
&:focus{     
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
}

  `

  export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 6%;
    justify-content: left;
    align-content: center;
  
  `

  export const CardTitle = styled.h4`
    display: flex;
    font-size: 1rem;
    margin-bottom: 1px;
    white-space: normal;
    text-align: left;
    word-wrap: break-word;
    color: ${props => props.theme.secondaryColor};
    
`

  export const H6 = styled.h6`
  display: flex;
    font-size: 1rem;
    width: 100%;
    flex-wrap: nowrap;
    color: ${props => hexToRgba(props.theme.secondaryColor, 1)};
`

  export const ContentText = styled.div`
    display: flex;
     
`

export const TextLink = styled(Link)`
text-decoration: none;
cursor: pointer;
color: ${props => props?.alt ? props?.theme?.secondaryColor : props.theme.secondaryBg};
&: hover{
 color: ${props => props?.alt ? props.theme.secondaryBg : props.theme.accentColor};
 text-decoration: underline;

}
`