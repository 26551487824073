import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useUser } from "../../hooks/useUser";
import { hexToStr, strToHex } from "../../utils";
import { CONTENT_TYPES, TRANSACTION_OPTIONS } from "../../utils/Enum";
import ContentList from "../ContentList";
import NFTokenList, { GroupNFTokenList } from "../ContentList/NFTokenList";
import LoadingScreen from "../LoadingScreen";
import { useModal } from "../Modal/ModalContext";
import OptionModal from "../OptionModal";
import QRCodeForm from "../QRCodeForm";

const NFTokenBurner = () => {
  const { toggleModal, modalContent, isOpen } = useModal();
  const { getNFTokens1, getNFTokenDetails } = useUser({});
  const { currentUser } = useAuth();
  const [isLoaded, setLoaded] = useState(false);
  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    getTokens();
    return () => {}
  }, [!isLoaded]);

  const getTokens = async () => {
    return await getNFTokens1(currentUser?.uid)
      .then(async(data) => {
        if(data?.length > 0){
          console.log(data)
         let t = []
        if(data?.length > 0){
          let requests = []
          for(let i = 0; i < data.length; i++){
            requests.push(getNFTokenDetails(data[i]?.tokens[0]?.NFTokenID))

        }
        await Promise.all(requests).then((tokens) => {
          t = tokens.map((t, index) => {
            if(!t?.error && t){
              return {
                ...t,
                ...data[index],
                nftoken:{
                 ...t.nftoken ,
                 ...data[index].nftoken,
                }
                
              }
            }else{
              return data[index]
            }
          })
        }).catch(err => {
          console.error(err)
          t = data
        })
        //console.log(t)
        setTokens(t);
        }

        
      }
        setLoaded(true);
        return
      })
      .catch((err) => {
        console.error(err);
        setLoaded(true);
      });
  };

  const burnToken = (token) => {
    modalContent(
      <QRCodeForm
        type={TRANSACTION_OPTIONS.NFTOKEN_BURN}
        heading={`Burning ${
          token?.nftoken?.metadata
            ? token?.nftoken?.metadata?.name
              ? token?.nftoken?.metadata?.name
              : token?.nftoken?.metadata?.collection
              ? token?.nftoken?.metadata?.collection?.name
              : `${token?.nftoken?.NFTokenID?.substring(
                  0,
                  5
                )}...${token?.nftoken?.NFTokenID?.substring(
                  token?.nftoken?.NFTokenID?.length - 5,
                  token?.nftoken?.NFTokenID?.length - 1
                )}`
            : `${token?.nftoken?.NFTokenID?.substring(
                0,
                5
              )}...${token?.nftoken?.NFTokenID?.substring(
                token?.nftoken?.NFTokenID?.length - 5,
                token?.nftoken?.NFTokenID?.length - 1
              )}`
        }
`}
        body={{ sender: currentUser?.uid, tokenId: token?.nftoken?.NFTokenID }}
        cancelQr={() => {
          modalContent(<NFTokenBurner />);
        }}
        onFail={() => {
          toggleModal();
          modalContent();
        }}
        onSuccess={() => {
          modalContent(<NFTokenBurner />);
        }}
      />
    );
  };

  return (
    <>
      <OptionModal heading={"NFToken Burner"}>
        {!isLoaded && <LoadingScreen transparent={true} />}
        {isLoaded && tokens?.length > 0 && (
          <GroupNFTokenList type={CONTENT_TYPES.NFTOKEN} content={tokens} onClick={burnToken}/>
        )}
      </OptionModal>
    </>
  );
};

export default NFTokenBurner;
