import React, {useEffect, useState} from 'react'
import Access from '../../components/Access'
import AudioPlayer from '../../components/AudioPlayer'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import Sidebar from '../../components/Sidebar'
import { useAuth } from '../../contexts/AuthContext'
import { Home as home, Welcome } from '../../components/Navbar/Data'
import { useNavigate } from 'react-router-dom'

const Home = () => {
	const [isOpen, setIsOpen] = useState(false);
	const navigate  = useNavigate()
	const toggle = () => setIsOpen(!isOpen);
	const {currentUser} = useAuth()
	useEffect(() => {
		if (!currentUser?.uid) {
		  navigate( "/");
		  return () => {}
		}return () => {}}, [])

	return (
		<>
		<Sidebar
          isOpen={isOpen}
          toggle={toggle}
          navInfo={localStorage.getItem('accInfo')? home(localStorage.getItem('accInfo')) : Welcome}
        />
          <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? home(localStorage.getItem('accInfo')) : Welcome}/>
			<Access/>
		</>	
	)

}
export default Home