import styled from "styled-components";

export const MEDIA_SCREEN = { //Screen sizes for devices
  MOBILE: '480',
  TABLETS: '768',
  LAPTOPS: '1024',
  DESKTOPS: '1025',
  TV: '1500'
}

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  padding: 2% 2%;
  width: 100%;
`;

export const ContentHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  padding: 0% 2%;
  width: 100%;
`;

export const ContentHeading = styled.h1`
  display: flex;
  font-size: 1.4rem;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 400;
  color: ${(props) => props.theme.secondaryBg};
  padding: 1% 0px;
  align-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    text-decoration: ${props => props?.onClick ? `underline` : `none`};
    transition: all 0.2s ease-in-out;
    opacity: 1;
  }
  @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 0.95rem;
  }
`;

export const ContentSubheading = styled.h1`
  display: block;
  font-size: 0.8rem;
  font-weight: 100;
  color: ${(props) => props.theme.secondaryBg};
  padding: 1% 0px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    transition: all 0.2s ease-in-out;
    opacity: 1;
  }
  @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    font-size: 0.65rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 0.4rem;
  }
`;


