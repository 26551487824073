import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { getUniqueListBy } from "../../../utils";
import { DEFAULT_IMAGE } from "../../GravatarAPI";
import { NavContainer } from "../../NavLink/NavLinkElements";
import ArtistAbout from "./ArtistAbout";
import {
  ArtistContentContainer,
  ArtistContentHeading,
  ArtistContentHeadingWrapper,
  ArtistContentWrapper,
  ArtistProfileNav,
  ArtistProfileNavLink,
} from "./ArtistContentElements";
import ArtistPurchaseMusic from "./ArtistPurchaseMusic";
import ArtistSettings from "./ArtistSettings";
import Discography from "./Discography";
import { useUser } from "../../../hooks/useUser";
import { useLocation, useNavigate } from "react-router-dom";
import useArtist from "../../../hooks/useArtist";
import { shuffleSongs } from "../../../utils/MusicUtils";

export const ArtistContent = ({
  artistId,
  isDiscographyLoaded,
  artist,
  background,
  isUserSubscribed,
  discography,
  setDiscography,
  searchArtistSingles,
  searchArtistAlbums,
  searchArtistProducedSongs,
  searchArtistWrittenSongs,
  searchArtistComposedSongs,
  searchArtistFeatSongs,
  about,
  gravatarProfile,
  getProfile,
}) => {
  const { currentUser } = useAuth();
  const location = useLocation()
  const navigate  = useNavigate()
  const { getCollectionsByUser } = useUser({});
  const {getArtistMusicRoyaltiesByUserId} = useArtist({})
  const [isStats, setStatsActive] = useState(false);
  const [currentTab, setTab] = useState("discography");
  const [collections, setCollections] = useState({})
  const [royaltyTokens, setRoyaltyTokens] = useState({});
  const [isCLoaded, setCLoaded] = useState(false);
  const [isRTLoaded, setRTLoaded] = useState(false);
  const [isPurchase, setPurchaseActive] = useState(false);
  const [isDiscography, setDiscographyActive] = useState(true);
  const [popularMusic, setPopular] = useState([]);
  const [featuredMusic, setFeaturedMusic] = useState([]);
  const [isAbout, setAboutActive] = useState(false);
  const [discogUpdate, setDiscogUpdate] = useState(false)
  const [isSettings, setSettingsActive] = useState(false);
  const slides = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
  const slides1 = [1, 2];
  const slides2 = [1, 2, 3, 4, 5];
  const slides3 = [1, 2, 3];
  const slides4 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 11, 12, 13];

  useEffect(() => { 
    const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search)
    if(params.has('tab')){
     
      setCurrentTab(params.get("tab"))
    }else{
      setCurrentTab()
    }
    return () => {}
  }, []);

  

    const setCurrentTab = (tab) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search)
              
    switch (tab) {
      case "discography":
        params.set("tab", tab)
        setTab(tab);
        setDiscographyActive(true);
        setPurchaseActive(false);
        setSettingsActive(false);
        setAboutActive(false);
        setStatsActive(false);
        break;
      case "purchase":
        if(discography?.songs?.length > 0 ||
          discography?.albums?.length > 0 ||
          discography?.collections?.length > 0){
            params.set("tab", tab)
        setTab(tab);
        setPurchaseActive(true);
        setDiscographyActive(false);
        setSettingsActive(false);
        setAboutActive(false);
        setStatsActive(false);
          }else{
            params.set("tab", "discography")
        setTab("discography");
        setDiscographyActive(true);
        setPurchaseActive(false);
        setStatsActive(false);
        setSettingsActive(false);
        setAboutActive(false);
          }
        
        break;
      case "about":
        params.set("tab", tab)
        setTab(tab);
        setDiscographyActive(false);
        setPurchaseActive(false);
        setSettingsActive(false);
        setStatsActive(false);
        setAboutActive(true);
        break;

      default:
        params.delete("tab")
        setTab("discography");
        setDiscographyActive(true);
        setPurchaseActive(false);
        setStatsActive(false);
        setSettingsActive(false);
        setAboutActive(false);
        break;
    }
    navigate(`${params.toString().length > 0 ? `?${params.toString()}` : '#'}`,{
      replace:true},
    );
  };

  useEffect(() => {
    getCollectionsByUser(artist?.uid, "all")
      .then((collections) => {
        if (collections?.length > 0) {
          setCollections(collections);
          setCLoaded(true);
        } else {
          setCLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setCLoaded(true);
      });
      getArtistMusicRoyaltiesByUserId(artist?.uid, "all")
      .then((tokens) => {
        if (tokens?.length > 0) {
          setRoyaltyTokens(tokens);
          setRTLoaded(true);
        } else {
          setRTLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setRTLoaded(true);
      });
      return () => {}
  }, [artistId]);

  const getPopularSongs = () => {
    let popular = [];
    let songs = (discography?.songs && discography?.songs?.songs?.length > 0) ? discography?.songs?.songs.sort((a, b) => a?.plays > b?.plays ? 1 : a?.plays < b?.plays ? -1 : 0 ).filter((s,index) => index < 5) : [];
    let albums =
    discography?.albums && discography?.albums?.albums?.length > 0 ? discography?.albums?.albums.sort((a, b) => a?.plays > b?.plays ? 1 : a?.plays < b?.plays ? -1 : 0 ).filter((s,index) => index < 5) : [];
    let features =
    discography?.features && discography?.features?.songs?.length > 0 ? discography?.features?.songs.sort((a, b) => a?.plays > b?.plays ? 1 : a?.plays < b?.plays ? -1 : 0 ).filter((s,index) => index < 5) : [];
    popular.push(...getUniqueListBy([...songs, ...features], "songId"),
      ...albums);
      console.log(popular.sort((a, b) => a?.plays > b?.plays ? 1 : a?.plays < b?.plays ? -1 : 0 ))
    setPopular(popular.sort((a, b) => a?.plays > b?.plays ? 1 : a?.plays < b?.plays ? -1 : 0 ))
  };

  const getFeaturesAndAppearences = () => {
    let appearences = [];
    let features =
      discography?.features?.length > 0 ? discography?.features.songs : [];
    let composed =
      discography?.composed?.length > 0
        ? discography?.composed?.songs.filter(
            (c) => !c.artists?.some((a) => a?.artistId === artistId)
          )
        : [];
    let written =
      discography?.written?.length > 0
        ? discography?.written?.songs.filter(
            (w) => !w.artists?.some((a) => a?.artistId === artistId)
          )
        : [];
    let produced =
      discography?.produced?.length > 0
        ? discography?.produced?.songs.filter(
            (p) => !p.artists?.some((a) => a?.artistId === artistId)
          )
        : [];
    appearences = [...features, ...composed, ...written, ...produced];
    return getUniqueListBy(appearences, "");
  };

  const getDiscography = async (type, setLoaded, setShowAll) => {
    setDiscogUpdate(false)
    switch (type) {
      case "Albums":
        if (
          discography?.albums &&
          discography?.albums?.albums?.length === discography?.albums?.length
        ) {
          if (discography?.albums?.albums?.length > 10) {
            setShowAll(true);
          }
          break;
        } else {
          setLoaded(false);
          setShowAll(true);
          let albums = discography?.albums?.albums;
          
            await searchArtistAlbums(artistId, "all").then((a) => {
              albums = a
            })
            
          
          setDiscography((prev) => {
            return {
              ...discography,
              albums: albums
            };
          });
          setLoaded(true);
        }
        break;
      case "Singles":
        if (
          discography?.songs &&
          discography?.songs?.songs?.length === discography.songs.length
        ) {
          if (discography?.songs?.songs?.length > 10) {
            setShowAll(true);
          }
          break;
        } else {
          setLoaded(false);
          setShowAll(true);
         
            let songs = discography?.songs
            
            await searchArtistSingles(artistId, "all").then(s => {
              songs = s
            })
            
          
          setDiscography((prev) => {
            return {
              ...discography,
              songs: songs
            };
          });
          setLoaded(true);
        }
        break;
      case "Features":
        if (
          discography?.features &&
          discography?.features?.songs?.length ===
            discography?.features?.length &&
          discography?.composed &&
          discography?.composed?.songs?.length ===
            discography?.composed?.length &&
          discography?.written &&
          discography?.written?.songs?.length ===
            discography?.written?.length &&
          discography?.produced &&
          discography?.produced?.songs?.length === discography?.produced?.length
        ) {
          if (getFeaturesAndAppearences().length > 10) {
            setShowAll(true);
          }

          break;
        } else {
          setLoaded(false);
          setShowAll(true);
          if (discography?.features?.length > 10) {
            let features = discography?.features
      
              await searchArtistFeatSongs(artistId, 'all').then((songs) => {
                features = songs
              });
            
            setDiscography((prev) => {
              return {
                ...discography,
                features: features
              };
            });
          }
          if (discography?.composed?.length > 10) {
            let composed = discography?.composed
            
              await searchArtistComposedSongs(artistId, "all").then((songs) => {
                composed = songs;
              });
            
            setDiscography((prev) => {
              return {
                ...discography,
                composed: composed
              };
            });
          }
          if (discography?.written?.length > 10) {
            let written = discography?.written
            
              await searchArtistWrittenSongs(artistId, "all").then((songs) => {
                written = songs
              });
            

            setDiscography((prev) => {
              return {
                ...discography,
                written: written
              };
            });
          }
          if (discography?.produced?.length > 10) {
            let produced = discography?.produced
            
              await searchArtistProducedSongs(artistId, "all").then((songs) => {
                produced = songs
              });
            
            setDiscography((prev) => {
              return {
                ...discography,
                produced: produced
              };
            });
          }

          setLoaded(true);
        }
        break;

      default:
        break;
    }
    setDiscogUpdate(true)
  };

  return (
    <>
      <ArtistContentContainer>
        <NavContainer>
          <ArtistProfileNav background={background}>
            <ArtistProfileNavLink
              background={background}
              active={isDiscography}
              onClick={() => setCurrentTab("discography")}
            >
              Discography
            </ArtistProfileNavLink>
            {(discography?.songs?.length > 0 ||
              discography?.albums?.length > 0 ||
              discography?.collections?.length > 0) && (
              <ArtistProfileNavLink
                background={background}
                active={isPurchase}
                onClick={() => setCurrentTab("purchase")}
              >
                Purchase Music
              </ArtistProfileNavLink>
            )}

            {about && Object.keys(about).length > 0 && (
              <ArtistProfileNavLink
                background={background}
                active={isAbout}
                onClick={() => setCurrentTab("about")}
              >
                About
              </ArtistProfileNavLink>
            )}
          </ArtistProfileNav>
        </NavContainer>

        {isDiscography && discography && (
          <Discography
          artist={artistId}
             discography={discography}
            getDiscography={getDiscography}
            isLoaded={isDiscographyLoaded && isRTLoaded && isCLoaded}
            albums={discography?.albums?.albums
            }
            singles={
              discography?.songs?.length > 0 && discography?.songs?.songs
            }
            royaltyTokens={ royaltyTokens?.length > 0 && royaltyTokens?.royalties}
            collections={collections?.length > 0 && collections?.collections }
            popular={discography?.popular?.length > 0 && discography.popular?.songs}
            videos={discography?.videos?.length > 0 && discography?.videos?.videos}
            features={
              getFeaturesAndAppearences()?.length > 0
                ? getFeaturesAndAppearences()
                : false
            }
          />
        )}

        {isPurchase &&
          (discography?.songs?.length > 0 ||
            discography?.albums?.length > 0 ||
            collections?.length > 0 || royaltyTokens?.length > 0) && (
            <ArtistPurchaseMusic
              artist={artist}
              content={{ ...discography, collections: collections, royalties: royaltyTokens }}
            />
          )}

        {isAbout && about && (
          <ArtistAbout
            socialLinks={about.accounts}
            images={about.photos}
            bio={about.bio}
            artist={artist}
            tourDates={about?.travel}
          />
        )}

        
      </ArtistContentContainer>
    </>
  );
};
