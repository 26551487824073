import React from 'react'
import { SLIDER_TYPES } from '../../../utils/Enum'
import ContentWrapper from '../../ContentWrapper'
import { Slider } from '../../Slider'

const PodcasterEpisodes = ({name, episodes, isLoaded}) => {
  return (
    <ContentWrapper heading={`${name}'s Episodes`}>
        {!isLoaded && 
        <><Slider type={SLIDER_TYPES.MUSIC_SKELETON} id={'episodes'}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        </>}
        {isLoaded && episodes?.length > 0 && 
        <Slider type={SLIDER_TYPES.EPISODE} id={'episodes'} slides={episodes}/>
        }
        {isLoaded && episodes?.length === 0 && 
        <Slider type={SLIDER_TYPES.EPISODE} id={'episodes'}/>
        }
    </ContentWrapper>
    
  )
}

export default PodcasterEpisodes