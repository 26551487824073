import React, {useState, useEffect, useRef} from 'react'
import { PushSpinner } from 'react-spinners-kit'
import { ServicesCard, ServicesContainer, ServicesH1, ServicesH2, ServicesIcon, ServicesP, ServicesSmall, ServicesWrapper } from '../Services/ServicesElements'
import { useAuth } from '../../contexts/AuthContext'
import {GrDocumentVerified} from 'react-icons/gr'
import {FaFingerprint, FaPiggyBank, FaRegIdCard, FaRegMoneyBillAlt, FaRegUser,  FaTools, FaWallet} from 'react-icons/fa'
import { Small } from '../SignUp/SignUpElements'
import hexToRgbA, { getRandomColor, hexToRgba } from '../HexToRGBA'
import { Theme } from '../WebsiteThemeElements'
import { getNumRegisteredUsers } from '../../utils/dbUtils/userUtils'

const XUMM_COMMUNITY_IMG = 'https://xrpl.services/assets/topbar_logo.png'

export const TrustlineStats = () => {
    const [stats, setStats] = useState(0)
    const tlStatsRef = useRef(0)
    const [userStats, setUserStats] = useState(0)
    const uStatsRef = useRef(0)
    const [isLoaded, setLoaded] = useState(false)
    const {getUsersStats, getTrustlineStats} = useAuth()



const trustStats = async() =>{
    
    let uStats = await getUsersStats()
   // let tStats = await getTrustlineStats()//  XUMM Community API call {Thanks to nixerFFM} :)
    if(uStatsRef.current !== uStats && uStats !== false )
    {
        //console.log('diff', uStats)
        uStatsRef.current = uStats
        setUserStats(uStats)
        
    } 
    /* if(tlStatsRef.current !== tStats && tStats !== false )
    {
       // //console.log('diff', tStats)
        tlStatsRef.current = tStats
        setStats(tStats)

    } */
    if (uStats === false /* && tStats === false */)
    {
        uStatsRef.current = 0
        setUserStats(0)
    }    
    /* if (tStats === false)
    {
        tlStatsRef.current = 0
        setStats(0)
    } */    
    setLoaded(true)
    
    return
}

       useEffect(() => {
        
        getNumRegisteredUsers(setUserStats, setLoaded) 
          
       }, [])

        return (
            <>  
                 <ServicesContainer>
                     <ServicesH1> Sonar Muse & Wymbo (WYM) Stats</ServicesH1>
                    <ServicesWrapper numGrid={2}>
                   {/*  <ServicesCard>
                        <FaWallet size="3em"/>
                        <br/>
                        {isLoaded === true && tlStatsRef.current &&
                            <><ServicesH2>Trustlines Set</ServicesH2>
                            <br/>
                            {tlStatsRef.current &&
                        
                            <>
                            
                            <ServicesP> <FaTools/> {tlStatsRef.current} </ServicesP>
                                    <br/>
                            <small>Captured from:</small>
                            
                            <ServicesP><span> <small>XRPL Services <img width='10%' src={XUMM_COMMUNITY_IMG} alt='Xumm Community'/></small> </span>
                            <br/>
                            </ServicesP>
                            </>

                        }
                        {stats === false &&
                            <ServicesP>Something is wrong</ServicesP>
                        }
                        </>}
                        {!isLoaded && 
                        <>
                        <PushSpinner loading={!isLoaded}/>
                        </>}
                    </ServicesCard> */}


<ServicesCard>
                    <ServicesIcon
              color1={getRandomColor()}
              color2={getRandomColor()}
              color3={getRandomColor()}
            >
                        <ServicesH2>Registered Users</ServicesH2> 
</ServicesIcon>
                        <br/>
                        <FaRegUser size={"3rem"}/>
                        {isLoaded && userStats &&
                            <>
                            <br/>
                            {userStats &&
                        
                            <ServicesP> {userStats} Users </ServicesP>
                        }
                        <br/>
                            <ServicesSmall >Number of users currently signed up for the Sonar Muse platform </ServicesSmall>
                        {userStats === false &&
                            <ServicesP>Something is wrong</ServicesP>
                        }
                        </>}
                        {!isLoaded && 
                        <>
                        <PushSpinner loading={!isLoaded}/>
                        </>}
                    </ServicesCard>


                   {/*  <ServicesCard>
                    <ServicesIcon
              color1={getRandomColor()}
              color2={getRandomColor()}
              color3={getRandomColor()}
            >
                        <ServicesH2>Eligible Users</ServicesH2> 
</ServicesIcon>
                        <br/>
                        <FaRegUser size={"3rem"}/>
                        {isLoaded && userStats &&
                            <>
                            <br/>
                            {userStats &&
                        
                            <ServicesP> {userStats} Users </ServicesP>
                        }
                        <br/>
                            <Small >Number of users currently signed up for the Sonar Muse platform </Small>
                        {userStats === false &&
                            <ServicesP>Something is wrong</ServicesP>
                        }
                        </>}
                        {!isLoaded && 
                        <>
                        <PushSpinner loading={!isLoaded}/>
                        </>}
                    </ServicesCard>

 */}

                    <ServicesCard>
                    <ServicesIcon
              color1={getRandomColor()}
              color2={getRandomColor()}
              color3={getRandomColor()}
            >
                                        <ServicesH2>Projected Token Distribution Per Account:</ServicesH2>  

          </ServicesIcon>
                        <FaRegMoneyBillAlt size={"3rem"}/>
                        <br/>
                        {isLoaded === true && userStats !== 0 &&
                            <>
                            <br/>
                            {userStats &&
                        
                            <ServicesP>  {(250000000 /userStats).toFixed(2)} WYM</ServicesP>
                            
                        }
                        <br/>
                            <ServicesSmall >WYM supply that will be distributed per registered user. </ServicesSmall>
                        {((userStats === false)  || (userStats === 0)) &&
                            <ServicesP>Something went wrong</ServicesP>
                        }
                        </>}
                        {!isLoaded && 
                        <>
                        <PushSpinner loading={!isLoaded}/>
                        </>}
                    </ServicesCard>

                    </ServicesWrapper>
                </ServicesContainer>
                
               
            </>
        )
}

