import {
    query,
    doc,
    getDoc,
    collection,
    getDocs,
    where,
    limit,
    orderBy,
    updateDoc,
    onSnapshot,
  } from "firebase/firestore";
  import axiosInstance from "../../components/Authorization/client";
import { db } from "../../components/Authorization/firebase";


export const getRoyaltyInfo = async (royaltyId, setRoyaltyInfo) => {
    return onSnapshot(doc(db,`userRoyalties/${royaltyId}`), async (royaltySnapshot) => {
      if(royaltySnapshot?.exists && royaltySnapshot?.data()){
      return await axiosInstance.get(`/api/royalties/${royaltyId}/getUserRoyaltyInfo`).then((royalty) => {
        if(royalty.data && !royalty.data.error){
        setRoyaltyInfo({...royalty.data,...royaltySnapshot?.data(), type:royaltySnapshot?.data().type, musicDetails: royalty.data?.musicDetails})
    }
      }).catch(err => {
        setRoyaltyInfo(prev => {
          return {
            ...prev,
            ...royaltySnapshot?.data(), type:royaltySnapshot?.data().type, musicDetails: prev?.musicDetails
          }
        })
      })}
      return false
    }, async (err) => {
      console.error(err)
      return
    })
  }

export const addPendingMusicRoyalties = async(royaltyId, amount) => {
    let payment = false
    return await getDoc(doc(db,`userRoyalties/${royaltyId}`))
          .then(async (royalty) => {
            if (royalty?.exists) {
              let pendingFunds = royalty.data()?.amount ? parseInt(royalty.data()?.amount) : 0;
              await onSnapshot(doc(db,`userRoyalties/${royaltyId}`),
              async (docSnapshot) => {
                pendingFunds = parseInt(docSnapshot.data()?.amount) > 0 ? parseInt(docSnapshot.data()?.amount) : 0;
                return pendingFunds
              },
              (err) => {
                console.error(`Encountered error: ${err}`);
                return false
              }
            );
              pendingFunds = parseInt(parseInt(pendingFunds) + parseInt(amount))

               payment = await updateDoc(doc(db,`userRoyalties/${royaltyId}`),{ amount: pendingFunds })
                  .then(() => {
                    return true;
                  })
                  .catch((err) => {
                    //console.error(`Encountered error: ${err}`);
                    return false;
                  });
                  if(payment){
                    return await incRoyaltyPendingPayment(amount)
                  }
              return payment;
            }
            return false;
          })
          .catch((err) => {
            console.error(err);
            payment = false;
            return false;
          });
}

export const incRoyaltyPendingPayment = async (amount) => {
    let payment = false
    return await getDoc(doc(db,`pendingRoyaltyPayment/streamingRoyalties`))
          .then(async (royalty) => {
              let pendingFunds = royalty.data()?.amount ? royalty.data()?.amount : 0;
              await onSnapshot(doc(db,`pendingRoyaltyPayment/streamingRoyalties`),
              async (docSnapshot) => {
                pendingFunds = parseInt(docSnapshot.data()?.amount) > 0 ? parseInt(docSnapshot.data()?.amount) : 0;
                return pendingFunds
              },
              (err) => {
                console.error(`Encountered error: ${err}`);
                return false
              }
            );
              pendingFunds = parseInt(parseInt(pendingFunds) + parseInt(amount))

               payment = await updateDoc(doc(db,`pendingRoyaltyPayment/streamingRoyalties`),{ amount: pendingFunds })
                  .then(() => {
                    return true;
                  })
                  .catch((err) => {
                    //console.error(`Encountered error: ${err}`);
                    return false;
                  });
              return payment;
          })
          .catch((err) => {
            console.error(err);
            payment = false;
            return false;
          });
}