import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";
import { MEDIA_SCREEN } from "../../utils/Enum"; 

export const MusicBannerWrapper = styled.div`
display: flex;
width: 85%;
padding: 2%;
flex-direction: row;
background-color: ${props => hexToRgba(props.theme.accentColor, 0.1)};
color: ${props => props?.theme.secondaryBg};
border-radius: 15px;

  @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    flex-direction: column-reverse;

  }

`

export const MusicBannerImageWrapper = styled.div`
display: flex;
padding: 2%;
justify-content: center;
align-items: center;

`

export const MusicBannerInfoWrapper = styled.div`
display: flex;
flex-direction: column;
padding: 2%;
width: 100%;
justify-content: center;
align-items: flex-start;
align-content: center;
`