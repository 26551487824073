import React, { useEffect, useState } from 'react'
import useMusic from '../../hooks/useMusic'
import { getObjectLength } from '../../utils'
import { SLIDER_TYPES } from '../../utils/Enum'
import ContentWrapper from '../ContentWrapper'
import { Header } from '../Header'
import { Slider } from '../Slider'

const GenrePage = ({id, isSubgenre}) => {
    const {
        getGenre,
        getSubgenre, 
        searchSongsbySubgenre, 
        searchSongsbyGenre, 
    } = useMusic({})

    const [isLoaded, setLoaded] = useState(false)
    const [isMusicLoaded, setMusicLoaded] = useState(false)
    const [genreInfo, setGenreInfo] = useState({})

    useEffect(() => {
    getGenreInfo()
    return () => {}
    
      
    }, [!isLoaded])


    const getGenreInfo = async() => {
        let info = {}
        if(isSubgenre){
            await getSubgenre(id).then((genre) => {

                info = {
                    ...info,
                    genre
                }
                setGenreInfo(genre)
                setLoaded(true)
        })
            return await Promise.all([searchSongsbySubgenre(id)]).then((data) => {
                console.log(data[0])

                if(data[0]){
                    info = {
                        ...info,
                        songs: data[0]
                    }
                }
                setMusicLoaded(true)
                /* if(data[1]){
                    info = {
                        ...info,
                        albums: data[1]
                    }
                } */
                setGenreInfo(prev => {
                    return {...prev,
                    ...info}
                })
                
        }).catch((err) => {
            setMusicLoaded(true)

            return false;
          });
        }
        else{                
            await getGenre(id).then((genre) => {

                info = {
                    ...info,
                    genre
                }
                setGenreInfo(genre)
                setLoaded(true)
        })
            return await Promise.all([searchSongsbyGenre(id)]).then((data) => {
                console.log(data[0])

                if(data[0]){
                    info = {
                        ...info,
                        songs: data[0]
                    }
                }
                /* if(data[1]){
                    info = {
                        ...info,
                        albums: data[1]
                    }
                } */
                setMusicLoaded(true)

                setGenreInfo(prev => {
                    return {
                    ...prev,
                    songs: data[0]}
                })
                //console.log(genreInfo)
                
        }).catch((err) => {
            //console.log(err)
            setMusicLoaded(true)

            return false;
          });
        }
    }
    
    
  return (
    <>
 
    {
    isLoaded && getObjectLength(genreInfo) > 0 && 
    <>
    <Header title={genreInfo?.name}/>
    <ContentWrapper>
    { isMusicLoaded && genreInfo?.songs?.length > 0 &&
    <ContentWrapper heading={`Top ${genreInfo.name} Songs`}>
        <Slider slides={genreInfo?.songs} id={'songs'} type={SLIDER_TYPES.SONG}/>
    </ContentWrapper>}   
    {!isMusicLoaded &&
    <ContentWrapper heading={`Top ${genreInfo.name} Songs`}>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
    </ContentWrapper>}
   {!isSubgenre && <ContentWrapper heading={`${genreInfo.name} Subgenres`}>    
        <Slider slides={genreInfo?.subgenres} id={'subgenres'} heightScroll={true} type={SLIDER_TYPES.GENRE}/>
</ContentWrapper>}
{isMusicLoaded && genreInfo?.albums && <ContentWrapper heading={`Music from ${genreInfo.name}`}>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
    </ContentWrapper>}
     {/* !genreInfo?.albums && <ContentWrapper heading={`Music from ${genreInfo.name}`}>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
    </ContentWrapper> */}
    
    </ContentWrapper>
    </>
    }
    {!isLoaded && 
    <>
    <Header title={'Genre'}/>
    <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
    <Slider type={SLIDER_TYPES.GENRE_SKELETON}/>
    <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
    </>
    
    }
    </>
  )
}

export default GenrePage