import styled from "styled-components";
import { hexToRgba } from "../../HexToRGBA";

export const MEDIA_SCREEN = { //Screen sizes for devices
      MOBILE: '480',
      TABLETS: '768',
      LAPTOPS: '1024',
      DESKTOPS: '1025',
      TV: '1500'
    }

export const MusicInfoWrapper = styled.section`
    display: flex;
    flex-direction: column;
    padding: 1% 5%;
    width: 100%;
`

export const MusicInfoContentWrapper = styled.div`
display: grid;
box-sizing: border-box;
gap: 30px 20px;
margin:2%;
justify-content: center;
align-items: center;
align-content: center;
grid-template-columns: repeat(${props => props?.columns ? props?.columns : 4}, minmax(0px, 1fr));

@media screen and (max-width: 735px)
{
  grid-template-columns: repeat(2, minmax(0px, 1fr));
}

@media screen and (max-width: ${MEDIA_SCREEN.MOBILE})
{
  grid-template-columns: repeat(1, minmax(0px, 1fr));
}
`

export const MusicInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1%;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        align-items: center;
      }
    
`

export const DetailsWrapper = styled.div`
      display: flex;
      padding: 2%;
      width: 50%;
      align-items: center;
      border-radius: 5px;
      justify-content: center;
      background-color: ${props => props.background ? props.background : hexToRgba(props.theme.accentColor, 0.6)};
      @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        width: 100%;
        justify-content: flex-start;
      }

`

export const Img = styled.img`
      display: flex-box;
      height: 5rem;
      width: 5rem;
      padding: 2%;
      overflow: hidden;
      border-radius: 50%;
      cursor: pointer;
      
`

export const SubHeading = styled.div`
      display: flex;
      justify-self: flex-end;
      font-size: 1.5rem;
      font-weight: 300;
      padding: 1%;

`

export const CreditsHeading = styled.div`
display: flex;
padding: 1%;
font-size: 1.2rem
align-items: center;
white-space: nowrap;
filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};

`

export const CreditsText = styled.div`
display: flex;
padding-left: 1%;
font-size: 1.2rem
align-items: center;
white-space: break-spaces;
text-align: center;
color: ${props => props.theme.secondaryColor};
filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};

@media screen and (max-width: 735px)
{
  font-size: 1rem
}

@media screen and (max-width: ${MEDIA_SCREEN.MOBILE})
{
  font-size: 0.8rem
}

`

export const Text = styled.text`
    display: flex;
    padding: 2%;
    align-items: center;
    white-space: nowrap;
    filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};

`