import React, { useState } from "react";
import Chip from "../Chip";
import { ChipList } from "../Chip/ChipElements";
import {
  FormButton1,
  FormInput,
  FormInput1,
  FormLabel,
  FormText,
  FormWrapper,
} from "../CustomForm/CustomFormElements";
import { DEFAULT_IMAGE } from "../GravatarAPI";
import { Nav, NavContainer, NavLink } from "../NavLink/NavLinkElements";
import { Theme } from "../WebsiteThemeElements";
import { SmallText } from "./NFTCollectionCreatorElements";
import { getArtistsNames2, getObjectLength, getSongTitle, validateAddress } from "../../utils";
import { ARTIST_FORM_INPUT_TYPES, ARTIST_SEARCH_TYPES } from "../../utils/Enum";
import {
  ContentListContainer,
  DetailsContainer,
  DetailsText,
  ImageWrapper,
  Img,
  Table,
  TableData,
  TableHeading,
  TableRow,
} from "../ContentList/ContentListElements";
import useArtist from "../../hooks/useArtist";
import { MusicUploadsButton } from "../MusicUploadsForm/MusicUploadsFormElements";
import useMusic from "../../hooks/useMusic";

const NFTokenDiscounts = ({
  discountType,
  price,
  currency,
  setRedeemers,
  discountRedeemers,
  currentDiscount,
  setCurrentDiscount,
  deleteSelectedDiscount,
  setDiscountType,
  addDiscount,
  editDiscount,
  isEditing,
  setEditing,
  selectDiscountType,
  selectedRedeemer,
}) => {
  const [address, setAddress] = useState(
    currentDiscount?.issuer ? currentDiscount?.issuer : ""
  );
  const [taxon, setTaxon] = useState(
    currentDiscount?.taxon ? currentDiscount?.taxon : 0
  );
  const [numTokens, setNumTokens] = useState(
    currentDiscount?.numTokens ? currentDiscount?.numTokens : 1
  );
  const [percentage, setPercentage] = useState(
    currentDiscount?.issuer ? currentDiscount?.percentage : 0
  );
  const [error, setError] = useState();

  const handleInput = (e) => {
    switch (e.target.name) {
      case "issuerAddress":
        setAddress(e.target.value);
        break;
      case "musicSearch":
        break;
      case "discountTaxon":
        if(e.target.value[0] === "0"){
          setCurrentDiscount((prev) => {
            return {
              ...prev,
              taxon: parseInt(e.target.value.substring(1,e.target.value.length)),
            };
          });
          setTaxon(parseInt(e.target.value.substring(1,e.target.value.length))); 
         }else{
          setCurrentDiscount((prev) => {
            return {
              ...prev,
              taxon: parseInt(e.target.value),
            };
          });
          setTaxon(parseInt(e.target.value));
         }
    
        break;
      case "tokenAmount":
        if(e.target.value[0] === "0"){
          setCurrentDiscount((prev) => {
            return {
              ...prev,
              numTokens: parseInt(e.target.value.substring(1,e.target.value.length)),
            };
          });
          setNumTokens(parseInt(e.target.value));
        }
        else{
        setCurrentDiscount((prev) => {
          return {
            ...prev,
            numTokens: parseInt(e.target.value),
          };
        });
        setNumTokens(parseInt(e.target.value));
      }
        break;
      case "discountPercentage":
        if(e.target.value?.length === 0){
          setCurrentDiscount((prev) => {
            return {
              ...prev,
              percentage: 0,
            };
          });
          setPercentage(0);
          return
        }
        if (e.target.value <= 80) {
          if(e.target.value[0] === "0"){
            setCurrentDiscount((prev) => {
              return {
                ...prev,
                percentage: e.target.value.substring(1,e.target.value.length),
              };
            });
            setPercentage(e.target.value.substring(1,e.target.value.length));
          }else{
            setCurrentDiscount((prev) => {
            return {
              ...prev,
              percentage: e.target.value,
            };
          });
          setPercentage(e.target.value);
          }
          
        }
        break;

      default:
        break;
    }
  };

  const onConfirmDiscount = () => {
    if (
      validateAddress(address) &&
      taxon?.length > 0 &&
      numTokens > 0 &&
      percentage > 0
    ) {
      addDiscount(discountType, {
        issuer: address,
        taxon: parseInt(taxon),
        numTokens: parseInt(numTokens),
        percentage: parseFloat(percentage),
      });
    } else {
      setError("Please check your NFToken Discount Info");
    }
  };
  const displayDiscounts = () => {
    return (
      <FormWrapper style={{ width: "100%", marginTop: "2%" }}>
        {(discountRedeemers?.music?.songs?.length > 0 || discountRedeemers?.music?.albums?.length > 0) &&  (
          <>
            <FormLabel>Digital Music</FormLabel>
            <ChipList alignment="left">
              
                <>{discountRedeemers?.music?.albums?.length > 0 &&
                <><FormLabel>Albums</FormLabel>
                  {discountRedeemers?.music?.albums?.map((album, index) => {
                  return (
                    <>
                    
                      <Chip
                        onDelete={() =>
                          deleteSelectedDiscount("DIGITAL", {type: "ALBUM", index: index})
                        }
                        onChipClick={() => {
                          setEditing(true);
                          setDiscountType("DIGITAL");
                          setCurrentDiscount({
                            ...album
                          });
                        }}

                        closeButton
                        image={album?.albumArt}
                        label={`${
                          album?.albumName
                        } (Album) | Percentage: ${album?.percentage}%`}
                      />
                    </>
                  );
                })}</>
                }
                {discountRedeemers?.music?.songs?.length > 0 && 
                <><FormLabel>Songs</FormLabel>{discountRedeemers?.music?.songs?.map((song, index) => {
                  return (
                    <>
                    
                      <Chip
                        onDelete={() =>
                          deleteSelectedDiscount("DIGITAL", {type: "SONG", index: index})
                        }
                        onChipClick={() => {
                          setEditing(true);
                          setDiscountType("DIGITAL");
                          setCurrentDiscount({
                            ...song
                          });
                        }}
                        closeButton
                        image={song?.albumArt}
                        label={`${song?.songName} | Percentage: ${song?.percentage}%`}
                      />
                    </>
                  );
                })}</>}
                
                </>
            </ChipList>
          </>
        )}
        {discountRedeemers?.nftokens?.length > 0 && (
          <>
            <FormLabel>NFTokens</FormLabel>
            <ChipList alignment="left">
              {discountRedeemers?.nftokens?.map((token, index) => {
                return (
                  <>
                    <Chip
                      background={
                        token?.issuer === currentDiscount?.issuer &&
                        token?.taxon === currentDiscount?.taxon &&
                        Theme({}).mainColor
                      }
                      onDelete={() => {
                        deleteSelectedDiscount("NFT_COLLECTION", index);
                      }}
                      closeButton
                      onChipClick={() => {
                        setEditing(true);
                        setDiscountType("NFT_COLLECTION");
                        setCurrentDiscount({
                          issuer: token.issuer,
                          taxon: token.taxon,
                          numTokens: token.numTokens,
                          percentage: token.percentage,
                        });
                      }}
                      label={`Issuer: ${token?.issuer.substring(
                        0,
                        8
                      )}... | Taxon:  ${token.taxon} | Percentage: ${
                        token.percentage
                      }%`}
                    />
                  </>
                );
              })}
            </ChipList>
          </>
        )}
        {discountRedeemers?.subscriptions?.length > 0 && (
          <>
            <FormLabel>Subscriptions</FormLabel>
            <ChipList alignment="left">
              {discountRedeemers?.subscriptions?.length > 0 &&
                discountRedeemers?.subscriptions?.map((subscription, index) => {
                  return (
                    <>
                      <Chip
                        background={
                          subscription?.artist === currentDiscount?.artist &&
                          Theme({}).mainColor
                        }
                        onDelete={() =>
                          deleteSelectedDiscount("SUBSCRIPTION", index)
                        }
                        onChipClick={() => {
                          setEditing(true);
                          setDiscountType("SUBSCRIPTION");
                          setCurrentDiscount({
                            artist: subscription?.artist,
                            percentage: subscription.percentage,
                          });
                          //console.log(subscription);
                        }}
                        closeButton
                        image={
                          subscription?.artist?.photoUrl
                            ? subscription?.artist.photoUrl
                            : DEFAULT_IMAGE(50)
                        }
                        label={`${subscription?.artist?.artistName} | Percentage: ${subscription?.percentage}%`}
                      />
                    </>
                  );
                })}
            </ChipList>
          </>
        )}
      </FormWrapper>
    );
  };

  return (
    <FormWrapper>
      <FormLabel>Discounts</FormLabel>
      <NavContainer>
        <Nav>
          <NavLink
            active={discountType === "DIGITAL"}
            onClick={() => {
              selectDiscountType("DIGITAL");
            }}
          >
            Digital Purchase
          </NavLink>
          <NavLink
            active={discountType === "NFT_COLLECTION"}
            onClick={() => {
              selectDiscountType("NFT_COLLECTION");
            }}
          >
            NFT Collection
          </NavLink>
          <NavLink
            active={discountType === "SUBSCRIPTION"}
            onClick={() => {
              selectDiscountType("SUBSCRIPTION");
            }}
          >
            Subscription
          </NavLink>
        </Nav>
      </NavContainer>
      {discountType === "NFT_COLLECTION" && (
        <SmallText>
          Create Discounts for holders of specific NFToken Collections on the
          XRPL
        </SmallText>
      )}
      {discountType === "DIGITAL" && (
        <SmallText>
          Create Discounts for purchasers of music sold digitally on the Sonar
          Muse platform
        </SmallText>
      )}
      {discountType === "SUBSCRIPTION" && (
        <SmallText>
          Create Discounts for holders of subscribers of artists on the Sonar
          Muse platform
        </SmallText>
      )}

      {displayDiscounts()}
      {discountType?.trim()?.length > 0 && (
        <FormWrapper>
          {/* discountType === "DIGITAL" && (
        <>
          <FormLabel>Music</FormLabel>
          <FormInput
            onChange={handleInput}
            placeholder={"NFT Issuer Address"}
            name={"musicSearch"}
            value={address}
          />
        </>
      ) */}
          {discountType === "NFT_COLLECTION" && (
            <>
              <FormWrapper>
                <FormLabel>Issuer Wallet Address</FormLabel>
                <FormInput1
                  onChange={handleInput}
                  placeholder={"Wallet Address"}
                  name={"issuerAddress"}
                  value={currentDiscount?.issuer}
                />
                <FormText>
                  The issuer address of the NFToken collection
                </FormText>
              </FormWrapper>
              <FormWrapper>
                <FormLabel>NFToken Collection Taxon</FormLabel>
                <FormInput1
                  onChange={handleInput}
                  placeholder={"Collection Taxon"}
                  name={"discountTaxon"}
                  min={0}
                  step={1}
                  value={currentDiscount?.taxon}
                />
                <FormText>
                  The taxon number (collection ID) the NFToken collection
                </FormText>
              </FormWrapper>
              <FormWrapper>
                <FormLabel>Minimum Number of NFTokens</FormLabel>
                <FormInput1
                  onChange={handleInput}
                  placeholder={"Minimum Number of Tokens"}
                  name={"tokenAmount"}
                  min={1}
                  step={1}
                  value={currentDiscount?.numTokens}
                />
                <FormText>
                  The minimum number of the NFTokens the redeemer should hold to
                  redeem discount
                </FormText>
              </FormWrapper>
              <FormWrapper>
                <FormLabel>Discount Percentage</FormLabel>
                <FormInput1
                  name="discountPercentage"
                  onChange={handleInput}
                  placeholder={`Set the discount percentage to be redeemed`}
                  value={currentDiscount?.percentage}
                  min={0.1}
                  max={80}
                  step={0.1}
                />
                {currentDiscount?.percentage > 0 && (
                  <FormText>
                    DISCOUNT PRICE:{" "}
                    {parseFloat(
                      price - price * (currentDiscount?.percentage / 100)
                    ).toFixed(2)}{" "}
                    {currency}
                    <SmallText>
                      (
                      {parseFloat(
                        price * (currentDiscount?.percentage / 100)
                      ).toFixed(2)}{" "}
                      {currency} OFF)
                    </SmallText>
                  </FormText>
                )}
                <SmallText>
                  The percentage that the redeemer will receive (max percentage
                  is 80%)
                </SmallText>
              </FormWrapper>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  padding: "2%",
                  justifyContent: "center",
                }}
              >
                {
                  <FormButton1
                    onClick={() => {
                      onConfirmDiscount();
                    }}
                  >
                    {isEditing ? "Edit" : "Confirm"}
                  </FormButton1>
                }
                <FormButton1
                  onClick={() => {
                    setDiscountType("");
                    setCurrentDiscount({});
                  }}
                >
                  Cancel
                </FormButton1>
              </div>{" "}
            </>
          )}

          {discountType === "DIGITAL" && (
            <>
              <MusicDiscountForm
              addDiscount={addDiscount}
              discountType={discountType}
              error={error}
              setDiscountType={setDiscountType}
              setError={setError}
                isEditing={isEditing}
                setEditing={setEditing}
                currency={currency}
                music={discountRedeemers?.music}
                price={price}
                setCurrentDiscount={setCurrentDiscount}
                currentDiscount={currentDiscount}
              />
            </>
          )}
          {discountType === "SUBSCRIPTION" && (
            <>
              <ArtistDiscountForm
                error={error}
                setEditing={setEditing}
                setError={setError}
                artists={discountRedeemers.subscriptions}
                addDiscount={addDiscount}
                isEditing={isEditing}
                currency={currency}
                discountType={discountType}
                price={price}
                setCurrentDiscount={setCurrentDiscount}
                setDiscountType={setDiscountType}
                currentDiscount={currentDiscount}
              />
            </>
          )}
        </FormWrapper>
      )}
      {error?.length > 0 && <SmallText error>{error}</SmallText>}
      <SmallText>
        Set the discount percentages of your collection. Select how users can
        redeem discount.
      </SmallText>
    </FormWrapper>
  );
};

export function MusicDiscountForm({ setError, error, addDiscount, discountType, setDiscountType, currentDiscount, setCurrentDiscount, isEditing, setEditing, price, currency, music }) {
  const [percentage, setPercentage] = useState(
    currentDiscount?.percentage ? currentDiscount?.percentage : 0
  );
  const [searchType, setSearchType] = useState(currentDiscount?.albumId ? "ALBUM" : "SONG")
  const [musicSearchInput, setSearchInput] = useState("")
  const [musicSearch, setMusicSearch] = useState();
  const [song, setSong] = useState(
    currentDiscount?.song ? currentDiscount?.song : {}
  );
  const [album, setAlbum] = useState(
    currentDiscount?.album ? currentDiscount?.album : {}
  );
  const {getDownloadableSongsByName, getDownloadableAlbumsByName} = useMusic({})

  const handleInput = (e) => {
    switch (e.target.name) {
      case "songSearch":
        setSearchInput(e.target.value);
        if (e.target.value?.length > 0) {
          
          getDownloadableSongsByName(e.target.value).then((data) => {
            if (data && (!data.error || data !== false)) {
              
              setMusicSearch(
                data.filter(
                  (dSong) => !music?.songs?.some(s => s?.songId === dSong?.songId))
                
              );
              //console.log(musicSearch)
            }
          });
        }
        break;
      case "albumSearch":
        setSearchInput(e.target.value);
        if (e.target.value?.length > 0) {
          
          getDownloadableAlbumsByName(e.target.value).then((data) => {
            if (data && (!data.error || data !== false)) {
              setMusicSearch(
                data.filter(
                  (album) =>
                  !music?.albums?.some(a => a?.albumId === album?.albumId))
                
              );
            }
          });
        }
        break;
      case "discountPercentage":
        if (e.target.value > 0 && e.target.value <= 80) {
          setCurrentDiscount((prev) => {
            return {
              ...prev,
              percentage: e.target.value,
            };
          });
          setPercentage(e.target.value);
        }
        break;

      default:
        break;
    }
  };

  const onConfirmDiscount = () => {
    setError();
    if (parseFloat(currentDiscount?.percentage) > 0) {
      addDiscount(discountType, {type: searchType, music: {
        ...currentDiscount,
        percentage: currentDiscount?.percentage,
      }});
    } else {
      setError("Please check your NFToken Discount Info");
    }
  };

  const selectMusic = (music) => {
    setCurrentDiscount({
      ...music,
      percentage: 0,
    });
    if(music?.albumId){
      setAlbum(music);
    }else{
      setSong(music);
    }
    
    setPercentage(0);
    setSearchInput("");
    setMusicSearch();
  };

  const displaySearch = () => {
    switch (searchType) {
      case "SONG":
        return (
          <ContentListContainer maxHeight={"80%"}>
            <>
              <Table>
                  {musicSearch?.map((song, index) => {
                    return (
                      <>
                        <TableRow
                          
                          key={index}
                          id={song.songId}
                          onClick={() => {
                            selectMusic(song);
                          }}
                        >
                          <TableData>
                            <ImageWrapper>
                              <Img
                                isCircular={true}
                                src={
                                  song?.albumArt
                                    ? song.albumArt
                                    : DEFAULT_IMAGE(800)
                                }
                                alt={DEFAULT_IMAGE}
                              />
                            </ImageWrapper>
                          </TableData>
                          <TableData>
                            <DetailsContainer>
                              <DetailsText>{song.songName}</DetailsText>
                            </DetailsContainer>
                          </TableData>
                        </TableRow>
                      </>
                    );
                  })}
          
                  
          </Table>
          </>
        </ContentListContainer>
      )
        break;
    
      case "ALBUM":
        return (
<ContentListContainer maxHeight={"80%"}>
            <>
              <Table>{musicSearch?.map((album, index) => {
                    
                    return (
                      <>
                        <TableRow
                          key={index}
                          id={album.albumId}
                          onClick={() => {
                            selectMusic(album);
                          }}
                        >
                          <TableData>
                            <ImageWrapper>
                              <Img
                                isCircular={true}
                                src={
                                  album?.albumArt
                                    ? album.albumArt
                                    : DEFAULT_IMAGE(800)
                                }
                                alt={DEFAULT_IMAGE}
                              />
                            </ImageWrapper>
                          </TableData>
                          <TableData>
                            <DetailsContainer>
                              <DetailsText>{album.albumName}</DetailsText>
                            </DetailsContainer>
                          </TableData>
                        </TableRow>
                      </>
                    );
                    
                  })}
                    </Table>
          </>
        </ContentListContainer>
        )
        break;
    
      default:
        break;
    }
  }

  const displayMusicDetails = () => {
    return (
      <>
        <FormWrapper>
          <FormLabel>Title</FormLabel>
          <FormInput
            readOnly={true}
            value={currentDiscount?.albumName ? currentDiscount.albumName : getSongTitle(currentDiscount)}
          />
        </FormWrapper>
        
          <FormWrapper>
            <FormLabel>Artist(s)</FormLabel>
            <FormInput
              name={ARTIST_FORM_INPUT_TYPES.FULL_NAME}
              readOnly={true}
              value={getArtistsNames2(currentDiscount?.artists,ARTIST_SEARCH_TYPES.NAME)}
            />
          </FormWrapper>
        
        <FormWrapper>
          <FormLabel>Discount Percentage</FormLabel>
          <FormInput1
            name="discountPercentage"
            onChange={handleInput}
            placeholder={`Set the discount percentage to be redeemed`}
            value={currentDiscount?.percentage}
            type="number"
            min={0.1}
            max={80}
            step={0.1}
          />
          {percentage > 0 && (
            <FormText>
              DISCOUNT PRICE:{" "}
              {parseFloat(price - price * (percentage / 100)).toFixed(2)}{" "}
              {currency}
              <SmallText>
                ({parseFloat(price * (percentage / 100)).toFixed(2)} {currency}{" "}
                OFF)
              </SmallText>
            </FormText>
          )}
          <SmallText>
            The percentage that the redeemer will receive (max percentage is
            80%)
          </SmallText>
        </FormWrapper>

        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "2%",
            justifyContent: "center",
          }}
        >
          {
            <FormButton1
              onClick={() => {
                onConfirmDiscount();
              }}
            >
              {isEditing ? "Edit" : "Confirm"}
            </FormButton1>
          }
          <FormButton1
            onClick={() => {
              setDiscountType("DIGITAL");
              setSong();
              setAlbum();
              setEditing(false)
              setSearchInput("")
              setCurrentDiscount({});
              setPercentage(0);
            }}
          >
            Cancel
          </FormButton1>
        </div>
      </>
    );
  };

  return (<>
 {getObjectLength(currentDiscount) === 0 && (<><FormWrapper>
       
          
            <FormLabel>Title</FormLabel>
            <FormInput
              name={searchType === "SONG" ? "songSearch" : "albumSearch"}
              placeholder={`Search for a ${searchType.toLowerCase()}`}
              onChange={handleInput}
              value={musicSearchInput}
            />
         
        
        <Nav>
          <NavContainer>
            <NavLink
              active={searchType === "SONG"}
              onClick={() => {
                setSearchType("SONG")
                setSearchInput()
                setMusicSearch()
            }}
            >
              Song
            </NavLink>
            <NavLink
              active={searchType === "ALBUM"}
              onClick={() => {
                setSearchInput()
                setSearchType("ALBUM")
                setMusicSearch()
            }}
            >
              Album
            </NavLink>
          </NavContainer>
        </Nav>
      </FormWrapper> </>)}
      {musicSearch?.length > 0 && displaySearch()}
      {getObjectLength(currentDiscount) > 0 && displayMusicDetails()}
  </>);
}

export function ArtistDiscountForm({
  error,
  setError,
  discountType,
  addDiscount,
  price,
  currency,
  currentDiscount,
  artists,
  setDiscountType,
  isEditing,
  setEditing,
  setCurrentDiscount,
}) {
  const [percentage, setPercentage] = useState(
    currentDiscount?.percentage ? currentDiscount?.percentage : 0
  );
  const [artistSearch, setArtistSearch] = useState([]);
  const [artist, setArtist] = useState(
    currentDiscount?.artist ? currentDiscount?.artist : {}
  );
  const [artistInput, setArtistInput] = useState(
    artist?.artistName ? artist?.artistName : ""
  );
  const [searchType, setSearch] = useState(ARTIST_SEARCH_TYPES.NAME);
  const { searchArtists } = useArtist({});

  const handleInput = (e) => {
    switch (e.target.name) {
      case "artistSearch":
        if (e.target.value?.length > 0) {
          setArtistInput(e.target.value);
          searchArtists(searchType, e.target.value).then((data) => {
            if (data && (!data.error || data !== false)) {
              setArtistSearch(
                data.filter(
                  (a) =>
                    artists?.findIndex(
                      (ai) => ai?.artist?.artistId === a.artistId
                    ) === -1
                )
              );
            }
          });
        }
        break;
      case "discountPercentage":
        if (e.target.value > 0 && e.target.value <= 80) {
          setCurrentDiscount((prev) => {
            return {
              artist: artist,
              percentage: e.target.value,
            };
          });
          setPercentage(e.target.value);
        }
        break;

      default:
        break;
    }
  };

  const onConfirmDiscount = () => {
    setError();
    if (parseFloat(currentDiscount?.percentage) > 0) {
      addDiscount(discountType, {
        artist: currentDiscount?.artist,
        percentage: currentDiscount?.percentage,
      });
    } else {
      setError("Please check your NFToken Discount Info");
    }
  };

  const selectArtist = (artist) => {
    setCurrentDiscount({
      artist: artist,
      percentage: 0,
    });
    setArtist(artist);
    setPercentage(0);
    setArtistInput("");
    setArtistSearch();
  };

  const displayArtistDetails = () => {
    return (
      <>
        <FormWrapper>
          <FormLabel>Artist Name</FormLabel>
          <FormInput
            name={ARTIST_FORM_INPUT_TYPES.NAME}
            readOnly={true}
            value={currentDiscount?.artist?.artistName}
            onChange={(e) => handleInput(e)}
          />
        </FormWrapper>
        {currentDiscount?.artist?.fullName && (
          <FormWrapper>
            <FormLabel>Full Name</FormLabel>
            <FormInput
              name={ARTIST_FORM_INPUT_TYPES.FULL_NAME}
              readOnly={true}
              value={currentDiscount?.artist?.fullName}
            />
          </FormWrapper>
        )}
        <FormWrapper>
          <FormLabel>Artist ID</FormLabel>
          <FormInput
            name={ARTIST_FORM_INPUT_TYPES.ID}
            readOnly={true}
            value={currentDiscount?.artist?.artistId}
          />
        </FormWrapper>
        {artist?.address && (
          <FormWrapper>
            <FormLabel>Wallet Address</FormLabel>
            <FormInput
              name={ARTIST_FORM_INPUT_TYPES.ADDRESS}
              readOnly={true}
              value={currentDiscount?.artist?.address}
            />
          </FormWrapper>
        )}
        <FormWrapper>
          <FormLabel>Discount Percentage</FormLabel>
          <FormInput1
            name="discountPercentage"
            onChange={handleInput}
            placeholder={`Set the discount percentage to be redeemed`}
            value={currentDiscount?.percentage}
            type="number"
            min={0.1}
            max={80}
            step={0.1}
          />
          {percentage > 0 && (
            <FormText>
              DISCOUNT PRICE:{" "}
              {parseFloat(price - price * (percentage / 100)).toFixed(2)}{" "}
              {currency}
              <SmallText>
                ({parseFloat(price * (percentage / 100)).toFixed(2)} {currency}{" "}
                OFF)
              </SmallText>
            </FormText>
          )}
          <SmallText>
            The percentage that the redeemer will receive (max percentage is
            80%)
          </SmallText>
        </FormWrapper>

        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "2%",
            justifyContent: "center",
          }}
        >
          {
            <FormButton1
              onClick={() => {
                onConfirmDiscount();
              }}
            >
              {isEditing ? "Edit" : "Confirm"}
            </FormButton1>
          }
          <FormButton1
            onClick={() => {
              setDiscountType("SUBSCRIPTION");
              setArtist();
              setEditing(false)
              setArtistInput("")
              setCurrentDiscount({});
              setPercentage(0);
            }}
          >
            Cancel
          </FormButton1>
        </div>
      </>
    );
  };

  const displaySearch = () => {
    return (
      <FormWrapper>
        <ContentListContainer>
          <>
            <Table>
              <TableRow>
                <TableHeading></TableHeading>
                <TableHeading></TableHeading>
                <TableHeading></TableHeading>
              </TableRow>
              {artistSearch?.map((artist, index) => {
                return (
                  <>
                    <TableRow
                      key={index}
                      id={artist.artistId}
                      onClick={() => {
                        selectArtist(artist);
                      }}
                    >
                      <TableData>
                        <Img
                          isCircular={true}
                          src={
                            artist?.photoUrl
                              ? artist.photoUrl
                              : DEFAULT_IMAGE(50)
                          }
                          alt={artist?.artistName}
                        />
                      </TableData>
                      <TableData>
                        <DetailsContainer>
                          <DetailsText>{artist.artistName}</DetailsText>
                        </DetailsContainer>
                      </TableData>
                    </TableRow>
                  </>
                );
              })}
            </Table>
          </>
        </ContentListContainer>
      </FormWrapper>
    );
  };

  return (
    <>
      <FormWrapper>
        {!currentDiscount?.artist && (
          <>
            <FormLabel>Artist</FormLabel>
            <FormInput
              name={"artistSearch"}
              placeholder={`Search for Artist by ${searchType}`}
              onChange={handleInput}
              value={artistInput}
            />
          </>
        )}
        <Nav>
          <NavContainer>
            <NavLink
              active={searchType === ARTIST_SEARCH_TYPES.NAME}
              onClick={() => setSearch(ARTIST_SEARCH_TYPES.NAME)}
            >
              Artist Name
            </NavLink>
            <NavLink
              active={searchType === ARTIST_SEARCH_TYPES.FULL_NAME}
              onClick={() => setSearch(ARTIST_SEARCH_TYPES.FULL_NAME)}
            >
              Full Name
            </NavLink>
            <NavLink
              active={searchType === ARTIST_SEARCH_TYPES.ID}
              onClick={() => setSearch(ARTIST_SEARCH_TYPES.ID)}
            >
              Artist ID
            </NavLink>
            <NavLink
              active={searchType === ARTIST_SEARCH_TYPES.ADDRESS}
              onClick={() => setSearch(ARTIST_SEARCH_TYPES.ADDRESS)}
            >
              Wallet Address
            </NavLink>
          </NavContainer>
        </Nav>
      </FormWrapper>
      {artistSearch?.length > 0 && (
        <FormWrapper> {displaySearch()}</FormWrapper>
      )}
      {getObjectLength(currentDiscount?.artist) > 0 && displayArtistDetails()}
    </>
  );
}

export default NFTokenDiscounts;
