import React from 'react'
import styled from 'styled-components'
import { hexToRgba } from '../HexToRGBA'

export const AlbumArtContainer = styled.div`
    display: flex;
    height: ${props => props.size ? props.size : '1rem' };
    width: ${props => props.size ? props.size : '1rem'};
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    background: ${props => props.background ? `linear-gradient(to left, ${hexToRgba(props.theme.accentColor, 0.5) },${hexToRgba(props.background, 0.7) })` : `linear-gradient(to left, ${hexToRgba(props.theme.accentColor, 0.5) },${hexToRgba(props.theme.mainColor, 0.7) })`};
    border-radius: ${props => props?.isRounded ? '50%': props.albumArt ? '15px' : '0px'};
    margin: 1%;
    border: ${props => props.albumArt? 'none' :"2px dashed white"};
    @media screen and (max-width: 480px) {
      height: 10rem;
      width: 10rem;
    }

`


export const AlbumArtPreview = styled.img`
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: ${props => props?.isRounded ? '50%': props.albumArt ? '15px' : '0px'};

`

const AlbumArt = ({background, albumArt, alt, size, isRounded}) => {
  return (
    <><AlbumArtContainer isRounded={isRounded} background={background} size={size}>
        <AlbumArtPreview isRounded={isRounded} src={albumArt} alt={alt} size={size}/>
    </AlbumArtContainer></>
  )
}

export default AlbumArt