import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ArtistAlbums from '../../components/ViewAllContent/ArtistAlbums';
import { Home, Welcome } from '../../components/Navbar/Data';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';

const ArtistAlbumsPage = () => {
    const navigate  = useNavigate()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}  
    const {artistId} = useParams()

    useEffect(() => {
        if(!artistId)
        {
            navigate(-1)
        }
        return () => {}
    }, [artistId])
    return( 
        <>
              <Sidebar
            isOpen={isOpen}
            toggle={toggle}
            navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
          />
            <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
            <ArtistAlbums artistId={artistId}/>
            
        </>
        )
}

export default ArtistAlbumsPage