import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Footer from '../../../components/Footer'
import Navbar from '../../../components/Navbar'
import {Home } from '../../../components/Navbar/Data'
import Profile from '../../../components/Profile'
import Sidebar from '../../../components/Sidebar'

const UserProfile = () => {
    const [userProfile, setUser] = useState()
    const {userId} = useParams()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}
    const navigate  = useNavigate()
    
    useEffect(() => {
        let uProfile = userId
        if(!userId)
        {
            navigate( '/')
        }
        setUser(uProfile)
        return () => {}
    }, [userId])



    return( 
        <>
              <Sidebar
            isOpen={isOpen}
            toggle={toggle}
            navInfo={Home(localStorage.getItem("accInfo"))}
          />
            <Navbar toggle={toggle} navInfo={Home(localStorage.getItem("accInfo"))}/>
            <Profile user={userId}/>
            
        </>
        )
    
}

export default UserProfile