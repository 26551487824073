import styled from "styled-components";
import { hexToRgba } from "../../HexToRGBA";
import { CardTitle } from "../CardElements";
import { getResizedImage } from "../../../utils/FileUploadsUtils";

export const CardContainer = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 2%;
  border-radius: 50px;
  align-content: space-around;
align-items: left;
justify-content: left;
  background: ${props => hexToRgba(props.theme.accentColor, 0.05)};


`
export const PodcastCardWrapper = styled.div`
    display: flex;
    width: 100%;
    min-width: 300px;
    max-width: 300px;
    height: 100%;
    min-height: 150px;
    max-height: 150px;
    align-items: center;
    border-radius: 25px;
    background: ${props => props.color} url(${props => props.src}) center;
    background-size: cover;
    box-shadow: 0 10px 3px rgba(0, 0, 0, 0.3);

`

export const PodcastImgContainer = styled.div`
    display: flex;
    width: 8rem;
    height: 8rem;


    
`

export const PodcastImg = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    background: ${props => props.color} url(${props => props.src}) center;
    background-size: cover;
    border-radius: 15px;
    box-shadow: 0 10px 3px rgba(0, 0, 0, 0.3);

`

export const PodcastCardBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3%;
    border-radius: 5%;
    background: ${props => hexToRgba(props.theme.mainBg,0.8)};
    &:hover {
        transform: scale(1.03);
        transition: transform 0.3s ease;
    }

`

export const PodcastDetails = styled.div`
    display: inline-flex;

`

export const PodcastButtonWrapper = styled.div`
    display: flex;

`
export const PodcastCardContent = styled.div`
    display: flex;
    font-size: 0.8rem;
    flex-direction: column;
    padding: 6%;
    justify-content: left;
    align-content: space-evenly;
`

export const PodcastCardTitle = styled(CardTitle)`
display: flex;
font-size: 0.9rem;
margin-bottom: 1px;
white-space: normal;
text-align: left;
word-wrap: break-word;
cursor: pointer;
justify-content: space-between;
color: ${props => props.theme.secondaryColor};
&:hover {
        transform: scale(1.03);
        text-decoration: underline;
        transition: transform 0.3s ease;
    }
`

export const CreatorInfoWrapper = styled.div`
    display: flex;
    cursor: pointer;
    gap: 0.7rem;
    align-items: center;
    margin-bottom: 3%;
    justify-content: space-evenly;
`

export const CreatorInfoContent = styled.div`
    display: flex;
    flex-direction: column;
    
`

export const CreatorInfo = styled.div`
    display: flex;
    font-size: 0.9rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    width: 100%;
`

export const H6 = styled.h6`
    font-size: 0.8rem;
    color: ${props => hexToRgba(props.theme.accentColor, 1)};
`

export const InfoContentText = styled.div`
    display: flex;
    white-space: normal;
    color: ${props => props.theme.secondaryBg};
    word-wrap: break-word;
    font-size: 0.8rem;
    &: hover{
    color: ${props => props.theme.secondaryColor};
    text-decoration: underline;

   }
`