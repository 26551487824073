import React, { useEffect } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'



export const LogAuthorizationRoute = () => {
    const {currentUser} = useAuth()
    const goHome = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accInfo");
        return (<Navigate to={'/'}/>)
    }
    return (
        currentUser?.uid ?
                    //console.log('Authed !!!')
                    <Outlet/>
                : goHome()
                    

    )
}
export const AdminAuthorizationRoute = () => {
    const {currentUser} = useAuth()
    return (
        currentUser?.uid && currentUser?.isAdmin ? <Outlet/>
                : <Navigate to={"/"
                        
                    }

                    />
            
    )
}


export const ArtistAuthorizationRoute = () => {
    const {currentUser} = useAuth()
    return (
        currentUser?.uid && currentUser?.isArtist ? <Outlet/>
                : <Navigate to={"/"
                        
                    }

                    />
            
    )
}
export const LabelAuthorizationRoute = () => {
    const {currentUser} = useAuth()
    return (
        currentUser?.uid && currentUser?.isLabel ? <Outlet/>
                : <Navigate to={"/"
                        
                    }

                    />
            
    )
}
export const PodcasterAuthorizationRoute = () => {
    const {currentUser} = useAuth()
    return (
        currentUser?.uid && currentUser?.isPodcaster ? <Outlet/>
                : <Navigate to={"/"
                        
                    }

                    />
            
    )
}