import React from 'react'
import { MusicBannerImageWrapper, MusicBannerInfoWrapper, MusicBannerWrapper } from './MusicBannerElements'
import { SkeletonPurchaseCardImg } from '../Skeleton/SkeletonElements'
import { PlayButton, PlayContainer } from '../Card/DigitalPurchaseCard/DigitalPurchaseCardElements'

const MusicBanner = ({ isImg, img, text, onClick}) => {
  return (
    <>
    <MusicBannerWrapper>
        <MusicBannerImageWrapper>
            {isImg && <SkeletonPurchaseCardImg
                onClick={() => onClick()}
                src={img}
              >
                
                  <PlayContainer>
                    <PlayButton
                      style={{ width: "6rem", height: "6rem" }}
                      onClick={() => onClick()}
                    />
                  </PlayContainer>
                
              </SkeletonPurchaseCardImg>}
            {!isImg && img}
        </MusicBannerImageWrapper>
        <MusicBannerInfoWrapper>
            {text}
        </MusicBannerInfoWrapper>
    </MusicBannerWrapper>
    
    </>
  )
}

export default MusicBanner