import React, { useEffect, useState } from "react";
import ScrollToTop from "../../ScrollToTop";
import { DraftOptions} from "../../MenuOptions";
import { useModal } from "../../Modal/ModalContext";
import { SkeletonMusicCard } from '../../Skeleton/SkeletonElements';
import ImageUtils from "../../../utils/ImageUtils";
import { CardContainer, CardContent, CardTitle, H6 } from "./DraftUploadCardElements";
import { getResizedImage } from "../../../utils/FileUploadsUtils";

const DraftUploadCard = ({content}) => {
    const {goTo, scrollToTop} = ScrollToTop()
    const {openModal, modalContent} = useModal()
    const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
    const [isAlbumArtLoaded,setAlbumArtLoaded] = useState(false)
  const [albumArt, setAlbumArt] = useState(content?.albumArt?.url ? content?.albumArt?.url : content?.thumbnail?.url? content?.thumbnail?.url: undefined)

  useEffect(() => {
    let art = content?.albumArt?.url ? content?.albumArt?.url : content?.thumbnail?.url 
    if(!isAlbumArtLoaded && (content?.albumArt?.url || content?.thumbnail?.url)){
      getResizedImage(art).then(image => {
        setAlbumArt(image)
        
      }).catch(err => {
        setAlbumArt(art)
      })
      setAlbumArtLoaded(true)
    }
  }, [])
    const musicCard = () => {
        return (
          <CardContainer>
            <SkeletonMusicCard
            id={content?.draftId}
             onContextMenu={(e) => {
              e.preventDefault();
              openModal();
						modalContent(
						  <>
							<DraftOptions draft={content} />
						  </>
						);
            }}
            onClick={ content?.draftId ? () =>{
                openModal();
                modalContent(
                  <>
                  <DraftOptions draft={content} />
                  </>
                );} : {}
            }
            img={isAlbumArtLoaded ? albumArt : content?.albumArt?.url ? content?.albumArt?.url : content?.thumbnail?.url? content?.thumbnail?.url: defaultAlbumArt}
          >
           
          </SkeletonMusicCard>
          <CardContent>
  <H6>{content.type[0].toUpperCase()}{content?.type.substring(1)}</H6>
    <CardTitle onClick={ content?.draftId ? () =>{
                openModal();
                modalContent(
                  <>
                  <DraftOptions draft={content} />
                  </>
                );} : {}}>
    {content.type === 'album' ? content?.albumName ? content?.albumName : "Untitled Album" : content.type === 'song' ? content?.songName ? content?.songName : "Untitled Song" : content?.episodeName ? content?.episodeName :"Untitled Draft"}

    </CardTitle>

  </CardContent>
          </CardContainer>
        )
    }


    return (
        <>
       {musicCard()}
      </>
    )
}

export default DraftUploadCard
