import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { Home, Welcome } from '../../components/Navbar/Data';
import Navbar from '../../components/Navbar';
import ArtistSongs from '../../components/ViewAllContent/ArtistSongs';

const ArtistSongsPage = () => {
    const navigate  = useNavigate()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}  
    const {artistId} = useParams()

    useEffect(() => {
        if(!artistId)
        {
            navigate(-1)
        }
        return () => {}
    }, [artistId])

    return( 
        <>
              <Sidebar
            isOpen={isOpen}
            toggle={toggle}
            navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
          />
            <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
            <ArtistSongs artistId={artistId}/>
            
        </>
        )
}

export default ArtistSongsPage