import React from 'react'
import { ContentContainer, ContentHeading, ContentHeadingWrapper, ContentSubheading } from './ContentWrapperElements'
import { HiChevronRight } from 'react-icons/hi'

function ContentWrapper({children, heading, subheading, onClick}) {
  return (
    <><ContentContainer>
        <ContentHeadingWrapper>
            <ContentHeading onClick={onClick}>{heading}{onClick && <div style={{alignContent: "center", display:"flex"}}><HiChevronRight/></div>}</ContentHeading>
            {subheading && <ContentSubheading onClick={onClick}>{subheading}</ContentSubheading>}
        </ContentHeadingWrapper>
            <>{children}</>
        
        </ContentContainer></>
  )
}

export default ContentWrapper