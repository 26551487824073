import React, { useState } from "react";
import {
  ErrorWrapper,
  FormInput,
  FormLabel,
  FormWrapper,
  SongDetailsSection,
  SongDetailsSectionTitle,
  SongDetailsText,
} from "./MusicUploadsFormElements";
import CurrencySelector from "../CurrencySelector";
import useMusic from "../../hooks/useMusic";
import { tickerRegex } from "../../utils/Regex";

const UserRoyaltyForm = ({
  setRoyaltyInfo,
  royaltyInfo,
  index,
  errors,
  setErrors,
  isEditing
}) => {
  const [price, setPrice] = useState(0);
  const [percentage, setPercentage] = useState(10);
  const [shares, setShares] = useState(100);
  const [reserve, setReserve] = useState(0);
  const [ticker, setTicker] = useState("")
  const { validateRoyaltyTokenTicker } = useMusic();

  const handleInput = async (e) => {
    let prevErrors = errors;
    switch (e.target.name) {
      case "share " + index:
        delete prevErrors?.userRoyalty?.shares;
        setErrors(prevErrors);
        setShares(parseInt(e.target.value));
        setRoyaltyInfo((prev) => {
          return {
            ...prev,
            price: parseInt(e.target.value),
          };
        });
        if (e.target.value?.length === 0) {
          prevErrors = {
            ...prevErrors,
            UserRoyalty: {
              ...prevErrors.UserRoyalty,
              shares: "Invalid Shares. Enter a Shares of 10 or greater",
            },
          };
          setErrors(prevErrors);
          setShares(0);
          setRoyaltyInfo((prev) => {
            return {
              ...prev,
              shares: 100,
            };
          });
        }
        if (
          !isNaN(e.target.value) &&
          e.target.value >= 2 &&
          e.target.value <= 20
        ) {
          return;
        }
        if (isNaN(e.target.value)) {
          prevErrors = {
            ...prevErrors,
            shares: "Invalid Shares. Enter a Shares of 10 or greater",
          };
          setShares(parseInt(0));
          setRoyaltyInfo((prev) => {
            return {
              ...prev,
              shares: parseInt(0),
            };
          });
          /* setErrors(err) */
          return;
        }
        if (e.target.value > 20) {
          setShares(parseInt(100));
          setRoyaltyInfo((prev) => {
            return {
              ...prev,
              shares: parseInt(100),
            };
          });
        }
        break;
      case "ticker " + index:
        delete prevErrors?.userRoyalty?.ticker
        setErrors(prevErrors)
        if(e.target.value.length <= 15 && e.target.value[0] !== '0'){
          setTicker(e.target.value)
          setRoyaltyInfo( prev => {
            return {
              ...prev,
              ticker: e.target.value
            }
          })
          if(!tickerRegex.test(e.target.value)){
            prevErrors = {
              ...prevErrors,
              userRoyalty:{
                ...prevErrors.UserRoyalty,
                ticker: "Invalid Ticker. Enter a valid tick. min 3 characters"
              }
            }
            setErrors(prevErrors)
          }
          return await validateRoyaltyTokenTicker(e.target.value).then((valid) => {
            if(!valid){
              prevErrors = {
                ...prevErrors,
                userRoyalty:{
                  ...prevErrors.UserRoyalty,
                  ticker: "Ticker already exists. Enter another one"
                }
              }
              setErrors(prevErrors)
            }
            
            return 
          })
        }else{
          prevErrors = {
            ...prevErrors,
            userRoyalty:{
              ...prevErrors.UserRoyalty,
              ticker: "Invalid Input"
            }
          }
          setErrors(prevErrors)
        }
        break;
      case "royalty percentage " + index:
        delete prevErrors?.userRoyalty?.percentage;
        setErrors(prevErrors);
        setPercentage(parseInt(e.target.value));
        setRoyaltyInfo((prev) => {
          return {
            ...prev,
            percentage: parseInt(e.target.value),
          };
        });
        if (e.target.value?.length === 0) {
          prevErrors = {
            ...prevErrors,
            userRoyalty: {
              ...prevErrors.UserRoyalty,
              percentage:
                "Invalid Percentage. Enter a percentage of 10 or greater",
            },
          };
          setErrors(prevErrors);
          setPercentage(0);
          setRoyaltyInfo((prev) => {
            return {
              ...prev,
              percentage: 0,
            };
          });
        }
        if (
          !isNaN(e.target.value) &&
          e.target.value >= 10 &&
          e.target.value <= 50
        ) {
          return;
        }
        if (isNaN(e.target.value)) {
          prevErrors = {
            ...prevErrors,
            percentage:
              "Invalid Percentage. Enter a percentage of 10 or greater",
          };
          setErrors(prevErrors);
          setPercentage(parseInt(10));
          setRoyaltyInfo((prev) => {
            return {
              ...prev,
              percentage: parseInt(10),
            };
          });
          /* setErrors(err) */
          return;
        }
        if (parseInt(e.target.value) > 50) {
          setPercentage(parseInt(50));
          setRoyaltyInfo((prev) => {
            return {
              ...prev,
              percentage: parseInt(50),
            };
          });
        }
        break;
      case "reserve " + index:
        delete prevErrors?.userRoyalty?.reserve;
        setErrors(prevErrors);
        setReserve(parseInt(e.target.value));
        setRoyaltyInfo((prev) => {
          return {
            ...prev,
            shareReserve: parseInt(e.target.value),
          };
        });
        if (e.target.value.length === 0) {
         
          setReserve(0);
          setRoyaltyInfo((prev) => {
            return {
              ...prev,
              shareReserve: 0,
            };
          });
        }
        if (parseInt(e.target.value) > 50) {
          setReserve(parseInt(50));
          setRoyaltyInfo((prev) => {
            return {
              ...prev,
              shareReserve: parseInt(50),
            };
          });
        }
        if (
          !isNaN(e.target.value) &&
          e.target.value >= 0 &&
          e.target.value <= 50
        ) {
          return;
        }
        if (isNaN(e.target.value)) {
          prevErrors = {
            ...prevErrors,
            shareReserve:
              "Invalid reserve amount. Enter a valid reserve amount of 50 or less",
          };
          setErrors(prevErrors);
          setReserve(parseInt(0));
          setRoyaltyInfo((prev) => {
            return {
              ...prev,
              shareReserve: parseInt(0),
            };
          });
          /* setErrors(err) */
          return;
        }
        
        break;

      case "royalty price " + index:
        delete prevErrors?.userRoyalty?.price;
          setErrors(prevErrors);
        if (e.target.value[0] === "0") {
          
          setPrice(e.target.value.substring(1, e.target.value.length));
          setRoyaltyInfo((prev) => {
            return {
              ...prev,
              price: e.target.value.substring(1, e.target.value.length),
            };
          });
        } else {
          setPrice(e.target.value);
          setRoyaltyInfo((prev) => {
            return {
              ...prev,
              price: e.target.value,
            };
          });
        }

        if (!isNaN(e.target.value) && e.target.value > 1) {
          return;
        }
        if (e.target.value?.length === 0) {
          /* prevErrors = {
            ...prevErrors,
            userRoyalty: {
              ...prevErrors.UserRoyalty,
              price: "Invalid Price. Enter price",
            },
          };
          setErrors(prevErrors); */
          setPrice(0);
          setRoyaltyInfo((prev) => {
            return {
              ...prev,
              price: 0,
            };
          });
        }
        if (isNaN(e.target.value)) {
          /*   err = {
             ...err,
             price: "Invalid Price. Enter price or leave 0"
           } */
          setPrice(parseFloat(e.target.value));
          setRoyaltyInfo((prev) => {
            return {
              ...prev,
              price: parseFloat(e.target.value),
            };
          });
          /* setErrors(err) */
          return;
        }
        break;
        break;

      default:
        break;
    }
  };

  return (
    <SongDetailsSection>
      <SongDetailsSectionTitle>User Royalty Shares</SongDetailsSectionTitle>
      <SongDetailsText>
        <i>
          Your biggest supporters can now invest in your music and earn for
          every sale and/or music streams
        </i>
      </SongDetailsText>
      <FormWrapper>
        <FormLabel>Royalty Share Ticker </FormLabel>
        <ErrorWrapper>
          <FormInput
            style={{ flex: "1 1 50%" }}
            name={"ticker " + index}
            placeholder={`Enter a custom ticker (currency code)`}
            maxLength={15}
            readOnly={isEditing}
            value={royaltyInfo?.ticker}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          <SongDetailsText>
            <i>Enter a ticker (currency code) for your royalty share.</i>
          </SongDetailsText>
          <SongDetailsText>
            <i>
              Currency codes can be a minimum of 3 characters in length. The
              following characters are permitted: all uppercase and lowercase
              letters, digits, as well as the symbols ?, !, @, #, $, %, ^, &, *,{" "}
              {"<"},{" >"}, (, ), {"{,"} {"}"}, [, ], and |{" "}
            </i>
          </SongDetailsText>
          <SongDetailsText>
            <i>To learn more click <a href={'https://xrpl.org/docs/references/protocol/data-types/currency-formats/#nonstandard-currency-codes'}> here </a></i>
          </SongDetailsText>

          {errors?.userRoyalty?.ticker && (
            <SongDetailsText error>{errors?.userRoyalty?.ticker}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
      <FormWrapper>
        <FormLabel>Number of Shares in Circulation </FormLabel>
        <ErrorWrapper>
          <FormInput
            style={{ flex: "1 1 50%" }}
            name={"share " + index}
            placeholder={`Enter number of royalty shares that can be bought`}
            min={100}
            max={100}
            readOnly={true}
            value={royaltyInfo?.shares}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          <SongDetailsText>
            <i>
              Only 100 shares will be available for sale and in circulation.
              This can not be increased.
            </i>
          </SongDetailsText>
          <SongDetailsText>
            <i>
              Fans would need tp hold a minimum of 1/2 (0.5) a share in order to
              earn.
            </i>
          </SongDetailsText>

          {errors?.userRoyalty?.shares && (
            <SongDetailsText error>{errors?.userRoyalty?.shares}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
      {!isEditing && <FormWrapper>
        <FormLabel>Token Reserve </FormLabel>
        <ErrorWrapper>
          <FormInput
            style={{ flex: "1 1 50%" }}
            name={"reserve " + index}
            placeholder={`Enter number of shares that can be reserved for you to claim`}
            min={0}
            max={50}
            value={royaltyInfo?.shareReserve}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          <SongDetailsText>
            <i>
              How many shares would you like to have reserved for yourself to claim. These shares will be sent directly to your wallet once you have claimed them
            </i>
          </SongDetailsText>
          <SongDetailsText>
            <i>
              A maximum of 50 shares can be reserved. You cannot reserve any more shares once changes have been submitted.
            </i>
          </SongDetailsText>

          {errors?.userRoyalty?.reserve && (
            <SongDetailsText error>{errors?.userRoyalty?.reserve}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>}
      <FormWrapper>
        <FormLabel>
          Price of Share{" "}
          <CurrencySelector
            currency={royaltyInfo?.currency}
            setCurrency={(currency) => {
              setRoyaltyInfo((prev) => {
                return {
                  ...prev,
                  currency: currency,
                };
              });
            }}
          />
        </FormLabel>
        <ErrorWrapper>
          <FormInput
            style={{ flex: "1 1 50%" }}
            name={"royalty price " + index}
            placeholder={`Enter price of royalty share in ${royaltyInfo?.currency}`}
            min={0}
            value={royaltyInfo?.price}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          <SongDetailsText>
            <i>How much can a user buy each royalty share for?</i>
          </SongDetailsText>

          {errors?.userRoyalty?.price && (
            <SongDetailsText error>{errors?.userRoyalty?.price}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
      <FormWrapper>
        <FormLabel>Percentage </FormLabel>
        <ErrorWrapper>
          <FormInput
            style={{ flex: "1 1 50%" }}
            name={"royalty percentage " + index}
            placeholder={`Enter royalty percentage you'll be giving to users`}
            min={0}
            max={50}
            value={royaltyInfo?.percentage}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          <SongDetailsText>
            <i>
              This is how much of your music sales and/or streaming royalties
              that will be reserved for users who own a royalty NFT. min 10% max
              50%
            </i>
          </SongDetailsText>
          {errors?.userRoyalty?.percentage && (
            <SongDetailsText error>{errors?.userRoyalty?.percentage}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
      <SongDetailsText>
        Each user will receive {percentage / shares} % of the music's total
        earnings, per royalty share that they own
      </SongDetailsText>
    </SongDetailsSection>
  );
};

export default UserRoyaltyForm;
