import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { CONTENT_TYPES, TRANSACTION_OPTIONS } from "../../utils/Enum";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { useModal } from "../Modal/ModalContext";
import OptionModal from "../OptionModal";
import QRCodeForm from "../QRCodeForm";
import { useUser } from "../../hooks/useUser";
import LoadingScreen, { ModalLoadingScreen } from "../LoadingScreen";
import NFTokenList from "../ContentList/NFTokenList";
import { FormInput, FormLabel, FormWrapper } from "../CustomForm/CustomFormElements";
import axiosInstance from "../Authorization/client";
import { SmallText } from "../NFTCollectionCreator/NFTCollectionCreatorElements";

const StreamingSubscriptionModal = ({onSuccess, onFail, cancel}) => {
  const { modalContent, openModal, closeModal, toggleModal, noCloseModal } = useModal();
  const { currentUser, getUserSubscription } = useAuth();
  const { getUserSwerlVouchers } = useUser({});

  const onCancel = () => {
    cancel()
  };

  const success = () => {
    getUserSubscription(currentUser?.uid)
    onSuccess()
  }

  const fail = () =>{
    onFail()
  }

  const paySubscription = (fee) => {
    switch (fee) {
      case "1 Month":
        noCloseModal(
          <QRCodeForm
            heading={"Paying 1 Month Subscription"}
            type={TRANSACTION_OPTIONS.PAY_STREAMING_SUBSCRIPTION}
            body={{
              currency: "XRP",
              months: "1 Month",
              pathfinding: true,
              instruction: `${currentUser?.uid} is making a 1 month payment to subscribe to the Sonar Muse streaming service`,
              memo: `${currentUser?.uid} is making a 1 month payment to subscribe to the Sonar Muse streaming service`,
              sender: currentUser?.uid,
            }}
            cancelQr={onCancel}
            onFail={fail}
            onSuccess={
              success
            }
          />
        );
        break;
      case "3 Months":
        noCloseModal(
          <QRCodeForm
            heading={"Paying 3 Month Subscription"}
            type={TRANSACTION_OPTIONS.PAY_STREAMING_SUBSCRIPTION}
            body={{
              currency: "XRP",
              months: "3 Months",
              pathfinding: true,
              instruction: `${currentUser?.uid} is making a 3 month payment to subscribe to the Sonar Muse streaming service`,
              memo: `${currentUser?.uid} is making a 3 month payment to subscribe to the Sonar Muse streaming service`,
              sender: currentUser?.uid,
            }}
            cancelQr={onCancel}
            onFail={fail}
            onSuccess={
              success
            }
          />
        );
        break;
      case "6 Months":
        noCloseModal(
          <QRCodeForm
            heading={"Paying 6 Month Subscription"}
            type={TRANSACTION_OPTIONS.PAY_STREAMING_SUBSCRIPTION}
            body={{
              currency: "XRP",
              months: "6 Months",
              pathfinding: true,
              instruction: `${currentUser?.uid} is making a 6 month payment to subscribe to the Sonar Muse streaming service`,
              memo: `${currentUser?.uid} is making a 6 month payment to subscribe to the Sonar Muse streaming service`,
              sender: currentUser?.uid,
            }}
            cancelQr={onCancel}
            onFail={fail}
            onSuccess={success
            }
          />
        );
        break;
      case "12 Months":
        noCloseModal(
          <QRCodeForm
            heading={"Paying 12 Month Subscription"}
            type={TRANSACTION_OPTIONS.PAY_STREAMING_SUBSCRIPTION}
            body={{
              currency: "XRP",
              pathfinding: true,
              months: "12 Months",
              instruction: `${currentUser?.uid} is making a 12 month payment to subscribe to the Sonar Muse streaming service`,
              memo: `${currentUser?.uid} is making a 12 month payment to subscribe to the Sonar Muse streaming service`,
              sender: currentUser?.uid,
            }}
            cancelQr={onCancel}
            onFail={fail}
            onSuccess={
              success
            }
          />
        );
        break;

      default:
        break;
    }
  };

  const redeemVoucher = (token) => {
    openModal()
    noCloseModal(
      <>
        <QRCodeForm
        type={TRANSACTION_OPTIONS.REDEEM_SWERL_VOUCHER}
          heading={`Redeeming ${token?.nftoken?.metadata.name}`}
          body={{
            tokenId: token?.nftoken?.NFTokenID,
            instruction: `${currentUser?.uid} is redeeming a ${token?.nftoken?.metadata.name} to subscribe to the Sonar Muse streaming service`,
            memo: `${currentUser?.uid} is redeeming a ${token?.nftoken?.metadata.name} to subscribe to the Sonar Muse streaming service`,
            sender: currentUser?.uid,
          }}
          cancelQr={onCancel}
          onFail={fail}
          onSuccess={
            success
          }
        />
      </>
    );
  };

  const getSwerlVouchers = async () => {
    openModal();
    modalContent(
      <OptionModal
        onCancel={onCancel}
        heading={"Retreiving available vouchers from wallet"}
      >
        <LoadingScreen transparent={true} />
      </OptionModal>
    );
    await getUserSwerlVouchers(currentUser?.uid)
      .then((vouchers) => {
        if (vouchers?.length > 0) {
          modalContent(
            <OptionModal onCancel={() => {
              openModal()
                modalContent(<>
                  <StreamingSubscriptionModal
                    onSuccess={success}
                    onFail={fail}
                    cancel={onCancel}
                  />
                </>
              );
            } } heading={"Available Redeemable Vouchers"} additionalText={'Vouchers in your wallet that have not expired'}>
              <NFTokenList
                type={CONTENT_TYPES.NFTOKEN}
                content={vouchers}
                onClick={redeemVoucher}
              />
            </OptionModal>
          );
        } else {
          modalContent(
            <OptionModal onCancel={onCancel} heading={"No Available Vouchers"}>
              <HoverOption
                onClick={() => {
                  openModal()
                    modalContent(<>
                      <StreamingSubscriptionModal
                        onSuccess={success}
                        onFail={fail}
                        cancel={onCancel}
                      />
                    </>
                  );
                }}
              >
                Go Back
              </HoverOption>
            </OptionModal>
          );
        }
      })
      .catch((err) => {
        modalContent(
          <OptionModal onCancel={onCancel} heading={"Something went wrong"}>
            <HoverOption
              onClick={() => {
                getSwerlVouchers();
              }}
            >
              Try Again
            </HoverOption>
            <HoverOption
                onClick={() => {
                  openModal()
                    modalContent(<>
                      <StreamingSubscriptionModal
                        onSuccess={success}
                        onFail={fail}
                        cancel={onCancel}
                      />
                    </>
                  );
                }}
              >
                Go Back
              </HoverOption>
          </OptionModal>
        );
      });
  };

  return (
    <OptionModal
      heading={"Streaming Subscription"}
      additionalText={
        "Access Unlimited Music and Podcasts On Demand by Subscibing to Our Streaming Service"
      }

      onCancel={onCancel}
    >
      <HoverOption onClick={() => getSwerlVouchers()}>
        Redeem Swərl Voucher
      </HoverOption>
      <HoverOption
        onClick={() => {
          paySubscription("1 Month");
        }}
      >
        1 Month ($5)
      </HoverOption>
      <HoverOption
        onClick={() => {
          /*modalContent(<OptionModal heading={'Coming Soon'}>
            <HoverOption onClick={() => {
                closeModal() 
                modalContent()}}>OK</HoverOption>
        </OptionModal>)*/
          paySubscription("3 Months");
        }}
      >
        3 Months ($14)
      </HoverOption>
      <HoverOption
        onClick={() => {
          /*modalContent(<OptionModal heading={'Coming Soon'}>
            <HoverOption onClick={() => {
                closeModal() 
                modalContent()}}>OK</HoverOption>
        </OptionModal>)*/
          paySubscription("6 Months");
        }}
      >
        6 Months ($28)
      </HoverOption>
      <HoverOption
        onClick={() => {
          /*modalContent(<OptionModal heading={'Coming Soon'}>
            <HoverOption onClick={() => {
                closeModal() 
                modalContent()}}>OK</HoverOption>
        </OptionModal>)*/
          paySubscription("12 Months");
        }}
      >
        12 Months ($56)
      </HoverOption>
    </OptionModal>
  );
};

//Pay as you go option using web monetization or Wallet Guru!

export const TopUpStreamingSubscriptionModal = ({onSuccess, onFail, cancel}) => {
  const { modalContent, openModal, closeModal, toggleModal, noCloseModal } = useModal();
  const { currentUser, getUserSubscription } = useAuth();
  const [rate, setRate] = useState(0)
  const [isRateLoaded, setRateLoaded] = useState(false)

  const onCancel = () => {
    cancel()
  };

  const success = () => {
    getUserSubscription(currentUser?.uid)
    onSuccess()
  }

  const fail = () =>{
    onFail()
  }

  const topupAmountSelector = () => {
    openModal()
    modalContent(<ModalLoadingScreen text={"Loading Please Wait"}/>)
    axiosInstance.get(`/api/${"USD"}/getPrice`).then((xrate) => {
      if(xrate.data?.price){
        console.log(xrate.data?.price)
        setRate((xrate.data?.price))
        setRateLoaded(true)
    return modalContent(
      <OptionModal heading={"Select Top Up Amount"} onCancel={cancel}> 
        <HoverOption onClick={() => confirmTopUp(1)}>
          1 XRP (${parseFloat(1 * xrate.data?.price).toFixed(2)})
        </HoverOption>
        <HoverOption onClick={() => confirmTopUp(2)}>
          2 XRP (${parseFloat(2 * xrate.data?.price).toFixed(2)})
        </HoverOption>
        <HoverOption onClick={() => confirmTopUp(3)}>
          5 XRP (${parseFloat(5 * xrate.data?.price).toFixed(2)})
        </HoverOption>
        <HoverOption onClick={() => {
          openModal()
          modalContent(
            <CustomTopUpAmount onFail={onFail} onSuccess={onSuccess} cancel={cancel} rate={xrate?.data?.price}/>
          )
        }}>
          Custom Amount
        </HoverOption>

      </OptionModal>
    )
      
  }

  modalContent(<OptionModal heading={"Something went wrong. Try Again?"} onCancel={cancel}>
    <HoverOption>Try Again</HoverOption>
  </OptionModal>)
    
  })
}

const confirmTopUp = (amount) => {
  openModal()
  modalContent(<QRCodeForm
    heading={"Subscription Top Up"}
    type={TRANSACTION_OPTIONS.TOP_UP_STREAMING_SUBSCRIPTION}
    body={{
     sender: currentUser?.uid,
     amount: amount,
     memo: `Sonar Muse Swerl: ${currentUser?.uid} is making a payment to top up their current streaming subscription`,
     instruction: `${currentUser?.uid} is making a payment to top up their current streaming subscription`
   } 
    }
    onFail={onFail}
    onSuccess={onSuccess}
    cancelQr={cancel}
  />)
}

  return (
    <>
    <OptionModal heading={"You will be adding more funds to your current monthly subscription"} additionalText={"Are you sure you want to continue"} onCancel={cancel}>
      <HoverOption onClick={topupAmountSelector}>
        Continue
      </HoverOption>

    </OptionModal>
    
    
    </>

  )


}

export const CustomTopUpAmount = ({onSuccess, onFail, cancel,rate}) => {
  const { modalContent, openModal, closeModal, toggleModal, noCloseModal } = useModal();
  const { currentUser, getUserSubscription } = useAuth();
  const [amount, setAmount] = useState(1)

  const handleInput = (e) => {
    if(e.target.value[0] === "0"){
      setAmount(e.target.value.substring(1,e.target.value.length)); 
     }else{
       setAmount(e.target.value)
     }
     
     if(!isNaN(e.target.value) && e.target.value > 0){
       return
     }
     if(e.target.value?.length === 0 || parseFloat(e.target.value) < 1){
       
       setAmount(0)
     }
     if(isNaN(e.target.value)){
     /*   err = {
         ...err,
         Amount: "Invalid Amount. Please enter Amount or leave 0"
       } */
       setAmount(1)
       /* setErrors(err) */
       return
     }
  }

  const confirmTopUp = (amount) => {
    openModal()
    modalContent(<QRCodeForm
    heading={"Subscription Top Up"}
     type={TRANSACTION_OPTIONS.TOP_UP_STREAMING_SUBSCRIPTION}
     body={{
      sender: currentUser?.uid,
      amount: amount,
      memo: `Sonar Muse Swerl: ${currentUser?.uid} is making a payment to top up their current streaming subscription`,
      instruction: `${currentUser?.uid} is making a payment to top up their current streaming subscription`
    } 
     }
     onFail={onFail}
     onSuccess={onSuccess}
     cancelQr={cancel}
    />)
  }

  return (
    <OptionModal heading={"Custom Top Up Amount"} additionalText={"Enter custom top up amount, in XRP. (Must be 1 XRP or more)"}>
      <FormWrapper>
        <FormLabel>Amount (In XRP)</FormLabel>
        <FormInput placeholder="Enter Custom Amount" onChange={handleInput} value={amount}/>
        {amount > 0 && <SmallText>{amount} XRP (${parseFloat(amount * rate).toFixed(2)}) </SmallText>}
      </FormWrapper>
      <HoverOption onClick={() => {
        if(amount > 0){
          confirmTopUp(amount)
        }
      }}>Confirm</HoverOption>
    
    </OptionModal>
  )
}


export default StreamingSubscriptionModal;
