import styled from "styled-components";
import { MintButtonConainer } from "../NFTCollectionCreator/NFTCollectionCreatorElements";

export const DownloaderButtonConainer = styled(MintButtonConainer)`
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(2, 1fr);
    background: transparent;

    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
      }
`;
