import React, { useCallback, useEffect, useRef, useState } from 'react'
import ContentWrapper from '../ContentWrapper'
import { Slider } from '../Slider'
import { SLIDER_TYPES } from '../../utils/Enum'
import { ModalLoadingScreen } from '../LoadingScreen'
import { useLatestEpisodeSearch } from '../../hooks/useSearch'
import Modal from '../Modal'

const LatestEpisodes = () => {
    const [pageNumber, setPageNumber] = useState(0)

    const {loading, error, episodes, pages, setPages} = useLatestEpisodeSearch({pageNumber: pageNumber})
  
    const observer = useRef()
    const lastEpisodeElementRef = useCallback( node => {
      if(loading){
        return
      }
      if(observer.current){ 
        observer.current.disconnect()}
        observer.current = new IntersectionObserver(entries=> {
          if(entries[0].isIntersecting && (pageNumber < parseInt(pages/10))){
                setPageNumber(prev => prev + 1)
          }
      }) 
      
      if(node) observer.current.observe(node)
     
    }, [loading, pages, pageNumber])
  
    useEffect(() => {
      
      /* getLatestEpisodes("all")
        .then((data) => {
          if (data) {
            setEpisodes(data);
            setLoaded(true);
          }
        })
        .catch((err) => {setLoaded(true)});
        return () => {} */
    }, [loading, pageNumber]);
  
    return (
      <>
        {
          !loading && episodes?.length > 0 && (
            <ContentWrapper heading={"Trending Episodes"}>
              <Slider
                type={SLIDER_TYPES.EPISODE}
                heightScroll
                slides={episodes}
                cardRef={lastEpisodeElementRef}
                id={"latestepisodes"}
              />
            </ContentWrapper>
          )
          /*         <PageIndexer/>
           */
        }
        {
          loading && episodes?.length > 0 && (
           <ContentWrapper heading={"Trending Episodes"}>
              <Slider
                type={SLIDER_TYPES.EPISODE}
                heightScroll
                slides={episodes}
                cardRef={lastEpisodeElementRef}
                id={"latestepisodes"}
              />
                       <Modal isOpen={loading}><ModalLoadingScreen text={'Loading. Please Wait'}/></Modal>
  
            </ContentWrapper>
          )
          /*         <PageIndexer/>
           */
        }
        {loading && episodes?.length === 0 && (
          <ContentWrapper heading={"Latest Episodes"}>
               <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          </ContentWrapper>
        )}
        {!loading && episodes?.length === 0 && <ContentWrapper heading={"No Episodes Found"}>
            
            </ContentWrapper>}
      </>
    );
}

export default LatestEpisodes