import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import {
  containsObject,
  getObjectLength,
  validateAddress,
} from "../../../utils";
import { convertArrObjToRevShare, convertRevShareToArrObj } from "../../../utils/nfTokensUploads";
import { Button1 } from "../../ButtonElement";
import Page1 from "./Page1";
import { ARTIST_SEARCH_TYPES, CONTENT_TYPES } from "../../../utils/Enum";
import useMusic from "../../../hooks/useMusic";
import useArtist from "../../../hooks/useArtist";

const CollectionEdit = ({ collection, submitChanges }) => {
  const { currentUser, gravatarInfo } = useAuth();
  const {getSong, getAlbum, getSongs, getAlbums} = useMusic({})
  const {searchArtists} = useArtist({})
  const location = useLocation();
  const navigate  = useNavigate();
  const [price, setPrice] = useState(collection?.price ? collection?.price : 0); // Price for the NFToken
  const [isDiscount, setDiscount] = useState(
    getObjectLength(collection?.discounts) > 0 ? true : false
  );
  const [discountType, selectDiscountType] = useState("");
  const [discountRedeemers, setRedeemers] = useState(getObjectLength(collection?.discounts) > 0 ? {
    nftokens: collection?.discounts?.nftokens,
    music: {songs: collection?.discounts?.songs,
      albums: collection?.discounts?.albums
    },
    subscriptions: collection?.subscriptions
  } : {
    nftokens: [],
    music: {},
    subscriptions: [],
  });
  const [isDiscountEditing, setDiscountEditing] = useState(false);
  const [isDiscountsLoaded, setDiscountsLoaded] = useState(false)
  const [currentDiscount, setCurrentDiscount] = useState({});
  const [revenue, setRevenue] = useState(
    collection?.revShare
      ? convertRevShareToArrObj(collection?.revShare)
      : [{ address: currentUser?.uid, percentage: 100 }]
  );
  const [isPremint, setPremint] = useState(collection?.isPreMint ?collection?.isPreMint: false )
  const [mintDate, setMintDate] = useState(collection?.isPreMint ? new Date(collection?.dateCreated).toISOString().split(".")[0]: undefined)
  const [revShare, setRevShare] = useState(collection?.revShare ? true : false); // Allow split of sales for the NFToken
  const [currentRevenueId, setCurrentId] = useState(); //current ID position who has been selected for revshare
  const [isRevenueEditing, setRevenueEditing] = useState(false); //editing a user who will be receiving revshare
  const [currentAddress, setCurrentAddress] = useState({}); //current user position who has been selected for revshare
  const [isFixedSupply, setFixedSupply] = useState(
    collection?.tokenSupply ? true : false
  ); // Make a fixed supply of NFTokens for this collection
  const [currency, setCurrency] = useState(
    collection?.currency ? collection?.currency : "WYM"
  );
  const [mintQuantity, setQuantity] = useState(
    collection?.tokenSupply ? collection?.tokenSupply : 1
  ); // The quantity allowed to be sold as NFTokens for this collection
  const [errors, setErrors] = useState({}); //Stores errors

  const addressRef = useRef("");
  const percentageRef = useRef(1);

  useEffect(() => {
    
    getDiscountInfo()
    
  return () => {}
  }, [collection])

  const getDiscountInfo = async() => {
    if(getObjectLength(collection?.discounts) > 0){
      let music = {
      songs: collection?.discounts?.songs,
      albums: collection?.discounts?.albums
    }
      if(collection?.discounts?.songs?.length > 0){
        let songs = collection?.discounts?.songs
        let songsInfo = await getSongs(songs.map(s => {return s?.songId}))
        songs = songs.map((s, index) => {
          return {
            ...songsInfo[index],
            percentage: s?.percentage
          }
        })
        music = {...music, songs: songs}
      }
      if(collection?.discounts?.albums?.length > 0){
        let albums = collection?.discounts?.albums
        let albumsInfo = await getAlbums(albums.map(a => {return a?.albumId}))
        albums = albums.map((a, index) => {
          return {
            ...albumsInfo[index],
            percentage: a?.percentage
          }
        })
        music = {...music, albums: albums}
      }
      setRedeemers(prev => {
        return {
          ...prev,
          music: music
        }
      })
      if(collection?.discounts?.subscriptions?.length > 0){
        let subs = collection?.discounts?.subscriptions
        for(let i = 0; i < collection?.discounts?.subscriptions?.length; i++){
          let sub = collection?.discounts?.subscriptions[i]
          await searchArtists(ARTIST_SEARCH_TYPES.ID,sub?.artistId).then(artist => {
            if(getObjectLength(artist) > 0){
              subs[i] = {artist: artist, percentage: subs[i].percentage}
            }else{
              delete subs[i]
            }
          }).catch(err => {
            console.error(err)
            delete subs[i]
          })
        }
        setRedeemers(prev => {
          return {
            ...prev,
            subscriptions: subs
          }
        })
      }
    }
    setDiscountsLoaded(true)
    return
  }

  const addDiscount = (type, discountInfo) => {
    let redeem
    switch (type) {
      case "DIGITAL":
        let music = getObjectLength(discountRedeemers?.music) > 0 ? discountRedeemers?.music : {}
        if(discountInfo?.type === "SONG"){
          let songs = music?.songs ? music?.songs : []
         redeem = songs?.findIndex(
          (s) =>
            s.songId === discountInfo?.music?.songId
        );
        if (redeem && redeem !== -1) {
          songs[redeem] = discountInfo?.music
        }else{
          songs.push(discountInfo?.music)
        }
        music = {...music, songs: songs}
        setRedeemers((prev) => {
          return {
            ...prev,
            music: music,
          };
        });
        }else{
          let albums = music?.albums ? music?.albums : []
          redeem = albums?.findIndex(
           (a) =>
             a.albumId === discountInfo?.music?.albumId  
         );
         if (redeem && redeem !== -1) {
           albums[redeem] = discountInfo?.music
         }else{
           albums.push(discountInfo?.music)
         }
         music = {...music, albums: albums}
         setRedeemers((prev) => {
           return {
             ...prev,
             music: music,
           };
         });
        }
        break;
      case "NFT_COLLECTION":
        let tokens = discountRedeemers?.nftokens?.length > 0 ? discountRedeemers?.nftokens : [];
        redeem = tokens?.findIndex(
          (t) =>
            t.issuer === discountInfo?.issuer && t.taxon === discountInfo.taxon
        );
        if (redeem && redeem !== -1) {
          tokens[redeem] = {
            issuer: discountInfo.issuer,
            taxon: discountInfo.taxon,
            numTokens: discountInfo.numTokens,
            percentage: discountInfo.percentage,
          };
        } else {
          tokens = [
            ...tokens,
            {
              issuer: discountInfo.issuer,
              taxon: discountInfo.taxon,
              numTokens: discountInfo.numTokens,
              percentage: discountInfo.percentage,
            },
          ];
        }
        setRedeemers((prev) => {
          return {
            ...prev,
            nftokens: tokens,
          };
        });
        break;
      case "SUBSCRIPTION":
        let subscriptions = discountRedeemers?.subscriptions?.length > 0 ? discountRedeemers?.subscriptions : [];
        redeem = subscriptions?.findIndex(
          (t) =>
            t.artist.artistId === discountInfo?.artist.artistId
        );
        if (redeem && redeem !== -1) {
          subscriptions[redeem] = {
            artist: discountInfo.artist,
            percentage: discountInfo.percentage,
          };
        } else {
          subscriptions.push({
              artist: discountInfo.artist,
              percentage: discountInfo.percentage,
            })
        }
        setRedeemers((prev) => {
          return {
            ...prev,
            subscriptions: subscriptions,
          };
        });

        break;

      default:
        break;
    }
    setCurrentDiscount({});
        setDiscountEditing(false);
        selectDiscountType();
  };

  const deleteDiscount = (type, id) => {
    switch (type) {
      case "DIGITAL":
        let music = discountRedeemers?.music
        if(id?.type === "SONG"){
          let songs = music?.songs
          songs = songs.filter((s, index) => index !== id?.index)
          music = {...music, songs : songs}
          setRedeemers((prev) => {
            return {
              ...prev,
              music: music,
            };
          });
        }else{
          let albums = music?.albums
          albums = albums.filter((s, index) => index !== id?.index)
          music = {...music, albums : albums}
          setRedeemers((prev) => {
            return {
              ...prev,
              music: music,
            };
          });
        }
        break;
      case "NFT_COLLECTION":
        let tokens = discountRedeemers?.nftokens;
        tokens = tokens.filter((t, index) => index !== id);
        setRedeemers((prev) => {
          return {
            ...prev,
            nftokens: tokens,
          };
        });
        break;
      case "SUBSCRIPTION":
        let subscriptions = discountRedeemers?.subscriptions;
        subscriptions = subscriptions.filter((t, index) => index !== id);
        setRedeemers((prev) => {
          return {
            ...prev,
            subscriptions: subscriptions,
          };
        });
        
        break;

      default:
        break;
    }
    setCurrentDiscount({});
        setDiscountEditing(false);
        selectDiscountType();
  };

  const toggleRevenue = () => {
    let rev = !revShare;
    if (rev === true) {
      setRevenue([{ address: currentUser?.uid, percentage: 100 }]);
    } else {
      setRevenue([]);
      setRevenueEditing(false);
    }
    setRevShare(rev);
  };

  const clearRevenueForm = () => {
    let e = errors;
    delete e?.revenue;
    delete e?.percentage;
    delete e?.address;
    setErrors(e);
    if (
      currentAddress?.address?.trim() !== "" ||
      currentAddress?.percentage > 0
    ) {
      setCurrentAddress({ address: "", percentage: 1 });
      addressRef.current.value = "";
      percentageRef.current.value = 0.1;
      setCurrentId();
      setRevenueEditing(false);
    }
  };

  const addRevenue = (address, percentage) => {
    let e = errors;
    delete e.address;
    delete e.percentage;
    if (
      validateAddress(currentAddress?.address) &&
      currentAddress?.percentage &&
      currentAddress.percentage > 0
    ) {
      let exists = containsObject(currentAddress, revenue);
      if (exists === false) {
        if (validatePercentage(currentAddress?.percentage)) {
          let r = revenue;
          r.push({
            address: currentAddress?.address,
            percentage: currentAddress.percentage,
          });
          setRevenue(r);
          clearRevenueForm();
        }
      }
    } else {
      if (!validateAddress(currentAddress?.address)) {
        setErrors((prev) => {
          return { ...prev, address: "Invalid wallet address." };
        });
      }
      if (currentAddress.percentage === 0 || !currentAddress.percentage) {
        setErrors((prev) => {
          return { ...prev, percentage: "Enter a revenue split percentage." };
        });
      }
    }
  };

  const validatePercentage = (percentage) => {
    let e = errors;
    delete e?.revenue;
    setErrors(e);
    let percentages = 0;
    for (let i = 0; i <= revenue.length - 1; i++) {
      if (currentAddress.address !== revenue[i].address) {
        percentages += parseInt(revenue[i].percentage);
      }
    }
    let value = parseInt(percentages) + parseInt(percentage);
    if (value <= 100) {
      return true;
    } else {
      setErrors({ ...e, revenue: "Rev percentages exceedes 100% " });
      return false;
    }
  };

  const editRevenue = () => {
    let e = errors;
    delete e.address;
    delete e.percentage;
    let r = revenue;
    let updatedRevenue = [];
    if (
      validateAddress(currentAddress?.address) &&
      currentAddress?.percentage &&
      currentAddress.percentage > 0
    ) {
      if (validatePercentage(currentAddress?.percentage)) {
        updatedRevenue = r.map((royalty, index) =>
          index === currentRevenueId ? currentAddress : royalty
        );
        setRevenue(updatedRevenue);
        setRevenueEditing(false);
        clearRevenueForm();
      }
    } else {
      if (!validateAddress(currentAddress?.address)) {
        setErrors((prev) => {
          return { ...prev, address: "Invalid wallet address." };
        });
      }
      if (currentAddress.percentage === 0 || !currentAddress.percentage) {
        setErrors((prev) => {
          return { ...prev, percentage: "Enter a revenue split percentage." };
        });
      }
    }
  };

  const removeRevenue = (id) => {
    let r = [];
    let updatedRevenue = [];
    r = revenue;
    updatedRevenue = r.filter((royalty, index) => index !== id);
    if (revenue[id].address !== currentUser?.uid) {
      setRevenue(updatedRevenue);
    } else {
      setRevenue([
        { address: currentUser?.uid, percentage: 100 },
        ...updatedRevenue,
      ]);
    }
    setRevenueEditing(false);
    setCurrentId();
    setCurrentAddress();
  };

  const handleInput = async (e) => {
    let errs = errors;
    switch (e.target.name) {
      case "price":
        if(e.target.value[0] === "0"){
          setPrice(e.target.value.substring(1,e.target.value.length)); 
         }else{
           setPrice(e.target.value)
         }
         
         if(!isNaN(e.target.value) && e.target.value > 0){
           delete errs.price
           setErrors(errs)
           return
         }
         if(e.target.value?.length === 0){
           
           setPrice(0)
         }
         if(isNaN(e.target.value)){
         /*   err = {
             ...err,
             price: "Invalid Price. Please enter price or leave 0"
           } */
           setPrice(parseFloat(0))
           /* setErrors(err) */
           return
         }
        break;

      case "fixed supply":
        if(e.target.value?.length > 0 && isNaN(e.target.value) === false){
          if(e.target.value?.length > collection?.amountSold){
            setQuantity(parseInt(e.target.value));
          }else{
            setQuantity(parseInt(collection?.amountSold))
          }
          return
          }if(e.target.value?.length === 0){
            setQuantity(collection?.amountSold > 0 ? collection?.amountSold : 1);
          }
        break;

      case "rev address":
        delete errs.address;
        setCurrentAddress((prev) => {
          return { ...prev, address: e.target.value };
        });
        break;

      case "rev percentage":
        delete errs.percentage;
        if(e.target.value?.length > 0){
        setCurrentAddress((prev) => {
          return { ...prev, percentage: parseInt(e.target.value) };
        });
        return
      }if(e.target.value?.length === 0){
        setCurrentAddress((prev) => {
          return { ...prev, percentage: 0 };
        })
        }
        break;
      default:
        break;
    }
    setErrors(errs);
  };

  return (
    <>
      <Page1
        addressRef={addressRef}
        percentageRef={percentageRef}
        handleInput={handleInput}
        errors={errors}
        setCurrentId={setCurrentId}
        setFixedSupply={setFixedSupply}
        isFixedSupply={isFixedSupply}
        isPremint = {isPremint}
        mintDate = {mintDate}
        currentDiscount={currentDiscount}
        setCurrentDiscount={setCurrentDiscount}
        deleteSelectedDiscount={deleteDiscount}
        discountRedeemers={discountRedeemers}
        addDiscount={addDiscount}
        isDiscount={isDiscount}
        isDiscountsLoaded={isDiscountsLoaded}
        setDiscount={setDiscount}
        setDiscountEditing={setDiscountEditing}
        isDiscountEditing={isDiscountEditing}
        selectDiscountType={selectDiscountType}
        discountType={discountType}
        setDiscountType={selectDiscountType}
        mintQuantity={mintQuantity}
        toggleRevenue={toggleRevenue}
        revShare={revShare}
        editRevenue={editRevenue}
        addRevenue={addRevenue}
        removeRevenue={removeRevenue}
        currentAddress={currentAddress}
        clearRevenueForm={clearRevenueForm}
        currentRevenueId={currentRevenueId}
        revenue={revenue}
        isRevenueEditing={isRevenueEditing}
        setCurrentAddress={setCurrentAddress}
        setRevenueEditing={setRevenueEditing}
        setCurrency={setCurrency}
        currency={currency}
        price={price}
      />
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          width: "100%",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Button1 onClick={() => {
          submitChanges(CONTENT_TYPES.COLLECTION, {
            price: price,
            discounts: (getObjectLength(discountRedeemers?.music) > 0 || discountRedeemers?.subscriptions?.length > 0 || discountRedeemers?.nftokens?.length > 0 ) ? {
              albums: discountRedeemers?.music?.albums?.length > 0 ? discountRedeemers?.music?.albums : undefined,
              songs: discountRedeemers?.music?.songs?.length > 0 ? discountRedeemers?.music?.songs : undefined,
              nftokens: discountRedeemers?.nftokens?.length > 0 ? discountRedeemers?.nftokens : undefined,
              subscriptions: discountRedeemers?.subscriptions?.length > 0 ? discountRedeemers?.subscriptions : undefined,
            } : undefined,
            tokenSupply: isFixedSupply ? mintQuantity : false,
            currency: currency,
            revShare: revShare === true ? convertArrObjToRevShare(revenue): undefined,
          })
        }}>Submit Changes</Button1>
        <Button1
          onClick={() => {
            navigate( `/collection/${collection?.collectionId}`,
            {replace: true});
          }}
        >
          Cancel
        </Button1>
      </div>
    </>
  );
};

export default CollectionEdit;
