import React from 'react'
import { ErrorWrapper, FormLabel, FormWrapper, SongDetailsSection, SongDetailsSectionTitle, SongDetailsText } from '../../MusicUploadsForm/MusicUploadsFormElements';
import { FormInput, FormTextArea } from '../../CustomForm/CustomFormElements';
import ToggleButton from '../../ToggleButton';
import CurrencySelector from '../../CurrencySelector';
import ImageUtils from '../../../utils/ImageUtils';

const Page1 = ({
    handleToggle,
    isExplicit,
    isDownloadable,
    isStreamable,
    isSubscription,
    isMusicContained,
    price,
    currency,
    setCurrency,
    episodeName,
    episodeDescription,
    handleInput,
    errors,
  }) => {
    const { defaultAlbumArt } = ImageUtils();
  
    return (
      <SongDetailsSection>
        <SongDetailsSectionTitle>Details</SongDetailsSectionTitle>
  
      {/*   <FormWrapper>
          <FormLabel>Episode Title</FormLabel>
          <ErrorWrapper>
            <FormInput
              placeholder={"Episode Title"}
              name={"title"}
              value={episodeName}
              onChange={(e) => {
                handleInput(e);
              }}
            />
            {errors?.episodeName && (
              <SongDetailsText error>{errors?.episodeName}</SongDetailsText>
            )}
          </ErrorWrapper>
        </FormWrapper> */}
        <FormWrapper>
          <FormLabel onClick={() => handleToggle("explicit")}>
            Contains Explicit Content
          </FormLabel>
          <ToggleButton
            onText={"On"}
            offText={"Off"}
            toggle={isExplicit}
            toggleClick={() => handleToggle("explicit")}
          />
        </FormWrapper>
        <FormWrapper>
          <FormLabel onClick={() => handleToggle("music")}>
            Contains Music from Sonar Muse Platfom
          </FormLabel>
          <ToggleButton
            onText={"On"}
            offText={"Off"}
            toggle={isMusicContained}
            toggleClick={() => handleToggle("music")}
          />
        </FormWrapper>
        <FormWrapper>
          <FormLabel onClick={() => handleToggle("downloadable")}>
            Downloadable
          </FormLabel>
          <ToggleButton
            onText={"On"}
            offText={"Off"}
            toggle={isDownloadable}
            toggleClick={() => handleToggle("downloadable")}
          />
        </FormWrapper>
        <FormWrapper>
          <FormLabel onClick={() => handleToggle("streamable")}>
            Streamable
          </FormLabel>
          <ToggleButton
            onText={"On"}
            offText={"Off"}
            toggle={isStreamable}
            toggleClick={() => handleToggle("streamable")}
          />
        </FormWrapper>
        <FormWrapper>
          <FormLabel onClick={() => handleToggle("subscription")}>
            Subscription
          </FormLabel>
          <ToggleButton
            onText={"On"}
            offText={"Off"}
            toggle={isSubscription}
            toggleClick={() => handleToggle("subscription")}
          />
        </FormWrapper>
  
        <br />
        <>
          <FormWrapper>
            <FormLabel>Episode Description</FormLabel>
            <ErrorWrapper>
              <FormTextArea
                placeholder={"Describe the episode"}
                name={"description"}
                value={episodeDescription}
                onChange={(e) => {
                  handleInput(e);
                }}
              />
              {errors?.description && (
                <SongDetailsText error>{errors?.description}</SongDetailsText>
              )}
            </ErrorWrapper>
          </FormWrapper>
        </>
        {isDownloadable === true && (
          <FormWrapper>
            <FormLabel>
              Price{" "}
              <CurrencySelector currency={currency} setCurrency={setCurrency} />
            </FormLabel>
            <ErrorWrapper>
              <FormInput
                style={{ flex: "1 1 50%" }}
                name={"price "}
                placeholder={`Enter price of song in ${currency}`}
                min={0}
                step={
                  price < 10 ? 1 : price < 100 ? 10 : price < 1000 ? 100 : 1000
                }
                type="number"
                value={price}
                onChange={(e) => {
                  handleInput(e);
                }}
              />
              {errors?.price && (
                <SongDetailsText error>{errors?.price}</SongDetailsText>
              )}
            </ErrorWrapper>
          </FormWrapper>
        )}    
      </SongDetailsSection>
    );
  };

export default Page1