import { hexToStr, strToHex } from ".";

export const convertToSwərlURL = (userId, channel) => {
   return `${strToHex(
    `${userId}.${channel[1]}.${hexToStr(channel[0])}${
      channel[2]?.trim().length > 0 ? `.${channel[2]}` : ""
    }`
  )}`
}


export const getSwərlDetails = (url) => {
    let details = hexToStr(url).split(".");
    
    return {
      user: details[0],
      typeId: details[1],
      type: details[2],
      title: details[3] ? hexToStr(details[3]) : undefined
    }
  }