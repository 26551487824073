import React, { useEffect, useState, useCallback, useRef } from "react";
import useMusic from "../../hooks/useMusic";
import { SLIDER_TYPES } from "../../utils/Enum";
import ContentWrapper from "../ContentWrapper";
import PageIndexer from "../PageIndexer";
import { Slider } from "../Slider";
import useAlbumSearch from "../../hooks/useSearch";
import { ModalLoadingScreen } from "../LoadingScreen";
import Modal from "../Modal";

const LatestAlbums = () => {
  const [pageNumber, setPageNumber] = useState(0)

  const {loading, error, albums, pages, setPages} = useAlbumSearch({pageNumber: pageNumber})

  const observer = useRef()
  const lastAlbumElementRef = useCallback( node => {
    if(loading){
      return
    }
    if(observer.current){ 
      observer.current.disconnect()}
      observer.current = new IntersectionObserver(entries=> {
        if(entries[0].isIntersecting && (pageNumber < parseInt(pages/10))){
              setPageNumber(prev => prev + 1)

        }
    }) 
    
    if(node) observer.current.observe(node)
   
  }, [loading, pages, pageNumber])

  useEffect(() => {
    
    /* getLatestAlbums("all")
      .then((data) => {
        if (data) {
          setAlbums(data);
          setLoaded(true);
        }
      })
      .catch((err) => {setLoaded(true)});
      return () => {} */
  }, [loading, pageNumber]);

  return (
    <>
      {
        !loading && albums?.length > 0 && (
          <ContentWrapper heading={"Latest Albums"}>
            <Slider
              type={SLIDER_TYPES.ALBUM}
              heightScroll
              slides={albums}
              cardRef={lastAlbumElementRef}
              id={"latestalbums"}
            />
          </ContentWrapper>
        )
        /*         <PageIndexer/>
         */
      }
      {
        loading && albums?.length > 0 && (
         <ContentWrapper heading={"Latest Albums"}>
            <Slider
              type={SLIDER_TYPES.ALBUM}
              heightScroll
              slides={albums}
              id={"latestalbums"}
            />
                    <Modal isOpen={loading}><ModalLoadingScreen text={'Loading. Please Wait'}/></Modal>

          </ContentWrapper>
        )
        /*         <PageIndexer/>
         */
      }
      {loading && albums?.length === 0 && (
        <ContentWrapper heading={"Latest Albums"}>
             <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        </ContentWrapper>
      )}
      {!loading && albums?.length === 0 && <ContentWrapper heading={"No Albums Found"}>
          
          </ContentWrapper>}
    </>
  );
};

export default LatestAlbums;
