import React from 'react'
import { SongDetailsSection, SongDetailsSectionTitle } from '../MusicUploadsFormElements'
import SongSelector from './SongSelector'
import { PodcasterRoyaltySelector } from './PodcasterSelector'

const Page4 = ({isMusicContained, songs, addSong, addPlaylistSongs, removeSong, addPodcaster, removePodcaster, podcastersPayouts, podcasters,
    payoutArtists,
    errors}) => {
  return (
    <SongDetailsSection>
        <SongDetailsSectionTitle>Credits and Royalties</SongDetailsSectionTitle>
        {isMusicContained && <SongSelector addSong={addSong} addPlaylistSongs={addPlaylistSongs} selectedSongs={songs} removeSong={removeSong}/>}
        <PodcasterRoyaltySelector podcasters={podcasters} payoutError={errors?.payout} removePodcaster={removePodcaster} podcastersPayouts={podcastersPayouts} addPodcaster={addPodcaster} artistPayouts={payoutArtists}/>
    </SongDetailsSection>
  )
}

export default Page4