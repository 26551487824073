import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";

export const HoverOption = styled.div`
    display:flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    padding: 2%;
    margin: 2%;
    text-align: center;
    border-radius: 50px;
    border: 1px solid ${props => props.theme.secondaryBg};
    &:hover {
        background-color: ${props => hexToRgba(props.theme.accentColor, 0.8)}; 
    }
`