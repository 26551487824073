import styled from "styled-components"
import { hexToRgba } from "../HexToRGBA"
import { Nav, NavLink } from "../Navbar/NavbarElements"
import { MusicUploadsButtonContainer } from "../MusicUploadsForm/MusicUploadsFormElements"

export const BannerMarketingNav = styled(Nav)`

@media screen and (max-width: 480px) {
  flex-direction: column;
}
`

export const BannerMarketingNavLink = styled(NavLink)`
flex-direction: column;
align-items: center;
justify-items: center;
background: transparent;
padding: 2%;
margin: 2%;
border: solid ${(props) => (hexToRgba(props.theme.secondaryColor,1)) } 2px;
&:hover {
  background: transparent;
  color: ${(props) => (props.active ? props.theme.secondaryBg: props.theme.secondaryColor )};
  transition: all 0.3s ease-in-out;
  transform: 'scale(0.9)';
}
  color: ${(props) => props.theme.secondaryBg};
    @media screen and (max-width: 480px) {
      font-size: 0.65rem;
    
    }

`

export const BannerMarketingDetailsContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
justify-content:flex-start;
padding: 3%;
border-radius: 15px;
background: ${props => hexToRgba(props.theme.accentColor, 0.1)};
margin-top: 1%;
color: ${props => props.theme.secondaryBg};

`

export const BannerMarketingDetailsWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
`

export const BannerMarketingButtonContainer = styled(MusicUploadsButtonContainer)`
`

export const BannerMarketingText = styled.text`
display: flex;
padding: 2%;
flex: 1 1 100%;
white-space: normal;
color: ${props => props?.error ? props.theme.errorColor : props.theme.secondaryBg};
`

