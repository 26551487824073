import { Link } from "react-router-dom";
import styled from "styled-components";

export const TextLink = styled.a`
  color: ${(props) => props.theme.secondaryColor};
  cursor: pointer;
  text-decoration: none;

  &:hover{
    transition: all 0.3s ease-in-out;
    text-decoration: underline;
    
  }
`;

export const TextRoute = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.secondaryColor};
  &:hover{
    transition: all 0.3s ease-in-out;
    text-decoration: underline;
    
  }

`

export const Text = styled.span`
  text-align: center;
  color: ${(props) => props.theme.secondaryBg};
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
  @media screen and (max-width: 480px) {
    margin-top: 0;
  }
`;


export const FormLabel = styled.label`
	margin-bottom: 40px;
	color: ${props => props.theme.secondaryColor};
	font-size: 14px;
`