import React from 'react'
import { SLIDER_TYPES } from '../../../utils/Enum'
import ContentWrapper from '../../ContentWrapper'
import { Slider } from '../../Slider'
import { useEffect } from 'react'

const ArtistPurchaseMusic = ({content, artist}) => {

    const getPurchasableContent = () => {
        let songs = (content?.songs && content?.songs?.songs?.length > 0) ? content?.songs?.songs.filter(s => s?.isDownloadable) :  []
        let albums = (content?.albums && content?.albums?.albums?.length > 0) ? content?.albums?.albums.filter(a => a?.isDownloadable) : []
        let collections = (content?.collections > 0 && content?.collections?.collections?.length > 0) ? content?.collections.collections  : []
        return [...albums,...collections,...songs]
        
    }

    useEffect(() => {
        getPurchasableContent()
    
      return () => {
      
      }
    }, [content, artist])
    

  return (
    <ContentWrapper heading={`Purchase Music from ${artist.name}`}>
        <ContentWrapper heading={'All Items'}>
            <Slider type={SLIDER_TYPES.MISCELLANEOUS}
            id={'all purchasable items'}
                slides={getPurchasableContent()}
            />
        </ContentWrapper>
        {content?.collections?.length > 0 && <ContentWrapper heading={'NFT Collections'}>
        <Slider type={SLIDER_TYPES.COLLECTION}
        id={'NFToken items'}
            slides={content?.collections?.collections}
        />
        </ContentWrapper>}
        {content?.royalties?.length > 0 && <ContentWrapper heading={'Regium Share Tokens'}>
        <Slider type={SLIDER_TYPES.MUSIC_ROYALTY_SHARE}
        id={'regim shares'}
            slides={content?.royalties?.royalties}
        />
        </ContentWrapper>}
        {content?.songs?.length > 0 && <ContentWrapper heading={'Songs (Digital Sale)'}>
        <Slider type={SLIDER_TYPES.DIGITAL_SONG}
                id={'purchasable songs'}
            slides={content?.songs?.songs?.filter(song => song.isDownloadable)}
        />
        </ContentWrapper>}
        {content?.albums?.length > 0 && <ContentWrapper heading={'Albums (Digital Sale)'}>
        <Slider type={SLIDER_TYPES.DIGITAL_ALBUM}
        id={'purchasable albums'}
        slides={content?.albums?.albums?.filter(album => album?.isDownloadable)}
        />
        </ContentWrapper>}
    </ContentWrapper>
  )
}

export default ArtistPurchaseMusic