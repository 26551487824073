import React, { useState } from 'react'
import { FormWrapper } from '../../CustomForm/CustomFormElements'
import UserRoyaltyForm from '../../MusicUploadsForm/UserRoyaltyForm'
import { CONTENT_TYPES } from '../../../utils/Enum'
import { Button1 } from '../../ButtonElement'
import { useNavigate } from 'react-router-dom'

const RoyaltyEdit
 = ({royalty, submitChanges}) => {
    const [royaltyInfo, setRoyaltyInfo] = useState({
        ...royalty
    })
    const [errors, setErrors] = useState({})
    const navigate = useNavigate()
  return (
    <>
        <FormWrapper>
            <UserRoyaltyForm royaltyInfo={royaltyInfo} setErrors={setErrors} setRoyaltyInfo={setRoyaltyInfo} isEditing={true} />
        
        </FormWrapper>
        <div style={{
        flexDirection: "row", 
      display: "flex", 
      width: "100%", 
      alignContent:"center", 
      alignItems:"center", 
      justifyContent: "space-around"}}><Button1 onClick ={ () =>{
      /* //console.log({
        isDownloadable: albumInfo?.isDownloadable,
        isStreamable: albumInfo?.isStreamable,
        isMintable: albumInfo?.isMintable,
        about: albumInfo?.about,
        price: albumInfo?.isDownloadable ? albumInfo?.price : undefined,
        currency: albumInfo?.isDownloadable ? albumInfo?.currency : undefined,
        songs : songs.map(s => {
          return s.songId
        })
      }) */
      submitChanges(CONTENT_TYPES.ROYALTY_SHARE, {
        percentage: royaltyInfo?.percentage,
        shares: 100,
        price: parseFloat(royaltyInfo?.price),
        currency: royaltyInfo?.currency,
      })}
    }>Submit Changes</Button1>
    <Button1 onClick={() => {
        navigate( `/regium/${royalty?.royaltyId}`,{replace: true});

    }}>Cancel</Button1></div>
    </>
  )
}

export default RoyaltyEdit
