import React, { useEffect, useState } from "react";
import { SkeletonPurchaseCardImg } from "../../Skeleton/SkeletonElements";
import { useModal } from "../../Modal/ModalContext";
import { ArtistSelectorModal, MusicContentMenu } from "../../MenuOptions";
import {
  CardContainer,
  CardContent,
  CardTitle,
  CreatorInfo,
  CreatorInfoContent,
  CreatorInfoWrapper,
  H6,
  InfoContentText,
  TextLink,
} from "../SongCard/SongCardElements";
import { Theme } from "../../WebsiteThemeElements";
import { useAuth } from "../../../contexts/AuthContext";
import ScrollToTop from "../../ScrollToTop";
import ImageUtils from "../../../utils/ImageUtils";
import { getArtistsNames2, getSongTitle, getUniqueListBy } from "../../../utils";
import { ARTIST_SEARCH_TYPES } from "../../../utils/Enum";
import { MdExplicit } from "react-icons/md";
import { RiVideoFill } from "react-icons/ri";
import { PlayButton, PlayContainer } from "../SongCard/SongCardElements";
import VideoPlayer from "../../VideoPlayer";
import OptionModal from "../../OptionModal";
import { useAudio } from "../../AudioPlayer/context/AudioContext";
import { getResizedImage } from "../../../utils/FileUploadsUtils";
import { PreReleaseBadge } from "../CardElements";




const VideoCard = ({content, cardRef}) => {
    const { openModal, closeModal, modalContent } = useModal();
    const { currentUser } = useAuth();
    const { goTo, scrollToTop } = ScrollToTop();
    const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
    const {stop, isPlaying, togglePlayPause} = useAudio()
    const [isAlbumArtLoaded,setAlbumArtLoaded] = useState(false)
    const [albumArt, setAlbumArt] = useState(content?.albumArt)
  
    useEffect(() => {
      if(!isAlbumArtLoaded){
        getResizedImage(content?.thumbnail).then(image => {
          setAlbumArt(image)
          
        }).catch(err => {
          setAlbumArt(content?.thumbnail)
        })
        setAlbumArtLoaded(true)
      }
    }, [])
    return (
      <CardContainer id={content?.videoId}
      onContextMenu={(e) => {
        e.preventDefault();
      }} ref={cardRef} background={content?.color}>
        <div style={{ alignItems: "center", margin: "auto" }}>
          <SkeletonPurchaseCardImg
            src={isAlbumArtLoaded ? albumArt : content?.thumbnail ? content?.thumbnail : defaultAlbumArt}
          ><PlayContainer>
            <PlayButton style={{ width: "6rem", height: "6rem" }}
                      onClick={() => {
                        goTo(`/video/${content?.videoId}`)
                      }}/>
          </PlayContainer></SkeletonPurchaseCardImg>
        </div>
        <CardContent>
        {content?.isPreRelease && <PreReleaseBadge style={{marginLeft: '0',}}>Pre Release</PreReleaseBadge>}
        <H6>Music Video</H6>
          <CardTitle>
            <TextLink alt
              to={content?.videoId ? `/video/${content?.videoId}` : "#"}
            >
              {content?.title}
            </TextLink>
          </CardTitle>
          <CreatorInfoWrapper>
            <CreatorInfo>
              <CreatorInfoContent>
                <InfoContentText>
                  {" "}
                  <TextLink
                    onClick={() => {
                      if(content?.artists?.length > 1){
                          openModal()
                         return modalContent(<ArtistSelectorModal artists={content?.artists}/>)
                      }
                      return scrollToTop()
                    }}
                    to={
                      content?.artists?.length === 1 ? `/artist/${content?.artists[0].artistId}`: 
                       "#"
                    }
                  >
                    {getArtistsNames2(content?.artists, ARTIST_SEARCH_TYPES.NAME)
                      ?.length > 30
                      ? `${getArtistsNames2(
                          content?.artists,
                          ARTIST_SEARCH_TYPES.NAME
                        ).substring(
                          0,
                          25
                        )}...`
                      : getArtistsNames2(
                          content?.artists,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                  </TextLink>
                </InfoContentText>
              </CreatorInfoContent>
            </CreatorInfo>
          </CreatorInfoWrapper>
        </CardContent>
      </CardContainer>
    );
}

export default VideoCard