import React, { useEffect } from 'react'
import { Fade } from 'react-reveal'
import { useNavigate } from 'react-router-dom'
import { HeroBg, HeroContainer, HeroContent, HeroH1, HeroP, VideoBg } from '../components/HeroSection/HeroElements'
import Video from '../videos/video.mp4'
const PageNotFound = () => {
    const navigate  = useNavigate()

    useEffect(() => {
        document.querySelector('meta[name="monetization"]').setAttribute("content", "$ilp.gatehub.net/239263989");

        const interval = setInterval(() => {
            navigate( '/') }, 6000);
        return () => clearInterval(interval);
        
    }, [])


    return (<>
        <HeroContainer>
            <HeroBg>
            <VideoBg autoPlay loop muted src={Video}
                    type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}/>
            </HeroBg>
            <HeroContent isPage={true}>
                
            <Fade infinite><HeroH1 style={{color: 'white'}}>404 Page Not Found</HeroH1></Fade>
            <Fade infinite><HeroH1 style={{color: 'white'}}>You have gone beyond our radar</HeroH1></Fade>
            <Fade infinite><HeroP style={{color:'white'}}>You will be redirected shortly</HeroP> </Fade>

                </HeroContent>
            </HeroContainer>

    </>)

}

export default PageNotFound