import React, { useEffect, useState } from 'react'
import ContentWrapper from '../../ContentWrapper';
import { FormText, FormWrapper } from '../../CustomForm/CustomFormElements';
import { getEpochData, getGroupedPlays, getNumListeners, getObjectLength, getPlayCount, groupByPlayingFrom, groupInfoByDate } from '../../../utils';
import { ChipList } from '../../Chip/ChipElements';
import Chip from '../../Chip';
import { MUSIC_PLAYING_FROM_STATS_COLORS } from '../../../utils/Enum';
import { SelectButton, SelectButtonWrapper } from '../../Select';
import useMusic from '../../../hooks/useMusic';
import { useWebsiteTheme } from '../../WebsiteTheme/WebsiteThemeContext';
import { ModalLoadingScreen } from '../../LoadingScreen';
import KeyMetrics from '../../Charts/KeyMetrics';
import BarGraph from '../../Charts/Bar';
import { Timer } from '../../CountdownTimer';
import ChartToggle from '../../Charts';

const EpisodeStats = ({royalty, episodeId}) => {
  const {themeColors} = useWebsiteTheme()

  const {
    getEpisodePlaysByDate,
    getEpisodeLikesByDate,
    getEpisodePlaylistAdditionsByDate,
  } = useMusic();
  const [filter, setFilter] = useState("7days");
  const [isLoaded, setLoaded] = useState(false);
  const [epochs, setEpochs] = useState([])
  const [metricType, setMetricType] = useState("plays");
  const [likes, setLikes] = useState([]);
  const [plays, setPlays] = useState([]);
  const [playType, setPlayType] = useState("all");
  const [playlists, setPlaylists] = useState([]);

  const getData = async () => {
    setLoaded(false);
    setPlays([]);
    setPlaylists([]);
    setLikes([]);
    return await Promise.all([
      getEpisodePlaysByDate(episodeId, filter),
      getEpisodeLikesByDate(episodeId, filter),
      getEpisodePlaylistAdditionsByDate(episodeId, filter, "all"),
    ])
      .then((data) => {
        if (data[0]?.plays?.length > 0) {
          setPlays(data[0]?.plays);
        }

        if (data[1]?.likes?.length > 0) {
          setLikes(data[1]?.likes);
        }
        if (data[2]?.playlists?.length > 0) {
          setPlaylists(data[2]?.playlists);
        }
        setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        setLoaded(true);
        return;
      });
  };

  useEffect(() => {
    getData();
    setEpochs(royalty?.prevPayouts?.length > 0 ? getEpochData(royalty?.prevPayouts) : [])

    return () => {};
  }, [filter]);

  return (
    <>
      {" "}
      <SelectButtonWrapper>
        <SelectButton
          disabled={!isLoaded}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value={"7days"}>7 days</option>
          <option value={"14days"}>2 Weeks</option>
          <option value={"30days"}>4 Weeks</option>
          <option value={"60days"}>2 Months</option>
          <option value={"180days"}>6 Months</option>
          <option value={"365days"}>12 Months</option>
          <option value={"AllTime"}>All Time</option>
        </SelectButton>
      </SelectButtonWrapper>
      {!isLoaded && <ModalLoadingScreen text={"Loading Stats"} />}
      {isLoaded && (
        <>
          <KeyMetrics
            data={[
              {
                heading: "Plays",
                onClick: () => {
                  setMetricType("plays");
                },
                labels:
                  plays?.length > 0
                    ? Object.keys(groupInfoByDate(plays, "timestamp", filter))
                    : [],
                datasets: [
                  {
                    label: "Plays",
                    data:
                      plays?.length > 0
                        ? Object.values(
                            groupInfoByDate(plays, "timestamp", filter)
                          ).map((p) => {
                            return getPlayCount(p);
                          })
                        : [],
                    borderColor: themeColors.warningColor,
                    backgroundColor: themeColors.warningColor,
                  },
                ],
              }, {
                onClick: () => {
                  setMetricType("epochs");
                },
                heading: "Payouts",
                labels: Object.keys(epochs),
                datasets: [
                  {
                    label: "XRP",
                    data: Object.values(epochs),
                    borderColor: themeColors.background,
                    backgroundColor: themeColors.background,
                  },
                ],
              },
              {
                heading: "Likes",
                onClick: () => {
                  setMetricType("likes");
                },
                labels:
                  likes?.length > 0
                    ? Object.keys(groupInfoByDate(likes, "timestamp", filter))
                    : [],
                datasets: [
                  {
                    label: "likes",
                    data:
                      likes?.length > 0
                        ? Object.values(
                            groupInfoByDate(likes, "timestamp", filter)
                          ).map((l) => {
                            return l;
                          })
                        : [],
                    borderColor: themeColors.successColor,
                    backgroundColor: themeColors.successColor,
                  },
                ],
              },
            ]}
          />
          <ContentWrapper>
            {metricType === "plays" && getObjectLength(plays) > 0 && (
              <>
                <ChipList alignment={"right"}>
                  <Chip
                    label={"All"}
                    background={playType === "all" && themeColors.accentColor}
                    onChipClick={() => setPlayType("all")}
                  />
                  <Chip
                    label={"Full Plays"}
                    background={playType === "full" && themeColors.successColor}
                    onChipClick={() => setPlayType("full")}
                  />
                  <Chip
                    label={"Partial Plays"}
                    background={playType === "partial" && themeColors.infoColor}
                    onChipClick={() => setPlayType("partial")}
                  />
                  <Chip
                    label={"Skips"}
                    background={playType === "skip" && themeColors.warningColor}
                    onChipClick={() => setPlayType("skip")}
                  />
                </ChipList>
                <>
                  {" "}
                  {playType === "all" && (
                    <>
                      
                        <ChartToggle
                          data={{
                            heading: 'All Plays (By Type)',
                            labels:
                              getObjectLength(plays) > 0
                                ? Object.keys(
                                    groupInfoByDate(
                                      [
                                        ...plays?.full,
                                        ...plays?.skips,
                                        ...plays?.partial,
                                      ],
                                      "timestamp",
                                      filter
                                    )
                                  )
                                : [],
                            datasets: [
                              {
                                label: "Full",
                                data:
                                  plays?.full?.length > 0
                                    ? Object.values(
                                        groupInfoByDate(
                                          plays?.full,
                                          "timestamp",
                                          filter
                                        )
                                      ).map((p) => {
                                        return p.length;
                                      })
                                    : [],

                                borderColor: themeColors.successColor,
                                backgroundColor: themeColors.successColor,
                              },
                              {
                                label: "Partial",
                                data:
                                  plays?.partial?.length > 0
                                    ? Object.values(
                                        groupInfoByDate(
                                          plays?.partial,
                                          "timestamp",
                                          filter
                                        )
                                      ).map((p) => {
                                        return p.length;
                                      })
                                    : [],

                                borderColor: themeColors.infoColor,
                                backgroundColor: themeColors.infoColor,
                              },
                              {
                                label: "Skips",
                                data:
                                  plays?.skips?.length > 0
                                    ? Object.values(
                                        groupInfoByDate(
                                          plays?.skips,
                                          "timestamp",
                                          filter
                                        )
                                      ).map((p) => {
                                        return p?.length;
                                      })
                                    : [],
                                borderColor: themeColors.warningColor,
                                backgroundColor: themeColors.warningColor,
                              },
                            ],
                          }}
                        />
                      
                        <ChartToggle
                          data={{
                            heading: 'Unique Listeners',
                            labels:
                              getObjectLength(plays) > 0
                                ? Object.keys(
                                    groupInfoByDate(
                                      [
                                        ...plays?.full,
                                        ...plays?.skips,
                                        ...plays?.partial,
                                      ],
                                      "timestamp",
                                      filter
                                    )
                                  )
                                : [],
                            datasets: [
                              {
                                label: "Listeners",
                                data:
                                  getObjectLength(plays) > 0
                                    ? Object.values(
                                        getNumListeners(
                                          groupInfoByDate(
                                            [
                                              ...plays?.full,
                                              ...plays?.skips,
                                              ...plays?.partial,
                                            ],
                                            "timestamp",
                                            filter
                                          )
                                        )
                                      )
                                    : [],
                                borderColor:
                                  playType === "skip"
                                    ? themeColors.warningColor
                                    : playType === "partial"
                                    ? themeColors.infoColor
                                    : themeColors.successColor,
                                backgroundColor:
                                  playType === "skip"
                                    ? themeColors.warningColor
                                    : playType === "partial"
                                    ? themeColors.infoColor
                                    : themeColors.successColor,
                              },
                            ],
                          }}
                        />
              
                    </>
                  )}
                  {playType !== "all" && (
                    <>
                      
                        <ChartToggle
                          data={{
                            heading: `${playType[0].toUpperCase()}${playType.substring(
                            1
                          )} Plays`,
                            labels:
                              getObjectLength(plays) > 0
                                ? Object.keys(
                                    groupInfoByDate(
                                      [
                                        ...plays?.full,
                                        ...plays?.skips,
                                        ...plays?.partial,
                                      ],
                                      "timestamp",
                                      filter
                                    )
                                  )
                                : [],
                            datasets: [
                              {
                                label: playType,
                                data:
                                  getObjectLength(plays) > 0
                                    ? Object.values(
                                        groupInfoByDate(
                                          [
                                            ...plays?.full,
                                            ...plays?.skips,
                                            ...plays?.partial,
                                          ],
                                          "timestamp",
                                          filter
                                        )
                                      ).map((plays) => {
                                        return getGroupedPlays(plays)[playType];
                                      })
                                    : [],

                                borderColor:
                                  playType === "skip"
                                    ? themeColors.warningColor
                                    : playType === "partial"
                                    ? themeColors.infoColor
                                    : themeColors.successColor,
                                backgroundColor:
                                  playType === "skip"
                                    ? themeColors.warningColor
                                    : playType === "partial"
                                    ? themeColors.infoColor
                                    : themeColors.successColor,
                              },
                            ],
                          }}
                        />
                     
                        <ChartToggle
                          data={{
                            heading: 'Unique Listeners',
                            labels:
                              getObjectLength(plays) > 0
                                ? Object.keys(
                                    groupInfoByDate(
                                      [
                                        ...plays?.full,
                                        ...plays?.skips,
                                        ...plays?.partial,
                                      ],
                                      "timestamp",
                                      filter
                                    )
                                  )
                                : [],
                            datasets: [
                              {
                                label: "Listeners",
                                data:
                                  getObjectLength(plays) > 0
                                    ? Object.values(
                                        getNumListeners(
                                          groupInfoByDate(
                                            [
                                              ...plays?.full,
                                              ...plays?.skips,
                                              ...plays?.partial,
                                            ].filter(
                                              (p) => playType === p?.playType
                                            ),
                                            "timestamp",
                                            filter
                                          )
                                        )
                                      )
                                    : [],
                                borderColor:
                                  playType === "skip"
                                    ? themeColors.warningColor
                                    : playType === "partial"
                                    ? themeColors.infoColor
                                    : themeColors.successColor,
                                backgroundColor:
                                  playType === "skip"
                                    ? themeColors.warningColor
                                    : playType === "partial"
                                    ? themeColors.infoColor
                                    : themeColors.successColor,
                              },
                            ],
                          }}
                        />
                    </>
                  )}
                </>
                  <ChartToggle
                  type={'bar'}
                    data={{
                      heading: 'Played From',
                      labels:
                        getObjectLength(plays) > 0
                          ? Object.keys(
                              groupByPlayingFrom([
                                ...plays?.full,
                                ...plays?.skips,
                                ...plays?.partial,
                              ])
                            )
                          : [],
                      datasets: [
                        {
                          label: "Played From",
                          data:
                            getObjectLength(plays) > 0
                              ? Object.values(
                                  groupByPlayingFrom([
                                    ...plays?.full,
                                    ...plays?.skips,
                                    ...plays?.partial,
                                  ])
                                )
                              : [],
                          borderColor: Object.keys(
                            groupByPlayingFrom([
                              ...plays?.full,
                              ...plays?.skips,
                              ...plays?.partial,
                            ])
                          ).map((k) => {
                            return MUSIC_PLAYING_FROM_STATS_COLORS[k]
                          }),
                          backgroundColor: Object.keys(
                            groupByPlayingFrom([
                              ...plays?.full,
                              ...plays?.skips,
                              ...plays?.partial,
                            ])
                          ).map((k) => {
                            return MUSIC_PLAYING_FROM_STATS_COLORS[k]
                          }),
                        },
                      ],
                    }}
                  />
              </>
            )}
      {metricType === 'likes' && likes?.length > 0 && 
      <ChartToggle data={{
          heading: "No. Likes",
          labels: likes.length > 0 ? Object.keys(groupInfoByDate(likes,'dateCreated', filter)) : [],
          datasets: [{
              label: "Likes",
              data: likes.length > 0 ? Object.values(groupInfoByDate(likes,'dateCreated', filter)).map(l => {
                return l?.length
              }) : [],
              borderColor: themeColors.infoColor,
              backgroundColor: themeColors.infoColor
          }]
          }}/>
   }
      {metricType === "epochs" && getObjectLength(epochs) > 0 && (
            <>
              {" "}
              <ContentWrapper>
                <Timer
                  heading={`$${royalty?.ticker} Payout Epoch ${
                    royalty.prevPayouts?.length > 0
                      ? royalty.prevPayouts?.length + 1
                      : 1
                  }`}
                  isPage={false}
                  date={royalty.nextPayout}
                  details={`Snapshot of $${
                    royalty?.ticker
                  } Shareholders for payout epoch ${
                    royalty.prevPayouts?.length > 0
                      ? royalty.prevPayouts?.length + 1
                      : 1
                  } ${
                    !royalty?.snapshot
                      ? `will take place on ${
                          new Date(
                            new Date()?.setDate(
                              new Date(royalty.nextPayout).getDate() - 3
                            )
                          )
                            .toISOString()
                            .split("T")[0]
                        }`
                      : "has been taken."
                  }`} /* img={royalty?.musicDetails?.albumArt
                ? royalty?.musicDetails?.albumArt
                : royalty?.musicDetails.thumbnail} */
                />
              </ContentWrapper>
              <div style={{ marginTop: "20%" }}></div>
              <ContentWrapper>
              
                  <ChartToggle
                    data={{
                      heading: "Epoch Payouts (In XRP)",
                      labels: Object.keys(epochs),
                      datasets: [
                        {
                          label: "Payout",
                          data: Object.values(epochs),

                          borderColor: themeColors.successColor,
                          backgroundColor: themeColors.successColor,
                        },
                      ],
                    }}
                  />
              </ContentWrapper>
            </>
          )}
            {((metricType === "plays" && plays?.length === 0) ||
              (metricType === "likes" && likes?.length === 0) ||
              (metricType === "playlists" && playlists?.length === 0)) && (
              <>
                <ContentWrapper>
                  <FormWrapper>
                    <FormText>No Data Available</FormText>
                  </FormWrapper>
                </ContentWrapper>
              </>
            )}
          </ContentWrapper>
        </>
      )}
    </>
  );
}

export default EpisodeStats