import React, { useCallback, useRef } from 'react'
import ContentWrapper from '../ContentWrapper'
import { SLIDER_TYPES } from '../../utils/Enum'
import { Slider } from '../Slider'
import Modal from '../Modal'
import { ModalLoadingScreen } from '../LoadingScreen'
import { useState } from 'react'
import { useSimilarRoyaltiesSearch } from '../../hooks/useSearch'

const SimilarRoyalties = ({type}) => {
    const [pageNumber, setPageNumber] = useState(0)
    const {loading, error, royalties, pages, setPages} = useSimilarRoyaltiesSearch({pageNumber: pageNumber, type: type})
    const observer = useRef()
    const lastRoyaltyElementRef = useCallback( node => {
      if(loading){
        return
      }
      if(observer.current){ 
        observer.current.disconnect()}
        observer.current = new IntersectionObserver(entries=> {
          if(entries[0].isIntersecting && (pageNumber < parseInt(pages/10))){
                setPageNumber(prev => prev + 1)
  
          }
      }) 
      
      if(node) observer.current.observe(node)
     
    }, [loading, pages, pageNumber])
    
  return (
    <>
    <ContentWrapper heading={`Other ${type} Regium Share Tokens`}>
    {
      !loading && royalties?.length > 0 && (
        
          <Slider
            type={SLIDER_TYPES.MUSIC_ROYALTY_SHARE}
            heightScroll
            slides={royalties}
            cardRef={lastRoyaltyElementRef}
            id={"similar royalties"}
          />
       
      )
      /*         <PageIndexer/>
       */
    }
    {
      loading && royalties?.length > 0 && (
        <>
          <Slider
            type={SLIDER_TYPES.MUSIC_ROYALTY_SHARE}
            heightScroll
            slides={royalties}
            cardRef={lastRoyaltyElementRef}
            id={"similar royalties"}
          />
                   <Modal isOpen={loading}><ModalLoadingScreen text={'Loading. Please Wait'}/></Modal>

</>
      )
      /*         <PageIndexer/>
       */
    }
    {loading && royalties?.length === 0 && (
      <ContentWrapper heading={`Other ${type} Regium Share Tokens`}>
           <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      </ContentWrapper>
    )}
    {!loading && royalties?.length === 0 && <ContentWrapper heading={"No Regium Share Tokens Found"}>
        
        </ContentWrapper>}
        </ContentWrapper>
  </>
  )
}


export default SimilarRoyalties