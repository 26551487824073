import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";
import { Nav, NavLink } from "../NavLink/NavLinkElements";

export const Container = styled.div`
    display: flex;
    min-height: 100vh;
    background: ${props => hexToRgba(props.theme.mainBg, 0.2)};
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2% 2%;
    border-radius: 15px;
    transition: all 0.2s ease-in-out;
    
`

export const  SearchHeader = styled.div`
    display: flex;
    position:relative;
    flex-direction: column;
    background: ${(props) => 
    ( (props?.background) ? 
      `linear-gradient(180deg, ${props.background} 0%, rgba(84, 120, 154, 0.6232) 100%)`:
        `linear-gradient(180deg, ${props.theme.mainColor} 0%, rgba(84, 120, 154, 0.6232) 100%)`)
    };
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 8% 3%;
    margin-bottom: 2%;

`

export const Title = styled.title`
  display: flex; 
  font-size: 3rem;
  font-weight: 700;
  color: ${props => props.theme.secondaryBg};
  filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};
`

export const SearchBarWrapper = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 50%;
    background: ${ props => `linear-gradient(180deg,  ${props.theme.mainColor} 0%, rgba(84, 120, 154, 0.6232) 100%)`};
    border-radius: 15px;
    overflow: hidden;
    @media screen and (max-width: 960px) {
        width: 70%;
      }
    
`

export const SearchBar = styled.input`
    display: flex; 
    height: 2.5rem;
    width: 100%;
    padding: 2%;
    margin: 1%;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px 0  0 10px;
    outline: none;
    color: ${props => props.theme.secondaryBg};
    background-color: ${props => hexToRgba(props.theme.mainBg, 1)};

    &:hover {
        outline: none;
        transition: all 0.2s ease-in-out;
      }

     &:active {
        border: 1px solid ${(props) => props.theme.mainColor};
    }
    @media screen and (max-width: 960px) {
        padding: 4%;
        width: 100%;
        height: 2rem;
        padding: 1.5%;
        font-size: 0.6rem;
      }
`

export const SearchNav = styled(Nav)`
    margin: 2% 0;
`

export const SearchNavLink = styled(NavLink)``


export const SearchButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 5px 5px 0;
    padding: 2% 3%;

    &:hover {
        transform: scale(1.07);
        outline: none;
        transition: all 0.2s ease-in-out;
    };
    @media screen and (max-width: 960px) {
        padding: 1% 1.5%;
      }
    ${SearchBar}: hover &{
        transition: all 0.2s ease-in-out;
        background: ${(props) => props.theme.mainBg};
    }
`

export const SearchContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100$;
    background: ${props => hexToRgba(props.theme.secondaryBg, 0.008)};
`
export const GenreSinglesContainer = styled.div`
    display: flex;
    flex-directionL row;
    width: 100%;
    background: ${props => hexToRgba(props.theme.secondaryBg, 0.008)};

`


export const SearchContentContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
color: white;
padding: 2% 2%;
width: 100%;

`