import { GravatarQuickEditorCore } from '@gravatar-com/quick-editor';
import React from 'react'
import { FormWrapper } from '../CustomForm/CustomFormElements';
import { ActionButton, BioInput, InputLabel, SettingsWrapper } from './GravatarSettingsElements';
import ToggleButton from '../ToggleButton';
import { stringFormatter } from '../../utils';

const GravatarSettings = ({type, options, email}) => {
  return (
    <>{type !== 'User' && <><FormWrapper> <SettingsWrapper>
    <InputLabel>Profile Picture</InputLabel>
    <ToggleButton
      onText={"Custom"}
      offText={"Gravatar"}
      toggle={options?.profilePictureType === "custom"}
      toggleClick={options?.switchProfilePictureType}
    />
  </SettingsWrapper>{options?.profilePictureType === 'gravatar' &&<SettingsWrapper>
            <InputLabel>Profile Picture</InputLabel>
            <ActionButton
          style={{ padding: "1% 2%" }}
          onClick={() => {
            new GravatarQuickEditorCore( {
              email: email,
              scope: ['avatars'],
              onProfileUpdated: () => {
                console.log( 'Profile updated!' );
              },
              onOpened: () => {
                console.log( 'Editor opened!' );
              },
            } ).open()
            
          }}
        >
          Edit
        </ActionButton>
          </SettingsWrapper>}</FormWrapper>
    <FormWrapper>
      <SettingsWrapper>
            <InputLabel>Header</InputLabel>
            <ToggleButton
              onText={"Color"}
              offText={"Background Image"}
              toggle={options?.headerType === "color"}
              toggleClick={options?.switchHeaderType}
            />
          </SettingsWrapper>
          <SettingsWrapper>
            <InputLabel>Change/Upload Header</InputLabel>
            <ActionButton
          style={{ padding: "1% 2%" }}
          onClick={() => {
            new GravatarQuickEditorCore( {
              email: email,
              scope: ['design'],
              onProfileUpdated: () => {
                console.log( 'Profile updated!' );
              },
              onOpened: () => {
                console.log( 'Editor opened!' );
              },
            } ).open()
            
          }}
        >
          Edit
        </ActionButton>
          </SettingsWrapper></FormWrapper>
   <FormWrapper>  
    <SettingsWrapper>
      <InputLabel>{type} Bio</InputLabel>
      <ToggleButton
        onText={"Custom"}
        offText={"Gravatar"}
        toggle={options?.bioType === "custom"}
        toggleClick={() => {
          if (options?.bio) {
            options?.switchBioType();
          }
        }}
      />
    </SettingsWrapper>
    {options?.bioType === "custom" && (
      <BioInput
        name={options?.bioInputName}
        type={"text"}
        disabled={options?.bioType !== "custom"}
        placeholder="Bio"
        value={stringFormatter(options?.bio)}
        onChange={options?.handleInput}
      />
    )}
  { options?.bioType === 'gravatar' && <SettingsWrapper>
            <InputLabel>Bio</InputLabel>
            <ActionButton
          style={{ padding: "1% 2%" }}
          onClick={() => {
            new GravatarQuickEditorCore( {
              email: email,
              scope: ['about'],
              onProfileUpdated: () => {
                console.log( 'Profile updated!' );
              },
              onOpened: () => {
                console.log( 'Editor opened!' );
              },
            } ).open()
            
          }}
        >
          Edit
        </ActionButton>
          </SettingsWrapper>}</FormWrapper>
          <FormWrapper>
    <SettingsWrapper>
            <InputLabel>Social Platforms Links</InputLabel>
            <ActionButton
          style={{ padding: "1% 2%" }}
          onClick={() => {
            new GravatarQuickEditorCore( {
              email: email,
              scope: ['verified-accounts'],
              onProfileUpdated: () => {
                console.log( 'Profile updated!' );
              },
              onOpened: () => {
                console.log( 'Editor opened!' );
              },
            } ).open()
            
          }}
        >
          Edit
        </ActionButton>
          </SettingsWrapper></FormWrapper>
    <FormWrapper> <SettingsWrapper>
    <InputLabel>Enable Tipping</InputLabel>
    <ToggleButton
      onText={"On"}
      offText={"Off"}
      toggle={options?.tipping}
      toggleClick={options?.toggleTipping}
    />
  </SettingsWrapper>{options?.tipping && 
      <SettingsWrapper>
            <InputLabel>Tipping Wallets</InputLabel>
            <ActionButton
          style={{ padding: "1% 2%" }}
          onClick={() => {
            new GravatarQuickEditorCore( {
              email: email,
              scope: ['wallet'],
              onProfileUpdated: () => {
                console.log( 'Profile updated!' );
              },
              onOpened: () => {
                console.log( 'Editor opened!' );
              },
            } ).open()
            
          }}
        >
          Edit
        </ActionButton>
          </SettingsWrapper>
       } </FormWrapper>

          <FormWrapper>
        <SettingsWrapper>
          <InputLabel>Photo Gallery</InputLabel>
          <ToggleButton
            onText={"On"}
            offText={"Off"}
            toggle={options?.showGallery}
            toggleClick={() => options?.setShowGallery(!options?.showGallery)}
          />
        </SettingsWrapper>
      
    {options?.showGallery && <SettingsWrapper>
            <InputLabel>Gallery</InputLabel>
            <ActionButton
          style={{ padding: "1% 2%" }}
          onClick={() => {
            new GravatarQuickEditorCore( {
              email: email,
              scope: ['photos'],
              onProfileUpdated: () => {
                console.log( 'Profile updated!' );
              },
              onOpened: () => {
                console.log( 'Editor opened!' );
              },
            } ).open()
            
          }}
        >
          Edit
        </ActionButton>
          </SettingsWrapper>}
          </FormWrapper>
  
 
      
      {options?.about?.trips && (
        <SettingsWrapper>
          <InputLabel>Trips</InputLabel>
          <ToggleButton
            onText={"On"}
            offText={"Off"}
            toggle={options?.showTrips}
            toggleClick={() => options?.setTrips(!options?.showTrips)}
          />
        </SettingsWrapper>
  )}</>}
  {type === 'User' && <>
    <FormWrapper> 
           <SettingsWrapper>
                    <InputLabel>Profile Picture</InputLabel>
                    <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    new GravatarQuickEditorCore( {
                      email: email,
                      scope: ['avatars'],
                      onProfileUpdated: () => {
                        console.log( 'Profile updated!' );
                      },
                      onOpened: () => {
                        console.log( 'Editor opened!' );
                      },
                    } ).open()
                    
                  }}
                >
                  Edit
                </ActionButton>
                  </SettingsWrapper></FormWrapper>
            <FormWrapper>
                  <SettingsWrapper>
                    <InputLabel>Change Theme Color</InputLabel>
                    <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    new GravatarQuickEditorCore( {
                      email: email,
                      scope: ['design'],
                      onProfileUpdated: () => {
                        console.log( 'Profile updated!' );
                      },
                      onOpened: () => {
                        console.log( 'Editor opened!' );
                      },
                    } ).open()
                    
                  }}
                >
                  Edit
                </ActionButton>
                  </SettingsWrapper></FormWrapper>
           <FormWrapper>  
            <SettingsWrapper>
                    <InputLabel>Bio</InputLabel>
                    <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    new GravatarQuickEditorCore( {
                      email: email,
                      scope: ['about'],
                      onProfileUpdated: () => {
                        console.log( 'Profile updated!' );
                      },
                      onOpened: () => {
                        console.log( 'Editor opened!' );
                      },
                    } ).open()
                    
                  }}
                >
                  Edit
                </ActionButton>
                  </SettingsWrapper></FormWrapper>
                  <FormWrapper>
            <SettingsWrapper>
                    <InputLabel>Social Platforms Links</InputLabel>
                    <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    new GravatarQuickEditorCore( {
                      email: email,
                      scope: ['verified-accounts'],
                      onProfileUpdated: () => {
                        console.log( 'Profile updated!' );
                      },
                      onOpened: () => {
                        console.log( 'Editor opened!' );
                      },
                    } ).open()
                    
                  }}
                >
                  Edit
                </ActionButton>
                  </SettingsWrapper></FormWrapper>
            <FormWrapper> 
              <SettingsWrapper>
                    <InputLabel>Tipping Wallets</InputLabel>
                    <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    new GravatarQuickEditorCore( {
                      email: email,
                      scope: ['wallet'],
                      onProfileUpdated: () => {
                        console.log( 'Profile updated!' );
                      },
                      onOpened: () => {
                        console.log( 'Editor opened!' );
                      },
                    } ).open()
                    
                  }}
                >
                  Edit
                </ActionButton>
                  </SettingsWrapper>
                </FormWrapper>

                  <FormWrapper>
             
              
            <SettingsWrapper>
                    <InputLabel>Gallery</InputLabel>
                    <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    new GravatarQuickEditorCore( {
                      email: email,
                      scope: ['photos'],
                      onProfileUpdated: () => {
                        console.log( 'Profile updated!' );
                      },
                      onOpened: () => {
                        console.log( 'Editor opened!' );
                      },
                    } ).open()
                    
                  }}
                >
                  Edit
                </ActionButton>
                  </SettingsWrapper>
                  </FormWrapper>
          
         
    
  
  </>}</>
  )
}

export default GravatarSettings