import React, { useEffect, useReducer, useRef, useState } from "react";
import VideoReducer from "./VideoReducer";
import { MUSIC_PLAYING_FROM, VIDEO_PLAYER_ACTIONS, VIDEO_SCREEN_SIZE } from "../../../utils/Enum";
import { Theme } from "../../WebsiteThemeElements";
import { convertDuration, getSongTitle } from "../../../utils";
import { SonarMuseWebMonetization, defaultPaymentChannel, defaultPaymentPointer } from "../../SonarMuseWebMonetization";
import { useAuth } from "../../../contexts/AuthContext";
import useMusic from "../../../hooks/useMusic";

const initialState = {
  currentVideo: 0,
  playlist: [],
  isPlaying: false,
  isShuffle: false,
  isRepeat: false,
  isPictureInPicture: false,
  isFullScreen: false,
  isTheatreMode: false,
  isMute: false,
  playback: 1,
  volume: 1,
};

const useVideoPlayer = () => {
  const {currentUser} = useAuth()
  const {updateMusicPlay} = useMusic()
  const [state, dispatch] = useReducer(VideoReducer, initialState);
  const [duration, setDuration] = useState(0);
  const {changeArtistPaymentChannel, defaultPaymentChannel, changePodcasterPaymentChannel} = SonarMuseWebMonetization()
  const [currentTime, setCurrentTime] = useState(0);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isUpdating, setUpdating] = useState(false)
  const videoPlayer = useRef();
  const progressBar = useRef(); // Reference to the progressBar
  const animationRef = useRef(); // Reference the animation of song play
  const videoContainer = useRef();



  useEffect(() => {
    if(videoPlayer && videoPlayer?.current){
      videoPlayer.current.preload = "auto"

      videoPlayer.current?.addEventListener('enterpictureinpicture', () => {
        dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_PIP_MODE, data: true })
        if(videoPlayer.current.paused){
          dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_PLAY, data: false})
      }
      });
      
      videoPlayer.current?.addEventListener('leavepictureinpicture', () => {
        dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_PIP_MODE, data: false })
        if(videoPlayer?.current?.paused){
          dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_PLAY, data: false})
      }
      });}

     videoPlayer.current?.addEventListener("waiting", onWaiting);
   videoPlayer.current?.addEventListener("play", onPlay);
      return () => {
       videoPlayer.current?.removeEventListener("waiting", onWaiting);
     videoPlayer.current?.removeEventListener("play", onPlay);
        videoPlayer.current?.removeEventListener('enterpictureinpicture', () => {})
        videoPlayer.current?.removeEventListener('leavepictureinpicture', () => {})
      }
  }, [])

  useEffect(() => {
    if (videoPlayer?.current?.readyState && videoPlayer.current?.duration ) {
        const seconds = Math.floor(videoPlayer.current?.duration);
  
        if(progressBar?.current){
          progressBar.current.value = 0
          setCurrentTime(videoPlayer?.current?.currentTime);
          setDuration(seconds);
          progressBar.current.max = seconds;
      }
      
      }
    return () => {}
  }, [videoPlayer,videoPlayer?.current?.loadeddata, videoPlayer?.current?.loadedmetadata, videoPlayer?.current?.readyState,  dispatch]);
  const onWaiting = () => {
    if (state.isPlaying) {
      setIsPlaying(false);}
    setIsWaiting(true);
  };

  const onPlay = () => {
    if (isWaiting) setIsWaiting(false);
      setIsPlaying(true)
  };

  useEffect(() => {
     if (
      (!isNaN(currentTime) &&
      Number(duration) > 0) &&
      Number(currentTime) === Number(duration) 
    ) {
        if(videoPlayer.current?.paused){
        dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_PLAY, data: false})
        videoPlayer.current.currentTime = 0
    }
    }
    return () => {}
  }, [currentTime, duration, state?.currentVideo, state?.playlist]);

  

  const setPlaylist = (pl) => {
    dispatch({type: VIDEO_PLAYER_ACTIONS.SET_PLAYLIST, data: pl})
    setCurrentVideo(0)
  } 

  const setCurrentVideo = (data) => {
    dispatch({type: VIDEO_PLAYER_ACTIONS.SET_CURRENT_VIDEO, data: data})
  } 


  const handleEnd = () => {
    stop()
  }
    
  const play = () => {
    videoPlayer?.current
    .play()
    .then(() => {
      try{
        dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_PLAY, data: true})
        animationRef.current = requestAnimationFrame(whilePlaying);
        setCurrentTime(videoPlayer?.current?.currentTime)
        videoPlayer.current.loop = state?.isRepeat;
        videoPlayer.current.volume = state?.volume;
        videoPlayer.current.muted = state?.isMute;
      }catch(err){
        dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_PLAY, data: false})
        timeTravel(0);
      }
    })
    .catch((e) => {
      document.title = "Sonar Muse"
      dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_PLAY, data: false})
      timeTravel(0);
      return
    })
  }

  const whilePlaying = () => {
    try{

      if(progressBar?.current && videoPlayer?.current){
      progressBar.current.value = videoPlayer?.current?.currentTime > 0 ? videoPlayer?.current?.currentTime : 0;
      changePlayerCurrentTime()
      animationRef.current = requestAnimationFrame(whilePlaying);}
      return
    }catch(err){
      
      if(progressBar?.current?.value){
        progressBar.current.value = 0;
      }

    }
  };

  const togglePlay = () => {
    let prevVal = state?.isPlaying
    //console.log(state.playlist[state?.currentVideo])

    dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_PLAY, data: !prevVal })
    if(!prevVal){
       play()
      
      if((state.playlist[state?.currentVideo]?.playingFrom?.playingFrom !== MUSIC_PLAYING_FROM.NFT) && (state.playlist[state?.currentVideo]?.playingFrom?.playingFrom !== MUSIC_PLAYING_FROM.COLLECTION)&& (state.playlist[state?.currentVideo]?.playingFrom?.playingFrom !== MUSIC_PLAYING_FROM.MUSIC_STORE))
      {if(state.playlist[state?.currentVideo]?.royalties && state.playlist[state?.currentVideo]?.videoId ){
        changeArtistPaymentChannel(state.playlist[state.currentVideo].royalties, `music video ${state.playlist[state?.currentVideo].title} (id: ${state.playlist[state?.currentVideo]?.videoId})`)
      }
      if(state.playlist[state?.currentVideo]?.royalties && state.playlist[state?.currentVideo]?.episodeId){
        changePodcasterPaymentChannel(state.playlist[state?.currentVideo]?.royalties, state.playlist[state?.currentVideo]?.artistRoyalties, `podcast episode video ${state.playlist[state.currentVideo]?.episodeName} (id: ${state.playlist[state?.currentVideo]?.episodeId})`)
      }}
    }else{
        videoPlayer.current.pause();
        defaultPaymentPointer();
      defaultPaymentChannel()
    }
  }

  const togglePlaybackSpeed = () => {
    let prevVal = state.playback
    if(prevVal < 2){
      dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_PLAYBACK_SPEED, data: parseFloat(prevVal + 0.25)})
      videoPlayer.current.playbackRate = parseFloat(prevVal + 0.25)
    }else{
      dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_PLAYBACK_SPEED, data: 0.25})
      videoPlayer.current.playbackRate = 0.25
    }
  }


  const stop = () => {
    videoPlayer.current.pause();
    /* defaultPaymentPointer();
    defaultPaymentChannel() */
    dispatch({type: VIDEO_PLAYER_ACTIONS.UPDATE_PLAY_ID, data: 0})
          dispatch({type: VIDEO_PLAYER_ACTIONS.UPDATE_PLAY_TYPE, data: "skip"})
    dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_PLAY, data: false})
    timeTravel(0);
    defaultPaymentPointer();
    defaultPaymentChannel()
}

  const toggleMute = () => {
    let prevVal = state.isMute
    dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_MUTE, data: !prevVal })
  }

  const toggleScreenMode = (type) => {
    let prevVal
    switch (type) {
        case VIDEO_SCREEN_SIZE.NORMAL:
            dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_NORMAL_SCREEN_MODE, data: type })
            break;
        case VIDEO_SCREEN_SIZE.FULL_SCREEN:
            prevVal = state.isFullScreen
            dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_FULL_SCREEN_MODE, data: !prevVal })
            if(document.fullscreenElement == null && videoContainer?.current){
              videoContainer?.current?.requestFullscreen()
            }else{
                document.exitFullscreen()
            }
            break;
        case VIDEO_SCREEN_SIZE.PIP:
            prevVal = state.isPictureInPicture
            dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_PIP_MODE, data: !prevVal })
            if((prevVal || document.pictureInPictureElement) && videoPlayer?.current){
                document.exitPictureInPicture()
            }else{
                videoPlayer.current.requestPictureInPicture()
            }
            
            break;
        case VIDEO_SCREEN_SIZE.THEATRE:
            prevVal = state.isTheatreMode
            dispatch({type: VIDEO_PLAYER_ACTIONS.TOGGLE_THEATRE_MODE, data: !prevVal })
            break;
    
        default:
            break;
    }

  }

  const toggleVolume = (e) => {
    dispatch({type: VIDEO_PLAYER_ACTIONS.VOLUME_CHANGE, data: e.target.value})
    videoPlayer.current.volume = e.target.value
}

const timeTravel = (newTime) => {
    if(progressBar?.current?.value){
      progressBar.current.value = newTime;
      changeRange();
    }
    
  };

const changeRange = () => {
    videoPlayer.current.currentTime = progressBar?.current?.value;
    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    setCurrentTime(progressBar?.current?.value);
    progressBar?.current?.style.setProperty(
      `${Theme({}).accentColor}`,
      `${(progressBar?.current?.value / duration) * 100}%`
    );
    
    
  };
  

  const calcTime = (secs) => {
    if(state.isPlaying){
      if (parseInt(secs) === 5) {
        updateMusicPlay(
          currentUser?.uid,
          state.playlist[state.currentSong]?.episodeId ? "episode video" : "music video",
          state.playlist[state.currentSong]?.episodeId
            ? state.playlist[state.currentSong]?.episodeId
            : state.playlist[state.currentSong]?.songId,
          state.playId,
          "skip",
          state.playlist[state.currentSong]?.playingFrom
        ).then((data) => { 
          dispatch({type: VIDEO_PLAYER_ACTIONS.UPDATE_PLAY_ID, data: data})
          dispatch({type: VIDEO_PLAYER_ACTIONS.UPDATE_PLAY_TYPE,data: "skip"})
        }).catch(err => {
          console.error(err)
        });
      }
      if (parseInt((parseInt(secs) / duration) * 100) >= 45 && state.playType === "skip") {
       updateMusicPlay(
          currentUser?.uid,
          state.playlist[state.currentSong]?.episodeId ? "episode video" : "music video",
          state.playlist[state.currentSong]?.episodeId
            ? state.playlist[state.currentSong]?.episodeId
            : state.playlist[state.currentSong]?.songId,
          state.playId,
          "partial",
          state.playlist[state.currentSong]?.playingFrom
        ).then((data) => { 
          dispatch({type: VIDEO_PLAYER_ACTIONS.UPDATE_PLAY_TYPE, data: "partial"})
        }).catch(err => {
          console.error(err)
        });
      }
      if (parseInt((parseInt(secs) / duration) * 100) >= 75 && state.playType === "partial") {
       updateMusicPlay(
          currentUser?.uid,
          state.playlist[state.currentSong]?.episodeId ? "episode video" : "music video",
          state.playlist[state.currentSong]?.episodeId
            ? state.playlist[state.currentSong]?.episodeId
            : state.playlist[state.currentSong]?.songId,
          state.playId,
          "full",
          state.playlist[state.currentSong]?.playingFrom
        ).then((data) => { 
          dispatch({type: VIDEO_PLAYER_ACTIONS.UPDATE_PLAY_TYPE, data: "full"})
        }).catch(err => {
          console.error(err)
        });
      }
    if (secs % 10 === 0 ) {
      //console.log(secs)
      if((state.playlist[state?.currentVideo]?.playingFrom?.playingFrom !== MUSIC_PLAYING_FROM.NFT) && (state.playlist[state?.currentVideo]?.playingFrom?.playingFrom !== MUSIC_PLAYING_FROM.COLLECTION)){
        if(state.playlist[state?.currentVideo]?.royalties){
      if(state.playlist[state?.currentVideo]?.songId ){
        changeArtistPaymentChannel(state.playlist[state.currentVideo].royalties, `music video ${getSongTitle(state.playlist[state.currentVideo])} (id: ${state.playlist[state?.currentVideo]?.songId})`)
      }
      if(state.playlist[state?.currentVideo]?.episodeId){
        changePodcasterPaymentChannel(state.playlist[state?.currentVideo]?.royalties, state.playlist[state?.currentVideo]?.artistRoyalties, `podcast episode video ${state.playlist[state.currentVideo]?.episodeName} (id: ${state.playlist[state?.currentVideo]?.episodeId})`)
      }
    }else{
        defaultPaymentPointer()
        defaultPaymentChannel()
      }}
    } 
  }
    return convertDuration(secs);
  };


  return {
    videoPlayer,
    progressBar,
    videoContainer,
    isVideoPlaying: state?.isPlaying,
    isRepeat: state?.isRepeat,
    isShuffle: state?.isShuffle,
    currentVideo: state?.currentVideo,
    isTheatreMode: state?.isTheatreMode,
    isFullScreen: state?.isFullScreen,
    isPictureInPicture: state?.isPictureInPicture,
    playlist: state?.playlist,
    volume: state?.volume,
    isMute: state?.isMute,
    playback: state?.playback,
    duration,
    currentTime,
    play,
    setPlaylist,
    setCurrentVideo,
    togglePlay,
    stop,
    toggleMute,
    toggleVolume,
    toggleScreenMode,
    togglePlaybackSpeed,
    calcTime,
    timeTravel,
    changeRange,
    handleEnd
  };
};

export default useVideoPlayer;
