import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { Home, Welcome } from '../../components/Navbar/Data';
import Navbar from '../../components/Navbar';
import MusicVideos from '../../components/ViewAllContent/MusicVideos';

const MusicVideosPage = () => {
    const navigate  = useNavigate()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}  

    return( 
        <>
              <Sidebar
            isOpen={isOpen}
            toggle={toggle}
            navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
          />
            <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
            <MusicVideos/>
            
        </>
        )
}

export default MusicVideosPage