import React, { useState } from "react";
import {
  ListContainer,
  SongDetailsSection,
  SongDetailsSectionTitle,
} from "../MusicUploadsFormElements";
import { SmallText } from "../../NFTCollectionCreator/NFTCollectionCreatorElements";
import {
  FormInput,
  FormLabel,
  FormWrapper,
} from "../../CustomForm/CustomFormElements";
import Chip from "../../Chip";
import { Theme } from "../../WebsiteThemeElements";
import {
  Table,
  TableRow,
  ContentListContainer,
  DetailsContainer,
  DetailsText,
  Img,
  TableData,
  TableHeading,
  ImageWrapper,
  NumButton,
} from "../../ContentList/ContentListElements";
import { Nav, NavContainer, NavLink } from "../../NavLink/NavLinkElements";
import useMusic from "../../../hooks/useMusic";
import { convertDuration, getSongTitle } from "../../../utils";
import { ARTIST_SEARCH_TYPES } from "../../../utils/Enum";
import { getArtistsNames2 } from "../../../utils";
import { MdExplicit } from "react-icons/md";
import ImageUtils from "../../../utils/ImageUtils";
import { ChipList } from "../../Chip/ChipElements";
import { useModal } from "../../Modal/ModalContext";
import { ModalLoadingScreen } from "../../LoadingScreen";
import OptionModal from "../../OptionModal";
import { PodcastMusicSearchModal } from "../../SearchModal";

const SongSelector = ({ selectedSongs, addSong,addPlaylistSongs, removeSong }) => {
  const { searchSongsByArtist, searchSongsByName, searchPlaylistsByName, getSongs } = useMusic();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const [searchedSongs, setSearchedSongs] = useState([]);
  const [searchedPlaylists, setSearchedPlaylists] = useState([]);
  const [searchType, setSearchType] = useState("Song");
  const [search, setSearch] = useState("");
  const {modalContent, openModal, closeModal} = useModal()
  const [isLoaded, setLoaded] = useState(false);

  const handleInput = (e) => {
    setSearch(e.target.value);
    if (e.target.value?.trim().length > 0) {
      switch (searchType) {
        case "Song":
          setLoaded(false);
          searchSongsByName(e.target.value)
            .then((songs) => {
              if (songs?.length > 0) {
                setSearchedSongs(songs.filter(song => !selectedSongs.some(sSongs => sSongs?.songId === song?.songId)));
              } else {
                return;
              }
            })
            .catch((err) => {
              console.error(err);
              return;
            });
          break;
        case "Playlist":
          setLoaded(false);
          searchPlaylistsByName(e.target.value)
            .then((playlists) => {
               if (playlists?.length > 0) {
                setSearchedPlaylists(playlists?.filter(p => p?.songs?.length > 0));
              } else {
                return;
              }
            })
            .catch((err) => {
              console.error(err);
              return;
            });
          break;
        case "ARTIST":
          searchSongsByArtist(e.target.value)
            .then((songs) => {
              if (songs?.length > 0) {
                setSearchedSongs(songs);
              } else {
                return;
              }
            })
            .catch((err) => {
              console.error(err);
              return;
            });
          break;

        default:
          break;
      }
      return
    }
    setSearchedSongs([])
  };

  const selectSong = (song) => {
        addSong(song)
        setSearchedSongs([])
        setSearch('')
  }

  const displaySearch = () => {
    return (
        <>
        <Table>
          {searchedSongs.map((song, index) => {
            return (
              <TableRow
                key={index}
                onClick={
                    () => {selectSong(song)}
                  }
               
                id={song.songId}>
                <TableData
                  
                >
                  <NumButton
                    
                  >
                    {
                      index + 1
                    }
                  </NumButton>
                </TableData>
                <TableData
                  style={{ width: "10%" }}
                >
                  <ImageWrapper>
                    <Img
                      src={song?.albumArt ? song.albumArt : defaultAlbumArt}
                      alt={song?.songName}
                    />
                  </ImageWrapper>
                </TableData>
                <TableData>
                  <DetailsContainer>
                    <DetailsText>
                      {song?.songId
                        ? getSongTitle(song)?.length > 25
                          ? `${getSongTitle(song)?.substring(0, 25)}...`
                          : getSongTitle(song)
                        : "Song Name"}
                      
                    </DetailsText>
                    <DetailsText
                      style={{ color: Theme({}).secondaryColor }}>
                        {song?.explicit && (
                        <MdExplicit style={{ marginLeft: "1%" }} />
                      )}
                      <small>
                        {(song?.artists || song?.mainArtists) &&
                          getArtistsNames2(
                            song?.artists ? song?.artists : song?.mainArtists,
                            ARTIST_SEARCH_TYPES.NAME
                          )}
                      </small>
                    </DetailsText>
                    {song?.album && (
                    <DetailsText style={{ justifyContent: "flex-start", cursor: 'pointer' }}>
                      <small>from {song?.album?.albumName}</small>
                    </DetailsText>
                  )}
                  </DetailsContainer>
                </TableData>
                <TableData>
                  
  
                  {song?.duration && (
                    <DetailsText>
                      <small>{convertDuration(song?.duration)}</small>
                    </DetailsText>
                  )}
                </TableData>
                <TableData>
                  <div>
                    
                  </div>
                </TableData>
                <TableData>
                  
                </TableData>
              </TableRow>
            );
          })}
          </Table>
          </>
)
  };

  const selectPlaylist = async (playlist) => {
    setSearchedPlaylists([])
    setSearch('')
    openModal()
    modalContent(<OptionModal><ModalLoadingScreen text={"Adding Songs, Please Wait"} /></OptionModal>)
    let songs = await getSongs(playlist?.songs)
    if(songs?.length >0){
   await addPlaylistSongs(songs.filter(s => !selectedSongs.some(song => song?.songId === s?.songId)))
    closeModal()
    modalContent()
  }
  return
  }

  const displayPlaylistSearch = () => {
    return (
        <>
        <Table>
          {searchedPlaylists.map((playlist, index) => {
            return (
              <TableRow
                key={index}
                onClick={
                    () => {selectPlaylist(playlist)}
                  }
               
                id={playlist.playlistId}>
                <TableData
                  
                >
                  <NumButton
                    
                  >
                    {
                      index + 1
                    }
                  </NumButton>
                </TableData>
                <TableData
                  style={{ width: "10%" }}
                >
                  <ImageWrapper>
                    <Img
                      src={playlist?.playlistArt ? playlist.playlistArt : defaultAlbumArt}
                      alt={playlist?.playlistName}
                    />
                  </ImageWrapper>
                </TableData>
                <TableData>
                  <DetailsContainer>
                    <DetailsText>
                      {playlist?.playlistId
                        ? playlist?.playlistName
                        : "Playlist Name"}
                      
                    </DetailsText>
                    <DetailsText
                      style={{ color: Theme({}).secondaryColor }}>
                        {playlist?.explicit && (
                        <MdExplicit style={{ marginLeft: "1%" }} />
                      )}
                      <small>
                        {playlist?.curatorId}
                      </small>
                    </DetailsText>
                    {playlist?.collaborators && (
                    <DetailsText style={{ justifyContent: "flex-start", cursor: 'pointer' }}>
                      <small>and {playlist?.collaborators?.length} other(s)</small>
                    </DetailsText>
                  )}
                  </DetailsContainer>
                </TableData>
                <TableData>
                  
  
                  {playlist?.songs?.length > 0 && (
                    <DetailsText>
                      <small>{playlist?.songs?.length} Songs</small>
                    </DetailsText>
                  )}
                </TableData>
                <TableData>
                  <div>
                    
                  </div>
                </TableData>
                <TableData>
                  
                </TableData>
              </TableRow>
            );
          })}
          </Table>
          </>
)
  };

  return (
    <SongDetailsSection>
      <SongDetailsSectionTitle>Featured Music</SongDetailsSectionTitle>
     
      <FormWrapper>
        <FormLabel>Search {searchType}</FormLabel>
        {selectedSongs?.length > 0 && <ChipList>{ selectedSongs.map((song, index) => {
            return(
                <Chip image={song?.albumArt ? song?.albumArt : defaultAlbumArt} background={'transparent'} label={song?.songName} closeButton onDelete={() => removeSong(index)}/>
            )
        })}</ChipList>}
        <div style={{ marginBottom: "2%", width: "100%" }}>
        <Nav >
            <NavContainer>
              <NavLink
                active={searchType === "Song"}
                onClick={() => {
                    if(searchType !== "Song"){
                        setSearchType("Song");
                  setSearch('')
                  setSearchedSongs([]);
                    }
                  
                }}
              >
                Search for Song
              </NavLink>
              <NavLink
                active={searchType === "Playlist"}
                onClick={() => {
                    if(searchType !== "Playlist"){
                       setSearchType("Playlist");
                        setSearch('')
                        setSearchedSongs([]); 
                    }
                  
                }}
              >
               Search for Playlist
              </NavLink>
            </NavContainer>
          </Nav>
          </div>
          {(
            <FormInput
              onChange={handleInput}
              onClick={() => {
                openModal()

                modalContent(<PodcastMusicSearchModal searchType={searchType} onClick={ searchType === 'Song' ? selectSong : selectPlaylist} selectedSongs={selectedSongs} />)
              
              }}
              readOnly
              value={search}
              placeholder={`Search for ${searchType === 'Song' ? 'song' : 'playlist with songs'} featured in episode`}
            />
          )}
                <SmallText>
        Select the music from the platform that is included within the podcast, from the platform.
        Artists will be paid out. Add songs individually or add songs from a playlist
      </SmallText>
        <SmallText error>
        An increment of 1% is assigned to each song - This is automatically deducted from the main podcasters royalty payout percentage
      </SmallText>

          {search?.trim()?.length > 0 && searchType === 'Song' && searchedSongs?.length > 0 && displaySearch()}
          {search?.trim()?.length > 0 && searchType === 'Playlist' && searchedPlaylists?.length > 0 && displayPlaylistSearch()}
        
      </FormWrapper>
    </SongDetailsSection>
  );
};



export default SongSelector;
