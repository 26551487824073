import React, { useContext, useEffect, useState } from "react";
import { BsCollectionPlayFill, BsDisc, BsDiscFill, BsListCheck, BsUiChecksGrid } from "react-icons/bs";
import { animateScroll as scroll } from "react-scroll";
import { FaAddressCard, FaBars, FaBell, FaFileDownload, FaFileUpload, FaHandshake, FaHome, FaInfo, FaSearch, FaSignInAlt, FaSignOutAlt, FaStore, FaTh, FaTimes } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import {
  Container,
  Main,
  MainWrapper,
  NavImg,
  SidebarBtnLink,
  SidebarContainer,
  SidebarLink,
  SideBarLinkIcon,
  SideBarLinkText,
  SidebarLogo,
  SidebarMenu,
  SideBarProfileIcon,
  SidebarTopSection,
  SidebarWrapper,
} from "./SidebarNavElements";
import whitepaper from '../../files/Sonar_Muse_Whitepaper.pdf'
import ImageUtils from "../../utils/ImageUtils";
import { Fade } from "react-reveal";
import { useAuth } from "../../contexts/AuthContext";
import MenuOptions, { MusicUploadModal, NftokenCollectionModal, NotificationModal, PlaylistModal, ProfileModal, MiscellaneousOptions, BetaTesterSignInModal } from "../MenuOptions";
import { useNavigate } from "react-router-dom";
import { MdFlashlightOff, MdFlashlightOn, MdOutlineAlternateEmail, MdOutlineMiscellaneousServices } from "react-icons/md";
import { GiWhiteBook } from "react-icons/gi";
import { RiPlayListFill } from "react-icons/ri";
import { downloadFile, getObjectLength } from "../../utils";
import { NavigationButtons } from "../NavigationButtons";
import BadgeIcon from "../BadgeIcon";
import { useUser } from "../../hooks/useUser";
import { useNotifications } from "../Notifications/NotificationContext";
import ToolTip from "../ToolTip";
import ToggleButton from "../ToggleButton";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";
import { TbBulbFilled, TbBulbOff } from "react-icons/tb";
import { useModal } from "../Modal/ModalContext";
import { useAudio } from "../AudioPlayer/context/AudioContext";

export const MEDIA_SCREEN = { //Screen sizes for devices
  MOBILE: '480',
  TABLETS: '768',
  LAPTOPS: '1024',
  DESKTOPS: '1025',
  TV: '1500'
}

export const SidebarContext = React.createContext();

export function useSidebar() {
  return useContext(SidebarContext);
}

const SidebarNav = ({ isOpenVal, children }) => {
  const {toggleTheme, theme } = useWebsiteTheme()
  const [scrollNav, setScrollNav] = useState(false);
  const {stopMainPlayer} = useAudio()
  const { invertedLogo, invertedLogo2, logo, logo2 } = ImageUtils(); 
  const { currentUser, logout} = useAuth();
  const [currentTab, setTab] = useState('home')
  const {notifications} = useNotifications()
  const [isOpen, setOpen] = useState(isOpenVal ? isOpenVal : false);
  const {openModal, closeModal, modalContent} = useModal()
  const navigate  = useNavigate()
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    return () => {}
  }, []);



  const toggleHome = () => {
    navigate( '/')
    setOpen(false)
    setTab('home')
    scroll.scrollToTop();
  };
  
  const menuItems = () => {
    let items = [
      {
        path: "/store",
        name: "Music Store",
        icon: <FaStore />,
      },
      /* {
        path: "/about",
        name: "About",
        icon: <FaInfo />,
      }, */
      {
        path: "#whitepaper",
        name: "Whitepaper",
        icon: <GiWhiteBook />,
      },
      {
        path: "/TDRequirements",
        name: "Token Distribution Requirements",
        icon: <BsListCheck />,
      },
      {
        path: "/eligibilityChecker",
        name: "Eligibility Checker",
        icon: <BsUiChecksGrid />,
      },
      {
        path: "/createTL",
        name: "Add Supported Trustlines",
        icon: <FaHandshake />,
      },
      {
        path: "/setEmailHash",
        name: "Account Set Email Hash",
        icon: <MdOutlineAlternateEmail />,
      },
      {
        path: "/musicDownloader",
        name: "Music Downloader",
        icon: <FaFileDownload />,
      },
      {
        path: "/access/signup",
        name: "Sign Up",
        icon: <FaAddressCard/>
      }
    ];

   

    if (currentUser?.uid) {
      items = [{
        path: `/home`,
        name: "Home",
        icon: <FaHome />,
      },{
        onClick: () => {openModal()
          modalContent(<NotificationModal/>)},
        name: "Notifications",
        icon: <BadgeIcon onClick={() => {openModal()
          modalContent(<NotificationModal/>)}} isOpen={isOpen} icon={(notifications && notifications?.filter(n => !n?.read)?.length > 0 && !notifications?.message) && (notifications?.filter(n => !n?.read)?.length)}><FaBell/></BadgeIcon>,
      },{
        onClick: () =>{
          openModal();
      
          return modalContent(<ProfileModal />);
        },
        name: "Profile",
        icon: <SideBarProfileIcon isOpen={isOpen} src={currentUser?.photoURL} alt={'Profile'}/>,
      },{
        path: "/search",
        name: "Discover",
        icon: <FaSearch />,
      },
      {
        path: "/store",
        name: "Music Store",
        icon: <FaStore />,
      },{
        onClick: () => {
          openModal();
          modalContent(
            <>
            <PlaylistModal />
            </>
          );
        },
        name: 'My Playlists',
        icon: <RiPlayListFill/>
      },
      {
        onClick: () => {
          openModal();
          modalContent(
            <>
            <MiscellaneousOptions />
            </>
          );
        },
        name: "More",
        icon: <MdOutlineMiscellaneousServices />,
      },
      {
        onClick: () => { 
          stopMainPlayer()
          logout()
          

        },
        name: 'Sign Out',
        icon: <FaSignOutAlt/>
      }
    ]
    }

    if(currentUser?.uid && window.location.href.includes('/edit')){
      items = [{}]
    }
    
    return items
  };

  const value = {
    isOpen,
    setOpen,
  };

  return (
    <>
      <SidebarContext.Provider value={value}>
          <Container isOpen={isOpen}>
            <SidebarContainer isOpen={isOpen}>
              <SidebarWrapper isOpen={isOpen} scrollNav={scrollNav}>
                <SidebarTopSection>
                  <Fade bottom>
                    <SidebarLogo
                      onClick={() => (currentUser?.uid && window.location.pathname.includes('/edit')) ? {} : toggleHome()}
                    >
                <NavImg isOpen={isOpen} src={theme === 'dark' ? logo : invertedLogo } alt='Sonar' />
                <NavImg isOpen={isOpen} src={theme === 'dark' ? logo2 : invertedLogo2} alt=' Muse'/>

                    </SidebarLogo>
                  </Fade>
                  <Fade bottom>{isOpen && <div style={{cursor: 'pointer'}}><FaTimes onClick={() => setOpen(false)} size={25}/></div>}</Fade>
                  {!isOpen && (
                    <SideBarLinkIcon isOpen={isOpen} onClick={() => setOpen(true)}>
                      <FaBars />
                    </SideBarLinkIcon>
                  )}
                </SidebarTopSection>
                <SidebarMenu>
                <ToolTip text={`Theme: ${theme === 'dark' ? 'Dark' : 'Light'}`}><ToggleButton customOffIcon={<TbBulbFilled/>} customOnIcon={<TbBulbOff/>}  toggleClick={toggleTheme} toggle={theme === 'dark'}/></ToolTip>
                  {menuItems().map((item, index) => {
                    if(item.name === 'Whitepaper'){return (
                      <ToolTip text={item.name}><SidebarLink
                        
                        onClick={() => {
                          setOpen(false)
                          downloadFile(whitepaper)
                        }}
                        to={item?.path ? item.path : `#${item.name}`}
                      >
                        <SideBarLinkIcon isOpen={isOpen}>{item.icon}</SideBarLinkIcon>
                        <SideBarLinkText isOpen={isOpen}>
                          {item.name}
                        </SideBarLinkText>
                      </SidebarLink></ToolTip>
                    );}
                    else{
                    return (
                      <ToolTip text={item.name}><SidebarLink 
                      isActive={
                        item?.path ? window.location.pathname.includes(item.path) : window.location.pathname.includes(`#${item?.name?.split(' ').join('%20')}`) 
                      }
                        onClick={() => {
                          setTab(item.name)
                          setOpen(false)
                          item?.onClick && item.onClick()
                          item?.path && scroll.scrollToTop()
                        }}
                        to={item?.path ? item.path : `#${item.name}`}
                      >
                        <SideBarLinkIcon isOpen={isOpen}>{item.icon}</SideBarLinkIcon>
                        <SideBarLinkText isOpen={isOpen}>
                          {item.name}
                        </SideBarLinkText>
                      </SidebarLink></ToolTip>
                    );}
                  })}
                  <br/>
                  {!currentUser?.uid &&
                  <SidebarBtnLink onClick={() => {
                    openModal()
                    modalContent(<BetaTesterSignInModal/>)
                  }} isOpen={isOpen} to={'#'}>
                    {!isOpen && <SideBarLinkIcon isOpen={isOpen}>
                      <FaSignInAlt/>
                    </SideBarLinkIcon> }
                    <SideBarLinkText isOpen={isOpen}>Sign In</SideBarLinkText>
                  </SidebarBtnLink>
                 }
                </SidebarMenu>
              </SidebarWrapper>
            </SidebarContainer>
            
            <Main isOpen={isOpen}><NavigationButtons/>{children}</Main>
          </Container>
      </SidebarContext.Provider>
    </>
  );
};

export default SidebarNav;
