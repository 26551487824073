import axios from 'axios'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../components/Authorization/client'

const useNetwork = () => {
    const [state, setState] = useState({
        since: undefined,
        online: navigator?.onLine
    })

    const handleOnline = async() => {
        setState( prev => ({
            ...prev,
            online: true,
            since: new Date().toString()
        }))
        
    }

    const handleOffline = () => {
        setState( prev => ({
            ...prev,
            online: false,
            since: new Date().toString()
        }))
    }

    const ping = async () => {
        return await axios.get("https://example.com", {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }    
        }).then(response => {
            console.log(response)
            if(response?.status === '200'){
                setState( prev => ({
                    ...prev,
                    online: true,
                    since: new Date().toString()
                }))
                return
            }
            setState( prev => ({
                ...prev,
                online: false,
                since: new Date().toString()
            }))
            return
        }).catch(err => {
            setState( prev => ({
                ...prev,
                online: false,
                since: new Date().toString()
            }))
            return
        })
    }

    useEffect(() => {
        window.addEventListener('online', handleOnline)
        window.addEventListener('offline', handleOffline)
    /* const interval = setInterval(() => {
        ping()
    }, 25000);
     */
    
      return () => {
        window.removeEventListener('online', handleOnline)
        window.removeEventListener('offline', handleOffline)
        //clearInterval(interval);
      }
    }, [])
    

  return {state}
}

export default useNetwork