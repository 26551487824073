export const Data = 
[{
    id: "ARR",
    name: "All Rights Reserved",
    icons: ["copyright"],
    about: "This license indicates that the copyright holder reserves, or holds for its own use, all the rights provided by copyright law."

},
{
    id: "CC0",
    name: "No Copyright (CC0): Public Domain Dedication",
    icons: ["creativeCommons", "zero"],
    about: "A public dedication tool, which allows creators to give up their copyright and put their works into the worldwide public domain. CC0 allows reusers to distribute, remix, adapt, and build upon the material in any medium or format, with no conditions.",
    link: "https://creativecommons.org/publicdomain/zero/1.0/"
},
{
    id: "CCBY",
    name: "Attribution",
    icons: ["attribute"],
    about: "This license allows reusers to distribute, remix, adapt, and build upon the material in any medium or format, so long as attribution is given to the creator. The license allows for commercial use.",
    link: "https://creativecommons.org/licenses/by/4.0/"
},
{
    id: "CCBYNC",
    name: "Attribution: Non-commercial",
    icons: ["attribute", "nonCommercial"],
    about: "This license allows reusers to distribute, remix, adapt, and build upon the material in any medium or format for noncommercial purposes only, and only so long as attribution is given to the creator. ",
    link: "https://creativecommons.org/licenses/by-nc/4.0/"
},
{
    id: "CCBYSA",
    name: "Attribution: Share Alike",
    icons: ["attribute", "shareAlike"],
    about: "This license allows reusers to distribute, remix, adapt, and build upon the material in any medium or format, so long as attribution is given to the creator. The license allows for commercial use. If you remix, adapt, or build upon the material, you must license the modified material under identical terms.",
    link: "https://creativecommons.org/licenses/by-sa/4.0/"
},
{
    id: "CCBYNCSA",
    name: "Attribution: Non-commercial Share Alike",
    icons: ["attribute", "nonCommercial", "shareAlike"],
    about: "This license allows reusers to distribute, remix, adapt, and build upon the material in any medium or format for noncommercial purposes only, and only so long as attribution is given to the creator. If you remix, adapt, or build upon the material, you must license the modified material under identical terms.",
    link: "https://creativecommons.org/licenses/by-nc-sa/4.0/"
},
{
    id: "CCBYND",
    name: "Attribution: No Derivatives",
    icons: ["attribute", "nonDerivatives"],
    about: "This license allows reusers to copy and distribute the material in any medium or format in unadapted form only, and only so long as attribution is given to the creator. The license allows for commercial use.",
    link: "https://creativecommons.org/licenses/by-nd/4.0/"
},
{
    id: "CCBYNCND",
    name: "Attribution: Non-commercial No Derivatives",
    icons: ["attribute", "nonCommercial", "nonDerivatives"],
    about: "This license allows reusers to copy and distribute the material in any medium or format in unadapted form only, for noncommercial purposes only, and only so long as attribution is given to the creator.",
    link: "https://creativecommons.org/licenses/by-nc-nd/4.0/"
}
]