import styled from "styled-components";
import { hexToRgba } from "../../HexToRGBA";
import { Link } from "react-router-dom";

export const CardContainer = styled.div`
display: flex;
flex-direction: column;
padding: 1%;
white-space: normal;
border-radius: 0.5rem;
margin: 2%;
width: 100%;
height: 100%;
max-width: 300px;
align-content: center;
align-items: left;
justify-content: left;
background: ${props => hexToRgba(props.theme.accentColor, 0)};

&: hover{
    background: ${props => props.background? hexToRgba(props.background, 0.2): hexToRgba(props.theme.accentColor, 0.2)};
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
    border: solid ${props => hexToRgba(props.theme?.secondaryBg, 0.7)} 1px;
   }
`

export const CardContent = styled.div`
    display: flex;
    font-size: 0.8rem;
    flex-direction: column;
    padding: 6%;
    justify-content: left;
    align-content: center;
`

export const CardTitle = styled.h4`
    display: flex;
    font-size: 1rem;
    margin-bottom: 1px;
    white-space: normal;
    text-align: left;
    word-wrap: break-word;
    color: ${props => props.theme.secondaryColor};
    
`

export const TextLink = styled(Link)`
   text-decoration: none;
   cursor: pointer;
   color: ${props => props.theme.secondaryBg};
   &: hover{
    color: ${props => props.theme.secondaryColor};
    text-decoration: underline;

   }
 
`
export const CreatorInfoWrapper = styled.div`
    display: flex;
    cursor: pointer;
    gap: 0.7rem;
    align-items: center;
    margin-bottom: 3%;
    justify-content: space-evenly;
`

export const CreatorInfoContent = styled.div`
    display: flex;
    flex-direction: column;
    
`

export const CreatorInfo = styled.div`
    display: flex;
    font-size: 0.9rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    width: 100%;
`

export const H6 = styled.h6`
    font-size: 0.8rem;
    color: ${props => hexToRgba(props.theme.accentColor, 1)};
`

export const InfoContentText = styled.div`
    display: flex;
    white-space: normal;
    color: ${props => props.theme.secondaryBg};
    word-wrap: break-word;
    font-size: 0.8rem;
    &: hover{
    color: ${props => props.theme.secondaryColor};
    text-decoration: underline;

   }
`