import { collection, limit, onSnapshot, query, where } from "firebase/firestore"
import { db } from "../../components/Authorization/firebase"
import { CONTENT_TYPES } from "../Enum";

export const getDownloadInfo = async (uid, type, typeId, setDownloadInfo) => {
    let downloadQuery 
    try{
    switch (type) {
        case CONTENT_TYPES.ALBUM:
            downloadQuery = query(collection(db, "downloads"), where("type", "==", type), where("albumId", "==", typeId), limit(1))
            break;
        case CONTENT_TYPES.SONG:
            downloadQuery = query(collection(db, "downloads"), where("type", "==", type), where("songId", "==", typeId), limit(1))
            break;
        case CONTENT_TYPES.NFTOKEN:
            downloadQuery = query(collection(db, "downloads"), where("type", "==", type), where("tokenId", "==", typeId), limit(1))
            break;
    
        default:
            break;
    }
    return onSnapshot(downloadQuery, downloadSnapshot => {
      if(!downloadSnapshot.empty){
      setDownloadInfo(
        {
          downloadId : downloadSnapshot.docs[0].id,
          ...downloadSnapshot.docs[0].data()
        })
      }else{
        setDownloadInfo({})
      }
      return
  }, err => {
    return setDownloadInfo({})
  })}catch(err){
    return setDownloadInfo({})
  }
  }