import { collection, doc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../components/Authorization/firebase";

export const getSignTxStatus = (invoice, status, setStatus, setResponse) => {
    return onSnapshot(doc(db,`signTx/${invoice}`), async (collectionSnapshot) => {
       if(collectionSnapshot?.exists){
        setStatus(collectionSnapshot.data()?.status)
        if(collectionSnapshot.data()?.response){
            setResponse(collectionSnapshot.data()?.response)
        }
        return
       }
       return
})
}