import React, {useEffect, useState} from 'react'
import axios from '../Authorization/client'
import WebSocket from "react-websocket";

import { ModalLoadingScreen } from '../LoadingScreen/index'
import Navbar from '../Navbar'
import { Access } from '../Navbar/Data'
import { 
  Container, 
  ContentWrapper, 
  Form,
  FormButton,
  FormContent, 
  FormH1, 
  FormWrap, 
  QrCode, 
  QrWrapper,  
  Text, 
  TextLink,
  VideoBg, } from '../SignUp/SignUpElements'
import { VideoUtils } from '../../utils/ImageUtils';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ALERT_TYPES } from '../../utils/Enum';
import { useAlert } from '../Alert/AlertContext';
import ScrollToTop from '../ScrollToTop';
import { getObjectLength } from '../../utils';


const SetNFTokenMinter = () => {
  const {video1} = VideoUtils()
  const {currentUser} = useAuth()
  const { addAlert } = useAlert();
  const navigate  = useNavigate()
  const {goTo} = ScrollToTop()
    const [errMsg, setError] = useState('')
    const [isLoaded, setLoaded] = useState(false)
    const [isOpened, setOpened] = useState(false)
    const [payload, setPayload] = useState()
    const [isSigned, setSigned] = useState(false)


    useEffect(() => {
      if(!currentUser?.uid && !currentUser?.isArtist){
        navigate( '/')
      }
      return () => {}
    }, [])
    
  
    const SignIn = async () => {
        axios.post(`/api/${currentUser?.uid}/setNFTMinter`, {userToken: localStorage.getItem('userToken')}).then(res => {
            setPayload(res.data.payload)
          setLoaded(true)
        }).catch(err => {
          //console.log(err)
          setError('Oops! Something wrong happened. Reload the page')
          setLoaded()
          
        })
      };

    const updateSignInStatus = async (messageData) => {
        //console.log('Message Data: ', messageData)
        const data = JSON.parse(messageData);
        if (data.opened === true) {
          setOpened(true);
          setError("Wating for you to sign the transaction");
        }
        if (isOpened === true && data.signed === true) {
        setLoaded(false);
        setSigned(true)
          setPayload();
          goTo('/home')
          addAlert({
            title: "NFT Minter",
            type: ALERT_TYPES.SUCCESS,
            message: `Sonar Muse has successfully been set as your NFT Minter.`,
          });
          setError(
            "Sonar Muse has successfully been set as your NFT Minter."
          ); 
          setOpened(false)        //console.log("Signed")
        } else if (isOpened === true && data?.signed === false) {
          setLoaded(false);
          setPayload();
          addAlert({
            title: "NFT Minter",
            type: ALERT_TYPES.DANGER,
            message: `The transaction was rejected. Reload the page and try again`,
          });
          setError(
            "The transaction was rejected. Reload the page and try again"
          );
          setOpened(false) 
        }
        if(data?.signed === false) {
          setLoaded(false);
          setPayload();
          addAlert({
            title: "NFT Minter",
            type: ALERT_TYPES.DANGER,
            message: `The transaction was rejected. Reload the page and try again`,
          });
          setError(
            "The transaction was rejected. Reload the page and try again"
          );
          setOpened(false) 
        }
      };

const refreshPage = ()=>{
  setPayload()
  setError('')
  setOpened(false)
  setLoaded(false)
  SignIn()

}

  useEffect(() => {
    SignIn()
    return () => {}
  }, [])
    
        //console.log("Websocket: ",payload.refs.websocket_status)
        return (
          <>
            <Navbar navInfo={Access} />

              <Container>
              <VideoBg autoPlay loop muted src={video1}
                    type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}>
                </VideoBg>
                <ContentWrapper>
               {isLoaded === true && getObjectLength(payload) > 0 && 
               <><WebSocket
                url={payload.refs.websocket_status}
                onMessage={(data) => updateSignInStatus(data)}
              />
                <FormWrap>
                  <FormContent>
                    <Form action={(e) => e.preventDefault}>
                      <FormH1> Set NFT Minter </FormH1>
                      <QrWrapper>
                        <QrCode src={payload.refs.qr_png} />
                      </QrWrapper>
                      {isOpened === false && (
                        <Text>
                          Scan QR code or click {" "}
                          <TextLink href={payload.next.always} target="_blank" rel="noopener noreferrer">here</TextLink> to go
                          to your Xaman wallet
                        </Text>
                      )}
                      {isOpened === true && (
                        <Text>
                          <Text>{errMsg}</Text>
                        </Text>
                      )}
                    <FormButton transparent={false} to='/setNFTMinter' onClick={refreshPage}>Reload</FormButton>
                    </Form>
                  </FormContent>
                </FormWrap>
                </>
                }{errMsg && 
              
                <FormWrap>
                  <FormContent>
                    <Form>
                      <Text>{errMsg}</Text>
                      {!isSigned && <FormButton transparent={true} to='/setNFTMinter' onClick={refreshPage}>Reload</FormButton>}
                      {isSigned && <FormButton transparent={false} to='/'>Go Home</FormButton> }
                    </Form>
                  </FormContent>
                </FormWrap>
            }
            {!errMsg && !isLoaded && <ModalLoadingScreen text={"Loading. Please Wait..."} isLoaded={isLoaded} transparent={true} />}
              </ContentWrapper>
              </Container>
              
          </>
        
           
          
        )
}

export default SetNFTokenMinter