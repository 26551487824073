import React, { useState } from 'react'
import MusicUploadsForm from '../../components/MusicUploadsForm';
import Navbar from '../../components/Navbar';
import { Access, Home } from '../../components/Navbar/Data';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';


function AlbumUploads() {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}    

  return (
    <>
        <Sidebar
            isOpen={isOpen}
            toggle={toggle}
            navInfo={Access}
          />
            <Navbar toggle={toggle} navInfo={Home(localStorage.getItem("accInfo"))}/>
            <MusicUploadsForm type={'album'}/>
            

    </>
  )
}

export default AlbumUploads