import React, { useState } from 'react'
import { getSongTitle } from '../../utils';
import AlbumArt from '../AlbumArt';
import ContentWrapper from '../ContentWrapper';
import { FormText, FormWrapper } from '../CustomForm/CustomFormElements';
import { Header } from '../Header'
import { HoverOption } from '../MenuOptions/MenuOptionsElements';
import { AUDIO_EXPORT_TYPES, CONTENT_TYPES } from '../../utils/Enum';

const SongDownload = ({song, download}) => {
    return (
        <>
          <Header
            title={
              `${getSongTitle(song)}`
            }
          />
          <ContentWrapper subheading={"Download Song"}>
            
            <FormWrapper><FormText>
              {`Download ${getSongTitle(song)}`}
            </FormText>
              <div style={{cursor: "pointer"}}><AlbumArt
                size={"25rem"}
                albumArt={song?.albumArt}
                alt={song?.songName}
                
              /></div>
              <HoverOption onClick={() => download(song, CONTENT_TYPES.SONG, song?.songId, AUDIO_EXPORT_TYPES.MP3 )}>Download Song</HoverOption>
            </FormWrapper>
          </ContentWrapper>
        </>
      );
}

export default SongDownload