import React, { useCallback, useRef, useState } from 'react'
import ContentWrapper from '../ContentWrapper'
import { Slider } from '../Slider'
import { SLIDER_TYPES } from '../../utils/Enum'
import { ModalLoadingScreen } from '../LoadingScreen'
import Modal from '../Modal'
import { useSimilarSongsSearch } from '../../hooks/useSearch'

const SimilarSongs = ({songId}) => {
    const [pageNumber, setPageNumber] = useState(0)

    const {loading, error, songs, pages, setPages} = useSimilarSongsSearch({pageNumber: pageNumber, songId: songId})
  
    const observer = useRef()
    const lastSongElementRef = useCallback( node => {
      if(loading){
        return
      }
      if(observer.current){ 
        observer.current.disconnect()}
        observer.current = new IntersectionObserver(entries=> {
          if(entries[0].isIntersecting && (pageNumber < parseInt(pages/10))){
                setPageNumber(prev => prev + 1)
  
          }
      }) 
      
      if(node) observer.current.observe(node)
     
    }, [loading, pages, pageNumber])
      
      
    return (
      <>
      <ContentWrapper heading={"Similar Songs"}>
      {
        !loading && songs?.length > 0 && (
          
            <Slider
              type={SLIDER_TYPES.SONG}
              heightScroll
              slides={songs}
              cardRef={lastSongElementRef}
              id={"similar songs"}
            />
         
        )
        /*         <PageIndexer/>
         */
      }
      {
        loading && songs?.length > 0 && (
          <>
            <Slider
              type={SLIDER_TYPES.SONG}
              heightScroll
              slides={songs}
              id={"similar songs"}
            />
        <Modal isOpen={loading}><ModalLoadingScreen text={'Loading. Please Wait'}/></Modal>
  </>
        )
        /*         <PageIndexer/>
         */
      }
      {loading && songs?.length === 0 && (
        <>
             <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        </>
      )}
      {!loading && songs?.length === 0 && <ContentWrapper heading={"No Songs Found"}>
          
          </ContentWrapper>}
          </ContentWrapper>
    </>
    )
}

export default SimilarSongs