import { Link } from "react-router-dom";
import styled from "styled-components";
import {hexToRgba} from "../HexToRGBA";
import Typical from 'react-typical';

export const ProfileDiv = styled.div`
  min-height: 100vh;
  background: ${props => props?.theme?.background ? props?.theme?.background : 'black'};
  /*background: ${(props) => (props.background ? `linear-gradient(180deg, ${hexToRgba(props.background,0.6)} 0%, rgba(84, 120, 154, 0.6232) 100%)` : props.theme.mainBg)};*/
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  filter: drop-shadow(1px 2px 2px rgba(15,15,15,0.76));
  
`;

export const ProfileQRCode = styled.img`
  display:flex;
  align-content: center;
  justify-content: center;
  justify-self: center;
  align-self: center;
  border-radius: 15%;
  border: 2px solid ${props => props.theme.accentColor};
  width: 60%;
  @media screen and (max-width: 480px) {
    width: 80%;
  }

`

export const ProfileWrapper = styled.div`
min-height: 100vh;
background: ${props => hexToRgba(props.theme.mainBg, 0.6)};
border-radius: 50px 50px 0px 0px;
backdrop-filter: blur(12px);
-webkit-backdrop-filter: blur(12px);
flex: none;
margin-top: 25vh;
margin-left: 2%;
margin-right: 2%;
padding-bottom: 5%;
z-index:2;
`;


export const ProfileContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.mainBg};
  justify-content: space-between;
  padding: 1% 1%;
  border-radius: 25px;
  filter: ${props => props.background ? `drop-shadow(4px 4px 4px ${props.background})` :'drop-shadow(0px 4px 4px rgba(179, 111, 247, 0.76))'};
  margin-right: 1%;
  margin-bottom: 2%;
`;

export const ProfileInfoText = styled.text`
display: flex;
color: ${(props) => props.theme.secondaryBg};
`;

export const ProfileAboutText = styled(Typical)`
display: flex;
padding-left: 3%;
font-size: 1rem;
color: ${(props) => props.theme.secondaryBg};
`;

export const Br = styled.p`
margin: 1.5% 2%;
color: white;
border: 1px solid rgba(216, 191, 191, 0.33);

`