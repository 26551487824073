import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { Home, Welcome } from '../../components/Navbar/Data';
import { useNavigate, useParams } from 'react-router-dom';
import UserCollabPlaylists from '../../components/ViewAllContent/UserCollabPlaylists';

const UserCollabPlaylistsPage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}  
    const {userId} = useParams()
    const navigate  = useNavigate()

    useEffect(() => {
        if(!userId)
        {
            navigate(-1)
        }
        return () => {}
    }, [userId])
  
    return( 
        <>
              <Sidebar
            isOpen={isOpen}
            toggle={toggle}
            navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
          />
            <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
            <UserCollabPlaylists userId= {userId}/>
            
        </>
        )
}

export default UserCollabPlaylistsPage