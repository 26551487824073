import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../components/Footer';
import MusicPage from '../../components/MusicPage';
import Navbar from '../../components/Navbar';
import { Home, Welcome } from '../../components/Navbar/Data';
import Sidebar from '../../components/Sidebar';

const NFToken = () => {
    const [nftokenId, setNFTokenId] = useState()
    const {tokenId} = useParams()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}
    const navigate  = useNavigate()
    
    useEffect(() => {
        if(!tokenId)
          {
            navigate(-1)
          }
        setNFTokenId(tokenId)
        return () => {}
    }, [tokenId])
  
  
  
    return( 
        <>
            <Sidebar
          isOpen={isOpen}
          toggle={toggle}
          navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
        />
          <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
            <MusicPage id={tokenId} type={'nftoken'}/>
            
        </>
        )
  }

export default NFToken