import React, { useContext, useEffect, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext';
import { useUser } from '../../hooks/useUser';
import { getObjectLength } from '../../utils';
import { ALERT_TYPES, NOTIFICATION_TYPES } from '../../utils/Enum';
import { useAlert } from '../Alert/AlertContext';

export const NotificationContext = React.createContext();

export function useNotifications() {
  return useContext(NotificationContext);

}
const NotificationProvider = ({children}) => {
    const {currentUser, isArtist} = useAuth()
    const {markNotificationAsRead, getNotifications, validateNFTokenOffer} = useUser({})
    const [notifications, setNotifications] = useState([])
    const [isLoaded, setLoaded] = useState(false)
    const { addAlert } = useAlert();


    useEffect(() => {
      getUserNotifications().then(data => {
        setLoaded(true)
      }).catch(err => {
        setLoaded(true)
      });
      return () => {}
    }, [!isLoaded]);

    useEffect(() => {
      return () => {}
        /* const interval = setInterval(() => {
          getUserNotifications() 
          
        }, 20000);
        return () => clearInterval(interval); */
      },[])

    
    
      async function getUserNotifications(){
        if(currentUser?.uid){
        return   await getNotifications(currentUser?.uid).then(data => {
          if(data?.length > 0 ){
            let notifs = data.filter(n => n?.type !== NOTIFICATION_TYPES.PENDING_NFTOKEN)
            let pendingNFtokens = data.filter(n => n?.type === NOTIFICATION_TYPES.PENDING_NFTOKEN && !n?.read)
            for(let i = 0; i < pendingNFtokens.length; i++){
                validateNFTokenOffer(pendingNFtokens[i].notificationId).then(data => {
                  if(data !== 'Claimed'){
                      notifs.push(pendingNFtokens[i])
                  }
                })
            }
            
          if((notifs?.filter(n => !n?.read)?.length > 0 )){ 
            document.title = `(${data.filter(n => !n?.read)?.length}) Sonar Muse`
            
          }
          setNotifications(notifs)
          return
          }
          else{
            
            document.title = 'Sonar Muse'
            return
          }
          
        }).catch(err => {
            setNotifications([])
            document.title = 'Sonar Muse'
            console.error(err)
        }) 
    }
        }


     const value ={
        getUserNotifications,
        notifications,
        markNotificationAsRead,
        isArtist
     }   

  return (
    <NotificationContext.Provider value={value}>
    <>{children}</>
    </NotificationContext.Provider>
  )
  }
export default NotificationProvider