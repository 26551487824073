import styled from "styled-components";


export const FileUploaderInput = styled.input`
cursor: pointer;
outline: none;
overflow: hidden;
width:0;
height: 0;
display: none;
left: -9999;
filter: alpha(opacity=0); /* IE < 9 */

` 