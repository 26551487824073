import styled from "styled-components";
import Typical from 'react-typical';
import {Button, CustomButton} from '../../ButtonElement'
import { hexToRgba } from "../../HexToRGBA";
import { Link } from "react-router-dom";
import { ProfileNav, ProfileNavLink } from "../ProfileContent/ProfileContentElements";


export const ProfileDetailsContainer = styled.div`
color: #FFFFFF;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 5px 5px;
margin-left: 2%;
position: relative;
overflow-x: scroll;
scrollbar-width: none;
::-webkit-scrollbar{
    display: none; 
}
`
export const ProfileActionButtonWrapper = styled.div`
display:flex;
flex-direction: row;
padding: 2% 10%;
align-items: center;
justify-content: space-between;
width: 100%;
top: 8%;
`

export const ProfileHeading = styled.div`
display: flex;
margin-top: 1%;
flex-direction: row;
order: 1;
flex-wrap: nowrap;
width: 100%;
`


export const ProfileTitle = styled.h1`
display: flex;
font-size: 1.5rem;
overflow: hidden;
color: #FFFFFF;
flex-wrap: nowrap;
@media screen and (max-width: 960px) {
  font-size: 1.2em;
}
`



export const HeadingTextWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start center;
padding-left: 2%;
flex: 1 1 50%;
overflow: hidden;

`



export const DetailsTextContainer = styled.div`
display: flex;
flex: 1 1 50%;
align-items: center;
justify-content: flex-start;
`


export const AboutText = styled.div`
font-size: 0.85rem;
max-width: 45%;
@media screen and (max-width: 960px) {
  font-size: 0.70em;
}
`



export const AdditionalDetailsContainer = styled.div`
margin-top: 3%;
padding: 0% 5%;
display: flex;
font-size: 0.8rem;
flex-direction: row;
flex-wrap:wrap;
justify-content: space-between;
width: 100%;
order: 2;
`

export const FollowDetailsContainer = styled.div`
display: flex;
padding: 5%;
justify-content: space-between;
wrap: nowrap;
`

export const FollowText = styled.text`
display: flex;
cursor: pointer;
padding: 1% 1%;
wrap: nowrap;
&:hover{
  transform: scale(1.02);
  text-decoration: underline;
  transition: 1s eas-out;
}
@media screen and (max-width: 960px) {
  font-size: 0.8em;
}

`

export const DetailsText = styled.text`
display: flex;

@media screen and (max-width: 960px) {
  font-size: 0.9em;
}
`

export const Small = styled.small`

@media screen and (max-width: 960px) {
  font-size: 0.6em;
  
}
`

export const ProfileImg = styled.img`
display: flex;
margin-left: 1%;
max-width: 8em;
max-height: 8em;
min-width: 8em;
min-height: 8em;
border-radius: 50%;
border: 3px solid ${props => props.background ? props.background : 'white'};
cursor: pointer;
&:hover{
    transform: scale(1.02);
    transition: 1s eas-out;
}
@media screen and (max-width: 960px) {
  width: 6.5em;
  height: 6.5em;
}
`

export const ButtonWrapper = styled.div`
display:flex;
margin-right: 10%;
flex: 1 1 50%;
align-items: center;
justify-content: right;
@media screen and (max-width: 960px) {
  margin-right: 5%;
  flex: 1 1 80%;
}
`

export const FollowButton = styled(CustomButton)`
    display: flex;
    font-size: 0.85em;
    padding: 1% 5%;
    margin: 0px 3%;
    color: ${props => props.theme.secondaryBg };
    justify-content: space-between;
    background: ${(props) => (props.active ? `${hexToRgba(props.background, 0.5)}` : 'transparent')};
    text-decoration: none;
    &:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.1);
        color: ${(props) => (props.active ? props.theme.mainColor : props.theme.secondaryBg )};
    }
    @media screen and (max-width: 960px) {
      font-size: 0.65em;
    }
`

export const VerifiedBadge = styled.img`
display: flex;
position: relative;
width: 1rem;
height: 1rem;
margin-left: 0.5%;
`

export const Form = styled.form`
  display: flex;
  position: absolute;
  backdrop-filter: blur(5px);
  flex-direction: column;
  -webkit-backdrop-filter: blur(5px);
  background: ${(props) => hexToRgba(props.theme.mainBg, 0.6)};
  width: 60vw;
  max-height: 70vh;
  margin: 2% 8%;
 
  padding: 1% 5%;
  border-radius: 25px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
`;

export const FormH1 = styled.h1`
  display: flex;
  margin: 4% 0%;
  justify-content: center;
  color: ${(props) => props.theme.secondaryBg};
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const FormTab = styled(ProfileNav)`
  display: flex,
  justify-content: space-around;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 1% 1%;
  
`


export const FormTabButton = styled(CustomButton)`
    display: flex;
    flex-direction: row;
    height: auto;
    width: auto;
    padding: 2% 2%;
    flex-wrap: nowrap;
    flex: 1 1 100%;
    border-radius: 0%;
    background-color: ${props => props.isActive ? (props.background ? props.background : hexToRgba(props.theme.mainColor, 0.8))  : 'transparent'};
    &:hover {
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      color: ${(props) => props.theme.secondaryBg};
      background-color: ${(props) => (props.isActive ? (props.background ? hexToRgba(props.background, 0.3) : hexToRgba(props.theme.mainColor, 0.3)) : props.theme.secondaryColor )};
  }
`

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100%;
  padding: 2% 2%;
  @media screen and (max-width: 480px) {
    padding: 2% 2%;
	
  }
`;

export const FormContent1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 10% 20%;
  @media screen and (max-width: 480px) {
    padding: 10% 20%;	
  }
`;

export const FormText = styled.span`
  text-align: center;
  color: ${(props) => props.theme.secondaryBg};
  font-size: 14px;
  margin-bottom: 10px;

  @media screen and (max-width: 480px) {
    margin-top: 0;
  }
`;

export const FormButton = styled(Link)`
  background: ${(props) => (props.transparent ? props.theme.mainBG : props.theme.mainColor)};
  padding: 2% 1%;
  border: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 3%;
  color: ${(props) => (props.transparent ? props.theme.secondaryBg : props.theme.secondaryBg)};
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 15px;
  text-decoration: ${(props) => (props.transparent ? 'underline' : 'none')};


  @media screen and (max-width: 480px) {
    margin-top: 0;
    font-size: 14px;
  }
`;