import React, { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext';
import { useUser } from '../../../hooks/useUser';
import ImageUtils from '../../../utils/ImageUtils';
import { VerifiedBadge } from '../../Profile/ProfileDetails/ProfileDetailsElements';
import { Button, Button1, ButtonWrapper, DetailsContainer, DetailsText, ImageWrapper, Img, PlayButton, Table, TableData, TableRow } from '../ContentListElements'
import ScrollToTop from '../../ScrollToTop';
import { FaCrown } from 'react-icons/fa';

const UserList = ({content, onButtonClick}) => {
  const { currentUser, gravatarInfo, getUserLikes } = useAuth();
  const {
    followUser, 
    unfollowUser} = useUser({})
  const {DEFAULT_IMAGE, artistBadge } = ImageUtils();
  const [users, setUsers] = useState(content)
  
  const onFollowClick = (user) => {
    if(user?.uid){
      let newUsersList = users.map((u, i) => {
        if(u.uid === user.uid){
          return {
            ...u,
            isFollowing: true
          }
        }
        return u
      })
    followUser(user.uid).then(data => {
      if(data && !data.error){
        setUsers(newUsersList)
      }
    })
    
  }
  }

  const onUnfollowClick = (user) => {
    
    if(user?.uid){
    let newUsersList = users.map((u, i) => {
      if(u.uid === user.uid){
        return {
          ...u,
          isFollowing: false
        }
      }
      return u
    })
  unfollowUser(user.uid).then(data => {
    if(data && !data.error){
      setUsers(newUsersList)
    }
  })
  
}}

  return (
    <>
      <Table>
      {users.map((user, index) => {
                return (
                  <TableRow
                    key={index}
                    id={user?.uid}
                    to={'#'}
                  >
                    <TableData
                    onClick={ onButtonClick ? () => onButtonClick(user) : {}}                    
                    >
                      
                        
                      
                      <ImageWrapper>
                      <Img
                        isCircular={true}
                        src={
                          user?.gravatarInfo?.avatar_url ? user?.gravatarInfo?.avatar_url : user?.photoURL ? user?.photoURL : DEFAULT_IMAGE(300)
                        }
                        alt={user?.uid ? user?.uid : "user"}
                      /></ImageWrapper>
                    </TableData>
                    <TableData onClick={() => onButtonClick ? onButtonClick(user) : {}}>
                      <DetailsContainer>
                        <DetailsText>{ user?.gravatarInfo?.display_name ? user.gravatarInfo.display_name : `${user?.uid.substring(0, 4)}...${user?.uid.substring(user?.uid.length -5, user?.uid.length)}`}{user.isArtist && <VerifiedBadge src={artistBadge} />}</DetailsText>
                      </DetailsContainer>
                    </TableData>
                    <TableData>
                      {" "}
                      {user?.uid !== currentUser?.uid && user?.isFollowing && (
                        <ButtonWrapper>
                          <small style={{fontSize: '0.7rem'}}><i>Following</i></small>
                        </ButtonWrapper>
                      )}
                    </TableData>
                    <TableData>
                      {" "}
                      {user?.uid !== currentUser?.uid && user?.isFollowing && (
                        <ButtonWrapper>
                          <Button1 onClick={() => onUnfollowClick(user)}>Unfollow</Button1>
                        </ButtonWrapper>
                      ) } {user?.uid !== currentUser?.uid && !user?.isFollowing &&  (<ButtonWrapper>
                        <Button1 onClick={() => onFollowClick(user)}>Follow</Button1>
                      </ButtonWrapper>)}
                    </TableData>
                  </TableRow>
                );
              })}
            </Table>
          </>
  )
}

export const RegularUserList = ({content, onButtonClick, btnText}) => {
  const {DEFAULT_IMAGE, artistBadge } = ImageUtils();
  const [users, setUsers] = useState(content)
  
 

  return (
    <>
      <Table>
      {content.map((user, index) => {
                return (
                  <TableRow
                    key={index}
                    id={user?.uid}
                    to={'#'}
                  >
                    <TableData
                    onClick={ onButtonClick ? () => onButtonClick(index) : {}}                    
                    >
                      
                        
                      
                      <ImageWrapper>
                      <Img
                        isCircular={true}
                        src={
                          user?.gravatarInfo?.avatar_url ? user?.gravatarInfo?.avatar_url : user?.photoURL ? user?.photoURL : DEFAULT_IMAGE(300)
                        }
                        alt={user?.uid ? user?.uid : "user"}
                      /></ImageWrapper>
                    </TableData>
                    <TableData onClick={() => onButtonClick ? onButtonClick(index) : {}}>
                      <DetailsContainer>
                        <DetailsText>{ user?.gravatarInfo?.display_name ? user.gravatarInfo.display_name : `${user?.uid?.substring(0, 4)}...${user?.uid?.substring(user?.uid?.length -5, user?.uid.length)}`}</DetailsText>
                      </DetailsContainer>
                    </TableData>
                  
                    <TableData>
                      {" "}
                      {onButtonClick && <ButtonWrapper>
                          <Button1 onClick={() => onButtonClick(index)}>{btnText}</Button1>
                        </ButtonWrapper>
                       }
                    </TableData>
                  </TableRow>
                );
              })}
            </Table>
          </>
  )
}

export const RoyaltyTokenUserList = ({content}) => {
  const { currentUser } = useAuth();
  const {DEFAULT_IMAGE, artistBadge } = ImageUtils();
  const {goTo} = ScrollToTop()
  

  return (
    <>
      <Table>
      {content.map((user, index) => {
                return (
                  <TableRow
                    key={index}
                    id={user?.uid}
                    to={'#'}
                  >
                    <TableData>
                    <FaCrown color="#a28834"/>
                    </TableData>
                    <TableData
                    onClick={ () => goTo(`/u/${user?.uid}`)}                    
                    >
                      
                        
                      
                      <ImageWrapper>
                      <Img
                        isCircular={true}
                        src={
                          user?.gravatarInfo?.avatar_url ? user?.gravatarInfo?.avatar_url : user?.photoURL ? user?.photoURL : DEFAULT_IMAGE(300)
                        }
                        alt={user?.uid ? user?.uid : "user"}
                      /></ImageWrapper>
                    </TableData>
                    <TableData onClick={ () => goTo(`/u/${user?.uid}`)}>
                      <DetailsContainer>
                        <DetailsText>{ user?.gravatarInfo?.display_name ? user.gravatarInfo.display_name : `${user?.uid.substring(0, 4)}...${user?.uid.substring(user?.uid.length -5, user?.uid.length)} `}{user.isArtist && <VerifiedBadge src={artistBadge} />}</DetailsText>
                      </DetailsContainer>
                    </TableData>
                    <TableData>
                      {" "}
                      <DetailsText>Shares: {user?.balance}%</DetailsText>
                    </TableData>
                    <TableData>
                      {" "}
                  
                    </TableData>
                  </TableRow>
                );
              })}
            </Table>
          </>
  )
}


export default UserList