import React, { useEffect, useState } from 'react'
import useMusic from '../../../hooks/useMusic';
import { getGroupedPlays, getNumListeners, getObjectLength, getPlayCount, groupInfoByDate } from '../../../utils';
import ContentWrapper from '../../ContentWrapper';
import { FormText, FormWrapper } from '../../CustomForm/CustomFormElements';
import ChartToggle from '../../Charts';
import KeyMetrics from '../../Charts/KeyMetrics';
import { ModalLoadingScreen } from '../../LoadingScreen';
import { SelectButton, SelectButtonWrapper } from '../../Select';
import { useWebsiteTheme } from '../../WebsiteTheme/WebsiteThemeContext';
import { ChipList } from '../../Chip/ChipElements';
import Chip from '../../Chip';

const PlaylistStats = ({playlistId}) => {
    const {themeColors} = useWebsiteTheme()
    const {getPlaylistLikesByDate, getPlaylistPlaysByDate} = useMusic()
    const [filter, setFilter] = useState("7days");
    const [isLoaded, setLoaded] = useState(false);
    const [plays, setPlays] = useState([]);
    const [likes, setLikes] = useState([]);    
    const [metricType, setMetricType] = useState("plays");
    const [playType, setPlayType] = useState("all");

    const getData = async () => {
        setLoaded(false);
        setLikes([]);
        setPlays([]);
        return await Promise.all([
            getPlaylistPlaysByDate(playlistId, filter),
            getPlaylistLikesByDate(playlistId, filter),
          
        ])
          .then((data) => {
            if (getObjectLength(data[0]?.plays) > 0) {
              setPlays(data[0]?.plays);
            }
            if (data[1]?.likes?.length > 0) {
              setLikes(data[1]?.likes);
            }
      
            setLoaded(true);
          })
          .catch((err) => {
            setLoaded(true);
          });
      };

    useEffect(() => {
        getData();
      
        return () => {};
      }, [playlistId, filter]);
  
  return (
    <>  <ContentWrapper>
    <SelectButtonWrapper>
      <SelectButton
        disabled={!isLoaded}
        onChange={(e) => setFilter(e.target.value)}
      >
        <option value={"7days"}>7 days</option>
        <option value={"14days"}>2 Weeks</option>
        <option value={"30days"}>4 Weeks</option>
        <option value={"60days"}>2 Months</option>
        <option value={"180days"}>6 Months</option>
        <option value={"365days"}>12 Months</option>
        <option value={"AllTime"}>All Time</option>
      </SelectButton>
    </SelectButtonWrapper>
    {!isLoaded && <ModalLoadingScreen text={"Loading Stats"} />}
    {isLoaded && (
      <>
        <KeyMetrics
          data={[
            {
              heading: "Plays",
              onClick: () => {
                setMetricType("plays");
              },
              labels:
                getObjectLength(plays) > 0
                  ? Object.keys(
                      groupInfoByDate(
                        [...plays?.full, ...plays?.skips, ...plays?.partial],
                        "timestamp",
                        filter
                      )
                    )
                  : [],
              datasets: [
                {
                  label: "Plays",
                  data:
                    getObjectLength(plays) > 0
                      ? Object.values(
                          groupInfoByDate(
                            [
                              ...plays?.full,
                              ...plays?.skips,
                              ...plays?.partial,
                            ],
                            "timestamp",
                            filter
                          )
                        ).map((p) => {
                          return getPlayCount(p);
                        })
                      : [],
                  borderColor: themeColors.infoColor,
                  backgroundColor: themeColors.infoColor,
                },
              ],
            },
            
            {
              heading: "Likes",
              onClick: () => {
                setMetricType("likes");
              },
              labels:
                likes.length > 0
                  ? Object.keys(groupInfoByDate(likes, "dateCreated", filter))
                  : [],
              datasets: [
                {
                  label: "Likes",
                  data:
                    likes.length > 0
                      ? Object.values(
                          groupInfoByDate(likes, "dateCreated", filter)
                        ).map((l) => {
                          return l?.length;
                        })
                      : [],
                  borderColor: themeColors.errorColor,
                  backgroundColor: themeColors.infoColor,
                },
              ],
            },
           
          ]}
        />
        <ContentWrapper>
          {metricType === "plays" && getObjectLength(plays) > 0 &&(
            <>
              <ChipList alignment={"right"}>
                <Chip
                  label={"All"}
                  background={playType === "all" && themeColors.accentColor}
                  onChipClick={() => setPlayType("all")}
                />
                <Chip
                  label={"Full Plays"}
                  background={playType === "full" && themeColors.successColor}
                  onChipClick={() => setPlayType("full")}
                />
                <Chip
                  label={"Partial Plays"}
                  background={playType === "partial" && themeColors.infoColor}
                  onChipClick={() => setPlayType("partial")}
                />
                <Chip
                  label={"Skips"}
                  background={playType === "skip" && themeColors.warningColor}
                  onChipClick={() => setPlayType("skip")}
                />
              </ChipList>
              <>
              
                {playType === "all" && (
                  <>
                 
                    <ChartToggle
                      data={{
                        heading: 'All Plays (By Type)',
                        labels:
                          getObjectLength(plays) > 0
                            ? Object.keys(
                                groupInfoByDate(
                                  [
                                    ...plays?.full,
                                    ...plays?.skips,
                                    ...plays?.partial,
                                  ],
                                  "timestamp",
                                  filter
                                )
                              )
                            : [],
                        datasets: [
                          {
                            label: "Full",
                            data:
                              plays?.full?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      plays?.full,
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p.length;
                                  })
                                : [],

                            borderColor: themeColors.successColor,
                            backgroundColor: themeColors.successColor,
                          },
                          {
                            label: "Partial",
                            data:
                              plays?.partial?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      plays?.partial,
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p.length;
                                  })
                                : [],

                            borderColor: themeColors.infoColor,
                            backgroundColor: themeColors.infoColor,
                          },
                          {
                            label: "Skips",
                            data:
                              plays?.skips?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      plays?.skips,
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p?.length;
                                  })
                                : [],

                            borderColor: themeColors.warningColor,
                            backgroundColor: themeColors.warningColor,
                          },
                        ],
                      }}
                    />
                    <ChartToggle
                      data={{
                        heading: 'Unique Listeners',
                        labels:
                          getObjectLength(plays) > 0
                            ? Object.keys(
                                groupInfoByDate(
                                  [
                                    ...plays?.full,
                                    ...plays?.skips,
                                    ...plays?.partial,
                                  ],
                                  "timestamp",
                                  filter
                                )
                              )
                            : [],
                        datasets: [
                          {
                            label: "Listeners",
                            data:
                              getObjectLength(plays) > 0
                                ? Object.values(
                                    getNumListeners(
                                      groupInfoByDate(
                                        [
                                          ...plays?.full,
                                          ...plays?.skips,
                                          ...plays?.partial,
                                        ],
                                        "timestamp",
                                        filter
                                      )
                                    )
                                  )
                                : [],
                            borderColor: themeColors.infoColor,
                            backgroundColor: themeColors.infoColor
                                
                          },
                        ],
                      }}
                    />
                  </>
                )}
                {playType !== "all" && (
                  <>
                 
                    <ChartToggle
                      data={{
                        heading: `${playType[0].toUpperCase()}${playType.substring(1)} Plays`,
                        labels:
                          getObjectLength(plays) > 0
                            ? Object.keys(
                                groupInfoByDate(
                                  [
                                    ...plays?.full,
                                    ...plays?.skips,
                                    ...plays?.partial,
                                  ],
                                  "timestamp",
                                  filter
                                )
                              )
                            : [],
                        datasets: [
                          {
                            label: playType,
                            data:
                              getObjectLength(plays) > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      [
                                        ...plays?.full,
                                        ...plays?.skips,
                                        ...plays?.partial,
                                      ],
                                      "timestamp",
                                      filter
                                    )
                                  ).map((plays) => {
                                    return getGroupedPlays(plays)[playType];
                                  })
                                : [],

                            borderColor:
                              playType === "skip"
                                ? themeColors.warningColor
                                : playType === "partial"
                                ? themeColors.infoColor
                                : themeColors.successColor,
                            backgroundColor:
                              playType === "skip"
                                ? themeColors.warningColor
                                : playType === "partial"
                                ? themeColors.infoColor
                                : themeColors.successColor,
                          },
                        ],
                      }}
                    />
                    <ChartToggle
                      data={{
                        heading: 'Unique Listeners',
                        labels:
                          getObjectLength(plays) > 0
                            ? Object.keys(
                                groupInfoByDate(
                                  [
                                    ...plays?.full,
                                    ...plays?.skips,
                                    ...plays?.partial,
                                  ],
                                  "timestamp",
                                  filter
                                )
                              )
                            : [],
                        datasets: [
                          {
                            label: "Listeners",
                            data:
                              getObjectLength(plays) > 0
                                ? Object.values(
                                    getNumListeners(
                                      groupInfoByDate(
                                        [
                                          ...plays?.full,
                                          ...plays?.skips,
                                          ...plays?.partial,
                                        ].filter(
                                          (p) => playType === p?.playType
                                        ),
                                        "timestamp",
                                        filter
                                      )
                                    )
                                  )
                                : [],
                            borderColor:
                              playType === "skip"
                                ? themeColors.warningColor
                                : playType === "partial"
                                ? themeColors.infoColor
                                : themeColors.successColor,
                            backgroundColor:
                              playType === "skip"
                                ? themeColors.warningColor
                                : playType === "partial"
                                ? themeColors.infoColor
                                : themeColors.successColor,
                          },
                        ],
                      }}
                    />
                  </>
                )}
              </>

              {/* {!isSongsLoaded && <ModalLoadingScreen />}
              {isSongsLoaded && (
                
                  <FormWrapper>
                    <AlbumSongList 
                      content={songs}
                      heading={`${playType !== 'all' ? `${playType[0].toUpperCase()}${playType.substring(1)} Played Songs` : 'All Songs'}`}
                    />
                  </FormWrapper>
                
              )} */}
            </>
          )}

          {metricType === "likes" && likes?.length > 0 && (
              <ChartToggle
                data={{
                  heading: "No. Likes",
                  labels:
                    likes.length > 0
                      ? Object.keys(
                          groupInfoByDate(likes, "dateCreated", filter)
                        )
                      : [],
                  datasets: [
                    {
                      label: "Likes",
                      data:
                        likes.length > 0
                          ? Object.values(
                              groupInfoByDate(likes, "dateCreated", filter)
                            ).map((l) => {
                              return l?.length;
                            })
                          : [],
                      borderColor: themeColors.infoColor,
                      backgroundColor: themeColors.infoColor,
                    },
                  ],
                }}
              />
          )}
    
    {((metricType === "plays" && plays?.length === 0) ||
            (metricType === "likes" && likes?.length === 0)) && (
            <>
              <ContentWrapper>
                <FormWrapper>
                  <FormText>No Data Available</FormText>
                </FormWrapper>
              </ContentWrapper>
            </>
          )}
        </ContentWrapper>
      </>
    )}
  </ContentWrapper>
    </>
  )
}

export default PlaylistStats