import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useMusic from '../../hooks/useMusic'
import { SLIDER_TYPES } from '../../utils/Enum'
import { useAudio } from '../AudioPlayer/context/AudioContext'
import ContentList from '../ContentList'
import ContentWrapper from '../ContentWrapper'
import LoadingScreen from '../LoadingScreen'
import { Slider } from '../Slider'
import { isUserPayingOut } from '../SonarMuseWebMonetization'




const Genres = () => {
    const [isLoaded, setLoaded] = useState(false)
    const [isSLoaded, setSLoaded] = useState(false)
    const [genres, setGenres] = useState([])
    const [selectedGenre, setSelectedGenre] = useState(false)
    const [searchData, setSearchData] = useState([])
    const  {setCurrentSong, setPlaylist, play} = useAudio()    
    const navigate  = useNavigate()

    const {
        getAllGenres, 
        searchSongsbySubgenre, 
        searchSongsbyGenre, 
    } = useMusic({})

    useEffect(() => {
        setLoaded(false)
        getAllGenres().then(data => {
            //console.log('genres: ', data)
        setGenres(data)
        setLoaded(true)
      }).catch(err => {
        console.error(err)
        setGenres()
        setLoaded(true)
    })
    return () => {}
}, [genres.length === 0])

const playSong = (i) => {
    if(isUserPayingOut()){
      setPlaylist(searchData?.songs.map(s => s))
      setCurrentSong(i) 
  }else{
    return
  }
}

    const selectGenre = async(i) => {
        setSLoaded(false)
        if(selectedGenre === i){
          setSelectedGenre(false)  
          setSearchData([])
        }
        else{
            setSelectedGenre(i)
            await searchSongsbyGenre(genres?.genres[i]?.id)
            .then(data => {
                if(data !== false){
                    setSearchData(data)
                    setSLoaded(true)
                    return 
                }
                return
            })
        }
    }

    const selectSubgenre = async(i) => {
        let sData = searchData
        let subGenres = genres?.subGenres.filter(genre => genre?.genreId === genres?.genres[selectedGenre]?.id)
        setSLoaded(false)
        await searchSongsbySubgenre(subGenres[i].id)
        .then(data => {
            if(data !== false){
                sData.push(...data)
                setSearchData(sData)
                setSLoaded(true)
                return 
            }
            return
        })
        
    }

    const displaySubgenres = () => {
        if(selectedGenre){
            let subGenres = genres?.subGenres?.filter(genre => genre?.genreId === genres?.genres[selectedGenre]?.id)
            return (
                <>
                <ContentWrapper heading={`${genres?.genres[selectedGenre]?.name} Subgenres`}>
                 <Slider type={SLIDER_TYPES.GENRE} heightScroll={true} slides={ subGenres } onSlideClick={selectSubgenre}
                     id={"subgenres"}/> 
                </ContentWrapper>
             </>
             )
        }
    }

    const displayContent = () => {
        return (
           <>
           <ContentWrapper heading={"Genres"}>
            <Slider type={SLIDER_TYPES.GENRE} selected={selectedGenre}  heightScroll={true} slides={genres?.genres} 
                id={"genres"}/> 
           </ContentWrapper>
        </>
        )
    } 


  return (
    <>
        {!isLoaded && <ContentWrapper heading={"Genres"}>
            <Slider type={SLIDER_TYPES.GENRE_SKELETON}/>
            <Slider type={SLIDER_TYPES.GENRE_SKELETON}/>
            <Slider type={SLIDER_TYPES.GENRE_SKELETON}/>
            <Slider type={SLIDER_TYPES.GENRE_SKELETON}/>
                </ContentWrapper>}
        {isLoaded && <> 
        {displayContent()}
        {selectedGenre && displaySubgenres()}
        {isSLoaded && selectedGenre && searchData?.length > 0 && 
        <ContentWrapper heading={'Songs'}>
            <Slider type={SLIDER_TYPES.SONG} id={'genre songs'} onButtonClick={playSong} slides={searchData}/>
        </ContentWrapper>
        }
    </>}
    </>
  )
}

export default Genres