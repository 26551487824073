import React, { useCallback, useEffect, useRef, useState } from 'react'
import useMusic from '../../hooks/useMusic'
import { SLIDER_TYPES } from '../../utils/Enum'
import ContentWrapper from '../ContentWrapper'
import PageIndexer from '../PageIndexer'
import { Slider } from '../Slider'
import { useDigitalSongSearch } from '../../hooks/useSearch'
import { ModalLoadingScreen } from '../LoadingScreen'
import Modal from '../Modal'

const DownloadableSongs = () => {
  const [pageNumber, setPageNumber] = useState(0)

  const {loading, error, songs, pages, setPages} = useDigitalSongSearch({pageNumber: pageNumber})

  const observer = useRef()
  const lastSongElementRef = useCallback( node => {
    
    if(loading){
      return
    }
    if(observer.current){ 
      observer.current.disconnect()}
      observer.current = new IntersectionObserver(entries=> {
        if(entries[0].isIntersecting && (pageNumber < parseInt(pages/10))){
              setPageNumber(pageNumber + 1)

        }
    }) 
    
    if(node) observer.current.observe(node)
   
  }, [loading, pages, pageNumber])
    
    
  return (
    <>
    {
      !loading && songs?.length > 0 && (
        <ContentWrapper heading={"Downloadbable Songs"}>
          <Slider
            type={SLIDER_TYPES.DIGITAL_SONG}
            heightScroll
            slides={songs}
            cardRef={lastSongElementRef}
            id={"digitalSongs"}
          />
        </ContentWrapper>
      )
      /*         <PageIndexer/>
       */
    }
    {
      loading && songs?.length > 0 && (
        <><ContentWrapper heading={"Downloadable Songs"}>
          <Slider
            type={SLIDER_TYPES.DIGITAL_SONG}
            heightScroll
            slides={songs}
            cardRef={lastSongElementRef}
            id={"digitalSongs"}
          />
                <Modal isOpen={loading}><ModalLoadingScreen text={'Loading. Please Wait'}/></Modal>

        </ContentWrapper></>
      )
      /*         <PageIndexer/>
       */
    }
    {loading && songs?.length === 0 && (
      <ContentWrapper heading={"Downloadable Songs"}>
           <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      </ContentWrapper>
    )}
    {!loading && songs?.length === 0 && <ContentWrapper heading={"No Songs Found"}>
        
        </ContentWrapper>}
  </>
  )
}

export default DownloadableSongs