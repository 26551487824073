import styled from "styled-components";
import { lightOrDark } from "../HexToRGBA";

export const Container = styled.section`

  background: ${props => props.theme.mainBg};  
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: none;

`;

export const VideoBg = styled.video`
display: flex;
background-repeat: no-repeat;
-o-object-fit: cover;
object-fit: cover;
background: ${props => props.theme.mainBg};
overflow: none;
width: 100%;
height: 100vh;
justify-content: center;

`


export const CountdownWrapper = styled.section`

  background: ${props => (props?.isVidBg ? props.theme.mainBg : props.theme.secondaryBg )};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  
`;

export const TimerInfoWrapper = styled.div`
display: flex;
position: relative;
justify-content: center;
align-items:center;
flex-direction: column;  
text-align: center;

`;

export const CountdownIcon = styled.span`
position: relative;
display: flex;
font-size: 10em;
  color: ${props =>(props.isVidBg ? 'white' : props.theme.mainColor )};
  @media screen and (max-width: 768px) {
    font-size: 3em;
}

@media screen and (max-width: 480px) {
    font-size: 3em;

}
`;

export const CountdownHeading = styled.h2`
position: relative;
display: flex;
font-weight: 700;
  margin-bottom: 0.5em;
  color: ${props =>(props.isVidBg ? 'white' : lightOrDark(props.theme.mainColor) === 'dark' ? props.theme.secondaryBg : props.theme.mainColor)};

  `;

export const CountdownDetails = styled.p`
position: relative;
display: flex;
color: ${props =>(props.isVidBg ? 'white' : lightOrDark(props.theme.mainColor) === 'dark' ? props.theme.secondaryBg : props.theme.mainColor)};
font-style: italic;
`;
export const TimerWrapper = styled.div`
	border: 1px solid ${props =>(props.isVidBg ? 'white' : lightOrDark(props.theme.mainColor) === 'dark' ? props.theme.secondaryBg : props.theme.mainColor)};
	border-radius: 25px;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	margin-top: 3em;
	padding: 3em;
  position: relative;
  color: ${props =>(props.isVidBg ? 'white' : lightOrDark(props.theme.mainColor) === 'dark' ? props.theme.secondaryBg : props.theme.mainColor)};
	text-align: center;

  @media screen and (max-width: 768px) {
    margin-top: 2em;
	  padding: 2em;
}

@media screen and (max-width: 480px) {
  margin-top: 2em;
	padding: 2em;
}

`;
export const TimeDetailsWrapper = styled.section`
color: ${props =>(props.isVidBg ? 'white' : lightOrDark(props.theme.mainColor) === 'dark' ? props.theme.secondaryBg : props.theme.mainColor)};


`;

export const TimerCounter = styled.div`
  font-size: 2em;
  @media screen and (max-width: 768px) {
    font-size: 1em;
}

@media screen and (max-width: 480px) {
    font-size: 1em;
}
  `;

export const TimerCounter2 = styled.div`
	font-size: 2em;
  @media screen and (max-width: 768px) {
    font-size: 1em;
}

@media screen and (max-width: 480px) {
    font-size: 1em;
}
`;

export const Img = styled.img`
    width: 12em;
    margin: 0 0 10px 0;
    padding-right: 0;
    position: relative;
  	@media screen and (max-width: 768px) {
		width: 10em;
    }
    
    @media screen and (max-width: 480px) {
		width: 8em;
    }
`