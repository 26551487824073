import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import ImageUtils from "../../../utils/ImageUtils";
import {
  DetailsContainer,
  DetailsText,
  ImageWrapper,
  Img,
  Table,
  TableData,
  TableHeading,
  TableRow,
} from "../ContentListElements";
import { getNFTImage } from "../../../utils/nfTokensUploads";
import { Theme } from "../../WebsiteThemeElements";
import ContentWrapper from "../../ContentWrapper";
import { useUser } from "../../../hooks/useUser";
import { ModalLoadingScreen } from "../../LoadingScreen";
import { useModal } from "../../Modal/ModalContext";

const NFTokenList = ({ content, onClick }) => {
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  return (
    <>
      <Table>
        <TableRow>
          <TableHeading></TableHeading>
          <TableHeading></TableHeading>
          <TableHeading></TableHeading>
        </TableRow>
        {content.map((token, index) => {
          return (
            <TableRow
              key={index}
              id={token?.nftoken?.NFTokenID}
              to={"#"}
              onClick={() => (onClick ? onClick(token) : {})}
            >
              <TableData style={{ width: "10%" }}>
                <ImageWrapper>
                  <Img
                    src={token?.tokens?.length > 1
                      ? token?.nftoken?.metadata?.collection?.image
                        ? getNFTImage(token?.nftoken?.metadata?.collection?.image)
                        : token?.nftoken?.metadata?.image
                        ? getNFTImage(token?.nftoken?.metadata?.image)
                        : defaultAlbumArt
                      : token?.nftoken?.metadata?.image
                      ? getNFTImage(token?.nftoken?.metadata?.image)
                      : defaultAlbumArt}
                    alt={token?.nftoken?.NFTokenID}
                  />
                </ImageWrapper>
              </TableData>
              <TableData>
                <DetailsContainer>
                  <DetailsText>
                    {token?.tokens?.length > 0
                ? token?.nftoken?.metadata?.collection?.name
                  ? token?.nftoken?.metadata?.collection?.name
                  : token?.nftoken?.metadata?.name
                  ? token?.nftoken?.metadata?.name
                  : `${token?.nftoken?.NFTokenID?.substring(
                      0,
                      5
                    )}...${token?.nftoken?.NFTokenID?.substring(
                      token?.nftoken?.NFTokenID?.length - 5,
                      token?.nftoken?.NFTokenID?.length - 1
                    )}`
                : token?.nftoken?.metadata?.name
                ? token?.nftoken?.metadata?.name
                : `${token?.nftoken?.NFTokenID?.substring(
                    0,
                    5
                  )}...${token?.nftoken?.NFTokenID?.substring(
                    token?.nftoken?.NFTokenID?.length - 5,
                    token?.nftoken?.NFTokenID?.length - 1
                  )}`
            }
                  </DetailsText>
                </DetailsContainer>
              </TableData>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
};

export const GroupNFTokenList = ({ content, onClick }) => {
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const { toggleModal, modalContent, isOpen, openModal, closeModal } =
    useModal();
  return (
    <>
      <Table>
        <TableRow>
          <TableHeading></TableHeading>
          <TableHeading></TableHeading>
          <TableHeading></TableHeading>
        </TableRow>
        {content.map((token, index) => {
          return (
            <TableRow
              key={token?.nftoken?.NFTokenID}
              id={token?.nftoken?.NFTokenID}
              to={"#"}
              onClick={() => {
                if(token?.tokens?.length > 1) {
                  modalContent(<NFTokenModal groupName={token?.nftoken?.metadata?.collection?.name
                ? token?.nftoken?.metadata?.collection?.name
                : `${token?.Issuer} | Taxon: ${token?.Taxon}`} collection={token?.tokens} onClick={onClick}/>)
              } 
                else{onClick(token)}}} >
           
              <TableData style={{ width: "10%" }}>
                <ImageWrapper>
                  <Img
                    src={token?.tokens?.length > 1
                      ? token?.nftoken?.metadata?.collection?.image
                        ? getNFTImage(token?.nftoken?.metadata?.collection?.image)
                        : token?.nftoken?.metadata?.image
                        ? getNFTImage(token?.nftoken?.metadata?.image)
                        : defaultAlbumArt
                      : token?.nftoken?.metadata?.image
                      ? getNFTImage(token?.nftoken?.metadata?.image)
                      : defaultAlbumArt}
                    alt={token?.nftoken?.NFTokenID}
                  />
                </ImageWrapper>
              </TableData>
              <TableData>
                <DetailsContainer>
                  <DetailsText>
                    {token?.tokens?.length > 1
                ? token?.nftoken?.metadata?.collection?.name
                  ? token?.nftoken?.metadata?.collection?.name
                  : token?.nftoken?.metadata?.name
                  ? token?.nftoken?.metadata?.name
                  : `${token?.nftoken?.NFTokenID?.substring(
                      0,
                      5
                    )}...${token?.nftoken?.NFTokenID?.substring(
                      token?.nftoken?.NFTokenID?.length - 5,
                      token?.nftoken?.NFTokenID?.length - 1
                    )}`
                : token?.nftoken?.metadata?.name
                ? token?.nftoken?.metadata?.name
                : `${token?.nftoken?.NFTokenID?.substring(
                    0,
                    5
                  )}...${token?.nftoken?.NFTokenID?.substring(
                    token?.nftoken?.NFTokenID?.length - 5,
                    token?.nftoken?.NFTokenID?.length - 1
                  )}`
            } {token?.tokens?.length > 1 && `(${token?.tokens?.length} tokens)`}
                  </DetailsText>
                </DetailsContainer>
              </TableData>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
};

export const NFTokenModal = ({ groupName, collection, onClick }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [NFTokens, setNFTokens] = useState([]);
  const { getNFTokenDetails } = useUser({});

  useEffect(() => {
    getNFTokens();
  }, []);

  const getNFTokens = async () => {
    let tokens = [];
    for (let i = 0; i < collection.length; i++) {
      await getNFTokenDetails(collection[i]?.NFTokenID)
        .then((data) => {
          //console.log('tokenInfo: ',data)
          if (data && !data.error) {
            tokens.push(data);
          } else {
            tokens.push(collection[i]);
          }
        })
        .catch((err) => {
          console.error(err);
          tokens.push(collection[i]);
        });
    }
    setNFTokens(tokens);
    setLoaded(true);
  };

  return (
    <>
      {isLoaded && NFTokens?.length > 0 && (
        <div style={{ background: Theme({}).mainBg, borderRadius: "15px" }}>
          <ContentWrapper heading={`${groupName} (${NFTokens?.length})`}>
            <NFTokenList onClick={onClick} content={NFTokens.map(nft => {
                return {
                  ...nft,
                  nftoken: {
                    ...nft.nftoken,
                    NFTokenID: nft.nftoken.nft_id,
                    Issuer: nft.nftoken.issuer,
                    Owner: nft?.nftoken?.owner,
                    URI: nft?.nftoken?.uri,
                    Taxon: nft?.nftoken?.taxon
                  }
                }
              })}/>
          </ContentWrapper>
        </div>
      )}
      {!isLoaded && NFTokens.length === 0 && (
        <ModalLoadingScreen text={"Loading NFTokens, Please Wait..."} transparent={true} />
      )}
    </>
  );
};

export default NFTokenList;
