import React, { useCallback, useEffect, useRef, useState } from 'react'
import ContentWrapper from '../ContentWrapper'
import { Slider } from '../Slider'
import { SLIDER_TYPES } from '../../utils/Enum'
import { ModalLoadingScreen } from '../LoadingScreen'
import Modal from '../Modal'
import { useArtistAlbumsSearch } from '../../hooks/useSearch'
import useArtist from '../../hooks/useArtist'

const ArtistAlbums = ({artistId}) => {
    const [pageNumber, setPageNumber] = useState(0)
    const {getArtistName, getArtistProfile} = useArtist({})
    const [artistName, setArtistName] = useState()
    const {loading, error, albums, pages, setPages} = useArtistAlbumsSearch({pageNumber: pageNumber, artistId: artistId})
  
    const observer = useRef()
    const lastAlbumElementRef = useCallback( node => {
      if(loading){
        return
      }
      if(observer.current){ 
        observer.current.disconnect()}
        observer.current = new IntersectionObserver(entries=> {
          if(entries[0].isIntersecting && (pageNumber < parseInt(pages/10))){
                setPageNumber(prev => prev + 1)
  
          }
      }) 
      
      if(node) observer.current.observe(node)
     
    }, [loading, pages, pageNumber])
  
    useEffect(() => {
    
    }, [artistId]);
  
    return (
      <>
        {
          !loading && albums?.length > 0 && (
            <ContentWrapper heading={"Artist Albums"}>
              <Slider
                type={SLIDER_TYPES.ALBUM}
                heightScroll
                slides={albums}
                cardRef={lastAlbumElementRef}
                id={"artist albums"}
              />
            </ContentWrapper>
          )
          /*         <PageIndexer/>
           */
        }
        {
          loading && albums?.length > 0 && (
           <ContentWrapper heading={"Artist Albums"}>
              <Slider
                type={SLIDER_TYPES.ALBUM}
                heightScroll
                slides={albums}
                id={"artist albums"}
              />
                      <Modal isOpen={loading}><ModalLoadingScreen text={'Loading. Please Wait'}/></Modal>
  
            </ContentWrapper>
          )
          /*         <PageIndexer/>
           */
        }
        {loading && albums?.length === 0 && (
          <ContentWrapper heading={"Artist Albums"}>
               <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
          </ContentWrapper>
        )}
        {!loading && albums?.length === 0 && <ContentWrapper heading={"No Albums Found"}>
            
            </ContentWrapper>}
      </>
    );
}

export default ArtistAlbums