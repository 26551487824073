import { Link } from "react-router-dom";
import styled from "styled-components";
import { CustomButton } from "../ButtonElement";
import { hexToRgba, lightOrDark } from "../HexToRGBA";


export const FormContainer = styled.div`
position: fixed;
display: flex;
width: 100%;
color: ${props => props.theme.secondaryBg};
min-height: 100%;
overflow: hidden;
margin-left: -2%;
font-size: 1rem;
z-index: 999;
justify-content: center;
transition: 0.3s ease-in-out;
opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
top: ${({ isOpen }) => (isOpen ? '5%' : '-150%')};
`

export const Form = styled.form`
  display: flex;
  backdrop-filter: blur(5px);
  flex-direction: column;
  -webkit-backdrop-filter: blur(5px);
  background: ${(props) => hexToRgba(props.theme.mainBg, 0.9)};
  color: ${(props) => props.theme.secondaryBg};
  min-width: 400px;
  padding: 10% 5%;
  border-radius: 25px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
`;

export const FormH1 = styled.h1`
  display: flex;
  margin: 4% 0%;
  justify-content: center;
  color: ${(props) => props.theme.secondaryBg};
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const FormTab = styled.div`

    display: flex;
    color: ${(props) => props.background ? props.background : props.theme.accentColor};
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 1% 1%;
  
`

export const FormImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  padding: 2%;  
  margin-bottom: 1%;
`

export const FormImg = styled.img`
  display:flex;
  align-items: center;
  justify-content: center;
  width: 18rem;
  height: 18rem;
  border-radius: 0.5rem;
  @media screen and (max-width: 480px) {
    width: 10rem;
    height: 10rem;
  }
`;


export const FormLabel = styled.label`
  display: flex;
  width: 100%;
  margin-bottom: 5%;
  color: ${(props) => props.theme.secondaryBg};
  font-size: 14px;
`;

export const FormInput = styled.input`
  padding: 16px 16px;
  background: transparent;
  outline: none;
  color: ${props => props.theme.secondaryBg};
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid ${props => props.theme.secondaryBg};
  border-radius: 4px;
`;

export const FormInput1 = styled.input`
    display: flex;
    padding: 1%;
    background: transparent;
    outline: none;
    color: ${props => props.theme.secondaryBg};
    width: 100%;
    margin-bottom: 1%;
    border: 1px solid ${props => props.theme.secondaryBg};
    border-radius: 4px;
    flex: 1 1 100%;
`
export const FormTextArea = styled.textarea`
 display: flex;
  resize: vertical;
  height: 100%;
  width: 100%;
  padding: 10px 16px;
  resize: vertical;
  background: transparent;
  color: ${props => props.theme.secondaryBg};
  border: 1px solid ${props => props.theme.secondaryBg};
  border-radius: 4px;
  flex: 1 1 100%;
`;


export const FormTabButton = styled(CustomButton)`
    display: flex;
    flex-direction: row;
    height: auto;
    width: auto;
    padding: 2% 2%;
    flex-wrap: nowrap;
    flex: 1 1 100%;
    border-radius: 0%;
    background-color: ${props => props.isActive ? (props.background ? props.background : hexToRgba(props.theme.mainColor, 0.8))  : 'transparent'};
    &:hover {
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      color: ${(props) => props.theme.secondaryBg};
      background-color: ${(props) => (props.isActive ? (props.background ? hexToRgba(props.background, 0.3) : hexToRgba(props.theme.mainColor, 0.3)) : props.theme.secondaryColor )};
  }
`

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100%;
  padding: 2% 2%;
  @media screen and (max-width: 480px) {
    padding: 2% 2%;
	
  }
`;

export const FormContent1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: break-space;
  color: ${props => props.theme.secondaryBg};
  width: 100%;
  padding: 1% 1%;
  @media screen and (max-width: 480px) {
    padding: 1% 1%;	
  }
`;

export const FormWrapper = styled.div`
    display: flex;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background: ${(props) => hexToRgba(props.theme.accentColor, 0.2)};
    border: ${props => `2px solid ${hexToRgba(props.theme.accentColor, 0.2)}`};
    box-shadow: ${props => props?.border ? `8px 10px 0px 0px ${hexToRgba(props?.theme.secondaryBg, 0.5)}` : 'none'};
    flex-direction: ${props => props.row ? 'row': 'column'};
    align-content: flex-start;
    width: 100%;
    padding: 2%;
    margin-bottom: 2%;
`

export const FormText = styled.span`
  text-align: center;
  color: ${(props) => props.theme.secondaryBg};
  font-size: 14px;
  margin-bottom: 10px;
  whiteSpace: 'wrap',
  @media screen and (max-width: 480px) {
    margin-top: 0;
  }
`;

export const FormButton = styled(Link)`
  background: ${(props) => (props.transparent ? 'transparent' : props.theme.mainColor)};
  padding: 2% 1%;
  border: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 3%;
  color: ${(props) => (props.transparent ? props.theme.secondaryBg : props.theme.secondaryBg)};
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 15px;
  text-decoration: ${(props) => (props.transparent ? 'underline' : 'none')};


  @media screen and (max-width: 480px) {
    margin-top: 0;
    font-size: 14px;
  }
`;

export const FormButton1 = styled.div`
  background: ${(props) => (props.transparent ? 'transparent' : props.theme.mainColor)};
  padding: 2% 1%;
  border: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-width: 10rem;
  border-radius: 0.5rem;
  color: ${(props) => (props.transparent ? props.theme.secondaryBg : lightOrDark(props.theme.mainColor) === "dark" ? "white" : "black")};
  font-size: 14px;
  cursor: pointer;
  margin-left: 2%;
  margin-bottom: 2%;
  text-decoration: ${(props) => (props.transparent ? 'underline' : 'none')};


  @media screen and (max-width: 480px) {
    margin-top: 0;
    font-size: 14px;
  }
`;