import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import SimilarAlbums from '../../components/ViewAllContent/SimilarAlbums';
import Navbar from '../../components/Navbar';
import { useNavigate, useParams } from 'react-router-dom';
import { Home, Welcome } from '../../components/Navbar/Data';

const SimilarAlbumsPage = () => {
    const navigate  = useNavigate()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}  
    const {albumId} = useParams()

    useEffect(() => {
        if(!albumId)
        {
            navigate(-1)
        }
        return () => {}
    }, [albumId])
    
    return( 
        <>
              <Sidebar
            isOpen={isOpen}
            toggle={toggle}
            navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
          />
            <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
            <SimilarAlbums albumId={albumId}/>
            
        </>
        )
}

export default SimilarAlbumsPage