import styled from 'styled-components'
import { CustomButton } from '../ButtonElement'
import { hexToRgba } from '../HexToRGBA'
import { Nav, NavLink } from '../NavLink/NavLinkElements'

export const Container = styled.div`
    background: ${props => props.theme.mainBg};
    justify-content: center;
    flex-direction: column;
    align-items: center;
`

export const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 5%;
    min-height: 100vh;
    color: ${props => props.theme.secondaryBg};
`

export const MintTitleContainer = styled.div`
    display: flex;
    border: none;
    padding: 5px;
    overflow: hidden;
    align-items: center;
    width: 100%;
    justify-content: center;
    max-height: 20%;
    background: ${props => `linear-gradient(to left, ${hexToRgba(props.theme.accentColor, 0.5) },${hexToRgba(props.background, 0.7)} )`};
    color: ${props => props.theme.secondaryBg};
`

export const MintTitleWrapper = styled.div`
    display: flex;
    padding: 2%;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    background: ${props => hexToRgba(props.theme.mainBg, 0.2)};
    color: ${props => props.theme.secondaryBg};
`

export const MintDetailsContainer = styled.div`
    display: flex;
    width: 100%;
    flex: 1 1 100%;
    justify-content: space-around;
    align-content: space-between;
    padding: 3%;
    color: ${props => props.theme.secondaryBg};
`


export const MintDetailsWrapper = styled.div`
    display: flex;
    width: 100%;
    flex: 1 1 60%;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
`

export const MintDetailsSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    padding: 3%;
    margin-bottom: 4%;
`

export const MintSectionHeading = styled.title`
    display: flex;
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 2%;
`

export const SmallText = styled.small`
    display: flex;
    flex: 1 1 100%;
    padding: 2%;
    white-space: normal;
    color: ${props => props.error ? props.theme.errorColor : props.theme.secondaryBg};
`
export const MintDetailsText = styled.div`
    display: flex;
    padding: 2%;
    flex: 1 1 100%;
    white-space: normal;
    color: ${props => props.error ? props.theme.errorColor : props.theme.secondaryBg};
`

export const MintButtonConainer = styled.section`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
    align-content: center;
    padding: 3%;
    background: linear-gradient(to left, ${props => hexToRgba(props.theme.accentColor, 0.5) },${props => hexToRgba(props.background ? props.background: props.theme.mainColor, 0.7) });
`

export const MintButton = styled(CustomButton)`
    padding: 2%;
    backgound: ${props => props.background ? props.background : props.theme.secondaryColor};
    min-width: 10rem;
    justify-self: center;
    align-self: center;
`

export const MintNav = styled(Nav)`
  display: flex;
  color: ${(props) => props.background ? props.background: props.theme.mainBg};
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  padding: 3% 2%;
  width: 100%;
  border-radius: 50px; 
  background: ${(props) => hexToRgba(props.background ? props.background : props.theme.secondaryBg, 0.9 )};
`

export const MintNavLink = styled(NavLink)`

  display: flex;
  font-size: 0.85rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: 1% 2%;
  margin: 0px 3%;
  
  &:hover {
    background-color: ${props => hexToRgba(props.theme.secondaryBg, 0.2)};
    color: ${(props) => props.theme.accentColor };
    transition: all 0.3s ease-in-out;
    border-radius: 50px; 
    border-bottom:  ${props => !props.active ? `${props.theme.secondaryBg} solid 2px ` : 'none'};
    transform: scale(1.1);
  }
    background: ${(props) => (props.active && props.theme.secondaryColor) };
    border-radius: 50px; 
    color: ${(props) => (props.active ?  (props.theme.mainColor) :  props.theme.mainBg) };
    transform: ${props => props.active ? 'scale(1.15)' : 'none'} ;
    border-bottom:  ${props => props.active ? `${props.color ? props.color :props.theme.mainColor} solid 2px ` : 'none'};
    @media screen and (max-width: 480px) {
      font-size: 0.65rem;
    }
`
