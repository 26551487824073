import React from 'react'
import { FaToggleOff, FaToggleOn } from 'react-icons/fa';
import styled from "styled-components";

export const FormToggle = styled.div`
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
    flex: 1 1 100%;
    color: ${props => props.theme.secondaryBg};
`

export const ToggleText = styled.text`
    font-size: 0.7rem;
    display:flex;
    padding: 2%;
    color: ${props => props.theme.secondaryBg};
`
export default function ToggleButton({toggle, toggleClick, onText, offText, customOnIcon, customOffIcon}) {
  return (
    <>
    <FormToggle>
    <ToggleText>{offText}</ToggleText>
        {toggle ? 
        customOnIcon ? <div style={{cursor:'pointer'}} onClick={toggleClick}>{customOnIcon}</div> : (<FaToggleOn onClick={toggleClick}/> ) : 
        customOffIcon ? <div style={{cursor:'pointer'}}  onClick={toggleClick}>{customOffIcon}</div> : (<FaToggleOff onClick={toggleClick}/>)}
        <ToggleText>{onText}</ToggleText>
    </FormToggle>
    </>
  )
}

