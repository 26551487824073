import React, { Profiler, useEffect, useState } from 'react'
import ToolTip from '../../ToolTip'
import verifiedBadge from "../../../images/verified_artist_page.png";
import useRecordLabel from '../../../hooks/useRecordLabel'
import { ActionButton, ActionButtonsContainer, HeaderContainer, RecordLabelHeader, RecordLabelInfoWrapper, RecordLabelName, RecordLabelProfileHeaderContainer, RecordLabelProfilePicture, RecordLabelProfilePictureContainer, VerifiedBadge } from './RecordLabelProfileHeaderElements'
import { useAuth } from '../../../contexts/AuthContext';
import { useAlert } from '../../Alert/AlertContext';
import OptionModal from '../../OptionModal';
import { HoverOption } from '../../MenuOptions/MenuOptionsElements';
import TipForm from '../../TipForm';
import { ALERT_TYPES } from '../../../utils/Enum';
import TipWallets from '../../TipForm/TipWallets';
import { DEFAULT_IMAGE } from '../../GravatarAPI';
import { useModal } from '../../Modal/ModalContext';

const RecordLabelProfileHeader = ({labelId, profile, isUserFollowing, wallets, gravatarProfile}) => {
  const {currentUser} = useAuth()
  const {toggleModal, modalContent} = useModal()
  const {followRecordLabel, unfollowRecordLabel} = useRecordLabel({id: labelId})
  const [isFollows, setFollowing] = useState(false)
  const { addAlert } = useAlert();

//console.log(gravatarProfile)
  useEffect(() => {
    setFollowing(isUserFollowing);
    return
  }, [labelId]);

  //MOVE THE BUTTONS INTO CUSTOM COMPONENT CLASSES!
  const onFollowClick = () => {
    // follow and unfollow usera
    followRecordLabel(labelId)
      .then((data) => {
        if (data !== false) {
          setFollowing(!isFollows);
        }
      })
      .catch((err) => {
        return;
      });
  };

  const onUnfollowClick = () => {
    // follow and unfollow user

    unfollowRecordLabel(labelId)
      .then((data) => {
        if (data !== false) {
          setFollowing(!isFollows);
        }
      })
      .catch((err) => {
        return;
      });
  };



  return (
    <HeaderContainer>
        
          <RecordLabelHeader background={gravatarProfile?.profileBackground?.color}>
          
          </RecordLabelHeader>
        <RecordLabelProfileHeaderContainer>
            <RecordLabelProfilePictureContainer>
            <RecordLabelProfilePicture src={profile?.labelSettings?.profilePictureType === "custom" ? profile?.profilePicture?.url : gravatarProfile?.avatar_url ? gravatarProfile?.avatar_url + '?d=mp&s=600' : DEFAULT_IMAGE(600)}/>
          </RecordLabelProfilePictureContainer>
          <RecordLabelInfoWrapper>
            <RecordLabelName>{profile?.name}{profile?.isVerified && (
                <ToolTip text={"This account is verified"}>
                  <VerifiedBadge
                    src={verifiedBadge}
                    onContextMenu={(e) => {
                      e.preventDefault();
                    }}
                  />
                </ToolTip>
              )}</RecordLabelName>
                {currentUser?.uid && (
              <ActionButtonsContainer>
                <ActionButton
                  /* ref={followRef} */
                  background={gravatarProfile?.profileBackground?.color}
                  onClick={() =>
                     isFollows ? onUnfollowClick() : onFollowClick()
                  }
                >
                  {" "}
                  { isFollows ? "Unfollow" : "Follow"}
                </ActionButton>
                {profile?.labelSettings?.tipping && <ActionButton background={gravatarProfile?.profileBackground?.color} onClick ={() => {
                  toggleModal()
                  modalContent(<OptionModal heading={`Select Wallet to tip ${profile?.name}`}>
                    {profile?.uid && <><HoverOption onClick={() => {
                      toggleModal()
                      modalContent(<TipForm displayName={profile?.name} address={profile?.uid}/>)
                    }}>XRP Wallet</HoverOption>
                    <HoverOption onClick={() => {
                      toggleModal()
                      modalContent()
                      addAlert({
                        title: "Coming Soon",
                        type: ALERT_TYPES.INFO,
                        secs: 10,
                      })
                    }}>ILP Wallet</HoverOption></>}
                  { wallets?.length > 0 && <HoverOption onClick={() => {
                      toggleModal()
                      modalContent(<TipWallets wallets={wallets} />)
                    }}>Other Wallets</HoverOption>}
                  </OptionModal>)}}>
                      Send Tip
                    </ActionButton>}
                {profile?.uid &&
                  profile?.isVerified &&
                  profile?.subscriptionFee > 0 &&
                  profile?.uid !== currentUser?.uid && (
                    <ActionButton background={profile?.gravatarProfile?.profileBackground?.color}>
                      Subscribe
                    </ActionButton>
                  )}
              </ActionButtonsContainer>
            )}
          </RecordLabelInfoWrapper>
          </RecordLabelProfileHeaderContainer>
    </HeaderContainer>
  )
}

export default RecordLabelProfileHeader