import React,{useState} from "react";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { ImageViewer } from "../ImageViewer";
import Modal from "../Modal";
import { ImageMainSlider, ImageSliderCard, ImageSliderCardImg, ImageSliderContainer, ImageSliderIcon, Img } from "./ImageSliderElements";

export default function ImageSlider({images}){
const [isOpen, setOpen] = useState(false)
const [id, setId] = useState()



    const openViewer = (id) => {
        setId(id)
        setOpen(!isOpen)
        
      }
      
      const closeModal = () => {
        setOpen(false)
        setId()
      }

    const Slide = (direction) => {
        let slider = document.getElementById('images');
        switch (direction) {
            case 'left':
                slider.scrollLeft = slider.scrollLeft - 400;
                break;
        
            case 'right':
                slider.scrollLeft = slider.scrollLeft + 400;                
            break;
            default:
                break;
        }
        
    }

    return (
        <>
        {id && <Modal ariaHideApp={false} isOpen={isOpen} onRequestClose={closeModal}>
          <ImageViewer img={images[id].url+'?s=600d=mp'}/>
      </Modal>}
        <ImageSliderContainer>
        {(images.length >= 7 ) && 
            <ImageSliderIcon style={{left: '2%'}} onClick={() => Slide('left')} >
                <FaChevronCircleLeft/>
            </ImageSliderIcon>}
            <ImageMainSlider id='images'>
        { images.map((image, index) => {
            return (<>
            {!image?.type && 
                <ImageSliderCard key={index} id={index}>
                
                    <ImageSliderCardImg>
                        {
                        //console.log('image',image.value)
                        }
                        <Img onClick={() => openViewer(index)} src={image?.url+'?s=1300'} onContextMenu={(e) => {e.preventDefault()}}/>
                    </ImageSliderCardImg>
        </ImageSliderCard>}</>
        )})}
        </ImageMainSlider>
        {(images.length >= 7 ) && 
            <ImageSliderIcon style={{right: '2%'}} onClick={() => Slide('right')} >
                <FaChevronCircleRight/>
            </ImageSliderIcon>}
        </ImageSliderContainer>
        </>
    )

}