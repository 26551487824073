import styled, { keyframes } from "styled-components";
import { ALERT_TYPES} from "../../utils/Enum";
import { hexToRgba } from "../HexToRGBA";

export const MEDIA_SCREEN = { //Screen sizes for devices
  MOBILE: '480',
  TABLETS: '768',
  LAPTOPS: '1024',
  DESKTOPS: '1025',
  TV: '1500'
}

const SlideLeft = keyframes`
0% {
    margin-left: 120%;
}

100% {
  margin-left: 0;
}
`


const SlideRight = keyframes`
0% {
    margin-left: 0;
}

100% {
  margin-left: 120%;
}

`

export const AlertWrapper = styled.div`
  position: fixed;
  z-index: 10000;
  top: 5%;
  right: 2%;
  width: 300px;
  @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px)
{
    top: 20%;
}
@media screen and (min-width: ${MEDIA_SCREEN.TV}px)
{
    top: 20%;
}
`;

export const AlertContainer = styled.div`
  padding-top: 5px;
  box-shadow: 0 0 15px ${(props) => hexToRgba(props.theme.secondaryColor, 0.5)};
  border-radius: 5px;
  background: ${(props) => hexToRgba(props.theme.mainBg, 0.6)};
  overflow: hidden;
  margin-bottom: 2%;
  width: 100%;
  color: ${(props) => props.theme.secondaryBg};
  animation: ${props => props.exit ? SlideRight : SlideLeft } 0.4s;
  animation-fill-mode: forwards;
`;

export const AlertTitle = styled.div`
  width: 100%;
  display: flex;
  padding: 2% 2%;
  font-weight: 700;
  background: ${props => hexToRgba(props.type, 0.3)};
  font-size: 1rem;
  justify-content: space-between;
  border-bottom: 3px groove ${props => props.theme.accentColor};

`

export const Closebtn = styled.div`
  color: ${(props) => props.theme.secondaryBg};
  font-weight: bold;
  font-size: 1rem;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
  &: hover {
    color: ${props => props.theme.accentColor};
  }
`;

export const ExpirationBar = styled.div`
    height: 12px;
    bottom: 0;
    border-radius: 0px 15px 15px 0px;
    width: ${props => props.width}%;
    background-color: ${props => props.type};
  `;

export const AlertMessageContainer = styled.div`
  display: flex;
  padding: 3%;
  justify-content: space-between;
  margin-bottom: 3%;
`
export const AlertMessage = styled.span`
  display: flex;
`;
