import React from "react";
import ImageUtils from "../../../utils/ImageUtils";
import { VerifiedBadge } from "../../Profile/ProfileDetails/ProfileDetailsElements";
import {
  Button,
  Button1,
  ButtonWrapper,
  DetailsContainer,
  DetailsText,
  ImageWrapper,
  Img,
  Table,
  TableData,
  TableRow,
} from "../ContentListElements";
import { getResizedImage } from "../../../utils/FileUploadsUtils";

const PodcasterList = ({ content, onButtonClick }) => {
  const { DEFAULT_IMAGE, verifiedBadge } = ImageUtils();
  return (
    <Table>
      {content.map((podcaster, index) => {
        return (
          <TableRow key={index} id={podcaster?.podcasterId}>
            <TableData>
              <ImageWrapper>
                <Img
                  isCircular={true}
                  src={
                    podcaster?.podcasterSettings?.profilePictureType ===
                      "custom" && podcaster?.profilePicture
                      ? podcaster?.profilePicture
                      : podcaster?.photoURL
                      ? podcaster?.photoURL
                      : DEFAULT_IMAGE(30)
                  }
                  alt={
                    podcaster?.podcasterName
                      ? podcaster?.podcasterName
                      : podcaster
                  }
                />
              </ImageWrapper>
            </TableData>
            <TableData
              onClick={() =>
                onButtonClick && podcaster?.podcasterId
                  ? onButtonClick(podcaster)
                  : {}
              }
            >
              <DetailsContainer>
                <DetailsText>
                  {podcaster?.podcasterName}
                  {podcaster?.isVerified && (
                    <VerifiedBadge src={verifiedBadge} />
                  )}
                </DetailsText>
              </DetailsContainer>
            </TableData>
            {!onButtonClick && (
              <>
                <TableData>
                  <ButtonWrapper style={{ opacity: "0" }}>
                    <Button1>Play</Button1>
                  </ButtonWrapper>
                </TableData>

                <TableData>
                  <ButtonWrapper style={{ opacity: "0" }}>
                    <Button1>Play</Button1>
                  </ButtonWrapper>
                </TableData>

                <TableData>
                  {" "}
                  {podcaster?.isFollowing && (
                    <ButtonWrapper style={{ opacity: "0" }}>
                      <Button1>Unfollow</Button1>
                    </ButtonWrapper>
                  )}{" "}
                  {!podcaster?.isFollowing && (
                    <ButtonWrapper style={{ opacity: "0" }}>
                      <Button1>Follow</Button1>
                    </ButtonWrapper>
                  )}
                </TableData>
              </>
            )}
          </TableRow>
        );
      })}
    </Table>
  );
};

export default PodcasterList;
