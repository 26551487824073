import React, { useEffect, useState } from 'react'
import { v4 } from 'uuid'
import { useUser } from '../../../hooks/useUser'
import { getObjectLength } from '../../../utils'
import { SLIDER_TYPES } from '../../../utils/Enum'
import ContentWrapper  from '../../ContentWrapper'
import { Slider } from '../../Slider'

const ProfileWatchlist = ({ isLoaded, watchlist}) => {
    
    
  return (
    <>{isLoaded && getObjectLength(watchlist) > 0 && <>
    {watchlist?.songs?.length > 0 && <ContentWrapper heading='Songs'>
        <Slider type={SLIDER_TYPES.SONG} slides={watchlist?.songs} id={`watchlist ${v4()}`}/>
    </ContentWrapper>}
    {watchlist?.albums.length > 0 && <ContentWrapper heading='Albums'>
        <Slider type={SLIDER_TYPES.ALBUM} slides={watchlist?.albums} id={`watchlist ${v4()}`}/>
    </ContentWrapper>}
    {watchlist?.collections?.length > 0 && <ContentWrapper heading='Collections'>
        <Slider type={SLIDER_TYPES.COLLECTION} slides={watchlist?.collections} id={`watchlist ${v4()}`}/>
    </ContentWrapper>}
    
    </>}
    {!isLoaded && <>
    <ContentWrapper heading='Songs'>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON} id={`watchlist ${v4()}`}/>
    </ContentWrapper>
    <ContentWrapper heading='Albums'>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}  id={`watchlist ${v4()}`}/>
    </ContentWrapper>
    <ContentWrapper heading='Collections'>
        <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}  id={`watchlist ${v4()}`}/>
    </ContentWrapper>
    
    </>} { isLoaded && getObjectLength(watchlist) === 0 && 
        <Slider type={SLIDER_TYPES.SONG} id={`watchlist ${v4()}`}/>
    }</>
  )
}

export default ProfileWatchlist