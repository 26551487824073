import { Link } from "react-router-dom";
import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";

export const EligibilityContainer = styled.div`
    display: flex;
    padding: 5%;
    border-radius: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${props => hexToRgba(props.theme.mainBg, 0.8)};
    border: solid 1px ${props => hexToRgba(props.theme.accentColor, 0.7)};

`


export const Img = styled.img`
width: 100%;
border-radius: 50%;
`

export const ImgWrap = styled.div`
    display: flex;
    padding: 2%;
    margin-bottom: 2%;
`

export const ProfilePictureBackground = styled.div`
    display: flex;
    width: 10em;
    justify-content: center;
    background-size: cover;
    border-radius: 50%;
    padding: 2%;
    cursor: pointer;
    border: groove 5px ${props => props.theme.secondaryBg};
    background-repeat: no-repeat;
    background-position: center;
    &:hover{
        transform: scale(1.01);
        transition: 1s eas-out;
        
    }
    @media screen and (max-width: 768px) {
    width: 8em;
}

@media screen and (max-width: 480px) {
    width: 6em;
}


`;

export const TextWrapper = styled.div`
max-width: 540px;
padding-top:0;
padding-bottom: 60px;
`
export const TopLine = styled.p`
    color: ${props => props.theme.accentColor};
    align-self: center;
    justify-self: center;
    display: inline-block;
    font-size: 16px;
    truncate: true;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
    
    @media screen and (max-width: 480px) {
        font-size: 14px;
    }
`

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 1.4rem;
    line-height: 1.1;
    font-weight: 600;
    color: ${(props) => (props.lightText ? props.theme.secondaryBg : props.theme.mainBg)};

    @media screen and (max-width: 480px){
        font: 32px;
    }

`

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom:35px;
    font-size: 18px;
    line-height: 24px;
    color: ${(props) => (props.darkText ? props.theme.mainBg : '#fff')};
`

export const BtnWrap = styled.div`
    display: flex;

`

export const Button = styled(Link)`
    border-radius: 50px;
    background: ${props => props.theme.secondaryColor};
    white-space: nowrap;
    padding: 12px 38px;
    color: ${props => props.theme.mainBg};
    font-size: 16px';
    outline: none;
    border: none;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${(props) => props.theme.mainColor};
        color: ${(props) => props.theme.secondaryBg};
    }
`