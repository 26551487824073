import React from "react";
import { ARTIST_FORM_TYPES } from "../../../utils/Enum";
import ArtistForm from "../ArtistForm";
import {
  ErrorWrapper,
  FormWrapper,
  SongDetailsSection,
  SongDetailsSectionTitle,
  SongDetailsText,
} from "../MusicUploadsFormElements";
import UserRoyaltyForm from "../UserRoyaltyForm";

const Page7 = ({
  payoutArtists,
  errors,
  setErrors,
  isUserRoyalties,
  setUserRoyalties,
  userRoyalties,
  payoutArtistSearchRef,
  removeArtist,
  clearArtistForm,
  setCurrentPayoutArtist,
  setPayoutEditing,
  handleArtistInput,
  searchInput,
  currentPayoutArtist,
  payoutArtistFullNameRef,
  payoutArtistWalletAddressRef,
  payoutSearch,
  payoutArtistNameRef,
  payoutArtistIdRef,
  artistPayoutRef,
  selectArtist,
  validateArtistInfo,
  payoutEditing,
}) => {
  //console.log(errors)
  return (
    <><FormWrapper>
      <ErrorWrapper>
        <ArtistForm
          title={"Artist Payouts (Royalties)"}
          type={ARTIST_FORM_TYPES.PAYOUT}
          artists={payoutArtists}
          artistSearchRef={payoutArtistSearchRef}
          removeArtist={removeArtist}
          clearArtistForm={clearArtistForm}
          setCurrentArtist={setCurrentPayoutArtist}
          setArtistEditing={setPayoutEditing}
          handleInput={handleArtistInput}
          searchInput={searchInput}
          currentArtist={currentPayoutArtist}
          walletAddressRef={payoutArtistWalletAddressRef}
          artistSearch={payoutSearch}
          artistNameRef={payoutArtistNameRef}
          artistFullNameRef={payoutArtistFullNameRef}
          artistIdRef={payoutArtistIdRef}
          artistPayoutRef={artistPayoutRef}
          selectArtist={selectArtist}
          validateArtistInfo={validateArtistInfo}
          artistEditng={payoutEditing}
        />
        {errors?.payoutArtists && (
          <SongDetailsText error>{errors?.payoutArtists}</SongDetailsText>
        )}
      </ErrorWrapper>
    </FormWrapper>
      {isUserRoyalties && <UserRoyaltyForm royaltyInfo={userRoyalties} setRoyaltyInfo={setUserRoyalties} errors={errors} setErrors={setErrors}/>}
    
                  
    </>
  );
};

export default Page7;
