import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";

export const MEDIA_SCREEN = { //Screen sizes for devices
    MOBILE: '480',
    TABLETS: '768',
    LAPTOPS: '1024',
    DESKTOPS: '1025',
    TV: '1500'
  }

export const ShareButtonClipboardWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    color: ${props => props.theme.secondaryBg};
    background: ${props => hexToRgba(props?.theme.mainBg, 0.5)};
    width: 100%;
    border: 1px solid grey;
    cursor: text;
    display: inline-flex;
    padding: 2%;
    position: relative;
    font-size: 0.8rem;
    box-sizing: border-box;
    font-style: normal;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: normal;
    border-radius: 4px;
    letter-spacing: -0.05px;
       @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
       flex-direction: column;

      
      }
  

`

export const ShareButtonClipboardText = styled.div`
display: flex;
    color: ${props => props.theme.secondaryBg};
    font-size: 16px;
    max-width: calc(100% - 55px);
    overflow-x: auto;
    line-height: 24px;
    white-space: nowrap;
      @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
       
        font-size: 0.5rem; 
      }
`

export const ShareButtonClipboardButton = styled.div`
    color: #0C66FF;
    right: 0px;
    width: auto;
    cursor: pointer;
    padding: 2%;
    position: absolute;
    font-weight: bold;
       @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        position: relative;

      }
   

`