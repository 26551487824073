import React, {useEffect, useState } from "react";
import defaultImg from "../../../images/sm_album_art_placeholder.png";
import {
  AlbumArt,
  AlbumArtContainer,
  CloseButton,
  HeaderText,
  HeaderTextContainer,
  Img,
  Modal,
  PlaylistContainer,
  PlaylistContainerWrapper,
  PlaylistFooter,
  PlaylistHeader,
  PlaylistHeaderContainer,
  PlaylistNav,
  PlaylistNavContainer,
  PlaylistNavLink,
  PlaylistSlider,
  PlaylistVideoContainer,
  PlaylistVideoContainerWrapper,
  PlaylistWrapper,
  Text,
} from "./AudioPlayerPlaylistElements";
import { AudioContext, useAudio } from "../context/AudioContext";
import { HeadingTextWrapper } from "../../Profile/ProfileDetails/ProfileDetailsElements";
import { convertDuration, convertTimeToSeconds, getArtistsNames, getArtistsNames2, getCCInfo, getPodcastersNames, getPodcastersNames2, getSongTitle, stringFormatter } from "../../../utils";
import { ARTIST_SEARCH_TYPES, CONTENT_TYPES, PODCASTER_SEARCH_TYPES } from "../../../utils/Enum";
import { useAuth } from "../../../contexts/AuthContext";
import { Zoom } from "react-reveal";
import ContentWrapper from "../../ContentWrapper";
import { isUserPayingOut } from "../../SonarMuseWebMonetization";
import RecordPlayer from "../../RecordPlayer";
import MusicInfo from "../../MusicPage/MusicInfo";
import { NavContainer } from "../../NavLink/NavLinkElements";
import { MusicLyricsWrapper } from "../../MusicPage/MusicPageElements";
import { FormText, FormWrapper as FW } from "../../CustomForm/CustomFormElements";
import { CustomButton } from "../../ButtonElement";
import { FormWrapper } from "../../MusicUploadsForm/MusicUploadsFormElements";
import { useUser } from "../../../hooks/useUser";
import { useModal } from "../../Modal/ModalContext";
import OptionModal from "../../OptionModal";
import StreamingSubscriptionModal from "../../StreamingSubscriptionModal";
import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import { VideoPlayerSkeleton } from "../../Skeleton";
import VideoPlayer from "../../VideoPlayer";
import AudioVisualizer from "../../AudioVisualizer";
import { AudioPlayerQueueList } from "../../ContentList/SongList";
import LyricSynchronizer from "../../LyricSynchronizer";
import { AudioPlayerControls, AudioPlayerControlsWrapper, AudioPlayerDetailsWrapper, AudioPlayerDurationTimerWrapper, AudioPlayerPlaybackControlsWrapper, AudioPlayerSlider, AudioPlayerSliderWrapper, AudioPlayerSongDetailsText, AudioPlayerSongDetailsWrapper, AudioPlayerTimerText, AudioPlayerVolumeSlider, VolumeControlsWrapper } from "../../AudioPlayer1/AudioPlayerElements";
import Marquee from "react-fast-marquee";
import { RiPlayList2Fill, RiVideoFill } from "react-icons/ri";
import { MdExplicit, MdLyrics } from "react-icons/md";
import ScrollToTop from "../../ScrollToTop";
import { TbArrowsShuffle, TbRepeatOff } from "react-icons/tb";
import { BsArrowRepeat, BsFillSkipEndFill, BsFillSkipForwardBtnFill, BsFillSkipStartFill, BsRepeat1, BsSkipBackwardBtnFill, BsThreeDotsVertical } from "react-icons/bs";
import { FaPause, FaPlay, FaVolumeDown, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import { FcLike, FcLikePlaceholder } from "react-icons/fc";
//import VideoPlayer from "../../VideoPlayer";

function AudioPlayerPlaylist1({ onClose, isOpen, currentNav }) {
  const { currentUser, gravatarInfo, isUserSubscribed, getUserSubscription } = useAuth();
  const {openModal, modalContent, closeModal} = useModal()
  const {getUserQueue} = useUser({})
  const {
    currentSong,
    handleToggle,
    playlist,
    audioPlayer,
    stopMainPlayer,
    setCurrentSong,
    isPlaying,
    timeTravel,
    currentTime,
    togglePlayPause,
  } = useAudio();
  const [currentTab, setTab] = useState(currentNav ? currentNav : "playlist");
  const [isPlaylistLoaded, setPlaylistLoaded] = useState(false)
  const [playlistTab, setPlaylistTab] = useState(
    isUserPayingOut() ? "playlist" : "nfts"
  );

  
  const validatePlay = async() => {
    try{
   if(isPlaying){
     handleToggle("play-pause", !isPlaying)
     return
   }else{
   if(!currentUser?.subscription){
     await getUserSubscription()
   }
   
    let isPlay = await isUserSubscribed()
        if (isPlay && isPlay !== "Network Error") {
     handleToggle("play-pause", true)
   } if(isPlay === "Network Error"){
    return handleToggle("play-pause", false);
   }else{
     
     openModal() 
         modalContent(<><StreamingSubscriptionModal onSuccess={() => {
          getUserSubscription(currentUser?.uid);
           handleToggle("play-pause", !isPlaying)
           openModal()
           modalContent(<OptionModal heading={"Subscription Enabled Sucessfully"}>
             <HoverOption onClick={ 
         closeModal()}>OK</HoverOption>
         </OptionModal>)
            }} onFail={() => {
           modalContent(<OptionModal heading={"Subscription Unsucessful"}>
             <HoverOption onClick={() => {closeModal()
             modalContent()}}>OK</HoverOption>
         </OptionModal>)}} cancel={() => {closeModal()
         modalContent()}} /></>)
   }
 }}catch(err){
   //console.log(err)
   if(isPlaying){
     handleToggle("play-pause", !isPlaying)
     return
   }
 }  
 }


  const displayContent = () => {
    switch (currentTab) {
      case "lyrics":
        return (
          <>{playlist[currentSong]?.syncedLyrics ? 
            <LyricSynchronizer song={playlist[currentSong]} currentTime={audioPlayer?.current?.currentTime} togglePlayPause={togglePlayPause}  timeTravel={timeTravel} isPlaying={isPlaying} /> :
          
          <MusicLyricsWrapper>{stringFormatter(playlist[currentSong]?.lyrics)}</MusicLyricsWrapper>}</>
        );
      case "timestamps": 
      return (
        <>
        <ContentWrapper>
          <FW>
            <HeaderText>Timestamps</HeaderText>
          {playlist[currentSong]?.timestamps.map(timestamp => {
            return (
              <FormWrapper style={{alignContent: "left", justifyContent:"left"}}><CustomButton onClick={() => {
                timeTravel(convertTimeToSeconds(timestamp?.time))
                }}>[{timestamp?.time}]</CustomButton> 
              - {timestamp?.label}</FormWrapper>
            )
          })}</FW>
        </ContentWrapper>
        </>
      )
      case "details":
        return (
          <ContentWrapper>
            <RecordPlayer
              onClick={validatePlay}
              stop={!isPlaying}
              albumArt={
                playlist[currentSong]?.albumArt
                ? playlist[currentSong]?.albumArt
                : playlist[currentSong]?.thumbnail ? playlist[currentSong]?.thumbnail :defaultImg
              }
              alt={playlist[currentSong]?.songName ? playlist[currentSong]?.songName : playlist[currentSong]?.episodeName}
            />

            {playlist?.length > 0 ? (
              <MusicInfo
                type={playlist[currentSong]?.songId ? CONTENT_TYPES.SONG : CONTENT_TYPES.EPISODE}
                content={playlist[currentSong]}
              />
            ) : (
              "No Songs Available"
            )}
          </ContentWrapper>
        );
      case "video":
        return (
        <>
          <VideoPlayer stopMainPlayer={stopMainPlayer} isPlaying={isPlaying} content={playlist[currentSong]} validateSubscription={true}/>
        </>)
      case "visuals":
        return <PlaylistWrapper>
          {{/* <VideoPlayer/> */}}
        </PlaylistWrapper>;
      default:
        return (
          <>
            <ContentWrapper>
              <RecordPlayer
                onClick={validatePlay}
                stop={!isPlaying}
                albumArt={
                  playlist[currentSong]?.albumArt
                    ? playlist[currentSong]?.albumArt
                    : playlist[currentSong]?.thumbnail ? playlist[currentSong]?.thumbnail :defaultImg
                }
                alt={playlist[currentSong]?.songName ? playlist[currentSong]?.songName : playlist[currentSong]?.episodeName}
              />
            </ContentWrapper>
            <PlaylistWrapper>
              <AudioPlayerQueueList
                content={playlist}
                onButtonClick={setCurrentSong}
                type={CONTENT_TYPES.SONG}
                displayFullList={true}
              />
            </PlaylistWrapper>
          </>
        );
    }
  };
  return (
    <>
      <PlaylistContainer isOpen={isOpen} src={playlist[currentSong]?.albumArt}>
        <PlaylistContainerWrapper>
          <PlaylistHeaderContainer>
            <PlaylistHeader background={gravatarInfo?.profileBackground?.color}>
              <HeaderTextContainer>
                {playlist.length > 0 && (
                  <>
                    {isPlaying && (
                      <small style={{ fontStyle: "italics" }}>
                        Now Playing{" "}
                      </small>
                    )}
                    <HeaderText>
                      {playlist[currentSong]?.songId
                        ? getSongTitle(playlist[currentSong])
                        : playlist[currentSong]?.episodeId ? playlist[currentSong].episodeName :  ""}
                    </HeaderText>
                    <Text>
                      {playlist[currentSong]?.songId
                        ? getArtistsNames(
                            playlist[currentSong].artists,
                            ARTIST_SEARCH_TYPES.NAME
                          )
                        : playlist[currentSong]?.episodeId ? getPodcastersNames(
                          playlist[currentSong].podcasters,
                          PODCASTER_SEARCH_TYPES.NAME
                        ): ""}
                    </Text>
                  </>
                )}
              </HeaderTextContainer>
              <AlbumArtContainer>
                <AlbumArt
                  onClick={() => setTab("details")}
                  src={
                    playlist[currentSong]?.albumArt
                    ? playlist[currentSong]?.albumArt
                    : playlist[currentSong]?.thumbnail ? playlist[currentSong]?.thumbnail :defaultImg
                  }
                />
              </AlbumArtContainer>
              <CloseButton onClick={onClose} />
            </PlaylistHeader>
          </PlaylistHeaderContainer>
          <PlaylistNavContainer >
            <PlaylistNav>
              <PlaylistNavLink
                active={currentTab === "playlist"}
                onClick={() => setTab("playlist")}
              >
                Queue
              </PlaylistNavLink>
              {playlist[currentSong]?.lyrics && (
                <PlaylistNavLink
                  active={currentTab === "lyrics"}
                  onClick={() => setTab("lyrics")}
                >
                  {`${playlist[currentSong]?.songName} Lyrics`}
                </PlaylistNavLink>
              )}
              { playlist[currentSong]?.video && (
                <PlaylistNavLink
                  active={currentTab === "video"}
                  onClick={() => setTab("video")}
                >
                  Video
                </PlaylistNavLink>
              )}
              {playlist[currentSong]?.timestamps?.length > 0 && (
                <PlaylistNavLink
                  active={currentTab === "timestamps"}
                  onClick={() => setTab("timestamps")}
                >
                  {`${playlist[currentSong]?.episodeName } timestamps`}
                </PlaylistNavLink>
              )}
              <PlaylistNavLink
                active={currentTab === "details"}
                onClick={() => setTab("details")}
              >
                {playlist[currentSong]?.episodeName ? 'Episode' : playlist[currentSong]?.songName} Details
              </PlaylistNavLink>
            </PlaylistNav>
          </PlaylistNavContainer >
          <Zoom>{displayContent()}</Zoom>
        </PlaylistContainerWrapper>
      </PlaylistContainer>
      <PlaylistFooter />
    </>
  );
}

function AudioPlayerPlaylist({ onClose, isOpen, currentNav, currentSong,  toggleVolume ,
  toggleMute,
handleToggle,
playlist,
audioPlayer,
stopMainPlayer,
setCurrentSong,
isPlaying,
timeTravel,
currentTime,
isRepeat,
volume,
isMute,
handleEnd,
isShuffle,
backThirty,
forwardThirty,
togglePlayPause,
progressBar,
calcTime,
duration,
changeRange }) {
  const { currentUser, gravatarInfo, isUserSubscribed, getUserSubscription } = useAuth();
  const {openModal, modalContent, closeModal} = useModal()
  const {goTo} = ScrollToTop()
  const {getUserQueue} = useUser({})
  const [currentTab, setTab] = useState(currentNav ? currentNav : "playlist");
  const [isPlaylistLoaded, setPlaylistLoaded] = useState(false)
  const [isCountdown, setCoundown] = useState(false);
  const [playlistTab, setPlaylistTab] = useState(
    isUserPayingOut() ? "playlist" : "nfts"
  );

  
  const validatePlay = async() => {
    try{
   if(isPlaying){
     handleToggle("play-pause", !isPlaying)
     return
   }else{
   if(!currentUser?.subscription){
     await getUserSubscription()
   }
   
    let isPlay = await isUserSubscribed()
        if (isPlay && isPlay !== "Network Error") {
     handleToggle("play-pause", true)
     return 
   } if(isPlay === "Network Error"){
    return handleToggle("play-pause", false);
   }else{
     
     openModal() 
         modalContent(<><StreamingSubscriptionModal onSuccess={() => {
          getUserSubscription(currentUser?.uid);
           handleToggle("play-pause", !isPlaying)
           openModal()
           modalContent(<OptionModal heading={"Subscription Enabled Sucessfully"}>
             <HoverOption onClick={ 
         closeModal()}>OK</HoverOption>
         </OptionModal>)
            }} onFail={() => {
           modalContent(<OptionModal heading={"Subscription Unsucessful"}>
             <HoverOption onClick={() => {closeModal()
             modalContent()}}>OK</HoverOption>
         </OptionModal>)}} cancel={() => {closeModal()
         modalContent()}} /></>)
   }
 }}catch(err){
   //console.log(err)
   if(isPlaying){
     handleToggle("play-pause", !isPlaying)
     return
   }
 }  
 }


 const option = async () => {
  openModal()
  modalContent(<OptionModal>
      <HoverOption>View Details</HoverOption>
      <HoverOption>Lyrics</HoverOption>
      <HoverOption>Queue</HoverOption>
  </OptionModal>)
 }

  const displayContent = () => {
    switch (currentTab) {
      case "lyrics":
        return (
          <>{playlist[currentSong]?.syncedLyrics ? 
            <LyricSynchronizer song={playlist[currentSong]} currentTime={audioPlayer?.current?.currentTime} togglePlayPause={togglePlayPause}  timeTravel={timeTravel} isPlaying={isPlaying} /> :
          
          <MusicLyricsWrapper>{stringFormatter(playlist[currentSong]?.lyrics)}</MusicLyricsWrapper>}</>
        );
        
        case "queue" : 
        
        return(
        <><HeaderText>Queue</HeaderText>
        <PlaylistWrapper>
          
              <AudioPlayerQueueList
                content={playlist}
                onButtonClick={setCurrentSong}
                type={CONTENT_TYPES.SONG}
                displayFullList={true}
              />
            </PlaylistWrapper></>
            )
      case "timestamps": 
      return (
        <>
        <ContentWrapper>
          <FW>
            <HeaderText>Timestamps</HeaderText>
          {playlist[currentSong]?.timestamps.map(timestamp => {
            return (
              <FormWrapper style={{alignContent: "left", justifyContent:"left"}}><CustomButton onClick={() => {
                timeTravel(convertTimeToSeconds(timestamp?.time))
                }}>[{timestamp?.time}]</CustomButton> 
              - {timestamp?.label}</FormWrapper>
            )
          })}</FW>
        </ContentWrapper>
        </>
      )
      case "details":
        return (
          <ContentWrapper>
            <RecordPlayer
              onClick={validatePlay}
              stop={!isPlaying}
              albumArt={
                playlist[currentSong]?.albumArt
                ? playlist[currentSong]?.albumArt
                : playlist[currentSong]?.thumbnail ? playlist[currentSong]?.thumbnail :defaultImg
              }
              alt={playlist[currentSong]?.songName ? playlist[currentSong]?.songName : playlist[currentSong]?.episodeName}
            />

            {playlist?.length > 0 ? (
              <MusicInfo
                type={playlist[currentSong]?.songId ? CONTENT_TYPES.SONG : CONTENT_TYPES.EPISODE}
                content={playlist[currentSong]}
              />
            ) : (
              "No Songs Available"
            )}
          </ContentWrapper>
        );
      case "video":
        return (
        <>
          <VideoPlayer stopMainPlayer={stopMainPlayer} isPlaying={isPlaying} content={playlist[currentSong]} validateSubscription={true}/>
        </>)
      case "visuals":
        return <PlaylistWrapper>
          {{/* <VideoPlayer/> */}}
        </PlaylistWrapper>;
      default:
        return (
          <>
              <RecordPlayer
                onClick={validatePlay}
                stop={!isPlaying}
                albumArt={
                  playlist[currentSong]?.albumArt
                    ? playlist[currentSong]?.albumArt
                    : playlist[currentSong]?.thumbnail ? playlist[currentSong]?.thumbnail :defaultImg
                }
                alt={playlist[currentSong]?.songName ? playlist[currentSong]?.songName : playlist[currentSong]?.episodeName}
              />


             
          <AudioPlayerSongDetailsWrapper style={{justifyContent: "center", alignContent: "center", alignItems: "center"}}>
            <HeaderText
              onClick={() => {
                onClose();
                if (playlist[currentSong]?.songId) {
                  goTo(`/song/${playlist[currentSong]?.songId}`);

                  return;
                }
                if (playlist[currentSong]?.episodeId) {
                  /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })*/

                  goTo(`/episode/${playlist[currentSong]?.episodeId}`);
                  return;
                }
              }}
            >
              <>
                {playlist[currentSong]?.songId &&
                  (
                    getSongTitle(playlist[currentSong])
                  )}

                {playlist[currentSong]?.episodeId &&
                  (
                   
                    playlist[currentSong]?.episodeName
                  )}
              </>
            </HeaderText>
            <Text
              onClick={
                playlist[currentSong] &&
                (playlist[currentSong]?.artists?.length > 0 ||
                  playlist[currentSong]?.podcasters?.length > 0)
                  ? () => {
                    onClose()
                      if (playlist[currentSong]?.songId) {
                        goTo(
                          `/artist/${playlist[currentSong]?.artists[0]?.artistId}`
                        );
                        return;
                      }
                      if (playlist[currentSong]?.episodeId) {
                        /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })*/

                        goTo(
                          `/podcaster/${playlist[currentSong]?.podcasters[0]?.podcasterId}`
                        );
                        return;
                      }
                    }
                  : () => {onClose()}
              }
            >
              <div>
                {playlist[currentSong]?.explicit && <MdExplicit />}
                {playlist[currentSong]?.video?.url && <RiVideoFill />}
                {playlist[currentSong]?.songId &&
                  <>{getArtistsNames2(
                    playlist[currentSong]?.artists,
                    ARTIST_SEARCH_TYPES.NAME
                  )?.length > 30 ? (
                    <Marquee
                      text={getArtistsNames(
                        playlist[currentSong]?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      )}
                    />
                  ) : (
                    getArtistsNames(
                      playlist[currentSong]?.artists,
                      ARTIST_SEARCH_TYPES.NAME
                    )
                  )} {playlist[currentSong]?.album && ` — ${playlist[currentSong]?.album?.albumName}`}</>}
                {playlist[currentSong]?.episodeId &&
                  (getPodcastersNames2(
                    playlist[currentSong]?.podcasters,
                    PODCASTER_SEARCH_TYPES.NAME
                  )?.length > 30 ? (
                    <Marquee
                      text={getPodcastersNames(
                        playlist[currentSong]?.podcasters,
                        PODCASTER_SEARCH_TYPES.NAME
                      )}
                    />
                  ) : (
                    getPodcastersNames(
                      playlist[currentSong]?.podcasters,
                      PODCASTER_SEARCH_TYPES.NAME
                    )
                  ))}
              </div>
            </Text>
       
          </AudioPlayerSongDetailsWrapper>
            
          </>
        );
    }
  };
  return (
    <>
      <PlaylistContainer isOpen={isOpen} src={playlist[currentSong]?.albumArt}>
        <PlaylistContainerWrapper>
        <CloseButton onClick={onClose} />
          <Zoom> 
          <AudioPlayerDetailsWrapper>
            {displayContent()}
          <AudioPlayerControlsWrapper style={{padding: ' 0 3%'}}  > <VolumeControlsWrapper>
          <AudioPlayerControls style={{fontSize: "1.4rem"}} onClick={toggleMute}>
            {isMute || volume === 0 ? (
              <FaVolumeMute />
            ) : volume * 100 < 50 ? (
              <FaVolumeDown />
            ) : (
              <FaVolumeUp />
            )}
          </AudioPlayerControls>
          <AudioPlayerVolumeSlider
            type="range"
            volume={isMute ? "0%" : `${volume * 58.5}px`}
            value={isMute ? 0 : volume * 100}
            onChange={toggleVolume}
          />
        </VolumeControlsWrapper>
        
        <AudioPlayerControlsWrapper style={{display: 'flex', padding: '2%', width: '100%', justifyContent: "flex-end", gap: '3%'}}>
        {playlist[currentSong]?.lyrics && <AudioPlayerControls style={{fontSize: "2rem"}} onClick={() => setTab('lyrics')}>
        <MdLyrics />
        </AudioPlayerControls>}
        {<AudioPlayerControls style={{fontSize: "2rem"}} onClick={() => {/* toggleLike(playlist[currentSong]) */}}>
        {currentUser?.uid && (!playlist[currentSong]?.isPreRelease || (playlist[currentSong]?.isPreRelease && playlist[currentSong]?.isPreview)) && 
                    ((currentUser?.likes?.length > 0 &&
                      currentUser?.likes?.filter((like) => like.songId)
                        .find((s) => s.songId === playlist[currentSong]?.songId)) ||
                      (currentUser?.likes?.length > 0 &&
                        currentUser?.likes
                          ?.filter((like) => like.songId)
                          .find((s) => s.songId === playlist[currentSong]?.songId)) ||
                      (currentUser?.likes?.length > 0 &&
                        currentUser?.likes
                          ?.filter((like) => like.episodeId)
                          .find((s) => s.episodeId === playlist[currentSong]?.episodeId)) ||
                      (currentUser?.likes?.length > 0 &&
                        currentUser?.likes
                          ?.filter((like) => like.episodeId)
                          .find((s) => s.episodeId === playlist[currentSong]?.episodeId))) ? (
                      <FcLike />
                    ) : (
                      <FcLikePlaceholder />
                    )}
        </AudioPlayerControls>}
        <AudioPlayerControls style={{fontSize: "2rem"}} onClick={() => currentTab === 'queue' ? setTab() : setTab('queue')}>
          <RiPlayList2Fill />
        </AudioPlayerControls>
        <AudioPlayerControls style={{fontSize: "2rem"}} onClick={() => option}>
        <BsThreeDotsVertical />
        </AudioPlayerControls>
        
        </AudioPlayerControlsWrapper>
        </AudioPlayerControlsWrapper>
          <div style={{display: 'flex', padding: '3%', width: '100%', flexDirection: 'column'}}>
             <AudioPlayerSliderWrapper>
          <PlaylistSlider
              progressWidth={`${(currentTime / duration) * 100}%`}
              type={"range"}
              ref={progressBar}
              onChange={changeRange}
            />
          </AudioPlayerSliderWrapper>
          <AudioPlayerDurationTimerWrapper
              onClick={() => setCoundown(!isCountdown)}
            >
              <AudioPlayerTimerText onClick={() => setCoundown(!isCountdown)}>
                {isPlaying && calcTime(currentTime)}
              </AudioPlayerTimerText>
              <AudioPlayerTimerText onClick={() => setCoundown(!isCountdown)}>
                {isPlaying && !isNaN(duration)
                  ? isCountdown
                    ? convertDuration(playlist[currentSong]?.duration ? playlist[currentSong]?.duration - currentTime : duration - currentTime)
                    : convertDuration(playlist[currentSong]?.duration ? playlist[currentSong]?.duration : duration)
                  : "0:00"}
              </AudioPlayerTimerText>
            </AudioPlayerDurationTimerWrapper>
          
          </div>
          <AudioPlayerControlsWrapper style={{width: '100%', justifyContent: 'center'}}>
        <AudioPlayerControls  style={{fontSize: "1.2rem"}}
          isActive={isRepeat !== false}
          onClick={() => {
            handleToggle("repeat", isRepeat);
          }}
        >
          {!isRepeat && <TbRepeatOff />} {isRepeat === 1 && <BsArrowRepeat />}
          {isRepeat === 2 && <BsRepeat1 />}
        </AudioPlayerControls>
        <AudioPlayerPlaybackControlsWrapper
          isPodcast={playlist[currentSong]?.episodeId}
          style={{ display: 'flex', width: '60%', alignItems: 'center', justifyContent: "space-evenly"}}
        >
          <AudioPlayerControls style={{fontSize: "3rem"}}
            onClick={() => {
              handleToggle("prev", true);
            }}
          >
            <BsFillSkipStartFill />
          </AudioPlayerControls>
          {playlist[currentSong]?.episodeId && (
            <AudioPlayerControls
              onClick={() => {
                backThirty();
              }}
            >
              <BsSkipBackwardBtnFill />
            </AudioPlayerControls>
          )}
          <AudioPlayerControls style={{fontSize: "3rem"}}
            onClick={() => {
              validatePlay();
            }}
          >
            {isPlaying ? <FaPause /> : <FaPlay />}
          </AudioPlayerControls>
          {playlist[currentSong]?.episodeId && (
            <AudioPlayerControls style={{fontSize: "3rem"}}
              onClick={() => {
                forwardThirty();
              }}
            >
              <BsFillSkipForwardBtnFill />
            </AudioPlayerControls>
          )}
          <AudioPlayerControls style={{fontSize: "3rem"}}
            onClick={() => {
              handleToggle("next", true);
            }}
          >
            <BsFillSkipEndFill />
          </AudioPlayerControls>
        </AudioPlayerPlaybackControlsWrapper>
        <AudioPlayerControls
        style={{fontSize: "1.2rem"}}
          isActive={isShuffle}
          onClick={() => {
            handleToggle("shuffle", !isShuffle);
          }}
        >
          <TbArrowsShuffle />
        </AudioPlayerControls>
      </AudioPlayerControlsWrapper>
         
        </AudioPlayerDetailsWrapper>
                    
                    
                    </Zoom>
        </PlaylistContainerWrapper>
      </PlaylistContainer>
    </>
  );
}

export function AudioPlayerVisualizer({onClose, isOpen, currentNav}){
  const { currentUser, gravatarInfo, isUserSubscribed, getUserSubscription } = useAuth();
  const {openModal, modalContent, closeModal} = useModal()
  const {getUserQueue} = useUser({})
  const {
    currentSong,
    handleToggle,
    playlist,
    nfts,
    audioPlayer,
    stopMainPlayer,
    setCurrentSong,
    isPlaying,
    timeTravel,
    togglePlayPause,
  } = useAudio();
  const [currentTab, setTab] = useState(currentNav ? currentNav : "playlist");
  const [isPlaylistLoaded, setPlaylistLoaded] = useState(false)
  const [playlistTab, setPlaylistTab] = useState(
    isUserPayingOut() ? "playlist" : "nfts"
  );

  
  const validatePlay = async() => {
    try{
   if(isPlaying){
     handleToggle("play-pause", !isPlaying)
     return
   }else{
   if(!currentUser?.subscription){
     await getUserSubscription()
   }
   
    let isPlay = await isUserSubscribed()
        if (isPlay && isPlay !== "Network Error") {
     handleToggle("play-pause", !isPlaying)
   }else{
     
     openModal() 
         modalContent(<><StreamingSubscriptionModal onSuccess={() => {
          getUserSubscription(currentUser?.uid);
           handleToggle("play-pause", !isPlaying)
           openModal()
           modalContent(<OptionModal heading={"Subscription Enabled Sucessfully"}>
             <HoverOption onClick={ 
         closeModal()}>OK</HoverOption>
         </OptionModal>)
            }} onFail={() => {
           modalContent(<OptionModal heading={"Subscription Unsucessful"}>
             <HoverOption onClick={() => {closeModal()
             modalContent()}}>OK</HoverOption>
         </OptionModal>)}} cancel={() => {closeModal()
         modalContent()}} /></>)
   }
 }}catch(err){
   //console.log(err)
   if(isPlaying){
     handleToggle("play-pause", !isPlaying)
     return
   }
 }  
 }

 useEffect(() => {
  if((currentTab === 'lyrics' && !playlist[currentSong]?.lyrics) || (currentTab === 'timestamps' && !playlist[currentSong]?.timestamps) || (currentTab === 'video' && !playlist[currentSong]?.video )){
    setTab("playlist")
  }
 
   return () => {
     
   }
 }, [currentSong])
 

  const displayContent = () => {
    switch (currentTab) {
      case "lyrics":
        return (
          <>{playlist[currentSong]?.syncedLyrics ? 
            <LyricSynchronizer song={playlist[currentSong]} togglePlayPause={togglePlayPause} currentTime={audioPlayer?.current?.currentTime} timeTravel={timeTravel} isPlaying={isPlaying} /> :
          
          <MusicLyricsWrapper>{stringFormatter(playlist[currentSong]?.lyrics)}</MusicLyricsWrapper>}</>
        );
      case "timestamps": 
      return (
        <>
        <ContentWrapper>
          <FW>
            <HeaderText>Timestamps</HeaderText>
          {playlist[currentSong]?.timestamps.map(timestamp => {
            return (
              <FormWrapper style={{alignContent: "left", justifyContent:"left"}}><CustomButton onClick={() => {
                timeTravel(convertTimeToSeconds(timestamp?.time))
                }}>[{timestamp?.time}]</CustomButton> 
              - {timestamp?.label}</FormWrapper>
            )
          })}</FW>
        </ContentWrapper>
        </>
      )
      case "details":
        return (
          <ContentWrapper>
            <RecordPlayer
              onClick={validatePlay}
              stop={!isPlaying}
              albumArt={
                playlist[currentSong]?.albumArt
                ? playlist[currentSong]?.albumArt
                : playlist[currentSong]?.thumbnail ? playlist[currentSong]?.thumbnail :defaultImg
              }
              alt={playlist[currentSong]?.songName ? playlist[currentSong]?.songName : playlist[currentSong]?.episodeName}
            />

            {playlist?.length > 0 ? (
              <MusicInfo
                type={playlist[currentSong]?.songId ? CONTENT_TYPES.SONG : CONTENT_TYPES.EPISODE}
                content={playlist[currentSong]}
              />
            ) : (
              "No Songs Available"
            )}
          </ContentWrapper>
        );
      case "video":
        return (
        <>
          <VideoPlayer stopMainPlayer={stopMainPlayer} isPlaying={isPlaying} content={playlist[currentSong]} validateSubscription={true}/>
        </>)
      case "visuals":
        return <PlaylistWrapper>
          {{/* <VideoPlayer/> */}}
        </PlaylistWrapper>;
      default:
        return (
          <>
            <ContentWrapper>
              <RecordPlayer
                onClick={validatePlay}
                stop={!isPlaying}
                albumArt={
                  playlist[currentSong]?.albumArt
                    ? playlist[currentSong]?.albumArt
                    : playlist[currentSong]?.thumbnail ? playlist[currentSong]?.thumbnail :defaultImg
                }
                alt={playlist[currentSong]?.songName ? playlist[currentSong]?.songName : playlist[currentSong]?.episodeName}
              />
            </ContentWrapper>
            <PlaylistWrapper style={{height: "fit-content", marginBottom: "2%"}}>
              <AudioPlayerQueueList
                content={playlist}
                onButtonClick={setCurrentSong}
                type={CONTENT_TYPES.SONG}
                displayFullList={true}
              />
            </PlaylistWrapper>
          </>
        );
    }
  };
  return (
    <>
      <PlaylistVideoContainer isOpen={isOpen}>
      <PlaylistVideoContainerWrapper>
        <AudioVisualizer visual={playlist[currentSong]?.visualizer?.url}>
        <PlaylistHeaderContainer>
            <PlaylistHeader background={gravatarInfo?.profileBackground?.color}>
              <HeaderTextContainer>
                {playlist.length > 0 && (
                  <>
                    {isPlaying && (
                      <small style={{ fontStyle: "italics" }}>
                        Now Playing{" "}
                      </small>
                    )}
                    <HeaderText>
                      {playlist[currentSong]?.songId
                        ? getSongTitle(playlist[currentSong])
                        : playlist[currentSong]?.episodeId ? playlist[currentSong].episodeName :  ""}
                    </HeaderText>
                    <Text>
                      {playlist[currentSong]?.songId
                        ? getArtistsNames(
                            playlist[currentSong].artists,
                            ARTIST_SEARCH_TYPES.NAME
                          )
                        : playlist[currentSong]?.episodeId ? getPodcastersNames(
                          playlist[currentSong].podcasters,
                          PODCASTER_SEARCH_TYPES.NAME
                        ): ""}
                    </Text>
                  </>
                )}
              </HeaderTextContainer>
              <AlbumArtContainer>
                <AlbumArt
                  onClick={() => setTab("details")}
                  src={
                    playlist[currentSong]?.albumArt
                    ? playlist[currentSong]?.albumArt
                    : playlist[currentSong]?.thumbnail ? playlist[currentSong]?.thumbnail :defaultImg
                  }
                />
              </AlbumArtContainer>
              <CloseButton onClick={onClose} />
            </PlaylistHeader>
          </PlaylistHeaderContainer>
          <PlaylistNavContainer >
            <PlaylistNav>
              <PlaylistNavLink
                active={currentTab === "playlist"}
                onClick={() => setTab("playlist")}
              >
                Queue
              </PlaylistNavLink>
              {playlist[currentSong]?.lyrics && (
                <PlaylistNavLink
                  active={currentTab === "lyrics"}
                  onClick={() => setTab("lyrics")}
                >
                  {`${playlist[currentSong]?.songName} Lyrics`}
                </PlaylistNavLink>
              )}
              { playlist[currentSong]?.video && (
                <PlaylistNavLink
                  active={currentTab === "video"}
                  onClick={() => setTab("video")}
                >
                  {`Video`}
                </PlaylistNavLink>
              )}
              {playlist[currentSong]?.timestamps?.length > 0 && (
                <PlaylistNavLink
                  active={currentTab === "timestamps"}
                  onClick={() => setTab("timestamps")}
                >
                  {`${playlist[currentSong]?.episodeName } timestamps`}
                </PlaylistNavLink>
              )}
              <PlaylistNavLink
                active={currentTab === "details"}
                onClick={() => setTab("details")}
              >
                {playlist[currentSong]?.episodeName ? 'Episode' : playlist[currentSong]?.songName} Details
              </PlaylistNavLink>
            </PlaylistNav>
          </PlaylistNavContainer >
          <Zoom>{displayContent()}</Zoom>
          
        </AudioVisualizer>
        <PlaylistFooter/>
       </PlaylistVideoContainerWrapper>
      </PlaylistVideoContainer>
    </>
  );

}

export default AudioPlayerPlaylist;
