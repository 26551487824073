import React from 'react'
import ImageUtils from '../../utils/ImageUtils'
import { getNFTImage } from '../../utils/nfTokensUploads'
import AlbumArt from '../AlbumArt'
import ContentWrapper from '../ContentWrapper'
import { FormText, FormWrapper } from '../CustomForm/CustomFormElements'
import { Header } from '../Header'
import { HoverOption } from '../MenuOptions/MenuOptionsElements'
import { AUDIO_EXPORT_TYPES, CONTENT_TYPES } from '../../utils/Enum'

const NFTokenDownload = ({token, download}) => {
    const { defaultAlbumArt, NftBadge } = ImageUtils();

  return (
    <>
    <Header title={token.collection?.name
                    ? token.collection?.name
                    : token?.nftoken?.metadata?.name
                    ? token?.nftoken?.metadata?.name
                    : token?.nftoken?.metadata?.collection?.name
                    ? token?.nftoken?.metadata?.collection?.name
                    : token?.nftoken?.NFTokenID}/>
    <ContentWrapper subheading={"Download NFToken Collection"}>
            
            <FormWrapper><FormText>
              {`Download ${token?.nftoken?.metadata?.name} `}
            </FormText>
              <div style={{cursor: "pointer"}}><AlbumArt
                size={"25rem"}
                albumArt={token?.collection
                    ? token?.collection?.cover?.url
                    : token?.nftoken?.metadata?.image
                    ? getNFTImage(token?.nftoken?.metadata?.image)
                    : defaultAlbumArt}
                alt={token?.nftoken?.metadata?.name}
                
              /></div>
              <HoverOption onClick={() => download(token, CONTENT_TYPES.ALBUM, token?.nftoken?.NFTokenID, AUDIO_EXPORT_TYPES.MP3 )}>Download NFToken Collection</HoverOption>
            </FormWrapper>
          </ContentWrapper>
    </>
  )
}

export default NFTokenDownload