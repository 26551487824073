import React, { useState } from 'react'
import Chip from '../Chip';
import { FaSearch, FaUserPlus } from "react-icons/fa";
import { Img } from '../Chip/ChipElements';
import { ButtonWrapper, ContentListButton, ContentListContainer, DetailsContainer, DetailsText, ImageWrapper, Table, TableData, TableHeading, TableRow } from '../ContentList/ContentListElements';
import { DEFAULT_IMAGE } from '../GravatarAPI';
import { hexToRgba } from '../HexToRGBA';
import { Theme } from '../WebsiteThemeElements';
import { ErrorWrapper, FormInput, FormLabel, FormWrapper, ListContainer, MusicUploadsButton, SongDetailsSection, SongDetailsSectionTitle } from './MusicUploadsFormElements';
import { ARTIST_SEARCH_TYPES, ARTIST_FORM_INPUT_TYPES, ARTIST_FORM_TYPES } from '../../utils/Enum'
import { SongDetailsText } from '../MusicPage/MusicPageElements';
import { Nav, NavContainer, NavLink } from '../NavLink/NavLinkElements';
import { useModal } from '../Modal/ModalContext';


function ArtistForm( 
    {artists, 
    removeArtist, 
    clearArtistForm, 
    setCurrentArtist, 
    setArtistEditing,
    handleInput,
    currentArtist,
    walletAddressRef,
    artistSearch,
    artistNameRef,
    artistFullNameRef,
    artistPayoutRef,
    artistIdRef,
    artistSearchRef,
    selectArtist,
    searchInput,
    validateArtistInfo,
    artistEditng,
    title,
    type}) {

        const [option, setOption] = useState()
        const [searchType, setSearchType] = useState(ARTIST_SEARCH_TYPES.NAME)
        const theme = Theme({})
        const {openModal, modalContent, closeModal} = useModal()

        const setArtistOption = (opt) =>{
            if(opt !== option){
                setOption(opt)
                setCurrentArtist()
            }else{
            setOption() 
        }
        setArtistEditing(false);
        }

        const setSearch = (type) => {
            setSearchType(type)
        }

        const displaySearch = () => {
            return (<FormWrapper>
                
                     <ContentListContainer >
                     <>
                    <Table>
                        <TableRow>
                            <TableHeading>

                            </TableHeading>
                            <TableHeading>
                                </TableHeading>
                            <TableHeading>
                            </TableHeading>
                                
                        </TableRow>
                    {artistSearch?.artists.map((artist, index) => {
                        return(
                        <>
                            <TableRow key={index} id={artist.artistId} onClick={() => {
                              selectArtist(type, artist)
                              if(type === ARTIST_FORM_TYPES.PAYOUT){
                                setArtistEditing(true);
                                setCurrentArtist({ ...artist, id: artists?.length - 1 });
                                setOption("update_view")
                              }
                               }}>
                        <TableData>
                            <Img isCircular={true} src={artist?.photoUrl ? artist.photoUrl : DEFAULT_IMAGE(50)}  alt={artist?.artistName} />
                        </TableData>
                        <TableData>
                            <DetailsContainer>
                                <DetailsText>{artist.artistName}</DetailsText>
                            </DetailsContainer>
                        </TableData>
        
                    </TableRow></>)
                    })}
                    </Table>
                    </>
                    </ContentListContainer>
             </FormWrapper>)
        }


        const displayArtistDetails = () => {
          return (<>
          <FormWrapper>
            <FormLabel>Artist Name</FormLabel>
            <FormInput
              ref={artistNameRef}
              name={ARTIST_FORM_INPUT_TYPES.NAME}
              readOnly={currentArtist?.exists || type === ARTIST_FORM_TYPES.PAYOUT}
              value={currentArtist?.artistName}
              onChange={(e) => 
                handleInput(e,type)
              }
            />
          </FormWrapper>
          {currentArtist?.errors?.name && <SongDetailsText>{currentArtist.errors.name}</SongDetailsText>}
          {(currentArtist?.fullName || !currentArtist.exists) &&  <FormWrapper>
            <FormLabel>Full Name</FormLabel>
            <FormInput
              ref={artistFullNameRef}
              name={ARTIST_FORM_INPUT_TYPES.FULL_NAME}
              readOnly={currentArtist?.exists || type === ARTIST_FORM_TYPES.PAYOUT}
              value={currentArtist?.fullName}
              onChange={(e) => 
                handleInput(e,type)
              }
            />
          </FormWrapper>}
          {currentArtist?.errors?.fullName && <SongDetailsText>{currentArtist.errors.fullName}</SongDetailsText>}
          {currentArtist?.exists === true && currentArtist?.artistId && <FormWrapper>
            <FormLabel>Artist ID</FormLabel>
            <FormInput
              ref={artistIdRef}
              name={ARTIST_FORM_INPUT_TYPES.ID}
              readOnly={currentArtist?.exists || type === ARTIST_FORM_TYPES.PAYOUT}                
              value={currentArtist?.artistId}
              onChange={(e) => handleInput(e,type)}
            />
          </FormWrapper>}
          {(currentArtist?.address || !currentArtist.exists) && <FormWrapper>
            <FormLabel>Wallet Address</FormLabel>
            <FormInput
              ref={walletAddressRef}
              name={ARTIST_FORM_INPUT_TYPES.ADDRESS}
              readOnly={currentArtist.exists || type === ARTIST_FORM_TYPES.PAYOUT}
              value={currentArtist?.address}
              onChange={(e) => 
                  handleInput(e,type)
                }
            />
          </FormWrapper>}
          {currentArtist?.errors?.address && <SongDetailsText>{currentArtist.errors.address}</SongDetailsText>}
          {type === ARTIST_FORM_TYPES.PAYOUT &&
          <FormWrapper>
          <FormLabel>Payout Percentage</FormLabel>
          <ErrorWrapper>
          <FormInput
            ref={artistPayoutRef}
            name={ARTIST_FORM_INPUT_TYPES.PAYOUT_PERCENTAGE}
            min={0}
            max={100}
            value={currentArtist?.payoutPercentage}
            onChange={(e) => 
                handleInput(e,type)
              }
          />
          {currentArtist?.errors?.payoutPercentage && <SongDetailsText error>{currentArtist.errors.payoutPercentage}</SongDetailsText>}
                  </ErrorWrapper>
        </FormWrapper>}
           
           {(!currentArtist?.exists  || type === ARTIST_FORM_TYPES.PAYOUT) ?  <>
           <MusicUploadsButton
              backgroundColor={`linear-gradient(to left, ${hexToRgba(theme.accentColor, 0.5) },${hexToRgba(theme.mainColor, 0.7) });`}
              onClick={() => {
                validateArtistInfo(currentArtist?.id, type)
                setOption()
              }}
            >
              {artistEditng ? `Update` : `Add`}
            </MusicUploadsButton>
            <MusicUploadsButton
              onClick={() => {
                setOption()
                clearArtistForm(type)
              }}
            >
              Cancel
            </MusicUploadsButton></> : 
            
            <MusicUploadsButton
              onClick={() => {
                setOption()
                clearArtistForm(type)
              }}
            >
              Close
            </MusicUploadsButton> }</>
      )
        }


  if(type === ARTIST_FORM_TYPES.PAYOUT)
  {
    return(<SongDetailsSection>
      <SongDetailsSectionTitle>{title}</SongDetailsSectionTitle>
      <FormWrapper>
      <ListContainer>
        <>
          {artists &&
            artists.map((artist, index) => {
              return (
                <Chip
                  id={index}
                  image={artist.photoUrl ? artist.photoUrl : DEFAULT_IMAGE(1000)}
                  label={artist.artistName}
                  closeButton
                  background={() => (artist.payoutPercentage === 0 || artist.payoutPercentage > 100 ) ? theme.errorColor: 'transparent'}
                  onDelete={() => {
                    removeArtist(index, type);
                    clearArtistForm(type);
                  }}
                  onChipClick={() => {
                    setCurrentArtist({ ...artist, id: index });
                    setArtistEditing(true);
                    setOption("update_view")
                  }}
                />
              );
            })}
        </>
        <Chip
          icon={<FaUserPlus/>}
          onChipClick={() => {
              setArtistOption("addNew")
          }}
          textDecoration={"underline"}
          background={"transparent"}
          label={"Select Artist"}
        />
      </ListContainer>
      </FormWrapper>
            {option === "addNew" && <><FormWrapper>
              <FormInput
                ref={artistSearchRef}
                name={searchType}
                placeholder={`Search for ${searchType}`}
                onChange={(e) => {
                    searchInput(e,type)
                  handleInput(e, type)}
                }
              />
            </FormWrapper>
            <FormWrapper>
              <Nav>
                <NavContainer>
                  <NavLink active={searchType === ARTIST_SEARCH_TYPES.NAME} onClick={() => setSearch(ARTIST_SEARCH_TYPES.NAME)}>Artist Name</NavLink>
                  <NavLink active={searchType === ARTIST_SEARCH_TYPES.FULL_NAME} onClick={() => setSearch(ARTIST_SEARCH_TYPES.FULL_NAME)}>Full Name</NavLink>
                  <NavLink active={searchType === ARTIST_SEARCH_TYPES.ID} onClick={() => setSearch(ARTIST_SEARCH_TYPES.ID)}>Artist ID</NavLink>
                  <NavLink active={searchType === ARTIST_SEARCH_TYPES.ADDRESS} onClick={() => setSearch(ARTIST_SEARCH_TYPES.ADDRESS)}>Wallet Address</NavLink>
                </NavContainer>
              </Nav>
            </FormWrapper>
            {artistSearch?.artists.length > 0 &&
               <FormWrapper> {displaySearch()}</FormWrapper> 
            }</>}
            {option === "update_view" 
            && displayArtistDetails()}
            
            
            
      </SongDetailsSection>)
  }else
  {
    return (
    
     <SongDetailsSection>
            <SongDetailsSectionTitle>{title}</SongDetailsSectionTitle>
            <ListContainer style={{marginBottom: "2%"}}>
              <>
                {artists &&
                  artists.map((artist, index) => {
                    return (
                      <Chip
                        key={index}
                        id={index}
                        image={artist.photoUrl ? artist.photoUrl : DEFAULT_IMAGE(1000)}
                        label={artist.artistName}
                        closeButton
                        onDelete={() => {
                          removeArtist(index, type);
                          clearArtistForm(type);
                        }}
                        onChipClick={() => {
                          setCurrentArtist({ ...artist, id: index });
                          setOption("update_view")
                          setArtistEditing(true);
                        }}
                      />
                    );
                  })}
              </>
              </ListContainer>
              <Nav style={{marginBottom: "2%"}}>
                <NavContainer>
              <NavLink
                
                onClick={() => {
                    setArtistOption("addNew")
                  
                }}
                active={option === "addNew"}
               
              >Add New <FaUserPlus/></NavLink>
              <NavLink
                
                onClick={() => {
                    setArtistOption("searchExisting")
                }}
                active={option === "searchExisting" }
                label={"Search Existing"}
              ><FaSearch/> Search Existing</NavLink>
              </NavContainer></Nav>
            
            
            {option === 'addNew' && <>
            <FormWrapper>
              <FormLabel>Artist Name</FormLabel>
              <ErrorWrapper>
              <FormInput
                ref={artistNameRef}
                name={ARTIST_FORM_INPUT_TYPES.NAME}
                readOnly={currentArtist?.exists}
                value={currentArtist?.artistName}
                onChange={(e) => 
                  handleInput(e,type)
                }
              />{currentArtist?.errors?.name && <SongDetailsText>{currentArtist.errors.name}</SongDetailsText>}
            </ErrorWrapper>
            </FormWrapper>
            
            <FormWrapper>
              <FormLabel>Full Name</FormLabel>
              <ErrorWrapper>
              <FormInput
                ref={artistFullNameRef}
                name={ARTIST_FORM_INPUT_TYPES.FULL_NAME}
                readOnly={currentArtist?.exists}                
                value={currentArtist?.fullName}
                onChange={(e) => 
                  handleInput(e,type)
                }
              />
              {currentArtist?.errors?.fullName && 
              <SongDetailsText error>{currentArtist.errors.fullName}</SongDetailsText>}
                  </ErrorWrapper>
            </FormWrapper>            
            <FormWrapper>
              <FormLabel>Wallet Address</FormLabel>
              <ErrorWrapper>
              <FormInput
                ref={walletAddressRef}
                name={ARTIST_FORM_INPUT_TYPES.ADDRESS}
                readOnly={currentArtist?.exists}
                value={currentArtist?.address}
                onChange={(e) => 
                    handleInput(e,type)
                  }
              />
              {currentArtist?.errors?.address && <SongDetailsText error>{currentArtist.errors.address}</SongDetailsText>}
                  </ErrorWrapper>
            </FormWrapper>              <MusicUploadsButton
                backgroundColor={`linear-gradient(to left, ${hexToRgba(theme.accentColor, 0.5) },${hexToRgba(theme.mainColor, 0.7) });`}
                onClick={() => {
                  validateArtistInfo(currentArtist?.id, type);
                }}
              >
                {artistEditng ? `Update` : `Add`}
              </MusicUploadsButton>
              <MusicUploadsButton
                onClick={() => {
                  clearArtistForm(type)
                  setOption()
                }}
              >
                Cancel
              </MusicUploadsButton></>}
              {option === "searchExisting" && <>
            <FormWrapper>
              <FormInput
                ref={artistSearchRef}
                name={searchType}
                placeholder={`Search for ${searchType}`}
                onClick={() => {
                  /* openModal()
                  modalContent(<ArtistSearchModal type={searchType} existingArtists={artists} onClick={(artist) => {
                    closeModal()
                    modalContent()
                  selectArtist(type, artist)
                  if(type === ARTIST_FORM_TYPES.PAYOUT){
                    setArtistEditing(true);
                    setCurrentArtist({ ...artist, id: artists?.length - 1 });
                    setOption("update_view")
                  }
                   }}/>) */}}
                onChange={(e) => {
                    searchInput(e,type)
                  handleInput(e, type)}
                }
              />
            </FormWrapper>
            <FormWrapper>
             <Nav style={{marginBottom: "2%"}}>
                <NavContainer>
                  <NavLink active={searchType === ARTIST_SEARCH_TYPES.NAME} onClick={() => setSearch(ARTIST_SEARCH_TYPES.NAME)}>Artist Name</NavLink>
                  <NavLink active={searchType === ARTIST_SEARCH_TYPES.FULL_NAME} onClick={() => setSearch(ARTIST_SEARCH_TYPES.FULL_NAME)}>Full Name</NavLink>
                  <NavLink active={searchType === ARTIST_SEARCH_TYPES.ID} onClick={() => setSearch(ARTIST_SEARCH_TYPES.ID)}>Artist ID</NavLink>
                  <NavLink active={searchType === ARTIST_SEARCH_TYPES.ADDRESS} onClick={() => setSearch(ARTIST_SEARCH_TYPES.ADDRESS)}>Wallet Address</NavLink>
                </NavContainer>
              </Nav>
            </FormWrapper>
            {artistSearch?.artists.length > 0 &&
               <FormWrapper> {displaySearch()}</FormWrapper>
                
            }
            </>}


            {currentArtist && option === "update_view" &&
              displayArtistDetails()
            }
              </SongDetailsSection>
  )}
}

export default ArtistForm