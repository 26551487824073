import { collection, onSnapshot, query, where } from "firebase/firestore"
import { db } from "../components/Authorization/firebase"

export const getReplies = (comments, id) => {
    return comments?.filter(comment => comment?.parentId === id)
}

export const getCommentLikes = (id, setLikes) => {
    let q = query(collection(db,"/likes"), where('commentId', '==', id))
    onSnapshot(q, (likes) => {
        if(likes.empty){
            setLikes([])
            return
        }else{
            setLikes(likes.docs.map(like => {
                return {
                    likeId: like.id,
                    ...like.data()
                }
            }))
        }
    })
}
