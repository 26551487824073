import styled from "styled-components";


export const RibbonWrapper = styled.div`
width: 150px;
height: 150px;
overflow: hidden;
positive: absolute;

&:before{
    position: absolute;
    z-index: -1;
    content:'';
    display: block;
    border: 5px solid #2980b9;
}
&:after{
    position: absolute;
    z-index: -1;
    content:'';
    display: block;
    border: 5px solid #2980b9;
}
`
export const RibbonSpan = styled.span`
position: absolute;
display: block;
width: 225px;
padding: 15px 0;
background-color:#3498db;
box-shadowL 0 5px 10px rgba(0,0,0,0.1);
color: #fff;
text-shadow: 0 1px 1px rgba(0,0,0,0.2);
text-transform: uppercase;
text-align: center;

`

export const RibbonTopLeft = styled.div`
top: -10px;
left:-10px;


&:before{
    border-top-color: transparent;
    border-left-color: transparent;
    top: 0;
    right: 0;
}
&:after{
    border-top-color: transparent;
    border-left-color: transparent;
    bottom: 0;
    left: 0;
}
`

export const RibbonTopLeftSpan = styled.span`
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);


`