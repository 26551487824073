import styled from "styled-components";
import {FaInfo} from 'react-icons/fa'
import { hexToRgba } from "../HexToRGBA";


export const FormContainer = styled.div`
    display: flex;

`

export const CCForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

`

export const CCList = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    list-style-type: none; 
    padding: 2%; 
    margin: 0;
`

export const CCFormWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

`
export const CCInfo = styled.div`
    display: flex;
    font-size: 0.6rem;
    align-items: center;
    font-style: italic;
`

export const CCIconsWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;

`

export const CCLink = styled.a`
    display: flex;
    padding: 2%;
    cursor: pointer;
    color: ${props => props.theme.accentColor};

`

export const CCInfoIcon = styled(FaInfo)`
    display: flex;
    color: ${props => props.theme.accentColor};
    font-size: 0.7rem;
    margin-left: 3%;

    &:hover{
        transition: all 0.3s ease-in-out;
        transform: scale(1.2);
        color: ${(props) => props.theme.secondaryColor};

    }

`