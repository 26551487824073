import React from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useModal } from '../Modal/ModalContext'
import { useUser } from '../../hooks/useUser'
import useMusic from '../../hooks/useMusic'
import { downloadFiles, downloadTokenGateFiles } from '../../utils/FileUploadsUtils'
import { ALERT_TYPES, TRANSACTION_OPTIONS } from '../../utils/Enum'
import { useAlert } from '../Alert/AlertContext'
import OptionModal from '../OptionModal'
import LoadingScreen, { ModalLoadingScreen } from '../LoadingScreen'
import { HoverOption } from '../MenuOptions/MenuOptionsElements'
import QRCodeForm from '../QRCodeForm'

const TokenGatedContentDownloader = ({collection}) => {
    const {currentUser} = useAuth()
    const {addAlert} = useAlert()
    const {modalContent, openModal, closeModal} = useModal()
    const {downloadTokenGatedContent} = useMusic()
    const {getNFTokens, validateUserNFTokenOwnerShip} = useUser({})

    const onSignInSuccess = (address) => {
        //console.log(address);
        getTokenGatedContent(address)
      };
    
      const onSignInFail = () => {
        closeModal();
        modalContent();
      };
    

    const validateUser = () => {
        if(collection?.additionalFiles && collection?.collectionId)
        {
        if(currentUser?.uid){
            return getTokenGatedContent(currentUser?.uid)
        }else{
            return (
                <QRCodeForm
            heading={"NFToken Gated Files Download Sign In"}
            onSuccess={onSignInSuccess}
            onFail={onSignInFail}
            cancelQr={() => {
              closeModal();
              modalContent();
            }}
            type={TRANSACTION_OPTIONS.GET_MUSIC_LIBRARY}
            body={{
              memo: "User is signing transaction to retreive music library",
              sender: currentUser?.uid,
              instruction: "User is signing transaction to retreive music library",
            }}
          />
            )
        }
    
        }else{
            closeModal()
        }
        return
    }

    const getTokenGatedContent = async (uid) => {
        openModal()
        modalContent(<OptionModal heading={'Please Wait'} >
            <ModalLoadingScreen text={'Validating User NFTokens'} transparent />
        </OptionModal>)
        return await validateUserNFTokenOwnerShip(uid, collection.issuer, collection?.mintInfo?.taxon).then(async (isOwner) => {
          //console.log(isOwner)
            if (isOwner) {
              //let content = await downloadTokenGatedContent(collection?.collectionId);
             return await downloadTokenGateFiles(collection?.additionalFiles).then(() => {
                modalContent(<OptionModal heading={'Token gated files will be downloaded soon'}>
                <HoverOption onClick={() => {closeModal()}}>OK</HoverOption>
            </OptionModal>)
              addAlert({
                title: "NFToken Gated Files Download",
                type: ALERT_TYPES.SUCCESS,
                message: `Downloading Token Gated Files from Collection`,
              });
              return;
              }).catch(err => {
                console.error(err)
                addAlert({
                  title: "NFToken Gated Files Download",
                  type: ALERT_TYPES.DANGER,
                  message: `Access Denied`,
                });
                return
              })
              
            }
            modalContent(<OptionModal heading={'You do not have access to files'} additionalText={'Purchase NFtoken from Collection to gain access'}>
                <HoverOption onClick={() => {closeModal()}}>OK</HoverOption>
            </OptionModal>)
addAlert({
    title: "NFToken Gated Files Download",
    type: ALERT_TYPES.DANGER,
    message: `Access Denied`,
  });
          })
          .catch((err) => {
            console.error(err);
            addAlert({
                title: "NFToken Gated Files Download",
                type: ALERT_TYPES.DANGER,
                message: `Something went wrong. Try again`,
              });
              closeModal()
            return;
          });
        }
    


  return (
    <>
        {validateUser()}
    </>
  )
}

export default TokenGatedContentDownloader