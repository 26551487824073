import { VIDEO_PLAYER_ACTIONS } from '../../../utils/Enum';

const VideoReducer = (state, action) => {
  switch (action.type) {
    case VIDEO_PLAYER_ACTIONS.TOGGLE_PLAY:
        return {...state, 
            isPlaying: action.data,
        }
        case VIDEO_PLAYER_ACTIONS.SET_PLAYLIST:
            return {...state, 
                playlist: action.data,
            }
    case VIDEO_PLAYER_ACTIONS.SET_CURRENT_VIDEO:
        return {...state, 
            currentVideo: action.data,
        }
    case VIDEO_PLAYER_ACTIONS.VOLUME_CHANGE:
        return {...state, 
            volume: action.data,
        }
    case VIDEO_PLAYER_ACTIONS.TOGGLE_MUTE:
        return {...state, 
            isMute: action.data,
        }
    case VIDEO_PLAYER_ACTIONS.TOGGLE_PLAYBACK_SPEED:
        return {...state, 
            playback: action.data,
        }
    case VIDEO_PLAYER_ACTIONS.TOGGLE_NORMAL_SCREEN_MODE:
        return {...state, 
            isPictureInPicture: false,
            isFullScreen: false,
            isTheatreMode: false,
        }
    case VIDEO_PLAYER_ACTIONS.TOGGLE_FULL_SCREEN_MODE:
        return {...state, 
            isPictureInPicture: false,
            isFullScreen: action.data,
            isTheatreMode: false,
        }
    case VIDEO_PLAYER_ACTIONS.TOGGLE_THEATRE_MODE:
        return {...state, 
            isPictureInPicture: false,
            isFullScreen: false,
            isTheatreMode: action.data,
        }
    case VIDEO_PLAYER_ACTIONS.TOGGLE_PIP_MODE:
        return {...state, 
            isPictureInPicture: action.data,
            isFullScreen: false,
            isTheatreMode: false,
        }
        case VIDEO_PLAYER_ACTIONS.UPDATE_PLAY_ID:
      //inc number of plays
      return {
        ...state,
        playId: action.data
      };
    case VIDEO_PLAYER_ACTIONS.UPDATE_PLAY_TYPE:
      //inc number of plays
      return {
        ...state,
        playType: action.data
      };
  
    default:
        break;
  }
}

export default VideoReducer