import React from 'react'
import SongStats from './SongStats'
import AlbumStats from './AlbumStats'
import EpisodeStats from './EpisodeStats'

const RoyaltyShareStats = ({royalty}) => {
  return (
    <>
    {royalty?.type === 'album' && <AlbumStats royalty={royalty} albumId={royalty?.albumId}/>}
    {royalty?.type === 'song' && <SongStats royalty={royalty} songId={royalty?.songId}/>}
    {royalty?.type === 'episode' && <EpisodeStats royalty={royalty} episodeId={royalty?.episodeId}/>}
    
    </>
  )
}

export default RoyaltyShareStats