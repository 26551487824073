import React, { useState } from 'react'
import { AccordianBody, AccordianContainer, AccordianHeader, Container, Title } from './FrequentlyAskedQuestionsElements'
import { Questions } from './Data'
import { Fade } from 'react-reveal'
import Footer from '../Footer'

const FrequentlyAskedQuestions = () => {
    const [openQuestions, setOpenQuestions] = useState([])
    const questions = Questions

    const openQuestion = (i) => {
      //console.log("open", i);
      setOpenQuestions((prev) => {
        if (prev.includes(i)) {
          return prev.filter(q => q !== i);
        } else {
          return [...prev, i];
        }
      });
    };
  
  return (
    <>
    <Container>
        <Title>Frequently Asked Questions</Title>
        
            <>{questions.map(( q, i) => {
                return (
                <Fade left><AccordianContainer key={i}>
                <AccordianHeader isOpen={openQuestions.includes(i)} onClick={() => openQuestion(i)}>
                {q.question}
            </AccordianHeader>
          <AccordianBody isOpen={openQuestions.includes(i) } >
                {q.answer}
            </AccordianBody>
            </AccordianContainer></Fade>
            )})}</>
        

    </Container>
    
    </>
  )
}

export default FrequentlyAskedQuestions