import { VIDEO_RES } from "../Enum";

export const getVideoResolutions = (original, otherResolutions) => {
  return [
    {
      resolution: getOriginalVideoResolution(otherResolutions[0]?.resolution),
      url: original?.url,
      videoRef: original?.ref,
    },...otherResolutions
  ];
};

export const getOriginalVideoResolution = (resolution) => {
  switch (resolution) {
    case VIDEO_RES.P_2160:
      return VIDEO_RES.P_4320;
    case VIDEO_RES.P_1440:
      return VIDEO_RES.P_2160;
    case VIDEO_RES.P_1080:
      return VIDEO_RES.P_1440;
    case VIDEO_RES.P_720:
      return VIDEO_RES.P_1080;
    case VIDEO_RES.P_480:
      return VIDEO_RES.P_720;
    case VIDEO_RES.P_360:
      return VIDEO_RES.P_480;
    case VIDEO_RES.P_240:
      return VIDEO_RES.P_360;

    default:
      break;
  }
};
