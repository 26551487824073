import React, { useContext, useEffect, useState } from 'react'
import { MdSentimentDissatisfied } from 'react-icons/md';
import useMusic from '../../hooks/useMusic';


const useComments = ({type, id}) => {
    const [isLoaded,setLoaded] = useState(false)
    const [comments, setComments] = useState([])
    const {getMusicComments} = useMusic()
    const [error, setError] = useState()
    
    useEffect(() => {
      if(!isLoaded){
    getMusicComments(type, id)
        .then((data) => {
            if(data){
                setComments(data)
                setLoaded(true)
                return
            }
            return
        }).catch(err => {
            console.error(err)
            setComments([])
            setLoaded(true)
            return
        })
      }
      return () => {}
    }, [type, id])


    const getComments = (type, id) => {
      setLoaded(false)
      return getMusicComments(type, id)
      .then((data) => {
          //console.log(data)
          if(data){
              setComments(data)
              setLoaded(true)
              return
          }
          return
      }).catch(err => {
          console.error(err)
          setLoaded(true)
      })
    }



  return {
    isLoaded,
    comments,
    error,
    setLoaded,
    setComments,
    getMusicComments,
    getComments,

  }
}

export default useComments

