import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import { FaBars, FaSearch, FaTimes, FaBell, FaFileUpload } from "react-icons/fa";
import {FiFilePlus} from "react-icons/fi"
import {HiUpload} from "react-icons/hi"
import {FaUpload} from "react-icons/fa"
import {MdUnarchive} from "react-icons/md"
import logo from "../../images/sonar_muse_logo_nav.png";
import invertedLogo from "../../images/sonar_muse_logo_nav_invert.png";
import { hexToRgba, lightOrDark } from "../HexToRGBA";
import uploadButton from "../../images/sonar_muse_upload_icon_white.png"
import { BsCollectionPlayFill } from "react-icons/bs";
import { RiPlayListFill } from "react-icons/ri";

export const MEDIA_SCREEN = { //Screen sizes for devices
  MOBILE: '480',
  TABLETS: '768',
  LAPTOPS: '1024',
  DESKTOPS: '1025',
  TV: '1500'
}

export const Nav = styled.nav`
  background-color: ${(props) =>
    props.scrollNav ? hexToRgba(props.theme.secondaryColor, 0.7) : props.theme.mainBg};
  height: 8vh;
  max-width: 100%;
  display: none;
  align-content: flex-start;
  align-items: center;
  justify-content: space-evenly;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(8px);
  position: sticky;
  padding: 2% 6%;
  top: 0;
  z-index: 20;
  transition: all 0.2s ease-in-out;
  color: ${(props) =>
    props.scrollNav ?  props.theme.secondaryBg: props.theme.mainBg};
    
  @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    display: flex;
    transition: 0.8s all ease;
  }
  @media screen and (min-width: ${MEDIA_SCREEN.DESKTOPS}px) {
    display: none;
    transition: 0.8s all ease;
  }
  @media screen and (min-width: ${MEDIA_SCREEN.TV}px) {
    display: none;
    transition: 0.8s all ease;
  }
  
`;

export const NavLink = styled(Link)`
color: ${(props) => props.scrollNav ? props.theme.mainBg: props.isActive ? ((lightOrDark(props.theme.mainColor) === 'light' && lightOrDark(props.theme.mainBg) === 'dark') || (lightOrDark(props.theme.mainColor) === 'dark' && lightOrDark(props.theme.mainBg) === 'light'))  ? props.theme.mainColor : props.theme.secondaryBg  :  props.theme.accentColor}; 
display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 2% 1rem;
  font-size: 0.8rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    background: ${(props) => props.theme.mainColor};
  }
`;
export const NavLinkA = styled.a`
  color: ${(props) => props.scrollNav ? props.theme.mainBg: props.theme.accentColor}; 
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  padding: 2%  1rem;
  font-size: 0.8rem;
  height: 100%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  
  &:active {
    border-bottom: 3px solid ${(props) => props.theme.mainColor};
  }

  &:hover {
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    background: ${(props) => props.theme.mainColor};
  }
`;

export const NavContainer = styled.div`
  display: flex;
  margin-top: 0;
  align-items: center;
  justify-content: space-evenly;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
 
`;

export const NavLogo = styled(Link)`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1%;
  height: 2.1rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 700;
  
  &:hover{
    transform: scale(1.07);
    transition: 1s ease-out;
}
`;

export const Img = styled.img`
  border-radius: 50%;
`;

export const NavImg = styled.img`
  src: ${(scrollNav) => (scrollNav ? invertedLogo : logo)};
  height: 100%;
  position: relative;
  &:hover {
    transform: rotateY(360deg);
    transition: 1s ease-out;
  }
`;

export const Bars = styled(FaBars)`
    display: none;

    @media screen and (max-width: 768px){
        display: flex;
        position: relative;
        margin-left: 6%;
        font-size: 1.5rem;
        cursor: pointer;
        color: ${(props) =>
          props.scrollNav ? props.theme.mainBg : props.theme.secondaryColor};

          &:hover{
            transform: scale(1.07);
            transition: 1s ease-out;
        }

`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  padding: 2% 2%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const SearchContainer =styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  justify-items: flex-end;
  justify-self: center;
  transition: all 0.2s;
  font-size: 1.2rem;
  flex: 1 1 50%;
  padding: 2% 2%;
`

export const SearchBtn = styled(FaSearch)`
  display: ${props => (props.toggle? 'none' : 'flex')};
  cursor: pointer;
  justify-content: flex-end;
   color: ${(props) =>
    props.scrollNav ? props.theme.mainBg : props.theme.secondaryBg};
  transition: all 0.2s;
  &:hover {
    transform: scale(1.07);
    transition: 1s ease-out;
    color: ${(props) => props.theme.mainColor};
  }
 
  @media screen and (max-width: 768px) {
    font-size: 1rem;    
    
  }

`
export const NotificationButton = styled(FaBell)`
  display: flex;
  cursor:pointer;
  color: ${(props) =>
    props.scrollNav ? props.theme.mainBg : props.theme.secondaryBg};
  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.2)
  }

`

export const MusicUploadsButton = styled(FaFileUpload)`
display: flex;
padding: 2px;
font-size: 1.5rem;
color: ${(props) =>
  props.scrollNav ? props.theme.mainBg : props.theme.secondaryColor};
cursor:pointer;
  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.2)
  }

`
export const NFTokenCollectionButton = styled(BsCollectionPlayFill)`
display: flex;
padding: 2px;
font-size: 1.5rem;
color: ${(props) =>
  props.scrollNav ? props.theme.mainBg : props.theme.secondaryColor};
cursor:pointer;
  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.2)
  }

`
export const PlaylistBtn = styled(RiPlayListFill)`
display: flex;
padding: 2px;
font-size: 1.5rem;
color: ${(props) =>
  props.scrollNav ? props.theme.mainBg : props.theme.secondaryColor};
cursor:pointer;
  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.2)
  }

`


export const CloseIcon = styled(FaTimes)`
display: ${props => (props.toggle? 'flex' : 'none')};
font-size: 1.3rem;
margin-left: 1%;
position: relative;

@media screen and (max-width: 768px) {
  font-size: 2rem;
  margin-left: 3%;
  padding: 0 1%;
}
`

export const NavBtnLink = styled(Link)`
  display: flex;
  white-space: nowrap;
  border-radius: 50px;
  font-size: 0.8rem;
  background: ${(props) =>
    props.scrollNav ? props.theme.secondaryBg : props.theme.mainColor};
    padding: 2% 1rem;
    color: ${(props) =>
    props.scrollNav ? props.theme.mainColor : props.theme.secondaryBg};
  border: 2px solid ${(props) => props.theme.mainColor};
  outline: none;
  cursor: pointer;
  padding: 5%  1rem;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 5%;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${(props) => props.theme.secondaryBg};
    border: 2px solid ${(props) => props.theme.secondaryBg};
    color: ${(props) => props.theme.mainColor};
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
`;
