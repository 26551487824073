import React, { useEffect } from "react";
import { Button } from "../ButtonElement";
import { Fade, Zoom } from "react-reveal"
import { animateScroll as scroll } from 'react-scroll'
import {
  InfoContainer,
  InfoWrapper,
  Column1,
  Column2,
  InfoRow,
  ImgWrap,
  Img,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
} from "./InfoElements";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  headLine,
  description,
  buttonLabel,
  lightText,
  darkText,
  img,
  alt,
  primary,
  dark,
  dark2,
  isCircular,
  to,
}) => {

useEffect(() => {
  return () => {}
  
}, [])

const toggleHome = () => {
  scroll.scrollToTop();
}

  return (
    <>
        <InfoContainer lightBg={lightBg} id={id}>
          <InfoWrapper>
            
            <InfoRow right imgStart={imgStart}>
            
              <Column1><Fade left>
                <TextWrapper>
                  <TopLine>{topLine}</TopLine>
                  <Heading lightText={lightText}>{headLine}</Heading>
                  <Subtitle darkText={darkText}>{description}</Subtitle>
                  <BtnWrap>{ buttonLabel &&
                      <Button to={to}
                      onClick={toggleHome}
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}>
                        {buttonLabel}
                      </Button>}
                  </BtnWrap>
                </TextWrapper>
                </Fade>
              </Column1>
              
              
              <Column2>
              <Fade left>
                <ImgWrap>
                  <Img onContextMenu={(e) => {e.preventDefault()}} src={img} alt={alt} isCircular={isCircular} />
                </ImgWrap>
               </Fade>
              </Column2> 
              
            </InfoRow>
          </InfoWrapper>
        </InfoContainer>
    </>
  );
};

export default InfoSection;
