import React from "react";
import { FaCheckSquare, FaSquare } from "react-icons/fa";
import Chip from "../../Chip";
import { ChipList } from "../../Chip/ChipElements";
import CurrencySelector from "../../CurrencySelector";
import {
  FormButton1,
  FormInput,
  FormInput1,
  FormLabel,
  FormWrapper,
} from "../../CustomForm/CustomFormElements";
import {
  MintDetailsText,
  SmallText,
} from "../../NFTCollectionCreator/NFTCollectionCreatorElements";
import NFTokenRevShare from "../../NFTCollectionCreator/NFTokenRevShare";
import NFTokenDiscounts from "../../NFTCollectionCreator/NFTokenDiscounts";
import ToggleButton from "../../ToggleButton";

const Page1 = ({
  address,
  addressRef,
  percentageRef,
  handleInput,
  errors,
  setCurrentId,
  setFixedSupply,
  isFixedSupply,
  mintQuantity,
  toggleRevenue,
  revShare,
  isPremint,
  mintDate,
  editRevenue,
  addRevenue,
  removeRevenue,
  currentAddress,
  clearRevenueForm,
  currentRevenueId,
  revenue,
  isRevenueEditing,
  setCurrentAddress,
  setRevenueEditing,
  setCurrency,
  currency,
  price,
  isDiscountsLoaded,
  isDiscount,
  setDiscount,
  setDiscountEditing,
  setDiscountType,
  isDiscountEditing,
  setRedeemers,
  deleteDiscount,
  currentDiscount,
  setCurrentDiscount,
  selectDiscountType,
  discountType,
  discountRedeemers,
  addDiscount,
}) => {
  return (
    <>
      {" "}
      <FormWrapper>
        <FormLabel>
          Price{" "}
          <CurrencySelector currency={currency} setCurrency={setCurrency} />
        </FormLabel>
        <FormInput1
          name="price"
          onChange={handleInput}
          placeholder={`Set the price of this collection in ${currency}`}
          value={price}
          min={0}
        />
        <div style={{ display: "flex", width: "100%", padding: "2%" }}>
          <MintDetailsText>Add Discounts</MintDetailsText>
          <ToggleButton
            toggle={isDiscount}
            toggleClick={() => {
              setDiscount(!isDiscount);
            }}
            customOnIcon={<FaCheckSquare />}
            customOffIcon={<FaSquare />}
          />
        </div>
        <SmallText>
          Set the price of your collection. Select from the currencies we
          accept.
        </SmallText>
      </FormWrapper>
      {isDiscountsLoaded && isDiscount && (
        <NFTokenDiscounts
          setRedeemers={setRedeemers}
          setEditing={setDiscountEditing}
          isEditing={isDiscountEditing}
          currentDiscount={currentDiscount}
          setCurrentDiscount={setCurrentDiscount}
          deleteSelectedDiscount={deleteDiscount}
          discountRedeemers={discountRedeemers}
          addDiscount={addDiscount}
          selectDiscountType={selectDiscountType}
          currency={currency}
          price={price}
          discountType={discountType}
          setDiscountType={setDiscountType}
        />
      )}
      {isPremint && (
        <FormWrapper>
          <div style={{ display: "flex", width: "100%", padding: "2%" }}>
            <MintDetailsText>Pre-Mint / Pre-Order</MintDetailsText>
          </div>

          <FormWrapper>
            <FormWrapper>
              <FormLabel>Release Date</FormLabel>
              <FormInput
                name="pre mint"
                onChange={handleInput}
                value={mintDate}
                readOnly={true}
                type={"datetime-local"}
              />
            </FormWrapper>
            {errors?.premint && <SmallText error>{errors?.premint}</SmallText>}
          </FormWrapper>
        </FormWrapper>
      )}
      <FormWrapper>
        <div style={{ display: "flex", width: "100%", padding: "2%" }}>
          <MintDetailsText>Fixed Supply</MintDetailsText>
          <ToggleButton
            toggle={isFixedSupply}
            toggleClick={() => {
              setFixedSupply(!isFixedSupply);
            }}
            customOnIcon={<FaCheckSquare />}
            customOffIcon={<FaSquare />}
          />
        </div>
        {isFixedSupply && (
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: "2%",
            }}
          >
            <MintDetailsText>Quantity</MintDetailsText>
            <FormInput1
              name="fixed supply"
              onChange={handleInput}
              value={mintQuantity}
              min={1}
            />
          </div>
        )}
        <SmallText>
          By checking this option, Sonar Muse will be allowed to only sell the
          quatity specified (You can personally mint more).
        </SmallText>
      </FormWrapper>
      <FormWrapper>
        <div style={{ display: "flex", width: "100%", padding: "2%" }}>
          <MintDetailsText>NFT Rev Share (BETA)</MintDetailsText>
          <ToggleButton
            toggle={revShare}
            toggleClick={toggleRevenue}
            customOnIcon={<FaCheckSquare />}
            customOffIcon={<FaSquare />}
          />
        </div>
        {revShare && (
          <NFTokenRevShare
            addresses={revenue}
            address={currentAddress?.address}
            addressRef={addressRef}
            percentage={currentAddress?.percentage}
            percentageRef={percentageRef}
            setCurrentId={setCurrentId}
            setEditing={setRevenueEditing}
            setCurrentRevenue={setCurrentAddress}
            handleInput={handleInput}
            onAdd={addRevenue}
            isEditing={isRevenueEditing}
            currentId={currentRevenueId}
            errors={errors}
            clearForm={clearRevenueForm}
            onDelete={removeRevenue}
            onEdit={editRevenue}
          />
        )}

        <SmallText>
          By checking this option, you agree to Sonar Muse selling this
          collection on your behalf and split the sales of this NFToken between
          the addresses specified. This is a test feature. Select at your own
          risk
        </SmallText>
      </FormWrapper>
    </>
  );
};

export default Page1;
