import axiosInstance from '../components/Authorization/client'

let axios = axiosInstance

const useVideos = () => {

    const getLatestSongVideos = async(page) => {
        return await axios.get(`/api/videos/getLatestSongVideos/${page}`).then(
            songs => {
              if(songs.data && !songs.data.error){
                
                return songs.data
              }else {
                return false
              }
            }
          ).catch(err => {
            console.error(err)
            return false
          })
    }

    const getVideo = async(id) => {
       return await axios
      .get(`/api/${id}/getVideo`)
      .then(async (video) => {
        if (video?.data && !video?.data.error) {
          return video.data;
        }
        return false;
      })
      .catch((err) => {
        return false;
      });
    }

    const getSimilarVideos = async(video, page) => {
      return await axios
      .get(`/api/videos/${video}/getSimilarVideos/${page}`)
      .then((videos) => {
        if (videos.data.error || videos.data === false) {
          return false;
        }
        return videos.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
    }

    const getNFTokenCollectionsByVideo = (video, page) => {
      return axios
      .get(`/api/collection/${video}/getCollectionsBySongId/${page}`)
      .then((collections) => {
        if (collections.data.error || collections.data === false) {
          return false;
        }
        return collections.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
    }

  return {
    getLatestSongVideos,
    getVideo,
    getSimilarVideos, 
    getNFTokenCollectionsByVideo
  }
}

export default useVideos