import React, { useCallback, useEffect, useRef, useState } from "react";
import useMusic from "../../hooks/useMusic";
import { SLIDER_TYPES } from "../../utils/Enum";
import ContentWrapper from "../ContentWrapper";
import PageIndexer from "../PageIndexer";
import { Slider } from "../Slider";
import { useDigitalAlbumSearch } from "../../hooks/useSearch";
import { ModalLoadingScreen } from "../LoadingScreen";
import Modal from "../Modal";

const DownloadableAlbums = () => {
  const [pageNumber, setPageNumber] = useState(0)

  const {loading, error, albums, pages, setPages} = useDigitalAlbumSearch({pageNumber: pageNumber})

  const observer = useRef()
  const lastAlbumElementRef = useCallback( node => {
    
    if(loading){
      return
    }
    if(observer.current){ 
      observer.current.disconnect()}
      observer.current = new IntersectionObserver(entries=> {
        if(entries[0].isIntersecting && (pageNumber < parseInt(pages/10))){
              setPageNumber(prev => prev + 1)

        }
    }) 
    
    if(node) observer.current.observe(node)
   
  }, [loading, pageNumber, pages])
    
    
  return (
    <>
    {
      !loading && albums?.length > 0 && (
        <ContentWrapper heading={"Downloadbable Albums"}>
          <Slider
            type={SLIDER_TYPES.DIGITAL_ALBUM}
            heightScroll
            slides={albums}
            cardRef={lastAlbumElementRef}
            id={"digitalAlbums"}
          />
        </ContentWrapper>
      )
      /*         <PageIndexer/>
       */
    }
    {
      loading && albums?.length > 0 && (
        <><ContentWrapper heading={"Downloadable Albums"}>
          <Slider
            type={SLIDER_TYPES.DIGITAL_ALBUM}
            heightScroll
            slides={albums}
            cardRef={lastAlbumElementRef}
            id={"digitalAlbums"}
          />
                  <Modal isOpen={loading}><ModalLoadingScreen text={'Loading. Please Wait'}/></Modal>

        </ContentWrapper></>
      )
      /*         <PageIndexer/>
       */
    }
    {loading && albums?.length === 0 && (
      <ContentWrapper heading={"Downloadable Albums"}>
           <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      </ContentWrapper>
    )}
    {!loading && albums?.length === 0 && <ContentWrapper heading={"No Albums Found"}>
        
        </ContentWrapper>}
  </>
  )
};
export default DownloadableAlbums;
