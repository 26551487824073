import React from 'react'
import { SongDetailsSection, SongDetailsSectionTitle } from '../MusicUploadsFormElements'
import CreativeCommonsSelector from '../../CreativeCommonsSelector'

const Page2 = ({license, setCreativeCommons}) => {
  return (
    <SongDetailsSection>
      <SongDetailsSectionTitle>Licensing</SongDetailsSectionTitle>
      <CreativeCommonsSelector
        creativeCommons={license}
        setCreativeCommons={setCreativeCommons}
      />
    </SongDetailsSection>
  )
}

export default Page2