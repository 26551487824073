import React, { useCallback, useEffect, useState } from "react";
import ContentList from "../ContentList";
import ContentWrapper from "../ContentWrapper";
import {
  AlbumArt,
  AlbumArtContainer,
  AlbumArtWrapper,
  DetailsWrapper,
  Img,
  MusicAboutWrapper,
  MusicContentWrapper,
  MusicDetailsContainer,
  MusicDetailsWrapper,
  MusicHeaderWrapper,
  MusicHeading,
  MusicHeadingContainer,
  MusicHeadingImage,
  MusicHeadingTextWrapper,
  MusicInfoWrapper,
  MusicNav,
  MusicNavLink,
  MusicOptionsContainer,
  MusicPageContainer,
  MusicPageWrapper,
  MusicPlayerWrapper,
  MusicPlayPauseButton,
  MusicHeadingBgImage,
  MusicHeadingWrapper,
  MusicHeadingImageWrapper,
  MusicSubheading,
  MusicPriceWrapper,
  NftBadgeWrapper,
  NftLogo,
  Option,
  OptionButton,
  OptionText,
  PurchaseButton,
  Select,
  SongDetailsText,
  SongDetailsWrapper,
  SubHeading,
  Text,
  OptionMenuButton,
  MusicHeadingSmall,
} from "./MusicPageElements";
import { FaPlay, FaPause, FaShare, FaRegMoneyBillAlt } from "react-icons/fa";
import { GiScrollUnfurled } from "react-icons/gi";
import { GoReport } from "react-icons/go";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { Slider } from "../Slider";
import Marquee from "react-text-marquee";
import NftBadge from "../../images/nft_badge.png";
import { useAuth } from "../../contexts/AuthContext";
import {
  filterLikes,
  getAllArtists,
  getArtistsNames,
  getObjectLength,
  getSongTitle,
} from "../../utils";
import { useContext } from "react";
import { useAudio, usePreviewAudio } from "../AudioPlayer/context/AudioContext";
import { FcLikePlaceholder, FcLike } from "react-icons/fc";
import { AiOutlineEye, AiFillEye } from "react-icons/ai";
import { isUserPayingOut } from "../SonarMuseWebMonetization";
import { useNavigate, useLocation } from "react-router-dom";
import QRCodeForm from "../QRCodeForm";
import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
  MINTING,
  MUSIC_PLAYER_ACTIONS,
  MUSIC_PLAYING_FROM,
  MUSIC_PURCHASE_OPTIONS,
  SLIDER_TYPES,
  TRANSACTION_OPTIONS,
} from "../../utils/Enum";
import Modal from "../Modal";
import ImageUtils from "../../utils/ImageUtils";
import {MusicOptionsModal } from "../MenuOptions";
import { NavContainer } from "../NavLink/NavLinkElements";
import { useSonarMusePlayer } from "../../hooks/AudioHook";
import { Audio } from "../AudioPlayer/AudioPlayerElements";
import {
  CollectionSongList,
  MusicStoreSongList,
} from "../ContentList/SongList";
import CollectionAudioPlayer from "../CollectionAudioPlayer";
import { useAlert } from "../Alert/AlertContext";
import MusicEditPage from "../MusicEditPage";
import CommentForm from "../CommentForm";
import { useModal } from "../Modal/ModalContext";
import CommentsSection from "../CommentsSection";
import OptionModal from "../OptionModal";
import { HoverOption } from "../HoverMenu/HoverMenuElements";
import useArtist from "../../hooks/useArtist";
import TokenGatedContentDownloader from "../MusicDownloader/TokenGatedContentDownloader";
import ProgressBar from "../ProgressBar";
import PurchaseModal from "../PurchaseModal";
import { useUser } from "../../hooks/useUser";
import {
  FormButton,
  FormButton1,
  FormText,
  FormWrapper,
} from "../CustomForm/CustomFormElements";
import { Button1, CustomButton, CustomButton1 } from "../ButtonElement";
import { SmallText } from "../NFTCollectionCreator/NFTCollectionCreatorElements";
import { getAddressName } from "../../utils/nfTokensUploads";
import { DEFAULT_IMAGE } from "../GravatarAPI";
import useMusic from "../../hooks/useMusic";
import { Theme } from "../WebsiteThemeElements";
import { RegularUserList } from "../ContentList/UserList";
import { getResizedImage } from "../../utils/FileUploadsUtils";
import ShareButton from "../ShareButton";

function CollectionPage({
  id,
  collection,
  isSongPlaying,
  stopMainPlayer,
  deleteSong,
  hasPurchased,
  currentTab,
  setTab,
  query,
  location,
  updateWebPageIcon
}) {
  const { currentUser } = useAuth();
  const { getCollectionsByUser } = useUser({});
  const { getSimilarCollections, getNFTokenCollectionOwners } = useMusic({});
  const navigate = useNavigate();
  
  const { defaultAlbumArt, NftBadge } = ImageUtils();
  const [requestPurchase, setRequest] = useState(false);
  const [music, setMusic] = useState();
  const {
    timeJump,
    currentSong,
    isPlaying,
    duration,
    currentTime,
    progressBar,
    audioPlayer,
    changeRange,
    setTimeJump,
    volume,
    isRepeat,
    calcTime,
    backThirty,
    forwardThirty,
    isPodcast,
    isShuffle,
    nextSong,
    handleToggle,
    prevSong,
    playlist,
    setPlaylist,
    setCurrentSong,
    timeTravel,
    togglePlayPause,
    toggleMute,
    toggleVolume,
    isOpen,
    closeViewer,
    handleEnd,
    stop,
    isMute,
    openViewer,
  } = useSonarMusePlayer();
  const {handlePreviewToggle, isPreviewPlaying, togglePreviewPlay} = usePreviewAudio()
  const { modalContent, toggleModal, openModal, closeModal } = useModal();
  const { getArtistName, searchArtists } = useArtist({});
  const [requestNFTMint, setMintRequest] = useState(false);
  const [artistName, setArtistName] = useState(
    collection?.issuer ? collection?.issuer : ""
  );
  const [nftokenOwners, setNFTokenOwners] = useState([]);
  const [isOwnersLoaded, setOwnersLoaded] = useState(false);
  const [showAllOwners, setShowAll] = useState(false);
  const [artistId, setArtistId] = useState("");
  const [collections, setCollections] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [songs, setSongs] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [isRecommendLoaded, setRecommendLoaded] = useState(false);
  const [isAlbumArtLoaded,setAlbumArtLoaded] = useState()
  const [albumArt, setAlbumArt] = useState(collection?.cover?.url)

  const [isCPlaying, setPlaying] = useState(false);
  const [showList, setShowList] = useState(false);
  const [body, setBody] = useState({});
  const { addAlert } = useAlert();
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  //Function call to get info from IPFS
  //display information
  const value = {
    timeJump,
    currentSong,
    isPlaying,
    duration,
    currentTime,
    progressBar,
    stopMainPlayer : () => {
      stopMainPlayer()
      if(isPreviewPlaying){
        handlePreviewToggle()
      }
    },
    audioPlayer,
    changeRange,
    setTimeJump,
    volume,
    isRepeat,
    calcTime,
    backThirty,
    forwardThirty,
    isPodcast,
    isShuffle,
    nextSong,
    handleToggle,
    prevSong,
    playlist,
    setPlaylist,
    togglePlayPause,
    toggleMute,
    toggleVolume,
    isOpen,
    closeViewer,
    isMute,
    handleEnd,
    openViewer,
  };

  const isPlayingFrom = useCallback(() => {
    if (
      JSON.parse(localStorage.getItem("isPlayingFrom"))?.playingFrom ===
        MUSIC_PLAYING_FROM.COLLECTION &&
      JSON.parse(localStorage.getItem("isPlayingFrom"))?.id ===
        collection.collectionId
    ) {
      return true;
    }
    return false;
  }, [collection]);

  useEffect(() => {
    updateWebPageIcon(collection?.cover?.url)
    return () => {}
  },[])

  useEffect(() => {
    if(!isAlbumArtLoaded){
      getResizedImage(collection?.cover?.url).then(image => {
        setAlbumArt(image)
        
      }).catch(err => {
        setAlbumArt(collection?.cover?.url)
      })
      setAlbumArtLoaded(true)
    }
  }, [])

  useEffect(() => {
    if (isSongPlaying) {
      if (isPlaying) {
        setPlaying(false);
        handleToggle("play-pause", false);
        timeTravel(0);
      }
    }
    return () => {};
  }, [isPlaying, isSongPlaying, isCPlaying]);

  useEffect(() => {
    getSongs();
    return () => {};
  }, [songs?.length === 0]);

  useEffect(() => {
    if (isPlaying && isPlayingFrom()) {
      setPlaying(true);
    } else {
      setPlaying(false);
    }
  }, [collection, isPlaying, isPlayingFrom]);



  useEffect(() => {
    displayArtistName();
    return () => {};
  }, [collection]);

  useEffect(() => {
    getCollectionsByUser(collection?.issuer, 0)
      .then((cols) => {
        if (cols?.length > 0) {
          setCollections(
            cols
          );
          setLoaded(true);
        } else {
          setLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoaded(true);
      });
    getSimilarCollections(collection?.collectionType, 0)
      .then((collections) => {
        setRecommendations(collections);
        setRecommendLoaded(true);
      })
      .catch((err) => {
        setRecommendLoaded(true);
      });
    getNFTokenCollectionOwners(collection.issuer, collection?.mintInfo?.taxon)
      .then((owners) => {
        setNFTokenOwners(owners);
        setOwnersLoaded(true);
        return;
      })
      .catch((err) => {
        setOwnersLoaded(true);
        return;
      });

    return () => {};
  }, [collection?.issuer]);

  const displayArtistName = async () => {
    return searchArtists(ARTIST_SEARCH_TYPES.ADDRESS, collection?.issuer)
      .then(async (artist) => {
        if (artist) {
          setArtistName(artist?.artistName);
          /* setProfileImg(artist?.artistSettings?.profilePictureType === "custom" && artist?.profilePicture ?
        artist?.profilePicture :
        artist?.photoUrl
          ? artist.photoUrl + "?s=600&d=mp"
          : DEFAULT_IMAGE(600)) */
          setArtistId(artist?.artistId);
          return;
        } else {
          let issuerName = await getAddressName(collection?.issuer);
          if (getObjectLength(issuerName) > 0) {
            setArtistName(issuerName?.name);
            //setProfileImg(issuerName?.profileUrl + "?s=600&d=mp")
            return;
          }
          setArtistName(issuerName);
          return;
        }
      })
      .catch((err) => {
        setArtistName(collection?.issuer);
        //console.error(err)
      });
  };

  const getSongs = () => {
    switch (collection.collectionType) {
      case MINTING.TYPE.SINGLE:
        setSongs(
          collection?.songs.map((s) => {
            return {
              ...s,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
                id: collection.collectionId,
              },
            };
          })
        );
        setPlaylist(
          collection?.songs.map((s) => {
            return {
              ...s,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
                id: collection.collectionId,
              },
            };
          })
        );
        setCurrentSong(0);
        break;
      case MINTING.TYPE.ALBUM:
        setSongs(
          collection?.albums[0]?.songs.map((s) => {
            return {
              ...s,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
                id: collection.collectionId,
              },
            };
          })
        );
        setPlaylist(
          collection?.albums[0]?.songs.map((s) => {
            return {
              ...s,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
                id: collection.collectionId,
              },
            };
          })
        );
        setCurrentSong(0);
        break;
      case MINTING.TYPE.CUSTOM:
        if (collection?.songs.length > 0 && !collection?.albums) {
          setSongs(
            collection?.songs.map((s) => {
              return {
                ...s,
                playingFrom: {
                  playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
                  id: collection.collectionId,
                },
              };
            })
          );
          setPlaylist(
            collection?.songs.map((s) => {
              return {
                ...s,
                playingFrom: {
                  playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
                  id: collection.collectionId,
                },
              };
            })
          );
          setCurrentSong(0);
        }
        if (collection?.songs.length > 0 && collection?.albums?.length > 0) {
          setSongs(
            collection?.songs?.map((s) => {
              return {
                ...s,
                playingFrom: {
                  playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
                  id: collection.collectionId,
                },
              };
            })
          );
          setPlaylist(
            collection?.songs.map((s) => {
              return {
                ...s,
                playingFrom: {
                  playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
                  id: collection.collectionId,
                },
              };
            })
          );
          setCurrentSong(0);
        }
        if (!collection?.songs && collection?.albums?.length > 0) {
          let s = collection?.albums[0]?.songs.map((song) => {
            return {
              ...song,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
                id: collection.collectionId,
              },
            };
          });
          setSongs(s);
          setPlaylist(s);
          setCurrentSong(0);
        }
        break;

      default:
        break;
    }
  };

  const onSuccess = () => {};

  const togglePlay = (pl) => {
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
      id: collection.collectionId,
    };
    localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
    stopMainPlayer();
    if (isPlaying && isPlayingFrom()) {
      // if(isPlaying && subscribed to coil)
      setPlaying(false);
      handleToggle("play-pause", false);
      return;
    }
    if (!isPlaying && isPlayingFrom()) {
      localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
      setPlaylist(pl);
      setPlaying(true);
      handleToggle("play-pause", true);
      return;
    }
    if (!isPlaying && !isPlayingFrom()) {
      localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
      setPlaylist(pl);
      setCurrentSong(0);
      setPlaying(true);
      handleToggle("play-pause", true);
      return;
    }

    return;
  };

  const playSong = (i) => {
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
      id: collection.collectionId,
    };
    stopMainPlayer();
    setPlaylist(
      songs.map((song) => {
        return {
          ...song,
          playingFrom: playingFrom,
        };
      })
    );
    setCurrentSong(i);
    localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
    if (isPlaying && playlist[currentSong]?.songId === playlist[i]?.songId) {
      handleToggle("play-pause", false);
      return;
    }
    if (!isPlaying && playlist[currentSong]?.songId === playlist[i]?.songId) {
      handleToggle("play-pause", true);
      return;
    }
    if (playlist[currentSong]?.songId !== playlist[i]?.songId) {
      handleToggle("play");
    }
  };

  const playMusic = () => {
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
      id: collection.collectionId,
    };
    if (isPlaying) {
      togglePlayPause();
    } else {
      if (collection.collectionType !== MINTING.TYPE.CUSTOM) {
        if (collection.collectionType === MINTING.TYPE.SINGLE) {
          togglePlay([{ ...collection?.songs[0], playingFrom: playingFrom }]);
          return;
        }
        if (collection.collectionType === MINTING.TYPE.ALBUM) {
          togglePlay(
            collection?.albums[0]?.songs.map((songs) => {
              return {
                ...songs,
                playingFrom: playingFrom,
              };
            })
          );
          return;
        }
      } else {
        openModal();
        modalContent(
          <>
            <OptionModal heading={"Select Music to Play from NFT"}>
              {collection?.songs?.length > 0 &&
                collection?.albums?.length > 0 && (
                  <>
                    {" "}
                    <HoverOption
                      onClick={() => {
                        let s = collection?.songs.map((song) => {
                          return {
                            ...song,
                            playingFrom: playingFrom,
                          };
                        });
                        localStorage.setItem(
                          "isPlayingFrom",
                          JSON.stringify(playingFrom)
                        );

                        setSongs(s);
                        setPlaylist(s);
                        currentSong(0);
                        stopMainPlayer();
                        handleToggle("play");
                        modalContent();
                        closeModal();
                      }}
                    >
                      Play Songs
                    </HoverOption>
                    <HoverOption
                      onClick={() => {
                        albumSelector(collection?.albums);
                      }}
                    >
                      Play Albums
                    </HoverOption>
                    <HoverOption
                      onClick={() => {
                        playAllMusic();
                      }}
                    >
                      Play All Music
                    </HoverOption>
                    <HoverOption
                      onClick={() => {
                        modalContent();

                        closeModal();
                      }}
                    >
                      Cancel
                    </HoverOption>
                  </>
                )}
              {collection?.albums?.length > 1 &&
                albumSelector(collection?.albums)}
            </OptionModal>
          </>
        );
      }
    }
  };

  const playAllMusic = () => {
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
      id: collection.collectionId,
    };
    let allMusic = [];
    let s = collection?.songs.map((song) => {
      return {
        ...song,
        playingFrom: playingFrom,
      };
    });
    allMusic.push(...s);
    collection?.albums.map((album) => {
      return album.songs.map((song) => {
        allMusic.push({
          ...song,
          playingFrom: playingFrom,
        });
        return song;
      });
    });
    stopMainPlayer();
    localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
    setSongs(allMusic);
    setPlaying(true);
    setPlaylist(allMusic);
    setCurrentSong(0);
    modalContent();
    closeModal();
  };

  const albumSelector = (albums) => {
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
      id: collection?.collectionId,
    };
    modalContent(
      <OptionModal>
        {albums.map((a) => {
          return (
            <HoverOption
              onClick={() => {
                let s = a?.songs.map((song) => {
                  return {
                    ...song,
                    playingFrom: playingFrom,
                  };
                });
                localStorage.setItem(
                  "isPlayingFrom",
                  JSON.stringify(playingFrom)
                );
                stopMainPlayer();
                setSongs(s);
                setPlaylist(s);
                setCurrentSong(0);
                handleToggle("play");
                modalContent();
                closeModal();
              }}
            >
              Play {a.albumName}
            </HoverOption>
          );
        })}
      </OptionModal>
    );
  };

  const playSongs = (songs) => {
    /* let songs =
      tokenInfo?.nftoken?.metadata?.songs?.length > 0
        ? convertSongsToSonarMuseSchema(tokenInfo?.nftoken?.metadata?.songs).map((s) => {
          return {...s,
          playingFrom:{
            playingFrom: MUSIC_PLAYING_FROM.NFT,
            id: tokenInfo?.nftoken?.nft_id,
          }}
        })
        : [];
    let albums =
      tokenInfo?.nftoken?.metadata?.albums?.length > 0
        ? convertAlbumsToSonarMuseSchema(
            tokenInfo?.nftoken?.metadata?.albums
          ).map(a => {
            songs.push(...a.songs.map(s => {
              return {...s,
                playingFrom:{
                  playingFrom: MUSIC_PLAYING_FROM.NFT,
                  id: tokenInfo?.nftoken?.nft_id,
                }}
            }))
            return a
          })
        : []; */
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
      id: collection?.collectionId,
    };
    setPlaylist(
      songs.map((s) => {
        return {
          ...s,
          playingFrom: playingFrom,
        };
      })
    );
    setSongs(
      songs.map((s) => {
        return {
          ...s,
          playingFrom: playingFrom,
        };
      })
    );
    stopMainPlayer();
    localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));

    if (isPlaying) {
    } else {
      setPlaying(true);
      setCurrentSong(0);
      handleToggle("play-pause", true);
    }
  };

  const playAlbum = (album) => {
    let s = [];
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
      id: collection?.collectionId,
    };
    album.songs.map((song) => {
      s.push({ ...song, playingFrom: playingFrom });
      return song;
    });
    stopMainPlayer();
    localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
    setSongs(s);
    setPlaylist(s);
    if (isPlaying) {
    } else {
      setPlaying(true);
      setCurrentSong(0);
      handleToggle("play-pause", true);
    }
  };

  const onFailure = () => {
    setRequest(false);
  };

  const cancelPurchase = () => {
    setRequest(false);
  };

  const musicOptions = (e) => {
    switch (e.target.value) {
      case "Buy collection":
        setBody({
          sender: currentUser?.uid,
          receiver: collection.issuer,
          value: collection.price,
          id: collection.collectionId,
          type: "collection",
          uri: currentUser?.uid,
          memo: `${currentUser?.uid} is buying (minting) the NFT Collection ${collection.name}`,
          instruction: `${currentUser?.uid} is minting the single ${
            collection.name
          } by ${getArtistsNames(
            collection.artists,
            ARTIST_SEARCH_TYPES.NAME
          )} `,
        });
        setMintRequest(true);
        break;
      case "edit":
        const params = new URLSearchParams({ edit: true });
        navigate(`${location.pathname}?edit=true`, {
          replace: true,
        });

        break;
      default:
        break;
    }
  };

  const onConfirmDelete = () => {
    openModal()
    modalContent(
      <OptionModal heading={'Collection delete'} additionalText={'Are you sure you want to delete this collection?'}>
        <HoverOption onClick={() => {
          modalContent(<DeleteCollection 
            collection={collection} 
            onCancel={() => {closeModal()}}
            onSuccess={() => {
            closeModal()
            navigate(`/home`)
          }} onFail={() => {
            closeModal()
          }}/>)
        }}>Continue</HoverOption>
        <CustomButton transparent={true} onClick={() => {
          modalContent()
          closeModal()
        }}>
                  Cancel
                </CustomButton>
      </OptionModal>
    )
  }

  const purchaseOptions = (e) => {
    switch (e.target.value) {
      case MUSIC_PURCHASE_OPTIONS.NFT_PURCHASE:
        break;
      case MUSIC_PURCHASE_OPTIONS.DOWNLOAD:
        break;

      default:
        break;
    }
    e.target.value = "Buy";
  };

  return (
    <>
      {requestNFTMint && (
        <Modal
          isOpen={requestNFTMint}
          onRequestClose={() => setMintRequest(false)}
        >
          <QRCodeForm
            heading={`Minting ${collection?.name} by ${collection?.issuer}`}
            body={body}
            cancelQr={() => setMintRequest(false)}
            onSuccess={onSuccess}
            onFail={onFailure}
            type={TRANSACTION_OPTIONS.NFTOKEN_MINT}
          ></QRCodeForm>
        </Modal>
      )}
      <MusicPageWrapper>
        <MusicHeadingContainer>
          <MusicHeadingBgImage
           onContextMenu={(e) => {
            e.preventDefault();
          }}
            src={isAlbumArtLoaded ? albumArt : collection?.cover?.url}
            alt={collection?.name}
          ></MusicHeadingBgImage>
          <MusicHeadingWrapper>
            <MusicHeadingImageWrapper>
              <MusicHeadingImage
                src={isAlbumArtLoaded ? albumArt : collection?.cover?.url}
                alt={collection?.name}
              />
            </MusicHeadingImageWrapper>
            <MusicHeadingTextWrapper>
              <SubHeading>
                <small>NFToken Collection</small>
              </SubHeading>
              <MusicHeading>{collection?.name}</MusicHeading>
              <MusicSubheading>
                {" "}
                <MusicHeading
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    artistId?.trim()?.length > 0
                      ? navigate(`/artist/${artistId}`)
                      : navigate(`/u/${collection?.issuer}`)
                  }
                >
                  {artistName}
                </MusicHeading>
              </MusicSubheading>
              <MusicHeadingSmall>
                {`${collection?.collectionType} · ${`${new Date(collection?.dateCreated).toLocaleString('default', { month: 'long' })} ${new Date(collection?.dateCreated).getDate()}, ${new Date(collection?.dateCreated).getFullYear()}`}`}
              </MusicHeadingSmall>
              <MusicOptionsContainer>
               
                  <>
                     <MusicPlayPauseButton
                      onClick={() => {
                        playMusic();
                      }}
                    >
                      {isCPlaying ? (
                        <>
                          <FaPause /> Pause
                        </>
                      ) : (
                        <>
                          <FaPlay
                            style={{ position: "relative", left: "1%" }}
                          />{" "}
                          Play
                        </>
                      )}
                    </MusicPlayPauseButton>

                    {((collection?.tokenSupply &&
                      collection?.amountSold < collection?.tokenSupply) ||
                      !collection?.tokenSupply) &&
                      !collection?.whitelist && (
                        <MusicPlayPauseButton
                          transparent={true}
                          onClick={() => {
                        
                            
                            if((!collection?.isPreMint && collection?.amountSold === collection?.tokenSupply) || (collection?.isPreMint && collection?.preMintAmount === collection?.amountSold)){
                              addAlert({
                                title: "NFToken Purchase",
                                type: ALERT_TYPES.DANGER,
                                message: "NFToken Sold Out",
                              })
                              return 
                            }
                            if(collection?.collectionId && ((!collection?.isPremint && collection?.tokenSupply && collection?.amountSold < collection?.tokenSupply) || !collection?.tokenSupply || (collection?.isPreMint && collection?.preMintAmount < collection?.amountSold))){
                              toggleModal();
                        modalContent(
                          <PurchaseModal
                            type={MUSIC_PURCHASE_OPTIONS.NFT_PURCHASE}
                            content={collection}
                          />
                        );
                              return
                            }
                            
                         
                        
                      }}
                        >
                          {/* <FaRegMoneyBillAlt
                            style={{ position: "relative", marginRight: "2%" }}
                          /> */}
                          {collection?.isPreMint ? 'Pre Mint' : `${parseFloat(collection?.price) > 0
                            ? collection?.price
                            : " FREE"}
                          ${parseFloat(collection?.price) > 0 && (collection?.currency
                              ? ` ${collection.currency}`
                              : " XRP")
                            }`}
                        </MusicPlayPauseButton>
                      )}
                  </>
                {currentUser?.uid && (
                  <OptionButton
                  onClick={() =>
                    {openModal()
                    modalContent(<MusicOptionsModal content={collection} type={ CONTENT_TYPES.COLLECTION}/>)
                  }
                }
                  >
                    <OptionMenuButton />
                  </OptionButton>
                )}
                {!currentUser?.uid && <>
                  <MusicPlayPauseButton transparent onClick={async () => {
                  openModal()
                  modalContent(<ShareButton url={`https://sonarmuse.org/collection/${collection?.collectionId}`} media={collection?.cover?.url} 
                    title={`The revolution of music ownership and collecting has now been tokenized! Check out the music NFT collection '${collection?.name}' on Sonar Muse`} />);
                }}>
                    Share
                 </MusicPlayPauseButton>
              
              </>}
                
                {!currentUser?.uid && collection?.tokenGate && (
                  <OptionButton
                    onClick={() => {
                      openModal();
                      modalContent(
                        <OptionModal heading={"Hidden Content Menu"}>
                          <HoverOption
                            onClick={() => {
                              modalContent(
                                <TokenGatedContentDownloader
                                  collection={collection}
                                />
                              );
                            }}
                          >
                            Download Token-Gated Files
                          </HoverOption>
                        </OptionModal>
                      );
                    }}
                  >
                    <OptionMenuButton />
                  </OptionButton>
                )}
              </MusicOptionsContainer>

              {(collection?.tokenSupply > collection?.amountSold ||
                !collection?.tokenSupply) &&
                collection?.whitelist && (
                  <MusicOptionsContainer>
                    {" "}
                    <CustomButton1
                      background={collection?.color}
                      style={{ marginRight: "2%" }}
                      onClick={() => {
                        openModal();
                        modalContent(
                          <PurchaseModal
                            content={collection}
                            type={MUSIC_PURCHASE_OPTIONS.FREE_MINT}
                          />
                        );
                      }}
                    >
                      Whitelist Mint
                    </CustomButton1>
                    <MusicPlayPauseButton
                      transparent={true}
                      onClick={() => {
                        
                            
                            if(collection?.amountSold === collection?.tokenSupply){
                              addAlert({
                                title: "NFToken Purchase",
                                type: ALERT_TYPES.DANGER,
                                message: "NFToken Sold Out",
                              })
                              return 
                            }
                            if(collection?.collectionId && ((collection?.tokenSupply && collection?.amountSold < collection?.tokenSupply) || !collection?.tokenSupply)){
                              toggleModal();
                        modalContent(
                          <PurchaseModal
                            type={MUSIC_PURCHASE_OPTIONS.NFT_PURCHASE}
                            content={collection}
                          />
                        );
                              return
                            }
                            
                         
                        
                      }}
                    >
                      <FaRegMoneyBillAlt
                        style={{ position: "relative", marginRight: "2%" }}
                      />
                      {collection?.price}{" "}
                      {collection?.currency ? collection.currency : "XRP"}
                    </MusicPlayPauseButton>
                  </MusicOptionsContainer>
                )}
              {collection?.description && (
                <MusicOptionsContainer>
                  <MusicAboutWrapper>
                    {collection?.description}
                  </MusicAboutWrapper>
                </MusicOptionsContainer>
              )}
              <Audio
                ref={audioPlayer}
                src={playlist[currentSong]?.url}
                loop={isRepeat === 2}
                preload={"metadata"}
                volume={isMute ? isMute : volume}
              />
            </MusicHeadingTextWrapper>
          </MusicHeadingWrapper>
        </MusicHeadingContainer>
        
          <>
            {collection?.tokenSupply && collection?.amountSold > 0 && (
              <>
                <MusicInfoWrapper>
                  <ProgressBar
                    message={`${parseFloat(
                      (collection?.amountSold / collection?.tokenSupply) * 100
                    )}% of ${collection?.tokenSupply} NFTs Sold`}
                    width={
                      (collection?.amountSold / collection?.tokenSupply) * 100
                    }
                    color={
                      collection?.color ? collection?.color : Theme({}).successColor
                    }
                  />
                </MusicInfoWrapper>
              </>
            )}

            {(new Date(collection?.dateCreated).getTime() <= new Date().getTime() || currentUser?.uid === collection?.issuer) && <div style={{ padding: "0 4%" }}>
              <CollectionAudioPlayer {...value} />
            </div>}
            
              <MusicDetailsContainer>
                {currentTab === "music" && (
                  <>
                    <ContentWrapper heading={`Collection Queue`}>
                      <CollectionSongList
                        onButtonClick={playSong}
                        type={CONTENT_TYPES.SONG}
                        content={songs.map((s) => {
                          return {
                            ...s,
                            playingFrom: {
                              playingFrom: MUSIC_PLAYING_FROM.COLLECTION,
                              id: collection?.collectionId,
                            },
                          };
                        })}
                        isPlaying={isPlaying}
                        currentSong={currentSong}
                        playlist={playlist}
                      />
                    </ContentWrapper>

                    {(collection?.albums?.length > 0 ||
                      (collection?.albums?.length > 0 &&
                        collection?.songs?.length > 0)) && (
                      <ContentWrapper
                        heading={`Music from ${collection?.name} Collection`}
                        onClick={() => setShowList(!showList)}
                        subheading={showList ? "Hide" : "Show"}
                      >
                        {showList && (
                          <>
                            {collection?.albums?.length > 0 && (
                              <FormWrapper>
                                <ContentWrapper heading={`Albums`}>
                                  {collection?.albums.map((album) => {
                                    return (
                                      <ContentWrapper
                                        subheading={"Play"}
                                        heading={`${album.albumName}`}
                                        onClick={() => playAlbum(album)}
                                      >
                                        <MusicStoreSongList
                                          onButtonClick={(i) => {
                                            setSongs(
                                              album.songs.map((song) => {
                                                return {
                                                  ...song,
                                                  playingFrom: {
                                                    playingFrom:
                                                      MUSIC_PLAYING_FROM.COLLECTION,
                                                    id: collection.collectionId,
                                                  },
                                                };
                                              })
                                            );
                                            playSong(i);
                                          }}
                                          type={CONTENT_TYPES.SONG}
                                          content={album.songs}
                                          isPlaying={isPlaying}
                                          currentSong={currentSong}
                                          playlist={playlist}
                                        />
                                      </ContentWrapper>
                                    );
                                  })}
                                </ContentWrapper>
                              </FormWrapper>
                            )}

                            {collection?.songs &&
                              collection?.songs?.length > 0 && (
                                <FormWrapper>
                                  <ContentWrapper
                                    subheading={"Play"}
                                    onClick={() => playSongs(collection?.songs)}
                                    heading={`Songs`}
                                  >
                                    <MusicStoreSongList
                                      onButtonClick={(i) => {
                                        setSongs(
                                          collection?.songs.map((song) => {
                                            return {
                                              ...song,
                                              playingFrom: {
                                                playingFrom:
                                                  MUSIC_PLAYING_FROM.COLLECTION,
                                                id: collection.collectionId,
                                              },
                                            };
                                          })
                                        );
                                        playSong(i);
                                      }}
                                      type={CONTENT_TYPES.SONG}
                                      content={collection?.songs}
                                      isPlaying={isPlaying}
                                      currentSong={currentSong}
                                      playlist={playlist}
                                    />
                                  </ContentWrapper>
                                </FormWrapper>
                              )}
                          </>
                        )}
                      </ContentWrapper>
                    )}

                    <ContentWrapper heading={`Comments`}>
                      <CommentsSection
                        type={CONTENT_TYPES.COLLECTION}
                        id={collection?.collectionId}
                        contentName={collection?.collectionName}
                      />
                    </ContentWrapper>

                    {isLoaded && (collections?.length > 0 && collections.collections.filter(
                            (c) => c.collectionId !== collection?.collectionId
                          )?.length > 0) && (
                      <ContentWrapper heading={`Available ${artistName} NFT Collections`}
                      onClick={() => collections?.length > 10 &&
                        navigate(`/artist/${collection?.issuer}/collections`)
                      } subheading={collections?.length > 10 && 'View All'}
                      >
                        <Slider
                          type={SLIDER_TYPES.COLLECTION}
                          id={"nft collections"}
                          slides={collections.collections.filter(
                            (c) => c.collectionId !== collection?.collectionId
                          )}
                        />
                      </ContentWrapper>
                    )}
                    {!isLoaded && (
                      <ContentWrapper heading={`Available ${artistName} NFT Collections`}
                      
                      >
                        <Slider
                          type={SLIDER_TYPES.PURCHASE_SKELETON}
                          id={"nft collections"}
                          slides={collections.collections}
                        />
                      </ContentWrapper>
                    )}

                    <ContentWrapper
                      heading={`Other ${collection?.collectionType} Music Collections`}
                      onClick={() => recommendations?.length > 10 &&
                        navigate(`/collection/${collection?.collectionType}/similarCollections`)
                      } subheading={recommendations?.length > 10 && 'View All'}
                    >
                      {isRecommendLoaded && recommendations?.length > 0 && (
                        <Slider
                          type={SLIDER_TYPES.COLLECTION}
                          id={"similar collections"}
                          slides={recommendations.collections.filter(
                            (c) => c.collectionId !== collection?.collectionId
                          )}
                        />
                      )}
                      {!isRecommendLoaded && recommendations?.length === 0 && (
                        <Slider
                          type={SLIDER_TYPES.PURCHASE_SKELETON}
                          id={"similar collections"}
                        />
                      )}
                    </ContentWrapper>
                    {isOwnersLoaded && nftokenOwners?.length > 0 && (<ContentWrapper
                      heading={`${collection?.name} NFToken Owners (${nftokenOwners?.length})`}
                      onClick={() =>
                        nftokenOwners.length > 10 && setShowAll(!showAllOwners)
                      }
                      subheading={showAllOwners ? "Hide" : "View All"}
                    >
                      {/* <RegularUserList content={nftokenOwners}/> */}
                        <Slider
                          heightScroll={showAllOwners}
                          slides={nftokenOwners
                          }
                          type={SLIDER_TYPES.USER}
                          id={"nftoken owners"}
                        /></ContentWrapper>
                      )}
                      {!isOwnersLoaded && (
                        <ContentWrapper
                        heading={`${collection?.name} NFToken Owners`}>
                        <Slider
                          type={SLIDER_TYPES.USER_SKELETON}
                          id={"nftoken owners"}
                        /></ContentWrapper>
                      )}
                    
                  </>
                )}
              </MusicDetailsContainer>
           
          </>

      </MusicPageWrapper>
    </>
  );
}

export const DeleteCollection = ({onSuccess, onFail, onCancel, collection}) => {
  const {currentUser} = useAuth()
  return (
    <><QRCodeForm
    heading={`Deleting ${collection.name} (id: ${collection?.collectionId})`}

     type={TRANSACTION_OPTIONS.DELETE_COLLECTION}
      body={
        {
          sender: currentUser?.uid,
          memo:  `User is signing transaction to delete ${collection.name} (${collection?.collectionId})`,
          collectionId: collection?.collectionId,
          collectionInfo: collection,
        instruction: `User is signing transaction to delete ${collection.name} (${collection?.collectionId})`
      }}
      onSuccess={onSuccess}
      onFail={onFail}
      cancelQr={onCancel}
    />
  </>)
}

export default CollectionPage;
