import React from 'react'
import { v4 } from 'uuid'
import { getObjectLength } from '../../../utils'
import { SLIDER_TYPES } from '../../../utils/Enum'
import ContentWrapper from '../../ContentWrapper'
import { Slider } from '../../Slider'

const ProfileLikes = ({isLoaded, likes}) => {
    return (
        <>{isLoaded && getObjectLength(likes) > 0 && <>
        {likes?.songs?.length > 0 && <ContentWrapper heading='Songs'>
            <Slider type={SLIDER_TYPES.SONG} slides={likes?.songs} id={`likes ${v4()}`}/>
        </ContentWrapper>}
        {likes?.albums.length > 0 && <ContentWrapper heading='Albums'>
            <Slider type={SLIDER_TYPES.ALBUM} slides={likes?.albums} id={`likes ${v4()}`}/>
        </ContentWrapper>}
        {likes?.playlists?.length > 0 && <ContentWrapper heading='Playlists'>
            <Slider type={SLIDER_TYPES.PLAYLIST} slides={likes?.playlists} id={`likes ${v4()}`}/>
        </ContentWrapper>}
        {likes?.collections?.length > 0 && <ContentWrapper heading='Collections'>
            <Slider type={SLIDER_TYPES.COLLECTION} slides={likes?.collections} id={`likes ${v4()}`}/>
        </ContentWrapper>}
        
        </>}
        {!isLoaded && <>
        <ContentWrapper heading='Songs'>
            <Slider type={SLIDER_TYPES.MUSIC_SKELETON} id={`likes ${v4()}`}/>
        </ContentWrapper>
        <ContentWrapper heading='Albums'>
            <Slider type={SLIDER_TYPES.MUSIC_SKELETON}  id={`likes ${v4()}`}/>
        </ContentWrapper>
        <ContentWrapper heading='Playlists'>
            <Slider type={SLIDER_TYPES.MUSIC_SKELETON}  id={`likes ${v4()}`}/>
        </ContentWrapper>
        <ContentWrapper heading='Collections'>
            <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}  id={`likes ${v4()}`}/>
        </ContentWrapper>
        
        </>} { isLoaded && getObjectLength(likes) === 0 && 
            <Slider type={SLIDER_TYPES.SONG} id={`likes ${v4()}`}/>
        }</>
      )
}

export default ProfileLikes