import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext';
import { CONTENT_TYPES, TRANSACTION_OPTIONS } from '../../utils/Enum';
import { FormButton, FormInput1, FormTextArea } from '../CustomForm/CustomFormElements';
import { HoverOption } from '../HoverMenu/HoverMenuElements';
import { useModal } from '../Modal/ModalContext';
import OptionModal from '../OptionModal';
import QRCodeForm from '../QRCodeForm';
import { CommentsFormContainer, MessageInput, PostButton } from './CommentFormElements';

const CommentForm = ({contentId, type, parentId, contentName, isLoaded, getComments}) => {
    const { modalContent, toggleModal, isOpen, openModal, closeModal } = useModal();
    const [memo, setMemo] = useState("");
    const {currentUser} = useAuth()


  return (
    <>{<CommentsFormContainer>
       <MessageInput onChange={(e) => setMemo(e.target.value) } placeholder={`Comment`} value={memo} />
            <PostButton style={{borderRadius: "15px"}} type="submit" to='#Comment' onClick={() => {
              if(isLoaded && memo?.trim()?.length > 0){
              toggleModal()
              modalContent(<>
              <QRCodeForm 
              body={{
              memo: memo,
              sender: currentUser?.uid,
              contentId: contentId,
              type: type,
              parentId: parentId
            }}
            type={TRANSACTION_OPTIONS.COMMENT}
            onFail={() => {
              toggleModal()
              modalContent()
            }}
            onSuccess={() => {
              toggleModal()
              getComments(type, contentId)
              setMemo("")
              modalContent()
            }}
            cancelQr={() => {
              toggleModal()
              modalContent()
            }}
            heading={`User is signing the following transaction to comment on the ${type} ${contentName}`}/>
              </>)}
            }}>Comment</PostButton>
    </CommentsFormContainer>}</>
  )
}

export default CommentForm