import React from 'react'
import AlbumArt from '../AlbumArt';
import ContentWrapper from '../ContentWrapper';
import { FormText, FormWrapper } from '../CustomForm/CustomFormElements';
import { Header } from '../Header'
import { HoverOption } from '../MenuOptions/MenuOptionsElements';
import { AUDIO_EXPORT_TYPES, CONTENT_TYPES } from '../../utils/Enum';

const CollectionDownload = ({collection, download}) => {
    return (
        <>
          <Header
            title={
              `${collection?.name}`
            }
          />
          <ContentWrapper subheading={"Download Album"}>
            
            <FormWrapper><FormText>
              {`Download ${collection?.name} `}
            </FormText>
              <div style={{cursor: "pointer"}}><AlbumArt
                size={"25rem"}
                albumArt={collection?.cover?.url}
                alt={collection?.name}
                
              /></div>
              <HoverOption onClick={() => download(collection, CONTENT_TYPES.COLLECTION, collection?.collectionId, AUDIO_EXPORT_TYPES.MP3 )}>Download Collection</HoverOption>
            </FormWrapper>
          </ContentWrapper>
        </>
      );
}

export default CollectionDownload