import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../components/Footer';
import GenrePage from '../../components/Genres/GenrePage';
import Navbar from '../../components/Navbar';
import { Home, Welcome } from '../../components/Navbar/Data';
import Sidebar from '../../components/Sidebar';

const Genre = () => {
    const [genre, setGenreId] = useState()
    const {genreId} = useParams()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}
    const navigate  = useNavigate()
    
    useEffect(() => {
        if(!genreId)
          {
            navigate(-1)
          }
        setGenreId(genreId)
        return () => {}
    }, [genreId])
  
  
  
    return( 
        <>
              <Sidebar
            isOpen={isOpen}
            toggle={toggle}
            navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
          />
            <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
            <GenrePage id={genreId} type={'genre'}/>
            
        </>
        )
}

export default Genre