import React, { useEffect, useState } from "react";
import axios from "../Authorization/client";
import WebSocket from "react-websocket";
import { ModalLoadingScreen } from "../LoadingScreen";
import {
  Container,
  Form,
  FormButton,
  FormContent,
  FormH1,
  FormWrap,
  QrCode,
  QrWrapper,
  Text,
  TextLink,
} from "./QrCodeFormElements";
import Modal from "../Modal";
import { useUser } from "../../hooks/useUser";
import { useAuth } from "../../contexts/AuthContext";
import { CustomButton } from "../ButtonElement";
import useMusic from "../../hooks/useMusic";
import useArtist from "../../hooks/useArtist";
import {
  ALERT_TYPES,
  PAYMENT_WALLETS,
  TRANSACTION_OPTIONS,
} from "../../utils/Enum";
import OptionModal from "../OptionModal";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { useAlert } from "../Alert/AlertContext";
import usePodcaster from "../../hooks/usePodcaster";
import useRecordLabel from "../../hooks/useRecordLabel";
import { FormButton1 } from "../CustomForm/CustomFormElements";
import { getSignTxStatus } from "../../utils/dbUtils/signTxUtils";

const QRCodeForm = ({
  children,
  heading,
  body,
  type,
  cancelQr,
  onSuccess,
  onFail,
}) => {
  const {
    uploadSong,
    uploadAlbum,
    uploadEpisode,
    digitalPurchase,
    nfTokenPurchase,
    createNFTokenCollection,
    mintNft,
    updateSong,
    updateAlbum,
    updateCollection,
    updateEpisode,
  } = useMusic();

  const {
    updateProfile,
    validateArtistNFTokenMint,
    enableArtistWebMonetization,
    requestArtistRegistration,
    artistProfileChecker,
  } = useArtist({
    id: body?.artistId,
  });

  const { updatePodcasterProfile, enablePodcasterWebMonetization } =
    usePodcaster({ id: body?.sender });
  const { updateRecordLabelProfile, enableRecordLabelWebMonetization } =
    useRecordLabel({ id: body?.sender });

  const {
    updateUserProfile,
    deactivateUser,
    subscribeToStreamingService,
    redeemSwerlVoucher,
    purchaseSwerlVoucher,
    purchaseNFToken,
    giftNFToken,
    enablePushNotifications,
    disablePushNotifications,
    addWhitelistedAddresses,
  } = useUser({
    id: body?.sender,
  });
  const [errMsg, setError] = useState("");
  const [isLoaded, setLoaded] = useState(false);
  const [isOpened, setOpened] = useState(false);
  const [payload, setPayload] = useState();
  const [isSigned, setSigned] = useState(false);
  const [status, setStatus] = useState("idle");
  const [response, setResponse] = useState({});
  const [invoice, setInvoice] = useState();
  const [nftokenId, setNFTokenId] = useState();
  const [offers, setOffers] = useState([]);
  const { currentUser } = useAuth();
  const { addAlert } = useAlert();
  const { tipUser, postComment, createMarketingCampaign } = useUser({
    id: currentUser?.uid,
  });

  useEffect(() => {
    SignTx();
    return () => {};
  }, []);

  useEffect(() => {
    if (invoice) {
      getSignTxStatus(invoice, status, setStatus, setResponse);
    }
    return () => {};
  }, [invoice]);

  useEffect(() => {
    if (invoice) {
      if (status === "success" && response !== false) {
        dispatch(response);
      }
      if (status === "error") {
        addAlert({
          title: `${type}`,
          type: ALERT_TYPES.DANGER,
          message: `The transaction was unsuccessful`,
        });
        onFail();
      }
    }
    return () => {};
  }, [status, invoice]);

  const SignTx = async () => {
    switch (type) {
      case TRANSACTION_OPTIONS.TIP_USER:
        addAlert({
          title: "User Tipping",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        await axios
          .post("/api/makePayment", {
            sender: body.sender,
            receiver: body.receiver,
            value: body.value,
            currency: body.currency,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            txInfo: { receiverId: body.receiver, senderId: body.sender },
            memo: body.memo,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            //console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.ROYALTY_TRUSTLINE_SET:
        addAlert({
          title: "Royalty Share Token Trustline",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        await axios
          .post(`/api/${body.tokenId}/createRoyaltyTokenTrustline`, {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            txInfo: { sender: body.sender, tokenId: body.tokenId },
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            //console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.SUBSCRIBE:
        break;

      case TRANSACTION_OPTIONS.COMMENT:
        addAlert({
          title: "Posting Comment",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        axios
          .post("/api/makePayment", {
            sender: body.sender,
            receiver: PAYMENT_WALLETS.COMMENTS,
            value: 0.01,
            currency: "XRP",
            invoice: true,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            txInfo: {
              parentId: body.parentId,
              comment: body.memo,
              contentId: body.contentId,
              type: body.type,
            },
            memo: body.memo,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.ARTIST_PROFILE_CHECKER:
        axios
          .post("/api/signTransaction", {
            userToken: localStorage.getItem("userToken"),
            instruction: body?.instruction,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.ARTIST_REGISTRATION:
        addAlert({
          title: "Artist Registration",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            type: type,
            txInfo: body.accountInfo,
            memo: body.memo,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.PODCASTER_REGISTRATION:
        addAlert({
          title: "Podcaster Registration",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            type: type,
            txInfo: body.accountInfo,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.MUSIC_UPLOAD.SONG:
        addAlert({
          title: "Music Upload",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            type: type,
            txInfo: body,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.MUSIC_UPLOAD.ALBUM:
        addAlert({
          title: "Music Upload",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        await axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            type: type,
            txInfo: body,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.MUSIC_UPLOAD.EPISODE:
        addAlert({
          title: "Episode Upload",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        await axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            type: type,
            txInfo: body,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.UPDATE_SONG:
        addAlert({
          title: "Song Update",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        await axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            txInfo: { songId: body?.songId, songInfo: body?.songInfo },
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.UPDATE_ALBUM:
        addAlert({
          title: "Album Update",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        await axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            txInfo: { albumId: body?.albumId, albumInfo: body?.albumInfo },
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.UPDATE_COLLECTION:
        addAlert({
          title: "Collection Update",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        await axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            txInfo: {
              collectionId: body?.collectionId,
              collectionInfo: body?.collectionInfo,
            },
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.UPDATE_EPISODE:
        addAlert({
          title: "Episode Update",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        await axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            txInfo: body,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.UPDATE_ROYALTY_SHARE:
        addAlert({
          title: "Royalty Update",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        await axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            txInfo: body,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.DELETE_COLLECTION:
        addAlert({
          title: "Collection Delete",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        await axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            txInfo: {
              collectionId: body?.collectionId,
              collectionInfo: body?.collectionInfo,
            },
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.ENABLE_PUSH_NOTIFICATIONS:
        addAlert({
          title: "Enabling Push Notifications",
          type: ALERT_TYPES.INFO,
        });
        await axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });

        break;

      case TRANSACTION_OPTIONS.DISABLE_PUSH_NOTIFICATIONS:
        addAlert({
          title: "Disabling Push Notifications",
          type: ALERT_TYPES.INFO,
        });
        await axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });

        break;

      case TRANSACTION_OPTIONS.DEACTIVATE_USER_ACCOUNT:
        addAlert({
          title: "User Account Deactivation",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        await axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.DIGITAL_SONG_PURCHASE:
        addAlert({
          title: "Digital Song Purchase",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        if (body?.value > 0) {
          await axios
            .post("/api/makeDigitalPayment", {
              sender: body.sender,
              value: body.value,
              pathfinding: body.pathfinding,
              currency: body.currency,
              invoice: true,
              userToken: localStorage.getItem("userToken"),
              instruction: body.instruction,
              memo: body.memo,
              txInfo: { ...body, type: "song" },
              type: type,
            })
            .then((res) => {
              if (!res?.data?.error && res?.data?.payload) {
                setInvoice(res.data.invoice);
                setPayload(res.data.payload);
              } else {
                setError(
                  "Oops! Something wrong happened. Reload the page"
                );
              }
              setLoaded(true);
            })
            .catch((err) => {
              console.error(err);
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
              setLoaded();
            });
        } else {
          axios
            .post("/api/signTransaction", {
              sender: body.sender,
              userToken: localStorage.getItem("userToken"),
              invoice: true,
              instruction: body.instruction,
              memo: body.memo,
              txInfo: { ...body, type: "song" },
              type: type,
            })
            .then((res) => {
              if (!res?.data?.error && res?.data?.payload) {
                setInvoice(res.data.invoice);
                setPayload(res?.data?.payload);
              } else {
                setError(
                  "Oops! Something wrong happened. Reload the page"
                );
              }
              setLoaded(true);
            })
            .catch((err) => {
              console.error(err);
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
              setLoaded();
            });
        }
        break;

      case TRANSACTION_OPTIONS.DIGITAL_ALBUM_PURCHASE:
        addAlert({
          title: "Digital Album Purchase",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        if (body.value > 0) {
          await axios
            .post("/api/makeDigitalPayment", {
              sender: body.sender,
              value: body.value,
              currency: body.currency,
              pathfinding: body.pathfinding,
              invoice: true,
              userToken: localStorage.getItem("userToken"),
              instruction: body.instruction,
              memo: body.memo,
              txInfo: { ...body, type: "album" },
              type: type,
            })
            .then((res) => {
              if (!res?.data?.error && res?.data?.payload) {
                setInvoice(res.data.invoice);
                setPayload(res.data.payload);
              } else {
                setError(
                  "Oops! Something wrong happened. Reload the page"
                );
              }
              setLoaded(true);
            })
            .catch((err) => {
              console.error(err);
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
              setLoaded();
            });
        } else {
          axios
            .post("/api/signTransaction", {
              sender: body.sender,
              userToken: localStorage.getItem("userToken"),
              invoice: true,
              instruction: body.instruction,
              memo: body.memo,
              txInfo: { ...body, type: "album" },
              type: type,
            })
            .then((res) => {
              if (!res?.data?.error && res?.data?.payload) {
                setInvoice(res.data.invoice);
                setPayload(res?.data?.payload);
              } else {
                setError(
                  "Oops! Something wrong happened. Reload the page"
                );
              }
              setLoaded(true);
            })
            .catch((err) => {
              console.error(err);
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
              setLoaded();
            });
        }
        break;

      case TRANSACTION_OPTIONS.PURCHASE_MUSIC_ROYALTY:
        addAlert({
          title: "Music Regium Shares Purchase",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        if (body.value > 0) {
          await axios
            .post("/api/makeRoyaltyTokenPurchase", {
              sender: body.sender,
              value: body.value,
              currency: body.currency,
              pathfinding: body.pathfinding,
              invoice: true,
              userToken: localStorage.getItem("userToken"),
              instruction: body.instruction,
              memo: body.memo,
              txInfo: { ...body, type: body?.purchaseType },
              type: type,
            })
            .then((res) => {
              if (!res?.data?.error && res?.data?.payload) {
                setInvoice(res.data.invoice);
                setPayload(res.data.payload);
              } else {
                setError(
                  "Oops! Something wrong happened. Reload the page"
                );
              }
              setLoaded(true);
            })
            .catch((err) => {
              console.error(err);
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
              setLoaded();
            });
        } else {
          axios
            .post("/api/signTransaction", {
              sender: body.sender,
              userToken: localStorage.getItem("userToken"),
              invoice: true,
              instruction: body.instruction,
              memo: body.memo,
              txInfo: { ...body, type: body?.purchaseType },
              type: type,
            })
            .then((res) => {
              if (!res?.data?.error && res?.data?.payload) {
                setInvoice(res.data.invoice);
                setPayload(res?.data?.payload);
              } else {
                setError(
                  "Oops! Something wrong happened. Reload the page"
                );
              }
              setLoaded(true);
            })
            .catch((err) => {
              console.error(err);
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
              setLoaded();
            });
        }
        break;

        case TRANSACTION_OPTIONS.GIFT_MUSIC_ROYALTY:
          addAlert({
            title: "Music Regium Shares Gift",
            type: ALERT_TYPES.INFO,
            secs: 10,
          });
          await axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            txInfo: {
              purchaser: body.sender,
              receiver: body?.receiver,
              royaltyId: body.royaltyId,
              type: body.type,
              quantity: body.quantity,
            },
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res?.data?.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError(
              "Oops! Something wrong happened. Reload the page"
            );
            setLoaded();
          });
          break;

        case TRANSACTION_OPTIONS.CLAIM_MUSIC_ROYALTY_RESERVES:
          addAlert({
            title: "Music Regium Shares Reserve Claim",
            type: ALERT_TYPES.INFO,
            secs: 10,
          });
          await axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            txInfo: {
              royaltyId: body.royaltyId,
              type: body.type,
              shareReturn: body.shareReturn,
            },
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res?.data?.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError(
              "Oops! Something wrong happened. Reload the page"
            );
            setLoaded();
          });
          break;

      case TRANSACTION_OPTIONS.CREATE_NFTOKEN_COLLECTION:
        addAlert({
          title: "NFToken Collection Create",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        await axios
          .post("/api/signTransaction", {
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            txInfo: body,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.NFTOKEN_MINT:
        addAlert({
          title: "NFToken Mint",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        await mintNft(
          body.collectionId,
          localStorage.getItem("userToken")
            ? localStorage.getItem("userToken")
            : "undefined"
        )
          .then((res) => {
            if (!res.error || res !== false) {
              setPayload(res?.payload);
              setInvoice(res?.invoice);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.NFTOKEN_PURCHASE:
        addAlert({
          title: "NFToken Collection Purchase",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        if (body.total > 0) {
          axios
            .post("/api/makeNFTokenPayment", {
              sender: body.sender,
              value: body.total,
              pathfinding: body.pathfinding,
              invoice: true,
              currency: body.currency,
              collection: body.collection,
              userToken: localStorage.getItem("userToken"),
              instruction: body.instruction,
              memo: body.memo,
              txInfo: {
                collectionId: body.collectionId,
                quantity: body.quantity,
                isWhitelist: body.isWhitelist,
                comment: body.comment,
              },
              type: type,
            })
            .then((res) => {
              if (!res?.data?.error && res?.data?.payload) {
                setInvoice(res.data.invoice);
                setPayload(res.data.payload);
              } else {
                setError(
                  "Oops! Something wrong happened. Reload the page"
                );
              }
              setLoaded(true);
            })
            .catch((err) => {
              console.error(err);
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
              setLoaded();
            });
        } else {
          axios
            .post("/api/signTransaction", {
              sender: body.sender,
              userToken: localStorage.getItem("userToken"),
              invoice: true,
              instruction: body.instruction,
              memo: body.memo,
              txInfo: {
                collectionId: body.collectionId,
                quantity: body.quantity,
                isWhitelist: body.isWhitelist,
                comment: body.comment,
              },
              type: type,
            })
            .then((res) => {
              if (!res?.data?.error && res?.data?.payload) {
                setInvoice(res.data.invoice);
                setPayload(res?.data?.payload);
              } else {
                setError(
                  "Oops! Something wrong happened. Reload the page"
                );
              }
              setLoaded(true);
            })
            .catch((err) => {
              console.error(err);
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
              setLoaded();
            });
        }

        break;

      case TRANSACTION_OPTIONS.NFTOKEN_GIFT:
        addAlert({
          title: "Gifting NFToken Collection",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        if (body?.isPurchase) {
          axios
            .post("/api/makeNFTokenPayment", {
              sender: body.sender,
              value: body.total,
              invoice: true,
              pathfinding: body?.pathfinding,
              currency: body.currency,
              collection: body.collection,
              userToken: localStorage.getItem("userToken"),
              instruction: body.instruction,
              memo: body.memo,
              type: type,
              txInfo: {
                uid: body.sender,
                isPurchase: body?.isPurchase,
                giftee: body?.giftee,
                total: body.total,
                collectionId: body.collectionId,
                quantity: body.quantity,
                comment: body.comment,
              },
            })
            .then((res) => {
              if (!res?.data?.error && res?.data?.payload) {
                setInvoice(res.data.invoice);
                setPayload(res.data.payload);
              } else {
                setError(
                  "Oops! Something wrong happened. Reload the page"
                );
              }
              setLoaded(true);
            })
            .catch((err) => {
              console.error(err);
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
              setLoaded();
            });
        } else {
          axios
            .post("/api/signTransaction", {
              sender: body.sender,
              userToken: localStorage.getItem("userToken"),
              instruction: body.instruction,
              memo: body.memo,
              txInfo: {
                uid: body.sender,
                isPurchase: body?.isPurchase,
                giftee: body?.giftee,
                total: body.total,
                collectionId: body.collectionId,
                quantity: body.quantity,
                comment: body.comment,
              },
              type: type,
            })
            .then((res) => {
              if (!res?.data?.error && res?.data?.payload) {
                setInvoice(res?.data?.invoice);
                setPayload(res?.data?.payload);
              } else {
                setError(
                  "Oops! Something wrong happened. Reload the page"
                );
              }
              setLoaded(true);
            })
            .catch((err) => {
              console.error(err);
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
              setLoaded();
            });
        }
        break;

      case TRANSACTION_OPTIONS.NFTOKEN_BURN:
        addAlert({
          title: "NFToken Burn",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        await axios
          .get(
            `api/${body.sender}/${body.tokenId}/${
              localStorage.getItem("userToken")
                ? localStorage.getItem("userToken")
                : "undefined"
            }/burnNFT`
          )
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res?.data?.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.NFTOKEN_TRANSFER:
        addAlert({
          title: "NFToken Transfer",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        await axios
          .get(
            `api/${body.sender}/${body.tokenId}/${body.destination}/${
              localStorage.getItem("userToken")
                ? localStorage.getItem("userToken")
                : "undefined"
            }/transferNFT`
          )
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res?.data?.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.UPDATE_USER_PROFILE:
        addAlert({
          title: "User Profile Update",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            txInfo: { profile: body.profile },
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res?.data?.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.NFTOKEN_OFFER_ACCEPT:
        addAlert({
          title: "NFToken Claim",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        return await axios
          .get(
            `/api/${body.sender}/${body.offer}/${
              localStorage.getItem("userToken")
                ? localStorage.getItem("userToken")
                : "undefined"
            }/acceptSellOffer`
          )
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res?.data?.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.NFTOKEN_OFFERS_ACCEPT:
        addAlert({
          title: "NFToken(s) Claim",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        return await axios
          .get(
            `/api/${body.sender}/${body?.offers[0]?.offer}/${
              localStorage.getItem("userToken")
                ? localStorage.getItem("userToken")
                : "undefined"
            }/acceptSellOffer`
          )
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setOffers(body?.offers);
              setInvoice(res?.data?.invoice);
              setPayload(res?.data?.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.SWERL_VOUCHER_OFFERS_ACCEPT:
        addAlert({
          title: "Vouchers(s) Claim",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        setPayload(body?.payload);
        setOffers(body?.offers);
        setNFTokenId(body?.offers[0]?.tokenId);
        setLoaded(true);
        break;

      case TRANSACTION_OPTIONS.CHANGE_ARTIST_LABEL_ADDRESS:
        addAlert({
          title: "Retreiving Wallet Address",
          type: ALERT_TYPES.INFO,
        });
        axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            txInfo: body,
            type: type,
            memo: body.memo,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.UPDATE_ARTIST_PROFILE:
        addAlert({
          title: "Artist Profile Update",
          type: ALERT_TYPES.INFO,
        });
        axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            txInfo: { artistId: body.artistId, profile: body.profile },
            type: type,
            memo: body.memo,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.UPDATE_PODCASTER_PROFILE:
        addAlert({
          title: "Artist Profile Update",
          type: ALERT_TYPES.INFO,
        });
        axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            txInfo: {
              podcasterId: body.podcasterId,
              profile: body.profile,
              type: type,
            },
            type: type,
            memo: body.memo,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.UPDATE_LABEL_PROFILE:
        addAlert({
          title: "Record Label Profile Update",
          type: ALERT_TYPES.INFO,
        });
        axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            txInfo: {
             ...body,
              type: type,
            },
            type: type,
            memo: body.memo,
          })
          .then((res) => {
            
            if (!res?.data?.error && res?.data?.payload) {
             
              setInvoice(res?.data?.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            console.log("body",body)
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.PAY_STREAMING_SUBSCRIPTION:
        addAlert({
          title: "Sonar Muse Streaming Subscription",
          type: ALERT_TYPES.INFO,
        });
        axios
          .post(`/api/sonarSubscription`, {
            sender: body.sender,
            pathfinding: body.pathfinding,
            months: body.months,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            txInfo: { months: body.months },
            type: type,
            memo: body.memo,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.TOP_UP_STREAMING_SUBSCRIPTION:
        addAlert({
          title: "Sonar Muse Streaming Subscription Top Up",
          type: ALERT_TYPES.INFO,
        });
        axios
          .post(`/api/makePayment`, {
             sender: body.sender,
            receiver: PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS,
            value: body.amount,
            currency: "XRP",
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            txInfo: { receiverId: body.receiver, sender: body.sender },
            memo: body.memo,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.REDEEM_SWERL_VOUCHER:
        addAlert({
          title: "Redeeming Sonar Muse Swerl Streaming Voucher",
          type: ALERT_TYPES.INFO,
        });
        axios
          .post(`/api/createRedeemVoucherOffer`, {
            owner: body.sender,
            tokenId: body.tokenId,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            txInfo: body,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.PURCHASE_SWERL_VOUCHER:
        addAlert({
          title: "Purchasing Swerl Streaming Voucher",
          type: ALERT_TYPES.INFO,
        });
        axios
          .post(`/api/makeSwerlVoucherPurchase`, {
            sender: body.sender,
            months: body.months,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            value: body.total,
            txInfo: { months: body.months },
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.ENABLE_WEB_MONETIZATION:
        addAlert({
          title: "Enabling Web Monetization",
          type: ALERT_TYPES.INFO,
        });

        return axios
          .post(`/api/enableWebMonetizationPayment`, {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            txInfo: body,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.AUTHORIZE_MONETIZATION_CLAIM:
        addAlert({
          title: "Authorization Receipt",
          type: ALERT_TYPES.INFO,
        });
        axios
          .post(`/api/authorizePaymentChannelReceipt`, {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            value: body.value,
            paymentChannel: body.paymentChannel,
            publicKey: body.publicKey,
            txInfo: body,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.AUTHORIZE_MONETIZATION_DISABLE:
        addAlert({
          title: "Authorization Receipt",
          type: ALERT_TYPES.INFO,
        });
        axios
          .post(`/api/authorizePaymentChannelReceipt`, {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            value: body.value,
            paymentChannel: body.paymentChannel,
            publicKey: body.publicKey,
            txInfo: body,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      case TRANSACTION_OPTIONS.CLAIM_MONETIZATION_FUNDS:
        addAlert({
          title: "Micropayments Funds Claim",
          type: ALERT_TYPES.INFO,
        });
        return axios
          .post(`/api/claimMicropayment`, {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            value: body.value,
            publicKey: body.publicKey,
            signature: body?.signature,
            paymentChannel: body.paymentChannel,
            txInfo: body,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;
        break;

      case TRANSACTION_OPTIONS.DISABLE_WEB_MONETIZATION:
        break;

      case TRANSACTION_OPTIONS.COLLECTION_WHITELIST:
        addAlert({
          title: "Collection Whitelist",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            txInfo: {
              collectionId: body?.collectionId,
              newList: body?.newList,
              removeList: body.removeList,
              startDate: body?.startDate,
              endDate: body?.endDate
            },
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res?.data?.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });

        break;

      case TRANSACTION_OPTIONS.GET_MUSIC_LIBRARY:
        addAlert({
          title: "Retreiving Music Library",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res?.data?.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        return;

      case TRANSACTION_OPTIONS.GET_ACCOUNT_INFO:
        addAlert({
          title: "Retreiving Account Information",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res?.data?.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        return;

      case TRANSACTION_OPTIONS.NFTOKEN_DISCOUNT:
        addAlert({
          title: "Retreiving Redeemable NFToken Discounts",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        axios
          .post("/api/signTransaction", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            instruction: body.instruction,
            memo: body.memo,
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res?.data?.invoice);
              setPayload(res?.data?.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        return;

      case TRANSACTION_OPTIONS.MARKETING_CAMPAIGN:
        addAlert({
          title: "Creating Marketing Campaign",
          type: ALERT_TYPES.INFO,
          secs: 10,
        });
        axios
          .post("/api/marketContent", {
            sender: body.sender,
            userToken: localStorage.getItem("userToken"),
            value: body.value,
            instruction: body.instruction,
            memo: body.memo,
            txInfo: {
              value: body?.value,
              dates: body?.dates,
              addToPlaylist: body?.addToPlaylist,
              playlistContent: body?.playlistContent,
              heading: body?.heading,
              type: body?.type,
              artists: body?.artists,
              image: body?.url,
              description: body?.description,
              link: body?.link,
              linkArt: body?.linkArt,
            },
            type: type,
          })
          .then((res) => {
            if (!res?.data?.error && res?.data?.payload) {
              setInvoice(res.data.invoice);
              setPayload(res.data.payload);
            } else {
              setError(
                "Oops! Something wrong happened. Reload the page"
              );
            }
            setLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setError("Oops! Something wrong happened. Reload the page");
            setLoaded();
          });
        break;

      default:
        break;
    }
  };

  const dispatch = async (response) => {
    switch (type) {
      case TRANSACTION_OPTIONS.TIP_USER:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "User Tipping",
          type: ALERT_TYPES.SUCCESS,
          message: `Tipping ${body.sender.substring(
            0,
            4
          )}...${body.sender.substring(body.sender.length - 5)} was Successful`,
        });
        onSuccess();
        return;

      case TRANSACTION_OPTIONS.ROYALTY_TRUSTLINE_SET:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        onSuccess({
          ...response,
          address: response?.address,
        });
        addAlert({
          title: "Royalty Share Token Trustline",
          type: ALERT_TYPES.SUCCESS,
          message: `Royalty Share Token Trustline Set was Successful`,
        });
        return;

      case TRANSACTION_OPTIONS.SUBSCRIBE:
        break;

      case TRANSACTION_OPTIONS.COMMENT:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Posting Comment",
          type: ALERT_TYPES.SUCCESS,
          message: `Comment successfully posted`,
        });
        onSuccess();
        return;
        break;

      case TRANSACTION_OPTIONS.ARTIST_PROFILE_CHECKER:
        return await artistProfileChecker(response?.address)
          .then((res) => {
            return onSuccess({ ...res, address: response?.address }, body.type);
          })
          .catch((err) => {
            return onFail(err);
          });

      case TRANSACTION_OPTIONS.ARTIST_REGISTRATION:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Artist Registration",
          type: ALERT_TYPES.SUCCESS,
          message: "Artist Registration Enabled",
        });
        return onSuccess(true);

      case TRANSACTION_OPTIONS.ENABLE_PUSH_NOTIFICATIONS:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Push Notifications",
          type: ALERT_TYPES.SUCCESS,
          message: "Push Notifications Enabled",
        });
        return onSuccess(true);

      case TRANSACTION_OPTIONS.DISABLE_PUSH_NOTIFICATIONS:
        localStorage.setItem("userToken", response?.userToken);
        addAlert({
          title: "Push Notifications",
          type: ALERT_TYPES.SUCCESS,
          message: "Push Notifications Disabled",
        });

        return onSuccess(false);

      case TRANSACTION_OPTIONS.UPDATE_USER_PROFILE:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }

        addAlert({
          title: "Profile Update",
          type: ALERT_TYPES.SUCCESS,
          message: "User Profile Update Successful",
        });
        return onSuccess();

      case TRANSACTION_OPTIONS.CHANGE_ARTIST_LABEL_ADDRESS:
        return; /*await axios
          .post("/api/verifySignin", { uuid: data.payload_uuidv4 })
          .then(async (value) => {
            //console.log(value?.data)
            if (value?.data && !value?.data?.error) {
              let address = value?.data?.address;
              if (response?.userToken) {
                localStorage.setItem("userToken", value?.response?.userToken);
              }
              return axios
                .get(`/api/userAddressInfo/${address}`)
                .then((res) => {
                  if (res?.data && !res?.data.error) {
                    addAlert({
                      title: "Retreiving Wallet Address",
                      type: ALERT_TYPES.SUCCESS,
                      message: `Retreiving Wallet Address Successful`,
                    });
                    return onSuccess(res?.data?.accInfo);
                  }
                  addAlert({
                    title: "Retreiving Wallet Address",
                    type: ALERT_TYPES.DANGER,
                    message: `Retreiving Wallet Address Unsuccessful`,
                  });
                  return onFail();
                })
                .catch((err) => {
                  addAlert({
                    title: "Retreiving Wallet Address",
                    type: ALERT_TYPES.DANGER,
                    message: `Retreiving Wallet Address Unsuccessful`,
                  });
                  return onFail();
                });
            }
            addAlert({
              title: "Retreiving Wallet Address",
              type: ALERT_TYPES.DANGER,
              message: `Retreiving Wallet Address Unsuccessful`,
            });
            return onFail();
          })
          .catch((err) => {
            addAlert({
              title: "Retreiving Wallet Address",
              type: ALERT_TYPES.DANGER,
              message: `Retreiving Wallet Address Unsuccessful`,
            });
            return onFail();
          }); */

      case TRANSACTION_OPTIONS.UPDATE_ARTIST_PROFILE:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        onSuccess();
        addAlert({
          title: "Profile Update",
          type: ALERT_TYPES.SUCCESS,
          message: "Artist Profile Update Successful",
        });
        return;
        break;

      case TRANSACTION_OPTIONS.UPDATE_PODCASTER_PROFILE:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        onSuccess();
        addAlert({
          title: "Profile Update",
          type: ALERT_TYPES.SUCCESS,
          message: "Podcaster Profile Update Successful",
        });
        return;
        break;

      case TRANSACTION_OPTIONS.UPDATE_LABEL_PROFILE:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        onSuccess();
        addAlert({
          title: "Profile Update",
          type: ALERT_TYPES.SUCCESS,
          message: "Record Label Profile Update Successful",
        });
        return;
        break;

      case TRANSACTION_OPTIONS.MUSIC_UPLOAD.SONG:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Music Upload",
          type: ALERT_TYPES.SUCCESS,
          message: "Music Upload Successful",
        });
        return onSuccess(response?.songId);

        break;

      case TRANSACTION_OPTIONS.MUSIC_UPLOAD.ALBUM:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Music Upload",
          type: ALERT_TYPES.SUCCESS,
          message: "Music Upload Successful",
        });
        return onSuccess(response.message);

      case TRANSACTION_OPTIONS.MUSIC_UPLOAD.EPISODE:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Episode Upload",
          type: ALERT_TYPES.SUCCESS,
          message: "Episode Upload Successful",
        });
        return onSuccess(response.episodeId);

      case TRANSACTION_OPTIONS.UPDATE_SONG:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Song Update",
          type: ALERT_TYPES.SUCCESS,
          message: "Song Update Successful",
        });
        return onSuccess(response.message);

      case TRANSACTION_OPTIONS.UPDATE_ALBUM:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Album Update",
          type: ALERT_TYPES.SUCCESS,
          message: "Album Update Successful",
        });
        return onSuccess(response.message);

      case TRANSACTION_OPTIONS.UPDATE_COLLECTION:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Collection Update",
          type: ALERT_TYPES.SUCCESS,
          message: "Collection Update Successful",
        });
        return onSuccess(response.message);

      case TRANSACTION_OPTIONS.UPDATE_EPISODE:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Episode Update",
          type: ALERT_TYPES.SUCCESS,
          message: "Episode Update Successful",
        });
        return onSuccess(response.message);

        case TRANSACTION_OPTIONS.UPDATE_ROYALTY_SHARE:
          if (response?.userToken) {
            localStorage.setItem("userToken", response?.userToken);
          }
          addAlert({
            title: "Royalty Update",
            type: ALERT_TYPES.SUCCESS,
            message: "Royalty Update Successful",
          });
          return onSuccess(response.message);

      case TRANSACTION_OPTIONS.DELETE_COLLECTION:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }

        return axios
          .get(
            `/api/collection/${body.sender}/${body.collectionId}/deleteCollection`
          )
          .then((res) => {
            if (res.data && !res.data.error) {
              addAlert({
                title: "Collection Delete",
                type: ALERT_TYPES.SUCCESS,
                message: "Collection Deletion Successful",
              });
              return onSuccess(res.data.message);
            }
            addAlert({
              title: "Collection Delete",
              type: ALERT_TYPES.DANGER,
              message: "Collection Deletion Unsuccessful",
            });
            return onFail();
          })
          .catch((err) => {
            addAlert({
              title: "Collection Delete",
              type: ALERT_TYPES.DANGER,
              message: "Collection Deletion Unsuccessful",
            });
            return onFail();
          });

      case TRANSACTION_OPTIONS.DIGITAL_SONG_PURCHASE:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        onSuccess({
          ...response,
          address: response?.address,
        });
        addAlert({
          title: "Digital Song Purchase",
          type: ALERT_TYPES.SUCCESS,
          message: `Song Purchase was Successful`,
        });
        return;

      case TRANSACTION_OPTIONS.DIGITAL_ALBUM_PURCHASE:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        onSuccess({
          ...response,
          address: response?.address,
        });
        addAlert({
          title: "Digital Album Purchase",
          type: ALERT_TYPES.SUCCESS,
          message: `Album Purchase was Successful`,
        });
        return;
      case TRANSACTION_OPTIONS.PURCHASE_MUSIC_ROYALTY:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        onSuccess(response);

        addAlert({
          title: "Music Regium Shares Purchase",
          type: ALERT_TYPES.SUCCESS,
          message: `Music Regium Shares Purchase Was Successful`,
        });
        break;
      
      case TRANSACTION_OPTIONS.GIFT_MUSIC_ROYALTY:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        onSuccess(response);

        addAlert({
          title: "Music Regium Shares Gift",
          type: ALERT_TYPES.SUCCESS,
          message: `Music Regium Shares Gifting Was Successful`,
        });
        break;

      case TRANSACTION_OPTIONS.CLAIM_MUSIC_ROYALTY_RESERVES:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        onSuccess(response);

        addAlert({
          title: "Music Regium Shares Reserves Claim",
          type: ALERT_TYPES.SUCCESS,
          message: `Music Regium Shares Reserves Claim Was Successful`,
        });
        break;
      
        case TRANSACTION_OPTIONS.CREATE_NFTOKEN_COLLECTION:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        onSuccess({
          name: body.title,
          mintInfo: { taxon: body.taxon },
          collectionId: response?.collectionId,
        });

        addAlert({
          title: "NFToken Collection Creation",
          type: ALERT_TYPES.SUCCESS,
          message: `NFToken Collection Creation Was Successful`,
        });
        return;

      case TRANSACTION_OPTIONS.NFTOKEN_PURCHASE:
        onSuccess({ offers: response?.offers, collection: body.collection });
        addAlert({
          title: "NFToken Purchase",
          type: ALERT_TYPES.SUCCESS,
          message: `NFToken Purchase Was Successful`,
        });

        //insert hook before purchase
        break;

      case TRANSACTION_OPTIONS.NFTOKEN_GIFT:
        onSuccess({ ...response });
        addAlert({
          title: "Gifting NFToken",
          type: ALERT_TYPES.SUCCESS,
          message: `Gifting NFToken Was Successful`,
        });
        //insert hook before purchase
        break;

      case TRANSACTION_OPTIONS.NFTOKEN_BURN:
        addAlert({
          title: "NFToken Burn",
          type: ALERT_TYPES.SUCCESS,
          message: `NFToken Burn Was Successful`,
        });
        return onSuccess();

      case TRANSACTION_OPTIONS.NFTOKEN_TRANSFER:
        addAlert({
          title: "NFToken Transfer",
          type: ALERT_TYPES.SUCCESS,
          message: `NFToken Transfer Was Successful`,
        });
        return onSuccess();

      case TRANSACTION_OPTIONS.NFTOKEN_MINT:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        onSuccess({ ...body, tokenId: response?.tokenId });
        addAlert({
          title: "NFToken Mint",
          type: ALERT_TYPES.SUCCESS,
          message: `NFToken Mint Was Successful`,
        });
        return;

      case TRANSACTION_OPTIONS.NFTOKEN_OFFER_ACCEPT:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        onSuccess({
          ...response,
          collection: body?.collection,
        });
        addAlert({
          title: "NFToken(s) Claim",
          type: ALERT_TYPES.SUCCESS,
          message: `Successfully Claimed An NFToken`,
        });
        return;

      case TRANSACTION_OPTIONS.NFTOKEN_OFFERS_ACCEPT:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        let remainingOffers = offers.filter((offer, index) => index !== 0);
        onSuccess({
          ...response,
          collection: body.collection,
          offers: remainingOffers,
        });
        addAlert({
          title: "NFToken(s) Claim",
          type: ALERT_TYPES.SUCCESS,
          message: `Successfully Claimed An NFToken`,
        });
        return;

      case TRANSACTION_OPTIONS.SWERL_VOUCHER_OFFERS_ACCEPT:
        /*        let remainingOffers = offers.filter(
          (offer, index) => index !== 0
        ); */

        addAlert({
          title: "Vouchers(s) Claim",
          type: ALERT_TYPES.SUCCESS,
          message: `Successfully Claimed An Voucher`,
        });
        return onSuccess({
          ...response,
          collection: body.collection,
          offers: remainingOffers,
        });

      case TRANSACTION_OPTIONS.DEACTIVATE_USER_ACCOUNT:
        return; /* deactivateUser(body, body.type, {
          txid: data.txid,
          payload: data.payload_uuidv4,
        })
          .then((data) => {
            if (!data.error || data !== false) {
              onSuccess();
              addAlert({
                title: "User Account Deactivation",
                type: ALERT_TYPES.SUCCESS,
                message: `Successfully deactivated account`,
              });
              return;
            }
            addAlert({
              title: "User Account Deactivation",
              type: ALERT_TYPES.SUCCESS,
              message: `Account Deactivation Successful`,
            });
          })
          .catch((err) => {
            onFail();
            addAlert({
              title: "User Account Deactivation",
              type: ALERT_TYPES.DANGER,
              message: `Account Deactivation Unsuccessful`,
            });
          }); */

      case TRANSACTION_OPTIONS.PAY_STREAMING_SUBSCRIPTION:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }

        addAlert({
          title: "Sonar Muse Streaming Subscription",
          type: ALERT_TYPES.SUCCESS,
          message: `Subscription Payment Successful`,
        });
        return onSuccess();

        break;

      case TRANSACTION_OPTIONS.TOP_UP_STREAMING_SUBSCRIPTION:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }

        addAlert({
          title: "Sonar Muse Streaming Subscription Top Up",
          type: ALERT_TYPES.SUCCESS,
          message: `Subscription Top Up Successful`,
        });
        return onSuccess();

        break;

      case TRANSACTION_OPTIONS.REDEEM_SWERL_VOUCHER:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }

        addAlert({
          title: "Sonar Muse Streaming Subscription",
          type: ALERT_TYPES.SUCCESS,
          message: `Subscription Payment Successful`,
        });
        return onSuccess();

        break;

      case TRANSACTION_OPTIONS.PURCHASE_SWERL_VOUCHER:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Purchasing Swerl Streaming Voucher",
          type: ALERT_TYPES.SUCCESS,
          message: `Streaming Voucher Purchase Successful`,
        });
        return onSuccess({ offers: response.offers });

        break;

      case TRANSACTION_OPTIONS.ENABLE_WEB_MONETIZATION:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }

        addAlert({
          title: "Enabling Web Monetization",
          type: ALERT_TYPES.SUCCESS,
          message: `Web Monetization Successfully Enabled`,
        });
        return onSuccess({
          paymentChannel: response?.paymentChannel,
          funds: response?.funds,
        });

        break;

      case TRANSACTION_OPTIONS.AUTHORIZE_MONETIZATION_CLAIM:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Authorization Receipt",
          type: ALERT_TYPES.SUCCESS,
          message: `Authorization Receipt Created Successfully`,
        });
        return onSuccess(response?.hex, response?.signer_pubkey);

        break;

      case TRANSACTION_OPTIONS.AUTHORIZE_MONETIZATION_DISABLE:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Authorization Receipt",
          type: ALERT_TYPES.SUCCESS,
          message: `Authorization Receipt Created Successfully`,
        });
        return onSuccess(
          response.payload_uuidv4,
          response.txid,
          response?.hex,
          response?.data?.signer_pubkey
        );

        break;

      case TRANSACTION_OPTIONS.CLAIM_MONETIZATION_FUNDS:
        //let address = value?.data?.address;
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Micropayment Funds Claim",
          type: ALERT_TYPES.SUCCESS,
          message: `Micropayments Fund Claim Successful`,
        });
        return onSuccess();
        break;

      case TRANSACTION_OPTIONS.DISABLE_WEB_MONETIZATION:
        break;

      case TRANSACTION_OPTIONS.COLLECTION_WHITELIST:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Collection Whitelist",
          type: ALERT_TYPES.SUCCESS,
          message: `Collection whitelist updated successfully`,
        });
        return onSuccess();

        break;

      case TRANSACTION_OPTIONS.GET_MUSIC_LIBRARY:
        //console.log(data)
        let address = response?.address;
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        return onSuccess(address);

      case TRANSACTION_OPTIONS.GET_ACCOUNT_INFO:
        console.log(response)
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        return onSuccess(response?.address);

      case TRANSACTION_OPTIONS.NFTOKEN_DISCOUNT:
        //console.log(data)
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        return onSuccess(address);

      case TRANSACTION_OPTIONS.PODCASTER_REGISTRATION:
        break;

      case TRANSACTION_OPTIONS.MARKETING_CAMPAIGN:
        if (response?.userToken) {
          localStorage.setItem("userToken", response?.userToken);
        }
        addAlert({
          title: "Marketing Campaign",
          type: ALERT_TYPES.DANGER,
          message: `Marketing Campaign Creation Successful`,
        });
        return onSuccess();

        break;

      default:
        break;
    }
  };

  const updateTxStatus = async (messageData) => {
    //console.log("Message Data: ", messageData);
    const data = JSON.parse(messageData);
    if (data.opened === true) {
      setOpened(true);
      setError("Wating for you to sign the transaction");
    }
    if (isOpened === true && data.signed === true) {
      setLoaded(false);
      setStatus("loading");
      /* dispatch(data); */
      setSigned(true);
      setPayload();
      setError();
      setOpened(false); //console.log("Signed")
      return;
    }
    if (isOpened === true && data?.signed === false) {
      addAlert({
        title: "Transaction Rejected",
        type: ALERT_TYPES.DANGER,
      });
      setLoaded(false);
      setPayload();
      onFail(body);
      cancelQr();
      setError(
        "The transaction was rejected. Reload the page and try again"
      );
      setOpened(false);
      return;
    }
    if (data?.expired === true && !data?.signed) {
      addAlert({
        title: "Transaction Expired",
        type: ALERT_TYPES.DANGER,
      });
      setLoaded(false);
      cancelQr();
      setOpened(false);
      setError(
        "The transaction was expired. Reload the page and try again"
      );
    }
    if (data?.signed === false) {
      addAlert({
        title: "Transaction Rejected",
        type: ALERT_TYPES.DANGER,
      });

      setLoaded(false);
      setPayload();
      onFail();
      setError(
        "The transaction was rejected. Reload the page and try again"
      );
      setOpened(false);
      return;
    }
  };

  const validateTx = async () => {
    setLoaded(false);
    return await axios
      .post("/api/verifyTxPayload", { uuid: payload?.uuid })
      .then((verification) => {
        //console.log(verification)
        if (verification?.data && !verification?.data?.error) {
          setLoaded(false);
          //dispatch(verification?.data);
          getSignTxStatus(invoice, status, setStatus, setResponse);
          setSigned(true);
          setPayload();
          setError("The transaction went through successfully.");
          setOpened(false); //console.log("Signed")
          return;
        } else {
          setSigned(false);
          setLoaded(true);
          setError("The transaction was not signed.");
          return;
        }
      })
      .catch((err) => {
        console.error(err);
        setSigned(false);
        setLoaded(true);
        setError("Something went wrong. Try again");
      });
  };

  const refreshPage = () => {
    setPayload();
    setError("");
    setInvoice();
    setResponse({});
    setOpened(false);
    setLoaded(false);
    SignTx();
  };

  //console.log(payload)

  return (
    <>
      {!isLoaded && errMsg?.trim()?.length === 0 && (
        <FormWrap>
          <ModalLoadingScreen
            text={"Loading. Please Wait..."}
            transparent={true}
          />
        </FormWrap>
      )}
      {isLoaded === true && <>{payload && Object.keys(payload).length > 0 && (
        <>
          {" "}
          <WebSocket
            url={payload?.refs?.websocket_status}
            onMessage={(data) => updateTxStatus(data)}
          />
          <FormWrap>
            <FormContent>
              <Form action={(e) => e.preventDefault}>
                <FormH1> {heading} </FormH1>
                <QrWrapper>
                  <QrCode src={payload?.refs?.qr_png} />
                </QrWrapper>
                {isOpened === false && (
                  <Text>
                    Scan QR code or click{" "}
                    <TextLink
                      href={payload?.next?.always}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </TextLink>{" "}
                    to go to your Xaman wallet
                  </Text>
                )}
                {isOpened === true && (
                  <Text>
                    <Text>{errMsg}</Text>
                  </Text>
                )}
                <FormButton1 onClick={() => validateTx()} transparent>
                  I Already Signed Transaction
                </FormButton1>
                <FormButton
                  transparent={false}
                  to={`#${type}`}
                  onClick={refreshPage}
                >
                  Reload
                </FormButton>
                <CustomButton transparent={true} onClick={cancelQr}>
                  Cancel
                </CustomButton>
                {children}
              </Form>
            </FormContent>
          </FormWrap>
        </>
      )}</>
      }
      {!isLoaded && errMsg?.trim()?.length > 0 && (
        <>
          <OptionModal heading={errMsg}>
            <HoverOption onClick={refreshPage}>Try Again</HoverOption>
            <HoverOption onClick={cancelQr}>OK</HoverOption>
          </OptionModal>
        </>
      )}
    </>
  );
};

export default QRCodeForm;
