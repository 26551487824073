import React, { useState } from 'react'
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import { Home } from '../../components/Navbar/Data';
import NFTokenWhitelister from '../../components/NFTokenWhitelister';
import Sidebar from '../../components/Sidebar';

const NFTWhitelister = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}    

  return (
    <>
        <Sidebar
            isOpen={isOpen}
            toggle={toggle}
            navInfo={Home(localStorage.getItem("accInfo"))}
          />
            <Navbar toggle={toggle} navInfo={Home(localStorage.getItem("accInfo"))}/>
            <NFTokenWhitelister/>
            
    </>
  )
}

export default NFTWhitelister