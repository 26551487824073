import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";

export const MEDIA_SCREEN = { //Screen sizes for devices
  MOBILE: '480',
  TABLETS: '768',
  LAPTOPS: '1024',
  DESKTOPS: '1025',
  TV: '1500'
}

export const ToolTipContainer = styled.div`
    position: relative;
    display: flex;
    margin-right: 1%;
    border-bottom: ${props => props.theme.secondaryBg};

    

`

export const ToolTipText = styled.div`
visibility: hidden;
  width: max-content;
  font-size: 0.9rem;
  background: ${props => hexToRgba(props.theme.mainBg,0.8)};
  color: ${props => props.theme.secondaryBg};
  text-align: center;
  padding: 4%;
  border-radius: 6px;
  transition: 0.1s all ease;
    /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 100;
  white-space: wrap;
  top: -5px;
  left: 105%;
     ${ToolTipContainer}: hover &{
        transition: 1s all ease;
        visibility: visible;
    }

  @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
   font-size: 0.55rem;

  }
`