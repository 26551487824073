import React, { useEffect, useState } from "react";
import { ALERT_TYPES, PODCASTER_SEARCH_TYPES } from "../../../utils/Enum";
import ImageUtils from "../../../utils/ImageUtils";
import { useAlert } from "../../Alert/AlertContext";
import { getRandomColor } from "../../HexToRGBA";
import { CardBody } from "../CardElements";
import {
  CardContainer,
  CreatorInfo,
  CreatorInfoWrapper,
  H6,
  InfoContentText,
  PodcastCardBody,
  PodcastCardContent,
  PodcastCardTitle,
  PodcastCardWrapper,
  PodcastHeading,
  PodcastImg,
  PodcastImgContainer,
} from "./PodcastCardElement";
import ScrollToTop from "../../ScrollToTop";
import { getPodcastersNames, getPodcastersNames2 } from "../../../utils";
import { Small } from "../../QRCodeForm/QrCodeFormElements";
import { SmallText } from "../../NFTCollectionCreator/NFTCollectionCreatorElements";
import { useModal } from "../../Modal/ModalContext";
import { MusicContentMenu, PodcasterSelectorModal } from "../../MenuOptions";
import { RiVideoFill } from "react-icons/ri";
import { MdExplicit } from "react-icons/md";
import { Theme } from "../../WebsiteThemeElements";
import { Link } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { getResizedImage } from "../../../utils/FileUploadsUtils";

const PodcastCard = ({ content, cardRef }) => {
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const [color, setColor] = useState(getRandomColor());
  const {currentUser} = useAuth()
  const { openModal, closeModal, modalContent } = useModal();
  const { addAlert } = useAlert();
  const { goTo, scrollToTop } = ScrollToTop();
  const [isAlbumArtLoaded,setAlbumArtLoaded] = useState(false)
  const [albumArt, setAlbumArt] = useState(content?.thumbnail)

  useEffect(() => {
    if(!isAlbumArtLoaded){
      getResizedImage(content?.thumbnail).then(image => {
        setAlbumArt(image)
        
      }).catch(err => {
        setAlbumArt(content?.thumbnail)
      })
      setAlbumArtLoaded(true)
    }
  }, [])

  return (
    <CardContainer>
      <PodcastCardWrapper
        ref={cardRef}
        color={color}
        src={isAlbumArtLoaded ? albumArt : content?.thumbnail ? content?.thumbnail : defaultAlbumArt}
        onClick={() => {
          if (content?.episodeId) {
            /* goTo(`/episode/${content?.episodeId}`) */
          }
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          if (content?.episodeId && currentUser?.uid) {
            openModal();
            modalContent(<MusicContentMenu music={content} />);
          }
        }}
      >
        <PodcastImgContainer
          onClick={() => {
            if (content?.episodeId) {
              goTo(`/episode/${content?.episodeId}`);
              /*addAlert({
              title: "Not Enabled",
              type: ALERT_TYPES.WARNING,
              message: "Coming Soon",
            })
            {openModal(); modalContent(<UnavailableFeature/>)}*/
            }
          }}
        >
          <PodcastImg />
        </PodcastImgContainer>
      </PodcastCardWrapper>
      <PodcastCardContent>
        <H6>Episode</H6>
        <PodcastCardTitle
          onClick={() => {
            if (content?.episodeId) {
              goTo(`/episode/${content?.episodeId}`);
              /* addAlert({
              title: "Not Enabled",
              type: ALERT_TYPES.WARNING,
              message: "Coming Soon",
            })
            {openModal(); modalContent(<UnavailableFeature/>)}*/
            }
          }}
        >
          {content?.episodeName.length > 50
            ? `${content?.episodeName.substring(0, 50)}...`
            : content?.episodeName}
          <div>
            {content?.explicit && <MdExplicit />}
            {content?.video?.url && <RiVideoFill />}
          </div>
        </PodcastCardTitle>
        <CreatorInfoWrapper>
        <CreatorInfo>
          <InfoContentText>
          <Link
                  style={{
                    textDecoration: "none",
                    color: Theme({}).secondaryBg,
                  }}
                  onClick={() => {
                    if(content?.podcasters?.length > 1){
                        openModal()
                       return modalContent(<PodcasterSelectorModal podcasters={content?.podcasters}/>)
                    }
                    return scrollToTop()
                  }}
                  to={
                    content?.podcasters?.length === 1 ? `/podcaster/${content?.podcasters[0].podcasterId}`: 
                     "#"
                  }
                >{getPodcastersNames2(
                  content?.podcasters,
                  PODCASTER_SEARCH_TYPES.NAME)
                  ?.length > 30
                  ? `${getPodcastersNames2(
                    content?.podcasters,
                    PODCASTER_SEARCH_TYPES.NAME
                    ).substring(
                      0,
                      25
                    )}...`
                  : getPodcastersNames2(
                    content?.podcasters,
                    PODCASTER_SEARCH_TYPES.NAME
                    )}
                </Link>
          </InfoContentText>
        </CreatorInfo>
        </CreatorInfoWrapper>
        <CreatorInfoWrapper>
          {content?.about && <CreatorInfo>
            <InfoContentText
              style={{ color: Theme({}).secondaryBg, fontSize: "0.65rem", fontStyle: "italic", textDecoration: "none" }}
              onClick={() => {
                if (content?.episodeId) {
                  goTo(`/episode/${content?.episodeId}`);
                  /*addAlert({
              title: "Not Enabled",
              type: ALERT_TYPES.WARNING,
              message: "Coming Soon",
            })
            {openModal(); modalContent(<UnavailableFeature/>)}*/
                }
              }}
            >
              {content?.about
                ? content?.about.length > 70
                  ? `${content?.about?.substring(0, 70)}...`
                  : content?.about
                : ""}
            </InfoContentText>
          </CreatorInfo>}
        </CreatorInfoWrapper>
      </PodcastCardContent>
    </CardContainer>
  );
};

export default PodcastCard;
