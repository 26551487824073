import styled from "styled-components";
import hexToRgbA, { hexToRgba } from "../HexToRGBA";


export const ImageSliderContainer = styled.div`
 display: flex;
 position: relative;
 padding: 1% 2%;
 color: white;
 justify-content: center;
 align-items: center;
 width: 100%;
 background: ${props => hexToRgba(props.theme.secondaryBg, 0.008)};
 min-height: 30%;
`

export const ImageMainSlider = styled.div`
 display: flex;
 margin: 0px 2%;
 height: 100%;
 width: 100%;
 padding: 2% 0;
 white-space: nowrap;
 overflow-x: scroll;
 scrollbar-width: none;
 scroll-behavior: smooth;
 ::-webkit-scrollbar{
     display: none; 
 }
 
 `

export const ImageSliderIcon = styled.div`
display: flex;
font-size: 2rem;
border-radius: 100%;
position: absolute;
color: ${props => props.theme.SecondaryBg};
cursor: pointer;
z-index: 2;
&:hover{
    transition: all 0.2s ease-in-out;
    transform: scale(1.1);
}
box-shadow; 2px 2px 2px 2px rgb(0 0 0 / 124);
`

export const ImageSliderCard = styled.div`
display: flex;
width: 10rem;
height: 10rem;
display: inline-block;
flex: none;
order: 1;
flex-grow: 0;
overflow: hidden;
position: relative;
border-radius: 5% 5% 5% 5%;
margin: 1% 0.9%;
cursor: pointer;
box-shadow: 0 1px 3px ${props => hexToRgba(props.theme.accentColor, 0.9)};
`

export const ImageSliderCardImg = styled.div`
width: 100%;
height: 100%;
background: ${props => hexToRgba(props.theme.secondaryColor, 0.4)};
transition: .5s;
background-size: contain;
`

export const Img = styled.img`
    width: 100%;
    height: 100%;
    background-size: contain;
    object-fit: cover;

`
