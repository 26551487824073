import React, { useState } from 'react'
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import { Home, Welcome } from '../../components/Navbar/Data';
import Sidebar from '../../components/Sidebar';
import LatestRoyalties from '../../components/ViewAllContent/LatestRoyalties';

const LatestRoyaltiesPage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}    

  return (
    <>
            <Navbar toggle={toggle}  navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>

<Sidebar
      isOpen={isOpen}
      toggle={toggle}
      navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
    />

            <LatestRoyalties/>
            
    </>
  )
}

export default LatestRoyaltiesPage