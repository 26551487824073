import styled from "styled-components";
import { CustomButton } from "../ButtonElement";


export const CommentsFormContainer = styled.div`
display: flex;
flex-direction: row;
gap: .5rem;
width: 100%;
padding: 2%;

`

export const MessageInput = styled.textarea`
flex-grow: 1;
  resize: none;
  height: 70px;
  width: 100%;
  border-radius: .5em;
  padding: .5em;
  font-size: inherit;
  font-family: inherit;
  border: 2px solid hsl(235, 50%, 74%);
  line-height: 1.4;
  background: transparent;
  color: ${props => props.theme.secondaryBg};
`

export const PostButton = styled.button`
    border-radius: 15px;
    padding: 2%; 
    background-color: ${props => props.theme.secondaryColor};
    color: ${props => props.theme.mainColor};
    cursor: pointer;

    &:hover {
      background-color: ${props => props.theme.mainColor};
      color: ${props => props.theme.secondaryColor};

    }

`