import React, {useEffect} from 'react'
import { FaFacebook, FaFlickr, FaFoursquare, FaLink, FaTumblr, FaTwitter, FaVimeoV, FaWordpressSimple } from 'react-icons/fa';
import {Container,SocialLink, UrlLink} from './SocialLinksElements'


const SocialLinks = ({accounts, background}) => {
   
    const displayAccounts = (accs) => {
        return accs.map((account) => {
          
              return (
                <>
                  
                    <SocialLink background={background} target="_blank" rel="noopener noreferrer" key={account?.service_type} href={account?.url}>
                    <img src={account?.service_icon} alt={account?.service_type}/>
                    </SocialLink>
                </>
              );
    
        });
      };
   
    return(
        <>
            <Container background={background}>
           { accounts && displayAccounts(accounts)}
            </Container>
        </>
    )
}

export const UrlLinks = ({urlLinks, background}) =>{

    const displayUrls = (links) => {
        return links?.map((link, id) => {
          return (
            <>
              <UrlLink key={id} background={background} target="_blank" rel="noopener noreferrer" href={link.url}>
                <FaLink style={{marginRight: '1%'}}/>
                  {link.label}
              </UrlLink>
            </>
          );
        })
       }


       return(
        <>
            <Container background={background} >
            {urlLinks && displayUrls(urlLinks)}
            </Container>
        </>
    )
}

export default SocialLinks