import { useEffect, useState } from 'react'
import axiosInstance from '../components/Authorization/client'
import { getUniqueListBy } from '../utils';
const axios = axiosInstance;

const useAlbumSearch = ({pageNumber}) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [albums, setAlbums] = useState([])
    const [pages, setPages] = useState(0)
    useEffect(() => {
        setLoading(true)
        setError(false)
        const controller = new AbortController();
        axios
        .get(`/api/albums/getLatest/${pageNumber}`, {signal: controller.signal})
        .then((albums) => {
          setAlbums(prev => {return  [...new Set([...prev, ...albums?.data?.albums])]})
        setPages(albums.data.length)
        setLoading(false)
        })
        .catch((err) => {
          console.error(err);
          setLoading(false)
          setError(true)
          return false;
        });
    
      return () => {
        controller.abort()
      }
    }, [pageNumber])
    
  return {loading, error, albums, pages, setPages}
}

export const useSongSearch = ({pageNumber}) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [songs, setSongs] = useState([])
    const [pages, setPages] = useState(0)
    useEffect(() => {
        setLoading(true)
        setError(false)
        const controller = new AbortController();
        axios
        .get(`/api/songs/getLatest/${pageNumber}`, {signal: controller.signal})
        .then((songs) => {
          setSongs(prev => {return  [...new Set([...prev, ...songs?.data?.songs])]})
        setPages(songs.data.length)
        setLoading(false)
        })
        .catch((err) => {
          console.error(err);
          setLoading(false)
          setError(true)
          return false;
        });
    
      return () => {
        controller.abort()
      }
    }, [pageNumber])
    
  return {loading, error, songs, pages, setPages}
}

export const useDigitalAlbumSearch = ({pageNumber}) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [albums, setAlbums] = useState([])
    const [pages, setPages] = useState(0)
    useEffect(() => {
        setLoading(true)
        setError(false)
        const controller = new AbortController();
        axios
        .get(`/api/albums/getDownloadable/${pageNumber}`, {signal: controller.signal})
        .then((albums) => {
          setAlbums(prev => {return  [...new Set([...prev, ...albums?.data?.albums])]})
        setPages(albums.data.length)
        setLoading(false)
        })
        .catch((err) => {
          console.error(err);
          setLoading(false)
          setError(true)
          return false;
        });
    
      return () => {
        controller.abort()
      }
    }, [pageNumber])
    
  return {loading, error, albums, pages, setPages}
}

export const useDigitalSongSearch = ({pageNumber}) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [songs, setSongs] = useState([])
    const [pages, setPages] = useState(0)
    useEffect(() => {
        setLoading(true)
        setError(false)
        const controller = new AbortController();
        axios
        .get(`/api/songs/getDownloadable/${pageNumber}`, {signal: controller.signal})
        .then((songs) => {
          setSongs(prev => {return  [...new Set([...prev, ...songs?.data?.songs])]})
        setPages(songs.data.length)
        setLoading(false)
        })
        .catch((err) => {
          console.error(err);
          setLoading(false)
          setError(true)
          return false;
        });
    
      return () => {
        controller.abort()
      }
    }, [pageNumber])
    
  return {loading, error, songs, pages, setPages}
}

export const useTopAlbumsSearch = ({pageNumber}) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [albums, setAlbums] = useState([])
    const [pages, setPages] = useState(0)
    useEffect(() => {
        setLoading(true)
        setError(false)
        const controller = new AbortController();
        axios
        .get(`/api/albums/getTopAlbums/${pageNumber}`, {signal: controller.signal})
        .then((albums) => {
          setAlbums(prev => {return  [...new Set([...prev, ...albums?.data?.albums])]})
        setPages(albums.data.length)
        setLoading(false)
        })
        .catch((err) => {
          console.error(err);
          setLoading(false)
          setError(true)
          return false;
        });
    
      return () => {
        controller.abort()
      }
    }, [pageNumber])
    
  return {loading, error, albums, pages, setPages}
}

export const useTopSongsSearch = ({pageNumber}) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [songs, setAlbums] = useState([])
    const [pages, setPages] = useState(0)
    useEffect(() => {
        setLoading(true)
        setError(false)
        const controller = new AbortController();
        axios
        .get(`/api/songs/getTopSongs/${pageNumber}`, {signal: controller.signal})
        .then((songs) => {
          setAlbums(prev => {return  [...new Set([...prev, ...songs?.data?.songs])]})
        setPages(songs.data.length)
        setLoading(false)
        })
        .catch((err) => {
          console.error(err);
          setLoading(false)
          setError(true)
          return false;
        });
    
      return () => {
        controller.abort()
      }
    }, [pageNumber])
    
  return {loading, error, songs, pages, setPages}
}

export const useCollectionSearch = ({pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [collections, setCollections] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/collections/${pageNumber}/getCollections`, {signal: controller.signal})
      .then((collections) => { 
      setCollections(prev => {return  [...new Set([...prev, ...collections?.data?.collections])]})
      setPages(collections.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber])
  
return {loading, error, collections, pages, setPages}
}

export const useLatestRoyaltiesSearch = ({pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [royalties, setRoyalties] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/royalties/${pageNumber}/getMusicRoyalties`, {signal: controller.signal})
      .then((royalties) => { 
      setRoyalties(prev => {return  [...new Set([...prev, ...royalties?.data?.royalties])]})
      setPages(royalties.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber])
  
return {loading, error, royalties, pages, setPages}
}

export const useLatestEpisodeSearch = ({pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [episodes, setEpisodes] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/episodes/getLatest/${pageNumber}`, {signal: controller.signal})
      .then((episodes) => { 
        setEpisodes(prev => {return  [...new Set([...prev, ...episodes?.data?.episodes])]})
      setPages(episodes.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber])
  
return {loading, error, episodes, pages, setPages}
}

export const useLatestPlaylistSearch = ({pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [playlists, setPlaylists] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/playlists/${pageNumber}/getNewest`, {signal: controller.signal})
      .then((playlists) => { 
        setPlaylists(prev => {return  [...new Set([...prev, ...playlists?.data?.playlists])]})
      setPages(playlists.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber])
  
return {loading, error, playlists, pages, setPages}
}

export const useTrendingPlaylistSearch = ({pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [playlists, setPlaylists] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/playlists/${pageNumber}/getTrending`, {signal: controller.signal})
      .then((playlists) => { 
        setPlaylists(prev => {return  [...new Set([...prev, ...playlists?.data?.playlists])]})
      setPages(playlists.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber])
  
return {loading, error, playlists, pages, setPages}
}

export const usePlaylistsContainingSongSearch = ({songId, pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [playlists, setPlaylists] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/playlists/${songId}/${pageNumber}/getPlaylistsContainingSong`, {signal: controller.signal})
      .then((playlists) => { 
        setPlaylists(prev => {return  [...new Set([...prev, ...playlists?.data?.playlists])]})
      setPages(playlists.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber, songId])
  
return {loading, error, playlists, pages, setPages}
}

export const useUserPlaylistsSearch = ({userId, pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [playlists, setPlaylists] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/user/${userId}/getPlaylists/${pageNumber}`, {signal: controller.signal})
      .then((playlists) => { 
        setPlaylists(prev => {return  [...new Set([...prev, ...playlists?.data?.playlists])]})
      setPages(playlists.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber, userId])
  
return {loading, error, playlists, pages, setPages}
}

export const useCollabPlaylistsSearch = ({userId, pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [playlists, setPlaylists] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/user/${userId}/getCollaborativePlaylists/${pageNumber}`, {signal: controller.signal})
      .then((playlists) => { 
        setPlaylists(prev => {return  [...new Set([...prev, ...playlists?.data?.playlists])]})
      setPages(playlists.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber, userId])
  
return {loading, error, playlists, pages, setPages}
}

export const useArtistSongsSearch = ({artistId, pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [songs, setSongs] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/${artistId}/getArtistSongs/${pageNumber}`, {signal: controller.signal})
      .then((songs) => {
        setSongs(prev => {return  [...new Set([...prev, ...songs?.data?.songs])]})
      setPages(songs.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber, artistId])
  
return {loading, error, songs, pages, setPages}
}

export const useArtistSinglesSearch = ({artistId, pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [songs, setSongs] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/${artistId}/getArtistSingles/${pageNumber}`, {signal: controller.signal})
      .then((songs) => {
        setSongs(prev => {return  [...new Set([...prev, ...songs?.data?.songs])]})
      setPages(songs.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber, artistId])
  
return {loading, error, songs, pages, setPages}
}

export const useArtistAlbumsSearch = ({artistId, pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [albums, setAlbums] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/${artistId}/getArtistAlbums/${pageNumber}`, {signal: controller.signal})
      .then((albums) => {
        setAlbums(prev => {return  [...new Set([...prev, ...albums?.data?.albums])]})
      setPages(albums.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber, artistId])
  
return {loading, error, albums, pages, setPages}

}

export const useArtistAppearencesSearch = ({artistId, pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [appearences, setAppearences] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      let feat = axios.get(`/api/${artistId}/getFeaturedSongs/${pageNumber}`, {signal: controller.signal})
      let composed = axios.get(`/api/${artistId}/getComposedSongs/${pageNumber}`, {signal: controller.signal})
      let written = axios.get(`/api/${artistId}/getWrittenSongs/${pageNumber}`, {signal: controller.signal})
      let produced = axios.get(`/api/${artistId}/getProducedSongs/${pageNumber}`, {signal: controller.signal})
      Promise.all([feat, composed, written, produced]).then((items) => {
        feat = items[0]?.data?.songs.length > 0 ? items[0]?.data?.songs : []
        composed = items[1]?.data?.songs.length > 0 ? items[1]?.data?.songs : []
        written = items[2]?.data?.songs.length > 0 ? items[2]?.data?.songs : []
        produced = items[3]?.data?.songs.length > 0 ? items[3]?.data?.songs : []
        setAppearences(getUniqueListBy([...feat, ...composed,...written,...produced], ""))
      setPages(0)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber, artistId])
  
return {loading, error, appearences, pages, setPages}

}

export const useArtistCollectionsSearch = ({userId, pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [collections, setCollections] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/collections/${userId}/getNFTokenCollections/${pageNumber}`, {signal: controller.signal})
      .then((collections) => { 
      setCollections(prev => {return  [...new Set([...prev, ...collections?.data?.collections])]})
      setPages(collections.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber, userId])
  
return {loading, error, collections, pages, setPages}

}

export const useArtistRoyaltiesSearch = ({userId, pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [royalties, setRoyalties] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/royalties/${userId}/getMusicRoyaltiesByArtist/${pageNumber}`, {signal: controller.signal})
      .then((royalties) => { 
      setRoyalties(prev => {return  [...new Set([...prev, ...royalties?.data?.royalties])]})
      setPages(royalties.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber, userId])
  
return {loading, error, royalties, pages, setPages}

}

export const useSimilarAlbumsSearch = ({albumId, pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [albums, setAlbums] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/albums/${albumId}/getSimilarAlbumsByAlbumId/${pageNumber}`, {signal: controller.signal})
      .then((albums) => {
        setAlbums(prev => {return  [...new Set([...prev, ...albums?.data?.albums])]})
      setPages(albums.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber, albumId])
  
return {loading, error, albums, pages, setPages}

}

export const useSimilarSongsSearch = ({songId, pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [songs, setAlbums] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/songs/${songId}/getSimilarSongs/${pageNumber}`, {signal: controller.signal})
      .then((songs) => {
        setAlbums(prev => {return  [...new Set([...prev, ...songs?.data?.songs])]})
      setPages(songs.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber, songId])
  
return {loading, error, songs, pages, setPages}
}

export const useSimilarCollectionsSearch = ({type, pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [collections, setCollections] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/collections/${type}/getSimilarCollections/${pageNumber}`, {signal: controller.signal})
      .then((collections) => { 
      setCollections(prev => {return  [...new Set([...prev, ...collections?.data?.collections])]})
      setPages(collections.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber, type])
  
return {loading, error, collections, pages, setPages}
}

export const useCollectionsBySongIdSearch = ({songId, pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [collections, setCollections] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/collection/${songId}/getCollectionsBySongId/${pageNumber}`, {signal: controller.signal})
      .then((collections) => { 
      setCollections(prev => {return  [...new Set([...prev, ...collections?.data?.collections])]})
      setPages(collections.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber, songId])
  
return {loading, error, collections, pages, setPages}
}

export const useCollectionsByAlbumIdSearch = ({albumId, pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [collections, setCollections] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/collection/${albumId}/getCollectionsBySongId/${pageNumber}`, {signal: controller.signal})
      .then((collections) => { 
      setCollections(prev => {return  [...new Set([...prev, ...collections?.data?.collections])]})
      setPages(collections.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber, albumId])
  
return {loading, error, collections, pages, setPages}
}

export const useSimilarRoyaltiesSearch = ({type, pageNumber}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [royalties, setRoyalties] = useState([])
  const [pages, setPages] = useState(0)
  useEffect(() => {
      setLoading(true)
      setError(false)
      const controller = new AbortController();
      axios
      .get(`/api/royalties/${type}/${pageNumber}/getMusicRoyaltiesByType`, {signal: controller.signal})
      .then((royalties) => { 
      setRoyalties(prev => {return  [...new Set([...prev, ...royalties?.data?.royalties])]})
      setPages(royalties.data.length)
      setLoading(false)
      })
      .catch((err) => {
        console.error(err);
        setLoading(false)
        setError(true)
        return false;
      });
  
    return () => {
      controller.abort()
    }
  }, [pageNumber, type])
  
return {loading, error, royalties, pages, setPages}
}

export const useMusicVideosSearch = ({pageNumber}) => {
  const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [videos, setVideos] = useState([])
    const [pages, setPages] = useState(0)
    useEffect(() => {
        setLoading(true)
        setError(false)
        const controller = new AbortController();
        axios
        .get(`/api/videos/getLatestSongVideos/${pageNumber}`, {signal: controller.signal})
        .then((vids) => {
          setVideos(prev => {return  [...new Set([...prev, ...vids?.data?.videos])]})
        setPages(vids.data.length)
        setLoading(false)
        })
        .catch((err) => {
          console.error(err);
          setLoading(false)
          setError(true)
          return false;
        });
    
      return () => {
        controller.abort()
      }
    }, [pageNumber])
    
  return {loading, error, videos, pages, setPages}
}

export const useArtistMusicVideosSearch = ({artistId, pageNumber}) => {
  const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [songs, setSongs] = useState([])
    const [pages, setPages] = useState(0)
    useEffect(() => {
        setLoading(true)
        setError(false)
        const controller = new AbortController();
        axios
        .get(`/api/videos/${artistId}/getLatestSongVideos/${pageNumber}`, {signal: controller.signal})
        .then((songs) => {
          setSongs(prev => {return  [...new Set([...prev, ...songs?.data?.songs])]})
        setPages(songs.data.length)
        setLoading(false)
        })
        .catch((err) => {
          console.error(err);
          setLoading(false)
          setError(true)
          return false;
        });
    
      return () => {
        controller.abort()
      }
    }, [pageNumber, artistId])
    
  return {loading, error, songs, pages, setPages}
}







export default useAlbumSearch