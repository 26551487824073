import React, { useState } from 'react'

import { Home, Welcome } from '../../components/Navbar/Data';
import LatestPlaylists from '../../components/ViewAllContent/LatestPlaylists';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';

const LatestPlaylistsPage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}    

  return (
    <>
            <Navbar toggle={toggle}  navInfo={localStorage.getItem('accInfo') ? Home(localStorage.getItem('accInfo')) : Welcome}/>

<Sidebar
      isOpen={isOpen}
      toggle={toggle}
      navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
    />

            <LatestPlaylists/>
            
    </>
  )
}

export default LatestPlaylistsPage