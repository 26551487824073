import styled from "styled-components";
import { hexToRgba } from "../../HexToRGBA";
import { Nav, NavLink } from "../../NavLink/NavLinkElements";
import { SettingsWrapper } from "../../Profile/ProfileContent/ProfileContentElements";
import { ArtistContentHeading, ArtistContentHeadingWrapper, ArtistContentWrapper } from "../../ArtistProfile/ArtistContent/ArtistContentElements";


export const RecordLabelProfileNav = styled(Nav)`
margin-top: 5%;
color: ${(props) => props.theme.secondaryBg};

`

export const RecordLabelProfileNavLink = styled(NavLink)`

&:hover {
   
  background: ${(props) => (props.active ? props.background ? props.background : props.theme.accentColor :'transparent') };
  color: ${(props) => (props.active ? props.theme.secondaryBg: (props.background ? props.background : props.theme.accentColor ))};
  transition: all 0.3s ease-in-out;
  transform: scale(1.1);
}
  background: ${(props) => (props.active ?  (props.background ? props.background : props.theme.accentColor ) :  hexToRgba(props.theme.accentColor, 0.5) ) };
  color: ${(props) => props.theme.secondaryBg};
    @media screen and (max-width: 480px) {
      font-size: 0.65rem;
    
    }
`

export const RecordLabelSettingsWrapper = styled(SettingsWrapper)``

export const RecordLabelContentWrapper = styled(ArtistContentWrapper)``

export const RecordLabelContentHeadingWrapper = styled(ArtistContentHeadingWrapper)``

export const RecordLabelContentHeading = styled(ArtistContentHeading)``