import React, { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaDownload, FaFire, FaPause, FaPlay } from "react-icons/fa";
import { FcLike, FcLikePlaceholder } from "react-icons/fc";
import { MdExplicit, MdRemoveCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import {
  convertDuration,
  getArtistsNames,
  getArtistsNames2,
  getPodcastersNames,
  getPodcastersNames2,
  getSongTitle,
} from "../../../utils";
import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
  MUSIC_PLAYING_FROM,
  MUSIC_PURCHASE_OPTIONS,
  PODCASTER_SEARCH_TYPES,
} from "../../../utils/Enum";
import ImageUtils from "../../../utils/ImageUtils";
import { useAlert } from "../../Alert/AlertContext";
import { useAudio } from "../../AudioPlayer/context/AudioContext";
import MenuOptions, { MusicContentMenu} from "../../MenuOptions";
import { useModal } from "../../Modal/ModalContext";
import ScrollToTop from "../../ScrollToTop";
import ToolTip from "../../ToolTip";
import { Theme } from "../../WebsiteThemeElements";
import {
  Button,
  Button1,
  DetailsContainer,
  DetailsText,
  ImageWrapper,
  Img,
  NumButton,
  PlayButton,
  PlayingDisc,
  Table,
  TableData,
  TableRow,
} from "../ContentListElements";
import Marquee from "react-text-marquee";
import StreamingSubscriptionModal from "../../StreamingSubscriptionModal";
import OptionModal from "../../OptionModal";
import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import PurchaseModal from "../../PurchaseModal";
import { RiVideoFill } from "react-icons/ri";
import { getResizedImage } from "../../../utils/FileUploadsUtils";
import useMusic from "../../../hooks/useMusic";

const SongList = ({
  content,
  showAll,
  setShowAll,
  onButtonClick,
  type,
}) => {
  const { toggleModal, modalContent, isOpen, openModal, closeModal } = useModal();
  const { currentUser, gravatarInfo, getUserLikes, userLikes, isUserSubscribed } = useAuth();
  const navigate  = useNavigate();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const {unlikeSong, likeSong, likeEpisode, unlikeEpisode} = useMusic()
  const {options} = MenuOptions()
  
  const {
    currentSong,
    playlist,
    setPlaylist,
    closeViewer,
    isPlaying,
    togglePlayPause,
    isQueueLoaded,
    setCurrentSong,
    handleToggle,
  } = useAudio();

  useEffect(() => {
    if (currentUser?.uid) {
    getUserLikes(currentUser?.uid)
    }
    return () => {
      
    }
  }, [currentUser?.uid])
  
const  toggleLike = async (index) => {
  if(content[index]?.songId){
    userLikes?.filter((like) => like.songId)?.some((s) => s.songId === content[index]?.songId) ? 
    await unlikeSong(content[index]?.songId) : await likeSong(content[index]?.songId);
    
  }
  if(content[index]?.episodeId){
    userLikes?.filter((like) => like.episodeId)?.some((s) => s.episodeId === content[index]?.episodeId) ? 
    await unlikeEpisode(content[index]?.episodeId) : await likeEpisode(content[index]?.episodeId);
    
  }
  return await getUserLikes(currentUser?.uid);
}



  const playSong = async(i) => {
    if (currentUser?.uid) {
      //let isSubscribed = await isUserSubscribed()
       let isPlay = await isUserSubscribed()
        if (isPlay && isPlay !== "Network Error") {
      setPlayingFrom(playlist[currentSong])
      if(isPlaying){  
        if(content[i]?.episodeId && (playlist[currentSong]?.episodeId === content[i]?.episodeId)){
          setPlaylist(content);
          setCurrentSong(i);
          handleToggle("play-pause",'');
          setPlayingFrom(playlist[currentSong])
          return
        }
        if(content[i]?.songId && (playlist[currentSong]?.songId === content[i]?.songId)){
          setPlaylist(content);
          setCurrentSong(i);
          handleToggle("play-pause",'');
          setPlayingFrom(playlist[currentSong])
          return
        }
       else {
          setPlaylist(content);
          setCurrentSong(i);
          handleToggle("play"); 
          setPlayingFrom(playlist[currentSong])
          return
        }
      }else{
        setPlaylist(content);
        setCurrentSong(i);
        handleToggle("play");
        setPlayingFrom(playlist[currentSong])
          return 
      }}
      if(isPlay === "Network Error"){
        addAlert({
          title: "Network Error",
          type: ALERT_TYPES.DANGER,
          message:
            'Cannot play music. Check your connection',
        })
        return handleToggle("play-pause", false);
       }
      else{
        if((currentUser?.purchases?.songs?.length > 0 && currentUser?.purchases?.songs.some(s => s?.songId === content[i]?.songId)) || (content[i]?.album && (currentUser?.purchases?.albums?.length > 0 && currentUser?.purchases?.albums.some(a => a?.albumId === content[i]?.album?.albumId))) ){
          if(isQueueLoaded){
            // filter songs that are purchased within song list
            setPlaylist(content?.filter(song => 
              currentUser?.purchases?.songs.some(s => s?.songId === song?.songId) || (song?.album && (currentUser?.purchases?.albums?.length > 0 && currentUser?.purchases?.albums.some(a => a?.albumId === song?.album?.albumId)))
            )).map(s => {
              return {
                ...s,
                playingFrom: {
                  playingFrom: MUSIC_PLAYING_FROM.MUSIC_STORE,
                  id: s.songId,
                }
              }
            })
            setCurrentSong(content?.filter(song => 
              currentUser?.purchases?.songs.some(s => s?.songId === song?.songId) || (song?.album && (currentUser?.purchases?.albums?.length > 0 && currentUser?.purchases?.albums.some(a => a?.albumId === song?.album?.albumId)))
            ).findIndex(c => content[i]?.songId === c?.songId ))
            handleToggle("play");
            setPlayingFrom(playlist[currentSong])
            return
          }
        }

        addAlert({
          title: "Music Player Error",
          type: ALERT_TYPES.DANGER,
          message:
            `You do not have an active Swerl subscription to play this ${content[i]?.episodeId ? 'episode' : 'song'}`,
        });
        openModal() 
          modalContent(<><StreamingSubscriptionModal onSuccess={() => {
            handleToggle("play-pause", !isPlaying)
            modalContent(<OptionModal heading={"Subscription Sucessful"}>
              <HoverOption onClick={ () => {
                closeModal()
                modalContent()
              }
          }>OK</HoverOption>
          </OptionModal>)
             }} onFail={() => {
            modalContent(<OptionModal heading={"Subscription Unsucessful"}>
              <HoverOption onClick={() => {closeModal()
              modalContent()}}>OK</HoverOption>
          </OptionModal>)}} cancel={() => {closeModal()
          modalContent()}} /></>)
      }
    } else {
      addAlert({
        title: "Music Player Error",
        type: ALERT_TYPES.DANGER,
        message:
          "You must log in to play this song",
      });
    }
  };

  const setPlayingFrom = (music) => {
      
    if (music?.playingFrom) {
      localStorage.setItem(
        "isPlayingFrom",
        JSON.stringify(music?.playingFrom)
      );
    } else {
      localStorage.setItem(
        "isPlayingFrom",
        JSON.stringify({
          playingFrom: music?.songId
            ? CONTENT_TYPES.SONG
            : CONTENT_TYPES.EPISODE,
          id: music?.songId
            ? music?.songId
            : music?.episodeId,
        })
      );
    }
  }

  const isMusicCurrentlyPlaying = (song, index) => {
   if(isPlaying === true){ 
    if(playlist[currentSong]?.songId  && playlist[currentSong].songId === song.songId ){  
        return true
    }
    if(playlist[currentSong]?.episodeId && playlist[currentSong].episodeId === song.episodeId){  
      
        return true
    }}
    else{
      return false
    }
  
  }

  return (
    <>
      <Table>
        {content.map((song, index) => {
          return (
            <TableRow
              key={index}
              onContextMenu={(e) => {
                e.preventDefault();
                if((song?.songId || song?.episodeId) && currentUser?.uid && ((song?.isPreview && song?.isPreRelease) || !song?.isPreRelease)){
                toggleModal();
                options(song)}
              }}
              background={
                ((playlist[currentSong]?.songId === song?.songId) || (playlist[currentSong]?.episodeId === song?.episodeId))
                  ? true : false
              }
              id={song.songId ? song.songId : song.episodeId }
              disabled={(song?.isPreRelease && !song?.isPreview)}
            >
              <TableData
                onClick={() => {
                  /* if ((song?.songId || song?.episodeId) && currentUser?.uid) {
                    if (currentUser?.uid) {
                      playSong(index);
                    }
                  } else {
                
                      if(onButtonClick){
                      onButtonClick(index)
                      return
                    }
                      addAlert({
                        title: "Not Logged In",
                        type: ALERT_TYPES.WARNING,
                        message: "You need to log in to play this song",
                      });
                    } */
                }}
              >
                <NumButton
                  isPlaying={isMusicCurrentlyPlaying(song, index)
                  }
                >
                  {(isMusicCurrentlyPlaying(song, index) && ((song?.isPreview && song?.isPreRelease) || !song?.isPreRelease)) ?(
                    <PlayingDisc />
                  ): (
                    index + 1
                  )}
                </NumButton>
              </TableData>
              <TableData
                style={{ width: "10%" }}
                onClick={() => {
                  if(((song?.isPreview && song?.isPreRelease) || !song?.isPreRelease)){
                  if (currentUser?.uid) {
                    playSong(index);
                  } else {
                    if(onButtonClick){
                      onButtonClick(index)
                      return
                    }
                    addAlert({
                      title: "Not Logged In",
                      type: ALERT_TYPES.WARNING,
                      message: "You need to log in to play this song",
                    });
                  }
                }
                }}
              >
                <ImageWrapper>
                <Img
                    src={
                      song?.albumArt
                        ? song.albumArt
                        : song?.thumbnail
                        ? song?.thumbnail
                        : defaultAlbumArt
                    }
                    alt={song?.songName ? song?.songName : song?.episodeName}
                  >{(((song?.isPreview && song?.isPreRelease) || !song?.isPreRelease)) && <PlayButton
                    isPlaying={() =>
                      isMusicCurrentlyPlaying(song, index)
                    }
                  >
                    {isMusicCurrentlyPlaying(song, index) ? (
                      <FaPause />
                    ) : (
                      <FaPlay />
                    )}
                  </PlayButton>}</Img>
                </ImageWrapper>
              </TableData>
              <TableData>
                <DetailsContainer>
                  <DetailsText
                  isPlaying={isMusicCurrentlyPlaying(song, index)}
                    onClick={() => {
                      if (song?.songId) {
                        (!song?.isPreRelease || (song?.isPreRelease && song?.isPreview)) && goTo(`/song/${song?.songId}`);
                        return;
                      }
                      if (song?.episodeId) {
                        (!song?.isPreRelease || (song?.isPreRelease && song?.isPreview)) && goTo(`/episode/${song?.episodeId}`); 
                       
                    /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })
                   
                        closeViewer();
                        return; */
                      }
                    }}
                  >
                    {song?.songId && (
                      getSongTitle(song)?.length > 30 ? (
                        `${getSongTitle(song)?.substring(0, 30)}...`
                      ) : (
                        getSongTitle(song)
                      )
                    )} 
                    {song?.episodeId && (
                      song?.episodeName?.length > 30 ? (
                        `${song?.episodeName?.substring(0, 30)}...`
                      ) : (
                        song?.episodeName
                      )
                    )} 
                    {!song?.episodeId && !song?.songId && (
                      'Music Title'
                    )}
                  </DetailsText>
                  <DetailsText
                    alt={true}
                    onClick={() => {
                      /* if (song?.artists && song?.artists[0]?.artistId) {
                        //Modal
                        goTo(`/artist/${song?.artists[0]?.artistId}`);
                      }
                      if (
                        song?.podcasters &&
                        song?.podcasters[0]?.podcasterId
                      ) {
                        //Modal
                        goTo(`/podcaster/${song?.podcasters[0]?.podcasterId}`);
                      } */
                    }}
                  >
                    {song?.explicit && (
                      <MdExplicit style={{ marginLeft: "1%" }} />
                    )}{song?.video?.url && <RiVideoFill style={{ marginLeft: "1%" }}/>}
                    <small>
                      {(song?.artists || song?.mainArtists) && (!song?.isPreRelease || (song?.isPreRelease && song?.isPreview)) ?
                        getArtistsNames(
                          song?.artists ? song?.artists : song?.mainArtists,
                          ARTIST_SEARCH_TYPES.NAME
                        ) : getArtistsNames2(
                          song?.artists ? song?.artists : song?.mainArtists,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                      {(song?.podcasters || song?.mainPodcasters) && (!song?.isPreRelease || (song?.isPreRelease && song?.isPreview)) ?
                        getPodcastersNames(
                          song?.podcasters
                            ? song?.podcasters
                            : song?.mainPodcasters,
                          PODCASTER_SEARCH_TYPES.NAME
                        ) : getPodcastersNames2(
                          song?.podcasters
                            ? song?.podcasters
                            : song?.mainPodcasters,
                          PODCASTER_SEARCH_TYPES.NAME
                        )}
                    </small>
                  </DetailsText>
                  {song?.album && (
                    <DetailsText
                    isPlaying={isMusicCurrentlyPlaying(song, index)}
                      onClick={() => {
                        goTo(`/album/${song?.album?.albumId}`);
                      }}
                      style={{
                        justifyContent: "flex-start",
                        cursor: "pointer",
                      }}
                    >
                      <small>from {song?.album?.albumName}</small>
                    </DetailsText>
                  )}
                </DetailsContainer>
              </TableData>
              <TableData>
                {song?.duration && (
                  <DetailsText 
                  isPlaying={isMusicCurrentlyPlaying(song, index)}>
                    <small>{convertDuration(song?.duration)}</small>
                  </DetailsText>
                )}
              </TableData>
              {currentUser?.uid && (!song?.isPreRelease || (song?.isPreRelease && song?.isPreview)) && <TableData>
                <div>
                  <Button
                    onClick={() => {
                      if (currentUser?.uid) {
                        toggleLike(index);
                      } else {
                        addAlert({
                          title: "Not Logged In",
                          type: ALERT_TYPES.WARNING,
                          message: "You need to log in to like this song",
                        });
                      }
                    }}
                  >
                    {currentUser?.uid &&
                    ((userLikes?.length > 0 &&
                      (userLikes
                        ?.filter((like) => like.songId)
                        .some((s) => s.songId === song?.songId) ||
                        userLikes
                          ?.filter((like) => like.episodeId)
                          .some((s) => s.episodeId === song?.episodeId)))) ? (
                      <FcLike />
                    ) : (
                      <FcLikePlaceholder />
                    )}
                  </Button>
                </div>
              </TableData>}
              {song?.isDownloadable && (!song?.isPreRelease || (song?.isPreRelease && song?.isPreview)) && <TableData>
                {currentUser?.purchases?.songs?.length > 0 && currentUser?.purchases?.songs?.find(
                    (s) => s?.songId === content?.songId
                  ) ? <Button onClick={() => goTo(`/song/${song?.songId}/download`)}>
                    <Button1><FaDownload/>Download</Button1></Button> : <Button onClick={() => {
                    openModal()
                        modalContent(<PurchaseModal type={MUSIC_PURCHASE_OPTIONS.DIGITAL_SONG_PURCHASE} content={song} />);
  
                  }}><Button1>{song?.isPreRelease ? 'Pre Order' : song?.price > 0 ? `${song?.price} ${song?.currency ? song?.currency: 'XRP'}` : "FREE"}</Button1></Button>}
              </TableData>}
              <TableData>
                {(!song?.isPreRelease || (song?.isPreRelease && song?.isPreview)) &&<Button
                  onClick={() => {
                    if((song?.songId || song?.episodeId) && currentUser?.uid && ((song?.isPreview && song?.isPreRelease) || !song?.isPreRelease)){
                        toggleModal();
                        options(song)
                        return;
                      
                    } else {
                      return;
                    }
                  }}
                >
                  <BsThreeDotsVertical />
                </Button>}
              </TableData>
            </TableRow>
          );
        })}
        {/*  {(displayFullList === undefined || displayFullList === null) && (
          <>
            {!showAll && content?.length - 1 > 5 && (
              <TableRow
                onClick={() => {
                  setVisibleContent(content);
                  setShowAll(true);
                }}
              >
                <TableData></TableData>
                <TableData></TableData>
                <TableData>
                  <DetailsText
                    style={{ justifySelf: "center", justifyContent: "center" }}
                  >
                    Show All
                  </DetailsText>
                </TableData>
                <TableData></TableData>
                <TableData></TableData>
                <TableData></TableData>
              </TableRow>
            )}
            {showAll && content?.length - 1 > 5 && (
              <TableRow
                onClick={() => {
                  //setVisibleContent(content.filter((c, index) => index < 5));
                  setShowAll(false);
                }}
              >
                <TableData></TableData>
                <TableData></TableData>
                <TableData>
                  <DetailsText
                    style={{ justifySelf: "center", justifyContent: "center" }}
                  >
                    Hide
                  </DetailsText>
                </TableData>
                <TableData></TableData>
                <TableData></TableData>
                <TableData></TableData>
              </TableRow>
            )}
          </>
        )} */}
      </Table>
    </>
  );
};

export const AudioPlayerQueueList = ({
  content,
  showAll,
  setShowAll,
  onButtonClick,
  type,
}) => {
  const { toggleModal, modalContent, isOpen, openModal, closeModal } = useModal();
  const { currentUser, gravatarInfo, getUserLikes, userLikes, isUserSubscribed } = useAuth();
  const navigate  = useNavigate();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const {unlikeSong, likeSong, likeEpisode, unlikeEpisode} = useMusic()
  const {options} = MenuOptions()
  
  const {
    currentSong,
    playlist,
    setPlaylist,
    closeViewer,
    isPlaying,
    togglePlayPause,
    isQueueLoaded,
    setCurrentSong,
    handleToggle,
  } = useAudio();

  useEffect(() => {
    if (currentUser?.uid) {
    getUserLikes(currentUser?.uid)
    }
    return () => {
      
    }
  }, [currentUser?.uid])
  
  const  toggleLike = async (index) => {
    if(content[index]?.songId){
      userLikes?.filter((like) => like.songId)?.some((s) => s.songId === content[index]?.songId) ? 
      await unlikeSong(content[index]?.songId) : await likeSong(content[index]?.songId);
      
    }
    if(content[index]?.episodeId){
      userLikes?.filter((like) => like.episodeId)?.some((s) => s.episodeId === content[index]?.episodeId) ? 
      await unlikeEpisode(content[index]?.episodeId) : await likeEpisode(content[index]?.episodeId);
      
    }
    return await getUserLikes(currentUser?.uid);
  }

  const playSong = async(i) => {
    if (currentUser?.uid) {
      //let isSubscribed = await isUserSubscribed()
       let isPlay = await isUserSubscribed()
        if (isPlay && isPlay !== "Network Error") {
      setPlayingFrom(playlist[currentSong])
      if(isPlaying){  
        if(content[i]?.episodeId && (playlist[currentSong]?.episodeId === content[i]?.episodeId)){
          setPlaylist(content);
          setCurrentSong(i);
          handleToggle("play-pause",'');
          setPlayingFrom(playlist[currentSong])
          return
        }
        if(content[i]?.songId && (playlist[currentSong]?.songId === content[i]?.songId)){
          setPlaylist(content);
          setCurrentSong(i);
          handleToggle("play-pause",'');
          setPlayingFrom(playlist[currentSong])
          return
        }
       else {
          setPlaylist(content);
          setCurrentSong(i);
          handleToggle("play"); 
          setPlayingFrom(playlist[currentSong])
          return
        }
      }else{
        setPlaylist(content);
        setCurrentSong(i);
        handleToggle("play");
        setPlayingFrom(playlist[currentSong])
          return 
      }}
      if(isPlay === "Network Error"){
        addAlert({
          title: "Network Error",
          type: ALERT_TYPES.DANGER,
          message:
            'Cannot play music. Check your connection',
        })
        return handleToggle("play-pause", false);
       }
      else{
        if((currentUser?.purchases?.songs?.length > 0 && currentUser?.purchases?.songs.some(s => s?.songId === content[i]?.songId)) || (content[i]?.album && (currentUser?.purchases?.albums?.length > 0 && currentUser?.purchases?.albums.some(a => a?.albumId === content[i]?.album?.albumId))) ){
          if(isQueueLoaded){
            // filter songs that are purchased within song list
            setPlaylist(content?.filter(song => 
              currentUser?.purchases?.songs.some(s => s?.songId === song?.songId) || (song?.album && (currentUser?.purchases?.albums?.length > 0 && currentUser?.purchases?.albums.some(a => a?.albumId === song?.album?.albumId)))
            )).map(s => {
              return {
                ...s,
                playingFrom: {
                  playingFrom: MUSIC_PLAYING_FROM.MUSIC_STORE,
                  id: s.songId,
                }
              }
            })
            setCurrentSong(content?.filter(song => 
              currentUser?.purchases?.songs.some(s => s?.songId === song?.songId) || (song?.album && (currentUser?.purchases?.albums?.length > 0 && currentUser?.purchases?.albums.some(a => a?.albumId === song?.album?.albumId)))
            ).findIndex(c => content[i]?.songId === c?.songId ))
            handleToggle("play");
            setPlayingFrom(playlist[currentSong])
            return
          }
        }

        addAlert({
          title: "Music Player Error",
          type: ALERT_TYPES.DANGER,
          message:
            `You do not have an active Swerl subscription to play this ${content[i]?.episodeId ? 'episode' : 'song'}`,
        });
        openModal() 
          modalContent(<><StreamingSubscriptionModal onSuccess={() => {
            handleToggle("play-pause", !isPlaying)
            modalContent(<OptionModal heading={"Subscription Sucessful"}>
              <HoverOption onClick={ () => {
                closeModal()
                modalContent()
              }
          }>OK</HoverOption>
          </OptionModal>)
             }} onFail={() => {
            modalContent(<OptionModal heading={"Subscription Unsucessful"}>
              <HoverOption onClick={() => {closeModal()
              modalContent()}}>OK</HoverOption>
          </OptionModal>)}} cancel={() => {closeModal()
          modalContent()}} /></>)
      }
    } else {
      addAlert({
        title: "Music Player Error",
        type: ALERT_TYPES.DANGER,
        message:
          "You must log in to play this song",
      });
    }
  };

  const setPlayingFrom = (music) => {
      
    if (music?.playingFrom) {
      localStorage.setItem(
        "isPlayingFrom",
        JSON.stringify(music?.playingFrom)
      );
    } else {
      localStorage.setItem(
        "isPlayingFrom",
        JSON.stringify({
          playingFrom: music?.songId
            ? CONTENT_TYPES.SONG
            : CONTENT_TYPES.EPISODE,
          id: music?.songId
            ? music?.songId
            : music?.episodeId,
        })
      );
    }
  }

  const isMusicCurrentlyPlaying = (song, index) => {
   if(isPlaying === true){ 
    if(playlist[currentSong]?.songId  && playlist[currentSong].songId === song.songId ){  
        return true
    }
    if(playlist[currentSong]?.episodeId && playlist[currentSong].episodeId === song.episodeId){  
      
        return true
    }}
    else{
      return false
    }
  
  }

  return (
    <>
      <Table>
        {content.filter((song, index, arr) => {
          if(arr.length > 30){
            if(currentSong > 25 && (index >= (currentSong -10)  || index <= (currentSong + 10 ))){
              return song
            }
            if(currentSong < 25 && index < 25 ){
              return song
            }
            if(currentSong >= 25 && ((index <= currentSong + 10) || (index >= currentSong - 10))){
              return song
            }
          
          }else{
              return song

        }}).map((song, index) => {
          return (
            <TableRow
              key={index}
              onContextMenu={(e) => {
                e.preventDefault();
                if(song?.songId && currentUser?.uid){
                  
                  options(song);
                }
              }}
              background={
                isMusicCurrentlyPlaying(song, index)
                  ? true : false
              }
              id={song.songId ? song.songId : song.episodeId }
            >
              <TableData
                onClick={() => {
                  /* if ((song?.songId || song?.episodeId) && currentUser?.uid) {
                    if (currentUser?.uid) {
                      playSong(index);
                    }
                  } else {
                      addAlert({
                        title: "Not Logged In",
                        type: ALERT_TYPES.WARNING,
                        message: "You need to log in to play this song",
                      });
                    } */
                }}
              >
                <NumButton
                  isPlaying={isMusicCurrentlyPlaying(song, index)
                  }
                >
                  {isMusicCurrentlyPlaying(song, index) ?(
                    <PlayingDisc />
                  ): (
                    index + 1
                  )}
                </NumButton>
              </TableData>
              <TableData
                style={{ width: "10%" }}
                onClick={() => {
                  if (currentUser?.uid) {
                    playSong(index);
                  } else {
                    addAlert({
                      title: "Not Logged In",
                      type: ALERT_TYPES.WARNING,
                      message: "You need to log in to play this song",
                    });
                  }
                }}
              >
                <ImageWrapper>
                <Img
                    onClick={() => onButtonClick(index)}
                    src={song?.albumArt ? song?.albumArt : defaultAlbumArt}
                    alt={song?.songName}
                  > <PlayButton
                    isPlaying={(currentSong && playlist[currentSong]?.songId === song?.songId)}
                  >
                    {(currentSong && playlist[currentSong]?.songId === song?.songId) &&
                    isPlaying ? (
                      <FaPause />
                    ) : (
                      <FaPlay />
                    )}
                  </PlayButton></Img>
                </ImageWrapper>
              </TableData>
              <TableData>
                <DetailsContainer>
                  <DetailsText
                  isPlaying={isMusicCurrentlyPlaying(song, index)}
                    onClick={() => {
                      if (song?.songId) {
                        goTo(`/song/${song?.songId}`);
                        return;
                      }
                      if (song?.episodeId) {
                        goTo(`/episode/${song?.episodeId}`); 
                       
                    /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })
                   
                        closeViewer();
                        return; */
                      }
                    }}
                  >
                    {song?.songId && (
                      getSongTitle(song)?.length > 30 ? (
                        `${getSongTitle(song)?.substring(0, 30)}...`
                      ) : (
                        getSongTitle(song)
                      )
                    )} 
                    {song?.episodeId && (
                      song?.episodeName?.length > 30 ? (
                        `${song?.episodeName?.substring(0, 30)}...`
                      ) : (
                        song?.episodeName
                      )
                    )} 
                    {!song?.episodeId && !song?.songId && (
                      'Music Title'
                    )}
                  </DetailsText>
                  <DetailsText
                    alt={true}
                    
                    onClick={() => {
                      /* if (song?.artists && song?.artists[0]?.artistId) {
                        //Modal
                        goTo(`/artist/${song?.artists[0]?.artistId}`);
                      }
                      if (
                        song?.podcasters &&
                        song?.podcasters[0]?.podcasterId
                      ) {
                        //Modal
                        goTo(`/podcaster/${song?.podcasters[0]?.podcasterId}`);
                      } */
                    }}
                  >
                    {song?.explicit && (
                      <MdExplicit style={{ marginLeft: "1%" }} />
                    )}{song?.video?.url && <RiVideoFill style={{ marginLeft: "1%" }}/>}
                    <small>
                      {(song?.artists || song?.mainArtists) &&
                        getArtistsNames(
                          song?.artists ? song?.artists : song?.mainArtists,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                      {(song?.podcasters || song?.mainPodcasters) &&
                        getPodcastersNames(
                          song?.podcasters
                            ? song?.podcasters
                            : song?.mainPodcasters,
                          PODCASTER_SEARCH_TYPES.NAME
                        )}
                    </small>
                  </DetailsText>
                  {song?.album && (
                    <DetailsText
                    isPlaying={isMusicCurrentlyPlaying(song, index)}
                      onClick={() => {
                        goTo(`/album/${song?.album?.albumId}`);
                      }}
                      style={{
                        justifyContent: "flex-start",
                        cursor: "pointer",
                      }}
                    >
                      <small>from {song?.album?.albumName}</small>
                    </DetailsText>
                  )}
                </DetailsContainer>
              </TableData>
              <TableData>
                {song?.duration && (
                  <DetailsText isPlaying={isMusicCurrentlyPlaying(song, index)}>
                    <small>{convertDuration(song?.duration)}</small>
                  </DetailsText>
                )}
              </TableData>
              {currentUser?.uid && <TableData>
                <div style={{opacity: 0}}>
                  <Button 
                    onClick={() => {
                      if (currentUser?.uid) {
                        toggleLike(index);
                      } else {
                        addAlert({
                          title: "Not Logged In",
                          type: ALERT_TYPES.WARNING,
                          message: "You need to log in to like this song",
                        });
                      }
                    }}
                  >
                    {currentUser?.uid &&
                    ((userLikes?.length > 0 &&
                      (userLikes
                        ?.filter((like) => like.songId)
                        .some((s) => s.songId === song?.songId) ||
                        userLikes
                          ?.filter((like) => like.episodeId)
                          .some((s) => s.episodeId === song?.episodeId)))) ? (
                      <FcLike />
                    ) : (
                      <FcLikePlaceholder />
                    )}
                  </Button>
                </div>
              </TableData>}
              {song?.isDownloadable ? <TableData>
                {currentUser?.purchases?.songs?.length > 0 && currentUser?.purchases?.songs?.find(
                    (s) => s?.songId === content?.songId
                  ) ? <Button onClick={() => goTo(`/song/${song?.songId}/download`)}>
                    <Button1><FaDownload/>Download</Button1></Button> : <Button onClick={() => {
                    openModal()
                        modalContent(<PurchaseModal type={MUSIC_PURCHASE_OPTIONS.DIGITAL_SONG_PURCHASE} content={song} />);
  
                  }}><Button1>{song?.isPreRelease ? 'Pre Order' : song?.price > 0 ? `${song?.price} ${song?.currency ? song?.currency: 'XRP'}` : "FREE"}</Button1></Button>}
              </TableData> : <TableData>
                </TableData>}
              {/* <TableData>
                <Button
                  onClick={() => {
                    if (currentUser?.uid) {
                      if (song) {
                        toggleModal();
                        options(song)
                        return;
                      } else {
                        return;
                      }
                    } else {
                      return;
                    }
                  }}
                >
                  <BsThreeDotsVertical />
                </Button>
              </TableData> */}
            </TableRow>
          );
        })}
        {/*  {(displayFullList === undefined || displayFullList === null) && (
          <>
            {!showAll && content?.length - 1 > 5 && (
              <TableRow
                onClick={() => {
                  setVisibleContent(content);
                  setShowAll(true);
                }}
              >
                <TableData></TableData>
                <TableData></TableData>
                <TableData>
                  <DetailsText
                    style={{ justifySelf: "center", justifyContent: "center" }}
                  >
                    Show All
                  </DetailsText>
                </TableData>
                <TableData></TableData>
                <TableData></TableData>
                <TableData></TableData>
              </TableRow>
            )}
            {showAll && content?.length - 1 > 5 && (
              <TableRow
                onClick={() => {
                  //setVisibleContent(content.filter((c, index) => index < 5));
                  setShowAll(false);
                }}
              >
                <TableData></TableData>
                <TableData></TableData>
                <TableData>
                  <DetailsText
                    style={{ justifySelf: "center", justifyContent: "center" }}
                  >
                    Hide
                  </DetailsText>
                </TableData>
                <TableData></TableData>
                <TableData></TableData>
                <TableData></TableData>
              </TableRow>
            )}
          </>
        )} */}
      </Table>
    </>
  );
};

export const CollectionSongList = ({
  content,
  showAll,
  setShowAll,
  onButtonClick,
  type,
  playlist,
  currentSong,
  isPlaying,
}) => {
  const { toggleModal, modalContent, isOpen } = useModal();
  const { closeViewer } = useAudio();
  const { currentUser, gravatarInfo, getUserLikes, userLikes } = useAuth();
  const navigate  = useNavigate();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const {unlikeSong, likeSong, likeEpisode, unlikeEpisode} = useMusic()
  const {options} = MenuOptions()

  const  toggleLike = async (index) => {
    if(content[index]?.songId){
      userLikes?.filter((like) => like.songId)?.some((s) => s.songId === content[index]?.songId) ? 
      await unlikeSong(content[index]?.songId) : await likeSong(content[index]?.songId);
      
    }
    if(content[index]?.episodeId){
      userLikes?.filter((like) => like.episodeId)?.some((s) => s.episodeId === content[index]?.episodeId) ? 
      await unlikeEpisode(content[index]?.episodeId) : await likeEpisode(content[index]?.episodeId);
      
    }
    return await getUserLikes(currentUser?.uid);
  }

  return (
    <>
      <Table>
        {content?.length > 0 && content?.map((song, index) => {
          return (
            <TableRow
              key={index}
              onContextMenu={(e) => {
                e.preventDefault();
                if(song?.songId && currentUser?.uid){
                  toggleModal();
                  options(song)}
              }}
              background={
                (currentSong && playlist[currentSong]?.songId === song?.songId) && true
              }
              id={song.songId}
            >
              <TableData
                /* onClick={
                  song?.songId
                    ? () => {
                        onButtonClick(index);
                      }
                    : {} 
                }*/
              >
                <NumButton
                  isPlaying={
                    (currentSong && playlist[currentSong]?.songId === song?.songId) && isPlaying
                  }
                >
                  {(currentSong && playlist[currentSong]?.songId === song?.songId) &&
                  isPlaying ? (
                    <PlayingDisc />
                  ) : (
                    index + 1
                  )}
                </NumButton>
              </TableData>
              <TableData
                style={{ width: "10%" }}
                onClick={() => {
                  onButtonClick(index);
                }}
              >
                <ImageWrapper>
                 

                  <Img
                    onClick={() => onButtonClick(index)}
                    src={song?.albumArt ? song?.albumArt : defaultAlbumArt}
                    alt={song?.songName}
                  > <PlayButton
                    isPlaying={(currentSong && playlist[currentSong]?.songId === song?.songId)}
                  >
                    {(currentSong && playlist[currentSong]?.songId === song?.songId) &&
                    isPlaying ? (
                      <FaPause />
                    ) : (
                      <FaPlay />
                    )}
                  </PlayButton></Img>
                </ImageWrapper>
              </TableData>
              <TableData>
                <DetailsContainer>
                  <DetailsText
                                      isPlaying={(currentSong && playlist[currentSong]?.songId === song?.songId)}

                    onClick={
                      song?.songId
                        ? () => {
                            goTo(`/song/${song?.songId}`);
                          }
                        : {}
                    }
                  >
                    {song?.songId
                      ? getSongTitle(song)?.length > 30
                        ? `${getSongTitle(song)?.substring(0, 30)}...`
                        : getSongTitle(song)
                      : "Song Name"}
                  </DetailsText>
                  <DetailsText
                    alt={true}
                    onClick={
                      song?.artists && song?.artists[0]?.artistId
                        ? () => {
                            closeViewer();
                          }
                        : {}
                    }
                  >
                    {song?.explicit && (
                      <MdExplicit style={{ marginLeft: "1%" }} />
                    )}
                    <small>
                      {(song?.artists || song?.mainArtists) &&
                        getArtistsNames(
                          song?.artists ? song?.artists : song?.mainArtists,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                    </small>
                  </DetailsText>
                  {song?.album && (
                    <DetailsText
                      onClick={() => goTo(`/album/${song?.album?.albumId}`)}
                      style={{
                        justifyContent: "flex-start",
                        cursor: "pointer",
                      }}
                    >
                      <small>from {song?.album?.albumName}</small>
                    </DetailsText>
                  )}
                </DetailsContainer>
              </TableData>
              <TableData>
                {song?.duration && (
                  <DetailsText                     
                  isPlaying={(currentSong && playlist[currentSong]?.songId === song?.songId)}
                  >
                    <small>{convertDuration(song?.duration)}</small>
                  </DetailsText>
                )}
              </TableData>
              <TableData>
                <div>
                  <Button
                    onClick={() => {
                      if (currentUser?.uid) {
                        toggleLike(index);
                      } else {
                        addAlert({
                          title: "Not Logged In",
                          type: ALERT_TYPES.WARNING,
                          message: "You need to log in to like this song",
                        });
                      }
                    }}
                  >
                    {currentUser?.uid &&
                    ((userLikes?.length > 0 &&
                      (userLikes
                        ?.filter((like) => like.songId)
                        .some((s) => s.songId === song?.songId) ||
                        userLikes
                          ?.filter((like) => like.episodeId)
                          .some((s) => s.episodeId === song?.episodeId)))) ? (
                      <FcLike />
                    ) : (
                      <FcLikePlaceholder />
                    )}
                  </Button>
                </div>
              </TableData>
              <TableData>
                <Button
                  onClick={() => {
                    if (currentUser?.uid) {
                      if (song) {
                        toggleModal();
                        options(song)
                        return;
                      } else {
                        return;
                      }
                    } else {
                      return;
                    }
                  }}
                >
                  <BsThreeDotsVertical />
                </Button>
              </TableData>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
};

export const MusicStoreSongList = ({
  content,
  showAll,
  setShowAll,
  type,
  playlist,
  currentSong,
  isPlaying,
}) => {
  const navigate  = useNavigate();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const { currentUser, gravatarInfo, getUserLikes, userLikes } = useAuth();
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();

  return (
    <>
      <Table>
        {content.map((song, index) => {
          return (
            <TableRow
              key={index}
              background={
                (currentSong && playlist[currentSong]?.songId === song?.songId) && true
              }
              id={song.songId}
            >
              <TableData>
                <NumButton
                  isPlaying={
                    playlist[currentSong]?.songId === song?.songId && isPlaying
                  }
                >
                  {playlist[currentSong]?.songId === song?.songId &&
                  isPlaying ? (
                    <PlayingDisc />
                  ) : (
                    index + 1
                  )}
                </NumButton>
              </TableData>
              <TableData style={{ width: "10%" }}>
                <ImageWrapper>
                  <Img
                    src={song?.albumArt ? song.albumArt: defaultAlbumArt}
                    alt={song?.songName}
                  />
                </ImageWrapper>
              </TableData>
              <TableData>
                <DetailsContainer>
                  <DetailsText                     
                  isPlaying={(currentSong && playlist[currentSong]?.songId === song?.songId)}
>
                    {song?.songId
                      ? getSongTitle(song)?.length > 30
                        ? `${getSongTitle(song)?.substring(0, 30)}...`
                        : getSongTitle(song)
                      : "Song Name"}
                  </DetailsText>
                  <DetailsText alt={true}>
                    {song?.explicit && (
                      <MdExplicit style={{ marginLeft: "1%" }} />
                    )}
                    {content?.video?.url && <RiVideoFill style={{ marginLeft: "1%" }}/>}
                    <small>
                      {(song?.artists || song?.mainArtists) &&
                        getArtistsNames(
                          song?.artists ? song?.artists : song?.mainArtists,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                    </small>
                  </DetailsText>
                  {song?.album && (
                    <DetailsText
                    isPlaying={(currentSong && playlist[currentSong]?.songId === song?.songId)}

                      style={{
                        justifyContent: "flex-start",
                        cursor: "pointer",
                      }}
                    >
                      <small>from {song?.album?.albumName}</small>
                    </DetailsText>
                  )}
                </DetailsContainer>
              </TableData>
              <TableData>
                {song?.duration && (
                  <DetailsText                     isPlaying={(currentSong && playlist[currentSong]?.songId === song?.songId)}
                  >
                    <small>{convertDuration(song?.duration)}</small>
                  </DetailsText>
                )}
              </TableData>
              <TableData>
                <div></div>
              </TableData>
              <TableData>
                <Button></Button>
              </TableData>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
};

export const DraggableSongList = ({ content, setContent, deleteContent }) => {
  const navigate  = useNavigate();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const { currentUser, gravatarInfo, getUserLikes, userLikes } = useAuth();
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const [isDragging, setDragging] = useState(false);
  let draggedItem = useRef();
  let dragOver = useRef();

  useEffect(() => {return () => {}}, [content?.length > 0]);

  const removeSong = (index) => {
    setContent((prev) => {
      return prev?.filter((s, i) => index !== i);
    });
  };

  const dragStart = (e, index) => {
    draggedItem.current = index;
    setDragging(true);
  };

  const dragEnter = (e, index) => {
    dragOver.current = index;
    let sArr = content;
    sArr = sArr.map((s) => {
      return {
        ...s,
        isDragging: false,
      };
    });
    sArr[index].isDragging = true;
    setContent(sArr);
  };

  const dragEnd = (e, index) => {
    const sArr = content;
    const mainItem = sArr[draggedItem.current];

    sArr.splice(draggedItem.current, 1);
    sArr.splice(dragOver.current, 0, mainItem);

    draggedItem.current = null;
    dragOver.current = null;

    setContent(sArr);
    setDragging(false);
  };

  return (
    <>
      <Table class={"container"} onDragOver={() => {}}>
        {content.map((song, index) => {
          return (
            <>
              <TableRow
                key={index}
                draggable={true}
                droppable
                onDragStart={(e) => {
                  dragStart(e, index);
                }}
                onDragEnter={(e) => {
                  dragEnter(e, index);
                }}
                onDragEnd={(e) => {
                  dragEnd(e, index);
                }}
                class={"draggable"}
                id={song.songId}
                style={{ cursor: "move" }}
              >
                <TableData onClick={song?.songId ? () => {} : {}}>
                  <NumButton>{index + 1}</NumButton>
                </TableData>
                <TableData style={{ width: "10%" }}>
                  <ImageWrapper>
                    <Img
                      src={song?.albumArt ? song.albumArt : defaultAlbumArt}
                      alt={song?.songName}
                    />
                  </ImageWrapper>
                </TableData>
                <TableData>
                  <DetailsContainer>
                    <DetailsText>
                      {song?.songId
                        ? getSongTitle(song)?.length > 30
                          ? `${getSongTitle(song)?.substring(0, 30)}...`
                          : getSongTitle(song)
                        : "Song Name"}
                    </DetailsText>
                    <DetailsText alt={true}>
                      {song?.explicit && (
                        <MdExplicit style={{ marginLeft: "1%" }} />
                      )}
                      <small>
                        {(song?.artists || song?.mainArtists) &&
                          getArtistsNames(
                            song?.artists ? song?.artists : song?.mainArtists,
                            ARTIST_SEARCH_TYPES.NAME
                          )}
                      </small>
                    </DetailsText>
                    {song?.album && (
                      <DetailsText
                        style={{
                          justifyContent: "flex-start",
                          cursor: "pointer",
                        }}
                      >
                        <small>from {song?.album?.albumName}</small>
                      </DetailsText>
                    )}
                  </DetailsContainer>
                </TableData>
                <TableData>
                  {song?.duration && (
                    <DetailsText>
                      <small>{convertDuration(song?.duration)}</small>
                    </DetailsText>
                  )}
                </TableData>
                <TableData>
                  <div></div>
                </TableData>
                <TableData>
                  {deleteContent && (
                    <Button
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        removeSong(index);
                      }}
                    >
                      <ToolTip text={"Delete"}>
                        <MdRemoveCircle color="Red" size={30} />
                      </ToolTip>
                    </Button>
                  )}
                </TableData>
              </TableRow>
              {index === dragOver?.current && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "3px",
                    background: Theme({mainColor: gravatarInfo?.profileBackground?.color}).secondaryColor,
                  }}
                ></div>
              )}
            </>
          );
        })}
      </Table>
    </>
  );
};

export const AlbumSongList = ({
  content,
  showAll,
  setShowAll,
  onButtonClick,
  type,
}) => {
  const {options} = MenuOptions()
  const { toggleModal, modalContent, isOpen, openModal, closeModal } = useModal();
  const { currentUser, gravatarInfo, getUserLikes, userLikes, isUserSubscribed } = useAuth();
  const navigate  = useNavigate();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const {unlikeSong, likeSong, likeEpisode, unlikeEpisode} = useMusic()
  const [songs, setSongs] = useState(content)
 const [hotSongs, sethotSongs] = useState(songs?.length > 3 && songs.filter(s => s?.plays > 0).sort((a, b) => b?.plays - a?.plays).filter((s,index, self) => self.length > 10 ? index < 5 : index < 3 ))
  
  const {
    currentSong,
    playlist,
    setPlaylist,
    closeViewer,
    isPlaying,
    togglePlayPause,
    isQueueLoaded,
    setCurrentSong,
    handleToggle,
  } = useAudio();

  useEffect(() => {
    if (currentUser?.uid) {
    getUserLikes(currentUser?.uid)
    }
    return () => {
      
    }
  }, [currentUser?.uid])
  
  const  toggleLike = async (index) => {
    if(content[index]?.songId){
      userLikes?.filter((like) => like.songId)?.some((s) => s.songId === content[index]?.songId) ? 
      await unlikeSong(content[index]?.songId) : await likeSong(content[index]?.songId);
      
    }
    if(content[index]?.episodeId){
      userLikes?.filter((like) => like.episodeId)?.some((s) => s.episodeId === content[index]?.episodeId) ? 
      await unlikeEpisode(content[index]?.episodeId) : await likeEpisode(content[index]?.episodeId);
      
    }
    return await getUserLikes(currentUser?.uid);
  }

  const playSong = async(i) => {
    if (currentUser?.uid) {
      //let isSubscribed = await isUserSubscribed()
       let isPlay = await isUserSubscribed()
        if (isPlay && isPlay !== "Network Error") {
      setPlayingFrom(playlist[currentSong])
      if(isPlaying){  
        if(content[i]?.episodeId && (playlist[currentSong]?.episodeId === content[i]?.episodeId)){
          setPlaylist(content?.filter(c => !c?.isPreRelease || (c?.isPreRelease && c?.isPreview)));
          setCurrentSong(i);
          handleToggle("play-pause",'');
          setPlayingFrom(playlist[currentSong])
          return
        }
        if(content[i]?.songId && (playlist[currentSong]?.songId === content[i]?.songId)){
          setPlaylist(content?.filter(c => !c?.isPreRelease || (c?.isPreRelease && c?.isPreview)));
          setCurrentSong(content?.filter(c => !c?.isPreRelease || (c?.isPreRelease && c?.isPreview)).findIndex(c => content[i]?.songId === c?.songId ));
          handleToggle("play-pause",'');
          setPlayingFrom(playlist[currentSong])
          return
        }
       else {

         setPlaylist(content?.filter(c => !c?.isPreRelease || (c?.isPreRelease && c?.isPreview)));
         content[i]?.episodeId ? setCurrentSong(i) : setCurrentSong(content?.filter(c => !c?.isPreRelease || (c?.isPreRelease && c?.isPreview)).findIndex(c => content[i]?.songId === c?.songId ));
         handleToggle("play"); 
          setPlayingFrom(playlist[currentSong])
          return
        }
      }else{
        setPlaylist(content?.filter(c => !c?.isPreRelease || (c?.isPreRelease && c?.isPreview)));
        content[i]?.episodeId ? setCurrentSong(i) : setCurrentSong(content?.filter(c => !c?.isPreRelease || (c?.isPreRelease && c?.isPreview)).findIndex(c => content[i]?.songId === c?.songId ));
        handleToggle("play");
        setPlayingFrom(playlist[currentSong])
          return 
      }} if(isPlay === "Network Error"){
        addAlert({
          title: "Network Error",
          type: ALERT_TYPES.DANGER,
          message:
            'Cannot play music. Please check your connection',
        })
        return handleToggle("play-pause", false);
       }
       else{
        if((currentUser?.purchases?.songs?.length > 0 && currentUser?.purchases?.songs.some(s => s?.songId === content?.songId)) || (content?.album && (currentUser?.purchases?.albums?.length > 0 && currentUser?.purchases?.albums.some(a => a?.albumId === content?.album?.albumId))) ){
          if(isQueueLoaded){
            // filter songs that are purchased within song list
            setPlaylist(content?.filter(song => 
              currentUser?.purchases?.songs.some(s => s?.songId === song?.songId) || (song?.album && (currentUser?.purchases?.albums?.length > 0 && currentUser?.purchases?.albums.some(a => a?.albumId === song?.album?.albumId)))
            )).map(s => {
              return {
                ...s,
                playingFrom: {
                  playingFrom: MUSIC_PLAYING_FROM.MUSIC_STORE,
                  id: s.songId,
                }
              }
            })
            setCurrentSong(content?.filter(song => 
              currentUser?.purchases?.songs.some(s => s?.songId === song?.songId) || (song?.album && (currentUser?.purchases?.albums?.length > 0 && currentUser?.purchases?.albums.some(a => a?.albumId === song?.album?.albumId)))
            ).findIndex(c => content[i]?.songId === c?.songId ))
            handleToggle("play");
            setPlayingFrom(playlist[currentSong])
            return
          }
        }

        addAlert({
          title: "Music Player Error",
          type: ALERT_TYPES.DANGER,
          message:
            `You do not have an active Swerl subscription to play this ${content[i]?.episodeId ? 'episode' : 'song'}`,
        });
        openModal() 
          modalContent(<><StreamingSubscriptionModal onSuccess={() => {
            handleToggle("play-pause", !isPlaying)
            modalContent(<OptionModal heading={"Subscription Sucessful"}>
              <HoverOption onClick={ () => {
                closeModal()
                modalContent()
              }
          }>OK</HoverOption>
          </OptionModal>)
             }} onFail={() => {
            modalContent(<OptionModal heading={"Subscription Unsucessful"}>
              <HoverOption onClick={() => {closeModal()
              modalContent()}}>OK</HoverOption>
          </OptionModal>)}} cancel={() => {closeModal()
          modalContent()}} /></>)
      }
    } else {
      addAlert({
        title: "Music Player Error",
        type: ALERT_TYPES.DANGER,
        message:
          "You must log in to play this song",
      });
    }
  };

  const isHotSong = (i) => {
    let song = content[i]
     if(hotSongs?.length > 0 && hotSongs?.filter(s => s?.songId === song?.songId)?.length > 0){
      return true
    }
    return false 
  }

  const setPlayingFrom = (music) => {
      
    if (music?.playingFrom) {
      localStorage.setItem(
        "isPlayingFrom",
        JSON.stringify(music?.playingFrom)
      );
    } else {
      localStorage.setItem(
        "isPlayingFrom",
        JSON.stringify({
          playingFrom: music?.songId
            ? CONTENT_TYPES.SONG
            : CONTENT_TYPES.EPISODE,
          id: music?.songId
            ? music?.songId
            : music?.episodeId,
        })
      );
    }
  }

  const isMusicCurrentlyPlaying = (song, index) => {
   if(isPlaying === true && (playlist[currentSong]?.songId  && playlist[currentSong].songId === song.songId) ){  
        return true
    }
         return false

  }

  return (
    <>
      <Table>
        {content.map((song, index) => {
          return (
            <TableRow
              key={index}
              onContextMenu={(e) => {
                e.preventDefault();
                if(song?.songId  && currentUser?.uid && ((song?.isPreview && song?.isPreRelease) || !song?.isPreRelease)){
                toggleModal();
                options(song)}
              }}
              background={
                ((playlist[currentSong]?.songId === song?.songId))
                  ? true : false
              }
              id={song.songId ? song.songId : song.episodeId }
              disabled={(song?.isPreRelease && !song?.isPreview)}
            >
              <TableData
                /* onClick={() => {
                 
                  if ((song?.songId || song?.episodeId) && currentUser?.uid) {
                    if (currentUser?.uid) {
                      playSong(index);
                    }
                  } else { 
                    if(onButtonClick){
                    onButtonClick(index)
                    return
                  }
                      addAlert({
                        title: "Not Logged In",
                        type: ALERT_TYPES.WARNING,
                        message: "You need to log in to play this song",
                      });
                    }
                }} */
              >
                <NumButton
                  isPlaying={isMusicCurrentlyPlaying(song, index)
                  }
                >
                  {(isMusicCurrentlyPlaying(song, index) && ((song?.isPreview && song?.isPreRelease) || !song?.isPreRelease)) ?(
                    <PlayingDisc />
                  ): (isHotSong(index) ? <FaFire color={"#db6400"}/>  : index + 1)}
                </NumButton>
              </TableData>
              <TableData
                style={{ width: "10%" }}
                onClick={() => {
                  if(((song?.isPreview && song?.isPreRelease) || !song?.isPreRelease)){
                  if (currentUser?.uid) {
                    playSong(index);
                  } else {
                    if(onButtonClick){
                      onButtonClick(index)
                      return
                    }
                    addAlert({
                      title: "Not Logged In",
                      type: ALERT_TYPES.WARNING,
                      message: "You need to log in to play this song",
                    });
                  }}
                }}
              >
                <ImageWrapper>
                 
                  <Img
                    src={
                      song?.albumArt
                        ? song.albumArt
                        : song?.thumbnail
                        ? song?.thumbnail
                        : defaultAlbumArt
                    }
                    alt={song?.songName ? song?.songName : song?.episodeName}
                  >{(((song?.isPreview && song?.isPreRelease) || !song?.isPreRelease)) && <PlayButton
                    isPlaying={() =>
                      isMusicCurrentlyPlaying(song, index)
                    }
                  >
                    {isMusicCurrentlyPlaying(song, index) ? (
                      <FaPause />
                    ) : (
                      <FaPlay />
                    )}
                  </PlayButton>}</Img>
                </ImageWrapper>
              </TableData>
              <TableData>
                <DetailsContainer>
                  <DetailsText
                  isPlaying={isMusicCurrentlyPlaying(song, index)}
                    onClick={() => {
                      if(!song?.isPreRelease || (song?.isPreRelease && song?.isPreview)){

                      
                      if (song?.songId) {
                        goTo(`/song/${song?.songId}`);
                        return;
                      }
                    }}}
                  >
                    {song?.songId && (
                      getSongTitle(song)?.length > 30 ? (
                        `${getSongTitle(song)?.substring(0, 30)}...`
                      ) : (
                        getSongTitle(song)
                      )
                    )} 
                  
                    {!song?.episodeId && !song?.songId && (
                      'Music Title'
                    )}
                  </DetailsText>
                  <DetailsText
                    alt={true}
                    onClick={() => {
                      /* if (song?.artists && song?.artists[0]?.artistId) {
                        //Modal
                        goTo(`/artist/${song?.artists[0]?.artistId}`);
                      }
                      if (
                        song?.podcasters &&
                        song?.podcasters[0]?.podcasterId
                      ) {
                        //Modal
                        goTo(`/podcaster/${song?.podcasters[0]?.podcasterId}`);
                      } */
                    }}
                  >
                    {song?.explicit && (
                      <MdExplicit style={{ marginLeft: "1%" }} />
                    )}{song?.video?.url && <RiVideoFill style={{ marginLeft: "1%" }}/>}
                    <small>
                      {(song?.artists || song?.mainArtists) && (!song?.isPreRelease || (song?.isPreRelease && song?.isPreview)) &&
                        getArtistsNames(
                          song?.artists ? song?.artists : song?.mainArtists,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                        {((song?.isPreRelease && !song?.isPreview)) && getArtistsNames2(
                          song?.artists ? song?.artists : song?.mainArtists,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                    </small>
                  </DetailsText>
                </DetailsContainer>
              </TableData>
              <TableData>
                {song?.duration && (!song?.isPreRelease || (song?.isPreRelease && song?.isPreview)) && (
                  <DetailsText 
                  isPlaying={isMusicCurrentlyPlaying(song, index)}>
                    <small>{convertDuration(song?.duration)}</small>
                  </DetailsText>
                )}
              </TableData>
              {currentUser?.uid && (!song?.isPreRelease || (song?.isPreRelease && song?.isPreview)) && <TableData>
                <div>
                  <Button
                    onClick={() => {
                      if (currentUser?.uid) {
                        toggleLike(index);
                      } else {
                        addAlert({
                          title: "Not Logged In",
                          type: ALERT_TYPES.WARNING,
                          message: "You need to log in to like this song",
                        });
                      }
                    }}
                  >
                    {currentUser?.uid &&
                    ((userLikes?.length > 0 &&
                      (userLikes
                        ?.filter((like) => like.songId)
                        .some((s) => s.songId === song?.songId) ||
                        userLikes
                          ?.filter((like) => like.episodeId)
                          .some((s) => s.episodeId === song?.episodeId)))) ? (
                      <FcLike />
                    ) : (
                      <FcLikePlaceholder />
                    )}
                  </Button>
                </div>
              </TableData>}
              {song?.isDownloadable && (!song?.isPreRelease || (song?.isPreRelease && song?.isPreview)) && <TableData>
                {currentUser?.purchases?.songs?.length > 0 && currentUser?.purchases?.songs?.find(
                    (s) => s?.songId === content?.songId
                  ) ? <Button onClick={() => goTo(`/song/${song?.songId}/download`)}>
                    <Button1><FaDownload/>Download</Button1></Button> : <Button onClick={() => {
                    openModal()
                        modalContent(<PurchaseModal type={MUSIC_PURCHASE_OPTIONS.DIGITAL_SONG_PURCHASE} content={song} />);
  
                  }}><Button1>{song?.isPreRelease ? 'Pre Order' : song?.price > 0 ? `${song?.price} ${song?.currency ? song?.currency: 'XRP'}` : "FREE"}</Button1></Button>}
              </TableData>}
              <TableData>
                {(!song?.isPreRelease || (song?.isPreRelease && song?.isPreview)) && <Button
                  onClick={() => {
                    if(song?.songId && currentUser?.uid && ((song?.isPreview && song?.isPreRelease) || !song?.isPreRelease)) {
                        toggleModal();
                        options(song)
                        return;
                    } else {
                      return;
                    }
                  }}
                >
                  <BsThreeDotsVertical />
                </Button>}
              </TableData>
            </TableRow>
          );
        })}
        {/*  {(displayFullList === undefined || displayFullList === null) && (
          <>
            {!showAll && content?.length - 1 > 5 && (
              <TableRow
                onClick={() => {
                  setVisibleContent(content);
                  setShowAll(true);
                }}
              >
                <TableData></TableData>
                <TableData></TableData>
                <TableData>
                  <DetailsText
                    style={{ justifySelf: "center", justifyContent: "center" }}
                  >
                    Show All
                  </DetailsText>
                </TableData>
                <TableData></TableData>
                <TableData></TableData>
                <TableData></TableData>
              </TableRow>
            )}
            {showAll && content?.length - 1 > 5 && (
              <TableRow
                onClick={() => {
                  //setVisibleContent(content.filter((c, index) => index < 5));
                  setShowAll(false);
                }}
              >
                <TableData></TableData>
                <TableData></TableData>
                <TableData>
                  <DetailsText
                    style={{ justifySelf: "center", justifyContent: "center" }}
                  >
                    Hide
                  </DetailsText>
                </TableData>
                <TableData></TableData>
                <TableData></TableData>
                <TableData></TableData>
              </TableRow>
            )}
          </>
        )} */}
      </Table>
    </>
  );
};


export default SongList;
