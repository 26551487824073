import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import useMusic from "../../../hooks/useMusic";
import { SLIDER_TYPES } from "../../../utils/Enum";
import Chip from "../../Chip";
import {
  Form,
  FormButton,
  FormContainer,
  FormContent,
  FormContent1,
  FormH1,
  FormInput,
  FormLabel,
} from "../../CustomForm/CustomFormElements";
import Modal from "../../Modal";
import { Slider } from "../../Slider";
import { ContentContainer } from "./ProfileContentElements";
import ContentWrapper from "../../ContentWrapper";
import { useNavigate } from "react-router-dom";

const ProfilePlaylists = ({
  playlists,
  collabPlaylists,
  background,
  userId,
}) => {
  const [playlistInfo, setPlaylistInfo] = useState();
  const [playlistName, setName] = useState("");
  const { addPlaylist } = useMusic({});
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  return (
    <>
    <ContentWrapper heading={'Curated Playlists'} onClick={() =>
          playlists?.length > 10 && navigate(`/u/${userId}/playlists`)
        }
        subheading={playlists?.length > 10 && "View All"}>
      <Slider
        type={SLIDER_TYPES.PLAYLIST}
        id={"playlists"}
        slides={playlists?.playlists}
        background={background}
      />
      </ContentWrapper>

      {collabPlaylists?.length > 0 && (
        <ContentWrapper
          heading={"Collaborative Playlists"}
          onClick={() =>
            collabPlaylists?.length > 10 &&
            navigate(`/u/${userId}/collabPlaylists`)
          }
          subheading={collabPlaylists?.length > 10 && "View All"}
        >
          <Slider
            type={SLIDER_TYPES.PLAYLIST}
            id={"collab playlists"}
            slides={collabPlaylists?.playlists}
            background={background}
          />
        </ContentWrapper>
      )}
    </>
  );
};

export default ProfilePlaylists;
