import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import { Home } from '../../components/Navbar/Data';
import NFTCollectionCreator from '../../components/NFTCollectionCreator';
import Sidebar from '../../components/Sidebar';
import useMusic from '../../hooks/useMusic';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingScreen, { ModalLoadingScreen } from '../../components/LoadingScreen';
import OptionModal from '../../components/OptionModal';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function CollectionCreator() {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}    
    const query = useQuery()
    const navigate  = useNavigate();
    const location = useLocation()  
    const {getDraft} = useMusic()
    const [isLoaded, setLoaded] = useState(false)
    const [draft, setDraft] = useState()

    useEffect(() => {
      const id = query.get('id')
       if(id && !isLoaded){
          getDraft(id,'collection').then(
              data => {
                  if(data){
                      setDraft(data)
                      setLoaded(true)
                      return
                  }
                  navigate(`/edit/collection`, {replace: true});
                  setLoaded(true)

              }
           ).catch(err => {
            navigate(`/edit/collection`, {replace: true});
              setLoaded(true)
           }) 
      }else{
          
          setLoaded(true)
          return () => {}
      }
      return () => {}
    })

      
      useEffect(()=>{
        const idParam = query.get('id')
        if(idParam){
            //console.log(idParam)
        }
        return () => {}
      },[query])
      
  return (
    <>
        <Sidebar
            isOpen={isOpen}
            toggle={toggle}
            navInfo={Home(localStorage.getItem("accInfo"))}
          />
            <Navbar toggle={toggle} navInfo={Home(localStorage.getItem("accInfo"))}/>
            {!isLoaded && 
            <OptionModal><ModalLoadingScreen text={'Please Wait...'} transparent={true}/></OptionModal>}
            {isLoaded && <NFTCollectionCreator info={draft}/>}
            
    </>
  )
}

export default CollectionCreator