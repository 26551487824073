import React, { useEffect, useState } from 'react'
import MusicPage from '../../components/MusicPage';
import { CONTENT_TYPES } from '../../utils/Enum';
import { Home, Welcome } from '../../components/Navbar/Data';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import { useNavigate, useParams } from 'react-router-dom';

const RoyaltyShares = () => {
    const {royaltyId} = useParams()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}
    const navigate  = useNavigate()
    
    useEffect(() => {
        if(!royaltyId)
        {
            navigate(-1)
        }
        return () => {}
    }, [royaltyId])
  
  
  
    return( 
        <>
              <Sidebar
            isOpen={isOpen}
            toggle={toggle}
            navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
          />
            <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
            <MusicPage id={royaltyId} type={CONTENT_TYPES.ROYALTY_SHARE}/>
            
        </>
        )
  }
  

export default RoyaltyShares