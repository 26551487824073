import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";
import { MEDIA_SCREEN } from "../MusicPage/MusicInfo/MusicInfoElements";

export const AudioVisualizerContainer = styled.div`
display: flex;
height:100%
width: 100%;
background: transparent;
`

export const Video = styled.video`
display: flex;
width: 100%;
height: 100%;
justify-self: center;
align-self: center;
-o-object-fit: cover;
object-fit: cover;
min-height: 100vh;
`

export const AudioVisualizerContent = styled.div`
position: absolute;
flex-direction: column;
padding 2%;
display:flex;
    background-color: ${(props) => hexToRgba(props.theme.mainBg, 0.35)};
    width: 100%;
    height: 100%;
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    color: ${(props) => props.theme.secondaryColor};
      @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
        justify-content: center;
      }
    
color: ${props => props?.theme.secondaryBg};
`