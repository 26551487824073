import React, { useEffect, useState } from "react";
import { FaEdit, FaHeart, FaReply, FaTrash } from "react-icons/fa";
import CommentList from ".";
import { getTimestamp, timestampToDate } from "../../../utils";
import { getCommentLikes, getReplies } from "../../../utils/CommentsUtils";
import { HoverOption } from "../../HoverMenu/HoverMenuElements";
import IconButton from "../../IconButton";
import { CommentActionButtons, CommentButton, CommentContainer, CommentFooter, CommentHeader, CommentMessage, CommentMessageText, CommentsHeaderText, NestedComments, NestedCommentStack } from "./CommentsListElements";
import { DEFAULT_IMAGE, DEFAULT_PROFILE, DEFAULT_PROFILE_V3, getUserProfile } from "../../GravatarAPI";
import AlbumArt from "../../AlbumArt";
import { ImageWrapper, Img } from "../ContentListElements";
import { Theme } from "../../WebsiteThemeElements";
import { useAuth } from "../../../contexts/AuthContext";
import CommentForm from "../../CommentForm";
import ReplyForm from "./ReplyForm";
import { VerifiedBadge } from "../../Profile/ProfileDetails/ProfileDetailsElements";
import verifiedBadge from "../../../images/verified_artist_page.png";
import { useUser } from "../../../hooks/useUser";
import { useWebsiteTheme } from "../../WebsiteTheme/WebsiteThemeContext";


const Comment = ({ comment,comments, getComments}) => {
  const [ childComments, getChildComments] = useState(getReplies(comments, comment?.commentId))
  const {themeColors} = useWebsiteTheme()
  const {currentUser} = useAuth()
  const {likeComment, unlikeComment} = useUser({id: currentUser?.uid})
  const [showReplies, setShowReplies] = useState(false)
  const [likes, setLikes] = useState()
  const[reply, setReply] = useState(false)
  const [userProfile, setUserProfile] = useState(comment?.user?.gravatarProfile ? comment?.user?.gravatarProfile : undefined )
   
  const getProfilePicture = () => {
    if(comment?.user){
      return comment?.user?.photoURL
    }
    if(comment?.artist){
      return comment?.artist?.profilePictureType === 'gravatar' ? comment?.artist?.profilePicture : comment?.artist?.avatar_url
    }
    if(comment?.label){
      return comment?.label?.profilePictureType === 'gravatar' ? comment?.label?.profilePicture : comment?.label?.avatar_url
    }
    if(comment?.podcaster){
      return comment?.podcaster?.profilePictureType === 'gravatar' ? comment?.podcaster?.profilePicture : comment?.podcaster?.avatar_url
    }else{
      return DEFAULT_IMAGE()
    }
  }

  useEffect(() => {
    getCommentLikes(comment?.commentId, setLikes)
    
    return () => {
      
    }
  },[comment])
  
  
  return (
    <>
    <CommentContainer>
      <CommentHeader>
        <CommentsHeaderText to={comment?.user?.uid ? `/u/${comment?.user?.uid}` : '#'}><ImageWrapper style={{ width: '2rem', height: '2rem', marginRight: '2%'}}><Img size={'1rem'} src={getProfilePicture()}/> </ImageWrapper>
        <text style={{marginLeft: '2%', whiteSpace: "nowrap"}}>{comment?.artistId
                          ? comment?.artist?.artistName
                          : comment?.labelId
                          ? comment?.label?.labelName
                          : comment?.podcasterId
                          ? comment?.podcaster?.podcasterName
                          : comment?.user?.gravatarInfo
                          ? comment?.user?.gravatarInfo?.display_name :
                          comment?.user?.uid ? `${comment?.user?.uid?.substring(
                0,
                3
              )}...${comment?.user?.uid?.substring(
                comment?.user?.uid?.length - 5,
                comment?.user?.uid?.length - 1
              )}` : 'Anonymous User'}</text>{!comment?.uid && <VerifiedBadge src={verifiedBadge}/>}</CommentsHeaderText>
        <span>
          <small>{comment?.createdAt
            ? getTimestamp( new Date(timestampToDate(comment?.createdAt)))
            : getTimestamp(new Date())}</small>
        </span>
      </CommentHeader>
      <CommentMessage>
        <CommentMessageText>{comment?.message}</CommentMessageText>
        <CommentActionButtons>
                      {!comment?.artistId && !reply && (
                        <>
                          {" "}
                          <CommentButton
                            onClick={() => {
                              setReply(true);
                            }}
                          >
                            Reply
                          </CommentButton>
                          {currentUser?.uid && <CommentButton style={{
                              backgroundColor: likes?.some(like => like?.uid === currentUser?.uid) && themeColors?.mainColor
                          }} onClick={async () => {
                            if(likes?.length > 0 && likes.some(l => l?.uid === currentUser?.uid)){
                              return await unlikeComment(currentUser?.uid, comment?.commentId).then((like) => {
                                if(like){
                                  setLikes(likes.filter(l => l?.uid !== currentUser?.uid))
                                }
                              })
                            }else{
                              return await likeComment(currentUser?.uid, comment?.commentId).then((like) => {
                                if(like){
                                  setLikes(prev => {
                                    return [...prev, {uid: currentUser?.uid}]
                                  })
                                }
                              })
                            }
                          }}>
                            <FaHeart /> {likes?.length > 0 && ` ${likes?.length}`}
                          </CommentButton>}
                        </>
                      )}
                    </CommentActionButtons>
      </CommentMessage>
      
      <CommentFooter>
      {reply && <ReplyForm id={`${comment?.type}Id`} setOpen={setReply} commentId={comment?.commentId} type={comment?.type} commenter={comment?.user?.gravatarInfo
                          ? comment?.user?.gravatarInfo?.display_name :
                          comment?.user?.uid} isLoaded={true} getComments={getComments}/>}
      </CommentFooter> 
      
      {childComments?.length > 0 && (
            <>
              <NestedCommentStack>
                <NestedComments>
                  <CommentList comments={childComments.filter((c, index) => (childComments?.length > 5 && !showReplies) ? index < 5 : c )} />
                </NestedComments>
              </NestedCommentStack>
            </>
          )}
          {childComments?.length > 5 && !showReplies && (
            <HoverOption onClick={() => setShowReplies(true)}>
              Show Replies
            </HoverOption>
          )}
    </CommentContainer>
   
    </>
  );
};

export default Comment;
