import { updateCurrentUser } from "firebase/auth";
import React, { useEffect, useRef, useState } from "react";
import { FaBtc, FaClipboard, FaHandHoldingHeart, FaMapMarkerAlt } from "react-icons/fa";
import { ThemeProvider } from "styled-components";
import { Button, CustomButton } from "../../ButtonElement";
import SocialLinks, { UrlLinks } from "../../SocialLinks";
import { Theme } from "../../WebsiteThemeElements";
import { useAuth } from "../../../contexts/AuthContext";
import {
    AboutText,
    AdditionalDetailsContainer,
    ButtonWrapper,
    DetailsText,
    DetailsTextContainer,
    FollowButton,
    FollowDetailsContainer,
    FollowText,
    Form,
    FormContent,
    FormButton,
    FormH1,
    HeadingTextWrapper,
  ProfileActionButtonWrapper,
  ProfileDetailsContainer,
  ProfileHeading,
  ProfileImg,
  ProfileTitle,
  FormText,
  Small,
  FormContent1,
  VerifiedBadge,
  FormTab,
  FormTabButton,
  
} from "./ProfileDetailsElements";
import { ImageViewer } from "../../ImageViewer";
import { useUser } from "../../../hooks/useUser";
import QRCodeForm from "../../QRCodeForm";
import { QrCode } from "../../QRCodeForm/QrCodeFormElements";
import { useNavigate } from "react-router-dom";
import { DEFAULT_IMAGE } from "../../GravatarAPI";
import LoadingScreen from "../../LoadingScreen";
import artistBadge from '../../../images/artist_page_owner.png'
import ContentList from "../../ContentList";
import CustomForm from "../../CustomForm";
import Modal from "../../Modal/index";
import { ALERT_TYPES, CURRENCIES, TRANSACTION_OPTIONS } from "../../../utils/Enum";
import CurrencySelector from "../../CurrencySelector";
import { FormInput, FormLabel, FormWrapper } from "../../CustomForm/CustomFormElements";
import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import { ModalContext, useModal } from "../../Modal/ModalContext";

import OptionModal from "../../OptionModal";
import ToolTip from "../../ToolTip";
import TipForm from "../../TipForm";
import { copyTextToClipboard, generateQRCode, getObjectLength } from "../../../utils";
import { useAlert } from "../../Alert/AlertContext";
import CryptoWalletIcons from "../../CryptoWalletIcons";
import { ProfileQRCode } from "../ProfileElements";


const ProfileDetails = ({
  accounts,
  currentLocation,
  aboutMe,
  exists,
  urls,
  photo,
  displayName,
  address,
  wallets,
  artist,
  subscribed,
  background,
  isUserFollower,
  isUserFollowing,
  numFollowers,
  numFollowing,
  onFollowClick,
  onUnfollowClick,
  onFollowsModal,
  followRef,
  user
}) => {
  const [tip, setTip] = useState(1)
  const [memo, setMemo] = useState()
  const [isOpen, setOpen] = useState(false)
  const [showQrForm, setQrForm] = useState(false)
  const [currency, setCurrency] = useState('WYM')
 

  const {toggleModal, modalContent} = useModal()
  const {addAlert} = useAlert()
  const [isSubscribed, setSubscribed] = useState(subscribed ? subscribed : false)
  const {currentUser} = useAuth()
  const {
    getArtistsUserFollowing, 
    followUser, 
    unfollowUser, 
    getFollowers, 
    getFollowing,
    isFollowing} = useUser({id: address})
  const [isFollows, setFollowing] = useState(false)// if currentUser follows user
  const [isFollower, setFollower] = useState(false)// if user is following currentUser
 
  const navigate  = useNavigate()
  


  useEffect(() => {
    setFollower(isUserFollower)
    setFollowing(isUserFollowing)
    return () => {}
  },[] )
  

  const onSubscribeClick = () => {
      setSubscribed(!isSubscribed)
  }

  

  const actionButtons = () => { // action buttons for users and artist accounts

return (
  <>
  {(address !== currentUser?.uid) && currentUser?.uid && <FollowButton background={Theme({}).secondaryColor} active onClick={() => {
    toggleModal()
    if(getObjectLength(wallets?.filter(wallet => wallet?.type !== 'xrp')) > 0){
      //console.log(wallets)
      modalContent(<><OptionModal heading={`Tipping ${displayName}`}>
         <HoverOption onClick={() => modalContent(<TipForm displayName={displayName} address={address}/>)}>Tip XRP Wallet</HoverOption>
         <HoverOption onClick ={() => {
          modalContent(<OptionModal heading={`Additional Tipping Wallets`}>{wallets?.filter(wallet => wallet?.type !== 'xrp')?.map((wallet, index) => {
            return(
              <HoverOption onClick={async() => {
                let qr = await generateQRCode(wallet.value)
                modalContent(<OptionModal heading={`${wallet?.type.toUpperCase()} Wallet`} additionalText={<small>{wallet.value}</small>}>
                    {qr && <ProfileQRCode src={qr}/>}
                    <HoverOption onClick={() => {
                      copyTextToClipboard(wallet?.value)
                      addAlert({
                        title: "Copied To Clipboard",
                        type: ALERT_TYPES.INFO,
                        secs: 10,
                      })
                    }}><FaClipboard/>Copy to Clipboard</HoverOption>
                    <HoverOption onClick={() => {
                      toggleModal()
                      modalContent()
                    }}>OK</HoverOption>
                </OptionModal>)
              }}><CryptoWalletIcons type={wallet?.type}/>{wallet?.type?.toUpperCase()}</HoverOption>
            )
          })}</OptionModal>)
         }}>View Additional Wallets</HoverOption>
      
      </OptionModal></>)
    }else{
      modalContent(<TipForm displayName={displayName} address={address}/>)
    }
    
  }}>Tip {" "}<FaHandHoldingHeart style={{marginLeft: '1%'}}/></FollowButton>}
  {exists &&(address !== currentUser?.uid) && currentUser?.uid && <FollowButton active={isFollowing}  ref={followRef} background={Theme({}).secondaryColor} onClick={isFollows ? onUnfollowClick : onFollowClick}>{isFollows ? 'Unfollow':'Follow'}</FollowButton>}</>
)

}

const openViewer = () => {
  toggleModal()
  modalContent( <OptionModal heading={displayName} onCancel={() => {
    toggleModal()
    modalContent()
  }}>
    <ImageViewer  onContextMenu={(e) => {
    e.preventDefault();
  }} img={photo+'?d=mp'} isCircular={true} title={false}/>
    </OptionModal>)
}


  return (
    
    <>
    
      
        <ProfileDetailsContainer>
            <ProfileActionButtonWrapper>
            {accounts && <SocialLinks background={background} accounts={accounts} />}
              <ButtonWrapper>
                {actionButtons()}
                </ButtonWrapper>
              </ProfileActionButtonWrapper>
          <ProfileHeading>
            <ProfileImg  onClick={() => openViewer()} background={background} src={photo} alt={DEFAULT_IMAGE+'?d=mp&s=600'} onContextMenu={(e) => {e.preventDefault()}} />
            <HeadingTextWrapper>
            
            <span style={{display: 'flex', flexDirection: 'row'}}>
              <><ProfileTitle >{displayName}   </ProfileTitle>{artist === true && <ToolTip text={
                'This account owns an Artist Page (for Artists, Podcasters or Recordlabels)'
              }><VerifiedBadge src={artistBadge} onContextMenu={(e) => {e.preventDefault()}}/></ToolTip>} </>
              {isFollower && (address !== currentUser?.uid) && 
              <Small style={{margin: '0% 1%', padding: '0.8%', textAlign: "center", color: Theme({}).secondaryBg, background:  background ? background : Theme({}).mainColor, borderRadius: '15px'}}>
                follows you
              </Small>}
            </span>
           
            
            {aboutMe && <AboutText>{aboutMe}</AboutText>}
            {/* <AboutText steps={[
                    aboutMe, 5000,
                    ]} /> */} 
            </HeadingTextWrapper>
          </ProfileHeading>
          <AdditionalDetailsContainer>
               
              < DetailsTextContainer>
              {currentLocation &&
              <><FaMapMarkerAlt style={{marginRight: '1%'}}/>
              <DetailsText>
                  {currentLocation}
              </DetailsText></>}
            </DetailsTextContainer>
            <DetailsTextContainer>
              <FollowDetailsContainer>
                
                {<FollowText onClick={() => {
                  if(currentUser?.uid && exists && numFollowers > 0){
                    onFollowsModal('followers')

                  }
                  

                }}>Followers {' ' + numFollowers}</FollowText>}
         
          {<FollowText onClick={async() => {
                  if(currentUser?.uid  && exists && numFollowing > 0){
                  onFollowsModal('following')
                  }

                }}>Following {' ' + (numFollowing)}</FollowText>}
              </FollowDetailsContainer>
              {urls?.length > 0 && <UrlLinks background={background} urlLinks={urls}/>}
            </DetailsTextContainer>
          </AdditionalDetailsContainer>
        </ProfileDetailsContainer>
    </>
  );
};

export default ProfileDetails;
