import {useEffect} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import { animateScroll as scroll } from "react-scroll";


export default function ScrollToTop(){
	const {pathname} = useLocation()
	const navigate  = useNavigate()

	
	const goTo = (url) => {
		navigate(url)
		scroll.scrollToTop()
		
	}

	const scrollToTop = () => {
		scroll.scrollToTop()
	}
	
	return {
		goTo,
		scrollToTop
	};
}
