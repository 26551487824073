import React from 'react'
import { ProfilePictureContainer, Container, HeaderBackground, ProfilePicture, Title, ProfileWrapper, ProfileHeaderTextWrapper, Subtitle } from './ProfileHeaderElements'
import defaultImg from '../../../images/sonar_muse_logo_nav1.png'
import { ProfileInfoText } from '../ProfileElements'
import hexToRgbA from '../../HexToRGBA'

const ProfileHeader = ({displayName, name, img, background}) => {

    return (
        <div>
            <Container background={hexToRgbA(background)}>
                
            </Container>
        </div>
    )
}

export default ProfileHeader
