import React, { useEffect, useState } from "react";
import {
  BannerMarketingButtonContainer,
  BannerMarketingDetailsContainer,
  BannerMarketingDetailsWrapper,
  BannerMarketingText,
} from "./BannerMarketingElements";
import FileUploader from "../FileUploader";
import {
  AlbumArtPreviewContainer,
  AlbumUploadsContainer,
} from "../MusicUploadsForm/MusicUploadsFormElements";
import {
  CarouselCard,
  CarouselCardBody,
  CarouselCardH2,
  CarouselCardHeader,
  CarouselCardImg,
  CarouselCardParagraph,
  CarouselCardText,
  CarouselDescription,
  CarouselMusicCover,
} from "../Carousel/CarouselElements";
import LoadingScreen, { ModalLoadingScreen } from "../LoadingScreen";
import ImageUtils from "../../utils/ImageUtils";
import { useAuth } from "../../contexts/AuthContext";
import {
  FormInput,
  FormLabel,
  FormText,
  FormWrapper,
} from "../CustomForm/CustomFormElements";
import { CustomButton } from "../ButtonElement";
import { Header } from "../Header";
import Footer from "../Footer";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { ChipContainer, ChipList } from "../Chip/ChipElements";
import Chip from "../Chip";
import { SmallText } from "../NFTCollectionCreator/NFTCollectionCreatorElements";
import { ErrorText } from "../ArtistProfile/ArtistContent/ArtistContentElements";
import { ARTIST_SEARCH_TYPES, CAMPAIGN_TYPE, CONTENT_TYPES, PODCASTER_SEARCH_TYPES, TRANSACTION_OPTIONS } from "../../utils/Enum";
import useArtist from "../../hooks/useArtist";
import useRecordLabel from "../../hooks/useRecordLabel";
import usePodcaster from "../../hooks/usePodcaster";
import { useUser } from "../../hooks/useUser";
import {
  ContentListContainer,
  DetailsContainer,
  DetailsText,
  Img,
  Table,
  TableData,
  TableHeading,
  TableRow,
} from "../ContentList/ContentListElements";
import {
  getArtistsNames2,
  getObjectLength,
  getPodcastersNames2,
  getSongTitle,
} from "../../utils";
import { FaCheckSquare, FaSquare } from "react-icons/fa";
import ToggleButton from "../ToggleButton";
import { useModal } from "../Modal/ModalContext";
import OptionModal from "../OptionModal";
import useMusic from "../../hooks/useMusic";
import QRCodeForm from "../QRCodeForm";

const MarketingForm = ({ type, userType, onCancel }) => {
  const { currentUser} = useAuth();
  const { searchArtistAlbums, searchArtistSongs } = useArtist({});
  const { searchLabelSongs, searchLabelAlbums } = useRecordLabel({});
  const { getPodcasterEpisodes } = usePodcaster({});
  const { getCollectionsByUser, getPlaylistsByUser } = useUser({});
  const { getSongs } = useMusic();
  const { openModal, closeModal, modalContent } = useModal();
  const [imageUpload, setImageUpload] = useState({});
  const [preview, setPreview] = useState(false);
  const [content, setContent] = useState();
  const [searchContents, setSearchContents] = useState();
  const [heading, setHeading] = useState();
  const [description, setDescription] = useState();
  const [addNew, setAddNew] = useState(false);
  const [filteredContent, setFilteredContent] = useState();
  const [searchValue, setSearchValue] = useState();
  const [addToPlaylist, setAddToPlaylist] = useState(false);
  const [playlistContent, setPlaylistContnet] = useState();
  let [value, setValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    showDateDisplay: false,
    disabled: true,
  });
  const [day, setDay] = useState();
  const [dates, setDates] = useState([]);
  const [numDays, setNumDays] = useState(1);
  const [errors, setErrors] = useState({});
  const [total, setTotal] = useState(0)

  const { defaultAlbumArt, DEFAULT_IMAGE, SonarNavInvert } = ImageUtils();

  useEffect(() => {
        getPlaylistsByUser(currentUser?.uid, "all").then((playlists) => {
          setSearchContents(playlists?.playlists);
          return;
        });
    return () => {};
  }, [type]);

  useEffect(() => {
    setTotal((30*dates?.length) + (addToPlaylist ? 50 : 0))
  },[])

  const selectContent = async () => {
    openModal();
    modalContent(
      <>
        <ModalLoadingScreen transparent={true} />
      </>
    );
    switch (type) {
      case CAMPAIGN_TYPE.ALBUM:
        if (content?.songs?.some((s) => s?.songId)) {
          modalContent(
            <>
              <OptionModal
                heading={"Select Song from Album"}
                onCancel={() => {
                  closeModal();
                  modalContent();
                }}
              >
                {content?.songs?.map((s) => {
                  return (
                    <HoverOption
                      onClick={() => {
                        setAddToPlaylist(true);
                        playlistContent(s);
                        closeModal();
                        modalContent();
                      }}
                    >
                      {getSongTitle(s)}
                    </HoverOption>
                  );
                })}
              </OptionModal>
            </>
          );
        } else {
          await getSongs(content?.songs).then((songs) => {
            modalContent(
              <>
                <OptionModal
                  heading={"Select Song from Album"}
                  onCancel={() => {
                    closeModal();
                    modalContent();
                  }}
                >
                  {songs?.map((s) => {
                    return (
                      <HoverOption
                        onClick={() => {
                          setAddToPlaylist(true);
                          playlistContent(s);
                          closeModal();
                          modalContent();
                        }}
                      >
                        {getSongTitle(s)}
                      </HoverOption>
                    );
                  })}
                </OptionModal>
              </>
            );
          });
        }
        break;
      case CAMPAIGN_TYPE.ARTIST:
        modalContent(
          <OptionModal heading={"Coming Soon"}>
            <HoverOption
              onClick={() => {
                closeModal();
                modalContent();
              }}
            >
              OK
            </HoverOption>
          </OptionModal>
        );
        break;
      case CAMPAIGN_TYPE.PODCASTER:
        modalContent(
          <OptionModal heading={"Coming Soon"}>
            <HoverOption
              onClick={() => {
                closeModal();
                modalContent();
              }}
            >
              OK
            </HoverOption>
          </OptionModal>
        );
        break;
      case CAMPAIGN_TYPE.RECORD_LABEL:
        modalContent(
          <OptionModal heading={"Coming Soon"}>
            <HoverOption
              onClick={() => {
                closeModal();
                modalContent();
              }}
            >
              OK
            </HoverOption>
          </OptionModal>
        );
        break;

      default:
        break;
    }
  };

  const handleInput = (e) => {
    let err = errors;
    switch (e.target.name) {
      case "heading":
        delete err.heading;
        if (e.target.value?.length < 30) {
          setHeading(e.target.value);
        } else {
          setErrors((prev) => {
            return {
              ...err,
              heading: "heading too long",
            };
          });
        }

        break;

      case "description":
        delete err.description;
        if (e.target.value?.length < 100) {
          setDescription(e.target.value);
        } else {
          setErrors((prev) => {
            return {
              ...err,
              description: "description too long",
            };
          });
        }
        break;

      default:
        break;
    }
    return;
  };

  function searchContent(e) {
    if (e.target.value?.trim()?.length > 0) {
      switch (type) {
        case CAMPAIGN_TYPE.PLAYLIST:
          setFilteredContent(
            searchContents?.filter((p) =>
              p?.playlistName
                ?.toLowerCase()
                .includes(e.target.value.toLowerCase())
            )
          );
          return;

        case CAMPAIGN_TYPE.ALBUM:
          setFilteredContent(
            searchContents?.filter((a) =>
              a?.albumName?.toLowerCase().includes(e.target.value.toLowerCase())
            )
          );
          return;

        case CAMPAIGN_TYPE.SINGLE:
          setFilteredContent(
            searchContents?.filter((s) =>
              s?.songName?.toLowerCase().includes(e.target.value.toLowerCase())
            )
          );
          return;

        case CAMPAIGN_TYPE.EPISODE:
          setFilteredContent(
            searchContents?.filter((ep) =>
              ep?.episodeName
                ?.toLowerCase()
                .includes(e.target.value.toLowerCase())
            )
          );
          return;

        case CAMPAIGN_TYPE.COLLECTION:
          setFilteredContent(
            searchContents?.filter((c) =>
              c?.name?.toLowerCase().includes(e.target.value.toLowerCase())
            )
          );
          return;

        default:
          break;
      }
    } else {
      setFilteredContent();
    }
  }

  function displaySearch() {
    return (
      <FormWrapper>
        <ContentListContainer>
          <>
            <Table>
              <TableRow>
                <TableHeading></TableHeading>
                <TableHeading></TableHeading>
                <TableHeading></TableHeading>
              </TableRow>
              {filteredContent?.map((content, index) => {
                return (
                  <>
                    <TableRow
                      key={index}
                      id={() => {
                        switch (type) {
                          case CAMPAIGN_TYPE.PLAYLIST:
                            return content?.playlistId;

                          case CAMPAIGN_TYPE.ALBUM:
                            return content?.albumId;
                          case CAMPAIGN_TYPE.SINGLE:
                            return content?.songId;
                          case CAMPAIGN_TYPE.EPISODE:
                            return content?.episodeId;
                          case CAMPAIGN_TYPE.COLLECTION:
                            return content?.collectionId;

                          default:
                            break;
                        }
                      }}
                      onClick={() => {
                        setContent(content);
                        setFilteredContent();
                        setSearchValue();
                      }}
                    >
                      <TableData>
                        <Img
                          src={
                            content?.albumArt
                              ? content.albumArt
                              : content?.playlistArt
                              ? content?.playlistArt
                              : content?.cover?.url
                              ? content?.cover?.url
                              : DEFAULT_IMAGE(50)
                          }
                          alt={`${type} search ${index}`}
                        />
                      </TableData>
                      <TableData>
                        <DetailsContainer>
                          <DetailsText>
                            {content?.albumName
                              ? content?.albumName
                              : content?.songName
                              ? getSongTitle(content)
                              : content?.name
                              ? content?.name
                              : content?.playlistName}
                          </DetailsText>
                        </DetailsContainer>
                      </TableData>
                    </TableRow>
                  </>
                );
              })}
            </Table>
          </>
        </ContentListContainer>
      </FormWrapper>
    );
  }

  function validateDay(day) {
    let e = errors;
    delete e.dates;
    let d = dates;
    if (d?.length > 0 && d?.includes(day)) {
      setErrors({
        ...e,
        dates: "date already added!",
      });
      return;
    } else {
      
      if (new Date().toISOString().split("T")[0] > day) {
        return;
      }
      d.push(day);
      setDates(d?.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0)));
      setAddNew(false);
    }
  }

  function filterContent(){
    let info 
    switch (type) {
        case CAMPAIGN_TYPE.ALBUM:
          info ={
            id: content?.albumId,
            artists: getArtistsNames2(content?.artists, ARTIST_SEARCH_TYPES.NAME),
            link: `/album/${content?.albumId}`,
            linkArt: content?.albumArt
          }
          break;
  
        case CAMPAIGN_TYPE.SINGLE:
            info ={
                id: content?.songId,
                artists: getArtistsNames2(content?.artists, ARTIST_SEARCH_TYPES.NAME),
                link: `/album/${content?.albumId}`,
                linkArt: content?.albumArt
              }
          break;
  
        case CAMPAIGN_TYPE.EPISODE:
            info ={
                id: content?.episodeId,
                artists: getArtistsNames2(content?.artists, ARTIST_SEARCH_TYPES.NAME),
                link: `/album/${content?.albumId}`,
                linkArt: content?.albumArt
              }
          break;
  
        case CAMPAIGN_TYPE.COLLECTION:
            info ={
                id: content?.collectionId,
                artists: getArtistsNames2(content?.artists, ARTIST_SEARCH_TYPES.NAME),
                link: `/album/${content?.albumId}`,
                linkArt: content?.albumArt
              }
          break;
  
        case CAMPAIGN_TYPE.PLAYLIST:
            info ={
                id: content?.playlistId,
                artists: getArtistsNames2(content?.artists, ARTIST_SEARCH_TYPES.NAME),
                link: `/album/${content?.albumId}`,
                linkArt: content?.albumArt
              }
          break;
  
        case CAMPAIGN_TYPE.ARTIST:
            info ={
                id: content?.artistId,
                artists: content?.artistName,
                link: `/artist/${content?.artistId}`,
                linkArt: content?.artistSettings?.profilePictureType === "custom" ? content?.profilePicture : content?.photoUrl
              }
          break;
        case CAMPAIGN_TYPE.RECORD_LABEL:
            info ={
                id: content?.labelId,
                artists: content?.labelName,
                link: `/label/${content?.labelId}`,
                linkArt: content?.artistSettings?.profilePictureType === "custom" ? content?.profilePicture : content?.photoUrl
              }
          break;
        case CAMPAIGN_TYPE.PODCASTER:
            info ={
                id: content?.podcasterId,
                artists:content?.podcasterName,
                link: `/podcaster/${content?.podcasterId}`,
                linkArt: content?.artistSettings?.profilePictureType === "custom" ? content?.profilePicture : content?.photoUrl
              }
          break;
  
        default:
          break;
      }
    return info
  }

  function validateContent(){
    setErrors()
    let e = {}
    if(dates?.length === 0){
        e = {...e,
            dates:"Cannot create campaign without dates"
        }
    }
    if(!content){
        e = { ...e,
            content:`Cannot create campaign without ${type} selected`
        }
    }
    if(heading?.trim()?.length === 0){
        e = {...e,
            heading:`Add heading for campaign`
        }
    }
    if(description?.trim()?.length === 0){
        e = {...e,
            description:`Add description to campaign`
        }
    }
    if(!imageUpload?.url){
        e = {...e,
            image:`Upload campaign banner`
        }
    }
    setErrors(e)
    if(getObjectLength(e) > 0){
        
        return false
    }
    return true
  }

  const onFail = () => {
    openModal()
    modalContent(<>
        <OptionModal heading={'Something Went Wrong'}>
            <HoverOption onClick={() => {
                createCampaign()
            }}>Try Again</HoverOption>
            <HoverOption onClick={() => {
                closeModal()
                modalContent()
            }}>OK</HoverOption>
        </OptionModal>
    </>)
  }

  const onSuccess = () => {
    onCancel()
    openModal()
    modalContent(<>
        <OptionModal heading={'Campaign Created'} additionalText={`Your campaign will start on ${dates[0]}`}>
            <HoverOption onClick={() => {
                closeModal()
                modalContent()
            }}>OK</HoverOption>
        </OptionModal>
    </>)
  }

  const createCampaign =() => {
    if(validateContent())
    {
    let val = (30*dates?.length) + (addToPlaylist ? 50 : 0)
    //console.log(val)
    let info = filterContent()
    openModal()
    modalContent(<>
        <QRCodeForm
            type={TRANSACTION_OPTIONS.MARKETING_CAMPAIGN}
            heading={'Creating Marketing Campaign'}
            body={{
                sender: currentUser?.uid,
                value: val,
                dates: dates,
                addToPlaylist: addToPlaylist,
                playlistContent: addToPlaylist ? playlistContent : undefined,
                heading: heading,
                type: type,
                artists: info?.artists,
                image: imageUpload?.url,
                description: description,
                link:info?.link,
                linkArt: info?.linkArt,
                memo: `${currentUser?.uid} is signing transaction to create a ${type.toLowerCase()} campaign (id: ${info?.id})`,
                instruction: `${currentUser?.uid} is signing transaction to create a ${type.toLowerCase()} campaign (id: ${info?.id})`
            }}
            onFail={() => onFail()}
            onSuccess={() => onSuccess()}
            onCancel={() => {
            closeModal() 
            modalContent()}}
        />
    </>)
    }
  }

  return (
    <>
      <Header title={"Marketing Campaign"} />
      <BannerMarketingDetailsContainer>
        {!preview && (
          <>
          <FormWrapper>
            <FormLabel>Campaign Banner</FormLabel>
            <AlbumUploadsContainer
              style={{ alignContent: "center" }}
              for={
                imageUpload?.progress &&
                (imageUpload.progress > 0 &&
                !imageUpload?.url)
                  ? ""
                  : "imageUpload"
              }
            >
              {imageUpload?.progress > 0 && !imageUpload?.url && (
                <FormWrapper>
                  <>
                    <ModalLoadingScreen
                      transparent
                      isLoaded={imageUpload?.url ? true : false}
                    />
                    {imageUpload.progress < 100
                      ? `${imageUpload?.progress.toFixed(2)}%`
                      : `Processing`}
                  </>
                </FormWrapper>
              )}
              {(imageUpload?.url || !imageUpload?.progress) && (
                <CarouselCardImg
                  src={imageUpload?.url ? imageUpload?.url : SonarNavInvert}
                />
              )}
              {!imageUpload?.progress && !imageUpload?.url && <SmallText> Click to upload Banner 1280px x 720px (16:9) </SmallText>}
              {imageUpload?.url && <SmallText> Click to replace Banner 1280px x 720px (16:9) </SmallText>}
            </AlbumUploadsContainer>
            <ErrorText>{errors?.image}</ErrorText>
            </FormWrapper>
            <FileUploader
              name={"imageUpload"}
              id={"imageUpload"}
              type={"file"}
              minHeight={720}
              minWidth={1280}
              setFileUpload={setImageUpload}
              acceptMultiple={"false"}
              fileUpload={imageUpload}
              userType={userType ? userType : "user"}
              fileTypes={"image/x-png,image/gif,image/jpeg, image/jpg"}
            />

            <BannerMarketingDetailsWrapper>
              <FormWrapper>
                <FormWrapper>
                  <FormLabel>Heading</FormLabel>
                  <FormInput
                    name={"heading"}
                    value={heading}
                    onChange={handleInput}
                  />
                  <SmallText>Title for Marketing Campaign</SmallText>
                  <ErrorText>{errors?.heading}</ErrorText>
                </FormWrapper>

                {type !== CAMPAIGN_TYPE.PODCASTER &&
                  type !== CAMPAIGN_TYPE.ARTIST &&
                  type !== CAMPAIGN_TYPE.RECORD_LABEL && (
                    <FormWrapper>
                      <FormLabel>{type}</FormLabel>
                      {content && (
                        <Chip
                          closeButton={true}
                          label={
                            content?.albumName
                              ? content?.albumName
                              : content?.songName
                              ? getSongTitle(content)
                              : content?.name
                              ? content?.name
                              : content?.playlistName
                          }
                          onDelete={() => setContent()}
                        />
                      )}
                      <FormInput
                        readOnly={searchContents?.length === 0}
                        placeholder={`Search for a ${type} to campaign`}
                        value={searchValue}
                        onChange={(e) => searchContent(e)}
                      />
                      {filteredContent?.length && displaySearch()}
                      <SmallText>{`Search for a ${type} to campaign`}</SmallText>
                      <ErrorText>{errors?.content}</ErrorText>
                    </FormWrapper>
                  )}

                <FormWrapper>
                  <FormLabel>Message</FormLabel>
                  <FormInput
                    name={"description"}
                    value={description}
                    onChange={handleInput}
                  />
                  <SmallText>Message to be added on campaign</SmallText>
                  <ErrorText>{errors?.description}</ErrorText>
                </FormWrapper>
                <FormWrapper>
                  <FormLabel>Campaign Dates</FormLabel>

                  <ChipList alignment={"center"}>
                    {dates?.length > 0 &&
                      dates.map((date, i) => {
                        return (
                          <Chip
                            onDelete={() =>
                              setDates(dates.filter((d, num) => num !== i))
                            }
                            label={date}
                            closeButton={true}
                          />
                        );
                      })}
                  </ChipList>
                  {!addNew && (
                    <HoverOption
                      onClick={() => {
                        setAddNew(true);
                      }}
                    >
                      Add New Date
                    </HoverOption>
                  )}
                  {addNew && (
                    <FormWrapper>
                      <FormLabel>Select Date</FormLabel>
                      <FormInput
                      
                        placeholder={"Select Date"}
                        min={new Date().toISOString().split("T")[0]}
                        type={"date"}
                        value={day}
                        onChange={(e) => {
                          setDay(e.target.value);
                        }}
                      />

                      <ErrorText>{errors?.day}</ErrorText>
                      <ErrorText>{errors?.dates}</ErrorText>
                      <CustomButton
                        onClick={() =>
                          day?.length > 0 ? validateDay(day) : {}
                        }
                      >
                        Add Date
                      </CustomButton>
                      {
                        <CustomButton onClick={() => setAddNew(false)}>
                          Cancel
                        </CustomButton>
                      }
                    </FormWrapper>
                  )}

                  {/*  <DateRangePicker 
        ranges={[value]}
        displayMode=""
        minDate={new Date()}
        showSelectionPreview={false}
        showMonthAndYearPickers={false}
        editableDateInputs={false}
        shownDate={new Date()}
        onChange={handleSelect}
        /> */}
                  <SmallText>
                    Marketing Campaign costs $30/day
                    {dates?.length > 0 ? `: $${30 * dates?.length}` : ""}{" "}
                  </SmallText>
                  
                </FormWrapper>
              </FormWrapper>
             { (type === CAMPAIGN_TYPE.SINGLE || type === CAMPAIGN_TYPE.EPISODE) &&<FormWrapper>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "2%",
                  }}
                >
                  <BannerMarketingText>
                    Add To A Sonar Muse Weekly Playlist
                  </BannerMarketingText>
                  <ToggleButton
                    toggle={addToPlaylist}
                    toggleClick={() => {
                      if (
                        type !== CAMPAIGN_TYPE.SINGLE ||
                        CAMPAIGN_TYPE.EPISODE
                      ) {
                        setAddToPlaylist(!addToPlaylist);
                      } else {
                        return selectContent();
                      }
                    }}
                    customOnIcon={<FaCheckSquare />}
                    customOffIcon={<FaSquare />}
                  />
                </div>
                <SmallText>
                  $50 to add content to a Sonar Muse weekly curated playlist
                </SmallText>
              </FormWrapper>}
            </BannerMarketingDetailsWrapper>
          </>
        )}
        {preview && (
        <FormWrapper>
          <CarouselCard>
            <CarouselCardHeader>
              <CarouselCardParagraph>{type}</CarouselCardParagraph>
              <CarouselCardH2>
                {heading?.trim()?.length > 0 ? heading : "Heading"}
              </CarouselCardH2>
              <CarouselCardText>
                {content?.artists?.length > 0
                  ? getArtistsNames2(content?.artists, ARTIST_SEARCH_TYPES.NAME)
                  : content?.podcasters?.length > 0
                  ? getPodcastersNames2(content?.podcasters, PODCASTER_SEARCH_TYPES.NAME)
                  : type === CAMPAIGN_TYPE.ARTIST ? content?.artistName :
                    type === CAMPAIGN_TYPE.PODCASTER ? content?.podcasterName :
                    type === CAMPAIGN_TYPE.RECORD_LABEL 
                  ? content?.labelName
                  : type === CAMPAIGN_TYPE.PLAYLIST
                  ? content?.curator
                  : "Artists Name"}
              </CarouselCardText>
            </CarouselCardHeader>
            <CarouselCardBody>
              <CarouselCardImg
                src={imageUpload?.url ? imageUpload?.url : SonarNavInvert}
              />
              <CarouselDescription>
                <CarouselCardParagraph>
                  {description?.trim()?.length > 0
                    ? description
                    : "Add description here"}
                </CarouselCardParagraph>
                {(type === CAMPAIGN_TYPE.ARTIST ||
                  type === CAMPAIGN_TYPE.PODCASTER ||
                  type === CAMPAIGN_TYPE.RECORD_LABEL) && (
                  <CarouselMusicCover
                    onClick={() => {}}
                    src={content?.artistSettings?.profilePictureType === "custom" ? content?.profilePicture : content?.photoUrl}
                  />
                )}
                {(type === CAMPAIGN_TYPE.EPISODE ||
                  type === CAMPAIGN_TYPE.ALBUM ||
                  type === CAMPAIGN_TYPE.SINGLE ||
                  type === CAMPAIGN_TYPE.PLAYLIST) && (
                  <CarouselMusicCover
                    onClick={() => {}}
                    src={
                      content?.albumArt
                        ? content.albumArt
                        : content?.playlistArt
                        ? content?.playlistArt
                        : content?.cover?.url
                        ? content?.cover?.url
                        : DEFAULT_IMAGE(50)
                    }
                  />
                )}
              </CarouselDescription>
            </CarouselCardBody>
          </CarouselCard>
          </FormWrapper>
        )}
        {preview && (
          <HoverOption onClick={() => setPreview(false)}>
            Close Preview
          </HoverOption>
        )}
        {total > 0 && <ErrorText>Total: {total}</ErrorText>}
         {!preview && (
         <BannerMarketingButtonContainer>
         
            <CustomButton onClick={() => setPreview(true)}>
              Show Preview
            </CustomButton>
          
          <CustomButton onClick={onCancel}>Cancel</CustomButton>
          <CustomButton onClick={() => createCampaign()}>Upload</CustomButton>
        </BannerMarketingButtonContainer>)}
      </BannerMarketingDetailsContainer>
      
    </>
  );
};

export default MarketingForm;
