import React from "react";
import useArtist from "../../hooks/useArtist";
import { getObjectLength, getPaymentPointer, strToHex } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";
import axiosInstance from "../Authorization/client";
import { PAYOUT_TYPE } from "../../utils/Enum";

const monetization = document.querySelector('meta[name="monetization"]'); // getting the meta tag from the Header to change the payment pointer

export const defaultPaymentPointer = () => {
  // Sonar Muse Default payment pointer
  monetization.setAttribute("content", "$ilp.gatehub.net/239263989");
};

export function pickPointer(pointers) {
  // Probabalistic picker for a payment pointer when a song is being played
  const sum = Object.values(pointers).reduce((sum, weight) => sum + weight, 0);
  let choice = Math.random() * sum;

  for (const pointer in pointers) {
    const weight = pointers[pointer];
    if ((choice -= weight) <= 0) {
      //console.log(pointer)
      return pointer;
    }
  }
}
export function pickChannels(channels) {
  // Probabalistic picker for a payment channels when a song is being played
  const sum = Object.values(channels).reduce((sum, weight) => parseFloat(sum) + parseFloat(weight), 0);
  let choice = Math.random() * sum;

  for (const channel in channels) {
    const weight = channels[channel];
    if ((choice -= weight) <= 0) {
      return channel;
    }
  }
}

export const DEFAULT_CHANNEL = `${strToHex(PAYOUT_TYPE.ARTIST)}.sONaRhzweIAZlMXUiKDM`

export const isUserPayingOut = () => {
  /* //console.log("WM", document?.monetization?.state)
        if(document?.monetization 
          && document?.monetization?.state 
          && document?.monetization?.state !=="Not enabled in browser"){
          return true
        }else{
          return false
        } */
  return true;
};

export const changePaymentPointer = (pointers, artists) => {
  let cPointer; // storing the current payment pointer
  try {
    // //console.log('Artists:',artists)
    if (pointers && getObjectLength(pointers) > 0) {
      // if more than one paymentPointer, then use pickPointer function
      cPointer = pickPointer(pointers);
      let cArtist = artists.filter((artist) => artist.artistId === cPointer);
      if (cArtist[0]?.paymentPointer) {
        monetization.setAttribute("content", cArtist[0]?.paymentPointer);
      } else {
        getPaymentPointer(cPointer)
          .then((artist) => {
            if (artist && artist !== false) {
              monetization.setAttribute("content", artist);
            } else {
              if (artists[0]?.paymentPointer) {
                monetization.setAttribute(
                  "content",
                  artists[0]?.paymentPointer
                );
              } else {
                defaultPaymentPointer();
              }
            }
          })
          .catch((err) => {
            if (artists[0]?.paymentPointer) {
              monetization.setAttribute("content", artists[0]?.paymentPointer);
            } else {
              defaultPaymentPointer();
            }
          });
      }
    }
  } catch (err) {
    //console.log(err)
    defaultPaymentPointer();
  }
};

export const getPaymentPointers = (royalties, artists) => {
  let paymentPointers = {};
  for (const royalty in royalties) {
    const artist = artists.filter((a) => a.artistId === royalty);
    if (artist[0]?.paymentPointer) {
      paymentPointers = {
        ...paymentPointers,
        [artist[0]?.paymentPointer]: royalties[royalty],
      };
    }
  }
  //console.log(paymentPointers);
  return paymentPointers;
};

export const setPaymentPointers = (pointers) => {
  let cPointer; // storing the current payment pointer
  if (pointers && getObjectLength(pointers) > 0) {
    // if more than one paymentPointer, then use pickPointer function
    cPointer = pickPointer(pointers);
    monetization.setAttribute("content", cPointer);
  } else {
    defaultPaymentPointer();
  }
};

export const sonarMonetization = document.querySelector(
  'meta[name="swerl"]'
);

export const defaultPaymentChannel = () => {
  // makeMicropayment(PAYOUT_TYPE.ARTIST, "sONaRhzweIAZlMXUiKDM");
   sonarMonetization.setAttribute(
     "content",
     `${strToHex(`${strToHex(PAYOUT_TYPE.ARTIST)}.sONaRhzweIAZlMXUiKDM`)}`
   );
 };

 export const setPaymentChannels = (type,channels) => {
  let cChannel; // storing the current payment pointer
  if (channels && getObjectLength(channels) > 0) {
    // if more than one paymentChannel, then use pickChannel function
    cChannel = pickPointer(channels);
    sonarMonetization.setAttribute("content", strToHex(`${type}.${cChannel}`));
  } else {
    defaultPaymentChannel();
  }
};

export const setSwərl = (type, channel, title) => {
  return sonarMonetization.setAttribute("content",strToHex(`${strToHex(type)}.${channel}${title?.trim()?.length > 0 ? `.${strToHex(title)}` : ''}`))
}
 

export const SonarMuseWebMonetization = () => {
  //const { currentUser, makeMicropayment } = useAuth();
  //const axios = axiosInstance;
  // getting the meta tag from the Header to change the payment channel

  const defaultPaymentChannel = () => {
   // makeMicropayment(PAYOUT_TYPE.ARTIST, "sONaRhzweIAZlMXUiKDM");
    setSwərl(PAYOUT_TYPE.ARTIST,'sONaRhzweIAZlMXUiKDM');
  };

  const changeArtistPaymentChannel = (royalties, title) => {
    let cChannel; // storing the current payment pointer
    try {
      // //console.log('Artists:',artists)
      if (royalties && getObjectLength(royalties) > 0) {
        // if more than one paymentPointer, then use pickPointer function
        cChannel = pickChannels(royalties);
        //makeMicropayment(PAYOUT_TYPE.ARTIST, cChannel)
        setSwərl(PAYOUT_TYPE.ARTIST,cChannel, title)
      } else {
        defaultPaymentChannel()
      }
    } catch(err){
      console.error(err)
      return defaultPaymentChannel()
    }
  };
  const changePodcasterPaymentChannel = (royalties, artistRoyalties, title) => {
    let payoutType = getObjectLength(artistRoyalties) > 0 ? Math.floor(Math.random() * 2) : 0
    let cChannel
    try{
    if(payoutType === 0){
      if (royalties && getObjectLength(royalties) > 0) {
        cChannel = pickChannels(royalties);
        return setSwərl(PAYOUT_TYPE.PODCASTER, cChannel, title)
      }else {
        return defaultPaymentChannel()
      }
    }
    if(payoutType === 1)
    if (artistRoyalties && getObjectLength(artistRoyalties) > 0) {
      cChannel = pickChannels(artistRoyalties);
      return setSwərl(PAYOUT_TYPE.ARTIST, cChannel, title)
    }else {
      return defaultPaymentChannel()
    }
  }catch(err){
    console.error(err)
    return defaultPaymentChannel()
  }
  };

  const userRoyaltyPayout = (royaltyId) => {
    setSwərl(PAYOUT_TYPE.ROYALTY_SHARE, royaltyId, "Regium Share")
  }




  return {
    changeArtistPaymentChannel,
    defaultPaymentChannel,
    changePodcasterPaymentChannel,
    userRoyaltyPayout
  };
};
