import React from 'react'
import { SLIDER_TYPES } from '../../../utils/Enum'
import ContentWrapper from '../../ContentWrapper'
import { Slider } from '../../Slider'

const RecordLabelArtists = ({name,artists}) => {
  return (
    <ContentWrapper heading={`${name} Artists `}>
        {artists?.length > 0 && <Slider type={SLIDER_TYPES.ARTIST} id={'artists'} slides={artists}/>}
        {artists?.length === 0 && 'No Artists Available'}
          
        </ContentWrapper>
  )
}

export default RecordLabelArtists