import styled from "styled-components"
import { hexToRgba } from "../HexToRGBA"


export const NavContainer = styled.div`
  display: flex;
  padding: 0 2%;
  width: 100%;

`

export const Nav = styled.div`
display: flex;
color: ${(props) => props.theme.secondaryBg};
flex-direction: row;
align-items: center;
overflow: hidden;
border-radius: 5px;
justify-content: space-evenly;
width: 100%;
`

export const NavLink = styled.a`
display: flex;
font-size: 0.85rem;
text-align: center;
justify-content: center;
overflow: hidden;
transition: all 0.2s ease-in-out;
cursor: pointer;
padding: 1%;
width: 100%;
height: 100%;
z-index: 10;
&:hover {
   
  background: ${(props) => (props.active ? hexToRgba(props.theme.secondaryColor,1) :'transparent') };
  color: ${(props) => (props.active ? props.theme.secondaryBg: props.theme.secondaryColor )};
  transition: all 0.3s ease-in-out;
  transform: ${(props) => (props.active ? 'scale(1.1)'  :'scale(0.9)' )};
}
  background: ${(props) => (props.active ?  hexToRgba(props.theme.secondaryColor,0.8) :  hexToRgba(props.theme.secondaryColor,0.4)) };
  color: ${(props) => props.theme.secondaryBg};
    @media screen and (max-width: 480px) {
      font-size: 0.5rem;
    
    }

`