import { Link } from "react-router-dom";
import styled from "styled-components";
import { hexToRgba } from "../../HexToRGBA";

export const CommentContainer = styled.div`
display: flex;
width: 100%;
height: 100%;
padding: 2%;
flex-direction: column;
border-radius: 15px;
justify-content: space-evenly;
background: transparent;
border: 1px solid ${(props) => hexToRgba(props.theme.accentColor, 1)};
`

export const CommentHeader = styled.div`
display: flex;
width: 100%;
height: 100%;
padding: 0% 1%;
font-weight: bold;
color: ${(props) => props.theme.secondaryBg};
justify-content: space-between;
margin-bottom: 2%;
border-bottom: solid 0.5px ${(props) => hexToRgba(props.theme.secondaryBg, 0.1)};
`

export const CommentsHeaderText = styled(Link)`
display: flex;
    text-decoration: none;
    padding-bottom: 1%;
    color: ${(props) => props.theme.secondaryBg};
    justify-content: space-evenly;
    align-items: center;
    &:hover {
        text-decoration: underline;
        transition: all 0.2s ease-in-out;
        opacity: 1;
      }

`

export const CommentMessage  = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
margin-bottom: 2%;
padding: 2%;
color: ${(props) => props.theme.secondaryBg};
border-radius: 5px;
background: ${(props) => hexToRgba(props.theme.accentColor, 0.05)};

`
export const CommentMessageText  = styled.text`
display: flex;
flex-direction: column;
white-space: break-spaces;
margin-bottom: 2%;
`

export const CommentFooter  = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${props => hexToRgba(props.theme.secondaryColor, 0.05)};
`

export const NestedCommentStack  = styled.div`
    display: flex;
    padding-left: 10%;
    justify-self: flex-end;
    width: 100%;
`

export const NestedComments  = styled.div`
    display: flex;
     
    width: 100%;
`

export const CommentActionButtons = styled.div`
display: flex;
flex-direction: row;
margin-top: 8px;
diplay: inline-flex;
align-items: center;
`

export const CommentButton = styled.div`
margin-right: 8px;
cursor: pointer;
font-weight: 700;
align-items: center;
align-content: center;
justify-content: center;
border-radius: 15px;
padding: 1% 3%;
white-space: nowrap;
word-break: nowrap;
width: fit-content;
background-color: ${props => hexToRgba(props.theme.accentColor, 0.3)};

 &:hover{
    background-color: ${props => hexToRgba(props.theme.accentColor, 0.5)} 
    
  }

`





