import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useRecordLabel from '../../hooks/useRecordLabel'
import RecordLabelProfileContent from './RecordLabelProfileContent'
import { RecordLabelProfileContainer, RecordLabelProfileWrapper } from './RecordLabelProfileElements'
import RecordLabelProfileHeader from './RecordLabelProfileHeader'
import { defaultPaymentChannel, setPaymentChannels, setSwərl } from '../SonarMuseWebMonetization'
import { PAYOUT_TYPE } from '../../utils/Enum'
import { updateWebPageIcon } from '../../utils/MusicUtils'
import LoadingScreen from '../LoadingScreen'

export const RecordLabelProfile = ({recordLabel}) => {
    const {getRecordLabelProfile} = useRecordLabel({})
    const [recordLabelProfile, setProfile] = useState({})
    const [isLoaded, setLoaded] = useState(false)
    const navigate  = useNavigate()

    useEffect(() => {
      setLoaded(false)
      getProfile()
      return () => {}
    }, [])
    

    const getProfile = async() => {
        return await getRecordLabelProfile(recordLabel).then((profile) => {
            if (profile){
              updateWebPageIcon(profile?.artistSettings?.profilePictureType === "custom" &&
          profile?.profilePicture
            ? profile?.profilePicture
            : profile?.photoUrl
            ? profile.photoUrl + "?s=600&d=mp"
            : 'https://sonarmuse.org/favicon.ico')
                setProfile(profile)
                if(profile?.profile?.paymentChannel){
                  setSwərl(PAYOUT_TYPE.RECORD_LABEL, recordLabel)
                  setPaymentChannels(PAYOUT_TYPE.RECORD_LABEL,{[profile?.profile?.paymentChannel]: 100})
                }else{
                  defaultPaymentChannel()
                }
            }
            setLoaded(true)
            return
        }).catch(err => {
            navigate(-1)
        })
    }

  return (
    <>
    {!isLoaded && <LoadingScreen isLoaded={isLoaded} />}
    {isLoaded && <RecordLabelProfileWrapper>
      <RecordLabelProfileHeader labelId={recordLabelProfile?.labelId} profile={recordLabelProfile?.profile}
        gravatarProfile={recordLabelProfile?.gravatarProfile}
        wallets={recordLabelProfile?.gravatarProfile?.currency ? Object.values(recordLabelProfile?.gravatarProfile?.currency): undefined}
        />
       
      <RecordLabelProfileContent labelId={recordLabelProfile?.labelId} 
       getProfile={getProfile}
       profile={recordLabelProfile?.profile}
       gravatarProfile={recordLabelProfile?.gravatarProfile}
       about={{
         photos: recordLabelProfile?.gravatarProfile?.gallery,
         accounts: recordLabelProfile?.gravatarProfile?.verified_accounts,
         bio: recordLabelProfile?.profile?.recordLabelSettings?.bioType === "custom" ? recordLabelProfile?.profile?.bio : recordLabelProfile?.gravatarProfile?.description,
       }}/>
    </RecordLabelProfileWrapper>}</>
  )
}

