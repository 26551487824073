import React from 'react'
import { SLIDER_TYPES } from '../../../utils/Enum'
import ContentWrapper from '../../ContentWrapper'
import { Slider } from '../../Slider'
import { PodcasterContentHeadingWrapper } from './PodcasterProfileContentElements'
import { stringFormatter } from '../../../utils'
import ImageSlider from "../../ImageSlider";
import SocialLinks from "../../SocialLinks";

const PodcasterAbout = ({name, socialLinks, bio, images, podcaster, tourDates}) => {
  return (
    <ContentWrapper heading={`About ${name}`}>
        {socialLinks &&<PodcasterContentHeadingWrapper style={{flexDirection: 'row', justifyContent:'flex-start'}}> <SocialLinks accounts={socialLinks}/></PodcasterContentHeadingWrapper>}
        {(bio || podcaster?.podcasterSettings?.bio) && <div style={{color:'white', whiteSpace: "pre-wrap"}}>{podcaster?.bio ? podcaster?.podcasterSettings?.bioType === "custom" ? stringFormatter(podcaster.bio): bio : bio }</div>}
        {(images?.length > 0 && podcaster?.podcasterSettings?.showGallery) && <ImageSlider images={images}/>}
        
        {(tourDates && podcaster?.podcasterSettings?.showTrips) && <>{tourDates}</>}
        </ContentWrapper>
  )
}

export default PodcasterAbout