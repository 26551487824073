import React, { useState, useEffect } from "react";
import CountdownTimer from "../CountdownTimer";
import LoadingScreen from "../LoadingScreen";
import Navbar from "../Navbar";
import { TrustlineStats } from "../TokenStats";
import { Navigate } from "react-router";
import { useAuth } from "../../contexts/AuthContext";
import { Snapshot, Airdrop } from "../CountdownTimer/Data";
import {
  Container,
  Form,
  FormContent,
  FormWrap,
  Text,
} from "../SignUp/SignUpElements";
import { AccessContainer } from "./AccessElements";
import MusicContent from "./MusicContent";
import { updateWebPageIcon } from "../../utils/MusicUtils";

const Access = () => {
  const [isLoaded, setLoaded] = useState(false);
  const [userAddress, setAddress] = useState();
 
  const { setEligible, currentUser} =
    useAuth();

  //const navigate  = useNavigate()
  const [errMsg, setError] = useState();
  
  const getUserInfo = async () => {
    //console.log('loading...')
    setLoaded(false);
    
    if (localStorage.getItem("user") && localStorage.getItem("accInfo")) {
      //console.log('loading...')
      let address = localStorage.getItem("accInfo");
      setAddress(address);
    } else {
      setEligible(false);
      setError("Something went wrong. Reload the page and try again");
    }

    setLoaded(true);
    return;
  };

  useEffect(() => {
    updateWebPageIcon('https://sonarmuse.org/favicon.ico')
    return () => {}
  },[])

  useEffect(() => {
    getUserInfo();
    return () => {}
  }, []);

  if (userAddress) {
    return (
      <>
        <AccessContainer>
          <MusicContent/>
          {/* <CountdownTimer {...Airdrop} /> */}
          <TrustlineStats />
        </AccessContainer>
      </>
    );
  }
  if (errMsg) {
    return (
      <Container>
        <Navbar navInfo={Access} />
        <FormWrap>
          <FormContent>
            <Form action={(e) => e.preventDefault()}>
              <Text>{errMsg}</Text>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    );
  }

  if (isLoaded === false && !userAddress) {
    return (
      <>
        <LoadingScreen isLoaded={isLoaded} />
      </>
    );
  }

  if (userAddress === false && !isLoaded) {
    //console.log(false);
    return (
      <>
        <Navigate to="/" />
      </>
    );
  }
};

export default Access;
