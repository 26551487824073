import styled from "styled-components";
import { MEDIA_SCREEN } from "../../../utils/Enum";
import { hexToRgba } from "../../HexToRGBA";

export const MetricsContainer = styled.div`
display: flex;
flex-direction: column;
margin-top: 1%;
min-height: 80px;
justify-content: center;
align-content: center;
margin-bottom: 5%;
box-sizing: border-box;
position: relative;
width: 100%;


`

export const MetricsHeader = styled.div`
display: flex;
margin-bottom: 15px;

`

export const MetricsDataWrapper = styled.div`
display: grid;
box-sizing: border-box;
gap: 30px 20px;
grid-template-columns: repeat(${props => props?.size ? props?.size : 4}, minmax(0px, 1fr));

@media screen and (max-width: 735px)
{
  grid-template-columns: repeat(${props => props?.size/2 > 0 ? props?.size/2 : 2}, minmax(0px, 1fr));
}

@media screen and (max-width: ${MEDIA_SCREEN.MOBILE})
{
  grid-template-columns: repeat(1, minmax(0px, 1fr));
}
` 

export const MetricsContent = styled.div`
display: flex;
box-sizing: border-box;
padding: 10% 15%;
border-radius: 4px;
flex-direction: column;
align-content: space-between;
width: 100%;
overflow: hidden;
cursor: pointer;
background-color: ${props => hexToRgba(props.theme.secondaryBg,0.5)};
&:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.07);
    background: ${(props) => hexToRgba(props.theme.secondaryBg,0.3)};
    border: solid ${props => hexToRgba(props.theme?.secondaryColor, 1)} 1px;
   
  }
`

export const MetricsContentHeader = styled.text`
    display: flex;
    font-size: 0.7rem;
    font-weight: 300;
    width:100%;
    color: ${props => props.theme.mainBg};
    margin-bottom: 15%;

`
export const MetricsContentText = styled.text`
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    width:100%;
    color: ${props => props.theme.mainBg};
    margin-bottom: 15%;

`