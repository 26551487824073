import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { copyTextToClipboard, validateAddress } from "../../utils";
import { ALERT_TYPES, TRANSACTION_OPTIONS } from "../../utils/Enum";
import { useAlert } from "../Alert/AlertContext";
import {
  FormImg,
  FormImgWrapper,
  FormInput,
  FormText,
  FormTextArea,
} from "../CustomForm/CustomFormElements";
import { HoverOption } from "../HoverMenu/HoverMenuElements";
import { useModal } from "../Modal/ModalContext";
import OptionModal from "../OptionModal";
import QRCodeForm from "../QRCodeForm";
import ScrollToTop from "../ScrollToTop";
import { ErrorText } from "../ArtistProfile/ArtistContent/ArtistContentElements";

const NFTokenCollectionGifter = ({ collection, isPurchase }) => {
  const { currentUser } = useAuth();
  const { addAlert } = useAlert();
  const { modalContent, toggleModal, openModal, closeModal } = useModal();
  const { goTo } = ScrollToTop();
  const [giftee, setGiftee] = useState(); //person the NFT will be gifted to
  const [comment, setComment] = useState(); // send a message to the giftee
  const [error, setError] = useState();

  const onGiftSuccess = (result) => {
    //console.log(result)
    modalContent(
      <OptionModal
        isOpen={true}
        heading={`NFToken from ${collection?.name} gifted to ${giftee}`}
      >
        <FormImgWrapper>
          <FormImg src={collection?.cover?.url} alt={collection.name} />
        </FormImgWrapper>

        <FormText>
          {" "}
          {`NFToken ${result?.tokenId.substring(0, 10)}... successfully minted`}
        </FormText>
        <HoverOption
          onClick={() => {
            copyTextToClipboard(result?.payload?.next?.always);
            addAlert({
              title: "Copied To Clipboard",
              type: ALERT_TYPES.INFO,
              secs: 10,
            });
          }}
        >
          Send Giftee NFToken Accept Offer
        </HoverOption>
        <HoverOption
          onClick={() => {
            toggleModal();
            modalContent();
            goTo(`/nftoken/${result?.tokenId}`);
          }}
        >
          View Token
        </HoverOption>
        <HoverOption
          onClick={() => {
            toggleModal();
            modalContent();
          }}
        >
          Close
        </HoverOption>
      </OptionModal>
    );
  };

  const onGiftFail = () => {
    modalContent(
      <OptionModal isOpen={true} heading={`Something Went Wrong`}>
        <FormText> {`Could Not Gift Token. Try Again?`}</FormText>
        <HoverOption
          onClick={() => {
            confirmGift();
          }}
        >
          Try Again
        </HoverOption>
        <HoverOption
          onClick={() => {
            toggleModal();
            modalContent();
          }}
        >
          Cancel
        </HoverOption>
      </OptionModal>
    );
  };

  const onCancelGift = () => {
    closeModal();
  };

  const confirmPathFinding = () => {
    modalContent(
      <OptionModal
        additionalText={`Note: If you try to pay with ${collection?.currency}, the transaction will fail!`}
        heading={`You are about to confirm paying with a token other than ${collection?.currency}`}
      >
        <HoverOption
          onClick={() => {
            confirmGift(true);
          }}
        >
          Continue
        </HoverOption>
        <HoverOption
          onClick={() => {
            closeModal();
            modalContent();
          }}
        >
          Cancel
        </HoverOption>
      </OptionModal>
    );
  };

  const confirmGift = (pathfinding) => {
    if (giftee !== currentUser?.uid) {
      modalContent(
        <>
          <QRCodeForm
            heading={`Minting an NFToken from ${collection?.name} as a gift to ${giftee}`}
            type={TRANSACTION_OPTIONS.NFTOKEN_GIFT}
            onFail={onGiftFail}
            onCancel={onCancelGift}
            onSuccess={onGiftSuccess}
            body={{
              pathfinding: true,
              comment: comment,
              isPurchase: isPurchase,
              giftee: giftee.trim(),
              quantity: 1,
              currency: collection?.currency,
              total: isPurchase && collection?.price,
              sender: currentUser?.uid,
              collection: collection,
              collectionId: collection?.collectionId,
              instruction: `${currentUser?.uid ? currentUser?.uid : 'User'} is minting an NFToken from the collection ${collection?.name} (id: ${collection?.collectionId}) to gift to ${giftee}`,
              memo: `${currentUser?.uid ? currentUser?.uid : 'User'} is minting an NFToken from the collection ${collection?.name} (id: ${collection?.collectionId}) to gift to ${giftee}`,
            }}
          />
        </>
      );
    }
  };

  return (
    <>
      <OptionModal
        heading={`NFToken Gifter`}
        additionalText={`Gifting NFToken ${collection?.name}`}
      >
        <FormInput
          onChange={(e) => setGiftee(e.target.value)}
          placeholder={"wallet address"}
          value={giftee}
        />
        <FormTextArea
          onChange={(e) => setComment(e.target.value)}
          placeholder={"Add a custom message"}
          value={comment}
        />
        {error?.trim().length > 0 && (
          <>
            <ErrorText>{error}</ErrorText>
          </>
        )}
        <HoverOption
          onClick={() => {
            if (validateAddress(giftee)) {
              confirmGift(false);
            } else {
              return setError("Invalid Destination");
            }
          }}
        >
          Send Gift
        </HoverOption>
       {/*  {isPurchase && (
          <HoverOption
            onClick={() => {
              if (validateAddress(giftee)) {
                confirmPathFinding();
              } else {
                return setError("Invalid Destination");
              }
            }}
          >
            Gift With A Currency (Token) of Your Choice
          </HoverOption>
        )} */}
        <HoverOption onClick={onCancelGift}>Cancel</HoverOption>
      </OptionModal>
    </>
  );
};

export default NFTokenCollectionGifter;
