import React from 'react'
import styled from 'styled-components'

export const MEDIA_SCREEN = { //Screen sizes for devices
  MOBILE: '480',
  TABLETS: '768',
  LAPTOPS: '1024',
  DESKTOPS: '1025',
  TV: '1500'
}


export const Badge = styled.div`
background-color: ${props => props.theme.mainColor};
display: flex;
color: white;
margin-left: ${props => props.isOpen ? '80%' : '30%' };
font-size: 0.7rem;
position: absolute;
padding: 4px 8px;
text-align: center;
border-radius: 5px;

@media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    margin-left: 2%;
    padding: 2px 4px;
    font-size: 0.3rem;
    color: ${props => props.theme.mainColor};
    border-radius: 50%;
  }
  @media screen and (min-width: ${MEDIA_SCREEN.TV}px)
  {
    transition: 0.8s all ease;
    margin-left: 1%;
    color: ${props => props.theme.mainColor};
    border-radius: 50%;  
  }

`

export const Span = styled.span`
display: flex;
flex-direction: row;
width: 100%;
`

const BadgeIcon = ({children, icon, isOpen, onClick}) => {
  return (
    <><Span onClick={onClick}><>{children}{icon && <Badge onClick={onClick} isOpen={isOpen}>{icon}</Badge>}</></Span></>
  )
}

export default BadgeIcon