import { FaPlayCircle } from "react-icons/fa"
import styled from "styled-components"
import { CustomButton } from "../../ButtonElement"



export const HeaderContainer = styled.div`
position: relative;
display: flex;
width: 100%;
height: 55vh;
margin-bottom: 2%;
background: transparent;
`


export const  PodcasterHeader = styled.div`
display: flex;
position:relative;
flex-direction: column;
background: ${(props) => 
    ( (props?.background && Object.keys(props?.background)?.length > 2) ? 
    props?.headerType ? props?.headerType === "background" ? 
    `url(${props.background.url}) no-repeat fixed center` : 
    `linear-gradient(180deg, ${props.background.color} 0%, rgba(84, 120, 154, 0.6232) 100%)` : 
    (props.background?.url ? `url(${props.background.url}) no-repeat fixed center` : `linear-gradient(180deg, ${props.background.color} 0%, rgba(84, 120, 154, 0.6232) 100%)`) :
         `linear-gradient(180deg, ${props.theme.mainColor} 0%, rgba(84, 120, 154, 0.6232) 100%)`)
};
opacity: 0.5;
justify-content: center;
justify-self: center;
align-items: center;
width: 100%;
height: 100%;

filter: blur(8px);
  -webkit-filter: blur(8px);
`


export const Img = styled.img`
    width: 100%;
    height:100%;
    object-fit: cover;
    

`

export const PodcasterProfileHeaderContainer = styled.div`
display: flex;
z-index: 5;
flex-direction: row;
position: absolute;
justify-content: space-evenly;
align-items: center;
width: 100%;
height: 100%;
padding: 2%;
`

export const PodcasterProfilePictureContainer = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
padding: 2%;

`


export const  PodcasterProfilePicture = styled.div`
border-radius: 50%;
width: 15rem;
height: 15rem;
border: 1px solid ${props => props.theme.secondaryColor};
background: url(${props => props.src}) ${props => props.theme.secondaryColor};
background-size: cover;
box-shadow: 0 10px 3px rgba(0, 0, 0, 0.3);
margin-bottom: 2%;
@media screen and (max-width: 960px) {
  width: 12rem;
  height: 12rem;
}
`

export const PodcasterInfoWrapper = styled.div`
display: flex;
flex: 1 1 100%;
justify-content: flex-start;
align-items: center;
@media screen and (max-width: 960px) {
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
}
`

export const PlayButton = styled(FaPlayCircle)`
display: flex;
margin-right: 1%;
color: ${(props) =>  props.background?.color ? props.background.color : props.theme.secondaryColor};
font-size: 2.5rem;
&:hover{
    color: ${props => props.theme.mainColor};
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
}

@media screen and (max-width: 960px) {
  font-size: 1.8rem;
  white-space: nowrap;
}

`

export const  PodcasterName = styled.div`
display: flex;
position: relative;
font-size: 2.5rem;
flex: 1 1 50%;
outline: 3px;
color: ${(props) =>  props.theme.secondaryBg};
filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};

@media screen and (max-width: 960px) {
    font-size: 1.5rem;
    text-align: left;
    white-space: nowrap;
    margin-bottom: 3%;
  }


`

export const VerifiedBadge = styled.img`
display: flex;
position: relative;
width: 1.5rem;
height: 1.5rem;
@media screen and (max-width: 960px) {
    font-size: 1rem;
  }
`

export const  ActionButtonsContainer = styled.div`
display: flex;
flex: 1 1 30%;
align-items: center;
justify-content: center;
align-content: center;
position: right;
`

export const ActionButton = styled(CustomButton)`
    display: flex;
    font-size: 0.85em;
    padding: 2% 5%;
    margin: 0px 3%;
    background-color:  ${props => props.active? (props.background?.color ? props.background.color : props.theme.mainColor) : props.theme.mainColor };
    color: ${props => props.active? (props.background?.color ? props.background.color : props.theme.mainColor) : props.theme.secondaryBg };
    justify-content: space-between;
    outline: ${(props) => (props.background?.color ? `1px solid ${props.background.color}` : `1px solid ${props.theme.mainColor}` )};

    &:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.1);
        background-color:  ${props => (props.background?.color ? props.background.color : props.theme.mainColor)};
        color: ${(props) => props.theme.secondaryBg};
        text-decoration: none;
    }
    @media screen and (max-width: 960px) {
      font-size: 0.65em;
    }
`