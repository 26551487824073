import React, {useEffect, useState} from 'react'
import axios from '../Authorization/client'
import WebSocket from "react-websocket";
import { ThemeProvider } from 'styled-components'
import LoadingScreen, { ModalLoadingScreen } from '../LoadingScreen/index'
import Navbar from '../Navbar'
import { Access } from '../Navbar/Data'
import { 
  Container, 
  ContentWrapper, 
  Form,
  FormButton,
  FormContent, 
  FormH1, 
  FormWrap, 
  QrCode, 
  QrWrapper,  
  Text, 
  TextLink,
  VideoBg, } from '../SignUp/SignUpElements'
import { VideoUtils } from '../../utils/ImageUtils';
import { ALERT_TYPES } from '../../utils/Enum';
import ScrollToTop from '../ScrollToTop';
import { useAlert } from '../Alert/AlertContext';
import Footer from '../Footer';
import { FormButton1 } from '../CustomForm/CustomFormElements';
import { CustomButton } from '../ButtonElement';
import { getObjectLength } from '../../utils';
import { getSignTxStatus } from '../../utils/dbUtils/signTxUtils';


const TrustlineCreate = ({token, onCancel}) => {
  const {video1} = VideoUtils()
  const { addAlert } = useAlert();
  const {goTo} = ScrollToTop()
    const [errMsg, setError] = useState()
    const [msg, setMessage] = useState('')
    const [invoice, setInvoice] = useState();
    const [status, setStatus] = useState("idle");
    const [response, setResponse] = useState({})  
    const [isLoaded, setLoaded] = useState(false)
    const [isOpened, setOpened] = useState(false)
    const [payload, setPayload] = useState()
    const [isSigned, setSigned] = useState(false)

    useEffect(() => {
      if(invoice){
            getSignTxStatus(invoice, status, setStatus, setResponse)
  
      }
      return () => {
      }
    }, [invoice])
    
    useEffect(() => {
      if(invoice){
        if(status === "success" && response !== false){
          setLoaded(false);
          setSigned(true)
            setPayload();
            onCancel()
            addAlert({
              title: "Trustline Set",
              type: ALERT_TYPES.SUCCESS,
              message: `The ${token} trustline was created successfully.`,
            }); 
            setError(
              `The ${token} trustline was created successfully.`
            ); 
            setOpened(false)
      }if(status === "error"){
        setLoaded(false);
        setPayload();
        addAlert({
          title: "Trustline Set",
          type: ALERT_TYPES.DANGER,
          message: `The transaction failed. Reload the page and try again.`,
        }); 
        setError(
          "The transaction failed. Reload the page and try again"
        );
        setOpened(false) 
      }}
      return () => {
  
      }
    }, [status, invoice])
  
    const SignIn = async () => {
        axios.post(`/api/${token}/createTrustline`, {userToken: localStorage.getItem('userToken')}).then(res => {
          if (!res?.data?.error && res?.data?.payload) {
            setInvoice(res.data.invoice)
            setPayload(res.data.payload);
            setLoaded(true);
          } else {
            setError(
              "Oops! Something wrong happened. Reload the page"
            );
            setLoaded()
          }
        }).catch(err => {
          //console.log(err)
          setError('Oops! Something wrong happened. Reload the page')
          setLoaded()
          
        })
      };

    const updateSignInStatus = async (messageData) => {
        //console.log('Message Data: ', messageData)
        const data = JSON.parse(messageData);
        if (data.opened === true) {
          setOpened(true);
          setMessage("Wating for you to sign the transaction");
        }
        if (isOpened === true && data.signed === true) {
        setLoaded(false);
        setSigned(true)
          setPayload();
          onCancel()
          addAlert({
            title: "Trustline Set",
            type: ALERT_TYPES.SUCCESS,
            message: `The ${token} trustline was created successfully.`,
          }); 
          setError(
            `The ${token} trustline was created successfully.`
          ); 
          setOpened(false)        //console.log("Signed")
        } 
        if(data?.signed === false) {
          setLoaded(false);
          setPayload();
          addAlert({
            title: "Trustline Set",
            type: ALERT_TYPES.DANGER,
            message: `The transaction was rejected. Reload the page and try again.`,
          }); 
          setError(
            "The transaction was rejected. Reload the page and try again"
          );
          setOpened(false) 
        }
      };

      const validateTx = async() => {
        setLoaded(false)
        return await axios
              .post("/api/verifyTxPayload", { uuid: payload?.uuid }).then((verification) => {
                //console.log(verification)
                if(verification?.data && !verification?.data?.error)
                {
                  setLoaded(false);
                  //dispatch(verification?.data);
                  getSignTxStatus(invoice, status, setStatus, setResponse)
                  setPayload();
                  setError("The transaction went through successfully.");
                  setOpened(false); //console.log("Signed")
                  return
                }else{
                  setLoaded(true)
                  setError("The transaction was not signed.");
                  return
                }
              }).catch(err => {
                console.error(err)
                setLoaded(true)
                setError("Something went wrong. Try again");
              }) 
           
      }

const refreshPage = ()=>{
  setPayload()
  setError('')
  setInvoice()
  setStatus("idle")
  setResponse()
  setOpened(false)
  setLoaded(false)
  SignIn()

}

  useEffect(() => {
    SignIn()
    return () => {}
  }, [])
    
        //console.log("Websocket: ",payload.refs.websocket_status)
        return (
          <>
           
               {isLoaded === true && getObjectLength(payload) > 0 && 
               <><WebSocket
                url={payload.refs.websocket_status}
                onMessage={(data) => updateSignInStatus(data)}
              />
                <FormWrap>
                  <FormContent>
                    <Form action={(e) => e.preventDefault}>
                      <FormH1> Add Trustline </FormH1>
                      <QrWrapper>
                        <QrCode src={payload.refs.qr_png} />
                      </QrWrapper>
                      {isOpened === false && (
                        <Text>
                          Scan QR code or click {" "}
                          <TextLink href={payload.next.always} target="_blank" rel="noopener noreferrer">here</TextLink> to go
                          to your Xaman wallet
                        </Text>
                      )}
                      {isOpened === true && (
                        <Text>
                          <Text>{msg}</Text>
                        </Text>
                      )}
                       <FormButton1 onClick={() => validateTx()} transparent>
                      I Already Signed Transaction
                    </FormButton1>
                    <FormButton1 transparent={false} onClick={refreshPage}>Reload</FormButton1>
                    <CustomButton onClick={onCancel}>Cancel</CustomButton>
                    </Form>
                  </FormContent>
                </FormWrap>
                </>
                }{errMsg && 
              
                <FormWrap>
                  <FormContent>
                    <Form>
                      <Text>{errMsg}</Text>
                      {!isSigned && <FormButton1 transparent={true} onClick={onCancel}>Go Back</FormButton1>}
                      {isSigned && <FormButton transparent={false} to='/'>Go Home</FormButton> }
                    </Form>
                  </FormContent>
                </FormWrap>
            }
            {!errMsg && !isLoaded && <ModalLoadingScreen text={"Loading. Please Wait..."} isLoaded={isLoaded} transparent={true} />}
    
          </>
        
           
          
        )
}

export default TrustlineCreate