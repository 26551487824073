import React, { useContext } from "react";
import { useState } from "react";
import Modal from ".";

export const ModalContext = React.createContext();

export function useModal() {
    return useContext(ModalContext);
  }

export const ModalProvider = ({children}) => {
    const [content, setContent] = useState()
    const [isOpen, setOpen] = useState(false)
    const [closeButton, setCloseButton] = useState(true)
    

    const toggleModal = () => {
        if(isOpen){
          setOpen(false) 
          setContent() 
        }else{
            setOpen(true)  
        }
    
        
    }

    const openModal = () => {
        if(!isOpen){
            setOpen(true)
            setContent() 
          }
    }

    const closeModal = () => {
        if(isOpen){
            setOpen(false)
            setContent() 
          }
    }

    const modalContent = (val) => {
        setContent(val)
    }

    const noCloseModal = (val) => {
        setCloseButton(false)
        setContent(val)
    }

    const value = {
        isOpen,
        content,
        noCloseModal,
        setCloseButton,
        closeModal,
        openModal,
        modalContent,
        toggleModal
    }


    return (
        <>
        <ModalContext.Provider value={value}>
 
        {isOpen && content && <Modal isOpen={isOpen} onRequestClose={closeButton ? closeModal : false}>
                {content}
            </Modal>}
            
            {children}
        </ModalContext.Provider>
        </>
    )

}