import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import { MusicStore as MStore } from '../../components/MusicStore'
import Navbar from '../../components/Navbar'
import { Home, Welcome } from '../../components/Navbar/Data';
import Sidebar from '../../components/Sidebar';
import { useAuth } from '../../contexts/AuthContext';

export const MusicStore = () => {
  const {currentUser} = useAuth()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}
    const navigate  = useNavigate()
    
  return (
    <>
     
    <Navbar toggle={toggle}  navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>

      <Sidebar
            isOpen={isOpen}
            toggle={toggle}
            navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
          />
      <MStore/>
    
    </>
  )
}
