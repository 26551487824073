import styled from "styled-components";
import { hexToRgba, lightOrDark } from "../HexToRGBA";
import { CustomButton } from "../ButtonElement";

export const SettingsWrapper = styled.div`
display: flex;
width: 100%;
height: 100%;
flex-direction: row;
justify-content: flex-start;
align-items: center;
align-content: space-around;
padding: 2% 2%;
`


export const InputLabel = styled.label`
  display: flex;
  margin-right: 25%;
  flex: 1 1 50%;
  padding: 1% 2%;
  flex-wrap: nowrap;
  color: ${(props) => props.theme.secondaryBg};
  font-size: 0.8rem;
`;

export const Input = styled.input`
  display: flex;
  flex: 1 1 100%;
  padding: 1%;
  margin: 1% 2%;;
  border: none;
  background: ${props => hexToRgba(props.theme.secondaryBg,0.6)};
  border-radius: 4px;
`;

export const BioInput = styled.textarea`
  display: flex;
  padding: 10px 16px;
  width: 90%;
  height: 100%;
  resize: vertical;
  margin: 1% 2%;;
  border: none;
  border-radius: 4px;
`;

export const ErrorText = styled.small`
    display: flex;
    padding: 2%;
    color: ${props =>props.theme.accentColor};
`

export const ActionButton = styled(CustomButton)`
    display: flex;
    font-size: 0.85em;
    padding: 2% 5%;
    margin: 0px 3%;
    background-color:  ${props => props.active? (props.background?.color ? props.background.color : props.theme.mainColor) : props.theme.mainColor };
    color: ${props => props.active? (props.background?.color ? (lightOrDark(props.background.color) === 'light' ? 'black' : 'white') :lightOrDark(props.theme.mainColor) === 'light' ? 'black' : 'white') : props.theme.secondaryBg };
    justify-content: space-between;
    outline: ${(props) => (props.background?.color ? `1px solid ${props.background.color}` : `1px solid ${props.theme.mainColor}` )};

    &:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.1);
        background-color:  ${props => (props.background?.color ? props.background.color : props.theme.mainColor)};
        color: ${(props) => (props?.background?.color ? (lightOrDark(props.background.color) === 'light' ? 'black' : 'white') : lightOrDark(props.theme.mainColor) === 'light' ? 'black' : 'white')};
        text-decoration: none;
    }
    @media screen and (max-width: 960px) {
      font-size: 0.65em;
    }
`