import { FaqLink, FaqLink1 } from "./FrequentlyAskedQuestionsElements";
import smPdf2 from "../../files/Sonar_Muse_Onboarding_Flow.pdf";
import { downloadFile } from "../../utils";


export const Questions = [
  {
    question: "How do I access the Streaming Platform?",
    answer: (
     <> <p>
        Sonar Muse uses web monetization as a revenue model. 
        You will be required to pay a $5 subscription fee in order to stream music uninterrupted with our new service,Swərl.
        Swərl will be streaming micropayments to artists as you stream their
        music in realtime. Visit{" "}
        <FaqLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://promo.sonarmuse.org"
        >
          Swərl
        </FaqLink>{" "}
        for more info. If you would like to stream music without subscribing, purchasing music in the <FaqLink1 to='/store'>music store</FaqLink1> allows you to download and listen to the music on sale before purchase
      </p>
      <br/>
      <p>
        You can buy a Swərl Voucher by visiting the <FaqLink1 to={'/store'}>store</FaqLink1>
      </p></>
    ),
  },
  {
    question: "What is Web Monetization?",
    answer: (
      <>
        <p>
          Website monetization is the process of creating revenue on a website
          through the current traffic it has brought. Webmasters are most
          commonly used, by advertisers for placing advertisements on webpages
          to receive rewards from the traffic a website is experiencing. Pay per
          click (PPC) and cost per impression (CPI/CPM) are usually implemented
          by websites in order to advertise and monetize the current traffic.
        </p>
        <br />
        <p>
          Web Monetization allows the creation of a payment stream from a user
          to the website through an API that uses the Interledger Protocol. It
          is secure, easy to set up and easy to use. Web Monetization is one of
          the solutions that people are working on to help creatives earn from
          the amount of time visitors consume their content. This will in turn
          allow for the web to be more fair for consumers and content
          creators.It can be enabled for users to send/streaming micropayments
          through an extension or pollyfill . Web monetization can be
          implemented, by content creators, by adding a single line of code to
          their website or specifying a streaming channel for web monetization.
          click{" "}
          <FaqLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://webmonetization.org"
          >
            here
          </FaqLink>{" "}
          to learn more about Web Monetization
        </p>
      </>
    ),
  },
  {
    question:
      "What is the difference between the NFT music store and an NFT Marketplace?",
    answer: (
      <p>
        A marketplace focuses on all sales of an NFT, whereas Sonar Muse will
        only be focused on the initial sale of Music NFTokens on our platform.
        Our Music store will not keep track of any secondary sales of music
        NFTs, nor do we allow for bids/offers to purchase NFTs on our platform
        unlike how marketplaces initiate NFT sales - NFT collections will be
        sold at a fixed price, set by the artist on the platform. The music
        store will be available for the public to access and listen to the music
        before they decide to purchase any music that is on sale on our
        platform.
      </p>
    ),
  },
  {
    question: "What is the Wymbo Token?",
    answer: (
      <>
        <p>
          Wymbo (WYM) is the native token that will be used in the Sonar Muse
          ecosystem, in order to purchase NFTs, tipping users and artists and
          subscribing to individual artists platform. The token will be used to
          reward users for tasks completed on the platform, and will be used by
          artists to pay for services on the music platform to help them receive
          more engagement for their music.
        </p>
        <br />
        <p>
          Artists will be paid in Wymbo but can choose to convert it to the
          token of their choice on the XRPL based on the current market value of
          the native token. As we progress with the platform, the use-cases for
          the Wymbo token will progress with it.
        </p>
      </>
    ),
  },
  {
    question: "How do I qualify for the airdrop?",
    answer: (
      <>
        <p>
          Visit the{" "}
          <FaqLink
            target="_blank"
            rel="noopener noreferrer"
            href="/TDRequirements"
          >
            qualification page
          </FaqLink>{" "}
          to learn more about qualifying.
        </p>
      </>
    ),
  },
  {
    question: "When is the airdrop?",
    answer: (
      <>
        <p>
         We have not set a snapshot date nor have we set an airdrop date. TBA
        </p>
      </>
    ),
  },
  {
    question: "How do I sign up for the platform?",
    answer: (
      <>
        <p>1. You will need to have a non-custodial XRP wallet.</p>
        <p>
          {" "}
          2. You will need an email hash assigned to your XRP wallet address
          trhough your account settings. You can set an email hash using our{" "}
          <FaqLink
            target="_blank"
            rel="noopener noreferrer"
            href="/setEmailHash"
          >
            Set Email Hash
          </FaqLink>{" "}
          page.{" "}
        </p>
        <p>
          {" "}
          3. To have a customizable profile on our website, as an optional step,
          you can create a{" "}
          <FaqLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://en.gravatar.com"
          >
            Gravatar
          </FaqLink>{" "}
          account.{" "}
        </p>
        <br/>
        <p>
          You can learn more about our onboaring process <FaqLink
            target="_blank"
            onClick={() => downloadFile(smPdf2)}
            rel="noopener noreferrer"
            href="#"
          >
            here
          </FaqLink>{" "}
        </p>
      </>
    ),
  },
  {
    question: "Do I need to go through KYC to join the platform?",
    answer: (
      <>
        <p>
          No. Music listeners will not have to go through KYC to enjoy the
          platform.
        </p>
        <br />
        <p>
          In order for artists to earn money through our streaming service, we
          will require them to go through KYC and a verification process, to
          ensure that they are the artist they say they are and the music
          submitted is theirs - the is to ensure the integrity of the platform
          and the quality of the music uploaded. Similar to major streaming
          platform - artists will need to verify that they are the creators of
          the music they intend to upload.
        </p>
      </>
    ),
  },
  {
    question: "What is Gravatar?",
    answer: (
      <>
        <p>
          Gravatar (Globally Recognized Avatar) is a service for providing
          globally unique avatars, linked to the WordPress website. Your email
          address is what links your Gravatar account and you can choose what to
          display on Gravatar-enabled sites: what you share on your profile will
          be public to the world. Gravatar can display links, photos, contact
          info, wallet addresses, and other details, so telling the web who you
          are and where people can find you can be done instantly.
        </p>
        <br />
        <p>
          <b>Why do we use Gravatar?</b>
        </p>
        <p>
          This is the best way to ensure that we don't store user's information
          on our platform, to ensure that they have control of what information
          they want public. It is also used on the XRP Ledger and it's the best
          way to keep information decentralized and dynamic. This is how users
          and artists will be able to customize their page and the theme of
          their account.
        </p>
      </>
    ),
  },
  {
    question: "What is a payment pointer?",
    answer: (
      <>
        <p>
          Payment Pointers are a standardized identifier for payment accounts. A
          payment pointer can be used by an account holder to share the details
          of their account with a counter-party similar to the way that an email
          address provides an identifier for a mailbox in the email ecosystem.
        </p>
        <br />
        <p>
          When a user wishes to make or receive a payment, and is engaging with
          a counter-party that is able to process Payment Pointers, it provides
          a Payment Pointer to the counter-party.A Payment Pointer resolves to a
          URL (with the https scheme) that can be used to discover the Open
          Payments endpoints for interacting with the account.
        </p>
      </>
    ),
  },
  
];
