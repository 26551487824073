import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components'
import Video from '../../videos/video.mp4'
import { Button } from '../ButtonElement'
import { Fade, Zoom } from "react-reveal"
import {
    HeroContainer,
    HeroBg,
    VideoBg,
    HeroContent,
    HeroP,
    HeroH1,
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight
} from './HeroElements'

const HeroSection = () => {
    const [hover, setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }

    return (
        <HeroContainer>
            <HeroBg style={{opacity: "0.5"}}>
                <VideoBg autoPlay loop muted src={Video}
                    type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}/>
            </HeroBg>
            <HeroContent isPage={true}>
                
                <Fade> <HeroH1>The First NFT Music Store and Streaming Platform for Musicians and Music Lovers. </HeroH1>
                <HeroH1>Built on The XRPL for The World.</HeroH1></Fade>
                <Zoom><HeroP>
                    Sign up for a new account to claim
                    your Wymbo (WYM) airdrop before the snapshot date
                </HeroP></Zoom>
                <HeroBtnWrapper>
                    <Button to='/access/signup'
                        primary='true'
                        dark='true'
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        smooth={true}
                        exact='true' 
                        >
                        Get started {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )

}

export default HeroSection