import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WelcomePage from "./pages/welcome";
import Home from "./pages/home";
//import { SignIn, SignUp } from './pages/access'
import {
  ArtistAuthorizationRoute,
  LogAuthorizationRoute,
  PodcasterAuthorizationRoute,
} from "./components/Authorization";
import { AuthProvider } from "./contexts/AuthContext";
import PageNotFound from "./pages/PageNotFound";
import Login from "./components/SignUp/Login";
import Registration from "./components/SignUp/Registration";
import UserProfile from "./pages/Profiles/userProfile";
import { AudioProvider, PreviewProvider } from "./components/AudioPlayer/context/AudioContext";
import ArtistProfile from "./pages/Profiles/artistProfile";
import Search from "./pages/search";
import Single from "./pages/musicPages/Single";
import Album from "./pages/musicPages/Album";
import Playlist from "./pages/musicPages/Playlist";
import AlbumUploads from "./pages/musicUploads/albumUploads";
import SongUploads from "./pages/musicUploads/songUploads";
import { MusicStore } from "./pages/musicStore";
import { ModalProvider } from "./components/Modal/ModalContext";
import Collection from "./pages/musicPages/Collection";
import NFToken from "./pages/musicPages/NFToken";
import CollectionCreator from "./pages/NFTokenPages/collectionCreator";
import SidebarNav from "./components/SidebarNav";
import NotificationProvider from "./components/Notifications/NotificationContext";
import AlertProvider from "./components/Alert/AlertContext";
import RecordLabelProfile from "./pages/Profiles/recordLabelProfile";
import PodcasterProfile from "./pages/Profiles/podcasterProfile";
import EmailAccountSet from "./components/EmailAccountSet";
import SetNFTokenMinter from "./components/SetNFTokenMinter";
import AirdropRequirements from "./pages/airdropRequirements";
import EligibilityChecker from "./components/EligibilityChecker";
import ContactUsForm from "./components/ContactUsForm";
import FrequentlyAskedQuestions from "./components/FrequentlyAskedQuestions";
import Genre from "./pages/musicPages/Genre";
import Subgenre from "./pages/musicPages/Subgenre";
import DraftUploads from "./components/MusicUploadsForm/DraftUploads";
import Artists from "./components/ViewAllContent/Artists";
import MusicDownloads from "./pages/musicDownloads";
import WebsiteThemeProvider from "./components/WebsiteTheme/WebsiteThemeContext";
import ArtistRegistrationPage from "./pages/registrationPages/ArtistRegistrationPage";
import LatestAlbumsPage from "./pages/viewContentPages/latestAlbums";
import DownloadableSongsPage from "./pages/viewContentPages/downloadableSongs";
import DownloadableAlbumsPage from "./pages/viewContentPages/downloadableAlbums";
import LatestSongsPage from "./pages/viewContentPages/latestSongs";
import EpisodeUploads from "./pages/musicUploads/episodeUploads";
import Episode from "./pages/musicPages/Episode";
import SupportedTokens from "./components/SupportedTokens";
import MarketingCampaign from "./pages/marketing";
import PodcasterRegistrationPage from "./pages/registrationPages/PodcasterRegistrationPage";
import RecordLabelRegistrationPage from "./pages/registrationPages/RecordLabelRegistrationPage";
import RegistrationForm from "./pages/registrationPages/RegistrationForm";
import Footer from "./components/Footer";
import NFTWhitelister from "./pages/NFTokenPages/nftokenWhitelister";
import LatestPlaylistsPage from "./pages/viewContentPages/latestPlaylists";
import TrendingPlaylistsPage from "./pages/viewContentPages/trendingPlaylists";
import LatestEpisodesPage from "./pages/viewContentPages/latestEpisodes";
import CollectionsPage from "./pages/viewContentPages/collections";
import UserPlaylistsPage from "./pages/viewContentPages/userPlaylists";
import UserCollabPlaylistsPage from "./pages/viewContentPages/collabPlaylists";
import SimilarAlbumsPage from "./pages/viewContentPages/similarAlbums";
import SimilarSongsPage from "./pages/viewContentPages/similarSongs";
import SimilarCollectionsPage from "./pages/viewContentPages/similarCollections";
import ArtistCollectionsPage from "./pages/viewContentPages/artistCollections";
import MusicVideosPage from "./pages/viewContentPages/musicVideos";
import ArtistMusicVideosPage from "./pages/viewContentPages/artistMusicVideos";
import ArtistSinglesPage from "./pages/viewContentPages/artistSingles";
import ArtistSongsPage from "./pages/viewContentPages/artistSongs";
import ArtistAlbumsPage from "./pages/viewContentPages/artistAlbums";
import ArtistAppearencesPage from "./pages/viewContentPages/artistAppearences";
import RoyaltyShares from "./pages/musicPages/RoyaltyShares";
import LatestRoyaltiesPage from "./pages/viewContentPages/latestRoyalties";
import ArtistRoyaltiesPage from "./pages/viewContentPages/artistRoyalties";
import SimilarRoyaltiesPage from "./pages/viewContentPages/similarRoyalties";
import SwerlStorePage from "./pages/swerl";
import NetworkModal from "./components/NetworkModal.js";
import Video from "./pages/musicPages/Video.js";

//import DownloadableAlbums from './components/ViewAllContent/downloadableAlbums'

function App() {
  return (
    <Router>
      
      <AuthProvider>
        <WebsiteThemeProvider>
          <NetworkModal>
          <AlertProvider>
            <NotificationProvider> 
            <ModalProvider>
              <AudioProvider>
              <PreviewProvider>
              <ModalProvider>
                  <SidebarNav>
                    <Routes>
                      <Route exact path="/search" element={<Search />} />
                      <Route
                        exact
                        path="/setEmailHash"
                        element={<EmailAccountSet />}
                      />
                      <Route
                        exact
                        path="/setNFTMinter"
                        element={<SetNFTokenMinter />}
                      />
                      <Route
                        exact
                        path="/TDRequirements"
                        element={<AirdropRequirements />}
                      />
                      <Route
                        exact
                        path="/contactUs"
                        element={<ContactUsForm />}
                      />
                      <Route
                        exact
                        path="/eligibilityChecker"
                        element={<EligibilityChecker />}
                      />
                      <Route
                        exact
                        path="/createTL"
                        element={<SupportedTokens />}
                      />
                      <Route
                        exact
                        path="/access/signup"
                        element={<Registration />}
                      />
                      <Route exact path="/access/signin" element={<Login />} />
                      <Route exact path="/charts" element={<Login />} />
                      <Route exact path="/genre/:genreId" element={<Genre />} />
                      <Route
                        exact
                        path="/subgenre/:subgenreId"
                        element={<Subgenre />}
                      />
                      <Route exact path="/charts" element={<Login />} />
                      <Route exact path="/" element={<WelcomePage />} />
                      <Route element={<LogAuthorizationRoute />}>
                        <Route exact path="/home" element={<Home />} />
                        <Route exact path={"/marketing"} element={<MarketingCampaign/>}/>
                      </Route>
                      {/*         <Route path='/about' exact element={About} />
                       */}{" "}
                      <Route
                        path="/faq"
                        exact
                        element={<FrequentlyAskedQuestions />}
                      />
                      <Route exact path="/video/:videoId" element={<Video />} />
                      {/* <Route exact path="/video/:videoId/similarSongs" element={<SimilarVideosPage />} /> */}
                      <Route exact path="/song/:songId" element={<Single />} />
                      <Route exact path="/song/:songId/similarSongs" element={<SimilarSongsPage />} />
                      <Route
                        exact
                        path="/collection/:collectionId"
                        element={<Collection />}
                      />
                      <Route
                        exact
                        path="/collection/:type/similarCollections"
                        element={<SimilarCollectionsPage />}
                      />
                      <Route
                        exact
                        path="/nftoken/:tokenId"
                        element={<NFToken />}
                      />
                      <Route exact path="/album/:albumId" element={<Album />} />
                      <Route exact path="/album/:albumId/similarAlbums" element={<SimilarAlbumsPage />} />
                      <Route
                        exact
                        path="/episode/:episodeId"
                        element={<Episode />}
                      />
                      <Route
                        exact
                        path="/playlist/:playlistId"
                        element={<Playlist />}
                      />
                      <Route
                        exact
                        path="/regium/:royaltyId"
                        element={<RoyaltyShares />}
                      />
                      <Route
                        exact
                        path="/regium/:type/SimilarRoyalties"
                        element={<SimilarRoyaltiesPage/>}
                      />

                      <Route
                        exact
                        path="/latestAlbums"
                        element={<LatestAlbumsPage />}
                      />
                      <Route
                        exact
                        path="/latestPlaylists"
                        element={<LatestPlaylistsPage />}
                      />
                      <Route
                        exact
                        path="/trendingPlaylists"
                        element={<TrendingPlaylistsPage />}
                      />
                      <Route
                        exact
                        path="/latestEpisodes"
                        element={<LatestEpisodesPage />}
                      />
                      <Route
                        exact
                        path="/regiums"
                        element={<LatestRoyaltiesPage />}
                      />
                      <Route
                        exact
                        path="/collections"
                        element={<CollectionsPage />}
                      />
                      <Route
                        exact
                        path="/downloadableSongs"
                        element={<DownloadableSongsPage />}
                      />
                      <Route
                        exact
                        path="/musicVideos"
                        element={<MusicVideosPage />}
                      />
                      <Route
                        exact
                        path="/:type/:id/download"
                        element={<MusicDownloads />}
                      />
                      <Route
                        exact
                        path="/musicDownloader"
                        element={<MusicDownloads />}
                      />
                      <Route
                        exact
                        path="/forArtists"
                        element={<RegistrationForm />}
                      />
                      <Route
                        exact
                        path="/artists/registration"
                        element={<RegistrationForm />}
                      />
                      <Route
                        exact
                        path="/podcasters/registration"
                        element={<RegistrationForm />}
                      />
                      <Route
                        exact
                        path="/labels/registration"
                        element={<RegistrationForm />}
                      />
                      <Route
                        exact
                        path="/downloadableAlbums"
                        element={<DownloadableAlbumsPage />}
                      />
                      <Route
                        exact
                        path="/latestSongs"
                        element={<LatestSongsPage />}
                      />
                      
                        
                      <Route exact path="/swerl" element={<SwerlStorePage />}/>
                      <Route exact path="/store" element={<MusicStore />} />
                      <Route exact path="/u/:userId" element={<UserProfile />} />
                      <Route exact path="/u/:userId/playlists" element={<UserPlaylistsPage/>}/>
                      <Route exact path="/u/:userId/collabPlaylists" element={<UserCollabPlaylistsPage/>}/>
                      <Route exact path="/artists" element={<Artists />} />
                      {/* <ArtistAuthorizationRoute
                        exact
                        path="/edit/nftokenAirdropper"
                        element={<NFTAirdropper/>}
                      /> */}
                      <Route element={<ArtistAuthorizationRoute/>}>
                      <Route
                        exact
                        path="/edit/album"
                        element={<AlbumUploads />}
                      />
                      
                      {/*<Route exact path='/edit/podcast' element={AlbumUploads}/> */}
                      <Route
                        exact
                        path="/edit/song"
                        element={<SongUploads />}
                      />
                      <Route
                        exact
                        path="/edit/collection"
                        element={<CollectionCreator />}
                      />
                      <Route
                        exact
                        path="/edit/collectionWhitelister"
                        element={<NFTWhitelister />}
                      />
                      
                      </Route>
                      <Route
                        exact
                        path="/edit/drafts"
                        element={<DraftUploads />}
                      />
                      <Route element={<PodcasterAuthorizationRoute/>}>
                      
                        <Route
                        exact
                        path="/edit/episode"
                        element={<EpisodeUploads />}
                      />
                      </Route>
                      <Route
                        exact
                        path="/artist/:artist"
                        element={<ArtistProfile />}
                      />
                      <Route exact path={"/artist/:artistId/songs"} element={<ArtistSongsPage/>}/>
                      <Route exact path={"/artist/:artistId/singles"} element={<ArtistSinglesPage/>}/>
                      <Route exact path={"/artist/:artistId/albums"} element={<ArtistAlbumsPage/>}/>
                      <Route exact path={"/artist/:userId/collections"} element={<ArtistCollectionsPage/>}/>
                      <Route exact path={"/artist/:userId/regiums"} element={<ArtistRoyaltiesPage/>}/>
                      <Route exact path={"/artist/:artistId/appearences"} element={<ArtistAppearencesPage/>}/>
                      <Route exact path={"/artist/:artistId/musicVideos"} element={<ArtistMusicVideosPage/>}/>
                      <Route
                        exact
                        path="/label/:recordLabel"
                        element={<RecordLabelProfile />}
                      />
                      <Route
                        exact
                        path="/podcaster/:podcaster"
                        element={<PodcasterProfile />}
                      />
                      <Route path="/access:signin" exact element={<Login />} />
                      <Route path={"*"} element={<PageNotFound />} />
                    </Routes>
                    <Footer/>
                  </SidebarNav>
                  </ModalProvider>
                  </PreviewProvider>
              </AudioProvider>
              </ModalProvider>
            </NotificationProvider>
          </AlertProvider>
          </NetworkModal>
        </WebsiteThemeProvider>
      </AuthProvider>
      
    </Router>
  );
}

export default App;
