import styled from "styled-components"
import { hexToRgba } from "../HexToRGBA"
import { Link } from "react-router-dom"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2% 5%;
    justify-content: space-evenly;

`

export const Title = styled.h1`
    display: flex;
    font-size: 2.8rem;
    font-weight: 700;
    width: 100%;
    padding: 2%;
    justify-content: center;
    text-align: center;
    color: ${props => props.theme.secondaryBg};
    margin-top: 2%;
    margin-bottom: 2%;

`


export const AccordianContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;

`
export const AccordianHeader = styled.div`
    display: flex;
    border-radius: ${props => props.isOpen ? ' 12px  12px 0 0' : '12px'};;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 2%;
    width:100%;
    background: ${props => hexToRgba(props.theme.secondaryColor, 0.5)};
    color: ${props => props.theme.secondaryBg};
    cursor: pointer;

`
export const AccordianBody = styled.div`
    display: ${props => props.isOpen ? 'flex' : 'none'};
    background: ${props => hexToRgba(props.theme.secondaryColor, 0.2)};
    color: ${props => props.theme.secondaryBg};
    width:100%;
    flex-direction: column;
    padding: 2%;
    min-height: 10%;
    text-align: left;
    border-radius: 0 0 12px  12px;


`

export const FaqLink = styled.a`
    color: ${props => props.theme.accentColor}

`
export const FaqLink1 = styled(Link)`
    color: ${props => props.theme.accentColor}

`