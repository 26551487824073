import React, { useEffect, useState } from "react";
import { SLIDER_TYPES } from "../../../utils/Enum";
import ContentWrapper from "../../ContentWrapper";
import { Slider } from "../../Slider";
import ScrollToTop from "../../ScrollToTop";
import MusicBanner from "../../MusicBanner";
import { CardText, CardTitle } from "../../Card/CardElements";
import { PreviewBadge } from "../../AudioPlayer1/AudioPlayerElements";
import { Small, TextRoute } from "../../QRCodeForm/QrCodeFormElements";
import { timestampToDate } from "../../../utils";
import { MusicAboutWrapper } from "../../MusicPage/MusicPageElements";

export default function Discography({
  artist,
  popular,
  albums,
  singles,
  videos,
  features,
  isLoaded,
  collections,
  royaltyTokens,
  getDiscography,
  discography
}) {
  const {goTo} = ScrollToTop()
  const [showAllSingles, setShowAllSingles] = useState(false);
  const [showAllAlbums, setShowAllAlbums] = useState(false);
  const [showAllFeatured, setShowAllFeatured] = useState(false);
  const [isSinglesLoaded, setSinglesLoaded] = useState(true);
  const [isAlbumsLoaded, setAlbumsLoaded] = useState(true);
  const [isFeaturedLoaded, setFeaturedLoaded] = useState(true);
 const [artistAlbums, setAlbums] = useState(albums)
  const [artistSingles, setSingles] = useState(singles)
  const [artistPopular, setPolular] = useState(popular)
  const [artistFeatures, setFeatures] = useState(features) 
  const [latestPreRelease, setLatestPreRelease] = useState({})
  const [preReleases, setPreReleases] = useState([]) 
  useEffect(()=> {
    if(isLoaded){
      let s = singles ?  singles?.filter(s => s.isPreRelease)?.length > 0 ? singles?.filter(s => s.isPreRelease) : [] : []
      let a = albums ? albums?.filter(s => s.isPreRelease)?.length > 0 ? albums?.filter(s => s.isPreRelease) : [] : []
     setPreReleases([...s, ...a]) 
     setLatestPreRelease([...s, ...a].sort((a, b) => b?.displayDate > a?.displayDate ? 1 : a?.displayDate > b?.displayDate ? -1 : 0 )[0])
    }

    return () => {} 
  }, [isLoaded])

  return (
    <>
      {!isLoaded && (
        <>
          <ContentWrapper>
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
              id={"discography loader"}
            />
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
              id={"discography loader"}
            />
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
              id={"discography loader"}
            />
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
              id={"discography loader"}
            />
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
              id={"discography loader"}
            />
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
              id={"discography loader"}
            />
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
              id={"discography loader"}
            />
          </ContentWrapper>
        </>
      )}
      {isLoaded && (
        <>
        {preReleases?.length > 0 && <>
          <ContentWrapper><MusicBanner isImg img={latestPreRelease?.albumArt}
          text={<>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
          <Small>Pre Release</Small>
          <CardTitle>{latestPreRelease?.albumName ? latestPreRelease?.albumName : latestPreRelease?.songName}</CardTitle>
          <CardText>{`${new Date(timestampToDate(latestPreRelease?.releaseDate)).toLocaleString('default', { month: 'long' })} ${new Date(timestampToDate(latestPreRelease?.releaseDate)).getDate()}, ${new Date(timestampToDate(latestPreRelease?.releaseDate)).getFullYear()}`}</CardText>
          <MusicAboutWrapper><span>Preview {latestPreRelease?.albumName ? latestPreRelease?.albumName : latestPreRelease?.songName} <TextRoute to={`/${latestPreRelease?.albumName ? 'album' : 'song'}/${latestPreRelease?.albumName ? latestPreRelease?.albumId : latestPreRelease?.songId}`}>here</TextRoute> before the release date</span></MusicAboutWrapper>
          </div></>}
          onClick={() => goTo(`/${latestPreRelease?.albumName ? 'album' : 'song'}/${latestPreRelease?.albumName ? latestPreRelease?.albumId : latestPreRelease?.songId}`)}
          />
        </ContentWrapper>
        </>}
          {popular?.length > 0 && (
            <ContentWrapper heading={"Popular Releases"}>
              <Slider
                type={SLIDER_TYPES.MISCELLANEOUS_MUSIC}
                slides={popular}
                id={"popular songs"}
              />
            </ContentWrapper>
          )}
          {collections?.length > 0 && (
            <ContentWrapper heading={"Music NFT Collections"}>
              <Slider
                type={SLIDER_TYPES.COLLECTION}
                slides={collections}
                id={"collections"}
              />
            </ContentWrapper>
          )}

          {royaltyTokens?.length > 0 && (
            <ContentWrapper heading={"Regium Share Tokens"}>
              <Slider
                type={SLIDER_TYPES.MUSIC_ROYALTY_SHARE}
                slides={royaltyTokens}
                id={"regium shares"}
              />
            </ContentWrapper>
          )}

          {albums?.length > 0 && (
            <ContentWrapper
              heading={"Albums"}
              subheading={discography?.albums?.length > 10 && (showAllAlbums ? "Hide" : "View All")}
              onClick={() => discography?.albums?.length > 10 && 
                goTo(`/artist/${artist}/albums`)
              }
            >
              <Slider
                type={SLIDER_TYPES.ALBUM}
                slides={
                    albums
                }
                id={"albums"}
              />
              {!isAlbumsLoaded && (
                <>
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                </>
              )}
            </ContentWrapper>
          )}

          {singles?.length > 0 && (
            <ContentWrapper
              heading={"Singles"}
              subheading={discography?.songs?.length > 10 && (showAllSingles ? "Hide" : "View All")}
              onClick={() => discography?.songs?.length > 10 && goTo(`/artist/${artist}/singles`)}
            >
              <Slider
                type={SLIDER_TYPES.MISCELLANEOUS_MUSIC}
                slides={singles}
                heightScroll={showAllSingles}
                id={"singles"}
              />
              {!isSinglesLoaded && (
                <>
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                </>
              )}
            </ContentWrapper>
          )}

          {features?.length > 0 && (
            <ContentWrapper
              heading={"Features & Appearences"}
              subheading={features?.length > 10 && (showAllFeatured ? "Hide" : "View All")}
              onClick={() => features?.length > 10 && goTo(`/artist/${artist}/appearences`)}
            >
              <Slider
                type={SLIDER_TYPES.SONG}
                slides={features}
                heightScroll={showAllFeatured}
                id={"features and appearences"}
              />
              {!isFeaturedLoaded && (
                <>
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                </>
              )}
            </ContentWrapper>
          )}
          {videos?.length > 0 && (
            <ContentWrapper
              heading={"Music Videos"}
              /* subheading={videos?.length > 10 && (showAllFeatured ? "Hide" : "View All")} */
              onClick={() => {/* videos?.length > 10 && goTo(`/artist/${artist}/videos`) */}}
            >
              <Slider
                type={SLIDER_TYPES.VIDEO}
                slides={videos}
                id={"videos"}
              />
              {!isFeaturedLoaded && (
                <>
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />

                </>
              )}
            </ContentWrapper>
          )}
        </>
      )}
    </>
  );
}
