import React from 'react'
import { useState } from 'react';
import { ALERT_TYPES } from '../../../utils/Enum';
import ImageUtils from '../../../utils/ImageUtils';
import { useAlert } from '../../Alert/AlertContext';
import { getRandomColor } from '../../HexToRGBA';
import ScrollToTop from '../../ScrollToTop';
import { RecordLabelCardBody, RecordLabelCardImageWrapper, RecordLabelCardImg, RecordLabelCardTitle, RecordLabelCardWrapper } from './RecordLabelCardElements'

const RecordLabelCard = ({content}) => {
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const {goTo} = ScrollToTop()
  const [color, setColor] = useState(getRandomColor())
  const { addAlert } = useAlert();


  return (
    <RecordLabelCardWrapper>
      <RecordLabelCardImageWrapper onClick={() => {goTo(`/label/${content?.labelId}`)}}>
          <RecordLabelCardImg src={content?.labelSettings?.profilePictureType === "custom" && content?.profilePicture ?
              content?.profilePicture : content?.gravatarProfile?.avatar_url ? content?.gravatarProfile?.avatar_url + "?s=300&d=mp" : defaultAlbumArt}/>
      </RecordLabelCardImageWrapper>
      <RecordLabelCardBody>
        <RecordLabelCardTitle onClick={() => {goTo(`/label/${content?.labelId}`)}}>{content?.labelName}</RecordLabelCardTitle>
        Label
      </RecordLabelCardBody>
    </RecordLabelCardWrapper>
  )
}

export default RecordLabelCard