import styled from 'styled-components'
import { Fade, Zoom } from "react-reveal"
import { hexToRgba } from '../HexToRGBA'

export const InfoContainer = styled.div`
    color: ${props => props.theme.mainColor};
    overflow: hidden;
    background: ${ props => (props.lightBg ? hexToRgba(props.theme.secondaryBg, 0.9) : hexToRgba(props.theme.mainBg, 0.9))};
    @media screen and (max-width: 768px) {
        padding: 100px 0;
        
    }

`

export const InfoWrapper = styled.div`
display: grid;
z-index: 1;
height: 860px;
width: 100%;
max-width: 1100px;
margin-left: auto;
margin-right: auto;
padding: 0 1%;
justify-content: center;

`

export const InfoRow = styled.div`
display: grid;
grid-auto-column: minmax(auto,1fr);
align-items: center;
justify-content: center;
grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};


@media screen and (max-width: 768px){
   grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};

}
`

export const Column1 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col1;


`
export const Column2 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col2;

`
export const TextWrapper = styled.div`
max-width: 540px;
padding-top:0;
padding-bottom: 60px;
`
export const TopLine = styled.p`
    color: ${props => props.theme.mainColor};
    font-size: 16px;
    truncate: true;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
    
    @media screen and (max-width: 480px) {
        font-size: 14px;
    }
`

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${(props) => (props.lightText ? props.theme.secondaryBg : props.theme.mainBg)};

    @media screen and (max-width: 480px){
        font: 32px;
    }

`

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom:35px;
    font-size: 18px;
    line-height: 24px;
    color: ${(props) => (props.darkText ? props.theme.mainBg : '#fff')};
`
export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`

export const Img = styled.img`
    width: 20em;
    margin: 0 0 10px 0;
    padding-right: 0;
    border-radius: ${isCircular => (isCircular ? '50%': 0)};
    @media screen and (max-width: 480px){
        width: 75%;
    }
`
export const SVG = styled.svg`
    display: block;
    xmlns: "http://www.w3.org/2000/svg";
`

export const SvgPath = styled.path`
fill: ${ props => (props.lightBg ? props.theme.secondaryBg : props.theme.mainBg)};
fill-opacity: 1;
d:"M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z";
`