import React from 'react'
import axiosInstance from "../components/Authorization/client";
import { ALERT_TYPES } from '../utils/Enum';
import { useAuth } from '../contexts/AuthContext';
import { useAlert } from '../components/Alert/AlertContext';
const axios = axiosInstance

const useRecordLabel = ({id}) => {
    const {currentUser} = useAuth()
    const {addAlert} = useAlert()
    
const getRecordLabelProfile = async(labelId) => {
    let labelProfile = await axios.get(`/api/recordLabels/${labelId}/getProfile`).then(async(label) => {
        if(label.data && !label.data.error){
            console.log(label.data)
            return label.data
            
        }
        return false
    }).catch(err => {
        return false
    })

    let isUserFollowing = await isFollowingRecordLabel(labelId ? labelId : id)
        .then((data) => {
            return data
        }).catch((err) => {
            console.error(err)
            return false
        })
    
    return {...labelProfile, isUserFollowing: isUserFollowing}
}


const getRecordLabels = () => {
   return axios.get(`/api/recordLabels/getRecordLabels`).then(labels => {
        if(labels.data && !labels.data.error){
            //console.log(labels.data)
            return labels.data
        }
        return false
    }).catch(err => {
        return false
    })
}

    const followRecordLabel = (labelId) =>{
        
        addAlert({type: ALERT_TYPES.INFO, title: 'Record Label Follow', secs: 10})

        return axios.post(`/api/${labelId ? labelId:id}/followLabel`, {uid: currentUser?.uid})
        .then((data) => {
            addAlert({type: ALERT_TYPES.SUCCESS, message: 'Record Label has been followed'})

            return data.data
        })
        .catch(err => {
            console.error(err)
            addAlert({type: ALERT_TYPES.DANGER, title: 'Follow Error', message: 'Oops, Something went wrong. Try again.'})

            return false
        })
    }

    const unfollowRecordLabel = async(labelId) => {
    addAlert({type: ALERT_TYPES.INFO, title: 'Record Label Unfollow', secs: 10})

        return axios.post(`/api/${labelId ? labelId:id}/unfollowLabel`, {uid: currentUser?.uid})
        .then((data) => {
            addAlert({type: ALERT_TYPES.SUCCESS, message: 'Record Label has been unfollowed'})

            return data.data
        })
        .catch(err => {
            addAlert({type: ALERT_TYPES.DANGER, title: 'Follow Error', message: 'Oops, Something went wrong. Try again.'})

            return false
        })
    }

    const isFollowingRecordLabel = async(labelId) => {

        return axios.get(`/api/${labelId ? labelId:id}/${currentUser?.uid}/isFollowingLabel`)
        .then((data) => {
            return data.data
        })
        .catch(err => {
            return false
        })
    }

    const getRecordLabelArtists = async(labelId) => {
        return axios.get(`/api/recordLabels/${labelId}/getArtists`).then(artists => {
            if(artists.data && !artists.data.error){
                //console.log(artists.data)
                return artists.data
            }
            return false
        }).catch(err => {
            return false
        })
    }

    const getRecordLabelDiscography = async(labelId) => {
        
        return await Promise.all([getRecordLabelSongs(labelId), getRecordLabelAlbums(labelId)]).then((discography) => {
            let discog = {}
            if(discography[0]?.length > 0){
                discog = {
                    ...discog,
                    songs: discography[0]
                }
            }
            if(discography[1]?.length > 0){
                discog = {
                    ...discog,
                    albums: discography[1]
                }
            }
           return discog
        })
    }

    const getRecordLabelSongs = async(labelId) => {
        return axios.get(`/api/recordLabels/${labelId}/getLabelSongs`).then(songs => {
            if(songs.data && !songs.data.error){
                //console.log(songs.data)
                return songs.data
            }
            return false
        }).catch(err => {
            return false
        })
    }

    const getRecordLabelAlbums = async(labelId) => {
        return axios.get(`/api/recordLabels/${labelId}/getLabelAlbums`).then(albums => {
            if(albums.data && !albums.data.error){
                //console.log(albums.data)
                return albums.data
            }
            return false
        }).catch(err => {
            return false
        })
    }


    const updateRecordLabelProfile = async(lId, profile, txInfo) => {
        return axios.post(`/api/${lId}/updateLabelProfile`, {profile: profile, txInfo})
        .then((data) => {
            if(data.data.error || data.data === false)
            {
                return false
            }
            return data.data
        })
        .catch(err => {
            return false
        })
    } 

    const enableRecordLabelWebMonetization = async(labelId, payload, txId) => {
        return await axios.post(`/api/${labelId}/enableRecordLabelWebMonetization`, {
            payload: payload,
            txId: txId
        }).then(result => {
            if(result.data && !result.data.error){
                return result.data
            }
            return false
        }).catch(err => {
            console.error(err)
            return false
        })
    }
    
    const getRecordLabelFunds = async(labelId, paymentChannel) => {
        return await axios.get(`/api/${labelId}/${paymentChannel}/getRecordLabelCurrentBalance`)
        .then((balance) => {
            if(balance.data && !balance.data.error){
                return balance.data
            }
        }).catch(err => {
            return false
        })
    }

  return {
    getRecordLabelProfile,
    getRecordLabels,
    getRecordLabelArtists,
    followRecordLabel,
    unfollowRecordLabel,
    getRecordLabelSongs,
    getRecordLabelAlbums,
    getRecordLabelDiscography,
    updateRecordLabelProfile,
    enableRecordLabelWebMonetization,
    getRecordLabelFunds
}


}

export default useRecordLabel
