import styled from 'styled-components'
import { hexToRgba } from '../../HexToRGBA'



export const Container = styled.div`
position: absolute;
left: 0px;
right: 0px;
top: 0px;
bottom: 655px;
height: 50vh;
background: ${props =>( props.background ? `linear-gradient(180deg, ${props.background} 0%, rgba(84, 120, 154, 0.6232) 100%)` : 'linear-gradient(180deg, rgba(179, 111, 247, 0.76) 0%, rgba(84, 120, 154, 0.6232) 100%)')};
box-sizing: border-box;
backdrop-filter: blur(4px);
/* Note: backdrop-filter has minimal browser support */
z-index: 1;
`

