import styled from 'styled-components'
import { hexToRgba } from '../../../HexToRGBA'


export const NFTokenInfoContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 3%;
    flex-direction: column;
    justify-content: center;

`

export const NFTokenInfoHeader = styled.div`
    width: 100%;
    padding: 8px 8px 8px 16px;
    font-weight: 700;
    justify-content: center;
    text-align: center;
    border-radius: 15px 15px 0px 0px;
    border-bottom: 0.25px solid ${props => props.theme.mainColor};
    color: ${props => props.theme.mainBg};
    background-color: ${props => props.color ? props.color : props.theme.accentColor};

`

export const NFTokenInfoContentContainer = styled.div`
    width: 100%;
    padding: 10px;
    border-radius: 0px 0px 20px 20px;
    background-color: ${props => props.color ? hexToRgba(props.color,0.4) : hexToRgba(props.theme.secondaryColor, 0.4)};
    color: ${props => props.theme.secondaryBg};
    border: 0.25px solid ${props => hexToRgba(props.theme.mainColor,0.3)};

`

export const NFTokenInfoContentWrapper = styled.div`
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
`

export const NFTokenInfoContent = styled.div`
    position: relative;
    flex: 1 1 50%;
    flex-direction: column;
    padding: 15px;
`

export const NFTokenInfoContentHeading = styled.div`
    font-size: 1.2rem;
    cursor: pointer;


`
export const NFTokenInfoContentText = styled.div`
    font-size: 0.8rem;

`