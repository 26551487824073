import React, { useEffect, useState } from 'react'
import useArtist from '../../hooks/useArtist'
import { SLIDER_TYPES } from '../../utils/Enum'
import ContentWrapper from '../ContentWrapper'
import { Slider } from '../Slider'

const Artists = () => {
    const {getArtists} = useArtist({})
    const [isLoaded, setLoaded] = useState(false)
    const [artists, setArtists] = useState({})

    useEffect(() => {
      getArtists("all").then(data => {
        if(data){
         setArtists(data)
         setLoaded(true)
        }
      }
       
      ).catch((err) => {
        setLoaded(true)
      })
      return () => {}
    }, [!isLoaded])
    
  return (
    <>
    {
        isLoaded && artists?.artists?.length > 0 && <ContentWrapper heading={"Artists"}>
            <Slider type={SLIDER_TYPES.ARTIST} heightScroll slides={artists?.artists} id={'allArtists'}/>
        </ContentWrapper>
/*         <PageIndexer/>
 */    }
        {!isLoaded && 
        <ContentWrapper heading={"Artists"}>
           <Slider type={SLIDER_TYPES.ARTIST_SKELETON}/>
        <Slider type={SLIDER_TYPES.ARTIST_SKELETON}/>
        <Slider type={SLIDER_TYPES.ARTIST_SKELETON}/>
        <Slider type={SLIDER_TYPES.ARTIST_SKELETON}/>
        <Slider type={SLIDER_TYPES.ARTIST_SKELETON}/>
        <Slider type={SLIDER_TYPES.ARTIST_SKELETON}/>
        </ContentWrapper>
            }

      {isLoaded && artists?.artists?.length === 0 && <ContentWrapper heading={"No Artists Found"}>
          
          </ContentWrapper>}
        
    </>
  )
}

export default Artists