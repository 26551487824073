import React, { useCallback, useRef, useState } from 'react'
import ContentWrapper from '../ContentWrapper'
import { Slider } from '../Slider'
import { SLIDER_TYPES } from '../../utils/Enum'
import { ModalLoadingScreen } from '../LoadingScreen'
import Modal from '../Modal'
import { useSimilarCollectionsSearch } from '../../hooks/useSearch'

const SimilarCollections = ({type}) => {
    const [pageNumber, setPageNumber] = useState(0)
    const {loading, error, collections, pages, setPages} = useSimilarCollectionsSearch({pageNumber: pageNumber, type: type})
    const observer = useRef()
    const lastCollectionElementRef = useCallback( node => {
      if(loading){
        return
      }
      if(observer.current){ 
        observer.current.disconnect()}
        observer.current = new IntersectionObserver(entries=> {
          if(entries[0].isIntersecting && (pageNumber < parseInt(pages/10))){
                setPageNumber(prev => prev + 1)
  
          }
      }) 
      
      if(node) observer.current.observe(node)
     
    }, [loading, pages, pageNumber])
    
  return (
    <>
    <ContentWrapper heading={`Other ${type} NFToken Collections`}>
    {
      !loading && collections?.length > 0 && (
        
          <Slider
            type={SLIDER_TYPES.COLLECTION}
            heightScroll
            slides={collections}
            cardRef={lastCollectionElementRef}
            id={"similar collections"}
          />
       
      )
      /*         <PageIndexer/>
       */
    }
    {
      loading && collections?.length > 0 && (
        <>
          <Slider
            type={SLIDER_TYPES.COLLECTION}
            heightScroll
            slides={collections}
            cardRef={lastCollectionElementRef}
            id={"similar collections"}
          />
                   <Modal isOpen={loading}><ModalLoadingScreen text={'Loading. Please Wait'}/></Modal>

</>
      )
      /*         <PageIndexer/>
       */
    }
    {loading && collections?.length === 0 && (
      <ContentWrapper heading={`Other ${type} NFToken Collections`}>
           <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      </ContentWrapper>
    )}
    {!loading && collections?.length === 0 && <ContentWrapper heading={"No Collections Found"}>
        
        </ContentWrapper>}
        </ContentWrapper>
  </>
  )
}

export default SimilarCollections