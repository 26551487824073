import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import { Welcome } from "../../components/Navbar/Data";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import LandingPage from "../../components/LandingSection";

const WelcomePage = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

 useEffect(() => {
  currentUser?.uid && navigate('/home', {replace: true})
 
   return () => {
     
   }
 },[])
 

  return (
      
     <>
    
      {!currentUser?.uid ? <><Sidebar isOpen={isOpen} toggle={toggle} navInfo={Welcome} />
      <Navbar toggle={toggle} navInfo={Welcome} />
    <LandingPage/>
      </>: navigate("/home", {replace: true})}
    </> 
  );
};

export default WelcomePage;
