import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";

export const FormContent = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 480px) {
    padding: 10px;
	
  }
`;
export const Form = styled.form`
  display: flex;
  backdrop-filter: blur(5px);
  flex-direction: column;
  -webkit-backdrop-filter: blur(5px);
  background: ${(props) => hexToRgba(props.theme.mainBg, 0.6)};
  min-width: 400px;
  padding: 10% 6%;
  border-radius: 3%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
`;

export const FormH1 = styled.h1`
  margin-bottom: 8%;;
  color: ${(props) => props.theme.secondaryBg};
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
`;

export const FormLabel = styled.label`
  margin-bottom: 10%;
  color: ${(props) => props.theme.secondaryBg};
  font-size: 14px;
`;

export const FormInput = styled.input`
  padding: 16px 16px;
  margin-bottom: 20px;
  border: none;
  border-radius: 4px;
`;