import React from 'react'
import { SLIDER_TYPES } from '../../../utils/Enum'
import ContentWrapper from '../../ContentWrapper'
import { Slider } from '../../Slider'
import { getObjectLength } from '../../../utils'

const RecordLabelDiscography = ({discography, isLoaded, name}) => {
  return (
    <ContentWrapper heading={`${name}'s Discography`}>{!isLoaded && <>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON} id={'discography'}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
        </>}
        {isLoaded && getObjectLength(discography) > 0 && 
        <>
        {discography?.songs?.length > 0 &&
          <ContentWrapper heading={'Available Songs'}>
        <Slider type={SLIDER_TYPES.SONG} id={'discography'} slides={discography?.songs}/>
        </ContentWrapper>}

        {discography?.albums?.length > 0 && 
        <ContentWrapper heading={'Available Albums'}>
        <Slider type={SLIDER_TYPES.ALBUM} id={'discography'} slides={discography?.albums}/>
        </ContentWrapper>}
        </>
        }
        {isLoaded && getObjectLength(discography) === 0 &&
        <Slider type={SLIDER_TYPES.MISCELLANEOUS_MUSIC} id={'discography'}/>
        }</ContentWrapper>
  )
}

export default RecordLabelDiscography