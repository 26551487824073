import styled from "styled-components";
import { FormTextArea } from "../CustomForm/CustomFormElements";
import hexToRgbA, { hexToRgba } from "../HexToRGBA";

export const MEDIA_SCREEN = { //Screen sizes for devices
  MOBILE: '480',
  TABLETS: '768',
  LAPTOPS: '1024',
  DESKTOPS: '1025',
  TV: '1500'
}

export const Conatiner = styled.div`
    display: flex;
    background: ${(props) => `linear-gradient(10deg, ${hexToRgbA(props.theme.mainBg)} 25%, ${hexToRgba(props.theme.secondaryColor, 0.6)} 35%, ${hexToRgba(props.theme.accentColor, 0.6)} 55%, ${hexToRgbA(props.theme.mainBg)}  65%)`};    
    align-items: center;
    justify-content: center;
    padding-top: 5%;
    margin-bottom: 15%;
    width: 100%;
    height: 100vh;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        flex-direction: column;
    }


`

export const SideContainer = styled.div`
    flex: 1 1 40%;
    width: 100%;
    height: 100%;
    padding: 3%;
    

align-content: center;

`
export const PageTitle = styled.h1`
    font-weight: 700;
    font-size: 3rem;
    padding: 2%;
    margin-bottom: 2%;
    color: ${props => props.theme.secondaryBg};

`


export const FormContainer = styled.div`
width: 100%;
flex: 1 1 60%;
align-items: center;
justify-content: center;
align-content: center;
padding: 3%;
height: fit-content;
flex-direction: column;
height: 100%;
`

export const TextArea = styled(FormTextArea)`
  display: flex;
  resize: none;
`;


export const FormButton = styled.button`
  background: ${(props) => (props.transparent ? 'transparent' : props.theme.mainColor)};
  padding: 2% 1%;
  border: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 3%;
  color: ${(props) => (props.transparent ? props.theme.secondaryBg : props.theme.secondaryBg)};
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 15px;
  text-decoration: ${(props) => (props.transparent ? 'underline' : 'none')};


  @media screen and (max-width: 480px) {
    margin-top: 0;
    font-size: 14px;
  }
`;