import React, { useState } from "react";
import { convertAlbumToSonarMuseSchema, convertSongToSonarMuseSchema, displayNFTokenFlag } from "../../../../utils/nfTokensUploads";
import { getRandomColor } from "../../../HexToRGBA";
import {  ShowAlbumDetails, ShowSongDetails } from "../../../MenuOptions";
import {
  NFTokenInfoContainer,
  NFTokenInfoContent,
  NFTokenInfoContentContainer,
  NFTokenInfoContentHeading,
  NFTokenInfoContentText,
  NFTokenInfoContentWrapper,
  NFTokenInfoHeader,
} from "./MusicInfoElements";
import { downloadFile } from "../../../../utils";
import ScrollToTop from "../../../ScrollToTop";
import { useModal } from "../../../Modal/ModalContext";

const NFTokenInfo = ({ type, content }) => {
  const [color, setColor] = useState(getRandomColor());
  const [showAttributes, setShowAttributes] = useState(false)
  const {modalContent, openModal, closeModal} = useModal()
  const {goTo} = ScrollToTop()

  return (
    <>
      <NFTokenInfoContainer>
        <NFTokenInfoHeader color={content?.metadata?.color ? content?.metadata?.color : color}>
          {content?.metadata?.name ? content?.metadata?.name : content?.metadata?.collection?.name ? content?.metadata?.collection?.name :  content?.nft_id}
        </NFTokenInfoHeader>
        <NFTokenInfoContentContainer>
          <NFTokenInfoContentWrapper>
            <NFTokenInfoContent>
              <NFTokenInfoContentHeading>
                NFToken Type
              </NFTokenInfoContentHeading>
              <NFTokenInfoContentText>
                {content?.metadata?.type ? content?.metadata?.type : content?.metadata?.nftType ? content?.metadata?.nftType : 'N/A'}
              </NFTokenInfoContentText>
            </NFTokenInfoContent>
            <NFTokenInfoContent>
              <NFTokenInfoContentHeading>NFToken ID</NFTokenInfoContentHeading>
              <NFTokenInfoContentText style={{cursor: "pointer", color: content?.metadata?.color && content?.metadata?.color }} onClick={() => downloadFile(`https://xrplexplorer.com//nft/${content?.nft_id}`)}>
                {`${content?.nft_id.substring(
                  0,
                  8
                )}...${content?.nft_id?.substring(content?.nft_id?.length - 3)}`}
              </NFTokenInfoContentText>
            </NFTokenInfoContent>
          </NFTokenInfoContentWrapper>
          <NFTokenInfoContentWrapper>
            {(content?.metadata?.dateCreated || content?.metadata?.date) &&
            <NFTokenInfoContent>
              <NFTokenInfoContentHeading>
                Date Created
              </NFTokenInfoContentHeading>
              <NFTokenInfoContentText>
                { `${new Date(
                  content?.metadata?.dateCreated ? content?.metadata?.dateCreated : content?.metadata?.date
                ).getDate()}/${new Date(
                  content?.metadata?.dateCreated ? content?.metadata?.dateCreated : content?.metadata?.date
                ).getMonth()}/${new Date(
                  content?.metadata?.dateCreated ? content?.metadata?.dateCreated : content?.metadata?.date
                ).getFullYear()}`}
              </NFTokenInfoContentText>
            </NFTokenInfoContent>}
            <NFTokenInfoContent>
              <NFTokenInfoContentHeading>Issuer</NFTokenInfoContentHeading>
              <NFTokenInfoContentText style={{cursor: "pointer", color: content?.metadata?.color && content?.metadata?.color }}  onClick={() => goTo(`/u/${content?.issuer}`)}>
                {`${content?.issuer?.substring(
                  0,
                  8
                )}...${content?.issuer?.substring(content?.issuer?.length - 3)}`}
              </NFTokenInfoContentText>
            </NFTokenInfoContent>
          </NFTokenInfoContentWrapper>

          <NFTokenInfoContentWrapper>
          
          {content?.metadata?.collection && <NFTokenInfoContent>
              <NFTokenInfoContentHeading>
                Collection Info
              </NFTokenInfoContentHeading>
              <NFTokenInfoContentWrapper>
              <NFTokenInfoContent>
              <NFTokenInfoContentText>
                {`Name: ${content?.metadata?.collection?.name}`}
              </NFTokenInfoContentText>
              </NFTokenInfoContent>
             { content?.metadata?.collection?.family && <NFTokenInfoContent>
              <NFTokenInfoContentText>
                {`Family: ${content?.metadata?.collection?.family}`}
              </NFTokenInfoContentText>
              </NFTokenInfoContent>}
              </NFTokenInfoContentWrapper>
              
            </NFTokenInfoContent>}
            {content?.metadata?.description && <NFTokenInfoContent>
              <NFTokenInfoContentHeading>Description</NFTokenInfoContentHeading>
              <NFTokenInfoContentText>
                {content?.metadata?.description}
              </NFTokenInfoContentText>
            </NFTokenInfoContent>}
          </NFTokenInfoContentWrapper>

          <NFTokenInfoContentWrapper>
             <NFTokenInfoContent>
              <NFTokenInfoContentHeading>Flags</NFTokenInfoContentHeading>
              <NFTokenInfoContentText>
                {displayNFTokenFlag(content?.flags)}
              </NFTokenInfoContentText>
            </NFTokenInfoContent>
            <NFTokenInfoContent>
              <NFTokenInfoContentHeading>
                Taxon (Collection ID)
              </NFTokenInfoContentHeading>
              <NFTokenInfoContentText>
                {content?.nft_taxon}
              </NFTokenInfoContentText>
            </NFTokenInfoContent>
           
          </NFTokenInfoContentWrapper>
          {content?.metadata?.attributes?.length > 0 && <NFTokenInfoContentWrapper>
          <NFTokenInfoContent>
                <NFTokenInfoContentHeading style={{textAlign: 'center'}} onClick={() => setShowAttributes(!showAttributes)}>Attributes {!showAttributes ? ` (Show)`: ` (Hide)`}</NFTokenInfoContentHeading>
                {showAttributes && <NFTokenInfoContentContainer>
                  <NFTokenInfoContentWrapper style={{textAlign: 'center'}}>
                {content?.metadata?.attributes.map(a => {
                  return(
                    <>
                    
                    <NFTokenInfoContent>
                    <NFTokenInfoContentText>{`${a?.description ? a?.description :  a?.trait_type}: ${a?.value}`}</NFTokenInfoContentText>
                    </NFTokenInfoContent>
                    
                    </>
                  )
                })}</NFTokenInfoContentWrapper>
                </NFTokenInfoContentContainer>}
          </NFTokenInfoContent>
          </NFTokenInfoContentWrapper>}
          {(content?.metadata?.songs?.length > 0 || content?.metadata?.albums?.length > 0) && (
            <NFTokenInfoContentWrapper>
              <NFTokenInfoContent>
                <NFTokenInfoContentHeading>Albums</NFTokenInfoContentHeading>
                {content?.metadata?.albums?.length > 0 ? (
                  content?.metadata?.albums?.map((album, index) => {
                    return (
                      <NFTokenInfoContentText style={{cursor: 'pointer'}} key={index} 
                      onClick={() => {
                        openModal();
                        modalContent(
                          <>
                          <ShowAlbumDetails song={convertAlbumToSonarMuseSchema(album)}/>
                          </>)}}>
                        {album?.albumName}
                      </NFTokenInfoContentText>
                    );
                  })
                ) : (
                  <NFTokenInfoContentText>N/A</NFTokenInfoContentText>
                )}
              </NFTokenInfoContent>
              <NFTokenInfoContent>
                <NFTokenInfoContentHeading>Songs</NFTokenInfoContentHeading>
                {content?.metadata?.songs?.length > 0 ? (
                  content?.metadata?.songs?.map((song, index) => {
                    return (
                      <NFTokenInfoContentText style={{cursor: 'pointer'}} key={index} onClick={() => {
                        openModal();
                        modalContent(
                          <>
                          <ShowSongDetails song={convertSongToSonarMuseSchema(song)}/>
                          </>)}}>
                        {index+1}. {song?.songName}
                      </NFTokenInfoContentText>
                      
                    );
                  })
                ) : (
                  <NFTokenInfoContentText>N/A</NFTokenInfoContentText>
                )}
              </NFTokenInfoContent>
            </NFTokenInfoContentWrapper>
          )}
        </NFTokenInfoContentContainer>
      </NFTokenInfoContainer>
    </>
  );
};

export default NFTokenInfo;
