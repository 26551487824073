import styled from "styled-components";
import { hexToRgba } from "./HexToRGBA";
import { MEDIA_SCREEN } from "../utils/Enum";

export const SelectButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 0 2%;
    justify-content: flex-end;
    margin-bottom: 5%;
`

export const SelectButton = styled.select`
    display: flex;
    margin-left: 2%;
    margin-right: 1%;
    font-size: 0.9rem;
    color: ${props => props.theme.secondaryBg};
    cursor: pointer;
    border: solid 1px ${props => hexToRgba(props.theme.mainColor, 0.8)};
    border-radius: 5px;
    outline: none;
    background-color: ${props => props.theme.mainBg};

    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        font-size: 0.5rem;;
    }
`