import React, { useContext, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { useAuth } from '../../contexts/AuthContext'
import { DarkTheme, LightTheme } from '../WebsiteThemeElements'

export const WebsiteThemeContext = React.createContext()

export function useWebsiteTheme(){
    return useContext(WebsiteThemeContext)
}

const WebsiteThemeProvider = ({children}) => {
    const {currentUser, gravatarInfo} = useAuth()
    const [theme, setTheme] = useState(localStorage.getItem('theme') ? localStorage.getItem('theme').toString() : 'dark')
    const [mainColor, setMainColor] = useState('#5F0994')
    const [colors, setColors] = useState(localStorage.getItem('theme') ? localStorage.getItem('theme').toString() === 'light' ? LightTheme({mainColor}) : DarkTheme({mainColor}) : DarkTheme({mainColor}))
    useEffect(() => {
      if(gravatarInfo?.profileBackground?.color){
        setMainColor(gravatarInfo.profileBackground?.color)
        return () => {}
      }else{
        setMainColor('#5F0994')
        return () => {}
      }
      return () => {}
    }, [gravatarInfo])


    const toggleTheme = () => {
        if(theme === 'dark'){
            setTheme('light')
            setColors(LightTheme({mainColor}))
            localStorage.setItem("theme", 'light');

        }else{
            setTheme('dark')
            setColors(DarkTheme({mainColor}))
            localStorage.setItem("theme", 'dark');

        }
        
    }
    const value = {
        mainColor,
        theme,
        themeColors: colors,
        setMainColor,
        toggleTheme
    }

    
    
  return (
    <WebsiteThemeContext.Provider value={value}>
    <ThemeProvider theme={theme === 'light' ? LightTheme({mainColor}) : DarkTheme({mainColor}) }>
    {children}
    </ThemeProvider>
    </WebsiteThemeContext.Provider>
  )
}

export default WebsiteThemeProvider



/*const [state, dispatch] = useReducer(WebsiteThemeReducer, {
  theme: localStorage.getItem('theme') ? localStorage.getItem('theme').toString() : 'dark',
  mainColor: gravatarInfo?.profileBackground?.color ? gravatarInfo?.profileBackground?.color : '#5F0994',
  colors: localStorage.getItem('theme') ? localStorage.getItem('theme').toString() === 'light' ? LightTheme({mainColor}) : DarkTheme({mainColor}) : DarkTheme({mainColor})
})

useEffect(() => {
  if(gravatarInfo?.profileBackground?.color){
    dispatch({type: WEBSITE_THEME_ACTIONS.CHANGE_MAIN_COLOR ,data: gravatarInfo.profileBackground?.color})
    return
  }else{
    dispatch({type: WEBSITE_THEME_ACTIONS.CHANGE_MAIN_COLOR ,data: '#5F0994'})
    return
  }
})


 const toggleTheme = () => {
    if(state.theme === 'dark'){
      dispatch({
        type: WEBSITE_THEME_ACTIONS.CHANGE_MAIN_COLOR ,
        data: {
          theme: 'light',
          colors: LightTheme({mainColor: state.mainColor})
      }})

    }else{
      dispatch({
        type: WEBSITE_THEME_ACTIONS.CHANGE_MAIN_COLOR ,
        data: {
          theme: 'dark',
          colors: DarkTheme({mainColor: state.mainColor})
      }})

    }
   
} */ 