import React from 'react'
import { useState } from 'react';
import { ALERT_TYPES } from '../../../utils/Enum';
import ImageUtils from '../../../utils/ImageUtils';
import { useAlert } from '../../Alert/AlertContext';
import { getRandomColor } from '../../HexToRGBA';
import ScrollToTop from '../../ScrollToTop';
import { PodcasterCardBody, PodcasterCardImageWrapper, PodcasterCardImg, PodcasterCardTitle, PodcasterCardWrapper } from './PodcasterCardElements'

const PodcasterCard = ({content}) => {
  const {goTo} = ScrollToTop()
    const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
    const [color, setColor] = useState(getRandomColor())
    const { addAlert } = useAlert();

  return (
    <PodcasterCardWrapper color={color} >
        <PodcasterCardImageWrapper onClick={() => goTo(`/podcaster/${content?.podcasterId}`)}>
            <PodcasterCardImg color={color} src={content?.podcasterSettings?.profilePictureType === "custom" && content?.profilePicture ?
              content?.profilePicture : content?.gravatarProfile?.avatar_url ? content?.gravatarProfile?.avatar_url + "?s=300&d=mp" : DEFAULT_IMAGE(300)}/>
        </PodcasterCardImageWrapper>
        <PodcasterCardBody>
        <PodcasterCardTitle onClick={() => goTo(`/podcaster/${content?.podcasterId}`)}>{content?.podcasterName}</PodcasterCardTitle>
        Podcaster
        </PodcasterCardBody>
    </PodcasterCardWrapper>
  )
}

export default PodcasterCard