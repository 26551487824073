import React, { useEffect, useState } from "react";


import { DEFAULT_IMAGE as defaultImg } from "../GravatarAPI";
import { Button1 } from "../ButtonElement";
import { useAuth } from "../../contexts/AuthContext";
import { Fade } from "react-reveal";
import { HeroH1 } from "../HeroSection/HeroElements";
import LoadingScreen, { Loader } from "../LoadingScreen";
import { FormButton } from "../SignUp/SignUpElements";
import { useNavigate } from "react-router";
import { BtnWrap, EligibilityContainer, Heading, Img, ImgWrap, ProfilePictureBackground, Subtitle, TextWrapper, TopLine } from "./EligibilityViewerElements";
import Modal from "../Modal";
import QualificationRequirements from "../QualificationRequirements";
import { useModal } from "../Modal/ModalContext";

function Eligible(accName, avtLink) {
  const uName = "Welcome, " + accName;
  return {
    id: "eligable",
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: uName,
    headLine: "You are eligible for the Wymbo token distribution",
    description:"", //"to find out more about the team, click here",
    buttonLabel: "About Us",
    imgStart: true,
    img: avtLink,
    alt: "mic",
    dark: false,
    primary: false,
    darkText: true,
    isCircular: true,
  };
}

function NotEligible(accName) {
  const uName = `Hello, ${accName?.substring(0,8)}...${accName?.substring(accName.length - 5, accName.length-1)}`;
  return {
    id: "noteligable",
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: uName,
    to: "#",
    headLine: `You did not register successfully. You currently do not qualify for the Wymbo token distribution.`,
    description:"", //to find out more about how to qualify, follow the instructions below
    buttonLabel: "How do I qualify?",
    imgStart: false,
    img: defaultImg(300),
    alt: "profile picture",
    dark: false,
    primary: false,
    darkText: true,
    isCircular: true,
  };
}

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  headLine,
  description,
  buttonLabel,
  lightText,
  darkText,
  img,
  alt,
  primary,
  dark,
  dark2,
  isCircular,
}) => {
    const {isEligible} = useAuth()
    const navigate  = useNavigate()
    const [isOpen, setOpen] = useState(false)
    const {modalContent} = useModal()
    const {currentUser} = useAuth()
    const toggleModal = () => 
    {
      let toggle = !isOpen
        setOpen(toggle) 

      }

  return (
    <div >
      <Modal isOpen={isOpen} onRequestClose={() => setOpen(false)}>
        <QualificationRequirements/>
      </Modal>
      <EligibilityContainer id={id}>
        <TopLine>{topLine}</TopLine>
      <ImgWrap> 
              <ProfilePictureBackground>
                <Img 
                src={img} alt={alt} 
                isCircular={isCircular} 
                onClick={() => {if(currentUser?.uid){  
                  navigate( `/u/${currentUser?.uid}`)
                  modalContent()}}}
                onContextMenu={(e) => {e.preventDefault()}}/>
              </ProfilePictureBackground>
              </ImgWrap>
              
                <Heading lightText={lightText}>{headLine}</Heading>
                <Subtitle lightText={darkText}>{description}</Subtitle>
                <BtnWrap>
                  {!isEligible && (
                    <Button1
                        onClick={() => toggleModal()}
                      primary={primary ? 1 : 0}
                      dark={dark ? 1 : 0}>
                      How Do I Become Eligible?
                    </Button1>
                  )}
                </BtnWrap>
      </EligibilityContainer>
    </div>
  );
};

const EligibilityViewer = ({ userAddress }) => {
const {gravatarInfo, isEligible} = useAuth()

useEffect(() => {

  return () => {}
}, [])
  
    return (
    <div>
      {isEligible && (
        <InfoSection
          {...Eligible(
            gravatarInfo?.display_name,
            gravatarInfo?.avatar_url + "?s=300"
          )}
        />
      )}
      {(!isEligible) && <InfoSection {...NotEligible(userAddress)} />}
        
    </div>
  );
};

export default EligibilityViewer