import styled from "styled-components";
import { hexToRgba } from "../../HexToRGBA";
import { CardTitle } from "../CardElements";

export const RecordLabelCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`
export const RecordLabelCardImageWrapper = styled.div`
    display: flex;
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 10px 3px rgba(0, 0, 0, 0.3);

`

export const RecordLabelCardImg = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    background: ${props => props.color} url(${props => props.src}) center;
    background-size: cover;
    border-radius: 15px;
    box-shadow: 0 10px 3px rgba(0, 0, 0, 0.3);

`

export const RecordLabelCardBody = styled.div`
    display: flex;
    padding: 3%;
    flex-direction: column;
    justify-content: flex-start;
 
`

export const RecordLabelCardTitle = styled(CardTitle)`
    cursor: pointer;
    &:after{
        ${RecordLabelCardWrapper}: hover &{
            transform: scaleX(1);
            left: 0;
        }

    }
`