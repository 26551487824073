import React, {useEffect, useState} from 'react'
import AudioPlayer from '../../components/AudioPlayer'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import Sidebar from '../../components/Sidebar'
import { useAuth } from '../../contexts/AuthContext'
import { Home as home, Welcome } from '../../components/Navbar/Data'
import QualificationRequirements from '../../components/QualificationRequirements'
import { Container, ContentWrapper, VideoBg } from '../../components/SignUp/SignUpElements'
import { VideoUtils } from '../../utils/ImageUtils'


const AirdropRequirements = () => {
    const {video1} = VideoUtils()
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
	const {currentUser} = useAuth()


  return (
    <>
        <>
		<Sidebar
          isOpen={isOpen}
          toggle={toggle}
          navInfo={localStorage.getItem('accInfo')? home(localStorage.getItem('accInfo')) : Welcome}
        />
          <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? home(localStorage.getItem('accInfo')) : Welcome}/>
			<>
            <Container>
              <VideoBg autoPlay loop muted src={video1}
                    type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}>
                </VideoBg>
                <ContentWrapper>
                <QualificationRequirements/>
            </ContentWrapper></Container>
            </>
		</>	
    </>
  )
}

export default AirdropRequirements