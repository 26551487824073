import React, { useEffect, useState } from "react";
import {
  BuyButton,
  CardContainer,
  CardContent,
  CardTitle,
  CreatorImg,
  CreatorInfo,
  CreatorInfoContent,
  CreatorInfoWrapper,
  H6,
  InfoContentText,
  TextLink,
} from "../DigitalPurchaseCard/DigitalPurchaseCardElements";
import { SkeletonPurchaseCardImg } from "../../Skeleton/SkeletonElements";
import { Theme } from "../../WebsiteThemeElements";
import { SoldOutText } from "../CardElements";
import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  PODCASTER_SEARCH_TYPES,
} from "../../../utils/Enum";
import { FaCrown } from "react-icons/fa";
import ImageUtils from "../../../utils/ImageUtils";
import { useModal } from "../../Modal/ModalContext";
import { useAlert } from "../../Alert/AlertContext";
import { RoyaltySharePurchase } from "../../PurchaseModal";
import { hexToRgba } from "../../HexToRGBA";
import {
  getArtistsNames,
  getArtistsNames2,
  getPodcastersNames2,
} from "../../../utils";
import { ArtistSelectorModal, PodcasterSelectorModal } from "../../MenuOptions";
import ScrollToTop from "../../ScrollToTop";
import { getResizedImage } from "../../../utils/FileUploadsUtils";

const RoyaltyShareCard = ({ content, cardRef }) => {
  const { toggleModal, modalContent, isOpen, openModal, closeModal } =
    useModal();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const { addAlert } = useAlert();
  const { goTo, scrollToTop } = ScrollToTop();
  const [isAlbumArtLoaded,setAlbumArtLoaded] = useState(false)
  const [albumArt, setAlbumArt] = useState(content?.musicDetails?.albumArt ? content?.musicDetails?.albumArt : content?.musicDetails?.thumbnail)

  useEffect(() => {
    let art = content?.musicDetails?.albumArt ? content?.musicDetails?.albumArt : content?.musicDetails?.thumbnail
    if(!isAlbumArtLoaded){
      getResizedImage(art).then(image => {
        setAlbumArt(image)
        
      }).catch(err => {
        setAlbumArt(art)
      })
      setAlbumArtLoaded(true)
    }
  }, [])


  function buy() {
    let info = content;
    try {
      toggleModal();
      modalContent(
        <RoyaltySharePurchase
          type={content.type}
          content={{
            ...info?.musicDetails,
            userRoyalties: info,
          }}
        />
      );
    } catch (err) {
      console.error(err);
    }
  }
  return (
    <CardContainer ref={cardRef}>
      <div style={{ alignItems: "center", margin: "auto" }}>
        {" "}
        <SkeletonPurchaseCardImg
          style={{ borderRadius: "50%", border: `8px inset #acb2ac` }}
          onClick={() => goTo(content?.royaltyId ? `/regium/${content?.royaltyId}` : "#")}
          src={
            isAlbumArtLoaded ? albumArt : content?.musicDetails?.albumArt
              ? content?.musicDetails?.albumArt
              : content?.musicDetails?.thumbnail
              ? content?.musicDetails?.thumbnail
              : defaultAlbumArt
          }
        />
      </div>
      <CardContent>
        {(content?.sharesSold / content?.shares) * 100 > 50 && (
          <SoldOutText
            style={{
              color: content?.color ? content?.color : Theme({}).warningColor,
            }}
          >
            LIMITED SUPPLY LEFT
          </SoldOutText>
        )}
        {content?.sharesSold === content?.shares && (
          <SoldOutText>SOLD OUT</SoldOutText>
        )}

        <CardTitle>
          <FaCrown color="#a28834" style={{ marginRight: "1%" }} />
          <TextLink
            alt
            to={content?.royaltyId ? `/regium/${content?.royaltyId}` : "#"}
          >{`${
            content?.musicDetails?.songName
              ? content?.musicDetails?.songName
              : content?.musicDetails?.albumName
              ? content?.musicDetails?.albumName
              : content?.musicDetails?.episodeName
          }`}</TextLink>
        </CardTitle>
        <CreatorInfoWrapper>
          <CreatorInfoContent>
            <H6>Ticker</H6>
            <InfoContentText>{content.ticker}</InfoContentText>
          </CreatorInfoContent>
          <CreatorInfoContent>
            {content?.type && <><H6>Type</H6>
            <InfoContentText>
              {content?.type[0]?.toUpperCase() + content?.type?.substring(1)}
            </InfoContentText></>}
          </CreatorInfoContent>
          <CreatorInfoContent>
            <H6>Price</H6>
            <InfoContentText>
              {(content?.price && parseFloat(content?.price)) > 0
                ? `${content?.price} ${
                    content?.currency ? content?.currency : "XRP"
                  }`
                : "FREE"}
            </InfoContentText>
          </CreatorInfoContent>
        </CreatorInfoWrapper>
        <CreatorInfoWrapper>
          <CreatorImg
            src={
              content?.musicDetails?.albumArt
                ? content?.musicDetails?.albumArt
                : content?.musicDetails?.thumbnail
                ? content?.musicDetails?.thumbnail
                : defaultAlbumArt
            }
          />
          <CreatorInfo>
            <CreatorInfoContent>
              <H6>Created By</H6>
              <InfoContentText>
                <TextLink
                  onClick={() => {
                    if (content?.musicDetails?.artists?.length > 1) {
                      openModal();
                      return modalContent(
                        <ArtistSelectorModal
                          artists={content?.musicDetails?.artists}
                        />
                      );
                    }
                    if (content?.musicDetails?.podcasters?.length > 1) {
                      openModal();
                      return modalContent(
                        <PodcasterSelectorModal
                          podcasters={content?.musicDetails?.podcasters}
                        />
                      );
                    }
                    return scrollToTop();
                  }}
                  to={
                    content?.musicDetails?.artists.length === 1
                      ? `/artist/${content?.musicDetails?.artists[0].artistId}`
                      : content?.musicDetails?.artists?.length > 1
                      ? "#"
                      : content?.musicDetails?.podcasters?.length === 1
                      ? `/podcaster/${content?.musicDetails?.podcasters[0].podcasterId}`
                      : "#"
                  }
                >
                  {content?.musicDetails?.artists
                    ? getArtistsNames2(
                        content?.musicDetails?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      )?.length > 30
                      ? `${getArtistsNames2(
                          content?.musicDetails?.artists,
                          ARTIST_SEARCH_TYPES.NAME
                        ).substring(0, 25)}...`
                      : getArtistsNames2(
                          content?.musicDetails?.artists,
                          ARTIST_SEARCH_TYPES.NAME
                        )
                    : getPodcastersNames2(
                        content?.musicDetails?.podcasters,
                        PODCASTER_SEARCH_TYPES.NAME
                      )?.length > 30
                    ? `${getPodcastersNames2(
                        content?.podcasters,
                        PODCASTER_SEARCH_TYPES.NAME
                      ).substring(0, 25)}...`
                    : getPodcastersNames2(
                        content?.musicDetails?.podcasters,
                        PODCASTER_SEARCH_TYPES.NAME
                      )}
                </TextLink>
              </InfoContentText>
            </CreatorInfoContent>
          </CreatorInfo>
        </CreatorInfoWrapper>
        <CreatorInfoWrapper>
          <BuyButton
            onClick={() => {
              if (content?.sharesSold === content?.shares) {
                addAlert({
                  title: "Regium Share Purchase",
                  type: ALERT_TYPES.DANGER,
                  message: "Regium Share Sold Out",
                });
                return;
              }
              if (
                content?.royaltyId &&
                content?.shares &&
                content?.sharesSold < content?.shares
              ) {
                buy();
                return;
              }
            }}
          >
            <FaCrown color="#a28834" />
            {content?.royaltyId &&
            content?.shares &&
            content?.sharesSold < content?.shares
              ? "Buy Regium Share"
              : "SOLD OUT"}
          </BuyButton>
          <CreatorInfoContent>
            <InfoContentText>
              <TextLink
                to={content?.royaltyId ? `/regium/${content?.royaltyId}` : "#"}
              >
                View Details
              </TextLink>
            </InfoContentText>
          </CreatorInfoContent>
        </CreatorInfoWrapper>
      </CardContent>
    </CardContainer>
  );
};

export default RoyaltyShareCard;
