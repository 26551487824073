import React, { useEffect, useRef, useState } from "react";
import {
  ProfileContainer,
  ProfileTabs,
  ProfileInfoContainer,
  ProfileInfoText,
  Col1,
  Col2,
  ProfileLink,
  ProfileNavLink,
  ProfileWrapper,
  ProfileNavWrapper,
  ProfileContentWrapper,
  ProfileDiv,
  ProfileInfoWrapper,
  ProfileAboutText,
  Br,
} from "./ProfileElements";
import {changePaymentPointer, defaultPaymentChannel, defaultPaymentPointer, setPaymentChannels, setPaymentPointers, setSwərl} from "../SonarMuseWebMonetization"
import defaultImg from "../../images/sonar_muse_logo_nav1.png";
import Navbar from "../Navbar";
import { Access } from "../Navbar/Data";
import ProfileHeader from "./ProfileHeader";
import ProfileContent from "./ProfileContent";
import { useNavigate, useParams } from "react-router";
import LoadingScreen from "../LoadingScreen";
import hexToRgbA from "../HexToRGBA";
import { UserSkeleton } from "../Skeleton";
import SocialLinks  from "../SocialLinks";
import ProfileDetails from "./ProfileDetails";
import { useUser } from "../../hooks/useUser";
import { useAuth } from "../../contexts/AuthContext";
import FollowersList from "../FollowersList";
import { useModal } from "../Modal/ModalContext";
import { PAYOUT_TYPE } from "../../utils/Enum";
import { DEFAULT_IMAGE } from "../GravatarAPI";
import { getUpdatedUserInfo } from "../../utils/dbUtils/userUtils";

export default function Profile({ user }) {
  const followRef = useRef()
  const [isLoaded, setLoaded] = useState(false);
  const {
    getProfile,
    getArtistsUserFollowing, 
    followUser, 
    unfollowUser, 
    getFollowers, 
    getFollowing,
    isFollowing} = useUser({id: user})
  const [numFollowers, setNumFollowers] = useState(0) // Displays the number of followers the user has
  const [numFollowing, setNumFollowing] = useState(0) // Displays the number of users that the user is following
  const [followers, setFollowers] = useState([])// gets the user's followers list
  const [following, setFollowing] = useState([])// gets the list of users that the user is following
  const [userFollowedArtists, setFArtists] = useState([]) // gets the list of artists that the user is following
  const [isFLoaded, setFLoaded] = useState(false) // A Boolean to chack if the followers/followings have loaded
  const [modalType, setType] = useState() // Follow Modal type (Followers or Following)
  const [isUserFollowing, setIsFollowing] = useState()// if currentUser follows user
  const [isUserFollower, setIsFollower] = useState()// if user is following currentUser
  const [followDetails, setFDetails] = useState({})
  const [profile, setProfile] = useState();// used to set the profile of the user page being visited
  const navigate  = useNavigate();
  const {currentUser} = useAuth()
  const {toggleModal, modalContent} = useModal()



  useEffect(() => {
      
    setLoaded(false)
    if (user === null || !user ) { // If no user found or if user is null
      navigate( "/home");// go back to the home screen
    } else {
      getProfile(user).then((data) => {
          if((data?.beta) && data?.paymentPointer){
            setPaymentPointers({[data?.paymentPointer]: 100})
          }else{
            defaultPaymentPointer()
    
          }
          if((data?.beta) && data?.paymentChannel){
            setSwərl(PAYOUT_TYPE.USER, user)
            setPaymentChannels(PAYOUT_TYPE.USER,{[profile?.profile?.paymentChannel]: 100})
          }else{
            defaultPaymentChannel()
          }
          setProfile(data)
          setNumFollowers(data?.followers)
          setNumFollowing(data?.following)
          setFArtists(data?.followedArtists)
          setIsFollower(data?.isFollower)
          setIsFollowing(data?.isFollowing)
          setLoaded(true)
          getUpdatedUserInfo(user, (d) => {
            setProfile(prev => {
              return {...prev, ...d}
            })
          } )
      }).catch(err => {
        //console.log(err)
        navigate( "/home");
      })
    }
    return () => {}
  }, [user]);
  

  //MOVE THE BUTTONS INTO CUSTOM COMPONENT CLASSES!
  const onFollowClick = () => { // follow and unfollow usera
    let nf = numFollowers + 1
    followRef.current.enabled = false
    followUser().then(data => {
      if(data !== false || data.error)
      {
        setIsFollowing(!isUserFollowing)
        setNumFollowers(nf)
      }
      
    }).catch(err => {
      return
    })
    followRef.current.enabled = true
}

const onUnfollowClick = () => { // follow and unfollow user
  let nf = numFollowers - 1
  followRef.current.enabled = false
  unfollowUser().then(data => {
    if(data !== false || data.error)
    {
      setIsFollowing(!isUserFollowing)
      setNumFollowers(nf)
    }
    
  }).catch(err => {
    return
  })
  followRef.current.enabled = true
}

const onFollowsModal = (modalType) => {
  toggleModal()
  modalContent(<FollowersList user={user} followers={followers} isLoaded={isFLoaded} type={modalType}/>)
  
}

const getUserProfile = async() => {
  return await getProfile().then((data) => {
    
      if((data?.beta) && data?.paymentPointer){
        changePaymentPointer({[data?.paymentPointer]: 100})
      }else{
        defaultPaymentPointer()

      }
      setProfile(data)
      setNumFollowers(data?.followers)
      setNumFollowing(data?.following)
      setFArtists(data?.followedArtists)
      setIsFollower(data?.isFollower)
      setIsFollowing(data?.isFollowing)
      setLoaded(true)
  }).catch(err => {
    console.error(err)
    return
  })
}

  const getFollowsList = async(type) => {
    try{
    setFLoaded(false)
    switch (type) {
      case 'followers':
        let uFollowers = await getFollowers().then((fData) => { // Get the users list of followers
          return fData
        
        }).catch((err)=>{
          //console.log(err)
          return false
        })
        let followersInfo = []
        if(uFollowers && uFollowers.length > 0)
        {
          for(let i = 0; i <= uFollowers.length - 1; i++){
            let isFollowingF = await isFollowing(uFollowers[i].uid, currentUser?.uid).then((data) => {
            return data
          }).catch(err => {
            //console.log(err)
            return false
          })
          followersInfo.push({...uFollowers[i], isFollowing: isFollowingF}) 
        }
        //console.log(followersInfo)
        setFollowers(followersInfo) //
        setFDetails({userFollowers: followersInfo})
        setNumFollowers(uFollowers?.length)
        setFLoaded(true)
      }else if(uFollowers === false && followers.length > 0){
        for(let i = 0; i <= uFollowers.length - 1; i++){
          let isFollowingF = await isFollowing(uFollowers[i].uid, currentUser?.uid).then((data) => {
          return data
        }).catch(err => {
          //console.log(err)
          return false
        })
        followersInfo.push({...uFollowers[i], isFollowing: isFollowingF}) 
      }
        setFDetails({userFollowers: followersInfo})
        setNumFollowers(uFollowers?.length)
        setFLoaded(true)
      }else {
        setFDetails({userFollowers: false})
        setNumFollowers(0)
        setFLoaded(true)
      }
        /* await getFollowing().then((fData) => {
          return  setFollowing(fData)
        }).catch((err)=>{
          //console.log(err)
          return false
        }) */
        break;
        
      case 'following':
        let fArtists = await getArtistsUserFollowing().then((fAData) => {
            return fAData.followedArtists
          }).catch((err)=>{
            //console.log(err)
            return userFollowedArtists
          })
          setFArtists(fArtists)
  
          let uFollowings = await getFollowing().then((fData) => {
            return fData
          }).catch((err)=>{
            //console.log(err)
             return false
          })
          let followingsInfo = []
          if(uFollowings?.length > 0)
          {
            for(let i = 0; i <= uFollowings.length - 1; i++){
              let isFollowingF = await isFollowing(uFollowings[i].uid, currentUser?.uid)
              .then((data) => {
                return data
              }).catch(err => {
              return false
              })
              followingsInfo.push({...uFollowings[i], isFollowing: isFollowingF}) 
              }
          setFollowing(followingsInfo)
          setFDetails({userFollowing: followingsInfo, userFollowedArtists })
          setFLoaded(true)
        }else if(uFollowings === false && following.length > 0 ){
          for(let i = 0; i <= uFollowings.length - 1; i++){
            let isFollowingF = await isFollowing(uFollowings[i].uid, currentUser?.uid)
            .then((data) => {
              return data
            }).catch(err => {
            return false
            })
            followingsInfo.push({...uFollowings[i], isFollowing: isFollowingF}) 
            }
            setFollowing(followingsInfo)
            setFDetails({userFollowing: followingsInfo, userFollowedArtists })
            setFLoaded(true)
        }else{
            setFollowing(0)
            setFDetails({userFollowing: false })
            setFLoaded(true)
        }
          /* await getFollowers().then((fData) => {
            setFollowers(fData)
            setNumFollowers(fData.length)
            return
          }).catch((err)=>{
            
            return false
          }) */
          
          break;
    
      default:
        //console.log("Something went wrong")
        break;
    }
    }catch (err){
      //console.log(err)
    }
  }



  return (
    <>
      {!isLoaded && <LoadingScreen isLoaded={isLoaded}/>}

      {isLoaded && (
          <ProfileDiv
            background={
              profile?.profileBackground
                ? hexToRgbA(profile?.profileBackground?.color)
                : false
            }
          >
            <ProfileContainer>
              <ProfileHeader
                background={
                  profile?.profileBackground
                    ? profile?.profileBackground?.color
                    : false
                }
              />
             <ProfileWrapper>
              <ProfileDetails 
              address={user} 
              exists={profile?.exists}
              wallets={profile?.payments?.crypto_wallets ? Object.values(profile?.payments?.crypto_wallets) : undefined}
              followers={followers?.length > 0 ? followers : undefined}
              following={following?.length > 0 ? following : undefined}
              numFollowers={numFollowers ? numFollowers : 0}
              numFollowing={numFollowing ? numFollowing : 0}
              photo={profile?.avatar_url ? profile?.avatar_url + '?d=mp&s=600': DEFAULT_IMAGE(600)}
              displayName={profile?.display_name ? profile?.display_name :  `${user.substring(0, 6)}...${user.substring(user.length -5, user.length -1)}`}
              accounts={profile?.number_verified_accounts > 0 ? profile?.verified_accounts: false }
              aboutMe={profile?.description ? profile?.description: false }
              currentLocation={profile?.currentLocation ? profile?.currentLocation: false }
              urls={profile?.links}
              artist={profile?.isArtist}
              background={profile?.profileBackground
                ? profile?.profileBackground?.color
                : false}
              
              onFollowsModal={onFollowsModal}
              setType={setType}
              modalType={modalType}
              onUnfollowClick={onUnfollowClick}
              onFollowClick={onFollowClick}
              followDetails={followDetails}
              isLoaded={isFLoaded}
              getFollowsList={getFollowsList}
              followedArtists={profile?.followedArtists}
              isUserFollower={isUserFollower}
              isUserFollowing={isUserFollowing}
              followRef={followRef}
              />
               <Br/>
               <ProfileContent background={profile?.profileBackground
                ? profile?.profileBackground?.color
                : false}
                user={user}
                isFollower={isUserFollower}
                getUserProfile={getUserProfile}
                displayName={profile?.display_name ? profile?.display_name : `${user.substring(0, 6)}...${user.substring(user.length -5, user.length -1)}`}/>
            </ProfileWrapper>
            </ProfileContainer>
          </ProfileDiv>
        )}
    </>
  );
}
