import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import useMusic from '../../hooks/useMusic'
import LoadingScreen, { ModalLoadingScreen } from '../LoadingScreen'
import Navbar from '../Navbar'
import { Home } from '../Navbar/Data'
import Sidebar from '../Sidebar'
import AlbumUploads from './AlbumUploads/index'
import { MusicUploadsConatiner, MusicUploadsWrapper } from './MusicUploadsFormElements'
import SongUploads from './SongUploads/index'
import PodcastUpload from './PodcastUploads'
import { updateWebPageIcon } from '../../utils/MusicUtils'

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

function MusicUploadsForm({type}) {
    const query = useQuery()
    const navigate  = useNavigate();
    const location = useLocation()  
    const {getDraft} = useMusic()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}
    const [isLoaded, setLoaded] = useState(false)
    const [draft, setDraft] = useState()

    useEffect(() => {
        updateWebPageIcon('https://sonarmuse.org/favicon.ico')
        const id = query.get('id')
         if(id && !isLoaded){
            getDraft(id, type).then(
                data => {
                    if(data){
                        setDraft(data)
                        setLoaded(true)
                        return
                    }
                    navigate(`/edit/${type}`, {replace: true});
                    setLoaded(true)

                }
             ).catch(err => {
                navigate(`/edit/${type}`, {replace: true});
                setLoaded(true)
             }) 
             return () => {}
        }else{
            
            setLoaded(true)
            return () => {}
        }
        return () => {}
      }, [type])

      
      useEffect(()=>{
        const idParam = query.get('id')
        if(idParam){
            //console.log(idParam)
        }
        return () => {}
      },)
      

      
  
    return (
       <> {isLoaded && 
       <MusicUploadsConatiner>
            
            <MusicUploadsWrapper>
                {type === 'album' && 
                <AlbumUploads info={draft}/>}
                {type === 'song' && 
                <SongUploads info={draft}/>}
                {type === 'episode' && 
                <PodcastUpload info={draft}/>}
            </MusicUploadsWrapper> 
            
        </MusicUploadsConatiner>}
        {!isLoaded && <ModalLoadingScreen/>}</>
  )
}

export default MusicUploadsForm