import React, { useEffect, useState } from "react";
import { Header } from "../Header";
import ContentWrapper from "../ContentWrapper";
import { Slider } from "../Slider";
import { SLIDER_TYPES } from "../../utils/Enum";
import useMusic from "../../hooks/useMusic";

const SwerlStore = () => {
  const [swerlVouchers, setSwerlVouchers] = useState([]);
  const [isSwerlLoaded, setSwerlLoaded] = useState(false);
  const { getSwerlVouchers } = useMusic({});

  useEffect(() => {
    getSwerlVouchers()
      .then((vouchers) => {
        if (vouchers) {
          setSwerlVouchers(vouchers);
        }
        setSwerlLoaded(true);
      })
      .catch((err) => {
        setSwerlLoaded(true);
      });
    return () => {};
  }, []);

  return (
    <>
      <Header title={"Swərl Store"} />
      <ContentWrapper heading={"What is Swərl?"}>
        <div style={{display: "flex", padding:"4%", alignContent: "center", justifyContent: "center", flexDirection:"column"}}><div>
          Swərl is a service, by the Sonar Muse team, that uses web monetization
          in order to pay for content that is consumed by users over the
          internet, designed to help creators monetize their content, using the
          XRPL. Swərl provides a new way for content creators to make money so
          that they can focus on their craft by creating new experiences for
          their fans to support their work. Swərl allows creators, publishers,
          and platforms to make money without the use of advertising, giving
          power to the consumers and creators by focusing more on the community
          and limit the data being tracked through ads.
        </div>
        <br/>
        <div>
          Rather than using an ad-based revenue model to allow for content to be
          monetized, Swərl uses the XRPL (XRP Ledger) to provide micropayments
          to content creators, by tracking the time spent by users consuming
          content, and sending the payments directly to the content creator’s
          XRP wallet. By paying a fixed subscription fee of $5/month (the
          equivelent in XRP), fans will be able to access content uninterrupted
          and knowing that they are paying out their favourite creators for
          every second they consume, without having their data mined through
          ads.
        </div>
        <br/>
        <div>
          Similar to the service provided by Coil, Swərl aims to become a web
          monetization provider, by allowing content creators such as video
          creators, photographers, writers and journalists, artists, podcasters,
          streamers,musicians and XRPL projects to monetize their platforms,
          using our services. We aim to become interoperable by incorporating
          the use of Interledger-protocol (ILP) as an alternative way to receive
          micropayments in the future, giving content creators and fans other
          ways in which they can pay and receive funds. Swərl is currently under
          development as a prototype and will only be available on the Sonar
          Muse platform, until further notice. For more information and updates
          about Swərl, visit the Sonar Muse platform or follow Sonar Muse
          on twitter.
        </div></div>
      </ContentWrapper>
      {isSwerlLoaded && swerlVouchers?.length > 0 && (
        <ContentWrapper heading={"Subscription Vouchers"}>
          <Slider
            heightScroll={true}
            type={SLIDER_TYPES.SWERL_VOUCHER}
            id={"swerl vouchers"}
            slides={swerlVouchers}
          />
        </ContentWrapper>
      )}
      {!isSwerlLoaded && (
        <ContentWrapper heading={"Subscription Vouchers"}>
          <Slider type={SLIDER_TYPES.PURCHASE_SKELETON} id={"swerl vouchers"} />
        </ContentWrapper>
      )}
    </>
  );
};

export default SwerlStore;
