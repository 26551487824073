import React from 'react'
import { FileUploaderInput } from './FileUploaderElements'
import { useAuth } from '../../contexts/AuthContext'
import { getDownloadUrl, storage, storageRef, uploadBytes } from '../Authorization/firebase'
import useMusic from '../../hooks/useMusic'
import { v4 } from 'uuid'

const FileUploader = ({fileUpload, setFileUpload, fileTypes, name, acceptMultiple, setDuration, userType, minHeight, minWidth}) => {
    const {currentUser} = useAuth()
    const {createMp3File, getLowerVideoResolutions} = useMusic()

    function gcd (a,b){
    if( b === 0){
        return a
      }
    return gcd (b, a % b)
  }

    const getDuration = (src) => {
        var reader = new FileReader();
        let audio = new Audio();
        let duration;
        reader.readAsDataURL(src);
        try{
        reader.onload = function (e) {
          audio.src = e.target.result;
          return audio.addEventListener(
            "loadedmetadata",
            function () {
              // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
              duration = audio.duration;
              setDuration(duration)
              setFileUpload((prev) => {
                return { ...prev, duration: duration };
              });
              // example 12.3234 seconds
              // Alternatively, just display the integer value with
              // parseInt(duration)
              // 12 seconds
            },
            false
          );
        }}catch(err){
          console.error(err)
        }
      };

    const getDurationLong = async(src, ref) => {
        let audio = new Audio();
        let duration;
        try{
        
          audio.src = src;
          return audio.addEventListener(
            "loadedmetadata",
           async function () {
              // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
              duration = audio.duration;
              setDuration(duration)
              if(userType === "artists" && fileTypes === ".wav"){
                await createMp3File({uid: currentUser?.uid, token: v4(), ref: ref, url: src }).then((mp3File) => {
                  if(mp3File){
                    setFileUpload((prev) => {
                    return {
                      ...prev,
                      duration: duration, 
                      url: src,
                    ref: ref,
                    
                      mp3Url: mp3File.mp3Url,
                      mp3Ref: mp3File.mp3Ref
                    };
                  });}else{
                    setFileUpload((prev) => {
                      return { ...prev, 
                        duration: duration, 
                        url: src,
                        ref: ref };
                    });
                  }
                }).catch(err => {
                  console.error(err)
                  setFileUpload((prev) => {
                    return { ...prev, 
                      duration: duration, 
                      url: src,
                      ref: ref };
                  });
                })
              }else{
                setFileUpload((prev) => {
                  return { ...prev, 
                    duration: duration, 
                    url: src,
                    ref: ref };
                });
             
              }
              
              // example 12.3234 seconds
              // Alternatively, just display the integer value with
              // parseInt(duration)
              // 12 seconds
            },
            false
          );
        }catch(err){
          console.error(err)
        }
      };

    const handleImgUpload = (e) => {
      console.log(e.target.files)
        const currArt = fileUpload;
        if (e.target.value && e.target.files[0]) {
          var reader = new FileReader();

          //Read the contents of Image File.
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function (e1) {
            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = e1.target.result;

            setFileUpload((prev) => { return {...prev,  file: e.target.files[0] }});
            image.onload = function () {
              var height = this.height;
              var width = this.width;
              if(!minHeight && !minWidth){
                 if (height < 1600 || width < 1600) {
                alert("Height and Width must not be less than 1600px.");
                setFileUpload(currArt);
                return false;
                }
              }else if((minHeight && minWidth) && (height < minHeight || width < minWidth)){
                alert(`Height and Width must not be less than ${minWidth}px x ${minHeight}px.`);
                setFileUpload(currArt);
                return false;
              }
              

              //let artBlob = convertFileToBytes(e.target.files[0])
              let imageFileName;
              if (!fileUpload.name) {
                imageFileName = `${Math.round(
                  Math.random() * 10000000000000
                )}.${
                  e.target.files[0].name.split(".")[
                    e.target.files[0].name.split(".").length - 1
                  ]
                }`;
                setFileUpload((prev) => {
                  return { ...prev, name: imageFileName };
                });
              } else {
                imageFileName = fileUpload.name;
              }
              const uploadTask = uploadBytes(
                storageRef(
                  storage,
                  `${userType}/${currentUser?.uid}/${imageFileName}`
                ),
                e.target.files[0],
                { contentType: e.target.files[0].type }
              );

              

              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  setFileUpload((prev) => {
                    return { ...prev, progress: progress };
                  });
                 
                  /* if(isCancelSongUpload){
                    if (!uploadTask.isComplete()) {
                      //Upload is not complete yet, let's cancel
                      uploadTask.cancel();
                  } else {
                      //Upload is complete, but user wanted to cancel. Let's delete the file
                      uploadTask.snapshot.ref.delete();
                      // storageRef.delete(); // will delete all your files
                  } 
                  }*/
                  //console.log("Upload is " + progress + "% done");
                  switch (snapshot.state) {
                    case "paused":
                      break;
                    case "running":
                      /* if(isCancelSongUpload){
                        if (!uploadTask.isComplete()) {
                          //Upload is not complete yet, let's cancel
                          uploadTask.cancel();
                      } else {
                          //Upload is complete, but user wanted to cancel. Let's delete the file
                          uploadTask.snapshot.ref.delete();
                          // storageRef.delete(); // will delete all your files
                      }
                      } */
                      break;
                    default:
                      /* if(isCancelSongUpload){
                        if (!uploadTask.isComplete()) {
                          //Upload is not complete yet, let's cancel
                          uploadTask.cancel();
                      } else {
                          //Upload is complete, but user wanted to cancel. Let's delete the file
                          uploadTask.snapshot.ref.delete();
                          // storageRef.delete(); // will delete all your files
                      }
                      } */
                      //console.log("Upload is " + progress + "% done");
                      break;
                  }
                },
                (error) => {
                  
                  // A full list of error codes is available at
                  // https://firebase.google.com/docs/storage/web/handle-errors
                  switch (error.code) {
                    case "storage/unauthorized":
                      // User doesn't have permission to access the object
                      break;
                    case "storage/canceled":
                      setFileUpload(currArt);
                      // User canceled the upload
                      break;
                    // ...
                    case "storage/unknown":
                      setFileUpload(currArt);
                      // Unknown error occurred, inspect error.serverResponse
                      
                      break;
                    default:
                      break;
                  }
                },
                () => {
                  // Upload completed successfully, now we can get the download URL
                  getDownloadUrl(uploadTask.snapshot.ref).then(
                    (downloadURL) => {
                      //console.log("File available at", downloadURL);
                   
                      setFileUpload((prev) => {
                        return {
                          ...prev,
                          url: downloadURL,
                          file: e.target.files[0],
                          ref: uploadTask.snapshot.ref.fullPath,
                        };
                      });
                    }
                  );
                }
              );
            };
          };
        }
    }

    const handleUpload = (e) => {
        if (e.target.value && e.target.files[0]) {
            if(e.target.files[0]?.type.includes('image')){
                return handleImgUpload(e)
            }
            let fileName;
          if (!fileUpload.name) {
            fileName = `${Math.round(Math.random() * 10000000000000)}.${
              e.target.files[0].name.split(".")[
                e.target.files[0].name.split(".").length - 1
              ]
            }`;
            setFileUpload({
              file: e.target.files[0],
              name: fileName,
              ref: `${userType}/${currentUser?.uid}/${fileName}`,
            });
          } else {
            fileName = fileUpload.name;
            setFileUpload((prev) => {
              return {
                ...prev,
                file: e.target.files[0],
                ref: `${userType}/${currentUser?.uid}/${fileName}`,
              };
            });
          }
          if((e.target.files[0]?.type.includes('audio') || e.target.files[0]?.type.includes('video')) && e.target.files[0].size < 209715200){
            getDuration(e.target.files[0])
          }
          try {
            const uploadTask = uploadBytes(
              storageRef(
                storage,
                `${userType}/${currentUser?.uid}/${fileName}`
              ),
              e.target.files[0],
              {
                contentType: e.target.files[0].type,
                customMetadata: {
                  fileName: e.target.files[0].name,
                },
              }
            );

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                setFileUpload((prev) => {
                  return { ...prev, progress: progress };
                });
                switch (snapshot.state) {
                  case "paused":
                    //console.log("Upload is paused");
                    break;

                  case "running":
                    //console.log("Upload is running");
                    break;
                  default:
                    break;
                }
              },
              (error) => {
                // A full list of error codes is available at
                switch (error.code) {
                  case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    break;
                  case "storage/canceled":
                    // User canceled the upload
                    break;

                  // ...

                  case "storage/unknown":
                    // Unknown error occurred, inspect error.serverResponse
                    break;

                  default:
                    break;
                }
              },
              () => {
                // Upload completed successfully, now we can get the download URL
                if(e.target.files[0].size >= 209715200){
                  getDownloadUrl(uploadTask.snapshot.ref).then((downloadURL) => {
                    
                       getDurationLong(downloadURL,uploadTask.snapshot.ref.fullPath)
                      
                    });
                  
                }else{
                getDownloadUrl(uploadTask.snapshot.ref).then(async (downloadURL) => {
                  if(userType === "artists" && fileTypes === ".wav"){
                    return await createMp3File({uid: currentUser?.uid, token: v4(), ref: uploadTask.snapshot.ref.fullPath, url: downloadURL }).then((mp3File) => {
                      if(mp3File){
                        setFileUpload((prev) => {
                        return {
                          ...prev,
                          url: downloadURL,
                          file: e.target.files[0],
                          ref: uploadTask.snapshot.ref.fullPath,
                          mp3Url: mp3File.mp3Url,
                          mp3Ref: mp3File.mp3Ref
                        };
                      });}else{
                        setFileUpload((prev) => {
                          return {
                            ...prev,
                            url: downloadURL,
                            file: e.target.files[0],
                            ref: uploadTask.snapshot.ref.fullPath,
                          };
                        })
                      }
                    }).catch(err => {
                      console.error(err)
                      setFileUpload((prev) => {
                        return {
                          ...prev,
                          url: downloadURL,
                          file: e.target.files[0],
                          ref: uploadTask.snapshot.ref.fullPath,
                        };
                      })
                    })
                  }else{
                    if(name === "video"){
                      return await getLowerVideoResolutions({uid: currentUser?.uid, type: userType, ref: uploadTask.snapshot.ref.fullPath, url: downloadURL })
                      .then((resolutions) => {
                        if(resolutions?.videoResolutions){
                          setFileUpload((prev) => {
                            return {
                              ...prev,
                              url: downloadURL,
                              file: e.target.files[0],
                              ref: uploadTask.snapshot.ref.fullPath,
                              videoResolutions: resolutions?.videoResolutions
                            };
                        })
                      }else{
                        setFileUpload((prev) => {
                          return {
                            ...prev,
                            url: downloadURL,
                            file: e.target.files[0],
                            ref: uploadTask.snapshot.ref.fullPath,
                          };
                        });
                      }
                      }).catch(err => {
                        setFileUpload((prev) => {
                          return {
                            ...prev,
                            url: downloadURL,
                            file: e.target.files[0],
                            ref: uploadTask.snapshot.ref.fullPath,
                          };
                        });
                      })
                    }
                    setFileUpload((prev) => {
                    return {
                      ...prev,
                      url: downloadURL,
                      file: e.target.files[0],
                      ref: uploadTask.snapshot.ref.fullPath,
                    };
                  });
                  }
                });}
              }
            );
          } catch (err) {
            return;
          }
          return
        }
    }

  return (
    <>
    <FileUploaderInput
        id={name}
        name={name}
        type={"file"}
        multiple={acceptMultiple}
        accept={fileTypes}
        onChange={handleUpload}
    />
    </>
  )
}

export const IPFSFileUploader = ({fileUpload, setFileUpload, fileTypes, name, acceptMultiple, setDuration, userType}) => {
    const {currentUser} = useAuth()

    const getDuration = (src) => {
        var reader = new FileReader();
        let audio = new Audio();
        let duration;
        reader.readAsDataURL(src);
        reader.onload = function (e) {
          audio.src = e.target.result;
          return audio.addEventListener(
            "loadedmetadata",
            function () {
              
              // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
              duration = audio.duration;
              setFileUpload((prev) => {
                return { ...prev, duration: duration };
              });
              // example 12.3234 seconds
              // Alternatively, just display the integer value with
              // parseInt(duration)
              // 12 seconds
            },
            false
          );
        };
      };

    const handleImgUpload = (e) => {
        const currArt = fileUpload;
        if (e.target.value && e.target.files[0]) {
          var reader = new FileReader();

          //Read the contents of Image File.
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function (e1) {
            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = e1.target.result;

            setFileUpload((prev) => { return {...prev,  file: e.target.files[0] }});
            image.onload = function () {
              var height = this.height;
              var width = this.width;
              if (height < 1600 || width < 1600) {
                alert("Height and Width must not be less than 1600px.");
                setFileUpload(currArt);
                return false;
              }

              //let artBlob = convertFileToBytes(e.target.files[0])
              let imageFileName;
              if (!fileUpload.name) {
                imageFileName = `${Math.round(
                  Math.random() * 10000000000000
                )}.${
                  e.target.files[0].name.split(".")[
                    e.target.files[0].name.split(".").length - 1
                  ]
                }`;
                setFileUpload((prev) => {
                  return { ...prev, name: imageFileName };
                });
              } else {
                imageFileName = fileUpload.name;
              }
              const uploadTask = uploadBytes(
                storageRef(
                  storage,
                  `${userType}/${currentUser?.uid}/${imageFileName}`
                ),
                e.target.files[0],
                { contentType: e.target.files[0].type }
              );

              

              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  setFileUpload((prev) => {
                    return { ...prev, progress: progress };
                  });
                 
                  /* if(isCancelSongUpload){
                    if (!uploadTask.isComplete()) {
                      //Upload is not complete yet, let's cancel
                      uploadTask.cancel();
                  } else {
                      //Upload is complete, but user wanted to cancel. Let's delete the file
                      uploadTask.snapshot.ref.delete();
                      // storageRef.delete(); // will delete all your files
                  } 
                  }*/
                  //console.log("Upload is " + progress + "% done");
                  switch (snapshot.state) {
                    case "paused":
                      break;
                    case "running":
                      /* if(isCancelSongUpload){
                        if (!uploadTask.isComplete()) {
                          //Upload is not complete yet, let's cancel
                          uploadTask.cancel();
                      } else {
                          //Upload is complete, but user wanted to cancel. Let's delete the file
                          uploadTask.snapshot.ref.delete();
                          // storageRef.delete(); // will delete all your files
                      }
                      } */
                      break;
                    default:
                      /* if(isCancelSongUpload){
                        if (!uploadTask.isComplete()) {
                          //Upload is not complete yet, let's cancel
                          uploadTask.cancel();
                      } else {
                          //Upload is complete, but user wanted to cancel. Let's delete the file
                          uploadTask.snapshot.ref.delete();
                          // storageRef.delete(); // will delete all your files
                      }
                      } */
                      //console.log("Upload is " + progress + "% done");
                      break;
                  }
                },
                (error) => {
                  // A full list of error codes is available at
                  // https://firebase.google.com/docs/storage/web/handle-errors
                  switch (error.code) {
                    case "storage/unauthorized":
                      // User doesn't have permission to access the object
                      break;
                    case "storage/canceled":
                      setFileUpload(currArt);
                      // User canceled the upload
                      break;
                    // ...
                    case "storage/unknown":
                      setFileUpload(currArt);
                      // Unknown error occurred, inspect error.serverResponse
                      break;
                    default:
                      break;
                  }
                },
                () => {
                  // Upload completed successfully, now we can get the download URL
                  getDownloadUrl(uploadTask.snapshot.ref).then(
                    (downloadURL) => {
                      //console.log("File available at", downloadURL);
                      setFileUpload((prev) => {
                        return {
                          ...prev,
                          url: downloadURL,
                          file: e.target.files[0],
                          ref: uploadTask.snapshot.ref.fullPath,
                        };
                      });
                    }
                  );
                }
              );
            };
          };
        }
    }

    const handleUpload = (e) => {
        
        if (e.target.value && ((acceptMultiple && e.target.files?.length > 0) || (acceptMultiple && e.target.files[0]))) {
            if(e.target.files[0]?.type.includes('image')){
                return handleImgUpload(e)
            }
            let fileName;
          if (!fileUpload.name) {
            fileName = `${Math.round(Math.random() * 10000000000000)}.${
              e.target.files[0].name.split(".")[
                e.target.files[0].name.split(".").length - 1
              ]
            }`;
            setFileUpload({
              file: e.target.files[0],
              name: fileName,
              ref: `${userType}/${currentUser?.uid}/${fileName}`,
            });
          } else {
            fileName = fileUpload.name;
            setFileUpload((prev) => {
              return {
                ...prev,
                file: e.target.files[0],
                ref: `${userType}/${currentUser?.uid}/${fileName}`,
              };
            });
          }
          if(e.target.files[0]?.type.includes('audio') || e.target.files[0]?.type.includes('video')){
            getDuration(e.target.files[0])
          }
          try {
            const uploadTask = uploadBytes(
              storageRef(
                storage,
                `${userType}/${currentUser?.uid}/${fileName}`
              ),
              e.target.files[0],
              {
                contentType: e.target.files[0].type,
                customMetadata: {
                  fileName: e.target.files[0].name,
                },
              }
            );

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                setFileUpload((prev) => {
                  return { ...prev, progress: progress };
                });
                switch (snapshot.state) {
                  case "paused":
                    //console.log("Upload is paused");
                    break;

                  case "running":
                    //console.log("Upload is running");
                    break;
                  default:
                    break;
                }
              },
              (error) => {
                // A full list of error codes is available at
                switch (error.code) {
                  case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    break;
                  case "storage/canceled":
                    // User canceled the upload
                    break;

                  // ...

                  case "storage/unknown":
                    // Unknown error occurred, inspect error.serverResponse
                    break;

                  default:
                    break;
                }
              },
              () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadUrl(uploadTask.snapshot.ref).then((downloadURL) => {
                  setFileUpload((prev) => {
                    return {
                      ...prev,
                      url: downloadURL,
                      ref: uploadTask.snapshot.ref.fullPath,
                    };
                  });
                });
              }
            );
          } catch (err) {
            return;
          }
          return
        }
    }

  return (
    <>
    <FileUploaderInput
        id={name}
        name={name}
        type={"file"}
        multiple={acceptMultiple}
        accept={fileTypes}
        onChange={handleUpload}
    />
    </>
  )
}

export default FileUploader