import { Link } from "react-router-dom";
import styled from "styled-components";
import hexToRgbA, { hexToRgba, lightOrDark } from "../../HexToRGBA";
import { getResizedImage } from "../../../utils/FileUploadsUtils";

export const CardContainer = styled.div`
display: flex;
flex-direction: column;
padding: 2%;
white-space: normal;
border-radius: 0.5rem;
margin: 2%;
width: 100%;
max-width: 300px;
align-content: center;
align-items: center;
justify-content: center;
background: ${props => hexToRgba(props.theme.accentColor, 0)};

&: hover{
    background: ${props => props.background? hexToRgba(props.background, 0.2): hexToRgba(props.theme.accentColor, 0.05)};
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
    border: solid ${props => hexToRgba(props.theme?.secondaryBg, 0.7)} 1px;
   }
`

export const CardImg = styled.div`
display: flex;
cursor: pointer;
justify-content: center;
align-content: center;
background: url(${props => props.src}) center;
background-size: cover;
background-position: center;
background-color: ${props => props?.background && props?.background};
width: 15rem;
height: 15rem;
border-radius: 0.5rem;
margin-bottom: 8px;
transition: transform 500ms ease;

&: hover{
    transform: scale(1.02);
  }
     
`

export const PlayContainer = styled.div` 
display: none;
width: 100%;
height: 100%;
justify-content: center;
align-items: center;

${CardImg}: hover &{
   display: flex;
}

`
export const SmallPlayContainer = styled.div` 
display: flex;
width: 100%;
justify-content: center;
align-items: center;

${CardContainer}: hover &{
   display: flex;
}

@media screen and (max-width: 960px) {
    display: flex;
}
`

export const PlayButton = styled.div`
display: flex;
font-size: 0.8rem;
position: absolute;
justify-self: center;
align-self: center;
align-content: center;
color : ${props => props.theme.accentColor};
background: transparent;
transition: 0.8s all ease;
cursor: pointer;
&:hover {
    color: ${props => props.theme.mainColor };
    transition: all 0.2s ease-in-out;
    transform: scale(0.9);
    border-radius: 50%;
    border: 3px solid ${props => props.theme.secondaryBg};

}


`

export const CardContent = styled.div`
    display: flex;
    font-size: 0.8rem;
    padding: 2% 3%;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;

`

export const CardTitle = styled.h4`
    display: flex;
    font-size: 1.2rem;
    margin-bottom: 15px;
    white-space: normal;
    word-wrap: break-word;
    text-align: left;
    color: ${props => props.theme.secondaryColor};

`

export const CreatorInfoWrapper = styled.div`
    display: flex;
    cursor: pointer;
    gap: 0.7rem;
    align-items: center;
    margin-bottom: 3%;
`

export const CreatorImg = styled.div`
    display: flex;
    cursor: pointer;
    padding: 1%;
    background: url(${props => props.src});
    background-size: cover;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: transform 500ms ease;


`

export const CreatorInfo = styled.div`
    display: flex;
    font-size: 0.9rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    width: 100%;
`

export const CreatorInfoContent = styled.div`
    display: flex;
    flex-direction: column;
    
`
export const H6 = styled.h6`
    font-size: 0.8rem;
    color: ${props => hexToRgba(props.theme.accentColor, 1)};
`

export const InfoContentText = styled.div`
    display: flex;
    white-space: normal;
    color: ${props => props.theme.secondaryBg};
    word-wrap: break-word;
`

export const TextLink = styled(Link)`
text-decoration: none;
cursor: pointer;
color: ${props => props?.alt ? props?.theme?.secondaryColor : props.theme.secondaryBg};
&: hover{
 color: ${props => props?.alt ? props.theme.secondaryBg : props.theme.accentColor};
 text-decoration: underline;

}
`

export const BuyButton = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 0.7rem;
    padding: 5px 22px;
    background: transparent;
    border: 1px solid ${props => props.theme.mainColor};
    color: ${props => props.theme.secondaryBg};
    font-size: 0.9rem;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    transition: transform 500ms ease;
    &: hover{
        background: ${props => props.theme.mainColor};
        border: 1px solid ${props => props.theme.mainColor};
        color: ${ props => lightOrDark(props.theme.mainColor) === 'light' ? 'black' : 'white'};

    }
    ${CardContainer}:hover &{
        border: 1px solid ${props => props.theme.accentColor};

    }

    `

