import styled from 'styled-components'
import { hexToRgba } from '../../HexToRGBA'

export const  ControlButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2%;



`

export const PlaylistContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: url(${props => props.src}) center;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    background-color: ${(props) => props.theme.mainBg};
    width: 100%;
    top: 0%;
    height: 100%;
    border-radius: 16px;
    position: relative;
    color: ${(props) => props.theme.secondaryColor};
      @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
      }

`

export const PlaylistContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props?.visualizer ? hexToRgba(props.theme.mainBg, 0.6) :  hexToRgba(props.theme.mainBg, 0.8)};
    width: 100%;
    height: 100%;
    padding: 5% 10%;
    top: 0%;
    position: relative;
    justify-content: center;
    backdrop-filter: ${(props) => props?.visualizer ? 'blur(10px)' : `blur(40px)`};
    -webkit-backdrop-filter: ${(props) => props?.visualizer ? 'blur(10px)' : `blur(40px)`};
    overflow: hidden;
    border-radius: 15px;
    border: 2px ${props =>  hexToRgba(props?.theme?.secondaryBg, 0.6)} groove;
    color: ${(props) => props.theme.secondaryColor};
      @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
        padding: 0%;
          backdrop-filter: ${(props) => props?.visualizer ? 'blur(8px)' : `blur(25px)`};
    -webkit-backdrop-filter: ${(props) => props?.visualizer ? 'blur(8px)' : `blur(25px)`};
      }

`


export const AudioControlButton = styled.div`
background: none;
border: none;
justify-content: center;
display: flex;
font-size: 30px;
margin: 8px 0px 0px;
align-items; center;
cursor: pointer;
  color: ${(props) => props.isActive ? props.theme.mainColor:  props.theme.secondaryColor};

&:hover {
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    color: ${(props) => props.theme.mainColor};
  }

`
export const AudioPlayPauseButton = styled.div`
display: flex;
border-radius: 50%;
padding: 0% 6%;
margin: 3% 0%;
background: ${(props) => props.theme.secondaryBg};
height: 2.3rem;
font-size: 2rem;
width: 2.3rem;
color: ${(props) =>  props.theme.accentColor};
justify-content: center;
align-items: center;
background: transparent;

&:hover {
  transition: all 0.3s ease-in-out;
  color: ${(props) => props.theme.mainColor};
}

`