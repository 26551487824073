import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../components/Footer';
import MusicPage from '../../components/MusicPage'
import Navbar from '../../components/Navbar';
import { Home, Welcome } from '../../components/Navbar/Data';
import Sidebar from '../../components/Sidebar';

function Video() {
  const [video, setVideoId] = useState()
  const {videoId} = useParams()
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {setIsOpen(!isOpen)}
  const navigate  = useNavigate()
  
  useEffect(() => {
      if(!videoId)
      {
          navigate(-1)
      }
      setVideoId(videoId)
      return () => {} 
  }, [videoId])



  return( 
      <>
           <Sidebar
          isOpen={isOpen}
          toggle={toggle}
          navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
        />
          <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
          <MusicPage id={videoId} type={'video'}/>
          
      </>
      )
}

export default Video