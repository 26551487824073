import React from "react";
import {
  AlbumArtPreviewContainer,
  AlbumUploadsContainer,
  AlbumUploadsInput,
  ErrorWrapper,
  FormInput,
  FormLabel,
  FormWrapper,
  SelectButton,
  SongDetailsSection,
  SongDetailsSectionTitle,
  SongDetailsText,
} from "../MusicUploadsFormElements";
import ToggleButton from "../../ToggleButton";
import CurrencySelector from "../../CurrencySelector";
import { FormTextArea } from "../../CustomForm/CustomFormElements";
import LoadingScreen from "../../LoadingScreen";
import { AlbumArtPreview } from "../../AlbumArt";
import ImageUtils from "../../../utils/ImageUtils";
import PodcasterSelector from "./PodcasterSelector";
import GenreSelector from "./GenreSelector";

const Page1 = ({
  handleToggle,
  isExplicit,
  isrcType,
  isrc,
  genres,
  addGenre,
  removeGenre,
  isDownloadable,
  isStreamable,
  isSubscription,
  isMusicContained,
  price,
  releaseDate,
  addPodcaster,
  removePodcaster,
  podcasters,
  currency,
  setCurrency,
  episodeName,
  episodeDescription,
  handleInput,
  errors,
}) => {
  const { defaultAlbumArt } = ImageUtils();

  return (
    <SongDetailsSection>
      <SongDetailsSectionTitle>Details</SongDetailsSectionTitle>
      <PodcasterSelector addPodcaster={addPodcaster} removePodcaster={removePodcaster} podcasters={podcasters}/>

      <FormWrapper>
        <FormLabel>Episode Title</FormLabel>
        <ErrorWrapper>
          <FormInput
            placeholder={"Episode Title"}
            name={"title"}
            value={episodeName}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          {errors?.episodeName && (
            <SongDetailsText error>{errors?.episodeName}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
      <FormWrapper>
        <FormLabel onClick={() => handleToggle("explicit")}>
          Contains Explicit Content
        </FormLabel>
        <ToggleButton
          onText={"On"}
          offText={"Off"}
          toggle={isExplicit}
          toggleClick={() => handleToggle("explicit")}
        />
      </FormWrapper>
      <FormWrapper>
        <FormLabel onClick={() => handleToggle("music")}>
          Contains Music from Sonar Muse Platfom
        </FormLabel>
        <ToggleButton
          onText={"On"}
          offText={"Off"}
          toggle={isMusicContained}
          toggleClick={() => handleToggle("music")}
        />
      </FormWrapper>
      <FormWrapper>
        <FormLabel onClick={() => handleToggle("downloadable")}>
          Downloadable
        </FormLabel>
        <ToggleButton
          onText={"On"}
          offText={"Off"}
          toggle={isDownloadable}
          toggleClick={() => handleToggle("downloadable")}
        />
      </FormWrapper>
      <FormWrapper>
        <FormLabel onClick={() => handleToggle("streamable")}>
          Streamable
        </FormLabel>
        <ToggleButton
          onText={"On"}
          offText={"Off"}
          toggle={isStreamable}
          toggleClick={() => handleToggle("streamable")}
        />
      </FormWrapper>
      <FormWrapper>
        <FormLabel onClick={() => handleToggle("subscription")}>
          Subscription
        </FormLabel>
        <ToggleButton
          onText={"On"}
          offText={"Off"}
          toggle={isSubscription}
          toggleClick={() => handleToggle("subscription")}
        />
      </FormWrapper>

      <br />
      <>
        <FormWrapper>
          <FormLabel>Episode Description</FormLabel>
          <ErrorWrapper>
            <FormTextArea
              placeholder={"Describe the episode"}
              name={"description"}
              value={episodeDescription}
              onChange={(e) => {
                handleInput(e);
              }}
            />
            {errors?.description && (
              <SongDetailsText error>{errors?.description}</SongDetailsText>
            )}
          </ErrorWrapper>
        </FormWrapper>
      </>
      {isDownloadable === true && (
        <FormWrapper>
          <FormLabel>
            Price{" "}
            <CurrencySelector currency={currency} setCurrency={setCurrency} />
          </FormLabel>
          <ErrorWrapper>
            <FormInput
              style={{ flex: "1 1 50%" }}
              name={"price "}
              placeholder={`Enter price of song in ${currency}`}
              min={0}
              step={
                price < 10 ? 1 : price < 100 ? 10 : price < 1000 ? 100 : 1000
              }
              type="number"
              value={price}
              onChange={(e) => {
                handleInput(e);
              }}
            />
            {errors?.price && (
              <SongDetailsText error>{errors?.price}</SongDetailsText>
            )}
          </ErrorWrapper>
        </FormWrapper>
      )}
      {(isStreamable || isDownloadable) && (
        <FormWrapper>
          <FormLabel>
            ISRC Code
            <SelectButton
              name={"isrcType"}
              value={isrcType}
              onChange={handleInput}
            >
              <option value={"auto"}>Auto generate</option>
              <option value={"custom"}>Custom</option>
            </SelectButton>{" "}
          </FormLabel>
          <ErrorWrapper>
            <FormInput
              readOnly={isrcType === "auto"}
              type={"text"}
              name={"isrc"}
              placeholder={"Enter your unique ISRC code for the song"}
              value={isrc}
              onContextMenu={(e) =>
                isrcType === "auto" ? e.preventDefault() : {}
              }
              onChange={(e) => {
                handleInput(e);
              }}
            />
            {errors?.isrc && (
              <SongDetailsText error>{errors?.isrc}</SongDetailsText>
            )}
          </ErrorWrapper>
        </FormWrapper>
      )}
      <FormWrapper>
        <FormLabel>Release Date</FormLabel>
        <ErrorWrapper>
          <FormInput
            placeholder={"Release Date"}
            type={"date"}
            name={"releaseDate"}
            value={releaseDate}
            onChange={(e) => {
              handleInput(e);
            }}
          />
        </ErrorWrapper>
      </FormWrapper>
      <GenreSelector selectedGenres={genres} addGenre={addGenre} removeGenre={removeGenre} genreError={errors?.genres}/>
    </SongDetailsSection>
  );
};

export default Page1;
