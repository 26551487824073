import { Fade } from 'react-reveal'
import styled, {keyframes} from 'styled-components'
import hexToRgbA, { hexToRgba } from '../HexToRGBA'

export const MEDIA_SCREEN = { //Screen sizes for devices
    MOBILE: '480',
    TABLETS: '768',
    LAPTOPS: '1024',
    DESKTOPS: '1025',
    TV: '1500'
  }

export const RecordContainer = styled.div`
	display: flex;
    margin-bottom: 2%;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 3%;
    transition: all 0.2s ease-in-out;
    
    
    ` 
    const rotate = keyframes`
    from {
        transform: rotate(0 deg);
    }
    to {
        transform rotate(360deg);
    }
    
    `
export const RotatingRecord = styled.div`
cursor: pointer;
display: flex;
position: relative;
margin: auto;
justify-content: center;
align-items: center;
width: 30rem;
height: 30rem;
border-radius: 50%;
border: 2px ${ props => hexToRgba(props?.theme?.accentColor, 0.7)} solid;
background: radial-gradient(circle, transparent 40%, ${hexToRgba('#695d53', 0.3)} 40%);
animation: ${rotate} 10s linear infinite;
&:hover{
    perspective: 100px;
    transform: ${props => (props.rotate ? 'rotateY(180deg)': 0)};

}

@media screen and (max-width: 480px) {
    width: 20rem;
    height: 20rem;
  }
@media screen and (max-width: 960px) {
    width: 20rem;
    height: 20rem;
  }

`
export const Record = styled(Fade)`
cursor: pointer;
border-radius: 50%;
padding: 3%;
display: flex;
position: relative;
margin: 19px auto;
justify-content: center;
align-items: center;
width: 30rem;
height: 30rem;
border-radius: 50%;
background: radial-gradient(circle, transparent 40%, rgba(42, 41, 40, 0.85) 40%);
&:hover{
    perspective: 100px;
    transform: ${props => (props.rotate ? 'rotateY(180deg)': 0)};
    animation: none;

}

`

export const AlbumArt = styled.div`
    display: ${props => props.stop ? 'hidden' :'flex'};
    cursor: pointer;
    align-self: center;
    background-image: url(${props => props.coverArt});
    border-radius: ${props => props.stop ? '5%' :'50%' };
    transition: ease 1s all;
    width: ${props => props.stop ? '30rem' :'15.5rem'};
    height: ${props => props.stop ? '30rem': '15.5rem'};
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */

    &:after{ 
    display: ${props => props.stop ? 'none' :'flex'};
    position: absolute;
	top: 50%;
	left: 50%;
	margin: -35px;
	border: solid 1px ${props => hexToRgba(props.theme.secondaryColor,0.6)};
	width: 68px;
	height: 68px;
	border-radius: 50%;
	box-shadow: ${props => `0 0 0 4px ${hexToRgba(props.theme.mainColor,0.6)}, inset 0 0 0 27px ${hexToRgba(props.theme.mainColor,0.6)}`};
	background: black;
	content: "";

}

    @media screen and (max-width: 480px) {
        width: ${props => props.stop ? '20rem' :'12rem'};
    height: ${props => props.stop ? '20rem': '12rem'};
      }
      @media screen and (max-width: 960px) {
        width: ${props => props.stop ? '20rem' :'12rem'};
    height: ${props => props.stop ? '20rem': '12rem'};
      }

`

