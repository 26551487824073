import React, { useEffect, useState } from 'react'
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs'
import {FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa'
import { Fade } from 'react-reveal'
import { useNavigate } from 'react-router-dom'
import ImageUtils from '../../utils/ImageUtils'
import { CarouselArrow, CarouselArrowContainer, CarouselCard, CarouselCardBody, CarouselCardH2, CarouselCardHeader, CarouselCardImg, CarouselCardParagraph, CarouselCardText, CarouselContainer, CarouselDescription, CarouselHeading, CarouselMusicCover, CarouselSection, CarouselSectionWrapper } from './CarouselElements'
import axiosInstance from '../Authorization/client'

const Carousel = ({content, heading}) => {
    const {defaultAlbumArt, DEFAULT_IMAGE, SonarNavInvert, wakeAndBakeVibez} = ImageUtils()
    const navigate  = useNavigate()
    const [index, setIndex] = useState(0)
    const [isLoaded, setLoaded] = useState(false)
    const [data, setData] = useState([])
    const [pager, setPager] = useState(false)
    const def = [
        {
            "header": "artists",
            "heading": "Sonar Muse for Artists",
            "artists": "Sonar Muse",
            "image": "https://firebasestorage.googleapis.com/v0/b/sonar-muse.appspot.com/o/SM%20for%20Artists.png?alt=media&token=cf9aff31-5be2-4964-809d-bab681ede2c9",
            "link":"#",
            "linkArt": "https://firebasestorage.googleapis.com/v0/b/sonar-muse.appspot.com/o/Sonar%20Muse%20For%20Artists%20Label.png?alt=media&token=2b6897c1-4d6b-4758-93b2-c93a519e557c",
            "description": "A new home for artists, podcasters and record labels on the XRPL to release, promote and measure the performance of their musical works all in one place."
      
             
        },
          {
              "image": "https://firebasestorage.googleapis.com/v0/b/sonar-muse.appspot.com/o/Sonar%20Muse%20Regium%20logo1.png?alt=media&token=8e32214c-ac2f-4a2f-a492-f5798ad9c2dc",
              "linkArt": "https://firebasestorage.googleapis.com/v0/b/sonar-muse.appspot.com/o/Sonar%20Muse%20Regium%20icon.png?alt=media&token=2f6fa7aa-4b7a-4de4-a30b-b62ab159cc8e",
              "artists": "Sonar Muse Regium",
              "heading": "Regium Share Tokens",
              "link": "/store",
              "header": "music tokenization",
              "description": "A way for fans to support their favourite artists by investing and earning from their music, through the tokenization of the music industry on the XRPL."
          },
          {
              "image": "https://firebasestorage.googleapis.com/v0/b/sonar-muse.appspot.com/o/users%2FrJZAcSJLmTo22Fh4vFQ2L83jjqtr7JeSWQ%2FSwerl%20Logo%20Black%20Background.png?alt=media&token=2017f22d-151c-4a06-b3c2-4d922e684b44",
              "linkArt": "https://firebasestorage.googleapis.com/v0/b/sonar-muse.appspot.com/o/users%2FrJZAcSJLmTo22Fh4vFQ2L83jjqtr7JeSWQ%2FSonar%20Muse%20Monetization.png?alt=media&token=abe6a874-6468-4eac-81b9-ffb8d9c3eb13",
              "heading": "Swərl Web Monetization",
              "artists": "Swərl",
              "header": "web monetization",
              "description": "A new subscription service that allows you to access music on the Sonar Muse platform.",
              "link": "/swerl"
          },
          {
              "image": "https://firebasestorage.googleapis.com/v0/b/sonar-muse.appspot.com/o/users%2FrJZAcSJLmTo22Fh4vFQ2L83jjqtr7JeSWQ%2F7665201301590.jpg?alt=media&token=b6f201d2-a364-4e65-afd9-8d648a087933",
              "linkArt": "https://firebasestorage.googleapis.com/v0/b/sonar-muse.appspot.com/o/users%2FrJZAcSJLmTo22Fh4vFQ2L83jjqtr7JeSWQ%2F7665201301590.jpg?alt=media&token=b6f201d2-a364-4e65-afd9-8d648a087933",
              "heading": "Sonar Muse for Artists Playlist",
              "artists": "Sonar Muse",
              "link": "/playlist/1OgatlsuaLcsfksjaZJg",
              "header": "playlist",
              "description": "A playlist to showcase talent on the Sonar Muse platform"
          },
          {
              "image": "https://firebasestorage.googleapis.com/v0/b/sonar-muse.appspot.com/o/artists%2FrKNVUBxSf9nKVHpPxYJpJAvsc3pabRdsak%2Fundefined%2FAlbumArt.png?alt=media&token=85df4496-50d1-48e9-9371-9381fae1e1bb",
              "linkArt": "https://firebasestorage.googleapis.com/v0/b/sonar-muse.appspot.com/o/artists%2FrKNVUBxSf9nKVHpPxYJpJAvsc3pabRdsak%2Fundefined%2FAlbumArt.png?alt=media&token=85df4496-50d1-48e9-9371-9381fae1e1bb",
              "heading": "Sonar Muse Freshmen Class of 2K22",
              "artists": "Sonar Muse",
              "link": "/playlist/dN04qs0k6srUso4D2i6I",
              "header": "playlist",
              "description": "A playlist showcasing the first wave (freshmen) of Sonar Muse Artists"
          },
          {
              "header": "podcast",
              "description": "A home for podcasts and long form content on the XRPL",
              "heading": "Sonar Muse for Podcasters",
              "artists": "Sonar Muse",
              "image": "https://firebasestorage.googleapis.com/v0/b/sonar-muse.appspot.com/o/SM%20for%20Podcasters.png?alt=media&token=c66fcbc2-c55c-4e48-9e76-982354d671ab",
              "linkArt": "https://firebasestorage.googleapis.com/v0/b/sonar-muse.appspot.com/o/SM%20for%20Podcasters1.png?alt=media&token=06b8cbf2-0db4-4c26-9217-ee91e23a8287",
              "link": "/latestEpisodes"
          },
        {
            header: "News",
            heading: "Coming Soon",
            artists: "Coming Soon",
            image: SonarNavInvert,
             
        },
        {
            header: "News",
            heading: "Coming Soon",
            artists: "Coming Soon",
            image: SonarNavInvert,
             
        },
        {
            header: "News",
            heading: "Coming Soon",
            artists: "Coming Soon",
            image: SonarNavInvert,
             
        },
        {
            header: "News",
            heading: "Coming Soon",
            artists: "Coming Soon",
            image: SonarNavInvert,
             
        },
        
    ]

    const onNextClick = () => {
        let i = index
        if(index + 2 < data.length-1 || (index + 1 < data.length-1 && data.length % 2 === 1)){
           
            setIndex(i +2)
            
        }
    }

    const onPrevClick = () => {
        let i = index
        //console.log('clicked Prev')
        if(index - 2 >= 0){
            //console.log(i-1)
            setIndex(i-2)
        }
    }

    useEffect(() => {
      axiosInstance.get('/api/marketing/getLatestCampaigns').then((campaigns) => {
        if(campaigns.data && !campaigns.data.error){
            setData(campaigns?.data?.campaigns)
            setLoaded(true)
            return
        }
        setData(def)
        setLoaded(true)
      }).catch(err => {
        setData(def)
        setLoaded(true)
      })
    
      return () => {
        
      }
    }, [])
    


  return (
    <CarouselContainer>
        <CarouselHeading>{heading}</CarouselHeading>
        <CarouselSectionWrapper>
           <CarouselArrowContainer>
              <CarouselArrow style={{opacity: index > 0 ? 1.0 : 0}} onClick={() => {if(index > 0){
                onPrevClick()
                }}}>
               <BsChevronCompactLeft />
            </CarouselArrow>
            </CarouselArrowContainer>
        <CarouselSection>
            {!isLoaded && <>
                <CarouselCard>
                <CarouselCardHeader>
                    <CarouselCardParagraph>{"News"}</CarouselCardParagraph>
                    <CarouselCardH2>{"What's New"}</CarouselCardH2>
                    <CarouselCardText>{"Sonar Muse"}</CarouselCardText>
                </CarouselCardHeader>
                <CarouselCardBody>
                    <CarouselCardImg src={SonarNavInvert}/>
                    <CarouselDescription>
                        <CarouselCardParagraph>
                            
                        </CarouselCardParagraph>
                    </CarouselDescription>
                </CarouselCardBody>
            </CarouselCard>
                
            <CarouselCard>
                <CarouselCardHeader>
                    <CarouselCardParagraph>{"News"}</CarouselCardParagraph>
                    <CarouselCardH2>{"What's New"}</CarouselCardH2>
                    <CarouselCardText>{"Sonar Muse"}</CarouselCardText>
                </CarouselCardHeader>
                <CarouselCardBody>
                    <CarouselCardImg src={SonarNavInvert}/>
                    <CarouselDescription>
                        <CarouselCardParagraph>
                            
                        </CarouselCardParagraph>
                    </CarouselDescription>
                </CarouselCardBody>
            </CarouselCard>
            
            </>}
        {isLoaded && data?.length > 0 && 
                   <><Fade>
                        <CarouselCard>
                <CarouselCardHeader>
                    <CarouselCardParagraph>{data[index].header}</CarouselCardParagraph>
                    <CarouselCardH2>{data[index].heading}</CarouselCardH2>
                    <CarouselCardText>{data[index].artists}</CarouselCardText>
                </CarouselCardHeader>
                <CarouselCardBody>
                    <CarouselCardImg src={data[index].image}/>
                    <CarouselDescription>
                        <CarouselCardParagraph>
                            {data[index].description}
                        </CarouselCardParagraph>
                        {data[index]?.linkArt && <CarouselMusicCover onClick={()=>{navigate( data[index].link)}} src={data[index]?.linkArt}/>}
                    </CarouselDescription>
                </CarouselCardBody>
            </CarouselCard>
                    </Fade>
                    {data[index+1] && <Fade>
                        <CarouselCard>
                <CarouselCardHeader>
                    <CarouselCardParagraph>{data[index+1]?.header}</CarouselCardParagraph>
                    <CarouselCardH2>{data[index+1].heading}</CarouselCardH2>
                    <CarouselCardText>{data[index+1].artists}</CarouselCardText>
                </CarouselCardHeader>
                <CarouselCardBody>
                    <CarouselCardImg src={data[index+1].image}/>
                    <CarouselDescription>
                        <CarouselCardParagraph>
                            {data[index+1].description}
                        </CarouselCardParagraph>
                        {data[index+1]?.linkArt && <CarouselMusicCover onClick={()=>{navigate( data[index+1].link)}} src={data[index+1]?.linkArt}/>}
                    </CarouselDescription>
                </CarouselCardBody>
            </CarouselCard>
                    </Fade>}</> }
        </CarouselSection>
        <CarouselArrowContainer>
           <CarouselArrow style={{opacity: index+1 < data.length-1 ? 1.0 : 0}}  onClick={() => {if(index < data.length-1){onNextClick()}}}>
                <BsChevronCompactRight/>
            </CarouselArrow>
        </CarouselArrowContainer>
        </CarouselSectionWrapper>
    </CarouselContainer>
  )
}

export default Carousel