import React from 'react'
import { Fade } from 'react-reveal'
import { AlbumArt, Record, RecordContainer, RotatingRecord } from './RecordPlayerElements'

const RecordPlayer = ({albumArt, stop, onClick}) => {
  return (
    <><RecordContainer>
        { stop ?
            
            
                <AlbumArt onClick={onClick} stop={stop} coverArt={albumArt}/>
            
            :
            <RotatingRecord>
                <AlbumArt onClick={onClick} coverArt={albumArt}/>
            </RotatingRecord>
        }
        </RecordContainer></>
  )
}

export default RecordPlayer