import React, { useCallback, useEffect, useRef, useState } from 'react'
import useMusic from '../../hooks/useMusic'
import { SLIDER_TYPES } from '../../utils/Enum'
import ContentWrapper from '../ContentWrapper'
import PageIndexer from '../PageIndexer'
import { Slider } from '../Slider'
import { useSongSearch } from '../../hooks/useSearch'
import { ModalLoadingScreen } from '../LoadingScreen'
import Modal from '../Modal'

const LatestSongs = () => {
  const [pageNumber, setPageNumber] = useState(0)

  const {loading, error, songs, pages, setPages} = useSongSearch({pageNumber: pageNumber})

  const observer = useRef()
  const lastSongElementRef = useCallback( node => {
    if(loading){
      return
    }
    if(observer.current){ 
      observer.current.disconnect()}
      observer.current = new IntersectionObserver(entries=> {
        if(entries[0].isIntersecting && (pageNumber < parseInt(pages/10))){
              setPageNumber(prev => prev + 1)

        }
    }) 
    
    if(node) observer.current.observe(node)
   
  }, [loading, pages, pageNumber])
    
    
  return (
    <>
    <ContentWrapper heading={"Latest Songs"}>
    {
      !loading && songs?.length > 0 && (
        
          <Slider
            type={SLIDER_TYPES.SONG}
            heightScroll
            slides={songs}
            cardRef={lastSongElementRef}
            id={"latestsongs"}
          />
       
      )
      /*         <PageIndexer/>
       */
    }
    {
      loading && songs?.length > 0 && (
        <>
          <Slider
            type={SLIDER_TYPES.SONG}
            heightScroll
            slides={songs}
            id={"latestsongs"}
          />
      <Modal isOpen={loading}><ModalLoadingScreen text={'Loading. Please Wait'}/></Modal>
</>
      )
      /*         <PageIndexer/>
       */
    }
    {loading && songs?.length === 0 && (
      <>
           <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
      <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
      <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
      <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
      <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
      <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
      </>
    )}
    {!loading && songs?.length === 0 && <ContentWrapper heading={"No Songs Found"}>
        
        </ContentWrapper>}
        </ContentWrapper>
  </>
  )
}

export default LatestSongs