import React, { useState, useRef, useEffect } from 'react'
import Video from '../../videos/video4.mp4'
import {
	CountdownHeading, 
	CountdownDetails,
	TimerCounter,
	TimerCounter2,
	TimerWrapper,
	TimerInfoWrapper,
	Img,
	TimeDetailsWrapper,
	VideoBg,
	CountdownWrapper,
	Container} from './CountdownTimerElements'
import { HeroBg, HeroContainer, HeroContent } from '../HeroSection/HeroElements'
import { Button } from '../ButtonElement'


const CountdownTimer = ({date, isVidBg, details, heading, img, button}) => {
	
	
			
		return (
		<>
		
			<Container>
				<CountdownWrapper>
				{isVidBg &&
				<>
				<HeroBg>
				<VideoBg autoPlay loop muted src={Video}
                    type='video/mp4'/>
				</HeroBg> </>}
				
				<Timer isPage={true} date={date} isVidBg={isVidBg} details={details} heading={heading} img={img} button={button} />
				
				</CountdownWrapper>
			</Container>
		
		</>
		)

}

export const Timer = ({isPage, date ,details, heading, img, button, isVidBg}) => {
	const [timerDays, setTimerDays] = useState('00')
	const [timerHours, setTimerHours] = useState('00')
	const [timerMins, setTimerMins] = useState('00')
	const [timerSecs, setTimerSecs] = useState('00')		
	
	let interval = useRef();
	
	const startTimer = () => {
		const countdownDate = new Date(date).getTime()
		
		interval = setInterval(() => {
			const now = new Date().getTime()
			const duration = countdownDate - now
			const days = Math.floor((duration / (1000 * 60 * 60 * 24)))
			const hours = Math.floor((duration % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)))
			const mins = Math.floor((duration % (1000 * 60 * 60  ) / (1000 * 60)))
			const secs = Math.floor((duration % (1000 * 60 ) / (1000 )))
		
			if(duration <= 0 )
			{
				//stop timer
				clearInterval(interval.current)
			}else {
				//set timer
				
				setTimerDays(days)
				setTimerHours(hours)
				setTimerMins(mins)
				setTimerSecs(secs)
			}
		}, 1000)
	}
	
	
	//componentDidMount
	useEffect(() => {
		startTimer()
		return () => {
			//clearInterval(interval)
		}})
	return (
		<HeroContent isPage={isPage}>
					
				<TimerInfoWrapper>
					
					{img && <Img src={img} alt="Sonar Muse"/>}
					<CountdownHeading isVidBg={isVidBg}>{heading}</CountdownHeading>
					<CountdownDetails isVidBg={isVidBg}>{details}</CountdownDetails>
				</TimerInfoWrapper>
				<TimerWrapper isVidBg={isVidBg}>
					<TimeDetailsWrapper isVidBg={isVidBg}>
						<TimerCounter>{timerDays}</TimerCounter>
						<TimerCounter2><small>Days</small></TimerCounter2>
					</TimeDetailsWrapper>
					<span>:</span>
					<TimeDetailsWrapper isVidBg={isVidBg}>
						<TimerCounter>{timerHours}</TimerCounter>
						<TimerCounter2><small>Hours</small></TimerCounter2>
					</TimeDetailsWrapper>
					<span>:</span>
					<TimeDetailsWrapper isVidBg={isVidBg}>
						<TimerCounter>{timerMins}</TimerCounter>
						<TimerCounter2><small>Minutes</small></TimerCounter2>
					</TimeDetailsWrapper>
					<span>:</span>
					<TimeDetailsWrapper isVidBg={isVidBg}>
						<TimerCounter>{timerSecs}</TimerCounter>
						<TimerCounter2><small>Seconds</small></TimerCounter2>
					</TimeDetailsWrapper>
					</TimerWrapper>
					
					{button &&
					<><br/>
					<br/> <Button to={button?.to}>{button?.title}</Button>
					</>}
				</HeroContent>
	)
}

export default CountdownTimer;
