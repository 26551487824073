import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";

export const MEDIA_SCREEN = { //Screen sizes for devices
  MOBILE: '480',
  TABLETS: '768',
  LAPTOPS: '1024',
  DESKTOPS: '1025',
  TV: '1500'
}


export const CarouselContainer = styled.div`
  display: flex;
  padding 2%;
  padding-bottom: 32px;
  flex-direction: column;
  scroll-behavior: smooth;
  color: ${(props) => props.theme.secondaryColor};
`;

export const CarouselHeading = styled.h1`
  display: block;
  font-size: 2em;
  padding: 0 2%;
  color: ${(props) => props.theme.secondaryBg};
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`;
export const CarouselSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`;

export const CarouselArrowContainer = styled.div`
    display: flex;
    height: 100%;
    font-size: 2rem;
    padding-top: 62px;
    color: ${(props) => props.theme.secondaryBg};
    cursor: pointer;
    transition: 0.8s all ease;
    z-index: 2;
    

`;

export const CarouselArrow = styled.div`
    display: flex;
    transition: 0.8s all ease;
    font-size: 3rem;
    &:hover{
        transition: all 0.2s ease-in-out;
        transform: scale(1.1);
    }
    box-shadow; 2px 2px 2px 2px rgb(0 0 0 / 124);
    @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
        display: flex;
      }
      @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        display: flex;
      }
    
`

export const CarouselSection = styled.section`
    display: grid;
    box-sizing: border-box;
    padding: 0 40px
    position: relative;
    grid-auto-flow: row;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    align-content: center;    
    width: 100%;
    z-index: 1;
    grid-template-columns: repeat(2, 1fr);
    padding-right: 0;
    padding-left: 0;
    column-gap: 20px;
    row-gap: 24px;

@media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
    max-content: 298px;
    column-gap: 5px;
    row-gap: 24px;
    grid-template-columns: repeat(1, 1fr);
  }

@media screen and (min-width: ${MEDIA_SCREEN.TV}px) {
    column-gap: 20px;
    row-gap: 24px;
    grid-template-columns: repeat(2, 1fr);
  }

`;

export const CarouselCard = styled.div`
  display: grid;
  margin: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  overscroll-behavior-x: none;
  scroll-behavior: smooth;
  scrollbar-width: none;
  cursor: pointer;
`;

export const CarouselCardHeader = styled.div`
  display: flex;
  min-height: 67px;
  flex-direction: column;
  padding: 2%;
`;

export const CarouselCardParagraph = styled.p`
  display: flex;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: uppercase;
  line-height: 1.27273;
  
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px)
  {
    font-size: 0.6rem;
  }
  @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px)
  {
    font-size: 0.5rem;
  }
`;

export const CarouselCardH2 = styled.h2`
  display: flex;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0;
  color: ${(props) => props.theme.secondaryBg};
  margin: 0;
  @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px)
  {
    font-size: 1rem;
  }
  @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px)
  {
    font-size: 0.8rem;
  }
`;
export const CarouselCardText = styled.p`
  display: flex;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0;
  margin: 0;
  padding: 0;
  @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px)
  {
    font-size: 1rem;
  }
  @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px)
  {
    font-size: 0.8rem;
  }
`;

export const CarouselCardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2%;
  width: 100%;
  height: 100%;
  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(0.98);
  }
`;

export const CarouselCardImg = styled.div`
  display: flex;
  background: ${(props) =>
      props?.color ? props.color : hexToRgba(props.theme.secondaryColor, 0.8)}
    url(${(props) => props.src}) center;
  background-size: cover;
  cursor: pointer;
  border-radius: 5px;
  min-height: 300px;
  width: 100%;
  align-items: center;
  transition: transform 500ms ease;
  &:hover {
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px)
  {
    min-height: 150px;
    width: 100%;
  }
  @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px)
  {
    min-height: 100px;
    width: 100%;
  }
`;

export const CarouselDescription = styled.div`
    align-items: center;
    border-radius: 4px;
    bottom: 0;
    display: grid;
    justify-content: space-between;
    color: ${(props) => props.theme.secondaryBg};
    background: ${ props => hexToRgba(props.theme.mainBg, 0.7)};
    margin-top: -100px;
    min-height: 100px;
    padding: 24px 16px 16px;
    white-space: normal;
    grid-template-columns: 87% 13%;;
    z-index: 2;

 /*    ${CarouselCard}: hover &{
        display: grid;
        background: ${ props => hexToRgba(props.theme.mainBg, 0.7)};

    } */
  

`

export const CarouselMusicCover = styled.div`
    display: flex;
    background: ${(props) =>
    props?.color ? props.color : hexToRgba(props.theme.secondaryColor, 0.8)}
    url(${(props) => props.src});
    background-size: cover;
    width: 60px;
    height: 60px;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      transition: all 0.2s ease-in-out;
        transform: scale(1.1);
        opacity: 70%;
      }
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px)
  {
    width: 40px;
    height: 40px;
  }
  @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px)
  {
    width: 30px;
    height: 30px;
  }

`
