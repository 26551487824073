import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router'
import Footer from '../../../components/Footer'
import Navbar from '../../../components/Navbar'
import {Home, Welcome } from '../../../components/Navbar/Data'
import Sidebar from '../../../components/Sidebar'
import {ArtistProfile as Profile} from '../../../components/ArtistProfile'


const ArtistProfile = () => {
    const [artistProfile, setArtist] = useState()
    const {artist} = useParams()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}
    const navigate  = useNavigate()
    
    useEffect(() => {
        let aProfile = artist
        if(!artist)
        {
            navigate(-1)
        }
        setArtist(artist)
        return () => {}
    }, [artist])



    return( 
        <>
             <Sidebar
          isOpen={isOpen}
          toggle={toggle}
          navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
        />
          <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
            <Profile artist={artist}/>
            
        </>
        )
    
}

export default ArtistProfile