import React, { useState } from "react";
import {
  FormButton,
  Conatiner,
  FormContainer,
  TextArea,
  SideContainer,
  PageTitle,
} from "./ContactUsFormElements";
import {
  FormContent1,
  FormInput,
  FormText,
} from "../CustomForm/CustomFormElements";
import { emailRegex } from "../../utils/Regex";
import axiosInstance from "../Authorization/client";
import { useModal } from "../Modal/ModalContext";
import OptionModal from "../OptionModal";
import { HoverOption } from "../HoverMenu/HoverMenuElements";
import { useNavigate } from "react-router-dom";
import LoadingScreen, { ModalLoadingScreen } from "../LoadingScreen";
import Navbar from "../Navbar";
import { Access } from "../Navbar/Data";
import Footer from "../Footer";
import { VideoBg } from "../SignUp/SignUpElements";
import { VideoUtils } from "../../utils/ImageUtils";
import { Theme } from "../WebsiteThemeElements";
import { hexToRgba } from "../HexToRGBA";
    const axios = axiosInstance

const ContactUsForm = () => {
    const {video3} = VideoUtils()
    const [name, setName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState()
    const [ errMsg, setError] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const [isLoaded, setLoaded] = useState(true)
    const {modalContent, openModal, closeModal} = useModal()
    const navigate  = useNavigate()

    const contactUs = () => {
        setError("")
        if(emailRegex.test(email) && message?.trim()?.length > 0 && name?.trim()?.length > 0 && subject?.trim()?.length > 0){
                    setSubmitted(false)
                    setLoaded(false)
            return axios.post(`api/contactUs/uploadMessage`, {content: {
                name: name,
                lastName: lastName,
                email: email,
                subject: subject,
                message: message
            }}).then((result) => {
                if(result.data){
                    setSubmitted(true)
                    setLoaded(true)
                    setName("")
                    setEmail("")
                    setMessage("")
                    openModal()
                    navigate( '/home')
                    modalContent(<OptionModal heading={"Your message has been submitted. Check your emails for any response from us"}>
                        <HoverOption onClick={() => {
                            navigate( '/home')
                            closeModal()
                            modalContent()
                            }}> OK</HoverOption>
                    </OptionModal>)
                    return
                }else{
                    setSubmitted(false)
                    setLoaded(true)
                    openModal()
                    modalContent(<OptionModal heading={"Something went wrong. Try again"}>
                        <HoverOption onClick={() => {
                            closeModal()
                            modalContent()
                            }}> Try Again </HoverOption>
                        <HoverOption onClick={() => {
                            navigate( '/')
                            closeModal()
                            modalContent()
                            }}> Go Home</HoverOption>
                    </OptionModal>)
                }
            }).catch( err => {
                setSubmitted(false)
                setLoaded(true)
                openModal()
                modalContent(<OptionModal heading={"Something went wrong. Try again"}>
                <HoverOption onClick={() => {
                    closeModal()
                    modalContent()
                    }}> Try Again </HoverOption>
                <HoverOption onClick={() => {
                    navigate( '/')
                    closeModal()
                    modalContent()
                    }}> OK</HoverOption>
            </OptionModal>)
            })
        }else{
          setError("Invalid Submission, Try Again")
        return  
        }
        
    }



  return (
    <>
    <Navbar navInfo={Access} />
    
      <Conatiner>
        {isLoaded && !submitted && <>
        <SideContainer>
          <PageTitle>Get In Touch</PageTitle>
          <FormText style={{ fontSize: "1.2rem" }}>
            Fill out the form to get in touch with a member of our information
            team. Once the form has been submitted, we will respond shortly.
          </FormText>
        </SideContainer>
        <FormContainer style={{justifyContent: "center", alignContent: "center"}}>
        <PageTitle style={{textAlign:"center"}}>Contact Us</PageTitle>

          <FormContent1>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <FormInput style={{ marginRight: "2%", color: Theme({}).secondaryBg}} placeholder={"First Name"} value={name} onChange={e => setName(e.target.value)}/>
              <FormInput style={{ marginRight: "2%", color: Theme({}).secondaryBg }} placeholder={"Last Name"} value={lastName} onChange={e => setLastName(e.target.value)}/>
            </div>
            <FormInput style={{color: Theme({}).secondaryBg }} placeholder={"Email address"} type={"email"} value={email} onChange={e => setEmail(e.target.value)}/>
            <FormInput style={{color: Theme({}).secondaryBg }} placeholder={"Subject"} value={subject} onChange={e => setSubject(e.target.value)}/>

          </FormContent1>
          <div style={{ display: "flex", width: "100%", height: "35%" }}>
            <TextArea style={{ color: Theme({}).secondaryBg }} placeholder={"Message"} value={message} onChange={e => setMessage(e.target.value)}/>
          </div>
          {errMsg?.length > 0 && <FormText>{errMsg}</FormText>}
          <div style={{ width: "100%", marginTop: "2%", marginBottom: "3%" }}>
            <FormButton 
            onClick={contactUs}>Contact Us</FormButton>
          </div>
        </FormContainer></>}
        {
            !isLoaded && !submitted && <ModalLoadingScreen text={'Loading Please Wait...'} transparent/>
        }
      </Conatiner>
      
    </>
  );
};

export default ContactUsForm;
