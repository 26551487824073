import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import useArtist from "../../../hooks/useArtist";
import useMusic from "../../../hooks/useMusic";
import { containsObject, getObjectLength, getUniqueListBy, isrcReg, newlineFormatter } from "../../../utils";
import {
  ALERT_TYPES,
  ARTIST_FORM_INPUT_TYPES,
  ARTIST_FORM_TYPES,
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
} from "../../../utils/Enum";
import { useAlert } from "../../Alert/AlertContext";
import { Button, Button1 } from "../../ButtonElement";
import { FormWrapper } from "../../CustomForm/CustomFormElements";
import LoadingScreen from "../../LoadingScreen";

import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import { useModal } from "../../Modal/ModalContext";
import { SongDetailsText } from "../../MusicUploadsForm/MusicUploadsFormElements";
import NFTStorageTokenForm from "../../MusicUploadsForm/NFTStorageTokenForm";
import OptionModal from "../../OptionModal";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Alert from "../../Alert";
import { Small } from "../../QRCodeForm/QrCodeFormElements";

const SongEdit = ({ song, submitChanges, validateNFTStorageKey }) => {
  const { modalContent, isOpen, toggleModal, openModal, closeModal } = useModal();
  const { addAlert } = useAlert();
  const { currentUser } = useAuth();
  const { searchArtists } = useArtist({});
  
  const { generateISRCCode } = useMusic();

  const isrcRef = useRef();

  const writerNameRef = useRef("");
  const writerIdRef = useRef("");
  const writerWalletAddressRef = useRef("");
  const writerFullNameRef = useRef("");
  const writerSearchRef = useRef("");

  const composerNameRef = useRef("");
  const composerIdRef = useRef("");
  const composerWalletAddressRef = useRef("");
  const composerFullNameRef = useRef("");
  const composerSearchRef = useRef("");

  const producerNameRef = useRef("");
  const producerIdRef = useRef("");
  const producerWalletAddressRef = useRef("");
  const producerFullNameRef = useRef("");
  const producerSearchRef = useRef("");

  const payoutArtistNameRef = useRef("");
  const payoutArtistIdRef = useRef("");
  const payoutArtistWalletAddressRef = useRef("");
  const payoutArtistFullNameRef = useRef("");
  const payoutArtistSearchRef = useRef("");
  const artistPayoutRef = useRef();

  const location = useLocation();
  const navigate  = useNavigate();

  const [isExplicit, setExplicit] = useState(
    song?.explicit ? song?.explicit : false
  );
  const [isCover, setCover] = useState(song?.isCover ? song?.isCover : false);
  const [isStreamable, setStreamable] = useState(
    song?.isStreamable ? song?.isStreamable : true
  );
  const [isDownloadable, setDownloadable] = useState(
    song?.isDownloadable ? song?.isDownloadable : false
  );
  const [isSubscription, setSubscription] = useState(
    song?.isSubscription ? song?.isSubscription : false
  );
  const [isMintable, setMintable] = useState(
    song?.isMintable ? song?.isMintable : false
  );
  const [isUserRoyalties, setIsUserRoyalties] = useState(song?.isUserRoyalties ? song?.isUserRoyalties : false)
  const [price, setPrice] = useState(
    song?.isDownloadable ? (song?.price ? song?.price : 1) : undefined
  );
  const [isIsrcValid, setIsrcValid] = useState(false);

  const [isrcType, setIsrcType] = useState("custom");
  const [autoIsrcCode, setAutoIsrcCode] = useState(
    song?.autoIsrcCode ? song?.autoIsrcCode : undefined
  );
  const [composerSearch, setComposerSearch] = useState();
  const [producerSearch, setProducerSearch] = useState();
  const [writerSearch, setWriterSearch] = useState();
  const [payoutSearch, setPayoutSearch] = useState();
  const [videoFile, setVideoFile] = useState(song?.video ? song?.video :{});
  const [visualizerFile, setVisualizerFile] = useState(song?.visualizer ? song?.visualizer :{});

  const [currency, setCurrency] = useState( song?.isUserRoyalties ? "XRP" : song?.currency ? song?.currency : "WYM"
  );
  const [isrc, setISRC] = useState(song?.isrc ? song?.isrc : "");
  const [about, setAbout] = useState(song?.about ? song?.about : "");
  const [lyrics, setLyrics] = useState(song?.lyrics ? song?.lyrics : "");
  const [currentPayoutArtist, setCurrentPayoutArtist] = useState({});
  const [composers, setComposers] = useState(
    song?.songCredits?.composers
      ? song?.songCredits?.composers.map((c) => {
          return {
            ...c,
            exists: true,
          };
        })
      : []
  );
  const [currentComposer, setCurrentComposer] = useState({});
  const [writers, setWriters] = useState(
    song?.songCredits?.writers
      ? song?.songCredits?.writers.map((w) => {
          return {
            ...w,
            exists: true,
          };
        })
      : []
  );
  const [currentWriter, setCurrentWriter] = useState({});
  const [producers, setProducers] = useState(
    song?.songCredits?.producers
      ? song?.songCredits?.producers.map((p) => {
          return {
            ...p,
            exists: true,
          };
        })
      : []
  );
  const [userRoyalties, setUserRoyalties] = useState(song?.userRoyalties? {
    ...song?.userRoyalties,
    percentage: song?.userRoyalties?.percentage,
    royaltyId: song?.userRoyalties?.royaltyId,
    shares: 100,
    price: song?.userRoyalties?.price,
    ticker: song?.userRoyalties?.ticker,
    currency: song?.userRoyalties?.currency
  } : {
    percentage: 10,
    shares: 100,
    price: 0,
    shareReserve: 0,
    ticker: "",
    currency: "XRP"
  })
  const [payoutArtists, setPayoutArtists] = useState(
    song?.royalties
      ? Object.keys(song?.royalties).map((p, index) => {
          let artists = [...song?.artists];
          if (song?.songCredits?.producers?.length > 0) {
            artists.push(...song?.songCredits?.producers);
          }
          if (song?.featuredArtists?.length > 0) {
            artists.push(...song?.featuredArtists);
          }
          if (song?.songCredits?.writers?.length > 0) {
            artists.push(...song?.songCredits?.writers);
          }
          if (song?.songCredits?.composers?.length > 0) {
            artists.push(...song?.songCredits?.composers);
          }
          artists = getUniqueListBy(artists, "artistId");
          let artist = artists.filter((a) => p === a?.artistId); // search for current payout artist from list
          return {
            ...artist[0],
            exists: true,
            payoutPercentage: song?.royalties[p],
          }; //return in payout artist format
        })
      : []
  );
  const [currentProducer, setCurrentProducer] = useState({});
  const [requestRelease, setReleaseRequest] = useState(false);
  const [artistEditng, setArtistEditing] = useState(false);
  const [fArtistEditng, setFArtistEditing] = useState(false);
  const [writerEditing, setWriterEditing] = useState(false);
  const [composerEditing, setComposerEditing] = useState(false);
  const [producerEditing, setProducerEditing] = useState(false);
  const [payoutEditing, setPayoutEditing] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!autoIsrcCode && isrc?.trim()?.length) {
      getISRCCode();
    }
    return () => {}
  }, []);

  const validateArtistInput = (currentArtist) => {
    if (currentArtist && currentArtist?.artistName?.trim() !== "") {
      return true;
    }
    return false;
  };

  const getISRCCode = async () => {
    return await generateISRCCode()
      .then((code) => {
        //console.log("code: ", code);
        if (!code?.error || code !== false) {
          setAutoIsrcCode(code);
          if(isrc?.trim()?.length === 0){
                      setISRC(code);
          }
        } else {
          //console.log("ID not generated");
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const clearArtistForm = (type) => {
    switch (type) {
      case ARTIST_FORM_TYPES.COMPOSER:
        setComposerSearch();
        setCurrentComposer();
        if (composerSearch?.current?.value) {
          composerSearch.current.value = "";
        }
        if (composerIdRef?.current?.value) {
          composerIdRef.current.value = "";
        }
        if (composerNameRef?.current?.value) {
          composerNameRef.current.value = "";
        }
        if (composerWalletAddressRef?.current?.value) {
          composerWalletAddressRef.current.value = "";
        }
        if (composerFullNameRef?.current) {
          composerFullNameRef.current = "";
        }

        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        setProducerSearch();
        setCurrentProducer();
        if (producerSearchRef?.current?.value) {
          producerSearchRef.current.value = "";
        }
        if (producerIdRef?.current?.value) {
          producerIdRef.current.value = "";
        }
        if (producerNameRef?.current?.value) {
          producerNameRef.current.value = "";
        }
        if (producerWalletAddressRef?.current?.value) {
          producerWalletAddressRef.current.value = "";
        }
        if (producerFullNameRef.current?.value) {
          producerFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.WRITER:
        setCurrentWriter();
        setWriterSearch();
        if (writerSearchRef?.current?.value) {
          writerSearchRef.current.value = "";
        }
        if (writerIdRef?.current?.value) {
          writerIdRef.current.value = "";
        }
        if (writerNameRef?.current?.value) {
          writerNameRef.current.value = "";
        }
        if (writerWalletAddressRef?.current?.value) {
          writerWalletAddressRef.current.value = "";
        }
        if (writerFullNameRef.current?.value) {
          writerFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        setCurrentPayoutArtist();
        setPayoutSearch();
        if (payoutArtistSearchRef?.current?.value) {
          payoutArtistSearchRef.current.value = "";
        }
        if (payoutArtistIdRef?.current?.value) {
          payoutArtistIdRef.current.value = "";
        }
        if (payoutArtistNameRef?.current?.value) {
          payoutArtistNameRef.current.value = "";
        }
        if (payoutArtistWalletAddressRef?.current?.value) {
          payoutArtistWalletAddressRef.current.value = "";
        }
        if (payoutArtistFullNameRef.current?.value) {
          payoutArtistFullNameRef.current.value = "";
        }
        if (artistPayoutRef.current?.value) {
          artistPayoutRef.current.value = 0.1;
        }
        break;
      default:
        break;
    }
  };

  const selectArtist = (type, artist) => {
    let a = [];

    switch (type) {
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;
        setCurrentComposer({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setComposers(a);
        setComposerSearch();
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;
        setCurrentProducer({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setProducers(a);
        setProducerSearch();
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;
        setCurrentWriter({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setWriters(a);
        setWriterSearch();
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        a = payoutArtists;
        setCurrentPayoutArtist({
          ...artist,
          payoutPercentage: 0,
        });
        a.push({ ...artist, payoutPercentage: 0 });
        setPayoutArtists(a);
        setPayoutSearch();
        break;
      default:
        break;
    }
    clearArtistForm(type);
  };

  const validateArtistInfo = (id, type) => {
    switch (type) {
      case ARTIST_FORM_TYPES.COMPOSER:
        if (validateArtistInput(currentComposer)) {
          composerEditing ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        if (validateArtistInput(currentProducer)) {
          producerEditing ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.WRITER:
        if (validateArtistInput(currentWriter)) {
          writerEditing ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        if (
          currentPayoutArtist.payoutPercentage <= 100 &&
          currentPayoutArtist.payoutPercentage > 0
        ) {
          editArtist(id, type);
        } else {
          setCurrentPayoutArtist((prev) => {
            return { ...prev, error: "Invalid payout split" };
          });
        }
        break;
      default:
        break;
    }

    clearArtistForm(type);
  };

  const editArtist = (id, type) => {
    let a = {};
    let updatedArtists = [];

    switch (type) {
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentComposer : artist
        );

        setComposers(updatedArtists);
        setComposerEditing(false);
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentProducer : artist
        );

        setProducers(updatedArtists);
        setProducerEditing(false);
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentWriter : artist
        );

        setWriters(updatedArtists);
        setWriterEditing(false);
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        a = payoutArtists;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentPayoutArtist : artist
        );

        setPayoutArtists(updatedArtists);
        setPayoutEditing(false);
        break;
      default:
        break;
    }

    clearArtistForm(type);
  };

  const addArtist = (type) => {
    let a = [];

    switch (type) {
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;
        a.push({ ...currentComposer, exists: false });
        setComposers(a);
        setCurrentComposer();
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;
        a.push({ ...currentProducer, exists: false });
        setProducers(a);
        setCurrentProducer();
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;
        a.push({ ...currentWriter, exists: false });
        setWriters(a);
        setCurrentWriter();
        break;
      /* case ARTIST_FORM_TYPES.PAYOUT:
        a = writers
        a.push({...currentPayoutArtist, exists: false});
        setPayoutArtists(a);
        setCurrentPayoutArtist()
          break; */
      default:
        break;
    }
    //console.log("Artists: ", a);
  };

  const removeArtist = (id, type) => {
    let a = [];
    let updatedArtists = [];
    switch (type) {
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setComposers(updatedArtists);
        setComposerEditing(false);
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setProducers(updatedArtists);
        setProducerEditing(false);
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setWriters(updatedArtists);
        setWriterEditing(false);

        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        a = payoutArtists;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setPayoutArtists(updatedArtists);
        setPayoutEditing(false);
        break;
      default:
        break;
    }

    clearArtistForm(type);
  };

  const handleInput = async (e) => {
    let prevErrors = errors;
    e.preventDefault();
    switch (e.target.name) {
      case "price song":
        if(e.target.value[0] === "0"){
          setPrice(e.target.value.substring(1,e.target.value.length)); 
         }else{
           setPrice(e.target.value)
         }
         
         if(!isNaN(e.target.value) && e.target.value > 0){
           delete prevErrors.price
           setErrors(prevErrors)
           return
         }
         if(e.target.value?.length === 0){
           
           setPrice(0)
         }
         if(isNaN(e.target.value)){
         /*   err = {
             ...err,
             price: "Invalid Price. Please enter price or leave 0"
           } */
           setPrice(parseFloat(e.target.value))
           /* setErrors(err) */
           return
         }
        break;

      case "isrc song":
        setISRC(e.target.value.toUpperCase());
        if (isrcReg.test(e.target.value)) {
          setIsrcValid(true);
          //console.log("valid ISRC: ", true);
        } else {
          setIsrcValid(false);
          //console.log("valid ISRC: ", false);
        }
        break;

      case "isrcType":
        switch (e.target.value) {
          case "auto":
            setIsrcType("auto");
            isrcRef.current.value = autoIsrcCode;
            //console.log("ISRC AUTO: ", autoIsrcCode);
            setISRC(autoIsrcCode);
            break;

          case "custom":
            setISRC("");
            setIsrcType("custom");
            isrcRef.current.value = "";

            break;

          default:
            setIsrcType("auto");
            isrcRef.current.value = autoIsrcCode;
            setISRC(autoIsrcCode);
            break;
        }
        break;

      case "about song":
        setAbout(newlineFormatter(e.target.value));
        break;
      case "lyrics song":
        setLyrics(e.target.value);
        break;

      default:
        break;
    }
  };

  const handleToggle = (type) => {
    let streamable = isStreamable;
    let downloadable = isDownloadable;
    let cover = isCover;
    let subscription = isSubscription;
    let mintable = isMintable;
    let explicit = isExplicit;
    switch (type) {
      case "explicit":
        explicit = !explicit;
        setExplicit(explicit);
        break;
      case "streamable":
        streamable = !streamable;
        if (streamable) {
          setSubscription(false);
        }
        setStreamable(streamable);
        break;
      case "downloadable":
        downloadable = !isDownloadable;
        if (downloadable) {
          setSubscription(false);
        }
        setDownloadable(downloadable);
        break;

      case "cover":
        cover = !isCover;
        if (cover) {
          setCover(false);
        }
        setCover(cover);
        break;
      case "subscription":
        subscription = !subscription;
        if (subscription) {
          setDownloadable(false);
          setStreamable(false);
        }
        setSubscription(subscription);
        break;
        case "royalties":
          let royalties = !isUserRoyalties;
          if (isUserRoyalties === false) {
            openModal();
            modalContent(
              <OptionModal
                onCancel={() => {
                  closeModal();
                  modalContent();
                }}
                heading={"Enable User Royalties Share"}
                additionalText={
                  "Allow your supporters to invest and earn from your music sales and royalty streams."
                }
              >
                <Small>Note: Once enabled, it cannot be disabled.</Small>
                <HoverOption
                  onClick={() => {
                    setIsUserRoyalties(true);
                    if (royalties) {
                      setSubscription(false);
                      setStreamable(true);
                    }
                    if (isDownloadable && royalties) {
                      setCurrency("XRP");
                    }
                    closeModal();
                    modalContent();
                  }}
                >
                  OK
                </HoverOption>
              </OptionModal>
            );
          } else {
            setIsUserRoyalties(false);
          }
          /*  let royalties = !isUserRoyalties
            setIsUserRoyalties(royalties)
            if(royalties){
            setSubscription(false);
            setStreamable(true);}
            if(isDownloadable && royalties){
              setCurrency("XRP")
            } */
          break;
      case "mintable":
        mintable = !mintable;
        if (mintable) {
          toggleModal();
          modalContent(
            <OptionModal
              onCancel={() => {
                toggleModal();
                modalContent();
              }}
              heading={"Set Mintable?"}
            >
              <SongDetailsText>{`By clicking 'OK', you agree to upload your music \n 
        on IPFS and allow for it to be minted as an NFT on our platform.`}</SongDetailsText>
              <HoverOption
                onClick={async () => {
                  modalContent(<>
                  <LoadingScreen transparent={true}/></>)
                  await validateNFTStorageKey(song?.artists[0]?.artistId).then(data => {
                    if(data){
                      setMintable(true)
                      toggleModal()
                          modalContent()
                    }else{
                      modalContent(<>
                      <OptionModal heading={"NFT Storage API Key Not Found"}>
                        <HoverOption onClick={() => {
                          modalContent(<>
                            <NFTStorageTokenForm artistId={song?.artists[0]?.artistId} currentUser={currentUser?.uid} setMintable={setMintable}/>
                          </>)
                        }}>
                          Add API Key
                        </HoverOption>
                        <HoverOption onClick={() => {
                          toggleModal()
                          modalContent()
                        }}>
                         Cancel
                        </HoverOption>
                      </OptionModal>
                    </>)
                       
                    }
                  }).catch(err => {
                    setMintable(false)
                    modalContent(<>
                      <OptionModal heading={"Something Went Wrong. Try again"}>
                        <HoverOption onClick={() => {
                          toggleModal()
                          modalContent()
                        }}>
                          Close
                        </HoverOption>
                      </OptionModal>
                    </>)
                  })
                 

                }}
              >
                OK
              </HoverOption>
            </OptionModal>
          );
        } else {
          setMintable(false);
        }


        break;
      default:
        break;
    }
    if (downloadable === false && streamable === false) {
      setSubscription(true);
      setStreamable(true);
    }
  };

  const handleArtistInput = (e, type) => {
    const cWriter = currentWriter;
    const cProducer = currentProducer;
    const cComposer = currentComposer;
    const cPayout = currentPayoutArtist;

    switch (e.target.name) {
      case ARTIST_FORM_INPUT_TYPES.NAME:
        switch (type) {
          case ARTIST_FORM_TYPES.COMPOSER:
            if (e.target.value.trim() !== "") {
              delete cComposer?.errors?.name;
              setCurrentComposer({ ...cComposer, artistName: e.target.value });
            } else {
              setCurrentComposer({
                ...cComposer,
                errors: {
                  ...cComposer?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            if (e.target.value.trim() !== "") {
              delete cProducer?.errors?.name;
              setCurrentProducer({ ...cProducer, artistName: e.target.value });
            } else {
              setCurrentProducer({
                ...cProducer,
                errors: {
                  ...cProducer?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.WRITER:
            if (e.target.value.trim() !== "") {
              delete cWriter?.errors?.name;
              setCurrentWriter({ ...cWriter, artistName: e.target.value });
            } else {
              setCurrentWriter({
                ...cWriter,
                errors: {
                  ...cWriter?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            if (e.target.value.trim() !== "") {
              delete cPayout?.errors?.name;
              setCurrentPayoutArtist({
                ...cPayout,
                artistName: e.target.value,
              });
            } else {
              setCurrentPayoutArtist({
                ...cPayout,
                errors: {
                  ...cPayout?.errors,
                  name: "Artist name cannot be empty",
                  artistName: e.target.value,
                },
              });
            }
            break;
          default:
            break;
        }

        break;
      case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
        switch (type) {
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.WRITER:
            setCurrentWriter({ ...cWriter, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({ ...cPayout, fullName: e.target.value });
            //console.log(currentPayoutArtist);
            break;
          default:
            break;
        }
        break;
      case ARTIST_FORM_INPUT_TYPES.ID:
        switch (type) {
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({ ...cPayout, artistId: e.target.value });
            break;
          default:
            break;
        }
        break;
      case ARTIST_FORM_INPUT_TYPES.ADDRESS:
        switch (type) {
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.WRITER:
            setCurrentWriter({ ...cWriter, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({ ...cPayout, address: e.target.value });
            break;
          default:
            break;
        }
        break;
      case ARTIST_FORM_INPUT_TYPES.PAYOUT_PERCENTAGE:
        if(e.target.value?.length > 0 && e.target.value <= 100 ){
          setCurrentPayoutArtist({
            ...cPayout,
            payoutPercentage: parseInt(e.target.value) }
          );
          return
        }
        if(e.target.value?.length === 0){
          setCurrentPayoutArtist({
            ...cPayout,
            payoutPercentage: 0 
          })
        }

        break;

      default:
        break;
    }
  };

  const searchInput = (e, type) => {
    if (e.target.value.length > 0 && type !== ARTIST_FORM_TYPES.PAYOUT) {
      searchArtists(e.target.name, e.target.value).then((data) => {
        if (data && (!data.error || data !== false)) {
          //console.log(data);
          switch (type) {
            case ARTIST_FORM_TYPES.COMPOSER:
              setComposerSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(composers, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.PRODUCER:
              setProducerSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(producers, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.WRITER:
              setWriterSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(writers, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.PAYOUT:
              setPayoutSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(payoutArtists, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            default:
              break;
          }
        } else {
          //Error check
        }
        return;
      });
    }

    if (type === ARTIST_FORM_TYPES.PAYOUT && e.target.value.length > 0) {
      setPayoutSearch({
        type: e.target.name,
        artists: displaySelectedArtists().filter((artist) => {
          let a = false;

          switch (e.target.name) {
            case ARTIST_SEARCH_TYPES.NAME:
              if (
                artist?.artistName &&
                artist.artistName
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              ) {
                a = true;
              }

              break;
            case ARTIST_SEARCH_TYPES.FULL_NAME:
              if (
                artist?.fullName &&
                artist?.fullName
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              ) {
                a = true;
              }
              break;
            case ARTIST_SEARCH_TYPES.ADDRESS:
              if (
                artist?.address &&
                artist?.address
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              ) {
                a = true;
              }
              break;
            case ARTIST_SEARCH_TYPES.ID:
              if (
                artist?.artistId &&
                artist?.artistId
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              ) {
                a = true;
              }
              break;
            default:
              break;
          }
          if (a === true) {
            return artist;
          }
        }),
      });
    }
    if (e.target.value.length === 0) {
      switch (type) {
        case ARTIST_FORM_TYPES.COMPOSER:
          setComposerSearch();
          break;
        case ARTIST_FORM_TYPES.PRODUCER:
          setProducerSearch();
          break;
        case ARTIST_FORM_TYPES.WRITER:
          setWriterSearch();
          break;
        case ARTIST_FORM_TYPES.PAYOUT:
          setPayoutSearch();
          break;
        default:
          break;
      }
    }
  };

  //Filter out existing artists in list when searching
  const filterExistingArtist = (artists1, searchArtist) => {
    let artistExists = false;
    artists1.map((artist) => {
      if (artist?.artistId === searchArtist.artistId) {
        artistExists = true;
      }
    });
    return artistExists;
  };

  const displaySelectedArtists = () => {
    let allEArtists = [
      ...composers.filter((a) => a.exists),
      ...producers.filter((a) => a.exists),
      ...writers.filter((a) => a.exists),
    ];
    let allNEArtists = [
      ...composers.filter((a) => !a.exists),
      ...producers.filter((a) => !a.exists),
      ...writers.filter((a) => !a.exists),
    ];
    let allArtists = [
      ...getUniqueListBy(allEArtists, "artistId"),
      ...getUniqueListBy(allNEArtists, "artistartistName"),
    ];
    return allArtists;
  };

  //Add newly added (Non-existing) artists to searches
  const AddNEArtistsToSearch = (e) => {
    const a = [...composers, ...producers, ...writers];
    const nEArtists = getUniqueListBy(
      a.filter((artist) => !artist.exists),
      "artistName"
    );
    let filteredArtists = [];

    switch (e.target.name) {
      case ARTIST_FORM_INPUT_TYPES.ID:
        filteredArtists = nEArtists.filter((artist) =>
          artist.id.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.ADDRESS:
        filteredArtists = nEArtists.filter((artist) =>
          artist.address.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.NAME:
        filteredArtists = nEArtists.filter((artist) =>
          artist.artistName.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
        filteredArtists = nEArtists.filter((artist) =>
          artist.fullName.includes(e.target.value)
        );
        break;
      default:
        break;
    }
    if (filteredArtists.length > 0) {
      return filteredArtists;
    } else return [];
  };

  const validateUserRoyalties = () => {
    let e = errors
    let royaltyErrors
    delete e?.userRoyalties
    setErrors(e)
    if(getObjectLength(userRoyalties) > 0){
      if(userRoyalties?.ticker.trim().length < 3){
        royaltyErrors = {
          ...royaltyErrors,
          ticker: 'Invalid Ticker. Please enter a valid tick. min 3 characters'
        }
      }
      if(userRoyalties.percentage < 10 || userRoyalties.percentage > 50){
        royaltyErrors = {
          ...royaltyErrors,
          percentage: 'Invalid royalty share percentage. please input value between 10% - 50%'
        }
      }
      if (userRoyalties.shareReserve < 0 || userRoyalties.shareReserve > 50) {
        royaltyErrors = {
          ...royaltyErrors,
          reserve:
            "Invalid royalty share reserve. Please enter a valid reserve amount of 50 or less",
        };
      }
      if(getObjectLength(royaltyErrors) > 0){
        setErrors(prev => {
          return {
            ...prev,
            userRoyalty: royaltyErrors
          }
        })
        return false
      }
      return true
    }
    return false
  }

  return (
    <>
      <FormWrapper><Page1
        song={song}
        errors={errors}
        isCover={isCover}
        isDownloadable={isDownloadable}
        isExplicit={isExplicit}
        isMintable={isMintable}
        isStreamable={isStreamable}
        handleInput={handleInput}
        isUserRoyalties={isUserRoyalties}
        handleToggle={handleToggle}
        currency={currency}
        setCurrency={setCurrency}
        price={price}
        about={about}
        isrcRef={isrcRef}
        isrcType={isrcType}
        isrc={isrc}
        lyrics={lyrics}
      /></FormWrapper>
      <FormWrapper><Page2
        composers={composers}
        composerSearchRef={composerSearchRef}
        removeArtist={removeArtist}
        clearArtistForm={clearArtistForm}
        setComposerEditing={setComposerEditing}
        setCurrentComposer={setCurrentComposer}
        handleArtistInput={handleArtistInput}
        searchInput={searchInput}
        isUserRoyalties={!song?.isUserRoyalties && isUserRoyalties}
        isEditing={song?.userRoyalties}
    userRoyalties={userRoyalties}
    setUserRoyalties={setUserRoyalties}
    setErrors={setErrors}
        currentComposer={currentComposer}
        composerEditing={composerEditing}
        composerWalletAddressRef={composerWalletAddressRef}
        composerSearch={composerSearch}
        composerNameRef={composerNameRef}
        composerIdRef={composerIdRef}
        composerFullNameRef={composerFullNameRef}
        selectArtist={selectArtist}
        validateArtistInfo={validateArtistInfo}
        errors={errors}
        writerEditing={writerEditing}
        writers={writers}
        writerSearchRef={writerSearchRef}
        setCurrentWriter={setCurrentWriter}
        setWriterEditing={setWriterEditing}
        currentWriter={currentWriter}
        writerWalletAddressRef={writerWalletAddressRef}
        writerSearch={writerSearch}
        writerNameRef={writerNameRef}
        writerFullNameRef={writerFullNameRef}
        writerIdRef={writerIdRef}
        producerSearchRef={producerSearchRef}
        producers={producers}
        setCurrentProducer={setCurrentProducer}
        setProducerEditing={setProducerEditing}
        currentProducer={currentProducer}
        producerWalletAddressRef={producerWalletAddressRef}
        producerSearch={producerSearch}
        producerNameRef={producerNameRef}
        producerFullNameRef={producerFullNameRef}
        producerIdRef={producerIdRef}
        producerEditing={producerEditing}
        payoutArtists={payoutArtists}
        payoutArtistSearchRef={payoutArtistSearchRef}
        setCurrentPayoutArtist={setCurrentPayoutArtist}
        setPayoutEditing={setPayoutEditing}
        currentPayoutArtist={currentPayoutArtist}
        payoutArtistFullNameRef={payoutArtistFullNameRef}
        payoutArtistWalletAddressRef={payoutArtistWalletAddressRef}
        payoutSearch={payoutSearch}
        payoutArtistNameRef={payoutArtistNameRef}
        payoutArtistIdRef={payoutArtistIdRef}
        artistPayoutRef={artistPayoutRef}
        payoutEditing={payoutEditing}
      /></FormWrapper>
      <FormWrapper>
        <Page3 setVideoFile={setVideoFile}
        setVisualizerFile={setVisualizerFile}
        videoFile={videoFile}
        visualizerFile={visualizerFile}
        song={song}/>
      </FormWrapper>
      <div style={{
        flexDirection: "row", 
      display: "flex", 
      width: "100%", 
      alignContent:"center", 
      alignItems:"center", 
      justifyContent: "space-around"}}>
        <Button1 onClick={() => {
        /* //console.log({
        isrc: isrc,
        lyrics: lyrics?.trim().length > 0 ? lyrics : undefined,
        about: about?.trim()?.length > 0 ? about: undefined,
        isCover,
        isDownloadable,
        explicit: isExplicit,
        isMintable,
        price: isDownloadable ? price : undefined,
        currency: isDownloadable ? currency : undefined,
        songCredits: {
          writers,
          producers,
          composers
        },
      royalties: payoutArtists}) */
      if((visualizerFile?.progress > 0 && visualizerFile?.progress < 100) || (videoFile?.progress > 0 && videoFile?.progress < 100)){
       addAlert({
            title: "Uploading Visuals",
            message: 'Visuals still uploading...',
            type: ALERT_TYPES.WARNING
          })
          return
      }
      if((isUserRoyalties && !validateUserRoyalties())){
        return 
      }
        else{
          submitChanges(CONTENT_TYPES.SONG, 
            { 
              isrc: isrc,
              lyrics: lyrics?.trim().length > 0 ? lyrics : undefined,
              about: about?.trim()?.length > 0 ? about: undefined,
              isCover,
              isDownloadable,
              explicit: isExplicit,
              isMintable,
              isUserRoyalties,
              userRoyalties: (!song?.isUserRoyalties && isUserRoyalties) ? userRoyalties : undefined,
              video: getObjectLength(videoFile) > 0 ? {
                url: videoFile?.url,
                duration: videoFile?.duration,
                ref: videoFile?.ref
              } : undefined,
              visualizer: getObjectLength(visualizerFile) > 0 ? {
                url: visualizerFile?.url,
                duration: visualizerFile?.duration,
                ref: visualizerFile?.ref
              } : undefined,
              price: isDownloadable ? price : undefined,
              currency: isDownloadable ? currency : undefined,
              songCredits: {
                writers,
                producers,
                composers
              },
            royalties: payoutArtists})
        }
      }}>Submit Changes</Button1>
      <Button1
        onClick={() => {
          navigate(`/song/${song?.songId}`, {replace: true});
        }}
      >
        Cancel
      </Button1></div>
    </>
  );
};

export default SongEdit;
