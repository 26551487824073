import React from 'react'
import { Container, CheckboxWrapper, Label } from './CheckboxElements'
import {ImRadioUnchecked, ImRadioChecked} from 'react-icons/im'

export default function Checkbox({
    customCheckedIcon, 
    customUncheckedIcon, 
    isChecked, 
    label, 
    onClick, 
    labelPosition}) {
  return (
    <>
        <Container onClick={onClick} flexDirection={labelPosition}>
            <CheckboxWrapper onClick={onClick}>
                {(isChecked) ? 
                customCheckedIcon ? customCheckedIcon : <ImRadioChecked/>
                    :
                customUncheckedIcon ?  customUncheckedIcon : <ImRadioUnchecked/>}
            </CheckboxWrapper>
            <Label>{label}</Label>
        </Container>
    
    </>
  )
}
