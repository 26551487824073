import React, { useState } from "react";
import {
  FormWrapper,
  SongDetailsSection,
  SongDetailsSectionTitle,
} from "../MusicUploadsFormElements";
import {
  FormButton,
  FormButton1,
  FormInput,
  FormInput1,
  FormLabel,
  FormWrapper as FW,
} from "../../CustomForm/CustomFormElements";
import { SmallText } from "../../NFTCollectionCreator/NFTCollectionCreatorElements";
import { ChipList } from "../../Chip/ChipElements";
import Chip from "../../Chip";
import { convertTimeToSeconds } from "../../../utils";

const Page3 = ({ timestamps, setTimestamps, deleteTimestamp, duration }) => {
  const [timestampLabel, setTimestampLabel] = useState("");
  const [timestampSeconds, setTimestampSeconds] = useState();
  const [timestampMinutes, setTimestampMinutes] = useState();
  const [timestampHours, setTimestampHours] = useState();
  const [timestampDays, setTimestampDays] = useState();
  const [errors, setErrors] = useState({})

  const handleInput = (e) => {
    let err = {}
    switch (e.target.name) {
      case "timestampLabel":
        delete e?.label
        if (e.target.value.length < 50) {
          setTimestampLabel(e.target.value);
        }else{
            err ={
                ...err,
                label: 'label cannot be more than 50 characters'
            }
        }
        break;
      case "timestampHours":
        delete e?.time
        if (e.target.value < 23 && e.target.value >= 0) {
          setTimestampHours(e.target.value);
        }else{
            err ={
                ...err,
                time: 'Invalid time input'
            }
        }
        break;
      case "timestampMinutes":
        if (e.target.value < 60 && e.target.value >= 0) {
          setTimestampMinutes(e.target.value);
        }else{
            err ={
                ...err,
                time: 'Invalid time input'
            }
        }
        break;
      case "timestampSeconds":
        if (e.target.value < 60 && e.target.value >= 0) {
          setTimestampSeconds(e.target.value);
        }else{
            err ={
                ...err,
                time: 'Invalid time input'
            }
        }
        break;

      default:
        break;
    }
    setErrors(err)
  };

  const validateInput = () => {
    setErrors({})
    let hours;
    let minutes;
    let seconds;
    let time;
    if (
      (timestampLabel?.trim().length < 50 && timestampLabel?.trim().length > 0) &&
      (timestampHours < 23 || !timestampHours) &&
      (timestampMinutes <= 59 || !timestampMinutes) &&
      (timestampSeconds <= 59 || !timestampSeconds)
    ) {
      if (timestampHours < 10 || !timestampHours) {
        if (!timestampHours|| parseInt(timestampHours) === 0) {
          hours = "00";
        } else {
          hours = `${timestampHours.length > 1 ? timestampHours.substring(timestampHours.length - 1): `0${timestampHours}`}`;
        }
      } else {
        hours = timestampHours;
      }
      if (timestampMinutes < 10 || !timestampMinutes) {
        if (!timestampMinutes || parseInt(timestampMinutes) === 0) {
          minutes = "00";
        } else {
          minutes = `${timestampMinutes.length > 1 ? timestampMinutes.substring(timestampMinutes.length - 1): `0${timestampMinutes}`}`;
        }
      } else {
        minutes = timestampMinutes;
      }
      if (timestampSeconds < 10 || !timestampSeconds) {
        if (!timestampSeconds|| parseInt(timestampSeconds) === 0) {
          seconds = "00";
        } else {
          seconds = `${timestampSeconds.length > 1 ? timestampSeconds.substring(timestampSeconds.length - 1): `0${timestampSeconds}`}`;
        }
      } else {
        seconds = timestampSeconds;
      }
      time = `${hours}:${minutes}:${seconds}`;
      if(convertTimeToSeconds(time) <= duration){
        setTimestamps(timestampLabel, time);
      resetValues();
      }else{
        setErrors({message: 'Invalid Timestamp information entered'})
      }
      
      return;
    }
    setErrors({message: 'Invalid Timestamp information entered'})

    return;
  };

  const resetValues = () => {
    setTimestampDays(NaN);
    setTimestampHours(NaN);
    setTimestampMinutes(NaN);
    setTimestampSeconds(NaN);
    setTimestampLabel("");
  };

  return (
    <SongDetailsSection>
      <SongDetailsSectionTitle>Timestamps</SongDetailsSectionTitle>
      <SmallText>Optionally add timestamps to your podcast episode</SmallText>
      <FW>
        <FormLabel>Timestamp Label</FormLabel>
        <FormInput
          name="timestampLabel"
          placeholder="Timestamp Label"
          value={timestampLabel}
          onChange={handleInput}
        />
        {errors?.label && <SmallText error>{errors?.label}</SmallText>}
        <SmallText>Input a title for the timestamp</SmallText>
      </FW>
      <FW>
        <FormWrapper>
          <FormInput1
            name="timestampHours"
            placeholder="HH"
            type={'Number'}
            min={0}
            max={23}
            value={timestampHours}
            onChange={handleInput}
          />
          <div style={{ margin: "0 2%" }}>:</div>
          <FormInput1
            name="timestampMinutes"
            placeholder="MM"
            type={'Number'}
            min={0}
            max={59}
            value={timestampMinutes}
            onChange={handleInput}
          />
          <div style={{ margin: "0 2%" }}>:</div>
          <FormInput1
            name="timestampSeconds"
            placeholder="SS"
            type={'Number'}
            min={0}
            max={59}
            value={timestampSeconds}
            onChange={handleInput}
          />
        </FormWrapper>
        {errors?.time && <SmallText error>{errors?.time}</SmallText>}
        <SmallText>Input the start time of timestamp</SmallText>
      </FW>
      {errors?.message && <FW><SmallText error>{errors?.message}</SmallText></FW>}
      <FormButton1  onClick={validateInput}>Add Timestamp</FormButton1>
      {timestamps?.length > 0 && (
        <FW>
          <ChipList>
            {timestamps?.map((timestamp, index) => {
              return (
                <Chip
                  closeButton
                  label={`${timestamp?.time} - ${timestamp?.label}`}
                  background={'transparent'}
                  onChipClick={() => {setTimestampLabel(timestampLabel)}}
                  onDelete={() => deleteTimestamp(index)}
                />
              );
            })}
          </ChipList>
        </FW>
      )}
    </SongDetailsSection>
  );
};

export default Page3;
