import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePodcaster from "../../hooks/usePodcaster";
import PodcasterProfileContent from "./PodcasterProfileContent";
import PodcasterProfileHeader from "./PodcasterProfileHeader";
import { PodcasterProfileWrapper } from "./PodcasterProfileProfileElements";
import { PAYOUT_TYPE } from "../../utils/Enum";
import { defaultPaymentChannel, setPaymentChannels, setSwərl } from "../SonarMuseWebMonetization";
import { updateWebPageIcon } from "../../utils/MusicUtils";

export const PodcasterProfile = ({ podcaster }) => {
  const { getPodcasterProfile } = usePodcaster({});
  const [podcasterProfile, setProfile] = useState({});
  const [isLoaded, setLoaded] = useState(false);
  const navigate  = useNavigate();

  useEffect(() => {
    setLoaded(false)
    getProfile();
    return () => {}
  }, [podcaster]);

  const getProfile = async () => {
    return await getPodcasterProfile(podcaster)
      .then((profile) => {
        if (profile) {
          updateWebPageIcon(profile?.artistSettings?.profilePictureType === "custom" &&
          profile?.profilePicture
            ? profile?.profilePicture
            : profile?.photoUrl
            ? profile.photoUrl + "?s=600&d=mp"
            : 'https://sonarmuse.org/favicon.ico')
          setProfile(profile);
          if(profile?.profile?.paymentChannel){
            setSwərl(PAYOUT_TYPE.PODCASTER, podcaster)
            setPaymentChannels(PAYOUT_TYPE.PODCASTER,{[profile?.profile?.paymentChannel]: 100})
          }else{
            defaultPaymentChannel()
          }
        }
        setLoaded(true);
        return;
      })
      .catch((err) => {
        navigate(-1);
      });
  };

  return (
    <PodcasterProfileWrapper>
      <PodcasterProfileHeader
        podcasterId={podcaster}
        profile={podcasterProfile?.profile}
        wallets={podcasterProfile?.gravatarProfile?.currency ? Object.values(podcasterProfile?.gravatarProfile?.currency): undefined}
        gravatarProfile={podcasterProfile?.gravatarProfile}
      />
      <PodcasterProfileContent
        podcasterId={podcaster}
        getProfile={getProfile}
        profile={podcasterProfile?.profile}
        gravatarProfile={podcasterProfile?.gravatarProfile}
        about={{
          photos: podcasterProfile?.gravatarProfile?.gallery,
          accounts: podcasterProfile?.gravatarProfile?.verified_accounts,
          bio: podcasterProfile?.profile?.podcasterSettings?.bioType === "custom" ? podcasterProfile?.profile?.bio : podcasterProfile?.gravatarProfile?.description,
        }}

      />
    </PodcasterProfileWrapper>
  );
};
