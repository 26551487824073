import React from 'react'
import { FaBtc, FaEthereum } from 'react-icons/fa';
import { MdGeneratingTokens } from 'react-icons/md';
import {SiXrp, SiDogecoin, SiStellar, SiLitecoin} from 'react-icons/si'
import {TbCurrencySolana} from 'react-icons/tb'

function CryptoWalletIcons({type}) {

    const displayIcon = () => {
        switch (type) {
            case 'btc':
                return <FaBtc/>
                break;
            case 'ltc':
                return <SiLitecoin/>
                break;
            case 'doge':
                return <SiDogecoin/>
                break;
            case 'eth':
                return <FaEthereum/>
                break;
            case 'xrp':
                return <SiXrp/>
                break;
            case 'xlm':
                return <SiStellar/>
                break;
            case 'sol':
                return <TbCurrencySolana/>
                break;
        
            default:
                return <MdGeneratingTokens/>
                break;
        }
    }
  return (
    <><div style={{marginRight: '2%'}}>{displayIcon()}</div></>
  )
}

export default CryptoWalletIcons