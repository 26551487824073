import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import useMusic from "../../../hooks/useMusic";
import { useUser } from "../../../hooks/useUser";
import {
    storage,
    storageRef,
    uploadBytes,
    getDownloadUrl,
  } from "../../Authorization/firebase";
import { ALERT_TYPES, CONTENT_TYPES } from "../../../utils/Enum";
import AlbumArt from "../../AlbumArt";
import { Button1, CustomButton } from "../../ButtonElement";
import ContentList from "../../ContentList";
import SongList, { DraggableSongList } from "../../ContentList/SongList";
import ContentWrapper from "../../ContentWrapper";
import {
  FormButton1,
  FormInput1,
  FormLabel,
  FormText,
  FormWrapper,
} from "../../CustomForm/CustomFormElements";
import { getRandomColor } from "../../HexToRGBA";
import { useModal } from "../../Modal/ModalContext";
import { Theme } from "../../WebsiteThemeElements";
import { SongDetailsSectionTitle } from "../../MusicUploadsForm/MusicUploadsFormElements";
import { newlineFormatter, stringFormatter, validateAddress } from "../../../utils";
import { SmallText } from "../../NFTCollectionCreator/NFTCollectionCreatorElements";
import { ChipList } from "../../Chip/ChipElements";
import Chip from "../../Chip";
import { useAlert } from "../../Alert/AlertContext";
import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import Alert from "../../Alert";

const PlaylistEdit = ({ playlist, submitChanges}) => {
  const location = useLocation();
  const navigate  = useNavigate();
  const { currentUser } = useAuth();
  const [songs, setSongs] = useState(playlist?.songs?.length > 0 ? playlist?.songs : [])
  const [playlistName, setName] = useState(
    playlist?.playlistName ? playlist?.playlistName : undefined
  );
  const [about, setAbout] = useState(
    playlist?.about?.length > 0 ? playlist?.about : undefined
  );
  const { addPlaylist, deletePlaylist } = useMusic({});
  const { toggleModal, modalContent } = useModal();
  const {addAlert} = useAlert()
  const { addSongToPlaylist, updatePlaylist } =
    useUser({ id: currentUser?.uid });
  const [color, setColor] = useState(
    playlist?.color?.length > 0 ? playlist?.color : getRandomColor()
  );
  const [isCollabOpen,setOpenCollab] = useState(false)
  const [currentCollaborator,setCurrentCollaborator] = useState()
  const [collaborators, setCollaborators] = useState(playlist?.collaborators?.length > 0 ? playlist?.collaborators : [])
  const [coverArt, setCoverArt] = useState(
    playlist?.playlistArt
      ? {
          url: playlist?.playlistArt,
          ref: playlist?.playlistArtRef,
          progress: 100,
        }
      : {}
  );
  const playlistNameRef = useRef();
  const theme = Theme({});

  useEffect(() => {
    return () => {}
  }, [])
  

  const handleInput = (e) => {
    switch (e.target.name) {
      case 'cover art':
        let uploadTask 
        let currArt = coverArt;
        if(e.target.value && e.target.files[0]){
          var reader = new FileReader();
     
      //Read the contents of Image File.
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e1) {
      
      //Initiate the JavaScript Image object.
      var image = new Image();
      
      //Set the Base64 string return from FileReader as source.
      image.src = e1.target.result;
      
      
      
      setCoverArt({ file: e.target.files[0] });
      image.onload = function () {
      var height = this.height;
      var width = this.width;
      //console.log(`width: ${width} - height: ${height} `)
      if (height < 1600 || width < 1600) {
        alert("Height and Width must not be less than 1600px.");
        setCoverArt(currArt)
        return false;
      }
      
       //let artBlob = convertFileToBytes(e.target.files[0]) 
       let imageFileName
       if((!coverArt.name && !coverArt?.ref)){
       imageFileName = `${Math.round(
        Math.random() * 10000000000000
      )}.${
        e.target.files[0].name.split(".")[
          e.target.files[0].name.split(".").length - 1
        ]
      }`;
      setCoverArt(prev => {return {...prev, name: imageFileName}})
      uploadTask = uploadBytes(
        storageRef(
          storage,
          `users/${currentUser?.uid}/${imageFileName}`        
          ),
        e.target.files[0],
        { contentType: e.target.files[0].type }
      );
    }else{
        imageFileName = coverArt.name
        uploadTask = uploadBytes(
          storageRef(
            storage,
            coverArt?.ref
          ),
          e.target.files[0],
          { contentType: e.target.files[0].type }
        );
      }
      

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setCoverArt((prev) => {
            return { ...prev, progress: progress };
          });

          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              //console.log("Upload is paused");
              break;
            case "running":
              //console.log("Upload is running");
              break;
            default:
              //console.log("Upload is " + progress + "% done");
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              setCoverArt(currArt);
              // User canceled the upload
              break;
            // ...
            case "storage/unknown":
              setCoverArt(currArt);
              // Unknown error occurred, inspect error.serverResponse
              break;
            default:
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadUrl(uploadTask.snapshot.ref).then((downloadURL) => {
            //console.log("File available at", downloadURL);
            setCoverArt((prev) => {
              return { ...prev, url: downloadURL, ref: uploadTask.snapshot.ref.fullPath};
            });
          });
        }
      );}
      };
        }
        break;
      case 'color':
        setColor(e.target.value)
        break;

      case 'name':
        setName(e.target.value)
        break;
      case 'about':
        setAbout(newlineFormatter(e.target.value))
        break;
      case 'collaborators':
          setCurrentCollaborator(e.target.value)
          break;
      default:
        break;
    }
}
  return (
    <>
                    

      <FormWrapper>
        <SongDetailsSectionTitle>Details</SongDetailsSectionTitle>
      {currentUser?.uid === playlist?.curator?.curatorId && <FormWrapper>
                  <FormLabel>Playlist Name</FormLabel>
                  <FormInput1
        name={"name"}
        placeholder="Playlist Name"
        value={playlistName}
        onChange={handleInput}
      />
                </FormWrapper>}
      <FormWrapper>
                  <FormLabel>About</FormLabel>
                  <FormInput1
        name={"about"}
        placeholder="About playlist"
        value={stringFormatter(about)}
        onChange={handleInput}
      />
                </FormWrapper>

      <div style={{ display: "flex", width: "100%", padding: "2%" }}>
        <FormLabel>Cover Art</FormLabel>
        <label for="cover art" style={{ cursor: "pointer" }}>
          {!coverArt?.url && coverArt?.progress < 100 && (
            <span>Uploading... {coverArt?.progress}</span>
          )}
          {!coverArt?.url &&
            (!coverArt?.progress || coverArt?.progress === 0) && (
              <AlbumArt
                background={color}
                size={"25rem"}
                alt={"Upload Cover Art"}
              />
            )}
          {coverArt?.url && coverArt?.progress === 100 && (
            <AlbumArt
              background={color}
              size={"25rem"}
              albumArt={coverArt?.url}
              alt={"cover art"}
            />
          )}
        </label>
        <FormInput1
          style={{ display: "none" }}
          id="cover art"
          name="cover art"
          onChange={handleInput}
          type={"file"}
        />
      </div>
      <div style={{ display: "flex", width: "100%", padding: "2%" }}>
        <FormText>
          <label style={{ cursor: "pointer" }} for="color">
            Alt Cover Art (Color)
          </label>
        </FormText>
        <label
          for="color"
          style={{
            display: "flex",
            flex: "1 1 100%",
            width: "100%",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              background: color,
              width: "2rem",
              height: "2rem",
              display: "flex",
              borderRadius: "0.2rem",
            }}
          ></div>
        </label>
        <FormInput1
          style={{ display: "none" }}
          id="color"
          name="color"
          onChange={handleInput}
          type={"color"}
          value={color}
        />
      </div>
      <FormText background={theme.secondaryColor}>
        Background color placeholder if the cover art does not load
      </FormText></FormWrapper>
      {currentUser?.uid === playlist?.curator?.curatorId && 
      <FormWrapper>
              <FormLabel>Playlist Collaborators</FormLabel>
              <ChipList>
                {collaborators?.map((c,index) => {
                  return <Chip label={`${c?.substring(0, 8)}...${c?.substring(c?.length -5,c?.length)}`} closeButton={true} onDelete={() => {
                    setCollaborators(collaborators.filter((col, i) => i !== index))
                  }}/>
                } )}
              </ChipList>
             {!isCollabOpen && <HoverOption onClick={() => setOpenCollab(true)}>Add Collaborator</HoverOption>}
              {isCollabOpen && <><FormInput1 name={"collaborators"} placeholder='Enter collaborator wallet address' value={currentCollaborator} onChange={handleInput}/>
              <FormButton1 onClick={() => {
                let users = collaborators
                if(currentCollaborator !== currentUser?.uid){
                if(validateAddress(currentCollaborator)){
                if(!users.includes(currentCollaborator)){
                  users.push(currentCollaborator)
                }else{
                  addAlert({
                    message: "User collaborator already exists",
                    type: ALERT_TYPES.DANGER,
                  })
                }
                setCollaborators(users)
                setCurrentCollaborator()
                setOpenCollab(false)
                return
              }else{
                addAlert({
                  title: "Invalid User",
                  message: "Wallet Address is invalid",
                  type: ALERT_TYPES.DANGER,
                })}
              }else{
                addAlert({
                  title: "Invalid User",
                  message: "You cannot be a collaborator of your own playlist",
                  type: ALERT_TYPES.DANGER,
                })
                }}}>Add User</FormButton1>
              <CustomButton onClick={() => {
                setCurrentCollaborator() 
                setOpenCollab(false)}}>Cancel</CustomButton></>}
                              <SmallText>Add users to collaborate to your playlist by wallet address</SmallText>

            </FormWrapper>}
      <FormWrapper><ContentWrapper heading={"Songs"}>
        <DraggableSongList content={songs} setContent={setSongs} deleteContent={true} />
      </ContentWrapper></FormWrapper>
      <div style={{
        flexDirection: "row", 
      display: "flex", 
      width: "100%", 
      alignContent:"center", 
      alignItems:"center", 
      justifyContent: "space-around"}}><Button1 onClick={() => {
        if(playlistName?.trim()?.length > 0 && 
                playlistName?.trim() !== '' &&  (!coverArt?.progress || (coverArt?.progress === 100 && coverArt?.url))) {
        submitChanges(CONTENT_TYPES.PLAYLIST, {
            color: color,
            songs: songs?.length > 0 ? songs.map(s => s?.songId) : [],
            playlistName: playlistName,
            playlistArt: coverArt?.url,
            playlistArtRef: coverArt?.ref,
            collaborators: collaborators,
            about: about
        })}else{
          addAlert({message: "Could not submit playlist. Review information", type: ALERT_TYPES.DANGER, title: "Playlist Edit Error" })
        }
      }}>Submit Changes</Button1>
      <Button1
        onClick={() => {
          navigate(`/playlist/${playlist?.playlistId}`, {replace: true});
        }}
      >
        Cancel
      </Button1></div>
    </>
  );
};

export default PlaylistEdit;
