import React from 'react'
import OptionModal from '../OptionModal'
import { HoverOption } from '../HoverMenu/HoverMenuElements'
import { copyTextToClipboard, generateQRCode } from '../../utils'
import { useModal } from '../Modal/ModalContext'
import { ProfileQRCode } from '../Profile/ProfileElements'
import { useAlert } from '../Alert/AlertContext'
import { ALERT_TYPES } from '../../utils/Enum'
import { FaClipboard } from 'react-icons/fa'
import CryptoWalletIcons from '../CryptoWalletIcons'

const TipWallets = ({wallets}) => {
    const {toggleModal, modalContent} = useModal()
    const { addAlert } = useAlert();
    return (
    <OptionModal heading={`Additional Tipping Wallets`}>{wallets?.filter(wallet => wallet?.type !== 'xrp')?.map((wallet, index) => {
        return(
          <HoverOption onClick={async() => {
            let qr = await generateQRCode(wallet.value)
            modalContent(<OptionModal heading={`${wallet?.type.toUpperCase()} Wallet`} additionalText={<small>{wallet.value}</small>}>
                {qr && <ProfileQRCode src={qr}/>}
                <HoverOption onClick={() => {
                  copyTextToClipboard(wallet?.value)
                  addAlert({
                    title: "Copied To Clipboard",
                    type: ALERT_TYPES.INFO,
                    secs: 10,
                  })
                }}><FaClipboard/>Copy to Clipboard</HoverOption>
                <HoverOption onClick={() => {
                  toggleModal()
                  modalContent()
                }}>OK</HoverOption>
            </OptionModal>)
          }}><CryptoWalletIcons type={wallet?.type}/>{wallet?.type?.toUpperCase()}</HoverOption>
        )
      })}</OptionModal>
  )
}

export default TipWallets