import React from 'react'
import { Container } from './MusicEditPageElements'
import AlbumEdit from './AlbumEdit'
import PlaylistEdit from './PlaylistEdit'
import PodcastEdit from './PodcastEdit'
import SongEdit from './SongEdit'
import CollectionEdit from './CollectionEdit'
import { useUser } from '../../hooks/useUser'
import { useAuth } from '../../contexts/AuthContext'
import { CONTENT_TYPES, TRANSACTION_OPTIONS } from '../../utils/Enum'
import useMusic from '../../hooks/useMusic'
import { useNavigate, useLocation } from 'react-router-dom'
import QRCodeForm from '../QRCodeForm'
import { useModal } from '../Modal/ModalContext'
import useArtist from '../../hooks/useArtist'
import RoyaltyEdit from './RoyaltyEdit'
import { getArtistsNames2, getPodcastersNames2 } from '../../utils'

const MusicEditPage = ({type, content, setUpdated}) => {
    const {currentUser} = useAuth()
    const {validateNFTStorageKey  } = useArtist({});
    const navigate  = useNavigate()
    const location = useLocation()
    const {updatePlaylist } =
    useUser({ id: currentUser?.uid });
    const { toggleModal, modalContent, openModal, closeModal } = useModal();

    const onSuccess = () => {
        closeModal()
        modalContent()
        const params = new URLSearchParams({refresh: true });
        navigate(`${location.pathname}?refresh=true`,{
            replace:true},
          );    }

    const onFail = () => {
        closeModal()
        modalContent()
    }

    const onCancel = () => {
            closeModal()
            modalContent()
        }

    const submitChanges = (type, changes) =>
    {
        const params = new URLSearchParams({refresh: true });

        switch (type) {
            case CONTENT_TYPES.ALBUM:
                openModal()
                modalContent(<>
                    <QRCodeForm
            heading={`Updating ${content?.albumName} by ${content?.artists[0]?.artistName}`}
            body={{
                albumId: content?.albumId,
                albumInfo: changes,
                sender: content?.uploadedBy,
                instruction: `User is signing transaction to update album ${content?.albumName} by ${content?.artists[0]?.artistName}`
            }}
            cancelQr={onCancel}
            onSuccess={onSuccess}
            onFail={onFail}
            type={TRANSACTION_OPTIONS.UPDATE_ALBUM}
          ></QRCodeForm>
                </>)
                break;
            case CONTENT_TYPES.SONG:
                openModal()
                modalContent(<>
                    <QRCodeForm
            heading={`Updating ${content?.songName} by ${content?.artists[0]?.artistName}`}
            body={{
                songId: content?.songId,
                songInfo: changes,
                sender: content?.uploadedBy,
                instruction: `User is signing transaction to update song ${content?.songName} by ${content?.artists[0]?.artistName}`

            }}
            cancelQr={onCancel}
            onSuccess={onSuccess}
            onFail={onFail}
            type={TRANSACTION_OPTIONS.UPDATE_SONG}
          ></QRCodeForm>
                </>)
                break;
            case CONTENT_TYPES.PLAYLIST:
                return updatePlaylist(currentUser?.uid, content?.playlistId, changes).then((data) => {
                    if(data){
                        //setUpdated(true)
                        navigate(`${location.pathname}?refresh=true`,{
                            replace:true},
                          );

                        return
                    }
                    return
                }).catch(err => {
                    console.error(err)
                    return 
                })
            case CONTENT_TYPES.COLLECTION:
                openModal()
                modalContent(<>
                    <QRCodeForm
            heading={`Updating ${content?.name} by ${content?.issuer}`}
            body={{
                collectionId: content?.collectionId,
                collectionInfo: changes,
                sender: content?.uploadedBy,
                instruction: `User is signing transaction to update collection ${content?.name} by ${content?.issuer}`
            }}
            cancelQr={onCancel}
            onSuccess={onSuccess}
            onFail={onFail}
            type={TRANSACTION_OPTIONS.UPDATE_COLLECTION}
          ></QRCodeForm>
                </>)
                break;
            case CONTENT_TYPES.EPISODE:
                openModal()
                modalContent(<>
                    <QRCodeForm
            heading={`Updating ${content?.episodeName} by ${content?.podcasters[0]?.podcasterName}`}
            body={{
                episodeId: content?.episodeId,
                episodeInfo: changes,
                sender: content?.uploadedBy,
                instruction: `User is signing transaction to update episode ${content?.episodeName} by ${content?.podcasters[0]?.podcasterName}`
            }}
            cancelQr={onCancel}
            onSuccess={onSuccess}
            onFail={onFail}
            type={TRANSACTION_OPTIONS.UPDATE_EPISODE}
          ></QRCodeForm>
                </>)
                break;
                case CONTENT_TYPES.ROYALTY_SHARE:
                    openModal()
                modalContent(<>
                    <QRCodeForm
            heading={`Updating Regium Share ${content?.ticker} by ${content?.musicDetails?.artists?.length > 0 ? getArtistsNames2(content?.musicDetails?.artists) : getPodcastersNames2(content?.musicDetails?.podcasters)}`}
            body={{
                royaltyId: content?.royaltyId,
                royaltyInfo: changes,
                sender: content?.musicDetails?.uploadedBy,
                instruction: `User is signing transaction to update Regium Share ${content?.ticker} by ${content?.musicDetails?.artists?.length > 0 ? getArtistsNames2(content?.musicDetails?.artists) : getPodcastersNames2(content?.musicDetails?.podcasters)}`
            }}
            cancelQr={onCancel}
            onSuccess={onSuccess}
            onFail={onFail}
            type={TRANSACTION_OPTIONS.UPDATE_ROYALTY_SHARE}
          ></QRCodeForm>
                </>)
                    break
        
            default:
                return
        }
    }


  return (
    <Container>{type === CONTENT_TYPES.ALBUM && 
        <AlbumEdit validateNFTStorageKey={validateNFTStorageKey} album={content} submitChanges={submitChanges}>
        </AlbumEdit>}
    {type === CONTENT_TYPES.SONG && 
        <SongEdit validateNFTStorageKey={validateNFTStorageKey} song={content} submitChanges={submitChanges}>
        </SongEdit>}
    {type === CONTENT_TYPES.PLAYLIST && 
        <PlaylistEdit playlist={content} submitChanges={submitChanges}>
        </PlaylistEdit>}
    {type === CONTENT_TYPES.COLLECTION && 
        <CollectionEdit collection={content} submitChanges={submitChanges}>
        </CollectionEdit>}
    {type === CONTENT_TYPES.EPISODE && 
        <PodcastEdit episode={content} submitChanges={submitChanges}>
        </PodcastEdit>}
    {type === CONTENT_TYPES.ROYALTY_SHARE && 
        <RoyaltyEdit royalty={content} submitChanges={submitChanges}/>
        }
    </Container>
  )
}

export default MusicEditPage