import styled from 'styled-components'
import React from 'react'
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

export const MEDIA_SCREEN = { //Screen sizes for devices
    MOBILE: '480',
    TABLETS: '768',
    LAPTOPS: '1024',
    DESKTOPS: '1025',
    TV: '1500'
  }

export const NavigationContainer = styled.div`
display: flex;
position: fixed;
top: 0%;
margin-right: 20%;
z-index: 80;
gap: 16px;
padding: 2%;
pointer-events: auto;
white-space: nowrap;
@media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px)
{
    margin-right: 25%;
    top: 8%;
}
@media screen and (min-width: ${MEDIA_SCREEN.TV}px)
{
    margin-right: 25%;
    top: 8%;
}
`
export const NavigationButton = styled.div`
    font-size: 2rem;
    border-radius: 100%;
    opacity: 0.7;
    cursor: pointer;
    color: ${props => props.theme.secondaryBg};
    &:hover{
        transition: all 0.2s ease-in-out;
        transform: scale(1.1);
        opacity: 1;
        color: ${props => props.theme.accentColor};
    }
    display: flex;
    box-sizing: border-box;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px)
    {
        font-size: 1.5rem;
    }

`



export const NavigationButtons = () => {
    const navigate  = useNavigate()
  return (
    <NavigationContainer>
        <NavigationButton onClick={() => navigate(-1)}><FaChevronCircleLeft/></NavigationButton>
        <NavigationButton onClick={() => navigate(+1)}><FaChevronCircleRight/></NavigationButton>
    </NavigationContainer>
  )
}
