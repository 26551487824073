import React, { useState } from 'react'
import { MetricsContainer, MetricsContent, MetricsContentHeader, MetricsContentText, MetricsDataWrapper, MetricsHeader } from './KeyMetricsElements'
import BarGraph, { NoLabelBarGraph } from '../Bar'
import ToggleButton from '../../ToggleButton'
import { FaChartBar, FaChartLine } from 'react-icons/fa'
import { NoLabelLineGraph } from '../Line'

const KeyMetrics = ({data}) => {
    const [toggle, setToggle] = useState('bar')

  return (
    <><MetricsContainer>
        <MetricsHeader></MetricsHeader>
        <div style={{display:'flex', alignSelf: 'flex-end', padding: '2%'}}>
        <ToggleButton toggle={toggle === 'line'} toggleClick={() => toggle === 'line' ? setToggle('bar') : setToggle('line') } onText={''} customOffIcon={<FaChartBar size={20} />} customOnIcon={<FaChartLine size={20} />} offText={''}/>
    </div>
        <MetricsDataWrapper size={data.length}>
            {
                data.map((d, index) => {
                    return (
                       <MetricsContent key={index} onClick={d?.onClick}>
                {d?.heading && <MetricsContentHeader>{d?.heading}</MetricsContentHeader>}
                {d?.additionalText && <MetricsContentText>{d?.additionalText}</MetricsContentText>}
                {toggle === 'bar' && <NoLabelBarGraph data={d}/>}
                {toggle === 'line' && <NoLabelLineGraph data={d}/>}
            </MetricsContent> 
                    )
                })
            }
            
        </MetricsDataWrapper>
        </MetricsContainer></>
  )
}

export default KeyMetrics