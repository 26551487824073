import React, { useState } from 'react'
import { PODCASTER_SEARCH_TYPES } from '../../../utils/Enum'
import { Theme } from '../../WebsiteThemeElements'
import { FormWrapper, ListContainer, SongDetailsSection, SongDetailsSectionTitle} from '../MusicUploadsFormElements'
import { Table, TableRow, ContentListContainer, DetailsContainer, DetailsText, TableData, TableHeading } from '../../ContentList/ContentListElements'
import Chip from '../../Chip'
import { DEFAULT_IMAGE } from '../../GravatarAPI'
import { Nav, NavContainer, NavLink } from '../../NavLink/NavLinkElements'
import { FormWrapper as FW, FormButton, FormButton1, FormInput,  FormInput1, FormLabel } from '../../CustomForm/CustomFormElements'
import usePodcaster from '../../../hooks/usePodcaster'
import { ChipList, Img } from '../../Chip/ChipElements'
import { SmallText } from '../../NFTCollectionCreator/NFTCollectionCreatorElements'
import { useAuth } from '../../../contexts/AuthContext'
import { useModal } from '../../Modal/ModalContext'
import { PodcasterSearchModal } from '../../SearchModal'


export const PodcasterSelector = ({podcasters, addPodcaster, removePodcaster}) => {
    const [searchType, setSearchType] = useState()
    const {currentUser} = useAuth()
    const {searchPodcasters} = usePodcaster({})
    const [podcasterSearch, setPodcasterSearch] = useState()
    const [currentPodcaster, setPodcaster] = useState()
    const [search, setSearchInput] = useState('')
    const {openModal, closeModal, modalContent} = useModal()

    const searchInput = (e) => {
        setSearchInput(e.target.value)
        if(e.target.value?.length > 0){
            searchPodcasters(searchType, e.target.value).then((data) => {
                if (data && (!data.error || data !== false)) {
                    setPodcasterSearch(data.filter((podcaster) => !podcasters?.some(p => p?.podcasterId === podcaster?.podcasterId)));
                }})
    }else{
        setPodcasterSearch()
    }
    }

    const displaySearch = () => {
        return (<FormWrapper>
                
            <ContentListContainer >
            <>
           <Table>
               <TableRow>
                   <TableHeading>

                   </TableHeading>
                   <TableHeading>
                       </TableHeading>
                   <TableHeading>
                   </TableHeading>
                       
               </TableRow>
           {podcasterSearch?.map((podcaster, index) => {
               return(
               <>
                   <TableRow key={index} id={podcaster.podcasterId} onClick={() => {
                    addPodcaster(podcaster) 
                    setSearchInput('')
                    setPodcasterSearch()
                    }}>
               <TableData>
                   <Img isCircular={true} src={podcaster?.photoUrl ? podcaster.photoUrl : DEFAULT_IMAGE(50)}  alt={podcaster?.podcasterName} />
               </TableData>
               <TableData>
                   <DetailsContainer>
                       <DetailsText>{podcaster.podcasterName}</DetailsText>
                   </DetailsContainer>
               </TableData>

           </TableRow></>)
           })}
           </Table>
           </>
           </ContentListContainer>
    </FormWrapper>)

    }

    const clearForm = () => {
        setPodcaster()
        setSearchType('')
        setPodcasterSearch()
    }

    const podcasterInfo = () => {
        return (
            <FW>
                <FormLabel>{currentPodcaster?.podcasterName} Payout Percentage</FormLabel>
                <FW>
                    <FormLabel>Podcaster ID</FormLabel>
                    <FormInput readOnly value={currentPodcaster?.podcasterId}/>
                </FW>
                <FW>
                    <FormLabel>Podcaster Name</FormLabel>
                    <FormInput readOnly value={currentPodcaster?.podcasterName}/>
                </FW>
                <FW>
                    <FormLabel>Podcaster Address(s)</FormLabel>
                    <FormInput readOnly value={currentPodcaster?.address}/>
                </FW>
                <FormButton1 onClick={clearForm}>OK</FormButton1>
            </FW>
        )
    }


    return (
        <>
           <FW>
            <FormLabel>Podcasters</FormLabel>
              {podcasters?.length > 0 && <FW>
                   <ChipList>
                       {podcasters.map((podcaster, index) => {
                           return (
                             <Chip label={podcaster?.podcasterName} image={podcaster?.photoUrl} onChipClick={() => setPodcaster(podcaster)} closeButton onDelete={() => podcaster?.address !== currentUser?.uid ?removePodcaster(index): {}}/>  
                           )
                       } )}
                       
                   </ChipList>
               </FW>}
           <Nav  style={{marginBottom: "2%", padding: "2%"}}>
           <NavContainer>
               <NavLink
                   active={searchType === PODCASTER_SEARCH_TYPES.NAME} 
                   onClick={() => {
                   setSearchType(PODCASTER_SEARCH_TYPES.NAME)
                   setSearchInput('')
                   setPodcaster()
               }}>Podcaster Name</NavLink>
               <NavLink
                   active={searchType === PODCASTER_SEARCH_TYPES.ID}
                   onClick={() => {
                   setSearchType(PODCASTER_SEARCH_TYPES.ID)
                   setSearchInput('')
                   setPodcaster()
               }}>Podcaster ID</NavLink>
               <NavLink 
                   active={searchType === PODCASTER_SEARCH_TYPES.ADDRESS}
                   onClick={() => {
                   setSearchType(PODCASTER_SEARCH_TYPES.ADDRESS)
                   setSearchInput('')
                   setPodcaster()
               }}>Wallet Address</NavLink>
       
           </NavContainer>
           </Nav>
          {searchType?.trim()?.length > 0 && <FormInput
               name={searchType}
               value={search}
               readOnly={true}
               onClick={() => {
                openModal()
                modalContent(<PodcasterSearchModal type={searchType} existingPodcaster={podcasters} onClick={(podcaster) => {
                    addPodcaster(podcaster) 
                    setSearchInput('')
                    setPodcasterSearch()
                }}/>)
                }}
               placeholder={`Search for podcaster by ${searchType}`}
               onChange={searchInput}
       
             />}
              <SmallText>
               Select the podcasters featured on this episode
             </SmallText>
             {podcasterSearch?.length > 0 && displaySearch() }
           </FW>
           {currentPodcaster && podcasterInfo()}
           </>
         )
}

export default PodcasterSelector


export const PodcasterRoyaltySelector = ({addPodcaster, podcastersPayouts, artistPayouts, removePodcaster, payoutError, podcasters}) => {
    const [searchType, setSearchType] = useState()
    const {currentUser} = useAuth()
    const {searchPodcasters} = usePodcaster({})
    const [podcasterSearch, setPodcasterSearch] = useState()
    const [currentPodcaster, setPodcaster] = useState()
    const [search, setSearchInput] = useState('')
    const [percentage, setPercentage] = useState()
    const [errors, setErrors] = useState(payoutError? {payout: payoutError} : {})
    const theme = Theme({})


    const displaySearch = () => {
        return (<FormWrapper>
                
            <ContentListContainer >
            <>
           <Table>
               <TableRow>
                   <TableHeading>

                   </TableHeading>
                   <TableHeading>
                       </TableHeading>
                   <TableHeading>
                   </TableHeading>
                       
               </TableRow>
           {podcasterSearch?.map((podcaster, index) => {
               return(
               <>
                   <TableRow key={index} id={podcaster.podcasterId} onClick={() => {
                    setPodcaster(podcaster) 
                    setSearchInput('')
                    setPodcasterSearch()
                    }}>
               <TableData>
                   <Img isCircular={true} src={podcaster?.photoUrl ? podcaster.photoUrl : DEFAULT_IMAGE(50)}  alt={podcaster?.podcasterName} />
               </TableData>
               <TableData>
                   <DetailsContainer>
                       <DetailsText>{podcaster.podcasterName}</DetailsText>
                   </DetailsContainer>
               </TableData>

           </TableRow></>)
           })}
           </Table>
           </>
           </ContentListContainer>
    </FormWrapper>)

    }

    const podcasterPercentageInput = () => {
        return (
            <FW>
                <FormLabel>{currentPodcaster?.podcasterName} Payout Percentage</FormLabel>
                <FW>
                    <FormLabel>Podcaster ID</FormLabel>
                    <FormInput readOnly value={currentPodcaster?.podcasterId}/>
                </FW>
                <FW>
                    <FormLabel>Podcaster Name</FormLabel>
                    <FormInput readOnly value={currentPodcaster?.podcasterName}/>
                </FW>
                <FW>
                    <FormLabel>Podcaster Address(s)</FormLabel>
                    <FormInput readOnly value={currentPodcaster?.address}/>
                </FW>
                <FW>
                    <FormLabel>Percentage</FormLabel>
                    <FormInput placeholder={'Percentage Payout'} value={currentPodcaster?.percentage} min={0} max={100} onChange={handleInput}/>
                    {errors?.percentage && <SmallText error>{errors?.percentage}</SmallText>}
                </FW>
                {errors?.revenue && <SmallText error>{errors?.revenue}</SmallText>}
                <FormButton1 onClick={validateInfo}>Confirm</FormButton1>
                <FormButton1 onClick={clearForm}>Cancel</FormButton1>
            </FW>
        )
    }

    const handleInput = (e) => {
        if(e.target.value <= 100){
        if(e.target.value?.length > 0){
            setPodcaster(prev => {
                return {
                    ...prev,
                    percentage: parseInt(e.target.value)
                }
            }
            );
            return
          }
          if(e.target.value?.length === 0){
            setPodcaster(prev => {
                return {
                    ...prev,
                    percentage: 0
                }
            })
          }
    
    }
    }

    const searchInput = (e) => {
        setSearchInput(e.target.value)
        if(e.target.value?.length > 0){
                    setPodcasterSearch(podcasters.filter((podcaster) => (podcaster?.podcasterName.includes(e.target.value) && !podcastersPayouts?.some(pPayout => pPayout?.podcasterId === podcaster?.podcasterId))));
        }else{
        setPodcasterSearch()
    }
    }

    const validatePercentage = (percentage) => {
        let e = errors
          delete e?.revenue
          setErrors()
          let revenue
          if(artistPayouts?.length > 0){
            revenue = [...podcastersPayouts.filter(p => p?.podcasterId !== currentPodcaster?.podcasterId), ...artistPayouts]
          }else{
           revenue = podcastersPayouts.filter(p => p?.podcasterId !== currentPodcaster?.podcasterId)
          }
          let percentages = 0
        for(let i =0; i <= revenue?.length-1; i++){
          if(currentPodcaster?.podcasterId !== (revenue[i]?.podcasterId)){
          percentages +=  parseInt(revenue[i]?.percentage)}
        }
        let value = parseInt(percentages) + parseInt(percentage)
        if(value <= 100){
          return true
        }else{
          setErrors({...e, revenue: "Rev percentages exceedes 100% "})
          return false
        }
      }

    const validateInfo = () => {
        if(currentPodcaster?.percentage > 0){
            if(validatePercentage(currentPodcaster?.percentage)){
                addPodcaster(currentPodcaster)
                clearForm()
            }
        }
    }

    const clearForm = () => {
        setPodcaster()
        setSearchType('')
        setPodcasterSearch()
    }

  return (
 <>
 <SongDetailsSection>
    <SongDetailsSectionTitle>Podcasters Royalty Payout</SongDetailsSectionTitle>
    <FW>
       {podcastersPayouts?.length > 0 && <FW>
            <ChipList>
                {podcastersPayouts.map((podcaster, index) => {
                    return (
                      <Chip label={podcaster?.podcasterName} image={podcaster?.photoUrl} onChipClick={() => setPodcaster(podcaster)} closeButton onDelete={() => podcaster?.address !== currentUser?.uid ?removePodcaster(index): {}}/>  
                    )
                } )}
                
            </ChipList>
        </FW>}
    <Nav  style={{marginBottom: "2%", padding: "2%"}}>
    <NavContainer>
        <NavLink
            active={searchType === PODCASTER_SEARCH_TYPES.NAME} 
            onClick={() => {
            setSearchType(PODCASTER_SEARCH_TYPES.NAME)
            setSearchInput('')
            setPodcaster()
        }}>Podcaster Name</NavLink>
        <NavLink
            active={searchType === PODCASTER_SEARCH_TYPES.ID}
            onClick={() => {
            setSearchType(PODCASTER_SEARCH_TYPES.ID)
            setSearchInput('')
            setPodcaster()
        }}>Podcaster ID</NavLink>
        <NavLink 
            active={searchType === PODCASTER_SEARCH_TYPES.ADDRESS}
            onClick={() => {
            setSearchType(PODCASTER_SEARCH_TYPES.ADDRESS)
            setSearchInput('')
            setPodcaster()
        }}>Wallet Address</NavLink>

    </NavContainer>
    </Nav>
   {searchType?.trim()?.length > 0 && <FormInput
        name={searchType}
        value={search}
        placeholder={`Search for podcaster by ${searchType}`}
        onChange={searchInput}

      />}
       <SmallText>
        Select the podcasters featured on this episode who are eligible to receive payouts
      </SmallText>
       {errors?.payout && <SmallText error>
        {errors?.payout}
      </SmallText>}
    </FW>
    {podcasterSearch?.length > 0 &&
       <FW> {displaySearch()}</FW> 
    }
    {currentPodcaster && podcasterPercentageInput()}
    </SongDetailsSection>
    </>
  )
}