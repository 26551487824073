import React from "react";
import { getArtistsNames, getArtistsNames2 } from "../../utils";
import { ARTIST_SEARCH_TYPES, AUDIO_EXPORT_TYPES, CONTENT_TYPES } from "../../utils/Enum";
import AlbumArt from "../AlbumArt";
import ContentWrapper from "../ContentWrapper";
import { FormText, FormWrapper } from "../CustomForm/CustomFormElements";
import { Header } from "../Header";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";

const AlbumDownload = ({ album, download }) => {
  return (
    <>
      <Header
        title={
          `${album?.albumName} - ` +
          getArtistsNames2(album?.artists, ARTIST_SEARCH_TYPES.NAME)
        }
      />
      <ContentWrapper subheading={"Download Album"}>
        
        <FormWrapper><FormText>
          {`Download ${album?.albumName} - ` +
            getArtistsNames2(album?.artists, ARTIST_SEARCH_TYPES.NAME)}
        </FormText>
          <div style={{cursor: "pointer"}}><AlbumArt
            size={"25rem"}
            albumArt={album?.albumArt}
            alt={album?.albumName}
            
          /></div>
          <HoverOption onClick={() => download(album, CONTENT_TYPES.ALBUM, album?.albumId, AUDIO_EXPORT_TYPES.MP3 )}>Download Album</HoverOption>
        </FormWrapper>
      </ContentWrapper>
    </>
  );
};

export default AlbumDownload;
