import React from 'react'
import ContentWrapper from '../../ContentWrapper'
import { Slider } from '../../Slider'
import { SLIDER_TYPES } from '../../../utils/Enum'

const RecordLabelPurchases = ({content, name}) => {
  return (
    <ContentWrapper heading={`Purchase Music by Artists from the ${name} Label`}>
        {(content?.songs?.filter(s => s?.isDownloadable)?.length > 0 || content?.albums?.filter(a => a?.isDownloadable)?.length > 0) && 
        <ContentWrapper heading={'Music for Purchase'}>
          <Slider type={SLIDER_TYPES.MISCELLANEOUS} id={`${name} purchases`} slides={[...content?.songs?.filter(s => s?.isDownloadable), content?.albums?.filter(a => a?.isDownloadable)]}/>
        </ContentWrapper>
        }
        {content?.songs?.filter(s => s?.isDownloadable)?.length > 0 && 
        <ContentWrapper heading={'Songs for Purchase'}>
        <Slider type={SLIDER_TYPES.DIGITAL_SONG} id={`${name} songs`} slides={content?.songs.filter(s => s?.isDownloadable)}/>
       </ContentWrapper>}
        {content?.albums?.filter(a => a?.isDownloadable)?.length > 0 && 
        <ContentWrapper heading={'Albums for Purchase'}>
        <Slider type={SLIDER_TYPES.DIGITAL_ALBUM} id={`${name} albums`} slides={content?.albums?.filter(a => a?.isDownloadable)}/>       
        </ContentWrapper>}
    </ContentWrapper>
  )
}

export default RecordLabelPurchases