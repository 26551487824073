import { useAudio } from "../components/AudioPlayer/context/AudioContext";
import { HoverOption } from "../components/MenuOptions/MenuOptionsElements";
import { useModal } from "../components/Modal/ModalContext";
import OptionModal from "../components/OptionModal";
import StreamingSubscriptionModal from "../components/StreamingSubscriptionModal";
import { useAuth } from "../contexts/AuthContext";

export function shuffleSongs(songs) {
    let array = songs
    let currentIndex = songs.length
    let randomIndex
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

 export const updateWebPageIcon = (icon) => {
    if(icon){
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = icon;} 
  }


  export const convertRoyaltiesToArrObj = (revShare) => {
    let keys = Object.keys(revShare)
    return keys.map((key, index) => {
      return {artistId: key,
      percentage: revShare[key]}
    })
  }

  const ValidatePlay = async() => {
    const {currentUser, getUserSubscription} = useAuth()
    const {isPlaying, handleToggle} = useAudio()
    const {openModal, closeModal, modalContent} = useModal()

    if(isPlaying){
      handleToggle("play-pause", !isPlaying)
      return
    }else{
    if(!currentUser?.subscription){
      await getUserSubscription()
    }
    if(currentUser?.subscription){
      handleToggle("play-pause", !isPlaying)
    }else{
      
      openModal() 
          modalContent(<><StreamingSubscriptionModal onSuccess={() => {
            handleToggle("play-pause", !isPlaying)
            modalContent(<OptionModal heading={"Subscription Unsucessful"}>
              <HoverOption onClick={ 
          closeModal()}>OK</HoverOption>
          </OptionModal>)
             }} onFail={() => {
            modalContent(<OptionModal heading={"Subscription Unsucessful"}>
              <HoverOption onClick={() => {closeModal()
              modalContent()}}>OK</HoverOption>
          </OptionModal>)}} cancel={() => {closeModal()
          modalContent()}} /></>)
    }
  }
  }