import React, { useEffect, useState } from "react";
import Page3 from "./Page3";
import Page2 from "./Page2";
import { FormWrapper } from "../../CustomForm/CustomFormElements";
import Page1 from "./Page1";
import { convertRoyaltiesToArrObj } from "../../../utils/MusicUtils";
import ScrollToTop from "../../ScrollToTop";
import {UnavailableFeature } from "../../MenuOptions";
import { useModal } from "../../Modal/ModalContext";
import useMusic from "../../../hooks/useMusic";
import { containsObject, getUniqueListBy, isrcReg } from "../../../utils";
import { CONTENT_TYPES, PODCASTER_SEARCH_TYPES } from "../../../utils/Enum";
import { useAuth } from "../../../contexts/AuthContext";
import usePodcaster from "../../../hooks/usePodcaster";
import { Button1 } from "../../ButtonElement";
import { useNavigate } from "react-router-dom";

const PodcastEdit = ({ episode, submitChanges }) => {
  const { generateISRCCode } = useMusic();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { closeModal, openModal, modalContent } = useModal();
  const { searchPodcasters } = usePodcaster({});

  const { goTo } = ScrollToTop();
  const [userPodcaster, setUserPodcaster] = useState(
    episode?.userPodcaster ? episode?.userPodcaster : {}
  );
  const [isLoaded, setLoaded] = useState(false);
  const [isrc, setISRC] = useState(episode?.isrc ? episode?.isrc : "");
  const [isIsrcValid, setIsrcValid] = useState(false);
  const [isrcType, setIsrcType] = useState(
    episode?.isrcType ? episode?.isrcType : "auto"
  );
  const [autoIsrcCode, setAutoIsrcCode] = useState(
    episode?.autoIsrcCode ? episode?.autoIsrcCode : undefined
  );
  const [currency, setCurrency] = useState(
    episode?.currency ? episode?.currency : "WYM"
  );
  const [price, setPrice] = useState(episode?.price ? episode?.price : 0);
  const [description, setDescription] = useState(
    episode?.about ? episode?.about : ""
  );
  const [podcasters, setPodcasters] = useState(
    episode?.podcasters ? episode?.podcasters : []
  );
  const [podcastersPayouts, setPodcastersPayouts] = useState(
    episode?.royalties
      ? Object.keys(episode?.royalties).map((p, index) => {
          let podcasters = episode?.podcasters;
          podcasters = getUniqueListBy(podcasters, "podcasterId");
          let podcaster = podcasters.filter((a) => p === a?.podcasterId); // search for current payout podcaster from list
          return {
            ...podcaster[0],
            exists: true,
            percentage: episode?.royalties[p],
          }; //return in payout artist format
        })
      : []
  );

  const [artistPayouts, setArtistPayouts] = useState(
    episode?.artistRoyalties
      ? Object.keys(episode?.artistRoyalties).map((p, index) => {
          let artists = [];
          episode?.songs?.map((song) => {
            if (song?.songCredits?.producers?.length > 0) {
              artists.push(...song?.songCredits?.producers);
            }
            if (song?.featuredArtists?.length > 0) {
              artists.push(...song?.featuredArtists);
            }
            if (song?.songCredits?.writers?.length > 0) {
              artists.push(...song?.songCredits?.writers);
            }
            if (song?.songCredits?.composers?.length > 0) {
              artists.push(...song?.songCredits?.composers);
            }
            return;
          });

          artists = getUniqueListBy(artists, "artistId");
          let artist = artists?.filter((a) => p === a?.artistId); // search for current payout artist from list
          return {
            ...artist[0],
            exists: true,
            percentage: episode?.artistRoyalties[p],
          }; //return in payout artist format
        })
      : []
  );
  const [music, setMusic] = useState(episode?.music ? episode?.music : []);
  const [isSubscription, setSubscription] = useState(
    episode?.isSubscription ? episode?.isSubscription : false
  );
  const [isDownloadable, setDownloadable] = useState(
    episode?.isDownloadable ? episode?.isDownloadable : false
  );
  const [isExplicit, setExplicit] = useState(
    episode?.explicit ? episode?.explicit : false
  );
  const [isMintable, setMintable] = useState(
    episode?.isMintable ? episode?.isMintable : false
  );
  const [isStreamable, setStreamable] = useState(
    episode?.isStreamable ? episode?.isStreamable : true
  );
  const [isMusicContained, setMusicContained] = useState(
    episode?.isMusicContained ? episode?.isMusicContained : false
  );
  const [license, setLicense] = useState(
    episode?.license ? episode?.license : "ARR"
  );
  const [timestamps, setTimestamps] = useState(
    episode?.timestamps ? episode?.timestamps : []
  );
  const [songs, setSongs] = useState(episode?.songs ? episode?.songs : []);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!isLoaded) {
      setMainPodcaster();
    }
    if (!autoIsrcCode) {
      getISRCCode();
    }
    return () => {};
    /*     const params = new URLSearchParams({id: 'test' });
      navigate( {pathname: location.pathname, search: params.toString()} )  
   */
  }, []);

  const getISRCCode = async () => {
    return await generateISRCCode()
      .then((code) => {
        if (!code?.error || code !== false) {
          setAutoIsrcCode(code);
          setISRC(code);
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const setMainPodcaster = async () => {
    await searchPodcasters(PODCASTER_SEARCH_TYPES.ADDRESS, currentUser?.uid)
      .then((podcaster) => {
        if (podcasters?.length === 0) {
          setUserPodcaster(podcaster);
          setPodcasters([podcaster]);
        } else if (
          podcasters?.length > 0 &&
          containsObject(podcasters, podcaster)
        ) {
          setPodcasters((prev) => {
            return getUniqueListBy([podcaster, ...prev]);
          });
          setUserPodcaster(podcaster);
        }
        if (podcastersPayouts?.length === 0) {
          setPodcastersPayouts([{ ...podcaster, percentage: 100 }]);
        }
        setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const addTimestamp = (label, time) => {
    let t = timestamps;
    let index = t?.findIndex((timestamp) => timestamp?.time === time);
    if (index >= 0) {
      t[index] = { label: label, time: time };
    } else {
      t.push({ label: label, time: time });
    }
    setTimestamps(
      t?.sort((a, b) => (a.time > b.time ? 1 : b.time > a.time ? -1 : 0))
    );
  };

  const deleteTimestamp = (i) => {
    let t = timestamps.filter((timestamp, index) => i !== index);
    setTimestamps(t);
  };
  const handleInput = (e) => {
    switch (e.target.name) {
      case "description":
        setDescription(e.target.value);
        break;
      case "isrc":
        setISRC(e.target.value.toUpperCase());
        if (isrcReg.test(e.target.value)) {
          setIsrcValid(true);
        } else {
          setIsrcValid(false);
        }
        break;

      case "isrcType":
        switch (e.target.value) {
          case "auto":
            setIsrcType("auto");
            setISRC(autoIsrcCode);
            break;

          case "custom":
            setISRC("");
            setIsrcType("custom");

            break;

          default:
            setIsrcType("auto");
            setISRC(autoIsrcCode);
            break;
        }
        break;
      default:
        break;
    }
  };

  const handleToggle = (value) => {
    switch (value) {
      case "explicit":
        setExplicit(!isExplicit);
        break;
      case "streamable":
        /* setStreamable(!isStreamable); */
        setSubscription(false);
        break;
      case "downloadable":
        /* setDownloadable(!isDownloadable); */
        openModal();
        modalContent(<UnavailableFeature />);
        break;
      case "subscription":
        /* setSubscription(!isSubscription);
        setStreamable(false); */
        openModal();
        modalContent(<UnavailableFeature />);
        break;
      case "mintable":
        openModal();
        modalContent(<UnavailableFeature />);
        break;
      case "music":
        setMusicContained(!isMusicContained);
        break;

      default:
        break;
    }
  };

  const addSong = (song) => {
    let s = songs;
    let songRoyalties = convertRoyaltiesToArrObj(song?.royalties);
    let artistRoyalties = [];
    let aPayouts = artistPayouts;
    let podPayouts = podcastersPayouts;
    for (let i = 0; i < songRoyalties?.length; i++) {
      let royalty = parseFloat(
        1 * (songRoyalties[i]?.percentage / 100)
      ).toFixed(2);
      artistRoyalties.push({
        artistId: songRoyalties[i]?.artistId,
        percentage: royalty,
      });
      let index = artistPayouts?.findIndex(
        (a) => a?.artistId === songRoyalties[i]?.artistId
      );
      if (index > -1) {
        aPayouts = aPayouts.map((a, i) => {
          if (index === i) {
            return {
              artistId: a?.artistId,
              percentage: parseFloat(royalty + a?.percentage),
            };
          }
          return a;
        });
      } else {
        aPayouts.push({
          artistId: songRoyalties[i]?.artistId,
          percentage: royalty,
        });
      }
    }
    podPayouts = podPayouts.map((p) => {
      if (p?.podcasterId === userPodcaster?.podcasterId) {
        return { ...p, percentage: parseFloat(p?.percentage - 1).toFixed(2) };
      } else {
        return p;
      }
    });
    setArtistPayouts(aPayouts);
    setPodcastersPayouts(podPayouts);
    s.push({ ...song, royalties: artistRoyalties });
    setSongs(s);
  };

  const removeSong = (index) => {
    let s = songs;
    let podPayouts = podcastersPayouts;
    let aPayouts = artistPayouts;
    setSongs(s.filter((song, i) => i !== index));
    for (let i = 0; i < s[index]?.royalties?.length; i++) {
      let royalty = s[index]?.royalties[i].percentage;
      let artistIndex = aPayouts.indexOf(
        (a) => a?.artistId === s[index]?.royalties[i]?.artistId
      );
      if (artistIndex > -1) {
        if (aPayouts[artistIndex].percentage - royalty > 0) {
          aPayouts[artistIndex] = {
            artistId: aPayouts[artistIndex],
            percentage: aPayouts[artistIndex].percentage - royalty,
          };
        } else {
          aPayouts = aPayouts.filter((artist, i) => i !== artistIndex);
        }
      }
    }
    podPayouts = podPayouts.map((p) => {
      if (p?.podcasterId === userPodcaster?.podcasterId) {
        return { ...p, percentage: parseFloat(p?.percentage + 1) };
      } else {
        return p;
      }
    });
    setArtistPayouts(aPayouts);
    setPodcastersPayouts(podPayouts);
  };

  const addPodcasterRoyalty = (podcaster) => {
    let p = podcastersPayouts;
    let index = p.findIndex(
      (pod) => podcaster?.podcasterId === pod?.podcasterId
    );
    if (index > -1) {
      p.map((pod, i) => {
        if (i === index) {
          return podcaster;
        } else {
          return pod;
        }
      });
    } else {
      p.push(podcaster);
    }
    setPodcastersPayouts(p);
  };

  const removePodcasterRoyalty = (index) => {
    let p = podcastersPayouts;
    setPodcastersPayouts(p.filter((podcaster, i) => i !== index));
  };

  return (
    <>
      <FormWrapper>
        <Page1
          episodeDescription={description}
          currency={currency}
          setCurrency={setCurrency}
          isMusicContained={isMusicContained}
          handleToggle={handleToggle}
          handleInput={handleInput}
          isDownloadable={isDownloadable}
          isExplicit={isExplicit}
          isStreamable={isStreamable}
          isSubscription={isSubscription}
        />
      </FormWrapper>

      <FormWrapper>
        <Page2
          timestamps={timestamps}
          setTimestamps={addTimestamp}
          deleteTimestamp={deleteTimestamp}
        />
      </FormWrapper>

      <FormWrapper>
        <Page3
          addPodcaster={addPodcasterRoyalty}
          isMusicContained={isMusicContained}
          payoutArtists={artistPayouts}
          errors={errors}
          podcasters={podcasters}
          removePodcaster={removePodcasterRoyalty}
          podcastersPayouts={podcastersPayouts}
          addSong={addSong}
          removeSong={removeSong}
          songs={songs}
        />
      </FormWrapper>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          width: "100%",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Button1
          onClick={() => {
            /*  //console.log({
          price: price,
          isrc: isrcType === 'custom' ? isrc?.trim()?.length > 0 ? isrc?.trim() : autoIsrcCode : autoIsrcCode,
          podcasters: podcasters,
          isDownloadable,
          isStreamable,
          isSubscription,
          isMusicContained,
          expicit: isExplicit,
          isMintable: isMintable,
          currency: isDownloadable
                ? currency
                  ? currency
                  : "WYM"
                : undefined,
          about: description,
          uploadedBy: currentUser.uid,
          timestamps: timestamps?.length > 0 ? timestamps: undefined,
          songs: isMusicContained ? songs : undefined,
          royalties: podcastersPayouts,
          artistRoyalties: isMusicContained ? artistPayouts : undefined
        }) */
            submitChanges(CONTENT_TYPES.EPISODE, {
              price: isDownloadable ? price : undefined,
              isrc:
                isrcType === "custom"
                  ? isrc?.trim()?.length > 0
                    ? isrc?.trim()
                    : autoIsrcCode
                  : autoIsrcCode,
              podcasters: podcasters.map((p) => {
                return p.podcasterId;
              }),
              isDownloadable,
              isStreamable,
              isSubscription,
              isMusicContained,
              expicit: isExplicit,
              isMintable: isMintable,
              currency: isDownloadable
                ? currency
                  ? currency
                  : "WYM"
                : undefined,
              about: description,
              uploadedBy: currentUser.uid,
              timestamps: timestamps?.length > 0 ? timestamps : undefined,
              songs: isMusicContained ? songs : undefined,
              royalties: podcastersPayouts,
              artistRoyalties: isMusicContained ? artistPayouts : undefined,
            });
          }}
        >
          Submit Changes
        </Button1>
        <Button1
          onClick={() => {
            navigate(`/episode/${episode?.episodeId}`, { replace: true });
          }}
        >
          Cancel
        </Button1>
      </div>
    </>
  );
};

export default PodcastEdit;
