import styled from "styled-components";
import hexToRgbA, { hexToRgba } from "../../HexToRGBA";
import { Link } from "react-router-dom";

export const CardContent = styled.div`
display: flex;
align-self: center;
justify-content: center;
align-content: center;
justify-self: center;
padding: 1.5rem;
white-space: normal;
background: ${props => hexToRgbA(props.theme.mainBg, 0)};
transition: transform 500ms ease;
@media screen and (max-width: 960px) {
    bottom: 0%; 
    background: ${props => hexToRgbA(props.theme.mainBg, 0.5)};
}


`

export const CardInfoContent = styled.div`
display: flex;
flex-direction: column;
justify-content: left;
padding: 6%;
justify-content: left;
align-content: center;

`

export const CardInfoTitle = styled.h4`
display: flex;
font-size: 1rem;
margin-bottom: 1px;
white-space: normal;
text-align: left;
word-wrap: break-word;
color: ${props => props.theme.secondaryColor};

`

export const H6 = styled.h6`
display: flex;
font-size: 1rem;
width: 100%;
flex-wrap: nowrap;
color: ${props => hexToRgba(props.theme.secondaryColor, 1)};
`

export const ContentText = styled.div`
display: flex;
 
`

export const TextLink = styled(Link)`
text-decoration: none;
cursor: pointer;
color: ${props => props.theme.secondaryBg};
&: hover{
color: ${props => props.theme.mainColor};
text-decoration: underline;

}
`