import React, { useEffect, useState } from "react";
import axios from "../Authorization/client";
import WebSocket from "react-websocket";
import { ModalLoadingScreen } from "../LoadingScreen/index";
import Navbar from "../Navbar";
import { Access } from "../Navbar/Data";
import { Theme } from "../WebsiteThemeElements";
import {
  Container,
  ContentWrapper,
  Form,
  FormButton,
  FormContent,
  FormH1,
  FormWrap,
  QrCode,
  QrWrapper,
  Text,
  TextLink,
  VideoBg,
} from "../SignUp/SignUpElements";
import {
  FormButton1,
  FormContainer,
  FormContent1,
  FormInput,
  FormInput1,
  FormLabel,
  FormText,
} from "../CustomForm/CustomFormElements";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { emailRegex } from "../../utils/Regex";
import { VideoUtils } from "../../utils/ImageUtils";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { downloadFile, getObjectLength, validateAddress } from "../../utils";
import {
  BsCheckSquare,
  BsFillCheckSquareFill,
  BsSquare,
  BsSquareFill,
} from "react-icons/bs";
import { getSignTxStatus } from "../../utils/dbUtils/signTxUtils";

const EligibilityChecker = () => {
  const { video1 } = VideoUtils();
  const { currentUser } = useAuth();
  const navigate  = useNavigate();
  const [errMsg, setError] = useState();
  const [msg, setMessage] = useState("");
  const [invoice, setInvoice] = useState();
  const [status, setStatus] = useState("idle");
  const [response, setResponse] = useState({})  
  const [isLoaded, setLoaded] = useState(false);
  const [isOpened, setOpened] = useState(false);
  const [payload, setPayload] = useState();
  const [isSigned, setSigned] = useState(false);
  const [address, setAddress] = useState("");
  const [addressErr, setAddressErr] = useState("");
  const [results, setResults] = useState({});
  const [inputType, setInputType] = useState("");
  const [next, setNext] = useState(false);

  useEffect(() => {
    return () => {}
  }, []);

  useEffect(() => {
    if(invoice){
          getSignTxStatus(invoice, status, setStatus, setResponse)

    }
    return () => {
    }
  }, [invoice])
  
  useEffect(() => {
    if(invoice){
      if(status === "success" && response !== false){
        setLoaded(false);
        setSigned(true);
        setPayload();
         setOpened(false);
         localStorage.setItem("userToken", response?.userToken)
        return checkEligibility(response.address);
       
    }if(status === "error"){
      setLoaded(false);
      setPayload();
      
      setError(
        "The transaction failed. Reload the page and try again"
      );
      setOpened(false);
    }}
    return () => {

    }
  }, [status, invoice])


  const checkEligibility = (a) => {
    setNext(true);
    return axios
      .get(`/api/${a}/eligibilityChecker`)
      .then((res) => {
        //console.log(res?.data);
        setResults(res?.data);

        setSigned(true);
        setPayload();
        setNext(true);
        setError("");
        setOpened(false);
        setLoaded(true);
      })
      .catch((err) => {
        //console.log(err);
        setError("Oops! Something wrong happened. Reload the page");
        setLoaded(false);
      });
  };

  const getQR = async () => {
    axios
      .post("/api/signTransaction", {
        userToken: localStorage.getItem("userToken"),
        instruction:
          "Signing Transaction to check wallet address Wymbo Token eligibility status",
      })
      .then((res) => {
        if (!res?.data?.error && res?.data?.payload) {
          setInvoice(res.data.invoice)
          setPayload(res.data.payload);
        } else {
          setError("Oops! Something wrong happened. Reload the page");
        }
        setLoaded(true);
      })
      .catch((err) => {
        //console.log(err);
        setError("Oops! Something wrong happened. Reload the page");
        setLoaded();
      });
  };

  const updateSignInStatus = async (messageData) => {
    //console.log("Message Data: ", messageData);
    const data = JSON.parse(messageData);
    if (data.opened === true) {
      setOpened(true);
      setError("Wating for you to sign the transaction");
    }
    if (isOpened === true && data.signed === true) {
      setNext(true);
      setLoaded(false);
      setError("");

    } else if (isOpened === true && data.signed === false) {
      setLoaded(false);
      setPayload();
      setError(
        "The transaction was rejected. Reload the page and try again"
      );
      setOpened(false);
    }
    if (data?.signed === false) {
      setLoaded(false);
      setPayload();
      setError(
        "The transaction was rejected. Reload the page and try again"
      );
      setOpened(false);
    }
  };

  const inputTypeSelector = () => {
    return (
      <>
        <FormWrap>
          <FormContent>
            <Form>
              <FormH1> Account Eligibility Checker</FormH1>
              <FormButton style={{
                    marginTop: "2%",
                    color: Theme({}).infoColor,
                    border: "1px solid " + Theme({}).infoColor,
                    background: "transparent",
                  }}
                  onClick={() => setInputType("Input")}>
                Enter Wallet Address
              </FormButton>
              <FormButton
                onClick={() => {
                  setInputType("QR");
                  getQR();
                }}
              >
                Scan QR (Xaman)
              </FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </>
    );
  };

  const addressForm = () => {
    return (
      <>
        <FormWrap>
          <FormContent>
            <Form>
              <FormH1> Account Eligibility Checker</FormH1>
              <FormLabel>Wallet Address</FormLabel>
              <FormInput
                placeholder={"Enter Wallet Address"}
                value={address}
                onChange={(e) => {
                  setAddressErr("");
                  setAddress(e.target.value);
                }}
              />
              <FormText>{addressErr.length > 0 && addressErr}</FormText>
              <FormButton
                style={{
                    marginTop: "2%",
                    color: Theme({}).infoColor,
                    border: "1px solid " + Theme({}).infoColor,
                    background: "transparent",
                  }}
                transparent={false}
                to={"#"}
                onClick={() => {
                  if (!validateAddress(address)) {
                    setAddressErr("Invalid XRP Wallet address");
                    return;
                  }
                  setNext(true);
                  setLoaded(false);
                  checkEligibility(address);
                }}
              >
                Confirm
              </FormButton>
              <FormButton transparent to="/">
                Cancel
              </FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </>
    );
  };

  const eligibilityForm = () => {
    return (
      <>
        <FormWrap>
          <FormContent>
            <Form>
              <FormH1> Account Eligibility Results</FormH1>
              {results?.emailHash && (
                <FormH1 onClick={() => downloadFile(`http://www.gravatar.com/${results?.email}`)}>
                  {" "}
                  <img
                    src={`http://www.gravatar.com/avatar/${results?.email}`}
                    alt={results?.display_name}
                    style={{ borderRadius: "50%", cursor: "pointer" }}
                  />{" "}
                </FormH1>
              )}
              <FormText style={{ fontWeight: 700, fontStyle: 'italic' }}>{address}</FormText>
              {results?.emailHash && <FormText style={{ fontWeight: 700, fontStyle: 'italic' }}>{results?.display_name}</FormText>}
              
              {results?.beta && <FormText style={{ fontWeight: 300,  color: Theme({}).infoColor, }}>
                Beta Tester:{" "}
                  <FormText
                    style={{
                      color: Theme({}).successColor,
                      fontWeight: 500,
                      fontSize: "1rem",
                    }}
                  >
                    Yes{" "}
                  </FormText>
              </FormText>}
              {results?.artist  && <FormText style={{ fontWeight: 300,  color: Theme({}).infoColor, }}>
                Sonar Muse Artist:{" "}
                 
                  <FormText
                    style={{
                      color: Theme({}).successColor,
                      fontWeight: 500,
                      fontSize: "1rem",
                    }}
                  >
                    Yes{" "}
                  </FormText>
                
                  
              </FormText>}
              <FormText style={{ fontWeight: 300 }}>
                Signed up for a Sonar Muse account:{" "}
                {results?.registration ? (
                  <FormText style={{ color: Theme({}).successColor,
                  fontWeight: 500,
                  fontSize: "1rem", }}>
                    Yes{" "}
                  </FormText>
                ) : (
                  <FormText style={{ color: Theme({}).errorColor,
                  fontWeight: 500,
                  fontSize: "1rem", }}>
                    No{" "}
                  </FormText>
                )}
              </FormText>
              <FormText style={{ fontWeight: 300 }}>
                Wallet Address Email Hash:{" "}
                {results?.emailHash ? (
                  <FormText style={{ color: Theme({}).successColor,
                  fontWeight: 500,
                  fontSize: "1rem", }}>
                    Yes{" "}
                  </FormText>
                ) : (
                  <FormText style={{ color: Theme({}).errorColor,
                  fontWeight: 500,
                  fontSize: "1rem",}}>
                    No{" "}
                  </FormText>
                )}
              </FormText>
              {!results?.emailHash && (
                <FormText style={{ color: Theme({}).accentColor }}>
                  (You will not be able to access the platform without an email
                  hash)
                </FormText>
              )}
              <FormText style={{ fontWeight: 300 }}>
                Gravatar:{" "}
                {results?.gravatar ? (
                  <FormText
                    style={{
                      color: Theme({}).successColor,
                      fontWeight: 500,
                      fontSize: "1rem",
                    }}
                  >
                    Yes{" "}
                  </FormText>
                ) : (
                  <FormText
                    style={{
                      color: Theme({}).errorColor,
                      fontWeight: 500,
                      fontSize: "1rem",
                    }}
                  >
                    No{" "}
                  </FormText>
                )}
              </FormText>
              

              <FormText style={{ fontWeight: 700, fontSize: "1.2rem" }}>
                {results?.registration && results?.gravatar ? (
                  <FormText style={{ color: Theme({}).infoColor }}>
                    You are eligible for the token distribution
                  </FormText>
                ) : (
                  <FormText style={{ color: Theme({}).errorColor }}>
                    You are not eligitble for the token distribution
                  </FormText>
                )}
              </FormText>

              <FormButton
                style={{
                  marginTop: "2%",
                  border: "1px solid " + Theme({}).warningColor,
                  background: "transparent",
                  color: Theme({}).warningColor,
                }}
                transparent={false}
                to={"#"}
                onClick={() => {
                  refreshPage();
                }}
              >
                Check Again
              </FormButton>
              {!results?.registration && (
                <FormButton
                  style={{
                    marginTop: "2%",
                    border: "1px solid " + Theme({}).secondaryBg,
                    background: "transparent",
                  }}
                  transparent={false}
                  to={"#"}
                  onClick={() => {
                    navigate( "/access/signup");
                  }}
                >
                  Sign Up
                </FormButton>
              )}
              {!results?.emailHash && (
                <FormButton
                  style={{
                    marginTop: "2%",
                    background: Theme({}).accentColor,
                  }}
                  transparent={false}
                  to={"#"}
                  onClick={() => {
                    navigate( "/setEmailHash");
                  }}
                >
                  Set Email Hash
                </FormButton>
              )}
              {!results?.gravatar && (
                <FormButton
                  style={{
                    marginTop: "2%",
                    color: Theme({}).infoColor,
                    border: "1px solid " + Theme({}).infoColor,
                    background: "transparent",
                  }}
                  transparent={false}
                  to={"#"}
                  onClick={() => {
                    downloadFile("https://en.gravatar.com");
                  }}
                >
                  Sign up for Gravatar
                </FormButton>
              )}
              <FormButton to="/">Home</FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </>
    );
  };

  const validateTx = async() => {
    setLoaded(false)
    return await axios
          .post("/api/verifyTxPayload", { uuid: payload?.uuid }).then((verification) => {
            //console.log(verification)
            if(verification?.data && !verification?.data?.error)
            {
              setLoaded(false);
              //dispatch(verification?.data);
              getSignTxStatus(invoice, status, setStatus, setResponse)
              setPayload();
              setError("The transaction went through successfully.");
              setOpened(false); //console.log("Signed")
              return
            }else{
              setLoaded(true)
              setError("The transaction was not signed.");
              return
            }
          }).catch(err => {
            console.error(err)
            setLoaded(true)
            setError("Something went wrong. Try again");
          }) 
       
  }

  const refreshPage = () => {
    setPayload();
    setError();
    setInputType("");
    setAddress("");
    setInvoice()
    setStatus("idle")
    setResponse()
    setResults({});
    setOpened(false);
    setLoaded();
    setNext(false);
  };

  //console.log("Websocket: ",payload.refs.websocket_status)
  return (
    <>
      <Navbar navInfo={Access} />

      <Container>
        <VideoBg
          autoPlay
          loop
          muted
          src={video1}
          type="video/mp4"
          contentEditable={false}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        ></VideoBg>
        <ContentWrapper>
          <>
            {next &&
              inputType !== "" &&
              getObjectLength(results) > 0 &&
              eligibilityForm()}
            {!next && inputType === "" && inputTypeSelector()}
            {!next && inputType === "Input" && addressForm()}
            {!next &&
              inputType === "QR" &&
              isLoaded === true &&
              getObjectLength(payload) > 0 && (
                <>
                  <WebSocket
                    url={payload.refs.websocket_status}
                    onMessage={(data) => updateSignInStatus(data)}
                  />
                  <FormWrap>
                    <FormContent>
                      <Form action={(e) => e.preventDefault}>
                        <FormH1> Account Eligibility Checker </FormH1>
                        <QrWrapper>
                          <QrCode src={payload.refs.qr_png} />
                        </QrWrapper>
                        {isOpened === false && (
                          <Text>
                            Scan QR code or click{" "}
                            <TextLink
                              href={payload.next.always}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              here
                            </TextLink>{" "}
                            to go to your Xaman wallet
                          </Text>
                        )}
                        {isOpened === true && (
                          <Text>
                            <Text>{msg}</Text>
                          </Text>
                        )}
                         <FormButton1 onClick={() => validateTx()} transparent>
                      I Already Signed Transaction
                    </FormButton1>
                        <FormButton
                          transparent={false}
                          to="/eligibilityChecker"
                          onClick={refreshPage}
                        >
                          Reload
                        </FormButton>
                        <FormButton transparent to="/">
                          Cancel
                        </FormButton>
                      </Form>
                    </FormContent>
                  </FormWrap>
                </>
              )}
            {next && errMsg && (
              <FormWrap>
                <FormContent>
                  <Form>
                    <Text>{errMsg}</Text>
                    {!isSigned && (
                      <FormButton
                        transparent={true}
                        to="/eligibilityChecker"
                        onClick={refreshPage}
                      >
                        Reload
                      </FormButton>
                    )}
                    {isSigned && (
                      <FormButton transparent={false} to="/">
                        Go Home
                      </FormButton>
                    )}
                  </Form>
                </FormContent>
              </FormWrap>
            )}
            {!next && inputType === "QR" && !errMsg && !isLoaded && (
              <ModalLoadingScreen text={'Loading Please Wait'} isLoaded={isLoaded} transparent={true} />
            )}
            {next && !errMsg && !isLoaded && (
              <ModalLoadingScreen text={'Loading Please Wait'} isLoaded={isLoaded} transparent={true} />
            )}
          </>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default EligibilityChecker;
