import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../components/Footer';
import GenrePage from '../../components/Genres/GenrePage';
import Navbar from '../../components/Navbar';
import { Home, Welcome } from '../../components/Navbar/Data';
import Sidebar from '../../components/Sidebar';

const Subgenre = () => {
    const [subgenre, setSubgenreId] = useState()
    const {subgenreId} = useParams()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}
    const navigate  = useNavigate()
    
    useEffect(() => {
        if(!subgenreId)
          {
            navigate(-1)
          }
        setSubgenreId(subgenreId)
        return () => {}
    }, [subgenreId])
  
  
  
    return( 
        <>
              <Sidebar
            isOpen={isOpen}
            toggle={toggle}
            navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
          />
            <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
            <GenrePage id={subgenreId} isSubgenre/>
            
        </>
        )
}

export default Subgenre