import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import { Home, Welcome } from '../../components/Navbar/Data';
import { TextRoute } from '../../components/SignUp/SignUpElements';
import { HeroBg, HeroContainer, HeroContent, HeroH1, HeroP, VideoBg } from '../../components/HeroSection/HeroElements';
import { Fade } from 'react-reveal';
import Video from '../../videos/video4.mp4'
import { hexToRgba } from '../../components/HexToRGBA';
import { useWebsiteTheme } from '../../components/WebsiteTheme/WebsiteThemeContext';

const RegistrationForm = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}
    const {themeColors} = useWebsiteTheme()
    const navigate  = useNavigate()
    return (
        <>
         
        <Navbar toggle={toggle}  navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
    
          <Sidebar
                isOpen={isOpen}
                toggle={toggle}
                navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
              />
              <HeroContainer style={{  backdropFilter: `blur(25px)`,WebkitBackdropFilter: `blur(25px)`, background: `${hexToRgba(themeColors?.secondaryBg)}`}}>
            <HeroBg >
            <VideoBg autoPlay loop muted src={Video}
                    type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}/>
            </HeroBg>
            <HeroContent style={{ height:'100%', maxWidth: '100%', width: '100%', alignContent: 'center', justifyContent: 'center', backdropFilter: `blur(5px)`,WebkitBackdropFilter: `blur(5px)`, background: `${hexToRgba(themeColors?.secondaryBg)}`}} isPage={true}>
                
            <Fade infinite><HeroH1 style={{color: 'white'}}>Sonar Muse for Artists</HeroH1></Fade>
            <Fade infinite><HeroP style={{fontSize: '1.1rem'}}> Are you an artist, podcaster or record label wanting to join the platform ? Visit the <TextRoute target="_blank" rel="noopener noreferrer" to={'https://artists.sonarmuse.org'}>Sonar Muse for Artists</TextRoute> portal
            </HeroP> </Fade>

                </HeroContent>
            </HeroContainer>
         
        </>
      )
}

export default RegistrationForm