import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";

export const MEDIA_SCREEN = { //Screen sizes for devices
  MOBILE: '480',
  TABLETS: '768',
  LAPTOPS: '1024',
  DESKTOPS: '1025',
  TV: '1500'
}

export const StoreContainer = styled.div`
    display: flex;
    min-height: 100vh;
    max-width: 100%;
    overflow: hidden;
    align-items: center;
    background: ${props => props.theme.mainBg};
    flex-direction: column;

`

export const StoreWrapper = styled.section`
    display: flex; 
    justify-content: center;
    align-items: center;
    max-width: 100%;
    background: ${props => props.theme.mainBg};
    flex-direction: column;


`
export const MusicStoreHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30vh;
  background: transparent;
  margin-bottom: 2%;

  `
  export const  MusicStoreHeader = styled.div`
  display: flex;
  position:relative;
  flex-direction: column;
  background: ${(props) => 
      ( (props?.background) ? 
        `linear-gradient(180deg, ${props.background} 0%, rgba(84, 120, 154, 0.6232) 100%)`:
          `linear-gradient(180deg, ${props.theme.mainColor} 0%, rgba(84, 120, 154, 0.6232) 100%)`)
  };
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 8% 3%;
  
  `

export const Title = styled.title`
  display: flex; 
  font-size: 3rem;
  font-weight: 700;
  color: ${props => props.theme.secondaryBg};
  filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};
`

export const SearchContainer = styled.div`
    display: flex;
    width: 100vw;
    align-items: center;
    flex-direction: row;
    padding: 2%;
    justify-content: space-around;
`

export const StoreFiltersContainer = styled.div`
    display: flex;
    width: 100vw;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        justify-content: space-evenly;
      }
`


export const StoreFilter = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    justify-content: space-between;
      @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        justify-content: space-evenly;
      }
`

export const FilterH1 = styled.h1`
    display: flex;
    font-size: 1rem;
    padding: 1%;
    margin-right: 2%;
    color: ${props => props.theme.secondaryBg};

`

export const FilterButton = styled.select`
    display: flex;
    padding: 5%;
    font-size: 0.9rem;
    color: ${props => props.theme.secondaryBg};
    cursor: pointer;
    border: solid 2px ${props => hexToRgba(props.theme.mainColor, 0.8)};
    border-radius: 5px;
    outline: none;
    background-color: ${props => props.theme.mainBg};
     @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        font-size: 0.7rem;
        padding: 3%;
      }
`
