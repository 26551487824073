import React, { useCallback, useEffect, useState } from 'react'
import ContentWrapper from '../ContentWrapper'
import useMusic from '../../hooks/useMusic'
import { SLIDER_TYPES } from '../../utils/Enum'
import { Slider } from '../Slider'
import { useRef } from 'react'
import { useCollectionSearch } from '../../hooks/useSearch'
import { ModalLoadingScreen } from '../LoadingScreen'
import Modal from '../Modal'

const LatestCollections = () => {
    const [pageNumber, setPageNumber] = useState("all")
    const {loading, error, collections, pages, setPages} = useCollectionSearch({pageNumber: pageNumber})
    const observer = useRef()
    const lastCollectionElementRef = useCallback( node => {
      if(loading){
        return
      }
      if(observer.current){ 
        observer.current.disconnect()}
        observer.current = new IntersectionObserver(entries=> {
          if(entries[0].isIntersecting && (pageNumber < parseInt(pages/10))){
                setPageNumber(prev => prev + 1)
  
          }
      }) 
      
      if(node) observer.current.observe(node)
     
    }, [loading, pages, pageNumber])
    
  return (
    <>
    <ContentWrapper heading={"NFToken Collections"}>
    {
      !loading && collections?.length > 0 && (
        
          <Slider
            type={SLIDER_TYPES.COLLECTION}
            heightScroll
            slides={collections}
            cardRef={lastCollectionElementRef}
            id={"latestcollections"}
          />
       
      )
      /*         <PageIndexer/>
       */
    }
    {
      loading && collections?.length > 0 && (
        <>
          <Slider
            type={SLIDER_TYPES.COLLECTION}
            heightScroll
            slides={collections}
            cardRef={lastCollectionElementRef}
            id={"latestcollections"}
          />
                   <Modal isOpen={loading}><ModalLoadingScreen text={'Loading. Please Wait'}/></Modal>

</>
      )
      /*         <PageIndexer/>
       */
    }
    {loading && collections?.length === 0 && (
      <ContentWrapper heading={"NFToken Collections"}>
           <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
      </ContentWrapper>
    )}
    {!loading && collections?.length === 0 && <ContentWrapper heading={"No Collections Found"}>
        
        </ContentWrapper>}
        </ContentWrapper>
  </>
  )
}

export default LatestCollections