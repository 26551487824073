import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const AccessContainer = styled.div`
display: flex;
flex-direction: column;
bottom: 0;
left: 0;
right: 0;
top: 0;
z-index: 0;
justify-content: center;
overflow:hidden;
background: linear-gradient(
	108deg,
	${props => props.theme.mainBg} 0%,
	${props => props.theme.mainBg} 100%
);
`

export const FormWrap = styled.div`
display: flex;
flex-direction: column;
justify-content: center;

@media screen and (max-width: 480px)
{
	height: 80%;
}
`

export const Icon = styled(Link)`
	margin-left: 32px;
	margin-top: 32px;
	align-items: center;
	border-radius: 50%;
	text-decoration: none;
	color: #fff;
	font-weight: 200;
	
	@media screen and (max-width: 480px){
	margin-left: 16px;
	margin-top: 8px;
}
`
export const FormContent = styled.div`
	height: 100%;
	margin-top: 20%;
	margin-bottom: 20%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	
	@media screen and (max-width: 480px)
	{
		padding: 10px;
	}
`
export const Form = styled.form`
	background: #01010;
	max-width: 480px;
	height: auto;
	z-index:1;
	display: grid;
	top: 30px;
	margin: 0 auto;
	padding: 80px 32px;
	border-radius: 25px;
	box-radius: 0 1px 3px rgba(0, 0, 0, 0, 0);

@media screen and (max-width: 480px){
	padding: 32px 32px;
}
`

export const FormH1 = styled.h1`
margin-bottom: 40px;
color: ${props => props.theme.secondaryColor};
font-size: 20px;
font-weight: 400;
text-align: center;
@media screen and (max-width: 768px) {
    font-size: 12px;
}

@media screen and (max-width: 480px) {
    font-size: 10px;
}

`

export const FormLabel = styled.label`
	margin-bottom: 40px;
	color: ${props => props.theme.secondaryColor};
	font-size: 14px;
`

export const FormInput = styled.input`
	padding: 16px 16px;
	margin-bottom: 32px;
	border: none;
	border-radius: 4px;
`

export const Text = styled.span`
	text-align: center;
	margin-top: 24px;
	color: ${props => props.theme.secondaryColor};
	font-size: 14px;
`

export const Img = styled.img`
    width: 27em;
    margin: 0 0 10px 0;
    padding-right: 0;
    border-radius: ${isCircular => (isCircular ? '50%': 0)};
	@media screen and (max-width: 768px) {
		width: 24em;
    }
    
    @media screen and (max-width: 480px) {
		width: 18em;
    }
`