import React from 'react'
import { stringFormatter } from '../../../utils';
import CurrencySelector from '../../CurrencySelector';
import { FormInput, FormTextArea } from '../../CustomForm/CustomFormElements';
import { AlbumDetailsSection, AlbumDetailsSectionTitle, ErrorWrapper, FormLabel, FormWrapper, SongDetailsText } from '../../MusicUploadsForm/MusicUploadsFormElements';
import ToggleButton from '../../ToggleButton';
import { Theme } from '../../WebsiteThemeElements';

const Page1 = ({
    albumInfo,
    handleToggle,
    handleInput,
    setCurrency,
}) => {
  return (
    <><AlbumDetailsSection>
    <AlbumDetailsSectionTitle>Album Details</AlbumDetailsSectionTitle>
    <FormWrapper>
      <FormLabel
        onClick={() => {
          handleToggle("streamable");
        }}
      >
        Streamable
      </FormLabel>
      <ToggleButton
        onText={"On"}
        offText={"Off"}
        toggle={albumInfo?.isStreamable}
        toggleClick={() => handleToggle("streamable")}
      />
    </FormWrapper>

    <FormWrapper>
      <FormLabel
        onClick={() => {
          handleToggle("downloadable");
        }}
      >
        Digital Sales
      </FormLabel>
      <ToggleButton
        onText={"On"}
        offText={"Off"}
        toggle={albumInfo?.isDownloadable}
        toggleClick={() => handleToggle("downloadable")}
      />
    </FormWrapper>
    {albumInfo?.isDownloadable && (
      <SongDetailsText background={Theme({}).secondaryColor}>
        Album will be available for sale digitally
      </SongDetailsText>
    )}

    {/*  <FormWrapper>
        <FormLabel
          onClick={() => {
            handleToggle("subscription");
          }}
        >
          Subscription Only
        </FormLabel>

        <ToggleButton
        onText={'On'}
        offText={'Off'}  
        toggle={isSubscription}
        toggleClick={() => handleToggle("subscription")}/>
      </FormWrapper> */}

    <FormWrapper>
      <FormLabel
        onClick={() => {
          handleToggle("mintable");
        }}
      >
        Mintable
      </FormLabel>

      <ToggleButton
        onText={"On"}
        offText={"Off"}
        toggle={albumInfo?.isMintable}
        toggleClick={() => handleToggle("mintable")}
      />
    </FormWrapper>

    <br />
    {albumInfo?.isDownloadable === true && (
      <FormWrapper>
        <FormLabel>
          Price <CurrencySelector currency={albumInfo?.currency} setCurrency={setCurrency} />
        </FormLabel>
        <ErrorWrapper>
          <FormInput
            style={{ flex: "1 1 50%" }}
            name={"album price"}
            placeholder={`Enter price of album in ${albumInfo?.currency}`}
            min={0}
            value={albumInfo?.price}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          {albumInfo?.errors?.price && (
            <SongDetailsText error>
              {albumInfo?.errors?.price}
            </SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
    )}
     <FormWrapper>
          <FormLabel>About Album</FormLabel>
          <FormTextArea ref={albumInfo?.aboutRef} value={stringFormatter(albumInfo?.about)} name={"album about"} onChange={handleInput} />
        </FormWrapper>
      </AlbumDetailsSection></>
  )
}

export default Page1