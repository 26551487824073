import styled from "styled-components"
import { Link } from "react-router-dom";
import { hexToRgba } from "../../HexToRGBA";
import { CustomButton } from "../../ButtonElement";
import { Nav, NavLink } from "../../NavLink/NavLinkElements";



export const ContentContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
`

export const ProfileNav = styled(Nav)`

`

export const ProfileNavLink = styled(NavLink)`


`

export const SettingsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: space-around;
  padding: 2% 2%;
`

export const InputLabel = styled.label`
  display: flex;
  margin-right: 25%;
  flex: 1 1 50%;
  padding: 1% 2%;
  flex-wrap: nowrap;
  color: ${(props) => props.theme.secondaryBg};
  font-size: 0.8rem;
`;

export const Input = styled.input`
  display: flex;
  flex: 1 1 100%;
  padding: 1%;
  margin: 1% 2%;;
  border: none;
  background: ${props => hexToRgba(props.theme.secondaryBg,0.6)};
  border-radius: 4px;
`;

export const  ActionButtonsContainer = styled.div`
display: flex;
flex: 1 1 100%;
padding: 2%;
justify-content: space-between;
align-items: center;
position: right;
`

export const ActionButton = styled(CustomButton)`
    display: flex;
    font-size: 1em;
    padding: 3% 5%;
    margin: 0px 3%;
    background-color:  ${props => props.active? (props.background?.color ? props.background.color : props.theme.mainColor) : props.theme.mainColor };
    color: ${props => props.active? (props.background?.color ? props.background.color : props.theme.mainColor) : props.theme.secondaryBg };
    justify-content: space-between;
    outline: ${(props) => (props.background?.color ? `1px solid ${props.background.color}` : `1px solid ${props.theme.mainColor}` )};

    &:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.1);
        background-color:  ${props => (props.background?.color ? props.background.color : props.theme.mainColor)};
        color: ${(props) => props.theme.secondaryBg};
        text-decoration: none;
    }
    @media screen and (max-width: 960px) {
      font-size: 0.65em;
    }
`

export const ErrorText = styled.small`
    display: flex;
    padding: 1%;
    color: ${props =>props.theme.accentColor};
`