import React, { useEffect, useState } from 'react'
import { VideoUtils } from '../../utils/ImageUtils'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container, ContentWrapper, VideoBg } from '../SignUp/SignUpElements'
import OptionModal from '../OptionModal'
import { BannerMarketingNav, BannerMarketingNavLink } from './BannerMarketingElements'
import { useAuth } from '../../contexts/AuthContext'
import MarketingForm from './MarketingForm'
import { CAMPAIGN_TYPE } from '../../utils/Enum'
import Footer from '../Footer'

const BannerMarketing = () => {
    const {currentUser} = useAuth()
    const {video1} = VideoUtils()
      const [type, setType] = useState()
      const [userType, setUserType] = useState()
      const location = useLocation()
      const navigate  = useNavigate()

    useEffect(() => {
        /* const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search)
        if((params.has('type') && !type) || (type && !params.has(type)) ){
            let t = type ? type : params.get("type")
            switch (t) {
                case (CAMPAIGN_TYPE.ALBUM).toLowerCase():
                    if(currentUser?.isArtist || currentUser?.isLabel ){
                        setType(CAMPAIGN_TYPE.ALBUM)
                    }else{
                        params.delete("type")
                    setType()
                    
                    }
                    
                    break;
                case (CAMPAIGN_TYPE.SINGLE).toLowerCase():
                    if(currentUser?.isArtist || currentUser?.isLabel ){
                        setType(CAMPAIGN_TYPE.SINGLE)
                    }else{
                        params.delete("type")
                    setType()
                    
                    }
                    break;
                case (CAMPAIGN_TYPE.COLLECTION).toLowerCase():
                    if(currentUser?.isArtist || currentUser?.isLabel ){
                        setType(CAMPAIGN_TYPE.COLLECTION)
                    }else{
                        params.delete("type")
                    setType()
                    
                    }
                    break;
                case (CAMPAIGN_TYPE.PLAYLIST).toLowerCase():
                    setType(CAMPAIGN_TYPE.PLAYLIST)
                    break;
                case (CAMPAIGN_TYPE.EPISODE).toLowerCase():
                    if(currentUser?.isPodcaster ){
                        setType(CAMPAIGN_TYPE.EPISODE)
                    }else{
                        params.delete("type")
                    setType()
                    
                    }break;
                case (CAMPAIGN_TYPE.ARTIST).toLowerCase():
                    if(currentUser?.isArtist || currentUser?.isLabel ){
                        setType(CAMPAIGN_TYPE.ARTIST)
                    }else{
                        params.delete("type")
                    setType()
                    
                    }
                    break;
                case (CAMPAIGN_TYPE.PODCASTER).toLowerCase():
                    if(currentUser?.isPodcaster ){
                        setType(CAMPAIGN_TYPE.PODCASTER)
                    }else{
                        params.delete("type")
                    setType()
                    
                    }
                    break;
                case (CAMPAIGN_TYPE.RECORD_LABEL).toLowerCase():
                    if(currentUser?.isLabel ){
                        setType(CAMPAIGN_TYPE.RECORD_LABEL)
                    }else{
                        params.delete("type")
                    setType()
                    
                    }
                    break;
            
                default:
                    params.delete("type")
                    setType()
                    
                    break;
            }
            navigate(`${location.pathname}${params.toString().length > 0 ? `?${params.toString()}` : ''}`,{
                        replace:true},
                      );
        } */
        return () => {}
    })

    const  setCampaignType = (type) => {
       
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search)
        setType(type)
        params.set("type", type.toLowerCase())
        navigate(`${location.pathname}?${params.toString()}`,{
            replace:true},
          ); 
          
    }

  return (
    <>
    
    {!type && <Container>
      <VideoBg autoPlay loop muted src={video1}
            type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}>
        </VideoBg>
        <ContentWrapper>
            <OptionModal onCancel={() => {navigate("/", {replace:true})}} heading={"Select Campaign Type"}>
                
                    <BannerMarketingNavLink onClick={() => setCampaignType(CAMPAIGN_TYPE.PLAYLIST)}>
                        Playlist
                    </BannerMarketingNavLink>
                
            </OptionModal>
            
        </ContentWrapper>
        </Container>}
    {type && <MarketingForm type={type} userType={userType} onCancel={() => {
        navigate(location.pathname, {replace:true}) 
        setType()
        } }/>}
       
    </>
  )
}

export default BannerMarketing