import {
  Client,
  xrpToDrops,
  dropsToXrp,
  Wallet,
  getBalanceChanges,
} from "xrpl";
import { datetoRippleEpoch, strToHex } from ".";
import { PAYMENT_WALLETS, SECRETS, SERVERS } from "./Enum";
import { getSequences } from "./dbUtils/sequencesUtils";

export const accAddressInfo = async (address) => {
  const client = new Client(SERVERS.MAINNET[0]);
  const userAddress = address;
  await client.connect();
  let response = await client.request({
    command: "account_info",
    account: userAddress,
    ledger_index: "validated",
  });
  let email = response?.result?.account_data?.EmailHash;
  await client.request({
    command: "subscribe",
    streams: ["ledger"],
  });
  client.on("ledgerClosed", async (ledger) => {});

  // Disconnect when done so Node.js can end the process

  if (email) {
    //console.log('user', email)
    //console.log(`email: ${email}, url: ${response.urlgravatar}`)
    await client.disconnect();
    return {
      account: response.result.account_data,
      email: email.toLowerCase(),
      gravatarUrl: response.result.account_data.urlgravatar,
    };
  } else {
    await client.disconnect();
    return false;
  }
};


export const makeChannelPayout = async (blob) => {
  const wallet = Wallet.fromSeed(
    SECRETS.MAIN_NET_SUBSCRIPTION_MICROPAYMENTS_SECRET
  );
  const sequences = await getSequences(
   wallet.classicAddress, 1
  );
  const client = new Client(SERVERS.MAINNET[0], { connectionTimeout: 3600000 });
  await client.connect();
  let transactionBlob = {
    Account: wallet.classicAddress,
    TransactionType: "PaymentChannelFund",
    Channel: blob.paymentChannel,
    Amount: blob.amount,
    SourceTag: 510162502,
    Expiration: datetoRippleEpoch(
      `${new Date(new Date().setFullYear(new Date().getFullYear() + 1))}`
    ),
    Memos: [
      {
        Memo: {
          MemoData: strToHex(
            `Sonar Muse Swerl: Payout for a stream via the Sonar Muse platform from the ${blob.title}`
          ),
        },
      },
    ],
  };
  if (sequences?.length > 0) {
    transactionBlob = {
      ...transactionBlob,
      TicketSequence: sequences[0],
      Sequence: 0,
    };
  }
  try {
    // Submit signed blob --------------------------------------------------------
    const tx = await client.submitAndWait(transactionBlob, { wallet });
    await client.disconnect();
    return tx;
  } catch (err) {
    if (err.Error === "WebSocket is not open: readyState 0 ") {
      client.connection.reconnect();
      try {
        delete transactionBlob?.Sequence;
        delete transactionBlob?.TicketSequence;
        // Submit signed blob --------------------------------------------------------
        const tx = await client.submitAndWait(transactionBlob, { wallet });
        await client.disconnect();
        return tx;
      } catch (err) {
        if (err?.message?.includes("Preliminary result: tefPAST_SEQ")) {
          try {
            delete transactionBlob?.Sequence;
            delete transactionBlob?.TicketSequence;
            // Submit signed blob --------------------------------------------------------
            const tx = await retrySequenceTx(client, transactionBlob, wallet);
            await client.disconnect();
            return tx;
          } catch (err) {
            await client.disconnect();
            return false;
          }
        }
        await client.disconnect();
        return false;
      }
    }
    if (err?.message?.includes("Preliminary result: tefPAST_SEQ")) {
      try {
        delete transactionBlob?.Sequence;
        delete transactionBlob?.TicketSequence;
        // Submit signed blob --------------------------------------------------------
        const tx = await retrySequenceTx(client, transactionBlob, wallet);
        await client.disconnect();
        return tx;
      } catch (err) {
        await client.disconnect();
        return false;
      }
    }
    if (err?.message.includes("NotConnectedError")) {
      await client.connect();
      try {
        delete transactionBlob?.Sequence;
        delete transactionBlob?.TicketSequence;
        // Submit signed blob --------------------------------------------------------
        const tx = await client.submitAndWait(transactionBlob, { wallet });
        return tx;
      } catch (err) {
        if (err?.message?.includes("Preliminary result: tefPAST_SEQ")) {
          try {
            delete transactionBlob?.Sequence;
            delete transactionBlob?.TicketSequence;
            // Submit signed blob --------------------------------------------------------
            const tx = await retrySequenceTx(client, transactionBlob, wallet);
            await client.disconnect();
            return tx;
          } catch (err) {
            await client.disconnect();
            return false;
          }
        }
        await client.disconnect();
        return false;
      }
    }
    await client.disconnect();
    return false;
  }
};

export const getPaymentChannelInfo = async (/* channel,  */ uid) => {
  const client = new Client(SERVERS.MAINNET[0]);
  let channels = [];
  await client.connect();
  try {
    let resp = await client.request({
      id: 1,
      command: "account_channels",
      account: PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS,
      destination_account: uid,
      ledger_index: "validated",
    });
    //console.log(PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS, uid)
    // Create a function to run on each API call.
    function printLedgerResult() {
      //console.log(resp["result"]);
    }
    const ledger_data_index = resp["result"]["ledger_index"];
    // Execute function at least once before checking for markers.
    do {
      //printLedgerResult();

      if (resp["result"]["marker"] === undefined) {
        break;
      }

      // Specify the same ledger and add the marker to continue querying.
      const ledger_marker = await client.request({
        command: "account_channels",
        account: PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS,
        destination_account: uid,
        ledger_index: ledger_data_index,
        marker: resp["result"]["marker"],
        ledger_index: "validated",
      });
      channels.push(...ledger_marker?.result?.channels);
      resp = ledger_marker;
    } while (true);
    //console.log(channels.filter((c) => c?.channel_id === channel));
    client.disconnect();
    if (channels?.length > 0) {
      return channels;
      //return channels.filter((c) => c?.channel_id === channel)[0];
    } else {
      return false;
    }
  } catch (err) {
    //console.error(err);
    return false;
  }
};

export const getPaymentChannelAmount = async (channel, uid) => {
  return await getPaymentChannelInfo(channel, uid)
    .then(async (txInfo) => {
      //console.log(txInfo?.amount);
      if (txInfo) {
        return txInfo?.amount;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
};

export const userChannelFunds = async (channel, uid) => {
  return await getPaymentChannelInfo(channel, uid)
    .then(async (txInfo) => {
      //console.log(txInfo)
      let funds = 0;
      if (txInfo?.amount && txInfo?.balance) {
        funds = txInfo?.amount - txInfo?.balance;
      }
      if (txInfo?.amount && !txInfo?.balance) {
        funds = parseInt(txInfo?.amount);
      }
      return funds;
    })
    .catch((err) => {
      return false;
    });
};

export const getNFTs = async (userId) => {
  //const wallet = xrpl.Wallet.fromSeed(SECRETS.NFT_DEV_NET)
  const client = new Client(SERVERS.MAINNET[0]);
  let tokens = [];
  await client.connect();
  try {
    let nfts = await client.request({
      method: "account_nfts",
      account: userId,
    });
    const ledger_data_index = nfts["result"]["ledger_index"];
    // Execute function at least once before checking for markers.
    do {
      //printLedgerResult()

      if (nfts["result"]["marker"] === undefined) {
        break;
      }

      // Specify the same ledger and add the marker to continue querying.
      const ledger_marker = await client.request({
        method: "account_nfts",
        account: userId,
        ledger_index: ledger_data_index,
        marker: nfts["result"]["marker"],
      });
      tokens.push(...ledger_marker?.result?.account_nfts);
      nfts = ledger_marker;
    } while (true);

    client.disconnect();
    return tokens;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/* export const burnNFTokenS = async (req) => {
  const wallet = Wallet.fromSeed();
  let tokens = req.tokens;
  let burnedTokens = 0;
  let unburned = [];
  let memo = req.memo;
  //console.log(tokens.length);
  const client = new Client(SERVERS.MAINNET[0]);
  await client.connect();
  for (let i = 0; i < tokens.length; i++) {
    // Prepare transaction -------------------------------------------------------
    let transactionBlob = {
      TransactionType: "NFTokenBurn",
      Account: wallet.classicAddress,
      NFTokenID: tokens[i],
      SourceTag: 510162502,
      Memos: [
        {
          Memo: {
            MemoData: strToHex(memo),
          },
        },
      ],
    };
    try {
      // Submit signed blob --------------------------------------------------------
      const tx = await client.submitAndWait(transactionBlob, { wallet });
      burnedTokens++;
    } catch (err) {
      console.error(err);
      unburned.push(tokens[i]);
    }
  }
  client.disconnect();
  return { burnedTokens, unburned };
}; */

export const retrySequenceTx = async (client, blob, wallet) => {
  try {
    // Submit signed blob --------------------------------------------------------
    const tx = await client.submitAndWait(blob, { wallet });
    return tx;
  } catch (err) {
    if (err.Error === "WebSocket is not open: readyState 0 ") {
      client.connection.reconnect();
      try {
        // Submit signed blob --------------------------------------------------------
        const tx = await retrySequenceTx(client, blob, wallet);;
        return tx;
      } catch (err) {
        return false;
      }
    }
    if (err?.message?.includes("Preliminary result: tefPAST_SEQ")) {
      try {
        // Submit signed blob --------------------------------------------------------
        const tx = await retrySequenceTx(client, blob, wallet);
        return tx;
      } catch (err) {
        return false;
      }
    }
    if (err?.message.includes("NotConnectedError")) {
      await client.connect();
      try {
        // Submit signed blob --------------------------------------------------------
        const tx = await retrySequenceTx(client, blob, wallet);
        return tx;
      } catch (err) {
        await client.disconnect();
        return false;
      }
    } else {
      await client.disconnect();
      return false;
    }
  }
};
