import React, { useState, useEffect, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { Form, FormContent, FormWrap } from "../SignUp/SignUpElements";
import {
  Container,
  GenreSinglesContainer,
  SearchBar,
  SearchBarWrapper,
  SearchButton,
  SearchContentContainer,
  SearchContentWrapper,
  SearchHeader,
  SearchNav,
  SearchNavLink,
  Title,
} from "./SearchbarElements";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import ContentList from "../ContentList";
import { DEFAULT_IMAGE } from "../GravatarAPI";
import { Theme } from "../WebsiteThemeElements";
import { Slider } from "../Slider";
import ContentWrapper from "../ContentWrapper";
import useMusic from "../../hooks/useMusic";
import useArtist from "../../hooks/useArtist";
import LoadingScreen from "../LoadingScreen/index";
import { Fade } from "react-reveal";
import { colorRandomizer, hexToRgba } from "../HexToRGBA";
import { useAudio } from "../AudioPlayer/context/AudioContext";
import { isUserPayingOut } from "../SonarMuseWebMonetization";
import { validateAddress } from "../../utils";
import Genres from "../Genres";
import OptionModal from "../OptionModal";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { useModal } from "../Modal/ModalContext";
import { CONTENT_TYPES, MEDIA_SCREEN, SLIDER_TYPES } from "../../utils/Enum";
import { useUser } from "../../hooks/useUser";
import { ContentListSkeleton } from "../Skeleton";
import { NavContainer } from "../NavLink/NavLinkElements";
import useRecordLabel from "../../hooks/useRecordLabel";
import usePodcaster from "../../hooks/usePodcaster";
import ScrollToTop from "../ScrollToTop";
import { updateWebPageIcon } from "../../utils/MusicUtils";



const Searchbar = () => {
  const { toggleModal, modalContent } = useModal();
  const {goTo} = ScrollToTop ()
  const searchbarRef = useRef();
  const { getVisibleUsers } = useUser({});
  const location = useLocation()
  const navigate  = useNavigate()
const {
    bulkMusicSearch,
    getAllGenres,
    searchSongsbySubgenre,
    searchSongsbyGenre,
    getLatestReleases,
    getLatestEpisodes,
    getLatestSongs,
    getTrendingPlaylists,
    searchPlaylists,
    getNewestPlaylists,
  } = useMusic({});
  const { getTopArtists, bulkArtistsSearch} = useArtist({});
  const [isSLoaded, setSLoaded] = useState(false);
  const [genres, setGenres] = useState();
  const [featuredArtists, setFeatArtists] = useState([]);
  const [newPlaylists, setNewPlaylists] = useState([]);
  const [playlistSearch, setPlaylistSearch] = useState([]);
  const [trendingPlaylists, setTrendingPlaylists] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState(false);
  const {getRecordLabels} = useRecordLabel({})
  const {getPodcasters} = usePodcaster({})
  const [error, setError] = useState("");
  const [isLoaded, setLoaded] = useState(false);
  const [searchbarValue, setSearchbarValue] = useState("");
  const [newReleases, setReleases] = useState();
  const [recordLabels, setRecordLabels] = useState([])
  const [podcasters, setPodcasters] = useState([])
  const [latestEpisodes, setLatestEpisodes] = useState([])
  const [searchType, setSearchType] = useState("Music");
  const [searchData, setSearchData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [visibleUsers, setUsers] = useState([]);
  const toggle = () => setIsOpen(!isOpen);
  const [isShowAll, setShowAll] = useState(false);
  const [slideColors, setColors] = useState({});
  const { setCurrentSong, setPlaylist, play } = useAudio();

  useEffect(() => {
    updateWebPageIcon('https://sonarmuse.org/favicon.ico')
    return () => {}
  },[])

  const searchContent = () => {
    switch (searchType) {
      case "Music":
        break;
      case "Users":
        if (validateAddress(searchbarValue)) {
          navigate( `/u/${searchbarValue}`);
        } else {
          setError("Invalid wallet address");
        }
        break;
      default:
        break;
    }
  };

  const handleSearch = async (e) => {
    setError();
    setSearchbarValue(e.target.value);
    if (e.target.value.trim() !== "") {
      setLoaded(false);
      setSearchData();
      switch (searchType) {
        case "Music":
          
          let music = {};
          let artists = [];
          
          await bulkMusicSearch(e.target.value)
            .then(async(data) => {
              music = data;
              //console.log(data)
              await bulkArtistsSearch(e.target.value)
                .then((data) => {
                  artists = data;
                  setSearchData({ ...music, artists });
                  setLoaded(true);
                })
                .catch((err) => {
                  //console.log(err);
                });
            }).catch((err) => {
              //console.log(err);
            });
          searchContent(searchType);
          break;
        case "Users":
          break;
        case "Artists":
          await bulkArtistsSearch(e.target.value)
                .then((data) => {
                  let artists = data;
                  setSearchData({ artists });
                  setLoaded(true);
                })
                .catch((err) => {
                  //console.log(err);
                });
          break;
        case "Podcasts":
         
          break;
        case "Playlists":
          setSearchData([]);
          await searchPlaylists(e.target.value)
            .then((data) => {
              setLoaded(true);
              if (data !== false) {
                setPlaylistSearch(data);
              }
            })
            .catch((err) => {
              toggleModal();
              modalContent(
                <>
                  <OptionModal heading={"Something Went Wrong Try Again"}>
                    <HoverOption
                      onClick={() => {
                        toggleModal();
                        modalContent();
                      }}
                    >
                      Try Again
                    </HoverOption>
                  </OptionModal>
                </>
              );
            });

          break;
        default:
          break;
      }
    } else {
      setSearchData();
      setLoaded(true);
    }
  };

  useEffect(() => {
    if(window?.location?.search.includes(`tab=`)){
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search)
      toggleTab(params.get("tab").substring(0, 1).toUpperCase()+params.get("tab").substring(1) )
    }else{
      toggleTab()
    }
    return () => {}
  }, []);

  useEffect(() => {
    setLoaded(false);
    getAllGenres()
      .then((data) => {
        if (
          Object.keys(slideColors).length !== Object.keys(data).length &&
          data.genres.length !== slideColors?.genres?.length &&
          data.subGenres.length !== slideColors?.subGenres?.length &&
          data !== false
        ) {
          let colors = [];
          for (let i = 0; i <= data.genres.length - 1; i++) {
            let color = hexToRgba(colorRandomizer(), 0.6);
            colors.push(color ? color : hexToRgba(colorRandomizer(), 0.6));
          }
          setColors({ genres: colors });
          let subColors = [];
          for (let j = 0; j <= data.subGenres.length - 1; j++) {
            let color = hexToRgba(colorRandomizer(), 0.6);
            subColors.push(color ? color : hexToRgba(colorRandomizer(), 0.6));
          }
          setColors((prev) => {
            return { ...prev, subGenres: subColors };
          });
        }

        setGenres(data);
        setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        setGenres();
        setLoaded(true);
      });

      getLatestReleases().then((releases) => {
      if (releases && releases?.length > 0) {
        setReleases(releases);
      }
    });
    getRecordLabels().then(data => {
      if(data){
      setRecordLabels(data)
      return}
    })
    getLatestEpisodes().then(episodes => {
      if(episodes?.episodes){
        
        setLatestEpisodes(episodes?.episodes)
      }
      
    })
    getPodcasters().then(async(data) => {
      if(data){
        setPodcasters(data)
      }
      return () => {}
    })

   

    getTopArtists("all").then((artists) => {
      setFeatArtists(artists?.artists);
    });

    getVisibleUsers().then((users) => {
      setUsers(users);
    });

    getNewestPlaylists().then((playlists) => {
      setNewPlaylists(playlists?.playlists);
    });

    getTrendingPlaylists().then((playlists) => {
      setTrendingPlaylists(playlists?.playlists);
    });
    return
  }, []);


  const toggleTab = (type) =>{
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search)
    switch (type) {
      case "Music":
        params.set("tab", type.toLowerCase())
        setSearchType(type);

        break;
      case "Users":
        params.set("tab", type.toLowerCase())
        setSearchType(type);
        break;
      case "Genres":
        params.set("tab", type.toLowerCase())
        setSearchType(type);
        break;
      case "Playlists":
        params.set("tab", type.toLowerCase())
        setSearchType(type);
        break;
      case "Labels":
        params.set("tab", type.toLowerCase())
        setSearchType(type);
        break;
      case "Artists":
        params.set("tab", type.toLowerCase())
        setSearchType(type);
        break;
      case "Podcasts":
        params.set("tab", type.toLowerCase())
        setSearchType(type);
        break;
    
      default:
        params.delete("tab")
        setSearchType("Music");
        break;
    }
    navigate(`${params.toString().length > 0 ? `?${params.toString()}` : '#'}`,{
      replace:true},
    );
  }

  const selectGenre = async (i) => {
    setSLoaded(false);
    if (selectedGenre === i) {
      setSearchData();
      setSelectedGenre(false);
    } else {
      setSelectedGenre(i);
      await searchSongsbyGenre(genres.genres[i].id).then((data) => {
        if (data !== false) {
          setSearchData({ songs: data });
          setSLoaded(true);
          return;
        }
        return;
      });
    }
  };

  const selectSubgenre = async (i) => {
    let subGenres = genres.subGenres.filter(
      (genre) => genre.genreId === genres?.genres[selectedGenre].id
    );
    setSLoaded(false);
    await searchSongsbySubgenre(subGenres[i].id).then((data) => {
      if (data !== false) {
        setSearchData({ songs: data });
        setSLoaded(true);
        return;
      }
      return;
    });
  };

  const displaySubgenres = () => {
    if (selectedGenre) {
      let subGenres = genres.subGenres.filter(
        (genre) => genre.genreId === genres?.genres[selectedGenre].id
      );
      return (
        <>
          <ContentWrapper
            heading={`${genres.genres[selectedGenre].name} Subgenres`}
          >
            <Slider
              heightScroll={true}
              slides={subGenres}
              onSlideClick={selectSubgenre}
              id={"genres"}
              background={slideColors.subGenres}
            />
          </ContentWrapper>
        </>
      );
    }
  };

  const displayContent = () => {
    return (
      <>
        <ContentWrapper heading={"Genres"}>
          <Slider
            onSlideClick={selectGenre}
            selected={selectedGenre}
            heightScroll={true}
            slides={genres.genres}
            id={"genres"}
            background={slideColors.genres}
          />
        </ContentWrapper>
      </>
    );
  };

  const displayReleases = () => {
    //Display New Album & Song Realeases
    return (
      <>
        <ContentWrapper heading={"New Releases"}>
        <ContentWrapper heading={"Songs"} onClick={() => {goTo('/latestSongs')}} subheading={'View All'}>
          <Slider
            type={SLIDER_TYPES.SONG}
            id={"new songs"}
            heightScroll={true}
            slides={newReleases?.filter(s => s?.songId)}
          />
        </ContentWrapper>
        { <ContentWrapper heading={"Albums"} onClick={() => {goTo('/latestSongs')}} subheading={'View All'}>
          <Slider
            type={SLIDER_TYPES.ALBUM}
            id={"new albums"}
            heightScroll={true}
            slides={newReleases?.filter(a => a?.albumId)}
          />
        </ContentWrapper>}
        </ContentWrapper>
      </>
    );
  };

  const displayFeaturedArtists = () => {
    return (
      <>
        <ContentWrapper heading={"Featured Artists"} subheading={"View All"} onClick={() => goTo('/artists')}>
          <Slider
            type={SLIDER_TYPES.ARTIST}
            id={"featured artists"}
            heightScroll={true}
            slides={featuredArtists}
          />
        </ContentWrapper>
      </>
    );
  };

  const displayNewPlaylists = () => {
    return (
      <>
        <ContentWrapper heading={"Newest Playlists"} subheading={`View All`} onClick={() => {goTo(`/latestPlaylists`)}}>
          <Slider
            type={SLIDER_TYPES.PLAYLIST}
            id={"newest playlists"}
            heightScroll={true}
            slides={newPlaylists}
          />
        </ContentWrapper>
      </>
    );
  };

  const displayTrendingPlaylists = () => {
    return (
      <>
        <ContentWrapper heading={"Trending Playlists"} subheading={`Show All`} onClick={() => {goTo(`/trendingPlaylists`)}}>
          <Slider
            type={SLIDER_TYPES.PLAYLIST}
            id={"trending playlists"}
            heightScroll={true}
            slides={trendingPlaylists}
          />
        </ContentWrapper>
      </>
    );
  };

  const displayVisibleUsers = () => {
    return (
      <>
        <ContentWrapper heading={"Users"}>
          <Slider
          heightScroll={true}
            type={SLIDER_TYPES.USER}
            id={"visible users"}
            slides={visibleUsers}
          />
        </ContentWrapper>
      </>
    );
  };

  const displayRecordLabels = () => {
    return (
      <>
        <ContentWrapper>
        <Slider
          type={SLIDER_TYPES.RECORD_LABEL}
          id={"labels"}
          slides={recordLabels}
        />
      </ContentWrapper>

      </>
    );
  };

  const displayPodcasters = () => {
    return (
      <>
        <ContentWrapper heading={'Podcasters'}>
        <Slider
          type={SLIDER_TYPES.PODCASTER}
          id={"podcasters"}
          slides={podcasters}
        />
      </ContentWrapper>

      </>
    );
  };

  const playSong = (i) => {
    if (isUserPayingOut()) {
      setPlaylist(searchData.songs.map((s) => s));
      setCurrentSong(i);
    } else {
      navigate( `/song/${searchData.songs}`);
    }
  };

  return (
    <>
      <Container>
        <SearchHeader>
          <Title>Discover</Title>
        </SearchHeader>
        {searchType !== "Genres" && (
          <SearchBarWrapper>
            <SearchBar
              ref={searchbarRef}
              type={"search"}
              placeholder={
                searchType === "Music"
                  ? "Search for artists, albums, playlists & songs"
                  : searchType === "Users"
                  ? "Search for users by wallet address"
                  : searchType === "Playlists"
                  ? "Search for playlists curated by users on the platform"
                  : searchType === "Podcasts"
                  ?"Search for podcasters, podcasts & podcast episodes"
                  :searchType === "Labels"
                  ? "Search for available record labels on our platform":
                  "Search for musical artists accross our platform"
              }
              onSubmit={searchContent}
              value={searchbarValue}
              onChange={(e) => {
                setSearchbarValue()
                setSearchData();
                setLoaded(true);
                handleSearch(e)
              }}
            />
            <SearchButton unselectable="on">
              <FaSearch onClick={searchContent} />
            </SearchButton>
          </SearchBarWrapper>
        )}
        {error && <span style={{ color: "white" }}>{error}</span>}
        <NavContainer>
        <SearchNav >
          <SearchNavLink
            active={
              searchType === "Music" 
            }
            onClick={() => {
              setSearchbarValue("");
              toggleTab("Music");
              setError();
            }}>
              Music
            </SearchNavLink>
          <SearchNavLink
            active={
              searchType === "Genres"
            }
            onClick={() => {
              setSearchbarValue("");
              toggleTab("Genres");
              setError();
            }}>
              Genres
            </SearchNavLink>
            <SearchNavLink
            active={
              searchType === "Artists"
            }
            onClick={() => {
              setSearchbarValue("");
              toggleTab("Artists");
            }}>Artists</SearchNavLink>
            <SearchNavLink
            active={
              searchType === "Labels"
            }
            onClick={() => {
              setSearchbarValue("");
              toggleTab("Labels")
            }}>Labels</SearchNavLink>
          <SearchNavLink
            active={
              searchType === "Users"
            }
            label={""}
            onClick={() => {
              setSearchbarValue("");
              toggleTab("Users")
            }}>
              Users
            </SearchNavLink>
          <SearchNavLink
            active={
              searchType === "Playlists"
            }
            onClick={() => {
              setSearchbarValue("");
              toggleTab("Playlists")
            }}>
              Playlists
            </SearchNavLink>
          <SearchNavLink
            active={
              searchType === "Podcasts"
            }
            onClick={() => {
              setSearchbarValue("");
              toggleTab("Podcasts")
            }}>Podcasts</SearchNavLink>
        </SearchNav>
        </NavContainer>
            {searchbarValue?.trim()?.length > 0 && searchbarValue?.trim()?.length !== "" && searchType !== "Genre" && <ContentWrapper heading={`Search "${searchbarValue}"`}></ContentWrapper>}
        <SearchContentWrapper>
          {newReleases &&
            searchType !== "Genres" &&
            searchType !== "Playlists" &&
            searchType !== "Labels" && 
            searchType !== "Podcasts" &&
            searchType !== "Users" &&
            searchType !== "Artists" &&
            (searchbarValue?.length === 0 || !searchbarValue) &&
            newReleases?.length > 0 &&
            displayReleases()}
          {newPlaylists &&
            searchType !== "Genres" &&
            searchType !== "Podcasts" &&
            searchType !== "Labels" && 
            searchType !== "Users" &&
            searchType !== "Artists" &&
            searchType !== "Music" &&
            (searchbarValue?.length === 0 || !searchbarValue) &&
            newPlaylists?.length > 0 &&
            displayNewPlaylists()}
          {trendingPlaylists &&
            searchType !== "Genres" &&
            searchType !== "Podcasts" &&
            searchType !== "Users" &&
            searchType !== "Labels" && 
            searchType !== "Artists" &&
            searchType !== "Music" &&
            (searchbarValue?.length === 0 || !searchbarValue) &&
            trendingPlaylists?.length > 0 &&
            displayTrendingPlaylists()}
          {visibleUsers &&
            searchType === "Users" &&
            visibleUsers?.length > 0 &&
            displayVisibleUsers()}
          {featuredArtists &&
          searchType === "Artists" &&
            searchType !== "Genres" &&
            searchType !== "Playlists" &&
            searchType !== "Podcasts" &&
            searchType !== "Music" &&
            searchType !== "Labels" && 
            searchType !== "Users" &&
            (searchbarValue?.length === 0 || !searchbarValue) &&
            featuredArtists?.length > 0 &&
            displayFeaturedArtists()}
            {searchType === "Labels" && 
            searchType !== "Music" &&
            searchType !== "Genres" &&
            searchType !== "Playlists" &&
            searchType !== "Podcasts" &&
            searchType !== "Users" &&
            searchType !== "Artists" &&
            (searchbarValue?.length === 0 || !searchbarValue) &&
            recordLabels && recordLabels?.length > 0 && displayRecordLabels()}

            {searchType !== "Labels" && 
            searchType === "Podcasts" && 
            searchType !== "Music" &&
            searchType !== "Genres" &&
            searchType !== "Playlists" &&
            searchType !== "Users" &&
            searchType !== "Artists" &&
            (searchbarValue?.length === 0 || !searchbarValue) &&
            podcasters && podcasters?.length > 0 && <>{displayPodcasters()}  
            {latestEpisodes?.length > 0 && <ContentWrapper heading={'Latest Podcast Episodes'} subheading={'View All'} onClick={() => {goTo(`/latestEpisodes`)}}>
            <Slider
              type={SLIDER_TYPES.EPISODE}
              id={'latestEpisodes'}
              slides={latestEpisodes}
            />
            </ContentWrapper>}</>
            
            }

          {searchType === "Genres" && <Genres />}
          {isLoaded &&
            searchType === "Genres" &&
            slideColors &&
            selectedGenre &&
            displaySubgenres()}
          {isSLoaded &&
            searchType === "Genres" &&
            selectedGenre &&
            searchData?.songs && (
              <ContentWrapper heading={"Songs"}>
                <SearchContentContainer>
                  <ContentList
                    type={CONTENT_TYPES.SONG}
                    content={searchData.songs}
                  />
                </SearchContentContainer>
              </ContentWrapper>
            )}

          {searchType === "Users" && (
            <>
              {!isLoaded && !searchData && (
                <>
                <ContentWrapper>
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                      id={"new Users"}
                    />
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                      id={"new Users"}
                    />
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                      id={"new Users"}
                    />
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                      id={"new Users"}
                    />
                  </ContentWrapper>
                </>
                )}
                </>)}

          {searchType === "Artists" && (
            <>
            {!isLoaded && searchbarValue?.trim()?.length > 0 && <ContentWrapper>
                    <Slider
                      type={SLIDER_TYPES.ARTIST_SKELETON}
                      id={"artists"}
                    />
                    <Slider
                      type={SLIDER_TYPES.ARTIST_SKELETON}
                      id={"artists"}
                    />
                  </ContentWrapper>}
            {searchbarValue.trim()?.length > 0 &&
                searchData?.artists?.length > 0 && isLoaded && (
                      <ContentWrapper heading={"Artists"}>
                        <Slider
                          type={SLIDER_TYPES.ARTIST}
                          id={"searched artists"}
                          slides={searchData?.artists}
                          heightScroll
                        />
                      </ContentWrapper>
                    )}
              {!isLoaded && !searchData && (
                <>
                 <ContentWrapper>
                    <Slider
                      type={SLIDER_TYPES.ARTIST_SKELETON}
                      id={"featured artists"}
                    />
                    <Slider
                      type={SLIDER_TYPES.ARTIST_SKELETON}
                      id={"featured artists"}
                    />
                  </ContentWrapper>
                </>
                )}
                </>)}

          {searchType === "Playlists" && (
            <>
              {!isLoaded && playlistSearch?.length === 0 && (
                <>
                  <ContentWrapper>
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                      id={"newest playlists"}
                    />
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                      id={"newest playlists"}
                    />
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                      id={"newest playlists"}
                    />
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                      id={"newest playlists"}
                    />
                  </ContentWrapper>
                </>
              )}
              {searchbarValue?.length > 0 &&
                searchbarValue?.trim !== "" &&
                playlistSearch?.length > 0 && (
                  <ContentWrapper heading={"Playlists"}>
                    <Slider
                      type={SLIDER_TYPES.PLAYLIST}
                      id={"playlist search"}
                      heightScroll={true}
                      slides={playlistSearch}
                    />
                  </ContentWrapper>
                )}
            </>
          )}
          {searchType === "Podcasts" && (
            <>
            {!isLoaded && searchbarValue?.trim !== "" && <><ContentWrapper>
              <Slider
                type={SLIDER_TYPES.USER_SKELETON}
                id={"labels"}
              />
              <Slider
                type={SLIDER_TYPES.USER_SKELETON}
                id={"labels"}
              />
            </ContentWrapper>
            <ContentWrapper>
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
            />
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
            />
          </ContentWrapper></>}


              {!isLoaded && !searchData && (
                <>
                  <ContentWrapper>
                    <Slider
                      type={SLIDER_TYPES.PODCAST}
                      slides={[1,2,3,4,5]}
                      id={'id'}
                    />
                    </ContentWrapper>
                    <ContentWrapper>
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                    />
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                    />
                  </ContentWrapper>
                </>
                )}
                </>
                )}
          {searchType === "Labels" && (
            <>
            
              {!isLoaded && searchbarValue?.trim !== "" && <ContentWrapper>
              <Slider
                type={SLIDER_TYPES.USER_SKELETON}
                id={"labels"}
              />
              <Slider
                type={SLIDER_TYPES.USER_SKELETON}
                id={"labels"}
              />
            </ContentWrapper>}
            
            
            </>
          )}
        
          {searchType === "Music" && (
            <>
              {!isLoaded && !searchData && (
                <>
                  <ContentWrapper>
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                      id={"new release"}
                    />
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                      id={"new release"}
                    />
                  </ContentWrapper>

                </>
              )}
              {searchbarValue?.length > 0 &&
                searchbarValue?.trim() !== "" &&
                isLoaded &&
                searchData && (
                  <>
                    {searchData?.artists?.length > 0 && (
                      <ContentWrapper heading={"Artists"}>
                        <Slider
                          type={SLIDER_TYPES.ARTIST}
                          id={"searched artists"}
                          slides={searchData.artists}
                        />
                      </ContentWrapper>
                    )}
                    {searchData?.albums?.length > 0 && (
                      <ContentWrapper heading={"Albums"}>
                        <Slider
                          type={SLIDER_TYPES.ALBUM}
                          id={"searched albums"}
                          slides={searchData?.albums}
                        />
                      </ContentWrapper>
                    )}
                    
                      <GenreSinglesContainer>
                        {isLoaded && displayContent}
                        {searchData?.songs?.filter(s => s?.songName.toLowerCase().includes(searchbarValue.toLowerCase()))?.length > 0 && (<ContentWrapper heading={"Songs"}>
                          <>
                            <SearchContentContainer>
                              <ContentList
                                type={CONTENT_TYPES.SONG}
                                onButtonClick={playSong}
                                content={window.innerWidth > MEDIA_SCREEN.TABLETS ? searchData?.songs?.filter(s => s?.songName.toLowerCase().includes(searchbarValue.toLowerCase())).filter(
                                  (s, index) => index > 10 ? index < (searchData.songs.length/2) : index < 10
                                ): searchData?.songs?.filter(s => s?.songName.toLowerCase().includes(searchbarValue.toLowerCase()))}
                              />
                            </SearchContentContainer>
                            {window.innerWidth > MEDIA_SCREEN.TABLETS && searchData?.songs?.filter(s => s?.songName.toLowerCase().includes(searchbarValue.toLowerCase())).length >= 10 && (
                              <SearchContentContainer>
                                <ContentList
                                  type={CONTENT_TYPES.SONG}
                                  onButtonClick={playSong}
                                  content={searchData?.songs?.filter(s => s?.songName.toLowerCase().includes(searchbarValue.toLowerCase())).filter(
                                    (s, index) => (index >= (searchData.songs.length/2) && index < (searchData.songs.length))
                                  )}
                                />
                              </SearchContentContainer>
                            )}
                          </>
                         </ContentWrapper>)}
                      </GenreSinglesContainer>
                   
                    

                    {searchData?.playlists?.length > 0 && (
                      <ContentWrapper heading={"Playlists"}>
                        <Slider
                          type={SLIDER_TYPES.PLAYLIST}
                          id={"searched playlists"}
                          slides={searchData?.playlists}
                        />
                      </ContentWrapper>
                    )}
                  </>
                )}
            </>
          )}
        </SearchContentWrapper>
      </Container>
    </>
  );
};

export default Searchbar;
