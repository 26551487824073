import styled from "styled-components";
import { MEDIA_SCREEN } from "../ContentWrapper/ContentWrapperElements";
import { hexToRgba } from "../HexToRGBA";


export const VideoPlayerContainer  = styled.div`
    width: ${props => (props.theatreMode || props.fullScreen) ? '100%': `90%`};
    position: relative;
    border-radius: 15px;
    max-width: ${props => (props.theatreMode || props.fullScreen) ? 'initial': `1000px`};
    max-height: ${props => props.theatreMode ? `80vh`: props.fullScreen ? '100vh': `100%`};
    display: flex;
    justify-content: center;
    margin-inline: auto;
    background: ${props => props.theme.background};
`

export const Video = styled.video`
    width: 100%;
    border-radius: 15px;
`

export const VideoControlsContainer = styled.div`
    position: absolute;
    padding: 0.25rem;
    bottom: 0;
    border-radius: 0 0 15px 15px;
    left:0;
    right: 0;
    color: ${props => props?.theme?.secondaryBg};
    z-index: 1000;
    opacity: ${props => props?.paused ? 1 : 0};
    transition: opacity 150ms ease-in-out;

    ${VideoPlayerContainer}: hover &{
        opacity: 1;
    }
    ${VideoPlayerContainer}: focus-within &{
        opacity: 1;
    }

    &:before{
        position: absolute;
        bottom: 0;
        content: '';
        background: ${props => `linear-gradient(to top, ${hexToRgba(props.theme.mainBg,0.75)}, transparent)`};
        width: 100;
        aspect-ratio: 6 / 1;
        z-index: -1;
        pointer-events: none;
      }
    background: ${props => hexToRgba(props.theme.mainBg,0.5)};
`

export const TimelineControler = styled.div`
margin-inline: 0.5rem;

`

export const VideoPlayerSlider = styled.input`
    padding: 0;
    width: 100%;
    flex: 1 1 100%;
    height: 3px;
    margin-bottom: 2%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color:  ${ props => props.theme.secondaryBg};
    border: 0;
    cursor: pointer;
    color: ${ props => props.theme.mainColor};
    position: relative;
    direction: ltr;

    //Chrome, Edge, Safari settings
    ::-webkit-slider-runnable-track{
        background-color:  ${ props => props.theme.secondaryBg};
        color: ${ props => props.theme.mainColor};

   border-radius: 0.5rem;
   height: 3px;
   @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    height: 0.3rem; 
    
  } 
    }

    ::-webkit-slider-thumb{
        -webkit-appearance: none; /* Override default look */
   appearance: none;
   background-color: ${(props) => props.theme.accentColor};
   color: ${ props => props.theme.mainColor};

   height: 0.2rem;
   width: 0.5rem;
   @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    height: 0.3rem; 
    
  }
    }

    //Firefox settings
    ::-moz-range-track{
        background-color:  ${ props => props.theme.secondaryBg};
        color: ${ props => props.theme.mainColor};

   border-radius: 0.5rem;
   height: 3px; 
   @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    height: 5px; 
    
  }
    }

    ::-moz-range-thumb{
        border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    background-color: ${(props) => props.theme.accentColor};
    height: 0.2rem;
    width: 0.5rem;
    
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        transition: 0.8s all ease;
        height: 0.2rem; 
        
      }
    }

    @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
      transition: 0.8s all ease;

      height: 1px;
        
      } 

      &:before{
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      height: 4px;
      width: ${props => props.progressWidth};
      z-index: 3;
      background-color: ${props => props.theme.mainColor};
   
    }
      &:after{
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      height: 4px;
      width: ${props => props.bufferWidth};
      z-index: 4;
      background-color: ${props => hexToRgba(props.theme.mainBg, 0.5)};
   
    }
      

`

export const VideoPlayerControls = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items:center;
    font-size: 1.5rem;
    padding: 0 1%;
     @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        transition: 0.8s all ease;
        font-size: 1rem;
      }
`

export const VideoPlayerLogo = styled.div`
    display: flex;
    padding: 0 5%;
    margin-bottom: 1%;
    align-content:center;
    width: 1.5rem;
    height: 1.5rem;
    color: inherit;
    justify-content: center;
    cursor: pointer;

    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        transition: 0.8s all ease;
        width: .7rem;
    height: .7rem;
        
      }
`

export const VideoControlsButton = styled.div`
    background: none;
    border: none;
    color: inherit;
    padding: 0;
    width: 30px;
    align-content:center;
    justify-content: center;
    cursor: pointer;
`
export const VideoPlayerVolumeContainer = styled.div`
display: flex;
align-items: center;
`
export const VideoPlayerVolumeSlider = styled.input`
    width: 0;
    transform-origin: left;
    height: 3px;
    top: 0;
    transform: scaleX(0);
    transition: width 150ms ease-in-out, transform 150ms ease-in-out;
    color: ${(props) => props.theme.secondaryColor};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
      margin-bottom: 2%;
    ::-webkit-slider-thumb{
        -webkit-appearance: none; /* Override default look */
   appearance: none;
   border-radius: 50%;
   background-color: ${(props) => props.theme.accentColor};
   height: 0.5rem;
   width: 0.5rem;
    }

    ::-moz-range-thumb{
        border: none; /*Removes extra border that FF applies*/
    border-radius: 50%; /*Removes default border-radius that FF applies*/
    background-color: ${(props) => props.theme.accentColor};
    height: 0.5rem;
    width: 0.5rem;
    }

    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        transition: 0.8s all ease;
        width: 70px;
        transform: scaleX(1);
      }
    
    ${VideoPlayerVolumeContainer}: hover &{
        width: 70px;
        transform: scaleX(1);
    }

`

export const VideoDurationContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.5%;
    gap: .25rem;
    flex-grow: 1;
    font-size: 0.8rem;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        transition: 0.8s all ease;
        font-size: 0.6rem;
      }
`

export const VideoPlayBackSpeedContainer = styled.div`
    font-size: 0.8rem;
    display: flex;
    margin-bottom: 0.5%;
    align-items: center;
    gap: .25rem;
    cursor: pointer;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        transition: 0.8s all ease;
        font-size: 0.6rem;
      }
`
