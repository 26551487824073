import styled from "styled-components";
import { hexToRgba, lightOrDark } from "../../HexToRGBA";
import { Link } from "react-router-dom";
import { FaPlayCircle } from "react-icons/fa";

export const CardContainer = styled.div`
display: flex;
flex-direction: column;
padding: 1%;
white-space: normal;
border-radius: 0.5rem;
margin: 2%;
width: 100%;
height: 100%;
max-width: 300px;
align-content: center;
align-items: left;
justify-content: left;
background: ${props => hexToRgba(props.theme.accentColor, 0)};

&: hover{
    background: ${props => props.background? hexToRgba(props.background, 0.2): hexToRgba(props.theme.accentColor, 0.2)};
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
    border: solid ${props => hexToRgba(props.theme?.secondaryBg, 0.7)} 1px;
   }
`
export const SmallPlayContainer = styled.div` 
display: flex;
width: 100%;
justify-content: center;
align-items: center;
padding: 2%;
`


export const PlayButton = styled(FaPlayCircle)`
display: flex;
width: 2rem;
height: 2rem;
z-index: 4;
justify-self: center;
align-self: center;
color : ${props => props.theme.accentColor};
cursor: pointer;

&:hover {
    color: ${props =>  props.theme.mainColor };
    transition: all 0.2s ease-in-out;
    transform: scale(0.9);
    border-radius: 50%;
    border: 3px solid ${props => props.theme.secondaryBg};

}

${CardContainer}: hover &{
    display: flex;
    color: ${props => props.theme.secondaryBg};

}

`

export const DownloadButton = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 0.7rem;
    padding: 5px 22px;
    background: transparent;
    border: 1px solid ${props => props.theme.mainColor};
    font-size: 0.9rem;
    border-radius: 50px;
    color: ${props => props.theme.secondaryBg};
    justify-content: center;
    align-items: center;
    transition: transform 500ms ease;
    &: hover{
        background: ${props => props.theme.mainColor};
        border: 1px solid ${props => props.theme.mainColor};
        color: ${ props => lightOrDark(props.theme.mainColor) === 'light' ? 'black' : 'white'};
    }
    ${CardContainer}:hover &{
        border: 1px solid ${props => props.theme.accentColor};

    }

    `
export const CardContent = styled.div`
    display: flex;
    font-size: 0.8rem;
    flex-direction: column;
    padding: 6%;
    justify-content: left;
    align-content: center;
`

export const CardTitle = styled.h4`
    display: flex;
    font-size: 1rem;
    margin-bottom: 1px;
    white-space: normal;
    text-align: left;
    word-wrap: break-word;
    color: ${props => props.theme.secondaryColor};
    
`

export const TextLink = styled(Link)`
text-decoration: none;
cursor: pointer;
color: ${props => props?.alt ? props?.theme?.secondaryColor : props.theme.secondaryBg};
&: hover{
 color: ${props => props?.alt ? props.theme.secondaryBg : props.theme.accentColor};
 text-decoration: underline;

}
 
`
export const CreatorInfoWrapper = styled.div`
    display: flex;
    cursor: pointer;
    gap: 0.7rem;
    align-items: center;
    margin-bottom: 3%;
    justify-content: space-evenly;
`

export const CreatorInfoContent = styled.div`
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.mainColor};
`

export const CreatorInfo = styled.div`
    display: flex;
    font-size: 0.9rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    width: 100%;
`

export const H6 = styled.h6`
    font-size: 0.8rem;
    color: ${props => hexToRgba(props.theme.accentColor, 1)};
`

export const InfoContentText = styled.div`
    display: flex;
    white-space: normal;
    color: ${props => props.theme.secondaryBg};
    word-wrap: break-word;
    font-size: 0.8rem;
    &: hover{
    color: ${props => props.theme.secondaryColor};
    text-decoration: underline;

   }
`