import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext';
import ToolTip from '../../ToolTip';
import verifiedBadge from "../../../images/verified_artist_page.png";
import { ActionButton, ActionButtonsContainer, HeaderContainer, PodcasterHeader, PodcasterInfoWrapper, PodcasterName, PodcasterProfileHeaderContainer, PodcasterProfilePicture, PodcasterProfilePictureContainer, VerifiedBadge } from './PodcasterProfileHeaderElements';
import usePodcaster from '../../../hooks/usePodcaster';
import { useAlert } from '../../Alert/AlertContext';
import { DEFAULT_IMAGE } from '../../GravatarAPI';
import { ALERT_TYPES } from '../../../utils/Enum';
import { HoverOption } from '../../MenuOptions/MenuOptionsElements';
import TipWallets from '../../TipForm/TipWallets';
import TipForm from '../../TipForm';
import OptionModal from '../../OptionModal';
import { useModal } from '../../Modal/ModalContext';

const PodcasterProfileHeader = ({podcasterId, profile, isUserFollowing, gravatarProfile, wallets}) => {
  const {currentUser} = useAuth()
  const {toggleModal, modalContent} = useModal()
  const {followPodcaster, unfollowPodcaster} = usePodcaster({id: podcasterId})
  const [isFollows, setFollowing] = useState(false)
  const { addAlert } = useAlert();

  useEffect(() => {
    setFollowing(isUserFollowing);
    return () => {}
  }, [podcasterId]);

  //MOVE THE BUTTONS INTO CUSTOM COMPONENT CLASSES!
  const onFollowClick = () => {
    // follow and unfollow podcaster
    followPodcaster(podcasterId)
      .then((data) => {
        if (data !== false) {
          setFollowing(!isFollows);
        }
      })
      .catch((err) => {
        return;
      });
  };

  const onUnfollowClick = () => {
    // follow and unfollow user

    unfollowPodcaster(podcasterId)
      .then((data) => {
        if (data !== false) {
          setFollowing(!isFollows);
        }
      })
      .catch((err) => {
        return;
      });
  };
    return (
      <HeaderContainer>
          
            <PodcasterHeader background={gravatarProfile?.profileBackground?.color}>
            
            </PodcasterHeader>
          <PodcasterProfileHeaderContainer>
              <PodcasterProfilePictureContainer>
              <PodcasterProfilePicture src={profile?.podcasterSettings?.profilePictureType === "custom" ? profile?.profilePicture?.url : gravatarProfile ? gravatarProfile?.avatar_url + '?d=mp&s=600' : DEFAULT_IMAGE(600)}/>
            </PodcasterProfilePictureContainer>
            <PodcasterInfoWrapper>
              <PodcasterName>{profile?.name}{profile?.isVerified && (
                  <ToolTip text={"This account is verified"}>
                    <VerifiedBadge
                      src={verifiedBadge}
                      onContextMenu={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </ToolTip>
                )}</PodcasterName>
                  {currentUser?.uid && (
                <ActionButtonsContainer>
                  <ActionButton
                    /* ref={followRef} */
                    background={gravatarProfile?.profileBackground?.color}
                    onClick={() =>{
                      isFollows ? onUnfollowClick() : onFollowClick()}
                    }
                  >
                    {" "}
                    {isFollows ? "Unfollow" : "Follow" }
                  </ActionButton>
                  {profile?.podcasterSettings?.tipping && <ActionButton background={gravatarProfile?.profileBackground?.color} onClick ={() => {
                  toggleModal()
                  modalContent(<OptionModal heading={`Select Wallet to tip ${profile?.name}`}>
                    {profile?.uid && <><HoverOption onClick={() => {
                      toggleModal()
                      modalContent(<TipForm displayName={profile?.name} address={profile?.uid}/>)
                    }}>XRP Wallet</HoverOption>
                    <HoverOption onClick={() => {
                      toggleModal()
                      modalContent()
                      addAlert({
                        title: "Coming Soon",
                        type: ALERT_TYPES.INFO,
                        secs: 10,
                      })
                    }}>ILP Wallet</HoverOption></>}
                  { wallets?.length > 0 && <HoverOption onClick={() => {
                      toggleModal()
                      modalContent(<TipWallets wallets={wallets} />)
                    }}>Other Wallets</HoverOption>}
                  </OptionModal>)}}>
                      Send Tip
                    </ActionButton>}
                  {profile?.uid &&
                    profile?.isVerified &&
                    profile?.subscriptionFee > 0 &&
                    profile?.uid !== currentUser?.uid && (
                      <ActionButton background={gravatarProfile?.profileBackground?.color}>
                        Subscribe
                      </ActionButton>
                    )}
                </ActionButtonsContainer>
              )}
            </PodcasterInfoWrapper>
            </PodcasterProfileHeaderContainer>
      </HeaderContainer>
  )
}

export default PodcasterProfileHeader