import React from 'react'
import { SongDetailsSection, SongDetailsSectionTitle } from '../../MusicUploadsForm/MusicUploadsFormElements'
import SongSelector from '../../MusicUploadsForm/PodcastUploads/SongSelector'
import { PodcasterRoyaltySelector } from '../../MusicUploadsForm/PodcastUploads/PodcasterSelector'

const Page3 = ({isMusicContained, songs, addSong, removeSong, addPodcaster, removePodcaster, podcastersPayouts, podcasters,
    payoutArtists,
    errors}) => {
  return (
    <SongDetailsSection>
        <SongDetailsSectionTitle>Credits and Royalties</SongDetailsSectionTitle>
        {isMusicContained && <SongSelector addSong={addSong} selectedSongs={songs} removeSong={removeSong}/>}
        <PodcasterRoyaltySelector podcasters={podcasters} payoutError={errors?.payout} removePodcaster={removePodcaster} podcastersPayouts={podcastersPayouts} addPodcaster={addPodcaster} artistPayouts={payoutArtists}/>
    </SongDetailsSection>
  )
}


export default Page3