import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import { Home, Welcome } from '../../components/Navbar/Data';
import { useNavigate, useParams } from 'react-router-dom';
import SimilarCollections from '../../components/ViewAllContent/SimilarCollections';

const SimilarCollectionsPage = () => {
    const navigate  = useNavigate()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(!isOpen)}  
    const {type} = useParams()

    useEffect(() => {
        if(!type)
        {
            navigate(-1)
        }
        return () => {}
    }, [type])
    
    return( 
        <>
              <Sidebar
            isOpen={isOpen}
            toggle={toggle}
            navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
          />
            <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}/>
            <SimilarCollections type={type}/>
            
        </>
        )
}

export default SimilarCollectionsPage