import React from "react";
import { SkeletonPurchaseCardImg } from "../../Skeleton/SkeletonElements";
import { useModal } from "../../Modal/ModalContext";
import MenuOptions, { ArtistSelectorModal, MusicContentMenu } from "../../MenuOptions";
import {
  CardContainer,
  CardContent,
  CardTitle,
  CreatorInfo,
  CreatorInfoContent,
  CreatorInfoWrapper,
  H6,
  InfoContentText,
  TextLink,
} from "./SongCardElements";
import { Theme } from "../../WebsiteThemeElements";
import { useAuth } from "../../../contexts/AuthContext";
import ScrollToTop from "../../ScrollToTop";
import ImageUtils from "../../../utils/ImageUtils";
import { getArtistsNames2, getSongTitle, getUniqueListBy } from "../../../utils";
import { ARTIST_SEARCH_TYPES } from "../../../utils/Enum";
import { MdExplicit } from "react-icons/md";
import { RiVideoFill } from "react-icons/ri";
import { PreReleaseBadge } from "../CardElements";

const SongCard = ({ content, cardRef }) => {
  const { openModal, closeModal, modalContent } = useModal();
  const { currentUser } = useAuth();
  const { goTo, scrollToTop } = ScrollToTop();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const {options} = MenuOptions()
  return (
    <CardContainer id={content?.songId}
    onContextMenu={(e) => {
      e.preventDefault();
      if (content?.songId && currentUser?.uid && (!content?.isPreRelease || (content?.isPreRelease && content?.isPreview))) {
        openModal();
        options(content)
      }
    }} ref={cardRef} background={content?.color}>
      <div style={{ alignItems: "center", margin: "auto" }}>
        <SkeletonPurchaseCardImg
          onClick={() => content?.songId ? goTo(`/song/${content?.songId}`) : goTo("#")}
          src={content?.albumArt ? content?.albumArt : defaultAlbumArt}
        />
      </div>
      <CardContent>
      {content?.isPreRelease && <PreReleaseBadge style={{marginLeft: '0',}}>Pre Release</PreReleaseBadge>}
        <CardTitle>
          <TextLink alt
            to={content?.songId ? `/song/${content?.songId}` : "#"}
          >
            {getSongTitle(content)}
          </TextLink>
          <div>
            {content?.explicit && <MdExplicit />}
            {content?.video?.url && <RiVideoFill />}
          </div>
        </CardTitle>
        <CreatorInfoWrapper>
          <CreatorInfo>
            <CreatorInfoContent>
              <InfoContentText>
                {" "}
                <TextLink
                  onClick={() => {
                    if(content?.artists?.length > 1){
                        openModal()
                       return modalContent(<ArtistSelectorModal artists={content?.artists}/>)
                    }
                    return scrollToTop()
                  }}
                  to={
                    content?.artists?.length === 1 ? `/artist/${content?.artists[0].artistId}`: 
                     "#"
                  }
                >
                  {getArtistsNames2(content?.artists, ARTIST_SEARCH_TYPES.NAME)
                    ?.length > 30
                    ? `${getArtistsNames2(
                        content?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      ).substring(
                        0,
                        25
                      )}...`
                    : getArtistsNames2(
                        content?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      )}
                </TextLink>
              </InfoContentText>
            </CreatorInfoContent>
          </CreatorInfo>
        </CreatorInfoWrapper>
        {content?.album && 
          <CreatorInfoWrapper>
            <CreatorInfo>  
              < CreatorInfoContent>
              <InfoContentText>
                <TextLink alt={true} to={`/album/${content?.album?.albumId}`}>from {content?.album?.albumName}</TextLink>
              </InfoContentText>
            </CreatorInfoContent>
            </CreatorInfo>
          </CreatorInfoWrapper>}
      </CardContent>
    </CardContainer>
  );
};

export default SongCard;
