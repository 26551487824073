import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import { FaPause, FaPlay, FaVolumeDown, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import { MdExplicit, MdLibraryMusic, MdLyrics } from "react-icons/md";
import { convertDuration, getArtistsNames, getArtistsNames2, getSongTitle } from "../../../utils";
import { ARTIST_SEARCH_TYPES } from "../../../utils/Enum";
import ImageUtils from "../../../utils/ImageUtils";
import { Audio, AudioPlayerDurationTimerWrapper, AudioPlayerPlaybackControlsWrapper, AudioPlayerSliderWrapper, AudioPlayerTimerText, AudioPlayerControls, AudioPlayerControlsWrapper, AudioPlayerSlider, AudioPlayerVolumeSlider, VolumeControlsWrapper } from "../../AudioPlayer1/AudioPlayerElements";
import ScrollToTop from "../../ScrollToTop";
import { BsFillSkipEndFill, BsFillSkipStartFill } from "react-icons/bs";
import { RiPlayList2Fill } from "react-icons/ri";

const MusicStoreAudioPlayer = ({
  timeJump,
  currentSong,
  isPlaying,
  duration,
  currentTime,
  progressBar,
  audioPlayer,
  changeRange,
  setTimeJump,
  volume,
  isRepeat,
  calcTime,
  backThirty,
  forwardThirty,
  isPodcast,
  isShuffle,
  nextSong,
  handleToggle,
  prevSong,
  playlist,
  setPlaylist,
  togglePlayPause,
  toggleMute,
  handleEnd,
  toggleVolume,
  isOpen,
  closeViewer,
  isMute,
  openViewer,
  setTab,
  currentTab
}) => {
  const { defaultAlbumArt } = ImageUtils();
  const { goTo } = ScrollToTop();
  const [isCountdown, setCoundown] = useState(false);
  return (
    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
      <Audio
          ref={audioPlayer}
          src={playlist[currentSong]?.mp3Url ? playlist[currentSong]?.mp3Url : playlist[currentSong]?.url}
          loop={isRepeat === 2}
          preload={"auto"}
          volume={volume}
          muted={isMute || volume === 0}
          onEnded={handleEnd}
        />
    <AudioPlayerControlsWrapper style={{padding: ' 0 3%'}}  > 
      <VolumeControlsWrapper>
        <AudioPlayerControls style={{fontSize: "1.4rem"}} onClick={toggleMute}>
          {isMute || volume === 0 ? (
            <FaVolumeMute />
          ) : volume * 100 < 50 ? (
            <FaVolumeDown />
          ) : (
            <FaVolumeUp />
          )}
        </AudioPlayerControls>
        <AudioPlayerVolumeSlider
          type="range"
          volume={isMute ? "0%" : `${volume * 59}px`}
          value={isMute ? 0 : volume * 100}
          onChange={toggleVolume}
        />
      </VolumeControlsWrapper>
        
      <AudioPlayerControlsWrapper style={{display: 'flex', padding: '2%', width: '100%', justifyContent: "flex-end", gap: '3%'}}>
        {currentTab !== 'default' && <AudioPlayerControls style={{fontSize: "1.6rem"}} onClick={() => setTab('default')}>
          <MdLibraryMusic /> 
      </AudioPlayerControls>}
      {playlist[currentSong]?.lyrics && <AudioPlayerControls style={{fontSize: "1.6rem"}} onClick={() => currentTab === 'lyrics' ? setTab('default') : setTab('lyrics')}>
      <MdLyrics />
      </AudioPlayerControls>}
      <AudioPlayerControls style={{fontSize: "2rem"}} onClick={() => currentTab === 'queue' ? setTab('default') : setTab('queue')}>
        <RiPlayList2Fill />
      </AudioPlayerControls>
      </AudioPlayerControlsWrapper>
    </AudioPlayerControlsWrapper>
        <div style={{display: 'flex', padding: '3%', width: '100%', flexDirection: 'column'}}>
           <AudioPlayerSliderWrapper>
        <AudioPlayerSlider
            progressWidth={`${(currentTime / duration) * 100}%`}
            type={"range"}
            ref={progressBar}
            value={currentTime}
              max={duration}
            onChange={changeRange}
          />
        </AudioPlayerSliderWrapper>
        <AudioPlayerDurationTimerWrapper style={{fontSize: '0.8rem', justifyContent: "space-between"}}
            onClick={() => setCoundown(!isCountdown)}
          >
            <AudioPlayerTimerText onClick={() => setCoundown(!isCountdown)}>
              {isPlaying && calcTime(currentTime)}
            </AudioPlayerTimerText>
            <AudioPlayerTimerText onClick={() => setCoundown(!isCountdown)}>
              {isPlaying && !isNaN(duration)
                ? isCountdown
                  ? convertDuration(playlist[currentSong]?.duration ? playlist[currentSong]?.duration - currentTime : duration - currentTime)
                  : convertDuration(playlist[currentSong]?.duration ? playlist[currentSong]?.duration : duration)
                : "0:00"}
            </AudioPlayerTimerText>
          </AudioPlayerDurationTimerWrapper>
        
        </div>
        <AudioPlayerControlsWrapper style={{width: '100%', justifyContent: 'center'}}>
      <AudioPlayerPlaybackControlsWrapper
        style={{ display: 'flex', width: '60%', alignItems: 'center', justifyContent: "space-evenly"}}
      >
        {playlist?.length > 1 &&<AudioPlayerControls style={{fontSize: "3rem"}}
          onClick={() => {
            handleToggle("prev", true);
          }}
        >
          <BsFillSkipStartFill />
        </AudioPlayerControls>}
    
        <AudioPlayerControls style={{fontSize: "3rem"}}
          onClick={() => {
            togglePlayPause()
          }}
        >
          {isPlaying ? <FaPause /> : <FaPlay />}
        </AudioPlayerControls>
      
      {playlist?.length > 1 &&  <AudioPlayerControls style={{fontSize: "3rem"}}
          onClick={() => {
            handleToggle("next", true);
          }}
        >
          <BsFillSkipEndFill />
        </AudioPlayerControls>}
      </AudioPlayerPlaybackControlsWrapper>
    </AudioPlayerControlsWrapper>
  
    </div>
  );
};

export default MusicStoreAudioPlayer;
