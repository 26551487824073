import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import { Home, Welcome } from '../../components/Navbar/Data';
import SwerlStore from '../../components/Swərl/SwerlStore';

const SwerlStorePage = () => {
    const [isOpen, setIsOpen] = useState(false);
	const navigate  = useNavigate()
	const toggle = () => setIsOpen(!isOpen);

  return (
    <>
		<Sidebar
          isOpen={isOpen}
          toggle={toggle}
          navInfo={localStorage.getItem('accInfo')? Home(localStorage.getItem('accInfo')) : Welcome}
        />
          <Navbar toggle={toggle} navInfo={localStorage.getItem('accInfo') ? Home(localStorage.getItem('accInfo')) : Welcome}/>
    <SwerlStore/>
    </>
  )
}

export default SwerlStorePage