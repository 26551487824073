import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import useMusic from '../../hooks/useMusic';
import { useUser } from '../../hooks/useUser';
import AlbumArt from '../AlbumArt';
import {
  storage,
  storageRef,
  uploadBytes,
  getDownloadUrl,
} from "../Authorization/firebase";
import { useAudio } from '../AudioPlayer/context/AudioContext';
import { FormButton, FormButton1, FormInput, FormInput1, FormLabel, FormText, FormTextArea, FormWrapper } from '../CustomForm/CustomFormElements';
import { getRandomColor } from '../HexToRGBA';
import { HoverOption } from '../MenuOptions/MenuOptionsElements';
import Modal from '../Modal';
import { useModal } from '../Modal/ModalContext';
import OptionModal from '../OptionModal';
import { Theme } from '../WebsiteThemeElements';
import { PulseSpinner } from 'react-spinners-kit';
import { getObjectLength, newlineFormatter, stringFormatter, validateAddress } from '../../utils';
import { SmallText } from '../NFTCollectionCreator/NFTCollectionCreatorElements';
import { Button1, CustomButton } from '../ButtonElement';
import { useAlert } from '../Alert/AlertContext';
import { ALERT_TYPES } from '../../utils/Enum';
import { ChipList } from '../Chip/ChipElements';
import Chip from '../Chip';

const PlaylistCreator = ({songId}) => {
    const { currentUser } = useAuth();
    const {addAlert} = useAlert()
    const [playlistName, setName] = useState()
    const [about, setAbout] = useState("")
    const {addPlaylist, deletePlaylist} = useMusic({})
    const { toggleModal, modalContent} = useModal();
    const { addSongToPlaylist, getPlaylistsByUser, getCollectionsByUser } = useUser({id: currentUser?.uid});
    const [collaborators, setCollaborators] = useState([])
    const [currentCollaborator, setCurrentCollaborator] = useState()
    const [isCollabOpen, setOpenCollab] = useState(false)
    const [color, setColor] = useState(getRandomColor())
    const [coverArt, setCoverArt] = useState({})
    const playlistNameRef = useRef()
    const aboutRef = useRef()
    const navigate  = useNavigate()
    const theme = Theme({})
    const [isOpen, setOpen] = useState(false)

    const handleInput = (e) => {
        switch (e.target.name) {
          case 'cover art':
            let currArt = coverArt;
            if(e.target.value && e.target.files[0]){
              var reader = new FileReader();
         
          //Read the contents of Image File.
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function (e1) {
          
          //Initiate the JavaScript Image object.
          var image = new Image();
          
          //Set the Base64 string return from FileReader as source.
          image.src = e1.target.result;
          
          
          
          setCoverArt({ file: e.target.files[0] });
          image.onload = function () {
          var height = this.height;
          var width = this.width;
          //console.log(`width: ${width} - height: ${height} `)
          if (height < 1600 || width < 1600) {
            alert("Height and Width must not be less than 1600px.");
            setCoverArt(currArt)
            return false;
          }
          
           //let artBlob = convertFileToBytes(e.target.files[0]) 
           let imageFileName
           if(!coverArt.name){
           imageFileName = `${Math.round(
            Math.random() * 10000000000000
          )}.${
            e.target.files[0].name.split(".")[
              e.target.files[0].name.split(".").length - 1
            ]
          }`;
          setCoverArt(prev => {return {...prev, name: imageFileName}})
        }else{
            imageFileName = coverArt.name
          }
          const uploadTask = uploadBytes(
            storageRef(
              storage,
              `users/${currentUser?.uid}/${imageFileName}`
            ),
            e.target.files[0],
            { contentType: e.target.files[0].type }
          );

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setCoverArt((prev) => {
                return { ...prev, progress: progress };
              });

              //console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case "paused":
                  //console.log("Upload is paused");
                  break;
                case "running":
                  //console.log("Upload is running");
                  break;
                default:
                  //console.log("Upload is " + progress + "% done");
                  break;
              }
            },
            (error) => {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case "storage/unauthorized":
                  // User doesn't have permission to access the object
                  break;
                case "storage/canceled":
                  setCoverArt(currArt);
                  // User canceled the upload
                  break;
                // ...
                case "storage/unknown":
                  setCoverArt(currArt);
                  // Unknown error occurred, inspect error.serverResponse
                  break;
                default:
                  break;
              }
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              getDownloadUrl(uploadTask.snapshot.ref).then((downloadURL) => {
                //console.log("File available at", downloadURL);
                setCoverArt((prev) => {
                  return { ...prev, url: downloadURL, ref: uploadTask.snapshot.ref.fullPath};
                });
              });
            }
          );}
          };
            }
            break;
          case 'color':
            setColor(e.target.value)
            break;

          case 'name':
            setName(e.target.value)
            break;

          case 'about':
            setAbout(newlineFormatter(e.target.value))
            break;

          case 'collaborators':
            setCurrentCollaborator(e.target.value)
            break;
        
          default:
            break;
        }
    }

    const uploadImage = () => {
      
    
    }

    const createNewPlaylist = async({playlistName, about, songs, playlistArt, color1, collaborators}) => {
      modalContent(<OptionModal
        isOpen={isOpen}
        heading={"Creating Playlist"}>
             <div style={{justifyContent: 'center', marginLeft:' 50%'}}>
              <PulseSpinner loading={true} />
            </div>

        </OptionModal>)
      await addPlaylist({
        name: playlistName, 
        uid: currentUser?.uid, 
        songs: songs,
        about: about, 
        collaborators: collaborators,
        playlistArt: playlistArt, 
        color: color1 }).then(data => {
        if(data){
          //console.log('Data-: ',data)
          modalContent(<OptionModal
            isOpen={isOpen}
            heading={"Successfully Created Playlist"}>
                    
                    <HoverOption to={`#`} onClick={() => {
                      modalContent();
                      toggleModal();}
                      }>OK</HoverOption>
                      <HoverOption to={`#`} onClick={() => {
                      modalContent();
                      toggleModal()
                      navigate( `/playlist/${data}`)
                    }
                      }>View Playlist</HoverOption>
                </OptionModal>);
        }
      }).catch(err => {
        modalContent(<OptionModal
              isOpen={isOpen}
              heading={"Something went wrong"}>
                      
                      <HoverOption to={`#addToPlaylist`} onClick={createNewPlaylist({playlistName, songs, playlistArt})
                        }>Try Again</HoverOption>
                        <FormButton to={`#`} onClick={() => {
                        modalContent();
                        toggleModal();
                        }
                        }>Cancel</FormButton>
                  </OptionModal>)
      })
      return
    }

    
               
  return (
   <> 
   
   {isOpen && <Modal isOpen={isOpen}>
    <OptionModal heading={'Cover Art'}>
        <HoverOption ><label htmlFor='cover art' onClick={() => setOpen(false)}>Select Image</label></HoverOption>
        {coverArt?.url && <HoverOption onClick={() => setOpen(false)}>Delete</HoverOption>}
        <FormInput1
                style={{ display: "none" }}
                id="cover art"
                name="cover art"
                onChange={handleInput}
                type={"file"}
                />
    </OptionModal>
</Modal>}


    <OptionModal
    isOpen={isOpen}
    heading={"Create New Playlist"}
    onCancel={() => {
      modalContent();
      toggleModal();
    }}>
        <FormWrapper>
            <div
              style={{ display: "flex", width: "100%", padding: "2%" }}
            >
              <FormLabel>Cover Art</FormLabel>
              <label for='cover art' style={{ cursor: "pointer" }}>
                {!coverArt?.url && coverArt?.progress < 100 && <span>Uploading... {coverArt?.progress}</span>}
                {!coverArt?.url && (!coverArt?.progress || coverArt?.progress === 0)&&
                <AlbumArt 
                background={color} 
                size={'12rem'} 
                alt={'Upload Cover Art'}/>}
                {coverArt?.url && coverArt?.progress === 100 && 
                <AlbumArt 
                background={color} 
                size={'12rem'} 
                albumArt={coverArt?.url} 
                alt={'cover art'}/>}
            </label>
            <FormInput1
                style={{ display: "none" }}
                id="cover art"
                name="cover art"
                onChange={handleInput}
                type={"file"}
                />
            </div>
            <div
              style={{ display: "flex", width: "100%", padding: "2%" }}
            >
              <FormText>
                <label style={{ cursor: "pointer" }} for="color">
                  Alt Cover Art (Color)
                </label>
              </FormText>
              <label
                for="color"
                style={{
                  display: "flex",
                  flex: "1 1 100%",
                  width: "100%",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    background: color,
                    width: "2rem",
                    height: "2rem",
                    display: "flex",
                    borderRadius: "0.2rem",
                  }}
                ></div>
              </label>
              <FormInput1
                style={{ display: "none" }}
                id="color"
                name="color"
                onChange={handleInput}
                type={"color"}
                value={color}
              />
            </div>
            <FormText background={theme.secondaryColor}>
              Background color placeholder if the cover art does not
              load
            </FormText>
          </FormWrapper>
          <FormWrapper>
            <FormLabel>Playlist Title</FormLabel>
            <FormInput1 ref={playlistNameRef} name={'name'} placeholder='Playlist Title' onChange={handleInput} value={playlistName}/>
          </FormWrapper>
           
              <FormTextArea ref={aboutRef} name={'about'} placeholder='About playlist' onChange={handleInput} value={stringFormatter(about)}/>
            <br/>
            <FormWrapper>
              <FormLabel>Playlist Collaborators</FormLabel>
              <ChipList>
                {collaborators?.map((c,index) => {
                  return <Chip label={`${c?.substring(0, 8)}...${c?.substring(c?.length -5,c?.length)}`} closeButton={true} onDelete={() => {
                    setCollaborators(collaborators.filter((col, i) => i !== index))
                  }}/>
                } )}
              </ChipList>
             {!isCollabOpen && <HoverOption onClick={() => setOpenCollab(true)}>Add Collaborator</HoverOption>}
              {isCollabOpen && <><FormInput name={"collaborators"} placeholder='Enter collaborator wallet address' value={currentCollaborator} onChange={handleInput}/>
              <FormButton1 onClick={() => {
                let users = collaborators
                if(currentCollaborator !== currentUser?.uid){
                if(validateAddress(currentCollaborator)){
                if(!users.includes(currentCollaborator)){
                  users.push(currentCollaborator)
                }else{
                  addAlert({
                    message: "User collaborator already exists",
                    type: ALERT_TYPES.DANGER,
                  })
                }
                setCollaborators(users)
                setCurrentCollaborator()
                setOpenCollab(false)
                return
              }else{
                addAlert({
                  title: "Invalid User",
                  message: "Wallet Address is invalid",
                  type: ALERT_TYPES.DANGER,
                })}
              }else{
                addAlert({
                  title: "Invalid User",
                  message: "You cannot be a collaborator of your own playlist",
                  type: ALERT_TYPES.DANGER,
                })
                }}}>Add User</FormButton1>
              <CustomButton onClick={() => {
                setCurrentCollaborator() 
                setOpenCollab(false)}}>Cancel</CustomButton></>}
                              <SmallText>Add users to collaborate to your playlist by wallet address</SmallText>

            </FormWrapper>
            <FormButton1 onClick={() => {
              if(playlistName?.trim()?.length > 0 && 
                playlistName?.trim() !== '' &&  (!coverArt?.progress || (coverArt?.progress === 100 && coverArt?.url))) {
                  let playlist = {
                    playlistName: playlistName?.trim()
                  }
                  playlist = {
                    ...playlist,
                    playlistArt: coverArt?.url && coverArt?.ref ? 
                    {
                      url: coverArt?.url,
                      ref: coverArt?.ref} : undefined,
                      songs: songId ? [songId] : undefined,
                      collaborators: collaborators?.length > 0 ? collaborators : [],
                      about: about?.trim()
                  }
                   createNewPlaylist(playlist)
                }}
               
                }>Create New Playlist</FormButton1>
        </OptionModal>
  </>)
}

export default PlaylistCreator