import  { getObjectLength, hexToStr, timestampToDate } from "../index"
import { db} from "../../components/Authorization/firebase"
import {query, doc, getDoc, collection, getDocs, where, limit, orderBy, updateDoc, onSnapshot} from 'firebase/firestore'
import { getAlbums, getNFTokenCollection, getSongs } from "./musicUtils"


export const getTopDigitalPurchasesUpdates = async (setPurchases) => {
    let purchases 
    return onSnapshot(query(collection(db,'digitalPurchases'), orderBy('createdAt', 'desc')), async (purchaseCollection) => {
      if(purchaseCollection?.empty){
        return 
      }
      purchases = purchaseCollection.docs.filter(purchase => (new Date(purchase.data().createdAt).getMonth === (new Date().getMonth)) && ((new Date().getFullYear) === new Date(purchase.data().createdAt).getFullYear) && ((new Date().getDate()) - (new Date(purchase.data().createdAt).getFullYear) <= 7) ).length > 0 ?
      purchaseCollection.docs.filter(purchase => (new Date(purchase.data().createdAt).getMonth === (new Date().getMonth)) && ((new Date().getFullYear) === new Date(purchase.data().createdAt).getFullYear) && ((new Date().getDate()) - (new Date(purchase.data().createdAt).getFullYear) <= 7) ) :  purchaseCollection.docs
      purchases = purchases.map(purchase => {
        return {
          ...purchase.data()
        }
      })
      let songs = purchases.filter(p => p?.songId).map(p => { return p?.songId})
      let albums = purchases.filter(p => p?.albumId).map(p => { return p?.albumId})
      let groupedAlbumsObj
      let groupedSongsObj
      if(albums?.length > 0){
       groupedAlbumsObj = albums.reduce(
        (prev, current) => ({
          ...prev,
          [current]: prev[current] ? prev[current] + 1 : 1 ,
        }),
        {}
      )}
    
      if(songs?.length > 0){
       groupedSongsObj = songs.reduce(
        (prev, current) => ({
          ...prev,
          [current]: prev[current] ? prev[current] + 1 : 1 ,
        }),
        {}
      )}
    let sortedAlbums = Object.entries(groupedAlbumsObj)
    .sort(([,a],[,b]) => a-b)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    let sortedSongs = Object.entries(groupedSongsObj)
    .sort(([,a],[,b]) => a-b)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    albums = getObjectLength(sortedAlbums)> 0 ? await getAlbums(Object.keys(sortedAlbums).filter((k, i) => i < 10)) : []
    songs = getObjectLength(sortedSongs) > 0 ? await getSongs(Object.keys(sortedSongs).filter((k, i) => i < 10)) : []
    setPurchases({
      albums: albums,
      songs: songs
    })

  
    })
  }
  
  export const getTopNFTokenPurchasesUpdates = async (setCollections) => {
    let purchases 
    return onSnapshot(query(collection(db,'nfTokenPurchases'), orderBy('createdAt', 'desc'))
    ,async (collection) => {
      if(collection?.empty){
        return
      }
      purchases = collection.docs.filter(purchase => (new Date(purchase.data().createdAt).getMonth === (new Date().getMonth)) && ((new Date().getFullYear) === new Date(purchase.data().createdAt).getFullYear) && ((new Date().getDate()) - (new Date(purchase.data().createdAt).getFullYear) <= 7) ).length > 0 ?
      collection.docs.filter(purchase => (new Date(purchase.data().createdAt).getMonth === (new Date().getMonth)) && ((new Date().getFullYear) === new Date(purchase.data().createdAt).getFullYear) && ((new Date().getDate()) - (new Date(purchase.data().createdAt).getFullYear) <= 7) ) :  collection.docs
      purchases = purchases.map(purchase => {
        return {
          ...purchase.data()
        }
      })
      let collections = purchases.filter(p => p?.collectionId).map(p => { return p?.collectionId})
      let groupedCollectionsObj
      
      if(collections?.length > 0){
      
      let existingCollections = []
      for(let i = 0; i < collections?.length; i++){
        if((await getDoc(doc(db,`collections/${collections[i]}`))).exists){
            existingCollections.push(collections[i])
        }
      }
       groupedCollectionsObj = existingCollections.reduce(
        (prev, current) => ({
          ...prev,
          [current]: prev[current] ? prev[current] + 1 : 1 ,
        }),
        {}
      )}
    let sortedCollections = Object.entries(groupedCollectionsObj)
    .sort(([,a],[,b]) => a-b)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    let c = []
    for(let i = 0; i < Object.keys(sortedCollections).filter((k, index) => index < 10).length; i++ ){
    c.push(await getNFTokenCollection(Object.keys(sortedCollections)[i]))
  }
      return setCollections(c)
  
    })
  }

  export const getTopSoldNFTokens = async (setCollections) => {
    let cols
    return onSnapshot(query(collection(db,'collections'), orderBy('amountSold', 'desc'))
    ,async (collections) => {
      if(collection?.empty){
        return
      }
      return setCollections(collections.docs.map(col => {
        return {
          collectionId: col.id,
          ...col.data()
        }
      }))
  
    })
  }
  