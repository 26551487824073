import  { hexToStr, timestampToDate } from "../index"
import { db} from "../../components/Authorization/firebase"
import {query, doc, getDoc, collection, getDocs, where, limit, orderBy, updateDoc, onSnapshot} from 'firebase/firestore'


export const getCollectionInfo = (collectionId, setCollectionInfo) => {
    return onSnapshot(doc(db,`collections/${collectionId}`), async (collectionSnapshot) => {
      if(collectionSnapshot?.exists){
        return await getDocs(query(collection(db, 'collectionWhitelisters'), where('collectionId', "==" ,collectionId)))
        .then(claimers => {
          if(claimers.empty){
            return setCollectionInfo({
              collectionId: collectionSnapshot.id,
              ...collectionSnapshot.data(),
            });
          }
          return setCollectionInfo({
            collectionId: collectionSnapshot.id,
            whitelist: true,
            ...collectionSnapshot.data(),
          }); 
    })
  }
  return false
})
}