import React from "react";
import { FaStore, FaPiggyBank } from "react-icons/fa";
import { RiFolderMusicFill } from "react-icons/ri";
import { getRandomColor } from "../HexToRGBA";
import {
  ServicesContainer,
  ServicesH1,
  ServicesH2,
  ServicesP,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
} from "./ServicesElements";
import { Button } from "../ButtonElement";

const Services = () => {
  return (
    <>
      <ServicesContainer id="services">
        <ServicesH1>Built For The Web3 Music Community</ServicesH1>
        <ServicesWrapper numGrid={2}>
          <ServicesCard>
            <ServicesIcon
              color1={getRandomColor()}
              color2={getRandomColor()}
              color3={getRandomColor()}
            >
              <ServicesH2>Secure Web3 Account </ServicesH2>
            </ServicesIcon>
            <br />
            <ServicesP>
              Connect to the platform and confirm transactions using your XRP
              wallet, making every process reflect instantly.
            </ServicesP>
            <ServicesP>
              Use Gravatar to have an instant & universally customizable
              profile.
            </ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              color1={getRandomColor()}
              color2={getRandomColor()}
              color3={getRandomColor()}
            >
              <ServicesH2>Music Store</ServicesH2>
            </ServicesIcon>
            <br />
            <ServicesP>
              {" "}
              We provide a store for musicians alike to sell their music
              digitally and as an NFT,allowing fans to listen to music on sale
              before purchasing, similar to traditional music stores.
            </ServicesP>
            <ServicesP>
              {" "}
              Music that has been purchased, by fans, from the music store can
              then be dowloaded using our music downloader
            </ServicesP>
            <Button to={"/store"}>Visit Music Store</Button>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              color1={getRandomColor()}
              color2={getRandomColor()}
              color3={getRandomColor()}
            >
              <ServicesH2>Streaming Service</ServicesH2>
            </ServicesIcon>
            <br />
            <ServicesP>
              {" "}
              A platform for fans to stream unlimited music and podcasts,
              supporting artists exclusively using the XRP Ledger.{" "}
            </ServicesP>
            <ServicesP>No ads, allowing users to stream music on
              demand and uninterrupted.</ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              color1={getRandomColor()}
              color2={getRandomColor()}
              color3={getRandomColor()}
            >
              <ServicesH2>Pay As You Stream Subscription Plan</ServicesH2>
            </ServicesIcon>
            <br />
            <ServicesP>
              {" "}
              Get access to playlists, music and podcasts curated and inspired by the artists and content creators you’re
              listening to for only $5/month.
            </ServicesP>
            <ServicesP>
              {" "}
              By using web monetization as our revenue model, fans can support
              their favourite artists, by paying for content they consume in
              real time, using the XRP Ledger.
            </ServicesP>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default Services;
